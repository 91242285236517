export var CorpusCapability;
(function (CorpusCapability) {
    CorpusCapability["ANALYZE_QUERY"] = "/api/v1/analyze/query";
    CorpusCapability["CONJUNCTION_BINDINGS"] = "/api/v1/conjunction/bindings";
    CorpusCapability["CORPORA"] = "/api/v1/corpora";
    CorpusCapability["CORPORA_DOCUMENT"] = "/api/v1/corpora/document";
    CorpusCapability["CORPUS_INFO"] = "/api/v1/corpora/info";
    CorpusCapability["CREATE_CONCEPT"] = "/api/v1/create/concept";
    CorpusCapability["DISCOVER_MULTIHOP_RELATIONSHIP"] = "/api/v1/discover/multihop/relationship";
    CorpusCapability["EXPORT_EVIDENCE"] = "/api/v1/export/evidence";
    CorpusCapability["EXPORT_TRENDS"] = "/api/v1/export/trends";
    CorpusCapability["FEEDBACK"] = "/api/v1/feedback";
    CorpusCapability["GET_URL_DATA"] = "/api/v1/get/url/data";
    CorpusCapability["GET_DOMAIN_MODEL"] = "/api/v1/domain_model/get";
    CorpusCapability["GET_URL_ID"] = "/api/v1/get/url/id";
    CorpusCapability["LOOKUP_CONCEPTS"] = "/api/v1/lookup/concepts";
    CorpusCapability["LOOKUP_KB_IDS"] = "/api/v1/lookup/concepts/kb_ids";
    CorpusCapability["MULTIHOP_DISCOVERY_CONFIG_PATH"] = "/api/v1/discover/multihop/config";
    CorpusCapability["SEARCH_ARGUMENTS"] = "/api/v1/search/arguments";
    CorpusCapability["SEARCH_ARGUMENTS_CLAUSES"] = "/api/v1/search/arguments/clauses";
    CorpusCapability["SEARCH_CONCEPTS"] = "/api/v1/search/concepts";
    CorpusCapability["SEARCH_CONCEPTS_MEMBERS"] = "/api/v1/search/concepts/members";
    CorpusCapability["SEARCH_CONCEPTS_REFUTING"] = "/api/v1/search/concepts/refuting";
    CorpusCapability["SEARCH_EVIDENCE"] = "/api/v1/search/evidence";
    CorpusCapability["SEARCH_GLOSSARY"] = "/api/2023-07-26/search/glossary";
    CorpusCapability["SEARCH_METADATA"] = "/api/v1/search/metadata";
    CorpusCapability["SEARCH_RELATIONS"] = "/api/v1/search/relations";
    CorpusCapability["SEARCH_RELATIONS_REFUTING"] = "/api/v1/search/relations/refuting";
    CorpusCapability["SUGGEST_CONCEPTS"] = "/api/v1/suggest/concepts";
    CorpusCapability["SUGGEST_EVIDENCE"] = "/api/v1/suggest/evidence";
    CorpusCapability["SUGGEST_FINDINGS"] = "/api/v1/suggest/findings";
    CorpusCapability["SUGGEST_QUERIES_RELATED"] = "/api/v1/suggest/queries/related";
    CorpusCapability["SUGGEST_QUERY_NEXT_CONCEPTS"] = "/api/v1/suggest/query/next/concepts";
    CorpusCapability["SUGGEST_QUERY_NEXT_EVIDENCE"] = "/api/v1/suggest/query/next/evidence";
    CorpusCapability["SUGGEST_RELATIONS"] = "/api/v1/suggest/relations";
    CorpusCapability["SUGGEST_SUMMARY"] = "/api/v1/suggest/summary";
    CorpusCapability["SUGGEST_ANSWER"] = "/api/v1/suggest/answer";
    CorpusCapability["TRENDS"] = "/api/v1/trends";
    CorpusCapability["TRENDS_GRANULARITIES"] = "/api/v1/trends/granularities";
    CorpusCapability["TRENDS_GROUP_BY_FIELDS"] = "/api/v1/trends/groupbyfields";
})(CorpusCapability || (CorpusCapability = {}));
export var AppCapability;
(function (AppCapability) {
    AppCapability["USER_DATA"] = "/api/user_data";
})(AppCapability || (AppCapability = {}));
const CapabilityConfigMap = {
    [CorpusCapability.ANALYZE_QUERY]: {
        description: "query analysis",
    },
    [CorpusCapability.CONJUNCTION_BINDINGS]: {
        description: "conjunction bindings",
    },
    [CorpusCapability.CORPORA]: { description: "corpora" },
    [CorpusCapability.CORPORA_DOCUMENT]: { description: "corpora document" },
    [CorpusCapability.CORPUS_INFO]: { description: "corpus info" },
    [CorpusCapability.CREATE_CONCEPT]: { description: "concept" },
    [CorpusCapability.EXPORT_EVIDENCE]: { description: "evidence" },
    [CorpusCapability.DISCOVER_MULTIHOP_RELATIONSHIP]: {
        description: "discover Multihop Relationship",
    },
    [CorpusCapability.MULTIHOP_DISCOVERY_CONFIG_PATH]: {
        description: "multihop discovery config",
    },
    [CorpusCapability.EXPORT_TRENDS]: { description: "trend export" },
    [CorpusCapability.FEEDBACK]: { description: "feedback" },
    [CorpusCapability.GET_URL_DATA]: { description: "url data" },
    [CorpusCapability.GET_DOMAIN_MODEL]: { description: "domain model" },
    [CorpusCapability.GET_URL_ID]: { description: "url id" },
    [CorpusCapability.LOOKUP_CONCEPTS]: { description: "concepts" },
    [CorpusCapability.LOOKUP_KB_IDS]: { description: " knowledge-base ids" },
    [CorpusCapability.SEARCH_ARGUMENTS]: { description: "argument search results" },
    [CorpusCapability.SEARCH_ARGUMENTS_CLAUSES]: {
        description: "argument clause search results",
    },
    [CorpusCapability.SEARCH_CONCEPTS]: { description: "concept search results" },
    [CorpusCapability.SEARCH_CONCEPTS_MEMBERS]: {
        description: "concept member search results",
    },
    [CorpusCapability.SEARCH_CONCEPTS_REFUTING]: {
        description: "refuting concept search results",
    },
    [CorpusCapability.SEARCH_EVIDENCE]: {
        description: "evidence search results",
        methodVerbs: {
            POST: "retrieving",
        },
    },
    [CorpusCapability.SEARCH_GLOSSARY]: { description: "glossary search results" },
    [CorpusCapability.SEARCH_METADATA]: { description: "metadata search results" },
    [CorpusCapability.SEARCH_RELATIONS]: { description: "relation search results" },
    [CorpusCapability.SEARCH_RELATIONS_REFUTING]: {
        description: "refuting relation search results",
    },
    [CorpusCapability.SUGGEST_CONCEPTS]: { description: "concept suggestions" },
    [CorpusCapability.SUGGEST_EVIDENCE]: { description: "evidence suggestions" },
    [CorpusCapability.SUGGEST_FINDINGS]: { description: "finding suggestions" },
    [CorpusCapability.SUGGEST_QUERIES_RELATED]: {
        description: "related query suggestions",
    },
    [CorpusCapability.SUGGEST_QUERY_NEXT_CONCEPTS]: {
        description: "next concept suggestions",
    },
    [CorpusCapability.SUGGEST_QUERY_NEXT_EVIDENCE]: {
        description: "next evidence suggestions",
    },
    [CorpusCapability.SUGGEST_RELATIONS]: { description: "relation suggestions" },
    [CorpusCapability.SUGGEST_SUMMARY]: { description: "summary suggestions" },
    [CorpusCapability.SUGGEST_ANSWER]: { description: "answer generation" },
    [CorpusCapability.TRENDS]: { description: "trends" },
    [CorpusCapability.TRENDS_GRANULARITIES]: { description: "trend granularities" },
    [CorpusCapability.TRENDS_GROUP_BY_FIELDS]: {
        description: "trend group-by fields",
    },
    [AppCapability.USER_DATA]: {
        description: "session data",
        methodVerbs: {
            POST: "storing",
        },
    },
};
export function getCapabilityConfig(capability) {
    return CapabilityConfigMap[capability];
}
export function getCapabilityIntent(method, capability) {
    let { description } = CapabilityConfigMap[capability];
    if (!description)
        console.log("No intent for endpoint:", capability);
    description = description || "data";
    const defaultMethodVerbs = {
        GET: "retrieving",
        POST: "accessing",
        PUT: "sending",
        DELETE: "deleting",
    };
    const capabilityVerbs = Object.assign(Object.assign({}, defaultMethodVerbs), CapabilityConfigMap[capability].methodVerbs);
    const methodVerb = method.toUpperCase();
    return `${capabilityVerbs[methodVerb]} ${description}`;
}
