import { keyBy } from "lodash";
import React from "react";
export function useValuesAndSelection(remote, selected, mapper, setSelected) {
    const { items, remoteByKey, selectedByName } = React.useMemo(() => {
        const remoteValues = remote.value || [];
        const selectedByName = keyBy(selected, i => i.name);
        const remoteByKey = keyBy(remoteValues, i => i.name);
        return {
            remoteByKey,
            selectedByName,
            items: remoteValues.map(mapper),
        };
    }, [remote.value, selected]);
    const isSelected = React.useCallback((item) => Boolean(selectedByName[item.name]), [selectedByName]);
    const changeSelection = React.useCallback((item, select) => {
        const selection = select
            ? isSelected(item)
                ? selected.map(i => (i.name === item.name ? item : i))
                : selected.concat([item])
            : selected.filter(i => i !== item);
        setSelected(selection, selection.map(item => remoteByKey[item.name]).filter(Boolean));
    }, [selected, setSelected, remoteByKey]);
    return { items, remoteByKey, isSelected, changeSelection };
}
