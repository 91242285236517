var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { loadQueryState } from "../../stateRestoration/loadQueryState";
import { QueryId } from "../../stateRestoration/queryId";
import { EMPTY_QUERY_STATE } from "../../verions/V3_QueryState";
import { useQueryInputTextResetter } from "../useQueryInputText";
import { UNNAMED_SESSION_ID } from "./parts/sessionMetadata";
import { useCurrentSession, useDefaultSession } from "./session";
import { useDeleteSession } from "./useDeleteSession";
import { useInfoTask } from "./useInfoStream";
import { useSaveRemoteSession } from "./useSaveRemoteSession";
import { getLatestQueryId } from "./utils/getLatestQueryId";
import { getRemoteSession } from "./utils/getRemoteSession";
import { initSession } from "./utils/initSession";
import { isUnnamedSession } from "./utils/isUnnamedSession";
import { localDebouncer, remoteDebouncer } from "./utils/sessionDebouncers";
export function useSessionControls() {
    const defaultSession = useDefaultSession();
    const [currentSession, sessionUpdater, resetSession] = useCurrentSession();
    const deleteSession = useDeleteSession();
    const saveRemoteSession = useSaveRemoteSession();
    const resetQueryInputText = useQueryInputTextResetter();
    const infoTask = useInfoTask();
    const setCurrentSession = React.useCallback((session, queryStateUpdater, keepCorpus) => __awaiter(this, void 0, void 0, function* () {
        sessionUpdater(session);
        const id = yield getLatestQueryId(session);
        const queryState = !id ? EMPTY_QUERY_STATE : yield loadQueryState(id);
        queryStateUpdater === null || queryStateUpdater === void 0 ? void 0 : queryStateUpdater(state => {
            const corpus_ids = keepCorpus
                ? state.scope.corpus_ids
                : queryState.scope.corpus_ids;
            return Object.assign(Object.assign({}, queryState), { scope: Object.assign(Object.assign({}, state.scope), { corpus_ids }) });
        });
        QueryId.set(id);
    }), [currentSession, sessionUpdater]);
    const switchSession = React.useCallback((id, name, queryStateUpdater, keepCorpus) => __awaiter(this, void 0, void 0, function* () {
        yield infoTask({ message: `Loading ${name}…`, modal: true }, () => __awaiter(this, void 0, void 0, function* () {
            yield remoteDebouncer.exec({ flush: true });
            const remoteSession = yield getRemoteSession(defaultSession, id);
            const session = currentSession.id === id &&
                remoteSession.timestamp < currentSession.timestamp
                ? currentSession
                : remoteSession;
            //TODO: handle events in state chain
            // sessionEvents.emit({type: "BEFORE_NEW_SESSION", id})
            resetSession();
            setCurrentSession(session, queryStateUpdater, keepCorpus);
        }));
    }), [defaultSession, currentSession, setCurrentSession, infoTask]);
    const setNewSession = React.useCallback((queryStateUpdater) => __awaiter(this, void 0, void 0, function* () {
        //TODO: handle events in state chain
        // sessionEvents.emit({type: "BEFORE_NEW_SESSION", id: UNNAMED_SESSION_ID})
        resetSession();
        yield infoTask({ message: `Creating new Session…`, modal: true }, () => __awaiter(this, void 0, void 0, function* () {
            const newSession = initSession(defaultSession, {
                sessionKey: uuidv4(),
                timestamp: Date.now(),
            });
            yield deleteSession(UNNAMED_SESSION_ID);
            yield localDebouncer.exec({ flush: false });
            yield remoteDebouncer.exec({
                flush: !isUnnamedSession(currentSession.id),
            });
            yield setCurrentSession(newSession, queryStateUpdater, true);
            resetQueryInputText();
        }));
    }), [
        defaultSession,
        currentSession,
        setCurrentSession,
        resetQueryInputText,
        infoTask,
    ]);
    const saveSessionAs = React.useCallback((session, name, queryStateUpdater) => __awaiter(this, void 0, void 0, function* () {
        const id = uuidv4();
        yield saveRemoteSession(initSession(defaultSession, Object.assign(Object.assign({}, session), { name, id: id })));
        yield switchSession(id, name, queryStateUpdater, true);
        return id;
    }), [defaultSession]);
    return {
        setCurrentSession,
        switchSession,
        setNewSession,
        saveSessionAs,
    };
}
