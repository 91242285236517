var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { ControlButton } from "../common/buttons/ControlButton";
import { AlignH, AlignV } from "../common/icons/Icons";
import { useReactFlow } from "reactflow";
import { fitGraph } from "./fitGraph";
export function LayoutGraphButton({ horizontal }) {
    const { fitView } = useReactFlow();
    const [, updateConfig] = useUserConfig();
    return (_jsx(ControlButton, Object.assign({ action: () => __awaiter(this, void 0, void 0, function* () {
            updateConfig({
                horizontalGraphLayout: horizontal || false,
            });
            fitGraph(fitView);
        }) }, { children: horizontal ? _jsx(AlignH, {}) : _jsx(AlignV, {}) })));
}
