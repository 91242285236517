import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { metadataValue } from "../../appState/atomic/queryState/semanticSearch/metadataValue";
import { Truncated } from "../common/text/Truncated";
export function MetadataInfo({ items, truncate, }) {
    return (_jsx("table", Object.assign({ className: "text-tiny" }, { children: _jsx("tbody", { children: items.map((item, idx) => {
                var _a;
                return (_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "align-top p-1 font-semibold" }, { children: _jsx("div", { children: item.name }) })), _jsx("td", Object.assign({ className: "align-top p-1" }, { children: _jsx(Truncated, { className: "text-tiny", text: (_a = metadataValue(item, false)) === null || _a === void 0 ? void 0 : _a.toString(), maxLength: truncate ? 120 : 1024 }, "V" + idx) }))] }, idx));
            }) }) })));
}
