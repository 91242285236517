import { pick } from "lodash";
import React from "react";
import { API } from "../../api/api";
import { useRemoteQuery } from "../../api/useRemoteQuery";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useSummariesDisplayState } from "../../appState/atomic/summaries/useSummariesDisplayState";
export function useSummariesData(evidenceRequestParams, disabled = false) {
    const { updateFoldedSummaryRefs } = useSummariesDisplayState();
    const summariesSupported = useIsFeatureSupported(Feature.TOPIC_SUMMARIZATION);
    const { value, loading } = useRemoteQuery({
        loader: API.suggest_summaries,
        params: summariesSupported &&
            !disabled &&
            evidenceRequestParams &&
            evidenceRequestParams.corpus_filter
            ? Object.assign({}, pick(evidenceRequestParams, [
                "corpus_filter",
                "rank_with_vector_search",
                "count",
                "use_precise_evidence_only",
                "evidence_type",
            ])) : undefined,
        rateLimitMode: "debounce",
        debounceSettings: { delayMs: 600 },
        key: "summaries",
    });
    const summaries = React.useMemo(() => {
        if (!value)
            return [];
        const validSummaries = value.summaries.filter(s => s.evidence_ids.length > 0 && s.references.length > 0);
        updateFoldedSummaryRefs(validSummaries.map(s => s.id));
        return validSummaries;
    }, [value]);
    return { summaries, loading };
}
export function useAnswer(evidenceRequestParams, disabled = false) {
    const answerSupported = useIsFeatureSupported(Feature.ANSWER_GENERATION);
    const { value, loading } = useRemoteQuery({
        loader: API.suggest_answer,
        params: answerSupported &&
            !disabled &&
            evidenceRequestParams &&
            evidenceRequestParams.corpus_filter
            ? Object.assign({}, pick(evidenceRequestParams, [
                "corpus_filter",
                "rank_with_vector_search",
                "count",
                "use_precise_evidence_only",
                "evidence_type",
            ])) : undefined,
        rateLimitMode: "debounce",
        debounceSettings: { delayMs: 600 },
        key: "answer",
    });
    const answer = React.useMemo(() => {
        if (!value || !value.answer || value.answer.references.length === 0)
            return {
                answer: "",
                references: [],
            };
        return value.answer;
    }, [value]);
    return { answer, loading };
}
