import { useAtomValue } from "jotai";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useGetSelectionMenuEntries } from "./getSelectionMenuEntries";
import { showPopupMenu } from "./showPopupMenu";
export function useShowSelectionMenu() {
    const getSelectionMenuEntries = useGetSelectionMenuEntries();
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    return (rect, { text, suggestEvidence, findings, evidence, instance, context, currentContextTitle, structuredQuery, usingHierarchyNav, expandedEvidence, zIndex, }) => {
        showPopupMenu({
            top: rect.top,
            left: rect.left + rect.width / 2,
            zIndex,
            entries: getSelectionMenuEntries({
                corpus_id: (instance === null || instance === void 0 ? void 0 : instance.corpus_id) || corpus_ids[0],
                evidence,
                document_id: instance === null || instance === void 0 ? void 0 : instance.document_id,
                text,
                context,
                suggestEvidence,
                selectedContext: evidence,
                currentContextTitle,
                findings,
                expandedEvidence,
                options: {
                    showNLQueryOption: true,
                    showStructuredQueryOptions: structuredQuery && !usingHierarchyNav,
                },
            }),
        });
    };
}
