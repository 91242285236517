var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { message } from "antd";
import { isEqual } from "lodash";
import { getConceptsFromKBIds } from "../../extensions/KBLinks";
import { DEFAULT_CRC_DIRECTION } from "../atomic/queryState/consts/DEFAULT_CRC_DIRECTION";
import { EMPTY_USER_FRIENDLY_PARAMS } from "../atomic/queryState/consts/EMPTY_USER_FRIENDLY_PARAMS";
import { createRelation } from "../atomic/queryState/semanticSearch/createRelation";
import { CRCDirection } from "../atomic/queryState/types/CRCDirection";
import { validateCorpusIds } from "../atomic/queryState/utils/validateCorpusIds";
import { EMPTY_CORA_STATE } from "../verions/V1_CoraState";
function toParts(field) {
    return field.split(",").filter(t => t.trim() !== "");
}
export function getCoraStateFromUserFriendlyParams(params, corpus_ids, corpora) {
    return __awaiter(this, void 0, void 0, function* () {
        const { concept1, relation, concept2, corpus_id, directed } = Object.assign(Object.assign({}, EMPTY_USER_FRIENDLY_PARAMS), params);
        const [validatedCorpusIds] = validateCorpusIds(toParts(corpus_id), corpus_ids, corpora);
        const [concepts1, unknown1, concepts2, unknown2] = yield getConcepts(validatedCorpusIds);
        if (unknown1.length + unknown2.length > 0) {
            message.error(`Unknown concepts: ${[...unknown1, ...unknown2].join(", ")}`);
            console.warn("Unknown concepts", [...unknown1, ...unknown2]);
        }
        const coraState = Object.assign(Object.assign({}, EMPTY_CORA_STATE), { concepts1,
            concepts2, relations: toParts(relation).map(createRelation), crcDirection: directed === "true"
                ? CRCDirection.C1C2
                : directed === "false"
                    ? CRCDirection.BOTH
                    : DEFAULT_CRC_DIRECTION, corpus_ids: validatedCorpusIds });
        return {
            coraState,
            corpus_ids: validatedCorpusIds,
            hasUrlParams: !isEqual(coraState, EMPTY_CORA_STATE),
        };
        function getConcepts(corpus_ids) {
            return __awaiter(this, void 0, void 0, function* () {
                const concept1Names = toParts(concept1);
                const concept2Names = toParts(concept2);
                const conceptNames = [...concept1Names, ...concept2Names];
                const concepts = yield getConceptsFromKBIds(conceptNames, corpus_ids);
                return [
                    ...getValidConcepts(concept1Names, concepts.slice(0, concept1Names.length)),
                    ...getValidConcepts(concept2Names, concepts.slice(concept1Names.length)),
                ];
                function getValidConcepts(names, concepts) {
                    return concepts.reduce((acc, concept, idx) => {
                        if (concept) {
                            acc[0].push(concept);
                        }
                        else {
                            acc[1].push(names[idx]);
                        }
                        return acc;
                    }, [[], []]);
                }
            });
        }
    });
}
