import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "antd";
import { useSummariesDisplayState } from "../../appState/atomic/summaries/useSummariesDisplayState";
import { SummaryCard } from "./cards/SummaryCard";
export function SummariesList({ summaries }) {
    const { summaryBlockIsFolded, setFoldSummariesBlock } = useSummariesDisplayState();
    if (!summaries.length)
        return null;
    const firstSummary = summaries[0];
    const additionalSummaries = summaries.slice(1);
    return (_jsxs(_Fragment, { children: [_jsx(SummaryCard, { summary: firstSummary, summaryIndex: 0, summaryCount: summaries.length }, 0), summaryBlockIsFolded && additionalSummaries.length > 0 && (_jsx(Button, Object.assign({ className: "mb-4 bg-gray-200 hover:bg-gray-100 cursor-pointer", onClick: () => setFoldSummariesBlock(false) }, { children: _jsxs("span", Object.assign({ className: "font-semibold" }, { children: ["Show ", additionalSummaries.length, " additional topic summaries..."] })) }))), !summaryBlockIsFolded &&
                additionalSummaries.map((s, idx) => (_jsx(SummaryCard, { summary: s, summaryIndex: idx, summaryCount: summaries.length }, idx)))] }));
}
