import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useCurrentSource } from "../../api/useCurrentSource";
import { a_modelLoading } from "../../appState/atomic/domainModel/modelState";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { NL } from "../common/icons/Icons";
import { ModelPicker } from "../constraintFlow/ModelPicker";
import { useActivateModelGraph } from "../constraintFlow/useDMParamsDiagramAddOn";
import { useDomainModelDescriptors } from "../constraintFlow/useDomainModelDescriptors";
export function EmptyEvidencePanel() {
    var _a;
    const activateModelGraph = useActivateModelGraph();
    const setLoading = useSetAtom(a_modelLoading);
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const selected = useCurrentSource(); //TODO: needs update for multiple sources
    const descriptors = useDomainModelDescriptors(corpus_ids);
    const { enableSelectModelOnLanding } = useFeatureFlags();
    return (_jsxs("div", Object.assign({ className: "flex flex-1 flex-col items-center justify-center space-y-2", style: {
            paddingBottom: "10rem",
        } }, { children: [_jsx(NL, { className: "text-gray-600 mb-2", style: { fontSize: "50px" } }), isEmpty(selected) ? (_jsx("span", Object.assign({ className: "select-none" }, { children: "Select a Source above, then ask Cora a question" }))) : (_jsx("span", Object.assign({ className: "select-none" }, { children: "Ask Cora a question above" }))), enableSelectModelOnLanding && ((_a = descriptors.value) === null || _a === void 0 ? void 0 : _a.length) ? (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("span", Object.assign({ className: "select-none mr-2" }, { children: "or start with a domain model" })), _jsx(ModelPicker, { excludeCurrentQuery: true, onLoading: setLoading, onSelect: activateModelGraph })] }))) : null] })));
}
