import { jsx as _jsx } from "react/jsx-runtime";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import React from "react";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useDebouncedEffect } from "../../appState/atomic/session/utils/debouncer";
import { classes } from "../../appState/context/theme/classes";
import { ValidatedInput } from "../common/forms/InputValidation";
import { Search } from "../common/icons/Icons";
import "./EvidenceFilter.less";
export function EvidenceFilter({ disabled, onFocusChange, }) {
    const [searchParams, setSearchParams] = useAtom(queryStateAtoms.searchParams);
    const [filter, setFilter] = React.useState(searchParams.evidenceFilter);
    const [disableResync, setDisableResync] = React.useState(false);
    // resync the filter if the queryState changes externally while this component is mounted
    React.useEffect(() => {
        if (!disableResync && filter !== searchParams.evidenceFilter)
            setFilter(searchParams.evidenceFilter);
    }, [searchParams]);
    // give the user time to type before resyncing
    // without this, sometimes the resync will remove the last few characters if their is a pause in typing
    useDebouncedEffect(() => setDisableResync(false), false, {
        value: filter,
        delay: 1000,
    });
    useDebouncedEffect(evidenceFilter => {
        setSearchParams({ evidenceFilter });
    }, false, { value: filter, delay: 500 });
    const onChange = React.useCallback((s) => {
        setFilter(s);
        setDisableResync(true);
    }, [setFilter, setDisableResync]);
    return (_jsx("div", Object.assign({ className: "EvidenceFilter", style: { minWidth: "300px" } }, { children: _jsx(ValidatedInput, { className: classes("flex-1 p-0 min-h-[34px] w-full text-md"), style: {
                borderWidth: "0 0 1px 0",
                borderStyle: "solid",
                borderColor: "#AAA",
                borderRadius: "0",
            }, bordered: true, prefix: _jsx(Search, { className: "ml-2 neutral-gray-800 text-base" }), value: filter, placeholder: "Search within results...", onChange: onChange, disabled: disabled, onFocus: () => onFocusChange && onFocusChange(true), onBlur: () => onFocusChange && onFocusChange(false), autoFocus: true, clearIcon: _jsx("div", Object.assign({ className: "text-md mr-2 flex items-center text-gray-500" }, { children: _jsx(CloseCircleOutlined, {}) })) }) })));
}
