var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { textPrompt } from "../../components/common/modals/Propmpt";
import { confirmation } from "./confirmation";
export function promptText({ title, placeholder, exists, replace, add, value, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const name = yield textPrompt({ title, placeholder, value });
        if (!name || !name.length)
            return;
        if ((exists === null || exists === void 0 ? void 0 : exists(name)) && replace) {
            if (yield confirmation({ title: `Override ${name}?` })) {
                replace(name);
            }
        }
        else {
            add(name);
        }
    });
}
