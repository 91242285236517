import React from "react";
import { useReactFlow, useStoreApi } from "reactflow";
import { QueryStateEventSource, useQueryStateEventListener, } from "../../appState/atomic/queryState/useQueryStateEventListener";
import { Bounds } from "./Bounds";
import { useDiagramStateUpdater } from "./DiagramState";
import { getBounds } from "./collisions";
import { getViewportBounds, isActive } from "./diagramUtils";
import { calcBounds } from "./geometry";
const SCROLL_DELAY = 1500;
export function useScrollToActive() {
    const queryStateListener = useQueryStateEventListener();
    const flow = useReactFlow();
    const store = useStoreApi();
    const dUpdater = useDiagramStateUpdater();
    React.useEffect(() => queryStateListener(([_, source]) => {
        if (source === QueryStateEventSource.CONSTRAINTS_BAR) {
            const nodes = flow.getNodes().filter(isActive);
            if (!nodes.length)
                return;
            const bounds = calcBounds(nodes, getBounds);
            if (Bounds.contains(getViewportBounds(store.getState()), bounds))
                return;
            flow.fitView({
                duration: SCROLL_DELAY,
                nodes,
                maxZoom: flow.getZoom(),
            });
            dUpdater.commit(SCROLL_DELAY + 200);
        }
    }), [flow]);
}
