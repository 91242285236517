var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { DivButton } from "./DivButton";
export function TinyButton(_a) {
    var { className, size = 16, bgClassName = "bg-gray-100" } = _a, props = __rest(_a, ["className", "size", "bgClassName"]);
    return (_jsx(DivButton, Object.assign({ className: classes("flex justify-center items-center rounded-md border", bgClassName, className), style: { fontSize: size, width: size + 6, height: size + 6 } }, props)));
}
