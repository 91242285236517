import { createRelation, emptyNode, targetIsPane } from "./diagramUtils";
import { CONTEXT, ElementType, upsertEdge } from "./model";
import { clearUnavailable } from "./unavailable";
const NODE_SIZE = {
    [ElementType.CONCEPT]: [200, 35],
    [ElementType.RELATION]: [28, 32],
};
export function createNodeFromSource(flow, dUpdater, event, { top, left }, source) {
    const nes = newNodesAndEdges();
    dUpdater.commit(({ nodes, edges }) => clearUnavailable({
        nodes: [...nodes, ...nes.nodes],
        edges: [...edges, ...nes.edges],
    }));
    function newNodesAndEdges() {
        if (!source || !targetIsPane(event)) {
            return { nodes: [], edges: [] };
        }
        const { x, y } = flow.project({
            x: event.clientX -
                left -
                (flow.getZoom() * NODE_SIZE[ElementType.CONCEPT][0]) / 2,
            y: event.clientY -
                top -
                (flow.getZoom() * NODE_SIZE[ElementType.CONCEPT][1]) / 2,
        });
        const target = emptyNode(ElementType.CONCEPT, { argName: CONTEXT }, { x, y });
        const isRelation = source.type === ElementType.RELATION;
        if (isRelation) {
            return {
                nodes: [target],
                edges: [
                    upsertEdge({
                        source: source.id,
                        target: target.id,
                        type: ElementType.QUALIFIER,
                        argName: CONTEXT,
                    }),
                ],
            };
        }
        else {
            const { node, edges } = createRelation(source, target);
            return {
                nodes: [node, target],
                edges,
            };
        }
    }
}
