import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { hasHighlightsOnlyOutsideOfParse } from "../../appState/atomic/evidence/utils/hasHighlightsOnlyOutsideOfParse";
import { highlightsOnlyOutsideOfParse } from "../../appState/atomic/evidence/utils/highlightsOnlyOutsideOfParse";
import { uniqueHighlightsOnlyOutsideOfParse } from "../../appState/atomic/evidence/utils/uniqueHighlightsOnlyOutsideOfParse";
import { classes } from "../../appState/context/theme/classes";
import { CONCEPT_COLOR_BG, CONCEPT_COLOR_BORDER, } from "../../appState/context/theme/lf";
import { ShareFromSquare } from "../common/icons/Icons";
import { TagsList } from "../common/tags/TagsList";
export function EvidenceHighlights({ evidence, showHighlights, urlOpener, }) {
    const hiddenHighlights = highlightsOnlyOutsideOfParse(evidence);
    const uniqueMatchIdHighlights = uniqueHighlightsOnlyOutsideOfParse(evidence);
    return showHighlights && hasHighlightsOnlyOutsideOfParse(evidence) ? (_jsx("div", Object.assign({ className: classes("flex flex-1 space-x-2 border-b justify-start", "pb-3 mb-2", "border-b border-neutral-200") }, { children: _jsx(TagsList, { className: "w-fit py-0.5 px-1 font-medium", widthClass: "w-[calc(100%-8em)]", items: uniqueMatchIdHighlights, tagContent: item => (_jsxs("div", Object.assign({ className: "flex align-center" }, { children: [_jsx(ShareFromSquare, { className: "mt-1 mr-1" }), item.match_name] }))), tagStyle: () => ({
                backgroundColor: CONCEPT_COLOR_BG,
                borderColor: CONCEPT_COLOR_BORDER,
            }), tagTooltip: item => "View in Document", onMouseDown: item => urlOpener === null || urlOpener === void 0 ? void 0 : urlOpener(evidence, evidence.instances[0], [
                evidence.text,
                ...hiddenHighlights.map(x => x.match_name),
                item.match_name,
            ]) }) }))) : null;
}
