import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useEvidenceFeedback } from "../../appState/atomic/evidence/useEvidenceFeedback";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { useEvidenceSearchContext } from "../../appState/context/evidence/EvidenceSearchContext";
import { VisualStatusMessage } from "../common/status/VisualStatusMessage";
import { OverallFeedback } from "./feedbackControls/OverallFeedback";
export function useEvidenceListPagination(pageSize) {
    const { evidenceSearch: { evidenceAndTotals }, } = useEvidenceSearchContext();
    const { loading, currentPage } = evidenceAndTotals;
    const { resetEvidenceSelection } = useEvidenceSelection();
    const { resetEvidenceFeedback } = useEvidenceFeedback();
    const virtuosoRef = React.useRef(null);
    React.useEffect(() => {
        if (currentPage === 0 && loading) {
            resetEvidenceSelection();
            resetEvidenceFeedback();
            // scrollToIndex(virtuosoRef.current, 0)
        }
        if (!loading && currentPage > 0) {
            // scrollToIndex(virtuosoRef.current, currentPage * pageSize - 1)
        }
    }, [loading, currentPage, pageSize]);
    const paginationFooter = (_jsxs("div", Object.assign({ className: "mt-1 flex flex-col items-stretch pb-2" }, { children: [_jsx(OverallFeedback, {}), _jsxs("div", Object.assign({ className: "mt-2 flex", style: { paddingBottom: 1 } }, { children: [_jsx("div", { className: "flex border-b border-neutral-500 flex-1 h-1/2" }), _jsx("div", Object.assign({ className: "flex" }, { children: !evidenceAndTotals.lastPage && evidenceAndTotals.loading && (_jsx(VisualStatusMessage, { status: "loading", message: "Loading more...", className: "text-tiny" })) }))] }))] })));
    return { paginationFooter, virtuosoRef };
}
