var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Form, Modal } from "antd";
import FormBuilder from "antd-form-builder";
import { useAtomValue } from "jotai";
import { useCallback, useMemo, useState } from "react";
import { API } from "../../api/api";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { pushError } from "../../appState/beacon/errorStream";
import { Export } from "../common/icons/Icons";
function getEvidenceData(downloadRequest, setCurrentlyDownloading) {
    return __awaiter(this, void 0, void 0, function* () {
        console.info("Download Config:", downloadRequest);
        setCurrentlyDownloading(true);
        const res = yield API.exportEvidence(downloadRequest);
        setCurrentlyDownloading(false);
        if (res) {
            console.error("Error Downloading Evidence: ", res);
            if (res instanceof Error) {
                pushError({ error: { status: 401, message: res.message } });
            }
        }
    });
}
const META = [
    {
        key: "filename",
        label: "File Name",
        widget: "input",
    },
];
export function useExportEvidenceAddOn({ corpus_filter, index, }) {
    const { aperture } = useAtomValue(queryStateAtoms.scope);
    const { evidenceFilter } = useAtomValue(queryStateAtoms.searchParams);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [currentlyDownloading, setCurrentlyDownloading] = useState(false);
    const supportsExportEvidence = useIsFeatureSupported(Feature.EXPORT_EVIDENCE);
    const showModal = useCallback(() => {
        setVisible(true);
    }, [setVisible]);
    const hideModal = useCallback(() => setVisible(false), [setVisible]);
    const requestParams = useMemo(() => {
        return { corpus_filter, search_text: evidenceFilter, aperture };
    }, [aperture, evidenceFilter, corpus_filter]);
    const handleSubmit = useCallback(() => {
        const { filename } = form.getFieldsValue();
        if (!filename)
            return;
        if (requestParams) {
            const request = Object.assign(Object.assign({}, requestParams), { link_to_application: window.location.href });
            getEvidenceData({ filename, exportParams: request }, setCurrentlyDownloading);
        }
        hideModal();
    }, [hideModal, requestParams]);
    return !supportsExportEvidence
        ? undefined
        : {
            name: "export-evidence",
            index,
            widget() {
                return (supportsExportEvidence && (_jsx(Modal, Object.assign({ width: 700, title: "Export Evidence", closable: false, open: visible, destroyOnClose: true, onCancel: hideModal, okText: "Ok", footer: [
                        _jsx(Button, Object.assign({ type: "primary", onClick: hideModal }, { children: "Cancel" }), "cancel"),
                        _jsx(Button, Object.assign({ type: "primary", onClick: handleSubmit }, { children: "Ok" }), "submit"),
                    ] }, { children: _jsx(Form, Object.assign({ form: form, onFinish: handleSubmit }, { children: _jsx(FormBuilder, { meta: META, form: form }) })) }))));
            },
            selector(key) {
                if (!supportsExportEvidence)
                    return null;
                //TODO: Add Spin to the icon
                /*
      
                    <>
                      {currentlyDownloading && (
                        <Spin size="small" className="py-auto ml-1" />
                      )}
                    </>
                    */
                return !supportsExportEvidence
                    ? null
                    : {
                        key,
                        icon: _jsx(Export, {}),
                        tooltip: "Export your evidence",
                        onSelect: showModal,
                        disabled: currentlyDownloading,
                        selected: false,
                    };
            },
        };
}
