import { getSize } from "../../utils/generic/getSize";
import { mapEntries, replaceKeys } from "../../utils/generic/objects";
import { createClusterId } from "../../utils/identity/createClusterId";
import { createRelationId } from "../../utils/identity/createRelationId";
import { uuidv4 } from "../../utils/identity/uuidv4";
import { ANY_MARKER } from "../atomic/queryState/consts/ANY";
import { getEmptyDmParams } from "../atomic/queryState/consts/EMPTY_QUERY_STATE";
import { CRCDirection } from "../atomic/queryState/types/CRCDirection";
import { getIdReplacements } from "../atomic/queryState/utils/getIdReplacements";
import { getUpdatedConceptGroup } from "./getUpdatedConceptGroup";
//TODO: probably need to rewrite this
function replaceRefs(params, replacements) {
    if (!params.constraints.length || !getSize(replacements))
        return params;
    return {
        concepts: replaceKeys(params.concepts, replacements),
        relations: replaceKeys(params.relations, replacements),
        clauses: replaceKeys(params.clauses, replacements),
        constraints: params.constraints.map(c => ({
            id: replacements[c.id] || c.id,
            sources: c.sources.map(id => replacements[id] || id),
            targets: c.targets.map(id => replacements[id] || id),
            relation: replaceId(c.relation),
            context: c.context.map(id => replacements[id] || id),
            qualifiers: mapEntries(c.qualifiers, ([k, v]) => [
                k,
                v === ANY_MARKER ? v : replacements[v] || v,
            ]),
            text: c.text,
            is_directed: c.is_directed,
        })),
    };
    function replaceId(id) {
        return (id && replacements[id]) || id;
    }
}
export function queryParamsToDMParams({ concepts1, concepts1Override, concepts2, concepts2Override, contextConcepts: ctxConcepts, contextConceptsOverride: ctxConceptsOverride, relations: rels, argClauses, required_arguments, crcDirection, text, }, constraintId, originalParams) {
    constraintId = constraintId || uuidv4();
    const overrides = {};
    const [sConcepts, sOverrides, tConcepts, tOverrides] = crcDirection === CRCDirection.C2C1
        ? [concepts2, concepts2Override, concepts1, concepts1Override]
        : [concepts1, concepts1Override, concepts2, concepts2Override];
    const params = getEmptyDmParams();
    const [sources, sourceConcepts, sourceOverrides] = getUpdatedConceptGroup(sConcepts, sOverrides);
    const [targets, targetConcepts, targetOverrides] = getUpdatedConceptGroup(tConcepts, tOverrides);
    const [context, contextConcepts, contextOverrides] = getUpdatedConceptGroup(ctxConcepts, ctxConceptsOverride);
    const relationSets = (rels || []).map(r => {
        const id = createRelationId(r);
        return [id, r];
    });
    const relations = relationSets.map(([id]) => id);
    const relation = ""; // listToDelimited(relations)
    const relationClusters = Object.fromEntries(relationSets);
    if (sources.length ||
        targets.length ||
        relation ||
        getSize(contextConcepts) ||
        getSize(argClauses) ||
        getSize(required_arguments)) {
        if (relation)
            params.relations = Object.assign(Object.assign({}, params.relations), relationClusters);
        //TODO: doesn't seem right
        params.concepts = Object.assign(Object.assign(Object.assign(Object.assign({}, params.concepts), sourceConcepts), targetConcepts), contextConcepts);
        overrides.sources = sourceOverrides;
        overrides.targets = targetOverrides;
        overrides.context = contextOverrides;
        const qualifiers = qualifiersFromArgClauses();
        const constraint = {
            id: constraintId,
            sources,
            relation,
            targets,
            context,
            is_directed: crcDirection !== CRCDirection.BOTH,
            qualifiers: Object.assign(Object.assign({}, qualifiers), Object.fromEntries(required_arguments.map(argName => [argName, ANY_MARKER]))),
            text,
        };
        params.constraints.push(constraint);
    }
    const replacements = originalParams
        ? getIdReplacements(params, originalParams, constraintId)
        : {};
    return [
        replaceRefs(params, replacements),
        replaceKeys(overrides, replacements),
        replacements,
    ];
    function qualifiersFromArgClauses() {
        return mapEntries(argClauses, ([argName, clusters]) => {
            const ids = clusters.map(cluster => {
                const id = createClusterId(cluster);
                params.clauses[id] = cluster;
                return id;
            });
            return [argName, ""]; // listToDelimited(ids)
        });
    }
}
