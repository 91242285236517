var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { Tooltip } from "../common/popups/Tooltip";
import { EvidenceText } from "./EvidenceText";
export function ExtendedEvidenceText(_a) {
    var { showSurrounding, prefix } = _a, props = __rest(_a, ["showSurrounding", "prefix"]);
    const [{ autoExpandEvidence }] = useUserConfig();
    const [expanded, setExpanded] = React.useState(false);
    React.useEffect(() => {
        if (autoExpandEvidence)
            setExpanded(true);
    }, [autoExpandEvidence]);
    const { evidence: { context, missing_query_content, text }, } = props;
    const { enableMissingConcepts } = useFeatureFlags();
    const missingTerms = enableMissingConcepts
        ? [
            ...((missing_query_content === null || missing_query_content === void 0 ? void 0 : missing_query_content.missing_query_concepts.map(i => i.name)) ||
                []),
            ...((missing_query_content === null || missing_query_content === void 0 ? void 0 : missing_query_content.missing_query_relations.map(i => i.name)) ||
                []),
        ]
        : [];
    const beforeText = (context === null || context === void 0 ? void 0 : context.before) || "";
    const afterText = (context === null || context === void 0 ? void 0 : context.after) || "";
    const [truncatedBefore, truncatedAfter, showBefore, showAfter, maxLengthGivenContext,] = React.useMemo(() => {
        if (!props.truncationThreshold)
            return [
                false,
                false,
                showSurrounding,
                showSurrounding,
                props.truncationThreshold,
            ];
        const beforeLength = (showSurrounding && beforeText.length) || 0;
        const afterLength = (showSurrounding && afterText.length) || 0;
        const totalLength = beforeLength + text.length + afterLength;
        const truncPercent = (totalLength - props.truncationThreshold) / totalLength;
        if (truncPercent < 0.2)
            return [false, false, showSurrounding, showSurrounding, totalLength];
        const truncatedBefore = beforeLength > props.truncationThreshold / 2 ||
            beforeLength + text.length > props.truncationThreshold;
        const showBefore = showSurrounding && (expanded || !truncatedBefore);
        const remainingLength = showBefore
            ? props.truncationThreshold - beforeLength
            : props.truncationThreshold;
        const truncatedAfter = afterLength + text.length > remainingLength;
        const showAfter = showSurrounding && (expanded || !truncatedAfter);
        return [
            truncatedBefore,
            truncatedAfter,
            showBefore,
            showAfter,
            remainingLength,
        ];
    }, [
        expanded,
        showSurrounding,
        props.truncationThreshold,
        beforeText,
        afterText,
        text.length,
    ]);
    const truncated = truncatedBefore || truncatedAfter;
    return (_jsxs("div", Object.assign({ className: "px-4" }, { children: [_jsxs("span", { children: [prefix, beforeText && showBefore && (_jsx("span", Object.assign({ className: "opacity-60 text-base" }, { children: beforeText + " " }))), truncatedBefore && !expanded && _jsx("span", { children: "..." }), _jsx(EvidenceText, Object.assign({}, props, { truncationThreshold: expanded ? undefined : maxLengthGivenContext })), truncatedAfter && !expanded && _jsx("span", { children: "..." }), afterText && showAfter && (_jsx("span", Object.assign({ className: "opacity-60 text-base" }, { children: " " + afterText })))] }), !autoExpandEvidence && truncated && (_jsx(BorderlessButton, Object.assign({ className: "text-sm text-primary select-none", onClick: () => setExpanded(!expanded) }, { children: _jsx("span", { children: expanded ? "show less" : "show more" }) }))), !!missingTerms.length && (_jsx("div", Object.assign({ className: "flex flex-col items-start mt-1" }, { children: _jsx(Tooltip, Object.assign({ placement: "right-start", content: "This piece of evidence only partially matches your query." }, { children: _jsxs("div", Object.assign({ className: "mt-1", style: { opacity: 0.8 } }, { children: [_jsx("span", { children: "Missing:" }), missingTerms.map((m, idx) => (_jsxs("span", { children: [idx ? ", " : " ", _jsx("span", Object.assign({ className: "line-through" }, { children: m }))] }, idx)))] })) })) })))] })));
}
