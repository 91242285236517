var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Spin } from "antd";
import React from "react";
import { useRemoteQuery } from "../../api/useRemoteQuery";
import { MenuEntry } from "./MenuEntry";
export function createRemoteMenuEntry(props) {
    return {
        icon: _jsx(Spin, { size: "small" }),
        renderer: (rowProps, key) => (_jsx(RemoteMenuRow, Object.assign({}, rowProps, props), key)),
    };
}
function RemoteMenuRow(_a) {
    var { icon, loader, params, caption, action, onSelect } = _a, rowProps = __rest(_a, ["icon", "loader", "params", "caption", "action", "onSelect"]);
    const { value, loading } = useRemoteQuery({
        loader,
        params,
    });
    return (_jsx(MenuEntry, { rowProps: rowProps, entry: {
            disabled: !value,
            icon: loading ? _jsx(Spin, { size: "small" }) : icon,
            caption,
            action: !value
                ? undefined
                : () => {
                    action(value);
                    onSelect === null || onSelect === void 0 ? void 0 : onSelect();
                },
        } }));
}
