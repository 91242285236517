import { deserializeSession } from "./deserializeSession";
import { getCurrentSessionKey } from "./getCurrentSessionKey";
import { initSession } from "./initSession";
export function getCurrentSessionFromLocalStorage(defaultSession) {
    try {
        const legacySessionKeys = [
            getCurrentSessionKey(false, true),
            getCurrentSessionKey(true, true),
        ];
        const currentSessionKey = getCurrentSessionKey();
        const legacySessions = legacySessionKeys.map(key => [key, window.localStorage.getItem(key)]);
        const legacySession = legacySessions.find(([_, json]) => json !== null);
        const newSessionJson = window.localStorage.getItem(currentSessionKey);
        if (legacySession) {
            // cleanup legacy sessions
            if (!newSessionJson) {
                console.log("Migrating legacy session (from,to)", legacySession[0], currentSessionKey);
                window.localStorage.setItem(currentSessionKey, legacySession[1]);
            }
            legacySessions.forEach(([key, json]) => {
                if (json) {
                    console.log("Removing legacy session", key);
                    window.localStorage.removeItem(key);
                }
            });
        }
        const json = newSessionJson || (legacySession === null || legacySession === void 0 ? void 0 : legacySession[1]);
        return json
            ? initSession(defaultSession, deserializeSession(JSON.parse(json)))
            : null;
    }
    catch (e) {
        console.warn("Error loading session from local storage");
        console.error(e);
        return null;
    }
}
