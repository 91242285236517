var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import App from "../../../../../App";
import { Loading } from "../../../../components/common/transitions/Loading";
import { ORG_LANDING_PAGES } from "../OrgLandingPage";
import { setSessionInfo } from "../utils/authInfo";
function Auth0AppWrapper() {
    const [isLoading, setIsLoading] = React.useState(true);
    const { getAccessTokenSilently, user, isAuthenticated, logout } = useAuth0();
    React.useEffect(() => {
        ;
        (() => __awaiter(this, void 0, void 0, function* () {
            try {
                yield getAccessTokenSilently();
                setSessionInfo(getAccessTokenSilently, false, {
                    userId: user.preferred_username,
                    userName: user.name,
                    orgId: user.org_id,
                }, () => {
                    const olpConfig = ORG_LANDING_PAGES.find(olp => olp.org_id == user.org_id);
                    const returnTo = window.location.origin +
                        (olpConfig
                            ? `?redirect=${encodeURIComponent(olpConfig.path)}`
                            : "");
                    logout({ logoutParams: { returnTo } });
                });
            }
            catch (e) {
                console.error(e);
            }
            finally {
                setIsLoading(false);
            }
        }))();
    }, []);
    if (isLoading) {
        return _jsx(Loading, { loading: true });
    }
    return (_jsx(_Fragment, { children: _jsx(App, {}) }));
}
export const Auth0AuthWrapper = withAuthenticationRequired(Auth0AppWrapper, {
    onRedirecting: () => {
        console.log("redirecting for auth0 login");
        return _jsx(Loading, { loading: true });
    },
});
