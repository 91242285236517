var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSetAtom } from "jotai";
import { API } from "../../../api/api";
import { a_directory } from "./useSessionsDirectory";
export function useDeleteSession() {
    const setDirectory = useSetAtom(a_directory);
    return (id) => __awaiter(this, void 0, void 0, function* () {
        //TODO: handle events in state chain
        // sessionEvents.emit({type: "BEFORE_DELETE", id})
        yield API.deleteSession(id);
        setDirectory(info => info.filter(s => s.id !== id));
    });
}
