import Lazy from "lazy.js";
function flatten(filters) {
    return Lazy(filters)
        .filter(Boolean)
        .map(filter => {
        if (typeof filter === "function")
            return [filter];
        if (Array.isArray(filter))
            return flatten(filter);
        return flatten(Object.values(filter));
    })
        .flatten()
        .toArray();
}
export function NOT(filter) {
    return (v) => !filter(v);
}
export function TRUE(v) {
    return true;
}
function FALSE(v) {
    return false;
}
export function AND(...filters) {
    const fs = flatten(filters);
    if (!fs.length)
        return TRUE;
    if (fs.length === 1)
        return fs[0];
    return (v) => {
        for (const filter of fs) {
            if (!filter(v))
                return false;
        }
        return true;
    };
}
export function ALL(filterItems) {
    return (items) => Lazy(filterItems).every(item => items.indexOf(item) >= 0);
}
export function EXACTLY(filterItems) {
    const all = ALL(filterItems);
    return (items) => {
        return items.length === filterItems.length && all(items);
    };
}
export function SOME(filterItems) {
    return (items) => Lazy(filterItems).some(tag => items.indexOf(tag) >= 0);
}
export function filterItems(items, filter) {
    return !filter || filter === TRUE
        ? items
        : filter === FALSE
            ? []
            : items.filter(filter);
}
export function includesFilter(text, filter) {
    filter = filter === null || filter === void 0 ? void 0 : filter.trim().toLowerCase();
    return !(filter === null || filter === void 0 ? void 0 : filter.length) || text.toLowerCase().trim().includes(filter);
}
export function wordMatches(filterText, includes = true) {
    filterText = filterText === null || filterText === void 0 ? void 0 : filterText.trim().toLowerCase();
    if (!(filterText === null || filterText === void 0 ? void 0 : filterText.length))
        return TRUE;
    return text => {
        text = text.toLowerCase();
        return (!(filterText === null || filterText === void 0 ? void 0 : filterText.length) ||
            !!text
                .split(/\s+/g)
                .find(part => includes ? part.includes(filterText) : part.startsWith(filterText)));
    };
}
