import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
import AutoAdjustingTextarea from "./AutoAdjustingTextarea";
export function InPlaceInput({ className, mode, value, isArea, onChange, }) {
    const [localValue, setLocalValue] = React.useState(value);
    const textAreaRef = React.useRef(null);
    React.useEffect(() => {
        setLocalValue(value);
    }, [value]);
    React.useEffect(() => { }, [mode, textAreaRef]);
    if (mode === "view") {
        return (_jsx("span", Object.assign({ className: classes(className, "w-full border-box border"), style: {
                whiteSpace: isArea ? "pre-wrap" : undefined,
                borderColor: "transparent",
            } }, { children: value })));
    }
    if (mode === "edit") {
        if (!isArea) {
            return (_jsx("input", { className: classes(className, "w-full border-box border border-secondary"), value: localValue, onChange: e => {
                    setLocalValue(e.target.value);
                    onChange(e.target.value);
                } }));
        }
        else {
            return (_jsx(AutoAdjustingTextarea, { className: className, value: localValue, onChange: text => {
                    setLocalValue(text);
                    onChange(text);
                    if (textAreaRef.current) {
                    }
                } }));
        }
    }
    return null;
}
