import { EMPTY_ACTIVE_CONSTRAINT_ID, EMPTY_EVIDENCE_SEARCH_PARAMS, EMPTY_MODEL_BUILDER_STATE, EMPTY_MODEL_ID, EMPTY_SCOPE, } from "../atomic/queryState/consts/EMPTY_QUERY_STATE";
import { EMPTY_CONSTRAINT_MODEL_CONFIG } from "../atomic/queryState/types/ConstraintModelConfig";
import { EMPTY_CONSTRAINT_MODEL_DATA } from "../atomic/queryState/types/ConstraintModelData";
import { EMPTY_CONSTRAINT_MODEL_SOLUTION } from "../atomic/queryState/types/ConstraintModelSolution";
import { EMPTY_CONSTRAINT_MODEL_STATE } from "../atomic/queryState/types/ConstraintModelState";
export const EMPTY_MULTIHOP_CONFIG = {
    discoveryStrategy: "NONE",
};
export const EMPTY_CONSTRAINT_MODEL = Object.assign(Object.assign(Object.assign({}, EMPTY_MODEL_ID), EMPTY_ACTIVE_CONSTRAINT_ID), { data: EMPTY_CONSTRAINT_MODEL_DATA, state: EMPTY_CONSTRAINT_MODEL_STATE, config: EMPTY_CONSTRAINT_MODEL_CONFIG, solution: EMPTY_CONSTRAINT_MODEL_SOLUTION });
export const EMPTY_QUERY_STATE = {
    scope: EMPTY_SCOPE,
    searchParams: EMPTY_EVIDENCE_SEARCH_PARAMS,
    constraintModel: EMPTY_CONSTRAINT_MODEL,
    modelBuilderState: EMPTY_MODEL_BUILDER_STATE,
    multihopConfig: EMPTY_MULTIHOP_CONFIG,
};
