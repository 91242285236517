var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FeedbackWidget } from "../../../../utils/feedback/FeedbackWidget";
import { sendRefutingFeedback } from "../../../../utils/feedback/sendRefutingFeedback";
export function getItemRenderer(_a) {
    var { item, nameGetter, feedback, action } = _a, refutingSelectionProps = __rest(_a, ["item", "nameGetter", "feedback", "action"]);
    return (_jsxs("div", Object.assign({ className: "flex w-full py-1" }, { children: [_jsx("div", Object.assign({ className: "flex flex-1 items-center", style: { minWidth: 100 }, onClick: action }, { children: nameGetter(item) })), _jsx(FeedbackWidget, { item: Object.assign({ id: nameGetter(item) }, item), expanded: false, sendFeedbackFn: sendRefutingFeedback(Object.assign({ nameGetter: item => nameGetter(item) }, refutingSelectionProps)), feedback: feedback })] })));
}
