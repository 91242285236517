import { useAtomValue } from "jotai";
import { API } from "../../../api/api";
import { EvidenceType } from "../../../api/types/evidenceTypes";
import { usePaginatedRemoteQuery } from "../../../api/usePaginatedRemoteQuery";
import { useDebouncedValue } from "../../../utils/lifecycle/useDebouncedValue";
import { useMemoIfEqual } from "../../../utils/lifecycle/useMemoIfEqual";
import { useFeatureFlags } from "../../atomic/featureFlags/useFeatureFlags";
import { a_constraintModelUtil, a_corpusFilterBuilder, } from "../../atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../atomic/queryState/constraintModel/constraints";
import { queryStateAtoms } from "../../atomic/queryState/queryStateAtoms";
import { validateTextFilter } from "../../atomic/queryState/semanticSearch/validateTextFilter";
import { usePreciseEvidenceOnly } from "../../atomic/queryState/usePreciseEvidenceOnly";
import { useUserConfig } from "../../atomic/session/parts/userConfig";
export function useEvidence(disabled) {
    const constraint = useAtomValue(a_activeConstraint);
    const { refuting, evidenceFilter = "", sortFieldId, sortOrder, } = useAtomValue(queryStateAtoms.searchParams);
    const { config: { refutingConfiguration }, } = useAtomValue(queryStateAtoms.constraintModel);
    const { refuting_concepts = null, refuting_relations = null } = refutingConfiguration[constraint.id] || {};
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const noSelection = modelUtil.isConstraintEmpty(constraint);
    const search_text = validateTextFilter(useDebouncedValue(evidenceFilter));
    const noResults = false; //TODO: used to be determined by lack of solutions
    const { disableVectorSearch } = useFeatureFlags();
    const [{ rankWithVectorSearch, defaultEvidencePageSize }] = useUserConfig();
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const use_precise_evidence_only = usePreciseEvidenceOnly();
    const corpus_filter = corpusFilterBuilder
        .fullFilter(undefined, undefined, undefined, undefined, refuting_concepts, refuting_relations)
        .toCorpusFilter();
    const evidenceRequestParams = useMemoIfEqual(disabled || noSelection || noResults
        ? undefined
        : Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ corpus_filter, count: defaultEvidencePageSize }, (search_text.length && { search_text })), { evidence_type: refuting
                ? EvidenceType.REFUTING
                : EvidenceType.SUPPORTING }), (sortFieldId && { sort_field: sortFieldId })), (sortFieldId && sortOrder && { sort_order: sortOrder })), { rank_with_vector_search: !disableVectorSearch && rankWithVectorSearch, use_precise_evidence_only }));
    const evidenceAndTotals = usePaginatedRemoteQuery({
        loader: API.searchEvidence,
        params: evidenceRequestParams,
        consolidatePages(pages) {
            return pages.reduce((acc, page) => {
                acc.evidence.push(...page.evidence);
                acc.total_count = page.total_count;
                acc.is_lower_bound = page.is_lower_bound;
                return acc;
            }, {
                evidence: [],
                is_lower_bound: false,
                total_count: 0,
            });
        },
        delayMs: 400,
    });
    return {
        pageSize: defaultEvidencePageSize,
        evidenceAndTotals,
        noSelection,
        corpus_filter,
        defaultEvidencePageSize,
        evidenceRequestParams,
    };
}
