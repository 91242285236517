var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { EvidenceType, } from "../../api/types/evidenceTypes";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { a_addConceptToNode, a_addContextNodeId, a_resetConceptNodes, } from "../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { CONTEXT_CAPTION } from "../../appState/atomic/queryState/consts/CONTEXT_CAPTION";
import { SOURCE_CAPTION } from "../../appState/atomic/queryState/consts/SOURCE_CAPTION";
import { TARGET_CAPTION } from "../../appState/atomic/queryState/consts/TARGET_CAPTION";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { getConceptFromText } from "../../appState/atomic/queryState/utils/getConceptFromText";
import { getCurrentSource } from "../../appState/atomic/queryState/utils/getCurrentSource";
import { useCurrentSession, } from "../../appState/atomic/session/session";
import { getContextUrl, upsertFinding, useCreateNewFinding, } from "../../appState/context/findings/findings";
import { addGlossaryEvidenceSearch, createMenuEntriesForText, } from "../../extensions/KBLinks";
import { uuidv4 } from "../../utils/identity/uuidv4";
import { Menu } from "../actions/Menu";
import { createRemoteMenuEntry } from "../actions/createRemoteMenuEntry";
import { Claim, Glossary, Plus, RelatedSearch } from "../common/icons/Icons";
import { showNotification } from "../common/status/notifications";
import { showAttachEvidenceToFindings } from "../modals/AttachEvidenceToFindings";
import { isValidQuestion } from "../queryAnalysis/isValidQuestion";
import { postQuestion } from "../queryAnalysis/postQuestion";
function getSpanFromTextSelection(originalText, text, offset = 0) {
    const start = originalText.indexOf(text) + offset;
    const end = start + text.length;
    return { start, end };
}
export function useGetSelectionMenuEntries() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const corpora = useGlobalInfo().corporaById;
    const [, sessionUpdater] = useCurrentSession();
    const constraint = useAtomValue(a_activeConstraint);
    const resetConceptNodes = useSetAtom(a_resetConceptNodes);
    const addConceptToNode = useSetAtom(a_addConceptToNode);
    const clearContextNodes = useSetAtom(a_resetConceptNodes);
    const addContextNodeId = useSetAtom(a_addContextNodeId);
    const createNewFinding = useCreateNewFinding();
    const glossarySupported = useIsFeatureSupported(Feature.GLOSSARY);
    return ({ corpus_id, evidence, document_id, text, suggestEvidence, findings, selectedContext, currentContextTitle, options = {}, }) => {
        var _a, _b, _c, _d;
        const findingEntries = sessionUpdater
            ? createFindingMenuEntries(sessionUpdater)
            : [];
        const crcEntries = createCRCEntries();
        const selected = getCurrentSource(corpus_ids, corpora);
        const sentence_text = [
            ((_a = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _a === void 0 ? void 0 : _a.before) || "",
            (evidence === null || evidence === void 0 ? void 0 : evidence.text) || "",
            ((_b = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _b === void 0 ? void 0 : _b.after) || "",
        ]
            .filter(x => x)
            .join(" ");
        const evidenceSpan = selected && text.trim().length && evidence
            ? getSpanFromTextSelection(evidence === null || evidence === void 0 ? void 0 : evidence.text, text, ((_c = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _c === void 0 ? void 0 : _c.before) ? ((_d = evidence === null || evidence === void 0 ? void 0 : evidence.context) === null || _d === void 0 ? void 0 : _d.before.length) + 1 : 0 // can't forget the space we'd have to add
            )
            : undefined;
        let entries = [
            ...findingEntries,
            findingEntries.length && crcEntries.length && Menu.SEPARATOR,
            ...crcEntries,
            findingEntries.length + crcEntries.length && Menu.SEPARATOR,
        ];
        if (glossarySupported && evidence && evidenceSpan) {
            entries = addGlossaryEvidenceSearch(corpus_ids, entries, {
                evidence_id: evidence === null || evidence === void 0 ? void 0 : evidence.id,
                span: evidenceSpan,
            }, corpora);
        }
        entries.push(createMenuEntriesForText(text, corpus_ids));
        return entries;
        function loader(text) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield getConceptFromText({ text, corpus_ids });
            });
        }
        function createCRCEntries() {
            const crcEntries = [];
            if (options.showNLQueryOption) {
                crcEntries.push({
                    caption: "Use as next natural language query",
                    disabled: !isValidQuestion(text) || text.trim().length > 100,
                    icon: _jsx(RelatedSearch, {}),
                    action() {
                        postQuestion(text);
                    },
                });
            }
            if (options.showStructuredQueryOptions) {
                crcEntries.push(...[
                    createRemoteMenuEntry({
                        caption: `Use as ${SOURCE_CAPTION}`,
                        icon: _jsx(Glossary, {}),
                        params: text,
                        loader,
                        action(concept) {
                            if (!concept)
                                return;
                            resetConceptNodes([constraint.sourceNodeId]);
                            addConceptToNode(constraint.sourceNodeId, concept);
                        },
                    }),
                    createRemoteMenuEntry({
                        caption: `Use as ${TARGET_CAPTION}`,
                        icon: _jsx(Glossary, {}),
                        params: text,
                        loader,
                        action(concept) {
                            if (!concept)
                                return;
                            resetConceptNodes([constraint.targetNodeId]);
                            addConceptToNode(constraint.targetNodeId, concept);
                        },
                    }),
                    createRemoteMenuEntry({
                        caption: `Use as ${CONTEXT_CAPTION}`,
                        icon: _jsx(Glossary, {}),
                        params: text,
                        loader,
                        action(concept) {
                            if (!concept)
                                return;
                            resetConceptNodes(constraint.contextNodeIds);
                            const nodeId = uuidv4(); // context gets a new node id
                            addContextNodeId(nodeId);
                            addConceptToNode(nodeId, concept);
                        },
                    }),
                ]);
            }
            return crcEntries;
        }
        function createFindingMenuEntries(sessionUpdater) {
            if (!selectedContext || !findings || !document_id)
                return [];
            const url = getContextUrl(selectedContext);
            const instances = url
                ? [
                    {
                        url,
                        external_url: url,
                        title: url,
                        id: url,
                        corpus_id,
                        document_id,
                    },
                ]
                : [];
            return [
                {
                    caption: "Add To My Findings",
                    action() {
                        return __awaiter(this, void 0, void 0, function* () {
                            const finding = yield createNewFinding({
                                selectedText: text,
                                context: selectedContext,
                                contextTitle: currentContextTitle,
                                evidence: [
                                    {
                                        text,
                                        id: uuidv4(),
                                        instances,
                                        evidence_type: EvidenceType.SUPPORTING,
                                        argument_spans: [],
                                        highlight_spans: [],
                                        is_precise: true,
                                    },
                                ],
                            });
                            suggestEvidence(finding);
                            sessionUpdater(({ findings }) => ({
                                findings: upsertFinding(findings, finding),
                            }));
                            showNotification("Finding Added");
                        });
                    },
                    icon: _jsx(Plus, {}),
                },
                Menu.SEPARATOR,
                {
                    caption: "Add Evidence to Finding",
                    disabled: !findings.length,
                    action() {
                        showAttachEvidenceToFindings({
                            evidence: [
                                {
                                    text,
                                    id: uuidv4(),
                                    instances,
                                    evidence_type: EvidenceType.SUPPORTING,
                                    argument_spans: [],
                                    highlight_spans: [],
                                    is_precise: true,
                                },
                            ],
                        });
                    },
                    icon: _jsx(Claim, {}),
                },
            ];
        }
    };
}
