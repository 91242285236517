import { jsx as _jsx } from "react/jsx-runtime";
import { Tabs } from "antd";
import { QueryTextRenderer } from "../common/text/QueryTextRenderer";
import "./ActiveConstraintBar.less";
import { useAtom, useAtomValue } from "jotai";
import { pick } from "lodash";
import { a_constraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraintId } from "../../appState/atomic/queryState/constraintModel/modelState";
import { EMPTY_CONSTRAINT_ID } from "../../appState/atomic/queryState/consts/EMPTY_CONSTRAINT_ID";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useQueryState } from "../../appState/atomic/queryState/useQueryState";
import { QueryStateEventSource } from "../../appState/atomic/queryState/useQueryStateEventListener";
import { CRC_ONLY_PARTS } from "../queryRenderer/CRC_ONLY_PARTS";
import { constraintToQueryParts } from "../queryRenderer/constraintToQueryParts";
import "./ConstraintsBar.less";
function getEmptyParamsQuestion({ constraints }) {
    var _a;
    return ((_a = constraints[0]) === null || _a === void 0 ? void 0 : _a.id) === EMPTY_CONSTRAINT_ID
        ? constraints[0].text
        : undefined;
}
export function ConstraintsBar() {
    var _a;
    const [activeConstraintId, setActiveConstraintId] = useAtom(a_activeConstraintId);
    const model = useAtomValue(queryStateAtoms.constraintModel);
    const { constraints } = model.state;
    //TODO: a bit of clean up to do here
    const [, updateQueryState] = useQueryState();
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const emptyQuestion = getEmptyParamsQuestion(modelUtil.model.state);
    const tabs = emptyQuestion || !constraints.length
        ? []
        : Object.values(constraints).map(c => ({
            key: c.id,
            label: (_jsx(QueryTextRenderer, { parts: pick(constraintToQueryParts(modelUtil.model, c.id), CRC_ONLY_PARTS), maxTags: 1 })),
            closable: false,
        }));
    if (!tabs.length || Object.values(constraints).filter(c => c.text).length < 2)
        return null;
    return (_jsx("div", Object.assign({ className: "ConstraintsBar flex flex-row items-center space-x-2" }, { children: _jsx(Tabs, { tabBarGutter: 0, size: "small", style: { width: 400 }, className: "flex-1", activeKey: ((_a = tabs[0]) === null || _a === void 0 ? void 0 : _a.key) === "" ? "" : activeConstraintId, onChange: key => {
                if (tabs.length <= 1)
                    return;
                setActiveConstraintId(key);
                updateQueryState({}, QueryStateEventSource.CONSTRAINTS_BAR);
            }, items: tabs, tabBarExtraContent: {
                left: (modelUtil.isModelEmpty() || emptyQuestion) && (_jsx("div", Object.assign({ className: "flex-1 flex flex-col", style: { height: 38 } }, { children: _jsx("div", Object.assign({ className: "flex items-center text-base border-b border-gray-100 px-2", style: { height: 38 } }, { children: emptyQuestion
                            ? `No answers for: ${emptyQuestion}`
                            : "No query" })) }))),
            } }) })));
}
