var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEqual, omit } from "lodash";
import { API } from "../../api/api";
import { getURLParams } from "../../utils/routing/utils/getURLParams";
import { setURLParams } from "../../utils/routing/utils/setURLParams";
import { ConstraintModelUtil } from "../atomic/queryState/constraintModel/ConstraintModelUtil";
import { validateCorpusIds } from "../atomic/queryState/utils/validateCorpusIds";
import { EMPTY_CORA_STATE } from "../verions/V1_CoraState";
import { getCoraStateFromUserFriendlyParams } from "../versionMigration/getCoraStateFromUserFriendlyParams";
import { getStateFromQueryParams } from "../versionMigration/getStateFromQueryParams";
import { migrateState } from "../versionMigration/stateMigration";
function getCoraStateFromUrl(params, corpus_ids = [], corpora) {
    return __awaiter(this, void 0, void 0, function* () {
        const fromFriendly = yield getCoraStateFromUserFriendlyParams(params, corpus_ids, corpora);
        if (!isEqual(omit(fromFriendly.coraState, "corpus_ids"), omit(EMPTY_CORA_STATE, "corpus_ids")))
            return fromFriendly;
        const coraState = yield getStateFromQueryParams(params, EMPTY_CORA_STATE);
        return {
            coraState,
            corpus_ids: validateCorpusIds(coraState.corpus_ids, corpus_ids, corpora)[0],
            hasUrlParams: false,
        };
    });
}
export const QueryId = (function () {
    return {
        get(params = getURLParams()) {
            var _a;
            return ((_a = params.queryId) === null || _a === void 0 ? void 0 : _a.trim()) || "";
        },
        set(queryId) {
            queryId = queryId.trim();
            if (QueryId.get() !== queryId) {
                setURLParams(queryId ? { queryId } : {});
            }
        },
        getQueryIdOrLegacyFromUrl(urlParams, corpus_ids = [], corpora) {
            return __awaiter(this, void 0, void 0, function* () {
                const queryId = QueryId.get(urlParams);
                if (queryId)
                    return queryId;
                const { coraState } = yield getCoraStateFromUrl(urlParams, corpus_ids, corpora);
                return yield QueryId.getQueryIdForCoraState(coraState);
            });
        },
        getQueryIdForCoraState(coraState) {
            return __awaiter(this, void 0, void 0, function* () {
                const stateToId = migrateState(coraState);
                return new ConstraintModelUtil(stateToId.constraintModel).isModelEmpty()
                    ? ""
                    : yield API.getUrlId({
                        data: stateToId,
                    });
            });
        },
    };
})();
