var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { classes } from "../../../appState/context/theme/classes";
import { Facet } from "../Facet";
import { getCheckBoxEntry } from "./getCheckBoxEntry";
function defaultItemToOption(item) {
    return { label: item.name, value: item.name, item };
}
export function NamedConceptMembersFacet(_a) {
    var { facetType, checkboxClass, initiallyCollapsed, titlePrefix, tagColor, suffixProvider, toOption = defaultItemToOption, itemsOverride, facetFor, getFacetTitle, max_count } = _a, props = __rest(_a, ["facetType", "checkboxClass", "initiallyCollapsed", "titlePrefix", "tagColor", "suffixProvider", "toOption", "itemsOverride", "facetFor", "getFacetTitle", "max_count"]);
    return (_jsx(Facet, Object.assign({ name: facetType }, props, { max_count: max_count, placeholder: facetType, toOption: toOption, getCheckBoxEntry: getCheckBoxEntry, getTitle: getFacetTitle, titlePrefix: titlePrefix, checkboxClass: classes("flex-1 flex overflow-hidden", checkboxClass), getSuffix: suffixProvider === null || suffixProvider === void 0 ? void 0 : suffixProvider(), itemsOverride: itemsOverride, emptyText: `No types of ${facetType} found` })));
}
