import { isEqual } from "lodash";
import { a_debugLog } from "./a_debugMode";
//TODO: handle typing more safely
export function maybeUpdateOrReset(atoms, get, set, value, currentValue) {
    Object.keys(value).forEach(key => {
        if (key in atoms) {
            const typedKey = key;
            //TODO: this check may be redundant
            if (!isEqual(value[typedKey], currentValue[typedKey])) {
                set(a_debugLog, "change detected in key:", typedKey);
                set(atoms[typedKey], value[typedKey]);
            }
        }
    });
}
