import { isHistoryEntry } from "../../atomic/session/utils/history";
import { isLegacyFindingContext } from "../../context/findings/findings";
import { toMetadataCluster } from "../toMetadataCluster";
import { migrateCoraState } from "./stateV1toV3";
function migrateCorpusFilter(corpus_filter) {
    function migrateMetadataFilter(meta) {
        if (isStrArray(meta.value)) {
            return Object.assign(Object.assign({}, meta), { value: meta.value.map(toMetadataCluster) });
        }
        return meta;
        function isStrArray(value) {
            var _a;
            if (!Array.isArray(value) ||
                !value.length ||
                typeof value[0] !== "string")
                return false;
            const first = (_a = value[0]) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            return first !== "true" && first !== "false";
        }
    }
    return Object.assign(Object.assign({}, corpus_filter), (corpus_filter.metadata && {
        metadata: corpus_filter.metadata.map(migrateMetadataFilter),
    }));
}
function migrateFinding(finding) {
    const context = finding.context;
    return !isLegacyFindingContext(context)
        ? finding
        : Object.assign(Object.assign({}, finding), { context: Object.assign(Object.assign(Object.assign({}, context), { coraState: migrateCoraState(context.coraState) }), (context.corpus_filter && {
                corpus_filter: migrateCorpusFilter(context.corpus_filter),
            })) });
}
function migrateHistoryEntry(entry) {
    return isHistoryEntry(entry) ? entry : migrateCoraState(entry);
}
export function v1SessionToV2Session(session) {
    session = Object.assign({}, session);
    return Object.assign(Object.assign({}, session), { queryHistory: session.queryHistory.map(migrateHistoryEntry), findings: session.findings.map(migrateFinding) });
}
