import { partition } from "lodash";
export function toComplexAndLoneConstraints(params) {
    const { constraints } = params;
    const [complex, lone] = partition(constraints, c => !isLoneConcept(c));
    return complex.length === 0 && lone.length === 1
        ? [lone, []]
        : [complex, lone];
    function isLoneConcept(c) {
        var _a;
        return (c.sources.length === 1 &&
            !c.relation &&
            !c.targets.length &&
            !((_a = c.context) === null || _a === void 0 ? void 0 : _a.length));
    }
}
