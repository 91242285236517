import { useCurrentSource } from "../../api/useCurrentSource";
import { useCurrentSession } from "../../appState/atomic/session/session";
export function useTrendDomain() {
    var _a;
    const [{ selectedTrendDomain }] = useCurrentSession();
    const currentSourceDomains = (_a = useCurrentSource()) === null || _a === void 0 ? void 0 : _a.supported_trends_fields.domain;
    const currentTrendInCurrentSource = (currentSourceDomains === null || currentSourceDomains === void 0 ? void 0 : currentSourceDomains.find(d => d.id === (selectedTrendDomain === null || selectedTrendDomain === void 0 ? void 0 : selectedTrendDomain.id))) !==
        undefined;
    const selectedDomain = currentTrendInCurrentSource
        ? selectedTrendDomain
        : currentSourceDomains !== undefined
            ? currentSourceDomains[0]
            : undefined;
    return selectedDomain;
}
