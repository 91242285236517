var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useAtomValue } from "jotai";
import { FeatureIdentifier, FeedbackType } from "../../api/types/apiTypes";
import { useCurrentSource } from "../../api/useCurrentSource";
import { getDocumentUrl, openUrl } from "../../api/utils/url";
import { useAllTags } from "../../appState/atomic/allTags";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_corpusFilterBuilder } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { sortMetadata } from "../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { addEvidenceToFinding, setFindingEvidence, updateFindingPayload, } from "../../appState/context/findings/findings";
import { classes } from "../../appState/context/theme/classes";
import { sendFeedback } from "../../utils/feedback/sendFeedback";
import { toggleFlag } from "../../utils/generic/collections";
import { CollapsableSection } from "../CollapsableSection";
import { ActionButton } from "../common/buttons/ActionButton";
import { ValidationIcon } from "../common/forms/InputValidation";
import { Robot } from "../common/icons/Icons";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { showNotification } from "../common/status/notifications";
import { useShowSelectionMenu } from "../contextMenu/showSelectionMenu";
import { FilteredEvidenceCardList } from "../evidence/FilteredEvidenceCardList";
import { showAttachSuggestedEvidence } from "../modals/AttachSuggestedEvidence";
export function FindingEditor({ finding, setFinding, disabled, titleError, }) {
    const [{ findingCollapsedSections: collapsed, structuredQuery: sq, showStructuredQuery, }, sessionUpdater,] = useCurrentSession();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const structuredQuery = sq && showStructuredQuery;
    const source = useCurrentSource();
    const tags = useAllTags(finding.payload.tags);
    const { suggestFindings } = finding.payload;
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const corpus_filter = corpusFilterBuilder.fullFilter().toCorpusFilter();
    const { suggestEvidence } = useSuggestedEvidence();
    const currentContextTitle = useCurrentContextTitle();
    const addEvidenceToNewFinding = (unusedFinding, evidence) => setFinding(finding => addEvidenceToFinding(finding, evidence));
    const showSelectionMenu = useShowSelectionMenu();
    return (_jsxs("div", Object.assign({ className: classes("FindingEditor flex-1 flex flex-col items-stretch p-4 radius-xl bg-white border rounded-lg space-y-4") }, { children: [_jsx(CollapsableSection, Object.assign({ title: _jsxs("div", Object.assign({ className: "flex space-x-2 items-center" }, { children: [_jsx("div", { children: "Title" }), " ", _jsx(ValidationIcon, { error: titleError })] })) }, { children: _jsx(Input, { value: finding.title, onChange: e => setFinding(f => (Object.assign(Object.assign({}, f), { title: e.target.value }))), placeholder: "Title" }) })), _jsx(CollapsableSection, Object.assign({ title: "Finding", error: !finding.payload.selectedText.trim() && "Required" }, { children: _jsxs("div", Object.assign({ className: "flex items-start space-x-2" }, { children: [_jsx(TextArea, { className: "flex1", value: finding.payload.selectedText, autoSize: false, onChange: e => setFinding(f => updateFindingPayload(f, { selectedText: e.target.value })), placeholder: "Finding Required" }), ((suggestFindings === null || suggestFindings === void 0 ? void 0 : suggestFindings.length) || 0) > 1 && (_jsx(DropDownMenu, Object.assign({ captionClassName: "max-w-[600px] !whitespace-normal text-ellipsis", entries: () => suggestFindings.map(e => ({
                                caption: e.text,
                                action() {
                                    setFinding(f => updateFindingPayload(f, { selectedText: e.text }));
                                },
                            })) }, { children: _jsx(ActionButton, { children: _jsx(Robot, { className: "animate-pulse" }) }) })))] })) })), _jsx(FilteredEvidenceCardList, { items: finding.payload.evidence, noItemsMessage: "No Evidence", metadataSorter: sortMetadata(source), containerStyleClass: " ", onEvidenceTextSelected: ({ evidence, rect, text }) => showSelectionMenu(rect, {
                    text,
                    evidence,
                    instance: evidence.instances[0],
                    context: evidence.text,
                    currentContextTitle,
                    structuredQuery,
                    usingHierarchyNav: supportsHierarchyNavigation,
                    suggestEvidence,
                }), headerActions: _jsx(Button, Object.assign({ disabled: disabled, onClick: () => showAttachSuggestedEvidence({
                        finding,
                        addEvidenceToFinding: addEvidenceToNewFinding,
                        onSelect(selected) {
                            return __awaiter(this, void 0, void 0, function* () {
                                yield sendFeedback(FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/suggest/findings", FeedbackType.IMPLICIT_POSITIVE, selected, corpus_filter);
                                addEvidenceToNewFinding(finding, selected);
                                showNotification("Feedback sent");
                            });
                        },
                    }) }, { children: "Suggest additional Evidence" })), onRemove: item => setFinding(finding => setFindingEvidence(finding, finding.payload.evidence.filter(f => f !== item))), urlOpener: (evidence, instance) => {
                    openUrl(getDocumentUrl(instance.document_id), instance.corpus_id, instance.document_id, evidence.text, instance.title, undefined, instance.metadata, sortMetadata(source), instance.external_url);
                } }), _jsxs("div", Object.assign({ className: classes(collapsed["tags"] && collapsed["notes"]
                    ? "flex flex-row space-x-4"
                    : "flex flex-col items-stretch space-y-4") }, { children: [_jsxs(CollapsableSection, Object.assign({ title: "Tags", collapsed: collapsed["tags"], onToggle: () => sessionUpdater({
                            findingCollapsedSections: toggleFlag(collapsed, "tags"),
                        }) }, { children: [_jsx(Select, Object.assign({ mode: "tags", allowClear: true, style: { width: "100%" }, placeholder: "Finding Tags", defaultValue: finding.payload.tags, value: finding.payload.tags, onChange: tags => setFinding(f => {
                                    return updateFindingPayload(f, { tags });
                                }) }, { children: tags === null || tags === void 0 ? void 0 : tags.map(tag => (_jsx(Select.Option, Object.assign({ value: tag }, { children: tag }), tag))) })), " "] })), _jsx(CollapsableSection, Object.assign({ title: "Notes", collapsed: collapsed["notes"], onToggle: () => sessionUpdater({
                            findingCollapsedSections: toggleFlag(collapsed, "notes"),
                        }) }, { children: _jsx(TextArea, { value: finding.payload.notes, onChange: e => setFinding(f => updateFindingPayload(f, { notes: e.target.value })) }) }))] }))] })));
}
