import { useSetAtom } from "jotai";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React from "react";
import { useAuthState } from "../auth/useAuthState";
import { a_featureFlags } from "./useFeatureFlags";
export function useLaunchDarklySync() {
    const setFeatureFlags = useSetAtom(a_featureFlags);
    const { userInfo, userProfile } = useAuthState();
    const client = useLDClient();
    const flags = useFlags();
    React.useEffect(() => {
        if (!userInfo)
            return;
        client === null || client === void 0 ? void 0 : client.identify({
            key: userProfile === null || userProfile === void 0 ? void 0 : userProfile.email,
            email: userProfile === null || userProfile === void 0 ? void 0 : userProfile.email,
            name: userProfile === null || userProfile === void 0 ? void 0 : userProfile.name,
            custom: {
                org_id: userInfo === null || userInfo === void 0 ? void 0 : userInfo.orgId,
            },
        });
    }, [userInfo, userProfile, client]);
    React.useEffect(() => {
        setFeatureFlags(flags);
    }, [flags]);
}
