import { selectTransmuter } from "./selectTransmuter";
export function decodeQueryParams(defValue, queryParams, queryParamPrefix = "") {
    return Object.entries(queryParams).reduce((acc, [paramName, paramValue]) => {
        const key = toKeyName(paramName);
        if (key in defValue) {
            const transmuter = selectTransmuter(defValue[key]);
            const accAsAny = acc;
            try {
                accAsAny[key] = transmuter.toValue(paramValue);
            }
            catch (e) {
                console.error(`Error Decoding URL. param[${key}]`, paramValue, e);
            }
        }
        return acc;
    }, Object.assign({}, defValue));
    function toKeyName(queryParamName) {
        return queryParamName.slice(queryParamPrefix.length);
    }
}
