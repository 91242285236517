import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { Handle, Position } from "reactflow";
import { a_constraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_activeConstraintId } from "../../appState/atomic/queryState/constraintModel/modelState";
import { NEUTRAL_COLOR_VALS, RELATION_COLOR_VALS, } from "../../appState/context/theme/lf";
import { Axis } from "../../utils/geometry/Axis";
import { a_userConfig } from "../../appState/atomic/session/parts/userConfig";
export function RelationNode({ id, data }) {
    const axis = Axis.get(useAtomValue(a_userConfig).horizontalGraphLayout);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const constraint = useAtomValue(a_activeConstraint);
    const setActiveConstraintId = useSetAtom(a_activeConstraintId);
    const relations = modelUtil.getRelationsForNodeIds([id]);
    const name = relations.length > 0 ? relations[0].name : "?";
    const active = constraint.relationNodeId === id;
    const color = active ? RELATION_COLOR_VALS[600] : NEUTRAL_COLOR_VALS[700];
    const textColor = active ? "black" : NEUTRAL_COLOR_VALS[900];
    return (_jsxs("div", Object.assign({ className: "flex items-center justify-center w-full h-full px-1", style: {
            backgroundColor: "white",
            borderColor: color,
            borderWidth: "2px",
            borderRadius: "4px",
            color: textColor,
        }, onClick: () => {
            data.constraintId && setActiveConstraintId(data.constraintId);
        } }, { children: [_jsx(Handle, { type: "target", position: axis(Position.Left, Position.Top) }), name, relations.length > 1 ? ` (+${relations.length - 1})` : "", _jsx(Handle, { type: "source", position: axis(Position.Right, Position.Bottom) })] })));
}
