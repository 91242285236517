import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { mapValues, pick, uniq } from "lodash";
import { createClusterId } from "../../../../utils/identity/createClusterId";
import { uuidv4 } from "../../../../utils/identity/uuidv4";
import { a_currentExtendedConceptTree } from "../../queryState/constraintModel/concepts";
import { queryStateAtoms } from "../../queryState/queryStateAtoms";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
export const EMPTY_WATCHLISTS_STATE = {
    extraConceptNodeIdsByCorpusId: {},
    extraConceptIdsByNodeId: {},
    extraConcepts: {},
};
const _atoms = {
    extraConceptNodeIdsByCorpusId: atomWithReset(EMPTY_WATCHLISTS_STATE.extraConceptNodeIdsByCorpusId),
    extraConceptIdsByNodeId: atomWithReset(EMPTY_WATCHLISTS_STATE.extraConceptIdsByNodeId),
    extraConcepts: atomWithReset(EMPTY_WATCHLISTS_STATE.extraConcepts),
};
export const WATCHLISTS_STATE_KEYS = Object.keys(_atoms);
export const a_watchlistsState = atom(get => {
    return {
        extraConceptNodeIdsByCorpusId: get(_atoms.extraConceptNodeIdsByCorpusId),
        extraConceptIdsByNodeId: get(_atoms.extraConceptIdsByNodeId),
        extraConcepts: get(_atoms.extraConcepts),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_watchlistsState);
    //TODO: hack to deal with old state
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export const a_currentExtraConceptNodeIds = atom(get => {
    const { extraConceptNodeIdsByCorpusId } = get(a_watchlistsState);
    const { corpus_ids } = get(queryStateAtoms.scope);
    const nodeIds = uniq(corpus_ids.map(id => extraConceptNodeIdsByCorpusId[id]).flat());
    return nodeIds;
});
export const a_currentExtraConcepts = atom(get => {
    const { extraConceptIdsByNodeId, extraConcepts } = get(a_watchlistsState);
    const nodeIds = get(a_currentExtraConceptNodeIds);
    return nodeIds
        .flatMap(id => extraConceptIdsByNodeId[id])
        .map(id => extraConcepts[id])
        .filter(Boolean);
});
const a_currentExtraConceptTree = atom(get => {
    const { extraConceptIdsByNodeId, extraConcepts } = get(a_watchlistsState);
    const nodeIds = get(a_currentExtraConceptNodeIds);
    // { [nodeId]: { [conceptId]: ConceptOrCustom } }
    const conceptTree = mapValues(pick(extraConceptIdsByNodeId, nodeIds), ids => Object.fromEntries(ids.map(id => [id, extraConcepts[id]])));
    return conceptTree;
});
export const a_addConceptToExtra = atom(null, (get, set, corpus_ids, concept) => {
    set(a_watchlistsState, state => {
        const nodeId = uuidv4();
        const newConcepts = Object.assign({}, state.extraConcepts);
        const id = createClusterId(concept);
        newConcepts[id] = concept;
        const newConceptIdsByNodeId = Object.assign({}, state.extraConceptIdsByNodeId);
        newConceptIdsByNodeId[nodeId] = uniq([
            ...(newConceptIdsByNodeId[nodeId] || []),
            id,
        ]);
        const newExtraConceptNodeIdsByCorpusId = Object.assign({}, state.extraConceptNodeIdsByCorpusId);
        corpus_ids.forEach(id => {
            newExtraConceptNodeIdsByCorpusId[id] = uniq([
                ...(newExtraConceptNodeIdsByCorpusId[id] || []),
                nodeId,
            ]);
        });
        return Object.assign(Object.assign({}, state), { extraConceptNodeIdsByCorpusId: newExtraConceptNodeIdsByCorpusId, extraConceptIdsByNodeId: newConceptIdsByNodeId, extraConcepts: newConcepts });
    });
});
export const a_removeConceptFromExtra = atom(null, (get, set, corpus_ids, nodeId, conceptId) => {
    set(a_watchlistsState, state => {
        const newConceptIdsByNodeId = Object.assign({}, state.extraConceptIdsByNodeId);
        const newConcepts = Object.assign({}, state.extraConcepts);
        // update the concept ids for the node
        const conceptIds = newConceptIdsByNodeId[nodeId] || [];
        const newConceptIds = conceptIds.filter(id => id !== conceptId);
        if (newConceptIds.length === 0) {
            delete newConceptIdsByNodeId[nodeId];
        }
        else {
            newConceptIdsByNodeId[nodeId] = newConceptIds;
        }
        // conceptIds still in use
        const conceptIdsInUse = uniq(Object.values(newConceptIdsByNodeId).flat());
        // if no nodes are still using the concept, delete it
        if (!conceptIdsInUse.includes(conceptId)) {
            delete newConcepts[conceptId];
        }
        // update the node ids for the corpus
        const newExtraConceptNodeIdsByCorpusId = Object.assign({}, state.extraConceptNodeIdsByCorpusId);
        corpus_ids.forEach(id => {
            const nodeIds = newExtraConceptNodeIdsByCorpusId[id] || [];
            // assuming the node has no other concepts (1:1)
            const newNodeIds = nodeIds.filter(nId => nId !== nodeId);
            if (newNodeIds.length === 0) {
                delete newExtraConceptNodeIdsByCorpusId[id];
            }
            else {
                newExtraConceptNodeIdsByCorpusId[id] = newNodeIds;
            }
        });
        return Object.assign(Object.assign({}, state), { extraConceptIdsByNodeId: newConceptIdsByNodeId, extraConcepts: newConcepts, extraConceptNodeIdsByCorpusId: newExtraConceptNodeIdsByCorpusId });
    });
});
export const a_resetExtraConcepts = atom(null, (get, set, corpus_ids) => {
    set(a_watchlistsState, state => {
        const newConceptIdsByNodeId = Object.assign({}, state.extraConceptIdsByNodeId);
        const newConcepts = Object.assign({}, state.extraConcepts);
        corpus_ids.forEach(id => {
            const nodeIds = state.extraConceptNodeIdsByCorpusId[id] || [];
            nodeIds.forEach(nodeId => {
                const conceptIds = newConceptIdsByNodeId[nodeId] || [];
                conceptIds.forEach(conceptId => {
                    delete newConcepts[conceptId];
                });
                delete newConceptIdsByNodeId[nodeId];
            });
        });
        return Object.assign(Object.assign({}, state), { extraConceptIdsByNodeId: newConceptIdsByNodeId, extraConcepts: newConcepts });
    });
});
export const a_fullExtendedConceptTree = atom(get => {
    const extraConceptTree = get(a_currentExtraConceptTree);
    const extendedConceptTree = get(a_currentExtendedConceptTree);
    return Object.assign(Object.assign({}, extraConceptTree), extendedConceptTree);
});
