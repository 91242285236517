import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from "antd";
import React from "react";
import { useDisplayState } from "../../appState/atomic/session/parts/displayState";
import { classes } from "../../appState/context/theme/classes";
import { ActionButton } from "../common/buttons/ActionButton";
import { Trash } from "../common/icons/Icons";
import { Tooltip } from "../common/popups/Tooltip";
import { EvidenceHighlights } from "./EvidenceHighlights";
import { ExtendedEvidenceText } from "./ExtendedEvidenceText";
export function EvidenceRow({ evidence, className, documentId, renderEvidenceActions, filterText, renderAsHtml, showSurrounding, showHighlights, isSelected, isHighlighted, selectEvidence, removeEvidence, openEvidence, onEvidenceTextSelected, annotationProvider, getEvidenceWithPosition, }) {
    const [{ showSummary }] = useDisplayState();
    function addAndRemoveButtons(evidence) {
        return (_jsxs("div", Object.assign({ className: "" }, { children: [removeEvidence && (_jsx(ActionButton, { onClick: e => removeEvidence(evidence), icon: _jsx(Trash, {}) })), selectEvidence && (_jsx(Tooltip, Object.assign({ content: "Select" }, { children: _jsx("div", { children: _jsx(Checkbox, { className: "ml-2 mr-2", checked: isSelected && isSelected(evidence.id), onChange: () => selectEvidence(evidence) }) }) })))] })));
    }
    const { position } = (getEvidenceWithPosition &&
        getEvidenceWithPosition(evidence.id)) || {
        position: undefined,
    };
    return (_jsxs("div", Object.assign({ className: classes("py-2 border-t border-background_secondary", isHighlighted && isHighlighted(evidence.id)
            ? "bg-accent-50"
            : isSelected && isSelected(evidence.id)
                ? "bg-accent-100 hover:bg-accent-100"
                : "hover:bg-gray-100", className) }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-start" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-start items-start" }, { children: [_jsx("div", Object.assign({ className: "w-[24px] min-w-[24px] mr-2" }, { children: addAndRemoveButtons(evidence) })), _jsx(ExtendedEvidenceText, { prefix: showSummary &&
                                    position && (_jsxs("span", Object.assign({ className: "text-gray-500 text-base" }, { children: [position, ". "] }))), evidence: evidence, truncationThreshold: 500, filterText: filterText, renderAsHtml: renderAsHtml, annotationProvider: annotationProvider, onEvidenceTextSelected: onEvidenceTextSelected, showSurrounding: showSurrounding })] })), _jsx("div", Object.assign({ className: "flex flex-col items-center px-2 space-y-1" }, { children: renderEvidenceActions && renderEvidenceActions(evidence, documentId) }))] })), _jsx("div", Object.assign({ className: "pl-10" }, { children: _jsx(EvidenceHighlights, { evidence: evidence, showHighlights: showHighlights, urlOpener: openEvidence }) }))] })));
}
