import { jsx as _jsx } from "react/jsx-runtime";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { Document } from "../common/icons/Icons";
export function useSummariesAction({ evidenceRequestParams, }) {
    const supportsSummaries = useIsFeatureSupported(Feature.TOPIC_SUMMARIZATION);
    const [{ showSummary }, sessionUpdater] = useCurrentSession();
    useEffectIfDifferent(() => sessionUpdater({ selectedSummaries: [] }), [], [evidenceRequestParams]);
    useEffectIfDifferent(() => !supportsSummaries &&
        sessionUpdater({ showSummary: false, selectedSummaries: [] }), [], [supportsSummaries, sessionUpdater]);
    const disabled = !supportsSummaries;
    return !supportsSummaries
        ? null
        : {
            key: "summaries",
            icon: _jsx(Document, {}),
            tooltip: "Show topic summaries",
            selected: showSummary,
            disabled,
            onSelect: () => {
                sessionUpdater({
                    showSummary: !showSummary,
                });
            },
        };
}
