var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { partition } from "lodash";
import { ConstraintModelUtil } from "../../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { getNodesBounds, getViewportForNodes, offsetToOrigin, } from "../diagramUtils";
import { layoutGraph } from "../layoutNodes";
import { dmParamsToDiagram } from "./dmParamsToDiagram";
export function queryStateToDiagram(queryState, possible, currentNE = { nodes: [], edges: [] }, viewportWidth, viewportHeight) {
    return __awaiter(this, void 0, void 0, function* () {
        const { nodesInfo } = queryState.modelBuilderState;
        let { viewport } = queryState.modelBuilderState;
        const orgNodeIds = new Set(currentNE.nodes.map(n => n.id));
        const modelUtil = new ConstraintModelUtil(queryState.constraintModel);
        const dmParams = modelUtil.toDMParams();
        const overrides = modelUtil.toPsuedoOverrides();
        const activeConstraintId = modelUtil.model.activeConstraintId;
        const ne = dmParamsToDiagram(dmParams, overrides, possible, nodesInfo, activeConstraintId, currentNE);
        const [noRefresh, toRefresh] = partition(ne.nodes, n => orgNodeIds.has(n.id));
        const [x, y, w, h] = getNodesBounds(noRefresh);
        const nodes = [
            ...noRefresh,
            ...offsetToOrigin(yield layoutGraph({ nodes: toRefresh, edges: ne.edges }, "H"), { x, y: y + h + 32 }),
        ];
        if (nodes.length && !noRefresh.length) {
            viewport = getViewportForNodes(nodes, viewportWidth, viewportHeight);
        }
        return {
            nodes: nodes,
            edges: ne.edges,
            viewport,
        };
    });
}
