import { atom, useAtom } from "jotai";
const a_userInfo = atom(undefined);
const a_userProfile = atom(undefined);
const a_isLoading = atom(false);
const a_isAuthenticated = atom(false);
const a_loginWithRedirect = atom(undefined);
export function useAuthState() {
    const [userInfo, setUserInfo] = useAtom(a_userInfo);
    const [userProfile, setUserProfile] = useAtom(a_userProfile);
    const [isLoading, setIsLoading] = useAtom(a_isLoading);
    const [isAuthenticated, setIsAuthenticated] = useAtom(a_isAuthenticated);
    const [loginWithRedirect, setLoginWithRedirect] = useAtom(a_loginWithRedirect);
    return {
        userInfo,
        setUserInfo,
        userProfile,
        setUserProfile,
        isLoading,
        setIsLoading,
        isAuthenticated,
        setIsAuthenticated,
        loginWithRedirect,
        setLoginWithRedirect,
    };
}
