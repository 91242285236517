import React from "react";
export function useMemoizedValue(supplier) {
    const ref = React.useRef();
    function getter() {
        if (ref.current === undefined) {
            ref.current = supplier();
        }
        return ref.current;
    }
    getter.reset = () => {
        ref.current = undefined;
    };
    return getter;
}
