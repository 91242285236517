import { notification } from "antd";
import { useBeacon } from "../appState/beacon/Beacon";
import { renderCancelActionNotification } from "../components/common/errorHandling/renderCancelActionNotification";
import { showNotification } from "../components/common/status/notifications";
import { activityBeacon } from "./utils/monitoredActivity";
function isTimedout({ started, timeout }) {
    if (!timeout)
        return false;
    if (Date.now() - started.valueOf() > timeout) {
        return true;
    }
    return false;
}
export function useActivityMonitor() {
    const [activities] = useBeacon(activityBeacon);
    const timedoutActivities = Object.entries(activities).filter(([k, v]) => !v.announce && isTimedout(v));
    const hasTimedoutActivities = Boolean(timedoutActivities.length);
    const key = "activityMonitor";
    if (hasTimedoutActivities) {
        showNotification({
            message: renderCancelActionNotification(`Some requests are taking longer than expected.`, () => {
                timedoutActivities.forEach(([k, v]) => {
                    var _a;
                    (_a = v.onInterrupt) === null || _a === void 0 ? void 0 : _a.call(v);
                });
            }, () => notification.destroy(key)),
            key: key,
            placement: "topRight",
            duration: 30,
        }, "warning");
    }
    else {
        notification.destroy(key);
    }
}
