import { metadataValue } from "./metadataValue";
export function filterMetadata(e, dropFalseBools = false) {
    if (!e)
        return [];
    return e.filter(ev => {
        const value = metadataValue(ev);
        if (Array.isArray(value))
            return value.length > 0;
        if (typeof value === "boolean" && dropFalseBools)
            return value;
        return ev.value !== undefined;
    });
}
