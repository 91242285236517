import { jsx as _jsx } from "react/jsx-runtime";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { ToggleButton } from "../common/buttons/ToggleButton";
import { Dictionary } from "../common/icons/Icons";
import { Tooltip } from "../common/popups/Tooltip";
export function PrintFeatureFlags() {
    const flags = useFeatureFlags();
    return (_jsx(Tooltip, Object.assign({ content: "Print Feature Flags" }, { children: _jsx(ToggleButton, Object.assign({ onClick: () => {
                console.log(flags);
            } }, { children: _jsx(Dictionary, {}) })) })));
}
