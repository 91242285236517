import React from "react";
import { useShortcuts } from "./useShortcuts";
export function useWhileKeyPressed(actionsByShortcut, disabled = false) {
    const pressed = React.useRef(new Set()).current;
    useShortcuts(Object.entries(actionsByShortcut).reduce((acc, [shortcut, action]) => {
        acc[shortcut + "+keydown"] = () => {
            if (!pressed.has(shortcut)) {
                pressed.add(shortcut);
                action(true);
            }
        };
        acc[shortcut + "+keyup"] = () => {
            if (pressed.has(shortcut)) {
                pressed.delete(shortcut);
                action(false);
            }
        };
        return acc;
    }, {}), disabled);
}
