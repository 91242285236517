import { FeedbackType } from "../../api/types/apiTypes";
export function getFeedbackStyles(feedbackType) {
    switch (feedbackType) {
        case FeedbackType.NEGATIVE:
            return "bg-alert_red-100 text-alert_red-500";
        case FeedbackType.POSITIVE:
            return "bg-alert_green-100 text-alert_green-500";
        default:
            return "bg-neutral-100";
    }
}
