var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { Close, Export, Import, Plus } from "../common/icons/Icons";
import { showFindingEditor } from "../modals/FindingEditorModal";
import { FILE_TYPES, inputFileName, pickFiles, RTF_MIME_TYPE, saveTextFile, } from "../../utils/fileIO/filePicker";
import { setLoading } from "../common/transitions/Loading";
import { JsonFileToFindings, useCreateNewFinding, } from "../../appState/context/findings/findings";
import { message } from "antd";
import { upsertArray } from "../../utils/generic/collections";
import { showNotification } from "../common/status/notifications";
import { EllipsisOutlined } from "@ant-design/icons";
import React from "react";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useHasBackgroundKnowledge } from "../../appState/atomic/queryState/useHasBackgroundKnowledge";
import { useAtomValue } from "jotai/index";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { findingsToHtml } from "../findings/findingsToHtml";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import Rtf from "html-to-rtf";
import html2md from "html-to-md";
export function FindingsDropDownMenu({ findings, }) {
    const [, sessionUpdater] = useCurrentSession();
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const disabled = invalidCorpusIds(corpus_ids);
    const createNewFinding = useCreateNewFinding();
    const currentContextTitle = useCurrentContextTitle();
    const backgroundKnowledge = useHasBackgroundKnowledge();
    return (_jsxs("div", Object.assign({ className: "flex space-x-1" }, { children: [_jsx(DropDownMenu, Object.assign({ entries: () => [
                    {
                        caption: "Create New Finding",
                        icon: _jsx(Plus, {}),
                        action() {
                            var _a;
                            return __awaiter(this, void 0, void 0, function* () {
                                const finding = yield createNewFinding({
                                    selectedText: ((_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) || "",
                                    context: null,
                                    contextTitle: currentContextTitle,
                                });
                                showFindingEditor(finding, true, backgroundKnowledge, disabled);
                            });
                        },
                    },
                    {
                        caption: "Export Findings:",
                        subEntries: [
                            {
                                mime: "text/html",
                                type: "html",
                                toText: () => findingsToHtml(findings),
                            },
                            {
                                mime: FILE_TYPES,
                                type: "json",
                                toText: () => JSON.stringify(findings, undefined, 2),
                            },
                            {
                                mime: RTF_MIME_TYPE,
                                type: "rtf",
                                toText: () => Rtf.convertHtmlToRtf(findingsToHtml(findings)),
                            },
                            {
                                name: "Markdown",
                                mime: "text/md",
                                type: "md",
                                toText: () => html2md(findingsToHtml(findings)),
                                includeExtension: true,
                            },
                        ].map(({ name, mime, type, toText, includeExtension }) => ({
                            caption: `As ${name || type.toUpperCase()}...`,
                            disabled: !findings.length,
                            icon: _jsx(Export, {}),
                            action() {
                                return __awaiter(this, void 0, void 0, function* () {
                                    const fileName = yield inputFileName(`Export My Findings as ${type.toUpperCase()}…`);
                                    if (fileName) {
                                        saveTextFile(includeExtension ? `${fileName}.${type}` : fileName, mime, toText());
                                        showNotification(`Findings Exported as ${fileName}.${type}`);
                                    }
                                });
                            },
                        })),
                    },
                    {
                        caption: "Import Findings…",
                        icon: _jsx(Import, {}),
                        action() {
                            return __awaiter(this, void 0, void 0, function* () {
                                const [file] = (yield pickFiles(FILE_TYPES, false)) || [];
                                if (!file)
                                    return;
                                try {
                                    setLoading(`Importing ${file.name}…`);
                                    const toMerge = yield JsonFileToFindings(file);
                                    if (typeof toMerge === "string") {
                                        message.error(toMerge, 10);
                                        return;
                                    }
                                    const { replaced, added, result } = upsertArray(findings, toMerge, f => f.id);
                                    showNotification({
                                        message: `Findings\nReplaced: ${replaced.length}\nFindings Added: ${added.length}`,
                                        duration: 5,
                                    });
                                    sessionUpdater({
                                        findings: result,
                                    });
                                }
                                finally {
                                    setLoading(false);
                                }
                            });
                        },
                    },
                ] }, { children: _jsx(BorderlessButton, { children: _jsx(EllipsisOutlined, {}) }) })), _jsx(BorderlessButton, Object.assign({ onClick: () => {
                    sessionUpdater({ showMyFindings: false });
                } }, { children: _jsx(Close, {}) }))] })));
}
