import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox } from "antd";
import React from "react";
import { union } from "../../utils/generic/collections";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { LIST_HEIGHT } from "./ClusterCheckList";
export function useSelectIdsWrapper(changeSelection, remoteByKey, synonyms, updateSynonyms) {
    return React.useCallback((content, item, idx, containerClassName) => {
        const syns = synonyms(item);
        const re = remoteByKey[item.name];
        const reSyns = union(re ? synonyms(re) : [], syns);
        return reSyns.length < 1 ? (content) : (_jsx(DropDownMenu, Object.assign({ containerClassName: containerClassName, trigger: "contextMenu", maxHeight: LIST_HEIGHT, entries: () => {
                const set = new Set(syns);
                return reSyns.map(s => ({
                    caption: s,
                    disabled: s === item.name,
                    action(e) {
                        e.preventDefault();
                        const newSyns = set.has(s)
                            ? syns.filter(i => i !== s)
                            : syns.concat([s]);
                        changeSelection(updateSynonyms(item, newSyns), true);
                    },
                    icon: () => _jsx(Checkbox, { checked: set.has(s) }),
                }));
            } }, { children: content }), idx));
    }, [changeSelection, remoteByKey, synonyms, updateSynonyms]);
}
