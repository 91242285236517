import { API } from "../../../api/api";
import { ClusteringMode } from "../../../api/types/apiTypes";
import { useHasCapability } from "../../../api/useHasCapability";
import { useRemoteQuery } from "../../../api/useRemoteQuery";
import { CorpusCapability } from "../../../api/utils/capabilities";
import { EXPANSION_COUNT } from "./consts/EXPANSION_COUNT";
import { mergeNamedMembersToList } from "./semanticSearch/mergeNamedMembers";
import { invalidCorpusIds } from "./utils/invalidCorpusIds";
function getConceptsExpansionParams({ concepts, corpus_ids, similarity_mode, aperture, }) {
    return {
        corpus_ids,
        cluster: mergeNamedMembersToList(concepts)[0],
        similarity_mode,
        count: EXPANSION_COUNT,
        aperture,
    };
}
export function useConceptExpansions(concepts, corpus_ids, similarity_mode, aperture, enabled) {
    const hasCapability = useHasCapability(CorpusCapability.SUGGEST_CONCEPTS) &&
        similarity_mode !== ClusteringMode.NONE;
    const hasParams = enabled && hasCapability && !invalidCorpusIds(corpus_ids);
    const params = !hasParams
        ? undefined
        : getConceptsExpansionParams({
            concepts,
            corpus_ids,
            similarity_mode: similarity_mode,
            aperture,
        });
    const remoteQuery = useRemoteQuery({
        loader: API.concept_expansion,
        params,
    });
    return { remote: hasCapability ? remoteQuery : null, params };
}
