import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
export const EMPTY_TREND_STATE = {
    selectedGranularity: undefined,
    selectedTrendDomain: undefined,
    selectedTrendRange: undefined,
    trendRangeType: undefined,
};
const _atoms = {
    selectedGranularity: atomWithReset(EMPTY_TREND_STATE.selectedGranularity),
    selectedTrendDomain: atomWithReset(EMPTY_TREND_STATE.selectedTrendRange),
    selectedTrendRange: atomWithReset(EMPTY_TREND_STATE.selectedTrendRange),
    trendRangeType: atomWithReset(EMPTY_TREND_STATE.trendRangeType),
};
export const TREND_STATE_KEYS = Object.keys(_atoms);
export const a_trendState = atom(get => {
    return {
        selectedGranularity: get(_atoms.selectedGranularity),
        selectedTrendDomain: get(_atoms.selectedTrendDomain),
        selectedTrendRange: get(_atoms.selectedTrendRange),
        trendRangeType: get(_atoms.trendRangeType),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_trendState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
