var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { omit, pick } from "lodash";
import { createClusterId } from "../../../utils/identity/createClusterId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { dmParamsToConstraintModel } from "../../atomic/queryState/constraintModel/fromDMParams";
import { migrateKeywordMetadata } from "../../atomic/queryState/coraState/versionManagement/migrateKeywordMetadata";
import { TwoKey } from "../../atomic/queryState/types/DoubleDict";
import { getActualActiveConstraintId } from "../../atomic/queryState/utils/getActualActiveConstraintId";
import { getConstraintReferredIds } from "../../atomic/queryState/utils/getConstraintReferredIds";
import { EMPTY_CORA_STATE, QUERY_PARAMS_KEYS, } from "../../verions/V1_CoraState";
import { EMPTY_QUERY_STATE } from "../../verions/V3_QueryState";
import { COMMON_STATE_PARAMS_KEYS } from "../../verions/common";
import { getUpdatedConceptGroup } from "../getUpdatedConceptGroup";
import { queryParamsToDMParams } from "../queryParamsToDMParams";
export function modelV1toV2(dmParams) {
    return {
        concepts: dmParams.concepts,
        relations: dmParams.relations,
        clauses: dmParams.clauses,
        constraints: dmParams.constraints.map(c => (Object.assign(Object.assign({}, c), { targets: c.target ? [c.target] : [], sources: c.source ? [c.source] : [] }))),
    };
}
/*
  replaces in dmParams the constraint with idToUpdate with the updated constraint
  if updated is empty, the constraint is removed
 */
function updateConstraint(dmParams, idToUpdate, updated // empty or one constraint
// empty or one constraint
) {
    var _a;
    if (idToUpdate === "")
        idToUpdate = ((_a = updated.constraints[0]) === null || _a === void 0 ? void 0 : _a.id) || uuidv4();
    const constraint = updated.constraints.length
        ? Object.assign(Object.assign({}, updated.constraints[0]), { id: idToUpdate }) : undefined;
    const orgC = dmParams.constraints.find(c => c.id === idToUpdate);
    const idsToRemove = getConstraintReferredIds(orgC)
        .removeAll(getConstraintReferredIds(constraint))
        .toArray();
    const constraints = !constraint
        ? dmParams.constraints.filter(c => c.id !== idToUpdate)
        : !orgC
            ? [...dmParams.constraints, constraint]
            : dmParams.constraints.map(c => (c.id === idToUpdate ? constraint : c));
    return [
        {
            concepts: Object.assign(Object.assign({}, omit(dmParams.concepts, idsToRemove)), updated.concepts),
            relations: Object.assign(Object.assign({}, omit(dmParams.relations, idsToRemove)), updated.relations),
            clauses: Object.assign(Object.assign({}, omit(dmParams.clauses, idsToRemove)), updated.clauses),
            constraints,
        },
        idsToRemove,
    ];
}
export function conceptsWithOverridesToNodes(concepts, overrides) {
    const [ids, conceptsById, overridesById] = getUpdatedConceptGroup(concepts, overrides);
    const conceptByNewId = {};
    const nodeConceptOverrides = {};
    const nodeConceptRequired = {};
    const nodes = ids.reduce((acc, id) => {
        if (!conceptsById[id])
            return acc;
        const concept = conceptsById[id];
        const conceptId = createClusterId(concept);
        conceptByNewId[conceptId] = concept;
        const nodeId = uuidv4();
        const overrides = overridesById[id] || [];
        const overrideConceptIds = overrides.map(c => {
            const conceptId = createClusterId(c);
            conceptByNewId[conceptId] = c;
            return conceptId;
        });
        TwoKey.set(nodeConceptOverrides, nodeId, conceptId, overrideConceptIds);
        TwoKey.set(nodeConceptRequired, nodeId, conceptId, overrideConceptIds.length > 0);
        return Object.assign(Object.assign({}, acc), { [nodeId]: [conceptId] });
    }, {});
    return {
        overrides: nodeConceptOverrides,
        required: nodeConceptRequired,
        conceptNodes: nodes,
        concepts: conceptByNewId,
    };
}
export function coraStateToV3QueryState(coraState) {
    const _a = pick(coraState, COMMON_STATE_PARAMS_KEYS), { corpus_ids, aperture } = _a, common = __rest(_a, ["corpus_ids", "aperture"]);
    const dmParams = modelV1toV2(coraState.dmParams);
    const queryParams = pick(coraState, QUERY_PARAMS_KEYS);
    const activeId = getActualActiveConstraintId(dmParams);
    const [fromCRC, overridesFromCRC] = queryParamsToDMParams(queryParams, activeId, dmParams);
    const [updatedDMParams, idsToRemove] = updateConstraint(dmParams, activeId, fromCRC);
    const constraintModel = dmParamsToConstraintModel(updatedDMParams, overridesFromCRC);
    // migrate metadata
    const { booleanMetadata, keywordMetadata, rangeMetadata } = migrateCoraState(coraState);
    constraintModel.config.booleanMetadata = booleanMetadata;
    constraintModel.config.keywordMetadata = keywordMetadata;
    constraintModel.config.rangeMetadata = rangeMetadata;
    const { conceptNodes: extendedNodes, concepts: extendedConcepts, overrides: extendedOverrides, required: extendedRequired, } = conceptsWithOverridesToNodes(coraState.extraConcepts, coraState.extraConceptsOverride);
    constraintModel.state.conceptNodes = Object.assign(Object.assign({}, constraintModel.state.conceptNodes), extendedNodes);
    constraintModel.data.concepts = Object.assign(Object.assign({}, constraintModel.data.concepts), extendedConcepts);
    constraintModel.config.overrides = Object.assign(Object.assign({}, constraintModel.config.overrides), extendedOverrides);
    constraintModel.config.requiredConcepts = Object.assign(Object.assign({}, constraintModel.config.requiredConcepts), extendedRequired);
    return Object.assign(Object.assign({}, EMPTY_QUERY_STATE), { scope: {
            aperture: coraState.aperture,
            corpus_ids: coraState.corpus_ids,
        }, searchParams: Object.assign({}, common), constraintModel });
}
export function migrateCoraState(coraState) {
    coraState = Object.assign(Object.assign({}, EMPTY_CORA_STATE), coraState);
    return Object.assign(Object.assign({}, coraState), { keywordMetadata: coraState.keywordMetadata.map(migrateKeywordMetadata) });
}
