var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as toi from "@toi/toi";
import Immutable, { Set } from "immutable";
import React from "react";
import { queryModelToQueryParts } from "../../../components/query/queryStateToQueryParts";
import { merge, toSet } from "../../../utils/generic/collections";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { ConstraintModelUtil } from "../../atomic/queryState/constraintModel/ConstraintModelUtil";
import { useQueryState } from "../../atomic/queryState/useQueryState";
import { getQueryStateHistoryEntry, restoreHistoricalQuery, } from "../../atomic/session/utils/history";
import { loadQueryState } from "../../stateRestoration/loadQueryState";
import { coraStateToV3QueryState } from "../../versionMigration/migrators/stateV1toV3";
export function setFindingEvidence(finding, evidence) {
    return Object.assign(Object.assign({}, finding), { updateTimestamp: Date.now(), payload: Object.assign(Object.assign({}, finding.payload), { evidence }) });
}
export function addEvidenceToFinding(finding, evidence) {
    const evidenceToAdd = getAvailableEvidence(evidence, finding.payload.evidence);
    return !(evidence === null || evidence === void 0 ? void 0 : evidence.length)
        ? finding
        : setFindingEvidence(finding, [
            ...finding.payload.evidence,
            ...evidenceToAdd,
        ]);
}
export function removeEvidenceFromFinding(finding, evidence) {
    return setFindingEvidence(finding, finding.payload.evidence.filter(e => !evidence.map(e => e.id).includes(e.id)));
}
export function existsInFindings(findings, evidence) {
    return findings.some(f => f.payload.evidence.map(e => e.id).includes(evidence.id));
}
export function countEvidenceInFindings(findings, evidence) {
    return findings
        .reduce((acc, finding) => {
        const ids = finding.payload.evidence.map(e => e.id);
        return acc.concat(ids);
    }, Immutable.Set())
        .filter(eId => evidence.map(e => e.id).includes(eId)).size;
}
export function updateFindingPayload(finding, payload) {
    return Object.assign(Object.assign({}, finding), { updateTimestamp: Date.now(), payload: merge(finding.payload, payload) });
}
export function upsertFinding(findings, finding) {
    let replaced = false;
    const mapped = findings.map(f => {
        const found = f.id === finding.id;
        if (found) {
            replaced = true;
            return finding;
        }
        return f;
    });
    return replaced ? mapped : [...mapped, finding];
}
export function getAvailableEvidence(suggested, current) {
    if (!(suggested === null || suggested === void 0 ? void 0 : suggested.length))
        return [];
    const set = toSet(current.map(e => e.text));
    return suggested.filter(e => !set.has(e.text));
}
export function useCreateNewFinding() {
    const [queryState] = useQueryState();
    return function createNewFinding({ selectedText, context, contextTitle, evidence = [], suggestFindings, title, }) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                id: uuidv4(),
                title,
                creationTimestamp: Date.now(),
                updateTimestamp: Date.now(),
                context: {
                    historyEntry: yield getQueryStateHistoryEntry(queryState),
                    contextTitle,
                },
                payload: {
                    selectedText,
                    context,
                    evidence,
                    tags: [],
                    notes: "",
                    foundTime: Date.now(),
                    suggestFindings,
                },
            };
        });
    };
}
export function getContextUrl(context) {
    return (context === null || context === void 0 ? void 0 : context.instances[0].external_url) || null;
}
export function JsonFileToFindings(file) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const json = yield file.text();
            const obj = JSON.parse(json);
            validateFindings(obj);
            const arr = obj;
            arr.forEach(validateFinding);
            return arr;
        }
        catch (e) {
            console.error(e);
            if (e instanceof toi.ValidationError) {
                console.warn(e.reasons);
            }
        }
        return "File has to contain a JSON array of findings";
    });
}
const validateFinding = toi.obj.keys({
    id: toi.required().and(toi.str.is()),
    title: toi.optional().and(toi.str.is()),
    creationTimestamp: toi.optional().and(toi.num.is()),
    updateTimestamp: toi.optional().and(toi.num.is()),
    context: toi.optional().and(toi.obj.isplain()),
    payload: toi.optional().and(toi.obj.isplain()),
}, { missing: ["title", "updateTimestamp"] });
const validateFindings = toi.required().and(toi.array.is());
export function isLegacyFindingContext(context) {
    return "coraState" in context;
}
export function restoreFindingContext(context, handleQueryState) {
    return __awaiter(this, void 0, void 0, function* () {
        const queryState = yield restoreHistoricalQuery(isLegacyFindingContext(context) ? context.coraState : context.historyEntry);
        handleQueryState(queryState);
    });
}
export function getContextQueryParts(context) {
    return isLegacyFindingContext(context)
        ? queryModelToQueryParts(coraStateToV3QueryState(context.coraState).constraintModel)
        : context.historyEntry.queryParts;
}
export function getContextCorpusIds(context) {
    return isLegacyFindingContext(context)
        ? context.coraState.corpus_ids
        : context.historyEntry.corpus_ids;
}
export function getContextText(context) {
    return isLegacyFindingContext(context)
        ? context.coraState.text
        : context.historyEntry.text;
}
export function getContextCorpusFilter(context) {
    return __awaiter(this, void 0, void 0, function* () {
        if (isLegacyFindingContext(context))
            return context.corpus_filter;
        const queryState = yield loadQueryState(context.historyEntry.queryId);
        const modelUtil = new ConstraintModelUtil(queryState.constraintModel);
        const activeConstraint = modelUtil.model.activeConstraintId &&
            modelUtil.model.state.constraints[modelUtil.model.activeConstraintId];
        return (activeConstraint && modelUtil.isConstraintEmpty(activeConstraint)) ||
            !activeConstraint
            ? undefined
            : modelUtil
                .buildCorpusFilter(queryState.scope.corpus_ids, queryState.scope.aperture, activeConstraint)
                .fullFilter()
                .toCorpusFilter();
    });
}
export function useValidateFindingTitle(findings, title) {
    const findingTitles = React.useMemo(() => {
        const titles = findings
            .map(f => { var _a; return ((_a = f.title) === null || _a === void 0 ? void 0 : _a.trim().toLowerCase()) || ""; })
            .filter(Boolean);
        return Set(titles);
    }, [findings]);
    const titleError = title &&
        findingTitles.has(title.trim().toLowerCase()) &&
        "There is a finding with that name.";
    return titleError;
}
