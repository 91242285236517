import { useEffectIfDifferent } from "../lifecycle/useEffectIfDifferent";
export function useSelectionTracker(disabled, onChange) {
    //TODO: could be leaky
    useEffectIfDifferent(() => {
        let selection = undefined;
        let tracking = false;
        const selectionchange = () => {
            var _a, _b;
            if (!tracking || disabled)
                return;
            selection = (_b = (_a = document.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.trim();
        };
        const mousedown = (e) => {
            if (disabled)
                return;
            tracking = true;
            selection = undefined;
        };
        const mouseup = (e) => {
            if (disabled)
                return;
            tracking = false;
            if (selection === null || selection === void 0 ? void 0 : selection.length) {
                onChange(document.getSelection());
            }
            selection = undefined;
        };
        document.addEventListener("mousedown", mousedown);
        document.addEventListener("mouseup", mouseup);
        document.addEventListener("selectionchange", selectionchange);
        return () => {
            document.removeEventListener("mousedown", mousedown);
            document.removeEventListener("mouseup", mouseup);
            document.removeEventListener("selectionchange", selectionchange);
        };
    }, [onChange], [disabled], true);
}
