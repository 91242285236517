import { atom } from "jotai";
import { isEmpty, mapValues, pick, uniq } from "lodash";
import { mapEntries } from "../../../../utils/generic/objects";
import { uuidv4 } from "../../../../utils/identity/uuidv4";
import { ANY_MARKER } from "../consts/ANY";
import { queryStateAtoms } from "../queryStateAtoms";
import { mergeNamedMembers } from "../semanticSearch/mergeNamedMembers";
import { mergeRelationClusters } from "../semanticSearch/mergeRelationClusters";
import { ConceptSource } from "../types/ConstraintModelState";
import { TwoKey } from "../types/DoubleDict";
import { CorpusFilterBuilder } from "./CorpusFilterBuilder";
import { findFirstConstraint } from "./constraintOrdering";
import { a_activeConstraint } from "./constraints";
export class ConstraintModelUtil {
    constructor(model) {
        this.model = model;
    }
    //////////////////////////////////////////
    // Concept methods
    //////////////////////////////////////////
    getConceptNodes(conceptSource) {
        return conceptSource === ConceptSource.EXTENDED
            ? this.model.config.extendedConceptNodes
            : this.model.state.conceptNodes;
    }
    getConceptIdsForNodeIds(conceptSource, nodeIds, excludeIds = []) {
        const nodes = this.getConceptNodes(conceptSource);
        return uniq(nodeIds.flatMap(nId => (nodes[nId] || []).filter(c => !excludeIds.includes(c))));
    }
    getConceptsForNodeIds(conceptSource, nodeIds, excludeIds = []) {
        return pick(this.model.data.concepts, this.getConceptIdsForNodeIds(conceptSource, nodeIds, excludeIds));
    }
    getConceptListForNodeIds(conceptSource, nodeIds, excludeIds = []) {
        return Object.values(this.getConceptsForNodeIds(conceptSource, nodeIds, excludeIds));
    }
    getConceptNodeIds(constraint) {
        return [
            constraint.sourceNodeId,
            constraint.targetNodeId,
            ...constraint.contextNodeIds,
        ];
    }
    getAllConceptNodeIds() {
        return Object.values(this.model.state.constraints).flatMap(c => this.getConceptNodeIds(c));
    }
    getAllConceptIds(conceptSource, constraint) {
        return this.getConceptIdsForNodeIds(conceptSource, this.getConceptNodeIds(constraint));
    }
    getAllNodeIdsWithConceptIds(conceptSource, constraint) {
        return this.getConceptNodeIds(constraint).map(nId => [nId, this.getConceptIdsForNodeIds(conceptSource, [nId])]);
    }
    getConstraintConceptPosition(constraint, conceptId, countEmptySlots = false) {
        const sourceNodeId = constraint.sourceNodeId;
        const targetNodeId = constraint.targetNodeId;
        const contextNodeIds = constraint.contextNodeIds;
        let modifier = 1;
        const sourceConceptIds = this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
            sourceNodeId,
        ]);
        if (sourceConceptIds.includes(conceptId))
            return sourceConceptIds.indexOf(conceptId) + modifier;
        modifier += sourceConceptIds.length;
        if (countEmptySlots && !sourceConceptIds.length)
            modifier += 1;
        const targetConceptIds = this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
            targetNodeId,
        ]);
        if (targetConceptIds.includes(conceptId))
            return targetConceptIds.indexOf(conceptId) + modifier;
        modifier += targetConceptIds.length;
        if (countEmptySlots && !targetConceptIds.length)
            modifier += 1;
        const contextConceptIds = this.getConceptIdsForNodeIds(ConceptSource.MODEL, contextNodeIds);
        if (contextConceptIds.includes(conceptId))
            return contextConceptIds.indexOf(conceptId) + modifier;
        return null;
    }
    getWatchlistConceptPosition(conceptId) {
        const watchlistConceptIds = this.getExtendedConceptIds();
        const index = watchlistConceptIds.indexOf(conceptId);
        return index === -1 ? null : 1 + index;
    }
    getMemberPathTable(conceptSource, nodeIds) {
        // returns a list in the form [nodeId, parentConceptId, leafConceptId, memberId][]
        const table = nodeIds.flatMap(nId => this.getConceptIdsForNodeIds(conceptSource, [nId])
            .map(cId => [nId, cId])
            .flatMap(([nId, cId]) => [
            cId,
            ...this.getOverrideIdsForNodeConceptId(conceptSource, nId, cId),
            ...this.getSolutionIdsForNodeConceptId(nId, cId),
        ].flatMap(leafConceptId => {
            var _a;
            return (((_a = this.model.data.concepts[leafConceptId]) === null || _a === void 0 ? void 0 : _a.members) || []).map(m => [nId, cId, leafConceptId, m.id]);
        })));
        return table;
    }
    getMemberIdToConceptPathTable(conceptSource, nodeIds) {
        const table = this.getMemberPathTable(conceptSource, nodeIds);
        return table.reduce((acc, [nId, pcId, lcId, mId]) => {
            acc[mId] = acc[mId] || [];
            const path = [nId, pcId, lcId];
            acc[mId].push(path);
            return acc;
        }, {});
    }
    //////////////////////////////////////////
    // Override methods
    //////////////////////////////////////////
    getOverrideIdsForNodeConceptId(conceptSource, nodeId, conceptId) {
        const nodes = this.getConceptNodes(conceptSource);
        const existentConceptId = (nodes[nodeId] || []).find(c => c === conceptId);
        if (!existentConceptId)
            return [];
        return TwoKey.get(this.model.config.overrides, nodeId, conceptId) || [];
    }
    getOverridesForNodeConceptId(conceptSource, nodeId, conceptId) {
        return (this.getOverrideIdsForNodeConceptId(conceptSource, nodeId, conceptId)
            .map(id => this.model.data.concepts[id])
            .filter(Boolean) || []);
    }
    getOverridesForNodeIds(conceptSource, nodeIds, excludeConceptIds = []) {
        const nodes = this.getConceptNodes(conceptSource);
        const entries = nodeIds.flatMap(nId => {
            const node = nodes[nId];
            if (!node)
                return [[nId, []]];
            const overrideMap = node
                .filter(c => !excludeConceptIds.includes(c))
                .map(c => [
                c,
                this.getOverrideIdsForNodeConceptId(conceptSource, nId, c)
                    .map(id => this.model.data.concepts[id])
                    .filter(Boolean),
            ]);
            return overrideMap;
        });
        return Object.fromEntries(entries);
    }
    isConceptOverriden(conceptSource, nodeId, conceptId) {
        const overrideIds = this.getOverrideIdsForNodeConceptId(conceptSource, nodeId, conceptId);
        return overrideIds.length > 0;
    }
    //////////////////////////////////////////
    // Solution methods
    //////////////////////////////////////////
    getSolutionIdsForNodeConceptId(nodeId, conceptId) {
        const nodes = this.getConceptNodes(ConceptSource.MODEL);
        const existentConceptId = (nodes[nodeId] || []).find(c => c === conceptId);
        if (!existentConceptId)
            return [];
        return TwoKey.get(this.model.solution.solutions, nodeId, conceptId) || [];
    }
    getSolutionsForNodeIds(nodeIds, excludeConceptIds = []) {
        const nodes = this.getConceptNodes(ConceptSource.MODEL);
        const entries = nodeIds.flatMap(nId => {
            const node = nodes[nId];
            if (!node)
                return [[nId, []]];
            const solutionMap = node
                .filter(c => !excludeConceptIds.includes(c))
                .map(c => [
                c,
                this.getSolutionIdsForNodeConceptId(nId, c)
                    .map(id => this.model.data.concepts[id])
                    .filter(Boolean),
            ]);
            return solutionMap;
        });
        return Object.fromEntries(entries);
    }
    //////////////////////////////////////////
    // Required methods
    //////////////////////////////////////////
    getRequiredIdsForNodeIds(conceptSource, nodeIds) {
        const nodes = this.getConceptNodes(conceptSource);
        return nodeIds.flatMap(nId => (nodes[nId] || []).filter(c => TwoKey.get(this.model.config.requiredConcepts, nId, c)));
    }
    isConceptRequired(nodeId, conceptId) {
        return (TwoKey.get(this.model.config.requiredConcepts, nodeId, conceptId) || false);
    }
    //////////////////////////////////////////
    // Relation methods
    //////////////////////////////////////////
    getAllRelationNodeIds() {
        return Object.values(this.model.state.constraints).map(c => c.relationNodeId);
    }
    getAllRelationIds(constraint, excludeRelationIds = []) {
        return this.getRelationIdsForNodeIds([constraint.relationNodeId], excludeRelationIds);
    }
    getAllRelations(constraint, excludeRelationIds = []) {
        return this.getRelationsForNodeIds([constraint.relationNodeId], excludeRelationIds);
    }
    getRelationIdsForNodeIds(nodeIds, excludeRelationIds = []) {
        return uniq(nodeIds.flatMap(nId => (this.model.state.relationNodes[nId] || []).filter(rId => !excludeRelationIds.includes(rId))));
    }
    getRelationsForNodeIds(nodeIds, excludeRelationIds = []) {
        return this.getRelationIdsForNodeIds(nodeIds, excludeRelationIds)
            .map(id => this.model.data.relations[id])
            .filter(Boolean);
    }
    //////////////////////////////////////////
    // Qualifier methods
    //////////////////////////////////////////
    getAllQualifierNodeIds() {
        return Object.values(this.model.state.constraints).flatMap(c => Object.values(c.qualifierNodeIds));
    }
    getQualifierNodeId(constraint, qualiferKey) {
        var _a;
        return (_a = constraint.qualifierNodeIds[qualiferKey]) !== null && _a !== void 0 ? _a : uuidv4();
    }
    getQualifierKeys(constraint) {
        return uniq(Object.keys(constraint.qualifierNodeIds));
    }
    getRequiredQualifierKeys(constraint, excludeIds = []) {
        const required_arguments = Object.entries(constraint.qualifierNodeIds || {})
            .filter(([_, id]) => id === ANY_MARKER)
            .map(([argName]) => argName)
            .filter(argName => !excludeIds.includes(argName));
        return required_arguments;
    }
    getQualifierIdsForNodeIds(qualifierNodeIds) {
        return uniq(qualifierNodeIds.flatMap(qId => this.model.config.qualifiers[qId] || []));
    }
    getQualiferIdsForKey(constraint, qualifierKey) {
        return this.getQualifierIdsForNodeIds([
            this.getQualifierNodeId(constraint, qualifierKey),
        ]);
    }
    getQualifierIdMap(constraint) {
        return Object.fromEntries(Object.entries(constraint.qualifierNodeIds)
            .filter(([_, id]) => id !== ANY_MARKER // should happen implicitly but here for clarity
        )
            .map(([key, qualifierNodeId]) => [
            key,
            this.getQualifierIdsForNodeIds([qualifierNodeId]),
        ]));
    }
    getQualiferMap(constraint) {
        return mapValues(this.getQualifierIdMap(constraint), ids => ids.map(id => this.model.data.clauses[id]).filter(Boolean));
    }
    getSelectedClausesForNodeIds(qualifierNodeIds) {
        return Object.values(pick(this.model.data.clauses, this.getQualifierIdsForNodeIds(qualifierNodeIds)));
    }
    getSelectedClausesForKey(constraint, qualifierKey) {
        return this.getSelectedClausesForNodeIds([
            this.getQualifierNodeId(constraint, qualifierKey),
        ]);
    }
    getAllSelectedClauseIdsByType(constraint) {
        return Object.fromEntries(this.getQualifierKeys(constraint).map(key => [
            key,
            this.getQualiferIdsForKey(constraint, key),
        ]));
    }
    getClauseTextIndex(constraint) {
        return Object.fromEntries(Object.entries(this.getAllSelectedClauseIdsByType(constraint)).flatMap(([key, ids]) => {
            const entries = ids.map(id => {
                const clause = this.model.data.clauses[id];
                const clauseTextsOrName = [
                    clause.name,
                    ...clause.members.map(m => [m.id, ...m.surface_forms]).flat(),
                ];
                const result = clauseTextsOrName.map(text => [text, { clauseType: key, clauseId: id }]);
                return result;
            });
            return entries.flat();
        }));
    }
    //////////////////////////////////////////
    // Extended methods
    //////////////////////////////////////////
    getExtendedConceptIds() {
        return this.model.config.extendedNodeIds.flatMap(nId => this.model.config.extendedConceptNodes[nId] || []);
    }
    getExtendedConcepts() {
        return pick(this.model.data.concepts, this.getExtendedConceptIds());
    }
    //////////////////////////////////////////
    // Indicator methods
    //////////////////////////////////////////
    isConstraintEmpty(constraint) {
        return (this.getAllConceptIds(ConceptSource.MODEL, constraint).length === 0 &&
            this.getAllRelationIds(constraint).length === 0 &&
            !constraint.text);
    }
    areAllConstraintsEmpty() {
        return (isEmpty(this.model.state.constraints) ||
            Object.values(this.model.state.constraints).every(c => this.isConstraintEmpty(c)));
    }
    isModelEmpty() {
        //TODO
        return (this.areAllConstraintsEmpty() &&
            isEmpty(this.model.config.booleanMetadata) &&
            isEmpty(this.model.config.keywordMetadata) &&
            isEmpty(this.model.config.rangeMetadata) &&
            isEmpty(this.model.config.extendedNodeIds));
    }
    hasConstraintOverrides(constraint) {
        return this.getAllNodeIdsWithConceptIds(ConceptSource.MODEL, constraint).some(([nodeId, conceptIds]) => !isEmpty(conceptIds) &&
            conceptIds.some(cId => this.isConceptOverriden(ConceptSource.MODEL, nodeId, cId) ||
                this.isConceptRequired(nodeId, cId)));
    }
    hasQualifierFilters(constraint) {
        return !isEmpty(constraint.qualifierNodeIds);
    }
    hasMetadataFilters() {
        return (!isEmpty(this.model.config.booleanMetadata) ||
            !isEmpty(this.model.config.keywordMetadata) ||
            !isEmpty(this.model.config.rangeMetadata));
    }
    hasExtendedOverrides(extraNodeIds) {
        return [...extraNodeIds, ...this.model.config.extendedNodeIds]
            .flatMap(nId => (this.model.config.extendedConceptNodes[nId] || []).map(cId => [nId, cId]))
            .some(([nId, cId]) => (TwoKey.get(this.model.config.overrides, nId, cId) || []).length >
            0 ||
            TwoKey.get(this.model.config.requiredConcepts, nId, cId) ||
            false);
    }
    isConstraintFiltered(constraint, extraNodeIds) {
        return Boolean(this.hasConstraintOverrides(constraint) ||
            this.hasQualifierFilters(constraint) ||
            this.hasMetadataFilters() ||
            this.hasExtendedOverrides(extraNodeIds));
    }
    onlySourceEmpty(constraint) {
        return (this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
            constraint.sourceNodeId,
        ]).length === 0 &&
            this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
                constraint.targetNodeId,
            ]).length > 0);
    }
    onlyTargetEmpty(constraint) {
        return (this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
            constraint.targetNodeId,
        ]).length === 0 &&
            this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
                constraint.sourceNodeId,
            ]).length > 0);
    }
    //////////////////////////////////////////
    // Constraint methods
    //////////////////////////////////////////
    getActiveConstraint() {
        return ((this.model.activeConstraintId &&
            this.model.state.constraints[this.model.activeConstraintId]) ||
            null);
    }
    getFirstConstraint() {
        return (findFirstConstraint(Object.values(this.model.state.constraints)) || null);
    }
    //////////////////////////////////////////
    // Conversion methods
    //////////////////////////////////////////
    // TODO: somewhat replicates prior behavior but should be removed
    toPsuedoOverrides() {
        let overrides = {};
        Object.entries(this.getConceptNodes(ConceptSource.MODEL)).forEach(([nId, cIds]) => {
            cIds.forEach(c => {
                const overrideIds = this.getOverrideIdsForNodeConceptId(ConceptSource.MODEL, nId, c);
                if (overrideIds.length > 0) {
                    const overrideConcepts = overrideIds
                        .map(id => this.model.data.concepts[id])
                        .filter(Boolean);
                    overrides = TwoKey.set(overrides, c, c, overrideConcepts); // c, c is intentional
                }
            });
        });
        return overrides;
    }
    toDMParams() {
        const dmParams = {
            concepts: {},
            relations: {},
            clauses: {},
            constraints: [],
        };
        const constraints = Object.values(this.model.state.constraints).map(constraint => {
            const sources = this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
                constraint.sourceNodeId,
            ]);
            const targets = this.getConceptIdsForNodeIds(ConceptSource.MODEL, [
                constraint.targetNodeId,
            ]);
            const context = this.getConceptIdsForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds);
            const constraintConcepts = pick(this.model.data.concepts, [
                ...sources,
                ...targets,
                ...context,
            ]);
            dmParams.concepts = Object.assign(Object.assign({}, dmParams.concepts), constraintConcepts);
            const relations = this.getAllRelationIds(constraint);
            const relationClusters = pick(this.model.data.relations, relations);
            const mergedRelation = mergeRelationClusters(Object.values(relationClusters))[0];
            let relation = null;
            if (mergedRelation) {
                relation = relations.join("/");
                dmParams.relations = Object.assign(Object.assign({}, dmParams.relations), { [relation]: mergedRelation });
            }
            const qualifiersIdMap = mapEntries(constraint.qualifierNodeIds, ([qKey, qNodeId]) => [
                qKey,
                qNodeId === ANY_MARKER
                    ? [qNodeId]
                    : this.getQualiferIdsForKey(constraint, qKey),
            ]);
            Object.entries(qualifiersIdMap).forEach(([qKey, qIds]) => {
                if (qKey === ANY_MARKER)
                    return;
                const clauses = pick(this.model.data.clauses, qIds);
                const mergedClauses = mergeNamedMembers(Object.values(clauses));
                if (mergedClauses) {
                    dmParams.clauses[qIds.join("/")] = mergedClauses;
                }
            });
            const qualifiers = mapEntries(qualifiersIdMap, ([qKey, qIds]) => [
                qKey,
                qIds.includes(ANY_MARKER) ? ANY_MARKER : qIds.join("/"),
            ]);
            return {
                id: constraint.id,
                sources,
                targets,
                context,
                relation,
                qualifiers,
                is_directed: constraint.is_directed,
                text: constraint.text,
            };
        });
        dmParams.constraints = constraints;
        return dmParams;
    }
    buildCorpusFilter(corpus_ids, aperture, constraint) {
        return new CorpusFilterBuilder(corpus_ids, aperture, constraint, this);
    }
}
export const a_constraintModelUtil = atom(get => {
    const model = get(queryStateAtoms.constraintModel);
    return new ConstraintModelUtil(model);
});
export const a_corpusFilterBuilder = atom(get => {
    const { corpus_ids, aperture } = get(queryStateAtoms.scope);
    const model = get(queryStateAtoms.constraintModel);
    const constraint = get(a_activeConstraint);
    return new ConstraintModelUtil(model).buildCorpusFilter(corpus_ids, aperture, constraint);
});
export const a_activeConstraintIsEmpty = atom(get => {
    const model = get(a_constraintModelUtil);
    const constraint = get(a_activeConstraint);
    return constraint ? model.isConstraintEmpty(constraint) : true;
});
