import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { VisualStatusMessage } from "../common/status/VisualStatusMessage";
import { CollapseToggle } from "../evidence/cards/GroupCollapse";
import { TextWithEvidenceReferences } from "./TextWithEvidenceReferences";
export function AnswerCard({ question, answer, loading, getEvidenceWithPosition, }) {
    const [collapsed, setCollapsed] = React.useState(false);
    const answerSupported = useIsFeatureSupported(Feature.ANSWER_GENERATION);
    if (!answerSupported)
        return null;
    let answerBody = null;
    if (loading) {
        answerBody = (_jsx(VisualStatusMessage, { status: "loading", message: "Generating answer...", style: { paddingLeft: 24 } }));
    }
    else if (answer.answer === "") {
        answerBody = (_jsx(VisualStatusMessage, { status: "empty", message: "No answer generated.", style: { paddingLeft: 24 } }));
    }
    else {
        answerBody = (_jsx("div", Object.assign({ style: { paddingLeft: 24, maxHeight: "20vh", overflowY: "scroll" } }, { children: _jsx(TextWithEvidenceReferences, { text: answer.answer, references: answer.references, showReferences: true, showHiddenReferences: true, getEvidenceWithPosition: getEvidenceWithPosition }) })));
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start space-y-2 pb-2 whitespace-pre-wrap" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("span", Object.assign({ className: "text-black font-bold" }, { children: "Answer" })), _jsxs("span", Object.assign({ className: "text-gray-600 font-semibold" }, { children: [" ", "(", question, !question.endsWith("?"), ")"] })), _jsx(CollapseToggle, { className: "ml-2", collapsed: collapsed, setCollapsed: setCollapsed })] })), !collapsed && answerBody] })));
}
