import { useAtomValue } from "jotai";
import { uniq } from "lodash";
import { a_constraintModelUtil } from "../constraintModel/ConstraintModelUtil";
import { queryStateAtoms } from "../queryStateAtoms";
import { getMetadataFilter } from "../semanticSearch/getMetadataFilter";
import { mergeNamedMembersToList } from "../semanticSearch/mergeNamedMembers";
import { mergeRelationClusters } from "../semanticSearch/mergeRelationClusters";
import { ConceptSource } from "../types/ConstraintModelState";
export function useConjunctionBindingsRequest(search_width, count) {
    const { corpus_ids, aperture } = useAtomValue(queryStateAtoms.scope);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const query = useConjunctionBindingsQuery();
    return (query && Object.assign({ query,
        corpus_ids,
        aperture,
        count, search_width: Math.max(search_width, count) }, getMetadataFilter({
        keywordMetadata: modelUtil.model.config.keywordMetadata,
        rangeMetadata: modelUtil.model.config.rangeMetadata,
        booleanMetadata: modelUtil.model.config.booleanMetadata,
    })));
}
function useConjunctionBindingsQuery() {
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const constraintStates = modelUtil.model.state.constraints;
    const conceptNodesInModel = modelUtil.getAllConceptNodeIds();
    const concepts = conceptNodesInModel.reduce((acc, cNodeId) => {
        const conceptIds = modelUtil.model.state.conceptNodes[cNodeId] || [];
        const entries = conceptIds
            .filter(cId => modelUtil.getOverrideIdsForNodeConceptId(ConceptSource.MODEL, cNodeId, cId).length > 0)
            .map(cId => {
            const overrides = modelUtil.getOverridesForNodeConceptId(ConceptSource.MODEL, cNodeId, cId);
            const merged = mergeNamedMembersToList(overrides);
            if (merged.length === 0)
                return;
            return [[cNodeId, cId].join("/"), merged[0]];
        })
            .filter(Boolean);
        return Object.assign(Object.assign({}, acc), Object.fromEntries(entries));
    }, {});
    const types = Object.fromEntries(conceptNodesInModel.flatMap(cNodeId => {
        const conceptIds = modelUtil.model.state.conceptNodes[cNodeId] || [];
        const entries = conceptIds
            .filter(cId => modelUtil.getOverrideIdsForNodeConceptId(ConceptSource.MODEL, cNodeId, cId).length === 0)
            .map(cId => {
            return [
                [cNodeId, cId].join("/"),
                modelUtil.model.data.concepts[cId].members.map(m => m.id),
            ];
        });
        return entries;
    }));
    const bindings_for = uniq(conceptNodesInModel.flatMap(cNodeId => {
        const conceptIds = modelUtil.model.state.conceptNodes[cNodeId] || [];
        return conceptIds
            .filter(cId => modelUtil.getOverrideIdsForNodeConceptId(ConceptSource.MODEL, cNodeId, cId).length === 0)
            .map(cId => [cNodeId, cId].join("/"));
    }));
    const relationNodesInModel = modelUtil.getAllRelationNodeIds();
    const relations = Object.fromEntries(relationNodesInModel
        .map(rNodeId => {
        const relationIds = modelUtil.model.state.relationNodes[rNodeId] || [];
        const relations = relationIds
            .map(rId => modelUtil.model.data.relations[rId])
            .filter(Boolean);
        const merged = mergeRelationClusters(relations);
        if (!merged.length)
            return;
        return [rNodeId, merged[0]];
    })
        .filter(Boolean));
    const qualifierNodesInModel = modelUtil.getAllQualifierNodeIds();
    const clauses = qualifierNodesInModel.reduce((acc, qNodeId) => {
        const qualifiers = (modelUtil.model.config.qualifiers[qNodeId] || [])
            .map(qId => modelUtil.model.data.clauses[qId])
            .filter(Boolean);
        const qualifier = mergeNamedMembersToList(qualifiers);
        if (!qualifier.length)
            return acc;
        return Object.assign(Object.assign({}, acc), { [qNodeId]: qualifier[0] });
    }, {});
    const constraints = Object.values(constraintStates).map(constraint => {
        return {
            id: constraint.id,
            sources: (modelUtil.model.state.conceptNodes[constraint.sourceNodeId] || []).map(cId => [constraint.sourceNodeId, cId].join("/")),
            targets: (modelUtil.model.state.conceptNodes[constraint.targetNodeId] || []).map(cId => [constraint.targetNodeId, cId].join("/")),
            relation: constraint.relationNodeId,
            context: (constraint.contextNodeIds || []).flatMap(cNodeId => (modelUtil.model.state.conceptNodes[cNodeId] || []).map(cId => [cNodeId, cId].join("/"))),
            qualifiers: constraint.qualifierNodeIds || {},
            text: constraint.text,
            is_directed: constraint.is_directed,
        };
    });
    if (!bindings_for.length)
        return undefined;
    return {
        concepts,
        relations,
        clauses,
        types,
        constraints,
        bindings_for,
    };
}
