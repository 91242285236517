var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FeatureIdentifier, FeedbackType } from "../../api/types/apiTypes";
import { CorpusCapability } from "../../api/utils/capabilities";
import { classes } from "../../appState/context/theme/classes";
import { brandButtonClass } from "../../appState/context/theme/lf";
import { sendFeedback } from "../../utils/feedback/sendFeedback";
import { ThumbsDown, ThumbsUp } from "../common/icons/Icons";
import { showNotification } from "../common/status/notifications";
import { FacetButton } from "./facetButtons";
export function defaultFeedbackProvider(category, feedback, setFeedback) {
    return () => (item, items, params) => {
        const key = category + item.name;
        function createButton(type) {
            return (_jsx(FacetButton, Object.assign({ className: classes("cursor-pointer  hover:border-gray-400", (feedback === null || feedback === void 0 ? void 0 : feedback[key]) === type && brandButtonClass, (feedback === null || feedback === void 0 ? void 0 : feedback[key]) !== type && "auto_hide"), onClick: () => __awaiter(this, void 0, void 0, function* () {
                    if ((feedback === null || feedback === void 0 ? void 0 : feedback[key]) === type)
                        return;
                    yield sendFeedback(FeatureIdentifier.ASPECTS, CorpusCapability.SEARCH_CONCEPTS, FeedbackType.NEGATIVE, item, params, items);
                    setFeedback === null || setFeedback === void 0 ? void 0 : setFeedback(f => (Object.assign(Object.assign({}, f), { [key]: type })));
                    showNotification("Feedback sent");
                }) }, { children: type === FeedbackType.NEGATIVE ? (_jsx(ThumbsDown, { className: "text-tiny" })) : (_jsx(ThumbsUp, { className: "text-tiny" })) })));
        }
        return !feedback || !setFeedback ? null : (_jsxs("div", Object.assign({ className: "flex space-x-1" }, { children: [createButton(FeedbackType.POSITIVE), createButton(FeedbackType.NEGATIVE)] })));
    };
}
