import dagre from "dagre";
import { range } from "lodash";
import React from "react";
import { updateItems } from "../../utils/generic/collections";
import { X, Y } from "./Axis";
import { getDiagonal } from "./collisions";
const { alg, Graph } = dagre.graphlib;
function groupByAxis(ga, axis) {
    const { nodes } = ga;
    const [from, to] = axis === X ? [0, 2] : [1, 3];
    const [oFrom, oTo] = axis === X ? [0, 2] : [1, 3];
    const graph = new Graph({ directed: false });
    nodes.forEach(n => graph.setNode(n.id, n.id));
    range(nodes.length).forEach(i => range(i + 1, nodes.length).forEach(j => {
        const a = nodes[i];
        const b = nodes[j];
        const da = getDiagonal(a);
        const db = getDiagonal(b);
        const [aFrom, aTo] = [da[from], da[to]];
        const [bFrom, bTo] = [db[from], db[to]];
        if (!(bFrom > aTo || aFrom > bTo)) {
            graph.setEdge(a.id, b.id);
        }
    }));
    const comp = (a, b) => getDiagonal(ga.node(a))[oFrom] - getDiagonal(ga.node(b))[oFrom];
    const tracks = alg
        .components(graph)
        .map(group => group.sort(comp))
        .sort((a, b) => comp(a[0], b[0]));
    return {
        tracks,
        coords: tracks.reduce((acc, track, tIdx) => track.reduce((acc, id, idx) => {
            acc[id] = [tIdx, idx];
            return acc;
        }, acc), {}),
    };
}
function getNodeAt(ga, nodeId, axis, dir) {
    var _a;
    const diags = ga.nodes.reduce((acc, n) => {
        acc[n.id] = getDiagonal(n);
        return acc;
    }, {});
    const { tracks, coords } = groupByAxis(ga, axis);
    let [tIdx, idx] = coords[nodeId];
    idx += dir;
    let tLen = tracks[tIdx].length;
    if (idx < 0 || idx >= tLen) {
        tIdx += dir;
        tLen = ((_a = tracks[tIdx]) === null || _a === void 0 ? void 0 : _a.length) || 0;
        idx = tIdx < 0 || tIdx >= tracks.length ? -1 : dir > 0 ? 0 : tLen - 1;
    }
    return idx >= 0 && idx < tLen ? tracks[tIdx][idx] : undefined;
}
export function useNavigationActions({ ga, selectedNode, dUpdater, flow, }) {
    return React.useCallback(() => {
        return {
            extra: [
                create("up", Y, -1),
                create("down", Y, 1),
                create("left", X, -1),
                create("right", X, 1),
            ],
        };
        function create(shortcut, axis, dir) {
            return {
                shortcut: shortcut,
                disabled: !selectedNode,
                action: () => {
                    const id = getNodeAt(ga, selectedNode.id, axis, dir);
                    id &&
                        dUpdater.commit({
                            nodes: updateItems(flow.getNodes(), n => ({
                                selected: n.id === id,
                            })),
                        });
                },
            };
        }
    }, [ga, selectedNode]);
}
