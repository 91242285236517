import { jsx as _jsx } from "react/jsx-runtime";
import { Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { showDialog } from "../../../utils/dialog/dialog";
import { ValidationIcon } from "../forms/InputValidation";
function Prompt({ title, value, placeholder, onValue, required = true, textArea, hideCancel, maxLength, okText, }) {
    const [text, setText] = React.useState(value || "");
    const ref = React.useRef(null);
    const error = required && !text.trim().length ? "Value is Required" : null;
    React.useEffect(() => {
        setTimeout(() => {
            var _a;
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus({ cursor: "all" });
        }, 100);
    }, []);
    const disabled = !!error;
    const props = {
        bordered: textArea,
        className: "flex-1",
        value: text,
        onChange: (e) => setText(e.target.value),
        placeholder,
        autoFocus: true,
        maxLength,
        onKeyPress: (e) => e.key === "Enter" && !disabled && onValue(text.trim()),
    };
    const input = textArea ? (_jsx(TextArea, Object.assign({ ref: ref }, props))) : (_jsx(Input, Object.assign({ ref: ref, allowClear: true, suffix: _jsx(ValidationIcon, { error: error }) }, props)));
    return (_jsx(Modal, Object.assign({ open: true, okText: okText, onOk: () => onValue(text.trim()), okButtonProps: { disabled }, onCancel: () => onValue(null), cancelButtonProps: { hidden: hideCancel }, title: title, getContainer: false }, { children: input })));
}
export function textPrompt(props) {
    return showDialog(onValue => _jsx(Prompt, Object.assign({ onValue: onValue }, props)));
}
