var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EllipsisOutlined } from "@ant-design/icons/lib";
import React from "react";
import { getCaption } from "../../../api/utils/url";
import { useEvidenceSelection } from "../../../appState/atomic/evidence/useEvidenceSelection";
import { useAnnotationProvider } from "../../../appState/atomic/queryState/useAnnotationProvider";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { classes } from "../../../appState/context/theme/classes";
import { ActionButton } from "../../common/buttons/ActionButton";
import { DropDownMenu } from "../../common/popups/DropDownMenu";
import { Tooltip } from "../../common/popups/Tooltip";
import { EvidenceCardTitle } from "../evidenceDisplay/EvidenceCardTitle";
import { EvidenceFooter } from "../evidenceDisplay/EvidenceFooter";
import { EvidenceRow } from "../evidenceDisplay/EvidenceRow";
import { RenderNiceMetadata } from "../evidenceDisplay/RenderNiceMetadata";
import { useEvidenceTextSelection } from "../useEvidenceTextSelection";
import { useRenderEvidenceActions } from "../useRenderEvidenceActions";
export function EvidenceCard(_a) {
    var { evidence_id, onSelect, className, selected, highlighted, urlOpenerFn, filterText, actions, extraItemActions, renderAsHtml, onRemove, showSurrounding, onMetadataClick, showHighlights, actionsTooltip, metadataSorter } = _a, props = __rest(_a, ["evidence_id", "onSelect", "className", "selected", "highlighted", "urlOpenerFn", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onMetadataClick", "showHighlights", "actionsTooltip", "metadataSorter"]);
    const { evidenceSelected, selectEvidence, deselectEvidence } = useEvidenceSelection();
    const { getEvidence } = useEvidenceSearchUtils();
    const renderEvidenceActions = useRenderEvidenceActions();
    const onEvidenceTextSelected = useEvidenceTextSelection();
    const annotationProvider = useAnnotationProvider();
    const evidence = getEvidence(evidence_id);
    if (!evidence)
        return null;
    const caption = getCaption(evidence.instances[0]);
    const selectEvidenceHelper = (item) => evidenceSelected(item.id)
        ? deselectEvidence([item.id])
        : selectEvidence([item.id]);
    return (evidence && (_jsx("div", Object.assign({ className: classes("relative flex flex-col items-stretch py-2 mb-2 font-sans hover:bg-brand-100 border-b border-b-gray-400", (selected === null || selected === void 0 ? void 0 : selected(evidence.id)) ? "border-gray-300" : "border-gray-300", className) }, props, { children: _jsxs("div", Object.assign({ className: " flex flex-col items-stretch" }, { children: [_jsxs("div", Object.assign({ className: "pb-2 pl-2" }, { children: [_jsx(EvidenceCardTitle, { title: caption || "", onOpen: () => {
                                urlOpenerFn === null || urlOpenerFn === void 0 ? void 0 : urlOpenerFn(evidence, evidence.instances[0]);
                            } }), _jsx(RenderNiceMetadata, { className: "text-sm text-gray-600", evidence: evidence })] })), _jsx(EvidenceRow, { className: "pl-2", evidence: evidence, documentId: evidence.instances[0].document_id, filterText: filterText, showSurrounding: showSurrounding, renderAsHtml: renderAsHtml, isSelected: evidenceSelected, isHighlighted: highlighted, selectEvidence: selectEvidenceHelper, removeEvidence: onRemove, openEvidence: urlOpenerFn, onEvidenceTextSelected: onEvidenceTextSelected, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions }), _jsx(EvidenceFooter, { className: "mt-2 px-4", evidence: evidence, onMetadataClick: onMetadataClick, metadataSorter: metadataSorter, showHighlights: showHighlights, urlOpener: urlOpenerFn, selected: selected === null || selected === void 0 ? void 0 : selected(evidence.id), suffix: _jsxs(_Fragment, { children: [actions && (_jsx(Tooltip, Object.assign({ content: actionsTooltip }, { children: _jsx("span", { children: _jsx(DropDownMenu, Object.assign({ entries: () => actions(evidence) }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })) }) }))), extraItemActions] }) })] })) }))));
}
