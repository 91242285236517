var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAtomValue } from "jotai";
import { isEqual } from "lodash";
import React from "react";
import { API } from "../../../api/api";
import { setLoading } from "../../../components/common/transitions/Loading";
import { useEffectIfDifferent } from "../../../utils/lifecycle/useEffectIfDifferent";
import { useLocationChange } from "../../../utils/routing/useLocationChange";
import { loadQueryState } from "../../stateRestoration/loadQueryState";
import { QueryId } from "../../stateRestoration/queryId";
import { EMPTY_QUERY_STATE } from "../../verions/V3_QueryState";
import { a_debouncedQueryState } from "../history/useHistoryUpdater";
import { useQueryState } from "./useQueryState";
import { getEmptyQueryStateForCorpus } from "./utils/getEmptyQueryStateForCorpus";
export function saveQueryState(state) {
    return __awaiter(this, void 0, void 0, function* () {
        return isEqual(state, EMPTY_QUERY_STATE)
            ? ""
            : yield API.getUrlId({ data: stateWithoutSolution(state) });
    });
}
function stateWithoutSolution(state) {
    return Object.assign(Object.assign({}, state), { constraintModel: Object.assign(Object.assign({}, state.constraintModel), { solution: {
                solutions: {},
            } }) });
}
export function useQueryIdSynchronizer() {
    const debouncedQueryState = useAtomValue(a_debouncedQueryState);
    const [, updateQueryState] = useQueryState();
    const latest = React.useRef({});
    useLocationChange(React.useCallback(function loadStateFromQueryId() {
        return __awaiter(this, void 0, void 0, function* () {
            const id = QueryId.get();
            if (id === latest.current.id)
                return;
            try {
                setLoading(true);
                if (!id) {
                    updateQueryState(state => {
                        latest.current = {
                            id,
                            state: getEmptyQueryStateForCorpus(state.scope.corpus_ids),
                        };
                        return latest.current.state;
                    });
                    return;
                }
                latest.current = {
                    id,
                    state: yield loadQueryState(id),
                };
                updateQueryState(latest.current.state);
            }
            catch (err) {
                console.error("Error loading queryId", id, err);
            }
            finally {
                setLoading(false);
            }
        });
    }, []));
    useEffectIfDifferent(function saveStateAndSetQueryId() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isEqual(debouncedQueryState, latest.current.state))
                return;
            latest.current = {
                id: yield saveQueryState(stateWithoutSolution(debouncedQueryState)),
                state: debouncedQueryState,
            };
            QueryId.set(latest.current.id);
        });
    }, [], [debouncedQueryState]);
}
