export function createConstraint(id, sources, text) {
    return {
        id,
        sources,
        relation: null,
        targets: [],
        context: [],
        qualifiers: {},
        text,
    };
}
