import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FeedbackType } from "../../api/types/apiTypes";
import { classes } from "../../appState/context/theme/classes";
import { DotDotDot, ThumbsDown, ThumbsUp, } from "../../components/common/icons/Icons";
export function getFeedbackIcon(feedbackType, iconClass) {
    switch (feedbackType) {
        case FeedbackType.NEGATIVE:
            return _jsx(ThumbsDown, { className: classes("cursor-pointer", iconClass) });
        case FeedbackType.POSITIVE:
            return _jsx(ThumbsUp, { className: classes("cursor-pointer", iconClass) });
        default:
            return _jsx(DotDotDot, { className: classes("cursor-pointer", iconClass) });
    }
}
