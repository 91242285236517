const EMPTY_NODE_MAPS = {
    conceptNodes: {},
    relationNodes: {},
};
const EMPTY_CONSTRAINTS = {
    constraints: {},
};
export const EMPTY_CONSTRAINT_MODEL_STATE = Object.assign(Object.assign({}, EMPTY_NODE_MAPS), EMPTY_CONSTRAINTS);
//enums
export var ConceptSlot;
(function (ConceptSlot) {
    ConceptSlot["SOURCE"] = "sourceNodeId";
    ConceptSlot["TARGET"] = "targetNodeId";
    ConceptSlot["CONTEXT"] = "contextNodeIds";
})(ConceptSlot || (ConceptSlot = {}));
export var ConstraintSlot;
(function (ConstraintSlot) {
    ConstraintSlot["SOURCE"] = "sourceNodeId";
    ConstraintSlot["TARGET"] = "targetNodeId";
    ConstraintSlot["CONTEXT"] = "contextNodeIds";
    ConstraintSlot["RELATION"] = "relationNodeId";
    ConstraintSlot["QUALIFIER"] = "qualifierNodeIds";
})(ConstraintSlot || (ConstraintSlot = {}));
export var ConceptSource;
(function (ConceptSource) {
    ConceptSource["MODEL"] = "model";
    ConceptSource["EXTENDED"] = "extended";
})(ConceptSource || (ConceptSource = {}));
export var ConceptType;
(function (ConceptType) {
    ConceptType["SOURCE"] = "source";
    ConceptType["TARGET"] = "target";
    ConceptType["CONTEXT"] = "context";
    ConceptType["EXTENDED"] = "extended";
})(ConceptType || (ConceptType = {}));
export const ALL_CONCEPT_TYPES = [
    ConceptType.SOURCE,
    ConceptType.TARGET,
    ConceptType.CONTEXT,
    ConceptType.EXTENDED,
];
export var ClusterSlot;
(function (ClusterSlot) {
    ClusterSlot["SOURCE"] = "sourceNodeId";
    ClusterSlot["TARGET"] = "targetNodeId";
    ClusterSlot["CONTEXT"] = "contextNodeIds";
    ClusterSlot["EXTENDED"] = "extendedContextNodeIds";
    ClusterSlot["RELATION"] = "relationNodeId";
})(ClusterSlot || (ClusterSlot = {}));
export var OverrideType;
(function (OverrideType) {
    OverrideType["OVERRIDE"] = "override";
    OverrideType["SOLUTION"] = "solution";
})(OverrideType || (OverrideType = {}));
const ALL_OVERRIDE_TYPES = [
    OverrideType.OVERRIDE,
    OverrideType.SOLUTION,
];
