import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox, Radio } from "antd";
import React from "react";
import { resolveValue } from "./resolveValue";
export function getActionIcon(params) {
    const icon = resolveValue(params.icon);
    if (icon)
        return icon;
    return "selected" in params ? (params.selectionGroup ? (_jsx(Radio, { className: "mr-0", checked: params.selected })) : (_jsx(Checkbox, { checked: params.selected }))) : null;
}
