var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { FeatureIdentifier, FeedbackType } from "../../../api/types/apiTypes";
import { a_constraintModelUtil } from "../../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../../appState/atomic/queryState/constraintModel/constraints";
import { sendFeedback } from "../../../utils/feedback/sendFeedback";
import { useQueryParams } from "../../../utils/routing/useQueryParams";
import { BorderlessButton } from "../../common/buttons/BorderlessButton";
import { textPrompt } from "../../common/modals/Propmpt";
import { showNotification } from "../../common/status/notifications";
export function OverallFeedback() {
    const [queryParams] = useQueryParams();
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    return modelUtil.isConstraintEmpty(constraint) ? null : (_jsx("div", Object.assign({ className: "flex justify-end space-x-2 text-tiny px-4 rounded-md bg-gray-100 select-none" }, { children: _jsxs("div", Object.assign({ className: "flex space-x-2 items-center" }, { children: [_jsx("span", { children: "Do you find these results to be useful?" }), _jsx(BorderlessButton, Object.assign({ className: "text-tiny hover:text-accent-500", onClick: () => feedback(true) }, { children: "Yes" })), _jsx(BorderlessButton, Object.assign({ className: "text-tiny hover:text-accent-500", onClick: () => feedback(false) }, { children: "No" }))] })) })));
    function feedback(positive) {
        return __awaiter(this, void 0, void 0, function* () {
            const comments = yield textPrompt({
                title: positive
                    ? "Any additional comments?"
                    : "Why were these results not useful?",
                required: false,
                textArea: true,
                maxLength: 500,
                placeholder: "Optional comments.(max length 500)..",
                okText: "Submit",
            });
            if (comments === null)
                return;
            yield sendFeedback(FeatureIdentifier.OVERALL, "", positive ? FeedbackType.POSITIVE : FeedbackType.NEGATIVE, null, queryParams, undefined, comments);
            showNotification("Thank you for your feedback");
        });
    }
}
