import { useAtom } from "jotai";
import React from "react";
import { useCurrentSource } from "../../api/useCurrentSource";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
export function useEvidenceSort() {
    const [{ sortFieldId, sortOrder }, setSearchParams] = useAtom(queryStateAtoms.searchParams);
    const source = useCurrentSource();
    const sortFieldOptions = (source === null || source === void 0 ? void 0 : source.sortable_fields) || [];
    const defaultSortField = (source === null || source === void 0 ? void 0 : source.sorting_default_field) || "";
    const defaultSortOrder = source === null || source === void 0 ? void 0 : source.sorting_default_order;
    const updateSort = React.useCallback((sortFieldId, sortOrder) => {
        setSearchParams({
            sortFieldId,
            sortOrder,
        });
    }, [setSearchParams]);
    return {
        sortFieldOptions,
        sortField: sortFieldId || defaultSortField,
        sortOrder: sortOrder || defaultSortOrder,
        updateSort,
    };
}
