var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined } from "@ant-design/icons/lib";
import { Checkbox } from "antd";
import React from "react";
import { getCaption } from "../../../api/utils/url";
import { useEvidenceSelection } from "../../../appState/atomic/evidence/useEvidenceSelection";
import { useAnnotationProvider } from "../../../appState/atomic/queryState/useAnnotationProvider";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { classes } from "../../../appState/context/theme/classes";
import { useUniqId } from "../../../utils/identity/useUniqId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { ActionButton } from "../../common/buttons/ActionButton";
import { DropDownMenu } from "../../common/popups/DropDownMenu";
import { Tooltip } from "../../common/popups/Tooltip";
import { EvidenceCardTitle } from "../evidenceDisplay/EvidenceCardTitle";
import { EvidenceFooter } from "../evidenceDisplay/EvidenceFooter";
import { EvidenceRow } from "../evidenceDisplay/EvidenceRow";
import { RenderNiceMetadata } from "../evidenceDisplay/RenderNiceMetadata";
import { useEvidenceTextSelection } from "../useEvidenceTextSelection";
import { useRenderEvidenceActions } from "../useRenderEvidenceActions";
import { CollapseToggle } from "./CollapseToggle";
export function DocumentCard(_a) {
    var { document_id, className, style, urlOpenerFn, filterText, actions, extraItemActions, renderAsHtml, onRemove, showSurrounding, onMetadataClick, showHighlights, actionsTooltip, metadataSorter, collapsed = false, setCollapsed = () => { } } = _a, props = __rest(_a, ["document_id", "className", "style", "urlOpenerFn", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onMetadataClick", "showHighlights", "actionsTooltip", "metadataSorter", "collapsed", "setCollapsed"]);
    const containerId = useUniqId(uuidv4);
    const renderEvidenceActions = useRenderEvidenceActions();
    const { evidenceSelected, selectEvidence, deselectEvidence } = useEvidenceSelection();
    const { getDocumentEvidence } = useEvidenceSearchUtils();
    const onEvidenceTextSelected = useEvidenceTextSelection();
    const annotationProvider = useAnnotationProvider();
    const documentEvidence = getDocumentEvidence(document_id);
    const documentEvidenceIdList = (documentEvidence === null || documentEvidence === void 0 ? void 0 : documentEvidence.map(x => x.id)) || [];
    const someSelected = documentEvidenceIdList.some(evidenceSelected);
    const allSelected = documentEvidenceIdList.every(evidenceSelected);
    if (!documentEvidence)
        return null;
    const allEvidenceText = documentEvidence.map(x => x.text);
    const primaryEvidence = documentEvidence[0];
    const caption = getCaption(primaryEvidence.instances[0]);
    const selectionCount = documentEvidence.filter(e => evidenceSelected(e.id)).length;
    const selectEvidenceHelper = (item) => evidenceSelected(item.id)
        ? deselectEvidence([item.id])
        : selectEvidence([item.id]);
    return (_jsx("div", Object.assign({ className: classes("relative flex flex-col items-stretch py-3 font-sans border-neutral-300 border-b border-r", selectionCount > 0 ? "bg-accent-50" : "", className), style: style }, props, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: [_jsx("div", { children: _jsx("div", Object.assign({ className: "flex" }, { children: _jsx("div", Object.assign({ style: { width: "0", flex: "1 1 auto" } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx(EvidenceCardTitle, { title: caption || "", prefix: _jsx(CollapseToggle, { className: "", collapsed: collapsed, setCollapsed: setCollapsed, prefix: _jsx(Checkbox, { className: "mx-2", checked: allSelected, indeterminate: someSelected && !allSelected, onChange: e => {
                                                    e.target.checked
                                                        ? selectEvidence(documentEvidenceIdList)
                                                        : deselectEvidence(documentEvidenceIdList);
                                                } }) }), onOpen: () => {
                                            urlOpenerFn === null || urlOpenerFn === void 0 ? void 0 : urlOpenerFn(primaryEvidence, primaryEvidence.instances[0], allEvidenceText, false);
                                        } }), _jsx(RenderNiceMetadata, { className: "pl-[46px] text-sm text-gray-600", evidence: primaryEvidence }), _jsx(EvidenceFooter, { className: "px-1", evidence: primaryEvidence, onMetadataClick: onMetadataClick, metadataSorter: metadataSorter, showHighlights: showHighlights, urlOpener: urlOpenerFn, suffix: _jsxs(_Fragment, { children: [actions && (_jsx(Tooltip, Object.assign({ content: actionsTooltip }, { children: _jsx("span", { children: _jsx(DropDownMenu, Object.assign({ entries: () => actions(primaryEvidence) }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })) }) }))), extraItemActions] }) })] })) })) })) }), !collapsed && (_jsx("div", Object.assign({ className: classes("flex flex-col items-stretch", "mt-2") }, { children: _jsx("div", Object.assign({ id: containerId }, { children: _jsx("div", Object.assign({ className: "pl-[24px]" }, { children: documentEvidence.map((evidence, index) => {
                                return (_jsx(EvidenceRow, { className: "", evidence: evidence, documentId: evidence.instances[0].document_id, filterText: filterText, showSurrounding: showSurrounding, renderAsHtml: renderAsHtml, isSelected: evidenceSelected, selectEvidence: selectEvidenceHelper, removeEvidence: onRemove, openEvidence: urlOpenerFn, onEvidenceTextSelected: onEvidenceTextSelected, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions }, index));
                            }) })) })) }))), collapsed && selectionCount ? (_jsx("div", { className: "w-full border-t border-gray-100" })) : null] })) })));
}
