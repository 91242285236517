import { jsx as _jsx } from "react/jsx-runtime";
import Immutable from "immutable";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { useCurrentSource } from "../../api/useCurrentSource";
import { a_modelSelectedEvidenceId } from "../../appState/atomic/domainModel/modelState";
import { useEvidenceHighlights } from "../../appState/atomic/evidence/useEvidenceHighlights";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { useEvidenceView } from "../../appState/atomic/evidence/useEvidenceView";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_metadataUpserter } from "../../appState/atomic/queryState/constraintModel/metadataUpserter";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { sortMetadata } from "../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { scrollToIndex } from "../../utils/dom/utils/scroll";
import { PickerList } from "../common/popups/PickerList";
import { useShowSelectionMenu } from "../contextMenu/showSelectionMenu";
import { EvidenceCard } from "./cards/EvidenceCard";
import { useEvidenceListPagination } from "./useEvidenceListPagination";
import { useUrlOpener } from "./useUrlOpener";
export function EvidenceSearchResults({ argsInfo, results: evidence, disabled, evidenceSearch, annotationProvider, renderEvidenceActions, getEvidenceWithPosition, className, }) {
    const [{ expandedEvidence, findings, structuredQuery: sq, showStructuredQuery },] = useCurrentSession();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const structuredQuery = sq && showStructuredQuery;
    const source = useCurrentSource();
    const { evidenceAndTotals, noSelection, pageSize, evidenceRequestParams } = evidenceSearch;
    const { loading } = evidenceAndTotals;
    const [modelSelectedEvidenceId, setModelSelectedEvidenceId] = useAtom(a_modelSelectedEvidenceId);
    const { evidenceSelected, selectEvidence, deselectEvidence } = useEvidenceSelection();
    const { evidenceHighlighted } = useEvidenceHighlights();
    const { view, resetEvidenceMarkedToView } = useEvidenceView();
    const { suggestEvidence } = useSuggestedEvidence();
    const currentContextTitle = useCurrentContextTitle();
    const metadataUpserter = useSetAtom(a_metadataUpserter);
    const isEmptyQuery = useAtomValue(a_activeConstraintIsEmpty);
    //TODO: this is a hack
    const [retries, setRetries] = React.useState(0);
    const [pauseScrolling, setPauseScrolling] = React.useState(false);
    const { listRender, paginationFooter, virtuosoRef } = useEvidenceListPagination(evidenceAndTotals, pageSize, evidenceRequestParams);
    function getDocumentIndexForEvidence(evidenceId) {
        return evidence.findIndex(e => e.id === evidenceId);
    }
    function clearTargets() {
        resetEvidenceMarkedToView();
        setRetries(0);
        setModelSelectedEvidenceId(null);
    }
    React.useEffect(() => {
        if (loading) {
            setPauseScrolling(true);
        }
        if (!loading) {
            setTimeout(() => setPauseScrolling(false), 1000);
        }
    }, [loading]);
    const toggleSelect = (id) => evidenceSelected(id) ? deselectEvidence([id]) : selectEvidence([id]);
    const selectEvidenceHelper = (e) => toggleSelect(e.id);
    React.useEffect(() => {
        const evidenceIdList = modelSelectedEvidenceId
            ? Immutable.Set([modelSelectedEvidenceId])
            : view;
        if (evidenceIdList.size > 0 && virtuosoRef.current && !pauseScrolling) {
            const indexes = evidenceIdList.map(getDocumentIndexForEvidence).sort();
            if (indexes.first() !== -1) {
                scrollToIndex(virtuosoRef.current, indexes.first(), "smooth");
                if (modelSelectedEvidenceId) {
                    toggleSelect(modelSelectedEvidenceId);
                }
                clearTargets();
            }
            else {
                if (retries === 10) {
                    clearTargets();
                    return;
                }
                setTimeout(() => setRetries(retries + 1), 100 * (retries + 1));
            }
        }
    }, [view, pauseScrolling, retries, modelSelectedEvidenceId, virtuosoRef]);
    const [{ refuting }, setSearchParams] = useAtom(queryStateAtoms.searchParams);
    const urlOpenerFn = useUrlOpener({
        suggestEvidence,
        currentContextTitle,
        findings,
        structuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        source,
    });
    const showSelectionMenu = useShowSelectionMenu();
    //TODO: really shouldn't do this
    const itemRender = React.useCallback((item, idx) => (_jsx(EvidenceCard, { className: className, evidence: [item], onSelect: selectEvidenceHelper, selected: evidenceSelected, highlighted: evidenceHighlighted, actionsTooltip: "Give Feedback", showSurrounding: expandedEvidence, onMetadataClick: (e) => {
            metadataUpserter(e);
        }, urlOpenerFn: urlOpenerFn, onEvidenceTextSelected: ({ evidence, rect, text }) => showSelectionMenu(rect, {
            text,
            suggestEvidence,
            findings,
            evidence,
            instance: evidence.instances[0],
            context: evidence.text,
            currentContextTitle,
            structuredQuery,
            usingHierarchyNav: supportsHierarchyNavigation,
            expandedEvidence,
        }), showHighlights: true, metadataSorter: sortMetadata(source), annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions, getEvidenceWithPosition: getEvidenceWithPosition })), [
        selectEvidenceHelper,
        evidenceSelected,
        evidenceHighlighted,
        expandedEvidence,
        structuredQuery,
        annotationProvider,
        renderEvidenceActions,
        showSelectionMenu,
        urlOpenerFn,
        suggestEvidence,
        metadataUpserter,
    ]);
    return (_jsx(PickerList, { containerStyleClass: "pt-0", items: evidence, disabled: disabled, className: "flex-1", narrow: false, itemRender: itemRender, itemsListRenderer: listRender, loading: loading && !evidenceAndTotals.value, isSelected: (e) => evidenceSelected(e.id), onSelect: selectEvidenceHelper, bgClassName: "bg-transparent", brandingColorClass: "bg-alert_green-500", setTextFilter: evidenceFilter => {
            setSearchParams({ evidenceFilter });
        }, noItemsMessage: noSelection
            ? "Enter a query to search for evidence."
            : refuting
                ? "No Refuting Evidence Found"
                : "No Evidence Found", footer: !isEmptyQuery && (_jsx("div", Object.assign({ className: "flex flex-col border-t py-1" }, { children: paginationFooter }))) }));
}
