import { useCurrentSource } from "../../api/useCurrentSource";
import { useCurrentSession } from "../../appState/atomic/session/session";
export function useTrendRange() {
    var _a;
    const [{ selectedTrendRange }] = useCurrentSession();
    const currentSourceRanges = (_a = useCurrentSource()) === null || _a === void 0 ? void 0 : _a.supported_trends_fields.range;
    const currentTrendInCurrentSource = (currentSourceRanges === null || currentSourceRanges === void 0 ? void 0 : currentSourceRanges.find(d => d.id === (selectedTrendRange === null || selectedTrendRange === void 0 ? void 0 : selectedTrendRange.id))) !==
        undefined;
    const selectedRange = currentTrendInCurrentSource
        ? selectedTrendRange
        : currentSourceRanges !== undefined
            ? currentSourceRanges.find(r => r.id === "evidence") ||
                currentSourceRanges[0]
            : undefined;
    return selectedRange;
}
