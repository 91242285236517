import { mapValues, pick, sortBy } from "lodash";
import { migrateKeywordMetadata } from "../coraState/versionManagement/migrateKeywordMetadata";
import { mergeNamedMembers, mergeNamedMembersToList, } from "../semanticSearch/mergeNamedMembers";
import { mergeRelationClusters } from "../semanticSearch/mergeRelationClusters";
import { ConceptSource } from "../types/ConstraintModelState";
export class CorpusFilterBuilder {
    constructor(corpus_ids, aperture, constraint, model) {
        this.util = model;
        this.constraint = constraint;
        this.query = {};
        this.aperture = aperture;
        this.corpus_ids = corpus_ids;
        this.filter = {
            corpus_ids,
            aperture,
        };
    }
    conceptFilters(conceptSource, nodeIds, argName, excludeIds = [], excludeOverridesForConceptIds = [], ignorePossibleValues = false) {
        const filters = nodeIds
            .flatMap(nodeId => {
            const conceptIds = this.util.getConceptIdsForNodeIds(conceptSource, [nodeId], excludeIds);
            const solutions = ignorePossibleValues
                ? {}
                : this.util.getSolutionsForNodeIds([nodeId], excludeIds);
            const overrides = this.util.getOverridesForNodeIds(conceptSource, [nodeId], excludeIds);
            return conceptIds.map(cId => {
                const filterPossible = ignorePossibleValues
                    ? null
                    : mergeNamedMembers(solutions[cId] || []);
                const doNotOverride = excludeOverridesForConceptIds.includes(cId);
                const filterOverride = doNotOverride
                    ? null
                    : mergeNamedMembers(overrides[cId] || [], {}, true);
                const filterConcept = addIsRequired(this.util.model.data.concepts[cId], doNotOverride ? false : this.util.isConceptRequired(nodeId, cId));
                const filterForId = filterOverride || filterPossible || filterConcept;
                if (!filterForId)
                    return null;
                return cleanupConcept(addOptionalArgument(argName)(filterForId));
            });
        })
            .filter(Boolean);
        return filters;
    }
    addContext(excludeIds = [], excludeOverridesForConceptIds = [], ignorePossibleValues = false) {
        var _a;
        const contextFilters = this.conceptFilters(ConceptSource.MODEL, ((_a = this.constraint) === null || _a === void 0 ? void 0 : _a.contextNodeIds) || [], undefined, excludeIds, excludeOverridesForConceptIds, ignorePossibleValues);
        if (contextFilters.length) {
            this.filter.context = sortBy([...contextFilters, ...(this.filter.context || [])], "name");
        }
        return this;
    }
    addExtra(excludeIds = [], excludeOverridesForConceptIds = [], includeContextConcepts = []) {
        const extendedFilters = this.conceptFilters(ConceptSource.EXTENDED, this.util.model.config.extendedNodeIds, undefined, excludeIds, excludeOverridesForConceptIds, true);
        // used to add extra concepts when they aren't selected for searching their own subconcepts
        const includedFilters = includeContextConcepts.map(concept => cleanupConcept(concept));
        if (extendedFilters.length || includedFilters.length) {
            this.filter.context = sortBy([
                ...extendedFilters,
                ...includedFilters,
                ...(this.filter.context || []),
            ], "name");
        }
        return this;
    }
    newQuery() {
        this.query = {};
        return this;
    }
    querySources(excludeIds = [], excludeOverridesForConceptIds = [], ignorePossibleValues = false) {
        var _a;
        const sourceFilters = this.conceptFilters(ConceptSource.MODEL, this.constraint ? [this.constraint.sourceNodeId] : [], ((_a = this.constraint) === null || _a === void 0 ? void 0 : _a.is_directed) ? "subject" : undefined, excludeIds, excludeOverridesForConceptIds, ignorePossibleValues);
        if (!this.query.concept_filter)
            this.query.concept_filter = { filter: [] };
        this.query.concept_filter.filter = [
            ...sourceFilters,
            ...this.query.concept_filter.filter,
        ];
        return this;
    }
    queryRelations(excludeIds = []) {
        const mergedRelations = mergeRelationClusters(this.constraint
            ? this.util.getAllRelations(this.constraint, excludeIds)
            : []);
        if (mergedRelations.length) {
            this.query.relation_filter = {
                filter: mergedRelations,
            };
        }
        return this;
    }
    queryTargets(excludeIds = [], excludeOverridesForConceptIds = [], ignorePossibleValues = false) {
        var _a;
        const targetFilters = this.conceptFilters(ConceptSource.MODEL, this.constraint ? [this.constraint.targetNodeId] : [], ((_a = this.constraint) === null || _a === void 0 ? void 0 : _a.is_directed) ? "object" : undefined, excludeIds, excludeOverridesForConceptIds, ignorePossibleValues);
        if (!this.query.concept_filter)
            this.query.concept_filter = { filter: [] };
        this.query.concept_filter.filter = [
            ...this.query.concept_filter.filter,
            ...targetFilters,
        ];
        return this;
    }
    queryText() {
        var _a, _b;
        const text = ((_a = this.constraint) === null || _a === void 0 ? void 0 : _a.text) || ((_b = this.constraint) === null || _b === void 0 ? void 0 : _b.userText);
        if (text) {
            this.query.text = text;
        }
        return this;
    }
    queryRefutingConcepts(original_refuting_concepts) {
        if (original_refuting_concepts) {
            const refuting_concepts = mergeConceptClusters(original_refuting_concepts);
            if (refuting_concepts.length) {
                this.query = Object.assign(Object.assign({}, this.query), { refuting_concepts });
            }
        }
        return this;
    }
    queryRefutingRelations(original_refuting_relations) {
        if (original_refuting_relations) {
            const refuting_relations = mergeRelationClusters(original_refuting_relations);
            if (refuting_relations.length) {
                this.query = Object.assign(Object.assign({}, this.query), { refuting_relations });
            }
        }
        return this;
    }
    fullQuery(excludeConceptIds = [], excludeOverridesForConceptIds = [], ignorePossibleValues = false, refuting_concepts = null, refuting_relations = null) {
        return this.newQuery()
            .querySources(excludeConceptIds, excludeOverridesForConceptIds, ignorePossibleValues)
            .queryRelations()
            .queryTargets(excludeConceptIds, excludeOverridesForConceptIds, ignorePossibleValues)
            .queryText()
            .queryRefutingRelations(refuting_relations)
            .queryRefutingConcepts(refuting_concepts);
    }
    toQuery() {
        return this.query;
    }
    addQueryToFilter() {
        if (!this.filter.queries)
            this.filter.queries = [];
        this.filter.queries.push(this.query);
        return this.newQuery();
    }
    addRangeMetadata(excludeIds = []) {
        const range = this.util.model.config.rangeMetadata.filter(r => !excludeIds.includes(r.id));
        const cleaned = cleanupMetadata(range);
        if (cleaned.length)
            this.filter.metadata = [...(this.filter.metadata || []), ...cleaned];
        return this;
    }
    addKeywordMetadata(excludeIds = []) {
        const keyword = this.util.model.config.keywordMetadata.filter(k => !excludeIds.includes(k.id));
        const updated = keyword.map(migrateKeywordMetadata).filter(Boolean);
        const cleaned = cleanupMetadata(updated);
        if (cleaned.length)
            this.filter.metadata = [...(this.filter.metadata || []), ...cleaned];
        return this;
    }
    addBooleanMetadata(excludeIds = []) {
        const boolean = this.util.model.config.booleanMetadata.filter(b => !excludeIds.includes(b.id));
        const cleaned = cleanupMetadata(boolean);
        if (cleaned.length)
            this.filter.metadata = [...(this.filter.metadata || []), ...cleaned];
        return this;
    }
    addClauses(excludeIds = []) {
        const { clauses, concepts } = this.util.model.data;
        const qualifierSets = this.constraint
            ? this.util.getQualifierIdMap(this.constraint)
            : {};
        const qualifiers = mapValues(qualifierSets, ids => ids);
        const required_arguments = this.constraint
            ? this.util.getRequiredQualifierKeys(this.constraint, excludeIds)
            : [];
        if (required_arguments.length) {
            this.filter.required_arguments_filter = {
                filter: required_arguments,
            };
        }
        const argClauses = Object.fromEntries(Object.entries(qualifiers || {}).flatMap(([argName, ids]) => ids
            .filter(id => !excludeIds.includes(id) && (clauses[id] || concepts[id]))
            .map(id => {
            return [
                argName,
                clauses[id] || conceptAsClause(concepts[id]), // TODO how to treat concepts?
            ];
        })
            .filter(([_, clause]) => Boolean(clause))));
        const filter = Object.entries(argClauses)
            .filter(([argument_name]) => !excludeIds.includes(argument_name))
            .map(([argument_name, clause]) => mergeNamedMembersToList([clause]).map(addArgument(argument_name)))
            .flat();
        if (filter.length) {
            this.filter.argument_clause_filter = { filter };
        }
        return this;
    }
    fullFilter(excludeConceptIds = [], excludeOverridesForConceptIds = [], includeContextConcepts = [], ignorePossibleValues = false, refuting_concepts = null, refuting_relations = null) {
        return this.fullQuery(excludeConceptIds, excludeOverridesForConceptIds, ignorePossibleValues, refuting_concepts, refuting_relations)
            .addQueryToFilter()
            .addContext(excludeConceptIds, excludeOverridesForConceptIds, ignorePossibleValues)
            .addExtra(excludeConceptIds, excludeOverridesForConceptIds, includeContextConcepts)
            .addRangeMetadata()
            .addKeywordMetadata()
            .addBooleanMetadata()
            .addClauses();
    }
    toCorpusFilter() {
        const corpusFilter = this.filter;
        this.filter = {
            corpus_ids: this.corpus_ids,
            aperture: this.aperture,
        };
        return corpusFilter;
    }
}
function cleanupMetadata(...values) {
    return values
        .filter(Boolean)
        .flat()
        .map(m => ({
        id: m.id,
        value: m.value,
    }));
}
function conceptAsClause({ name, members, }) {
    return {
        name,
        members: members.map(({ id, type_names }) => ({
            id,
            surface_forms: type_names,
        })),
    };
}
function mergeConceptClusters(concepts) {
    if (!concepts.length)
        return [];
    const conceptsByConcept = {};
    concepts.forEach(conceptTuple => {
        const key = conceptTuple.concept_cluster.name;
        if (!conceptsByConcept[key]) {
            conceptsByConcept[key] = {
                concept_cluster: conceptTuple.concept_cluster,
                refuting_concept_clusters: [conceptTuple.refuting_concept_cluster],
            };
        }
        else {
            conceptsByConcept[key].refuting_concept_clusters.push(conceptTuple.refuting_concept_cluster);
        }
    });
    return Object.values(conceptsByConcept);
}
function cleanupConcept(concept) {
    return pick(concept, ["members", "name", "is_required", "argument_name"]);
}
function addArgument(argument_name) {
    return c => (Object.assign(Object.assign({}, c), { argument_name }));
}
function addIsRequired(c, is_required) {
    if (c && is_required)
        return Object.assign(Object.assign({}, c), { is_required });
    return c;
}
function addOptionalArgument(argument_name) {
    return !(argument_name === null || argument_name === void 0 ? void 0 : argument_name.length) ? c => c : addArgument(argument_name);
}
