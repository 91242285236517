import { OBJ } from "../consts/OBJ";
import { createTransmuter } from "./createTransmuter";
export const Transmuters = {
    NULL: createTransmuter("null", value => OBJ.toQuery(value), query => OBJ.toValue(query), value => value === null),
    STR: createTransmuter("string", value => value, query => query, value => typeof value === "string"),
    NUM: createTransmuter("number", value => `${value}`, query => Number(query), value => typeof value === "number" && !isNaN(value)),
    BOOL: createTransmuter("boolean", value => `${value}`, query => {
        if (query === "true" || query === "false")
            return query === "true";
        throw `value [${query}] is cannot be parsed into a boolean`;
    }, value => typeof value === "boolean"),
    ARR: createTransmuter("array", value => OBJ.toQuery(value), query => OBJ.toValue(query), value => Array.isArray(value)),
    OBJ,
};
