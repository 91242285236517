import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from "antd";
import { atom, useAtom } from "jotai";
import React from "react";
import "tippy.js/dist/tippy.css";
import { useGlobalEscape } from "../../../utils/interactions/useGlobalEscape";
import { Close, Down, Right } from "../icons/Icons";
import { Scroller } from "../layout/Scroller";
import { TitledContainer } from "../layout/TitledContainer";
import "./FAQ.less";
import { Landmark } from "./Landmark";
const a_landmark = atom(null);
export function FAQ() {
    const [landmarks, setFaqLandmark] = useAtom(a_landmark);
    React.useEffect(() => setActive(-1), [landmarks]);
    const [active, setActive] = React.useState(-1);
    useGlobalEscape(() => setFaqLandmark(null), !!landmarks);
    return landmarks ? (_jsx(Modal, Object.assign({ closable: false, open: true, destroyOnClose: true, className: "FAQ", centered: true, footer: null }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch space-y-2", style: { height: 400 } }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between mb-2" }, { children: [_jsx("div", Object.assign({ className: "text-lg font-semibold" }, { children: "Frequently Asked Questions" })), _jsx(Close, { className: "cursor-pointer", onClick: () => setFaqLandmark(null) })] })), _jsx(Scroller, Object.assign({ direction: "Y", className: "flex-1 w-full" }, { children: _jsx("div", Object.assign({ className: "flex flex-col items-stretch space-y-2" }, { children: landmarks.map((landmark, idx) => (_jsx(TitledContainer, Object.assign({ bgClassName: "bg-white", containerStyleClass: "p-2 rounded-lg", headerLeft: landmark.title, headerRight: active === idx ? _jsx(Down, {}) : _jsx(Right, {}), onHeaderClick: () => setActive(active => (active === idx ? -1 : idx)), noScroll: true }, { children: active === idx && (_jsx(Landmark, { landmark: landmark, noTitle: true }, "l" + idx)) }), idx))) })) }))] })) }))) : null;
}
