import { FeedbackType } from "../../api/types/apiTypes";
export function getFeedbackHoverStyles(feedbackType) {
    switch (feedbackType) {
        case FeedbackType.NEGATIVE:
            return "hover:bg-alert_red-100 hover:text-alert_red-500";
        case FeedbackType.POSITIVE:
            return "hover:bg-alert_green-100 hover:text-alert_green-500";
        default:
            return "hover:bg-neutral-100";
    }
}
