import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const EMPTY_USAGE_STATE = {
    currentQueryId: null,
    lastQueryIdByCorpusId: {},
    questionsByCorpusId: {},
    suggestedQuestionsHistory: [],
};
const _atoms = {
    currentQueryId: atomWithReset(EMPTY_USAGE_STATE.currentQueryId),
    lastQueryIdByCorpusId: atomWithReset(EMPTY_USAGE_STATE.lastQueryIdByCorpusId),
    questionsByCorpusId: atomWithReset(EMPTY_USAGE_STATE.questionsByCorpusId),
    suggestedQuestionsHistory: atomWithReset(EMPTY_USAGE_STATE.suggestedQuestionsHistory),
};
export const USAGE_STATE_KEYS = Object.keys(_atoms);
export const a_usageState = atom(get => {
    return {
        currentQueryId: get(_atoms.currentQueryId),
        lastQueryIdByCorpusId: get(_atoms.lastQueryIdByCorpusId),
        questionsByCorpusId: get(_atoms.questionsByCorpusId),
        suggestedQuestionsHistory: get(_atoms.suggestedQuestionsHistory),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_usageState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useUsageState() {
    return useResettableState(a_usageState);
}
