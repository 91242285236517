export function sortMetadata(source) {
    const metadataInfo = (source === null || source === void 0 ? void 0 : source.display_configuration.metadata) || [];
    return (metadata) => {
        return [...metadata].sort((a, b) => {
            var _a, _b;
            const aVal = ((_a = metadataInfo.find(x => x.id === a.id)) === null || _a === void 0 ? void 0 : _a.order) || -1;
            const bVal = ((_b = metadataInfo.find(x => x.id === b.id)) === null || _b === void 0 ? void 0 : _b.order) || -1;
            if (aVal === -1 || (bVal !== -1 && aVal > bVal))
                return 1;
            if (bVal === -1 || (aVal !== -1 && aVal < bVal))
                return -1;
            return 0;
        });
    };
}
