import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { NodeResizer } from "@reactflow/node-resizer";
import React from "react";
import { Handle, Position, useReactFlow, useStore, } from "reactflow";
import { updateItems } from "../../../utils/generic/collections";
import { useDiagramStateUpdater } from "../DiagramState";
import { useStage } from "../Stage";
import { isExpanded, setExpanded } from "../diagramUtils";
import { ElementType, isConcept, setContent, setSelection, } from "../model";
import { EntityView } from "./EntityView";
import { useLoader } from "./Loader";
import { NodeExtender } from "./NodeExtender";
const connectionNodeIdSelector = (state) => state.connectionNodeId;
const MIN_WIDTH = 128;
const MIN_HEIGHT = 200;
function DiagramNode(props) {
    const stage = useStage();
    const { id, sourcePosition = Position.Bottom, targetPosition = Position.Top, dragging, } = props;
    const type = props.type;
    const data = props.data;
    const dUpdater = useDiagramStateUpdater();
    const connectionNodeId = useStore(connectionNodeIdSelector);
    const isConnecting = !!connectionNodeId;
    const isSource = connectionNodeId === id;
    const isTarget = connectionNodeId && connectionNodeId !== id;
    const { label, argName, content, selection, possible, expandable } = data;
    const flow = useReactFlow();
    const node = flow.getNode(id);
    const expanded = isExpanded(node);
    const loader = useLoader({
        node: node,
    });
    React.useEffect(() => updateNodeHeight(id, expanded), [expanded]);
    const onResizeEnd = React.useCallback((e, params) => {
        // if node is collapsed only register width
        dUpdater.commit({
            nodes: updateItems(flow.getNodes(), n => {
                var _a;
                return n.id === id && {
                    data: Object.assign(Object.assign({}, n.data), { resized: {
                            x: params.width,
                            y: expanded ? params.height : (_a = data.resized) === null || _a === void 0 ? void 0 : _a.y,
                        } }),
                };
            }),
        });
    }, [expanded]);
    const updateExpanded = React.useCallback((expanded) => {
        updateNodeHeight(id, expanded);
        setTimeout(() => {
            dUpdater.commit({
                nodes: updateItems(flow.getNodes(), n => n.id === id && setExpanded(n, expanded)),
            });
        }, 0);
    }, []);
    const updateContent = React.useCallback((sel) => {
        dUpdater.commit({
            nodes: flow
                .getNodes()
                .map(n => n.id === id
                ? !sel
                    ? setContent(n, [])
                    : !isConcept(node) || !content.length
                        ? setContent(n, sel)
                        : setSelection(n, sel.length ? { [content[0].name]: sel } : {})
                : n),
        });
    }, [content]);
    return (_jsxs(_Fragment, { children: [_jsx(NodeResizer, { isVisible: !isConnecting, minWidth: MIN_WIDTH, minHeight: expanded ? MIN_HEIGHT : node.height, maxHeight: !expanded ? node.height : undefined, onResizeEnd: onResizeEnd }), _jsx(EntityView, { id: id, type: type, label: label, argName: argName, content: content, expanded: expanded, selection: Object.values(selection)[0] || [], possible: possible, loader: loader, updateExpanded: updateExpanded, updateContent: updateContent }), !dragging && (isSource || !isConnecting) && (_jsx(NodeExtender, { node: node, stage: stage, isSource: isSource, targetPosition: targetPosition })), _jsx(Handle, { type: "target", style: { visibility: isTarget ? "visible" : "hidden" }, position: sourcePosition, className: "targetHandle flex items-center justify-center" })] }));
    function updateNodeHeight(id, expanded) {
        var _a;
        const element = document.querySelector(`div[data-id="${id}"]`);
        // if expanding, restore previous resized height,
        // otherwise clear height so component can get size from layout
        element.style.height =
            expanded && ((_a = data.resized) === null || _a === void 0 ? void 0 : _a.y) !== undefined
                ? data.resized.y + "px"
                : "unset";
    }
}
export const NODE_TYPES = {
    [ElementType.CONCEPT]: DiagramNode,
    [ElementType.RELATION]: DiagramNode,
    [ElementType.QUALIFIER]: DiagramNode,
};
const OVERFLOW_WRAP = { overflowWrap: "anywhere" };
