var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined } from "@ant-design/icons/lib";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { ActionButton } from "../../common/buttons/ActionButton";
import { DropDownMenu } from "../../common/popups/DropDownMenu";
import { Tooltip } from "../../common/popups/Tooltip";
import { EvidenceFooter } from "../EvidenceFooter";
import { EvidenceHeader } from "../EvidenceHeader";
import { EvidenceRow } from "../EvidenceRow";
export function EvidenceCard(_a) {
    var { evidence: evidences, onSelect, className, selected, highlighted, urlOpenerFn, filterText, actions, extraItemActions, renderAsHtml, onRemove, showSurrounding, onMetadataClick, onEvidenceTextSelected, showHighlights, actionsTooltip, metadataSorter, annotationProvider, renderEvidenceActions, getEvidenceWithPosition } = _a, props = __rest(_a, ["evidence", "onSelect", "className", "selected", "highlighted", "urlOpenerFn", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onMetadataClick", "onEvidenceTextSelected", "showHighlights", "actionsTooltip", "metadataSorter", "annotationProvider", "renderEvidenceActions", "getEvidenceWithPosition"]);
    const evidence = evidences[0];
    return (_jsx("div", Object.assign({ className: classes("relative flex flex-col items-stretch bg-neutral-100 rounded-xl border-t py-3 font-sans hover:bg-gray-100", (selected === null || selected === void 0 ? void 0 : selected(evidence.id)) ? "border-gray-300" : "border-neutral-300", className) }, props, { children: _jsxs("div", Object.assign({ className: " flex flex-col items-stretch" }, { children: [_jsx("div", Object.assign({ className: "px-4" }, { children: _jsx(EvidenceHeader, { metadataSorter: metadataSorter, evidence: evidence, urlOpener: urlOpenerFn }) })), _jsx(EvidenceRow, { className: "px-2", evidence: evidence, documentId: evidence.instances[0].document_id, filterText: filterText, showSurrounding: showSurrounding, showHighlights: showHighlights, renderAsHtml: renderAsHtml, isSelected: selected, isHighlighted: highlighted, selectEvidence: onSelect, removeEvidence: onRemove, openEvidence: urlOpenerFn, onEvidenceTextSelected: onEvidenceTextSelected, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions, getEvidenceWithPosition: getEvidenceWithPosition }), _jsx(EvidenceFooter, { className: "mt-2 px-4", evidence: evidence, onMetadataClick: onMetadataClick, metadataSorter: metadataSorter, selected: selected === null || selected === void 0 ? void 0 : selected(evidence.id), suffix: _jsxs(_Fragment, { children: [actions && (_jsx(Tooltip, Object.assign({ content: actionsTooltip }, { children: _jsx("span", { children: _jsx(DropDownMenu, Object.assign({ entries: () => actions(evidence) }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })) }) }))), extraItemActions] }) })] })) })));
}
