import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
export function focusAtomWithSetEffect(a_T, key, setEffect) {
    const a_focused = focusAtom(a_T, O => O.prop(key));
    return atom(get => {
        return get(a_focused);
    }, (get, set, update) => {
        set(a_focused, update);
        setEffect && setEffect(get, set, update);
    });
}
