import React from "react";
export function useGlobalKeyDown(callback, key, enabled = false) {
    React.useEffect(() => {
        if (!enabled)
            return;
        const keyListener = (e) => {
            if (e.key === key) {
                callback(e);
            }
        };
        document.addEventListener("keydown", keyListener);
        return () => {
            document.removeEventListener("keydown", keyListener);
        };
    }, [callback, enabled]);
}
