// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import featureFlags from "featureFlags";
import { atom, useAtom } from "jotai";
import { defaultFeatureFlags } from "./featureFlags";
export const a_featureFlags = atom(Object.assign(Object.assign({}, defaultFeatureFlags), featureFlags));
export function useFeatureFlags() {
    const [featureFlags] = useAtom(a_featureFlags);
    return featureFlags;
}
