import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { API } from "../../api/api";
import { ClusteringMode, } from "../../api/types/apiTypes";
import { useRemoteQuery } from "../../api/useRemoteQuery";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { a_constraintModelUtil, a_corpusFilterBuilder, } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_addQualifier, a_addQualifierKey, a_removeQualifierKey, a_resetClasuesForQualifierKey, } from "../../appState/atomic/queryState/constraintModel/qualifiers";
import { ANY_MARKER } from "../../appState/atomic/queryState/consts/ANY";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { mergeNamedMembersToList } from "../../appState/atomic/queryState/semanticSearch/mergeNamedMembers";
import { validateTextFilter } from "../../appState/atomic/queryState/semanticSearch/validateTextFilter";
import { ConceptSource } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { classes } from "../../appState/context/theme/classes";
import { getSize } from "../../utils/generic/getSize";
import { FacetsTabContent } from "./FacetsTabContent";
import { defaultFeedbackProvider } from "./defaultFeedbackProvider";
import { ArgumentClausesFacet } from "./specializedFacets/ArgumentClausesFacet";
import { usePreciseEvidenceOnly } from "../../appState/atomic/queryState/usePreciseEvidenceOnly";
export function FacetsForQualifiers({ argsInfo, feedback, setFeedback }) {
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const required_arguments = modelUtil.getRequiredQualifierKeys(constraint);
    const [{ expandedFacetCount }] = useUserConfig();
    const addQualifierKey = useSetAtom(a_addQualifierKey);
    const removeQualifierKey = useSetAtom(a_removeQualifierKey);
    const addQualifier = useSetAtom(a_addQualifier);
    const resetClasuesForQualifierKey = useSetAtom(a_resetClasuesForQualifierKey);
    function createFacet(argKey, loaderParamsGetter) {
        function setReq(req) {
            if (req) {
                addQualifierKey(argKey, ANY_MARKER);
            }
            else {
                removeQualifierKey(argKey);
            }
        }
        const selectedItems = modelUtil.getSelectedClausesForKey(constraint, argKey);
        const reqSelected = required_arguments.indexOf(argKey) >= 0 || getSize(selectedItems) > 0;
        const disabled = !reqSelected && !availableArgs.has(argKey);
        const tagColor = reqSelected || selectedItems ? argsInfo.argColors[argKey] : undefined;
        return (_jsx(ArgumentClausesFacet, { facetLoader: API.searchArgumentClauses, valueToSelection: v => v, itemInCollectionFilter: items => {
                const names = new Set(items.map(i => i.name));
                return s => names.has(s.name);
            }, onlyLoadWhenExpanded: true, initiallyCollapsed: true, loaderParamsGetter: loaderParamsGetter, facetType: argKey, clusterId: argKey, setSelected: selection => {
                resetClasuesForQualifierKey(argKey);
                selection.forEach(s => addQualifier(argKey, s));
            }, selected: selectedItems, tagColor: tagColor, noExpansion: !argsInfo.qualifierClauses.has(argKey) || !availableArgs.has(argKey), setReq: availableArgs.has(argKey) ? setReq : undefined, reqSelected: reqSelected, disabled: disabled, getFacetTitle: (count, disabled) => (_jsx("div", Object.assign({ className: "relative w-full" }, { children: _jsx("div", Object.assign({ className: classes("font-semibold", disabled && "cursor-not-allowed"), style: {
                        opacity: disabled ? 0.5 : 1,
                    } }, { children: argKey })) }))), suffixProvider: defaultFeedbackProvider(argKey, feedback, setFeedback), itemsOverride: null, max_count: expandedFacetCount }, argKey));
    }
    const { args, paramsGetter } = useQualifiers();
    const availableArgs = new Set((args.value || []).map(a => a.id));
    const hasQuery = !modelUtil.isConstraintEmpty(constraint);
    return (_jsx(FacetsTabContent, { description: !hasQuery && (_jsx("div", { children: "Run a Research Query to display available qualifiers that influence the meaning of your query, which you can select to filter your results." })), facets: () => (_jsx(_Fragment, { children: argsInfo.allArgumentNames.map(argument_name => {
                return createFacet(argument_name, ({ prefix, maxCount }) => {
                    prefix = validateTextFilter(prefix);
                    return paramsGetter(argument_name, maxCount, prefix);
                });
            }) })) }));
}
function useQualifiers() {
    const { disableVectorSearch } = useFeatureFlags();
    const [{ rankWithVectorSearch }] = useUserConfig();
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const use_precise_evidence_only = usePreciseEvidenceOnly();
    const rank_with_vector_search = !disableVectorSearch && rankWithVectorSearch;
    //TODO: why doesn't this look at targets?
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const sourceConcepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [constraint.sourceNodeId]);
    const sourceConceptsOverride = modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [constraint.sourceNodeId]);
    const contextConcepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds);
    const contextConceptsOverride = modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds);
    const relations = modelUtil.getAllRelations(constraint);
    //INFO: nulls out possible and excludes clauses
    const corpus_filter = corpusFilterBuilder
        .fullQuery()
        .addQueryToFilter() //TODO: really easy to forget this, maybe a better way
        .addBooleanMetadata()
        .addKeywordMetadata()
        .addRangeMetadata()
        .addContext()
        .addExtra()
        .toCorpusFilter();
    const merged = mergeNamedMembersToList(sourceConcepts, sourceConceptsOverride);
    const mergedContext = mergeNamedMembersToList(contextConcepts, contextConceptsOverride);
    const disabled = invalidCorpusIds(corpus_ids) ||
        (!merged.length && !mergedContext.length && !relations.length);
    const args = useRemoteQuery({
        loader: API.searchArguments,
        params: disabled
            ? undefined
            : {
                corpus_filter,
                use_precise_evidence_only,
            },
    });
    function paramsGetter(argument_name, count, prefix) {
        //INFO: nulls out possible and excludes argument_name from clauses
        const corpus_filter = corpusFilterBuilder
            .fullQuery()
            .addQueryToFilter() //TODO: really easy to forget this, maybe a better way
            .addClauses([argument_name])
            .addBooleanMetadata()
            .addKeywordMetadata()
            .addRangeMetadata()
            .addContext()
            .addExtra()
            .toCorpusFilter();
        return Object.assign(Object.assign({ corpus_filter,
            count, clustering_mode: ClusteringMode.AGGRESSIVE, argument_name }, (prefix.length && { prefix })), { rank_with_vector_search,
            use_precise_evidence_only });
    }
    return { args, paramsGetter, disabled };
}
