var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
import { getTagStyle, } from "../../appState/context/theme/themeUtils";
import { FeedbackWidget } from "../../utils/feedback/FeedbackWidget";
import { ClusterCard } from "../common/popups/PickerList";
import { showNotification } from "../common/status/notifications";
import { ItemsPicker } from "./ItemsPicker";
import { useSelectIdsWrapper } from "./useSelectIdsWrapper";
import { useValuesAndSelection } from "./useValuesAndSelection";
export const LIST_HEIGHT = 300;
export function ClusterCheckList({ remote, emptyMessage, filter, selected, setSelected, synonyms, updateSynonyms, mapper, sendFeedback, tagColorVals, prefix, hideHeaderList = false, style, checkboxClassName, itemFilter, confidence, renderName, }) {
    const [feedback, setFeedback] = React.useState({});
    function sendClusterFeedback(item, type, context) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!type || !sendFeedback)
                return;
            setFeedback((f) => (Object.assign(Object.assign({}, f), { [item.name]: type })));
            yield sendFeedback(item, type);
            showNotification("Feedback sent");
        });
    }
    const { items, remoteByKey, isSelected, changeSelection } = useValuesAndSelection(remote, selected, mapper, setSelected);
    const wrapWithSynonymsPicker = useSelectIdsWrapper(changeSelection, remoteByKey, synonyms, updateSynonyms);
    return (_jsx(ItemsPicker, { getId: i => i.name, entries: itemFilter ? items.filter(i => itemFilter(i)) : items, loading: remote.loading, selected: selected, updateSelection: selected => remote.value && setSelected(selected, remote.value), listClassName: "ml-2", itemRowClassName: classes("rounded px-2 mr-2 border border-neutral-200 py-1"), selectedRowStyle: tagColorVals && getTagStyle(tagColorVals[500]), wrapperClassName: "flex-1 mr-2", renderItem: item => {
            const content = (_jsxs("div", Object.assign({ className: "w-full flex items-center space-x-1 auto_hide_container" }, { children: [_jsx(ClusterCard, { className: "flex-1 overflow-hidden", titleClassName: "font-normal", noRiseOnHover: true, cardLookClass: "", spacingClass: "px-2 py-1", item: item, filterText: (filter || "").length >= 3 ? filter : undefined, synonyms: synonyms, infoExtractor: () => {
                            return {
                                title: renderName ? renderName(item) : item.name,
                                className: "mr-2",
                            };
                        } }), sendFeedback && (_jsx(FeedbackWidget, { item: Object.assign({ id: item.name }, item), expanded: false, sendFeedbackFn: (item, type, context) => sendClusterFeedback(item, type, context), feedback: feedback }))] })));
            return wrapWithSynonymsPicker(content, item, undefined, "w-full flex flex-1");
        }, emptyMessage: emptyMessage, checkboxClassName: checkboxClassName, style: style }));
}
