var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import React from "react";
import { a_corpusFilterBuilder } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useFindingContext } from "../../appState/context/findings/FindingContext";
import { addEvidenceToFinding } from "../../appState/context/findings/findings";
import { classes } from "../../appState/context/theme/classes";
import { useSelectionChange } from "../../utils/dom/useSelectionChange";
import { sendImplicitEvidenceFeedback } from "../../utils/feedback/sendImplicitEvidenceFeedback";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { ForwardButton } from "../common/icons/Icons";
import { Card } from "../common/layout/Card";
import { showNotification } from "../common/status/notifications";
import { TagsList } from "../common/tags/TagsList";
import { DefaultMarkDown } from "../common/text/MarkDown";
import { showAttachSuggestedEvidence } from "../modals/AttachSuggestedEvidence";
export function FindingCard({ indexId, item, allTags, highlightText, suggestQuestions, disabled, className, onTextSelected, }) {
    const [, sessionUpdaer] = useCurrentSession();
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const corpus_filter = corpusFilterBuilder.fullFilter().toCorpusFilter();
    const { dispatch } = useFindingContext();
    // const containerId = useUniqId(uuidv4)
    useSelectionChange(!item.payload.selectedText.length, item.id, item.payload.selectedText, onTextSelected);
    const addEvidenceToFindingInSession = (finding, evidence) => sessionUpdaer(({ findings }) => ({
        findings: findings.map(f => f.id !== finding.id ? f : addEvidenceToFinding(f, evidence)),
    }));
    function showAddEvidence() {
        showAttachSuggestedEvidence({
            finding: item,
            addEvidenceToFinding: addEvidenceToFindingInSession,
            onSelect(selected) {
                return __awaiter(this, void 0, void 0, function* () {
                    addEvidenceToFindingInSession(item, selected);
                    yield sendImplicitEvidenceFeedback(selected, corpus_filter);
                    showNotification("Evidence Attached");
                });
            },
        });
    }
    return (_jsxs("div", Object.assign({ className: "FindingCard flex flex-1" }, { children: [_jsx(Card, { id: item.id, className: className, cardLookClass: "py-4", titleClass: "text-lg", spacingClass: "", title: _jsxs("div", Object.assign({ className: "flex flex-col font-bold" }, { children: [_jsxs("span", { children: [indexId, " ", item.title || item.context.contextTitle] }), _jsx(DefaultMarkDown, { text: item.payload.selectedText, separator: highlightText, className: "text-base font-normal" })] })), extra: _jsxs("div", Object.assign({ className: classes("flex text-base pt-4 items-center", item.payload.tags.length > 0 ? "justify-between" : "justify-end") }, { children: [item.payload.tags.length > 0 ? (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("span", Object.assign({ className: "mr-2" }, { children: "Tags" })), _jsx(TagsList, { items: item.payload.tags, tagContent: (t) => t })] }))) : undefined, _jsxs("div", Object.assign({ className: "flex space-x-2 items-center" }, { children: [_jsxs("div", { children: [_jsx("span", { children: "Evidence attached" }), _jsx("span", Object.assign({ className: "ml-1 font-bold" }, { children: item.payload.evidence.length }))] }), _jsx(BorderlessButton, Object.assign({ className: "text-accent-500", onClick: () => dispatch({ type: "open", findingId: item.id, mode: "edit" }) }, { children: "Edit" })), _jsx(BorderlessButton, Object.assign({ className: "text-accent-500", onClick: () => 
                                    //TODO: add confirmation modal
                                    sessionUpdaer(({ findings }) => ({
                                        findings: findings.filter(f => f.id !== item.id),
                                    })) }, { children: "Delete" }))] }))] })) }), _jsx("div", Object.assign({ className: "flex flex-col justify-center" }, { children: _jsx(BorderlessButton, Object.assign({ onClick: () => dispatch({ type: "open", findingId: item.id, mode: "view" }) }, { children: _jsx(ForwardButton, { style: { fontSize: "30px" } }) })) }))] })));
}
