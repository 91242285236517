import React from "react";
import { useCapabilitiesList, useCorpusFeatureFlags, } from "../../../api/useHasCapability";
import { CorpusCapability } from "../../../api/utils/capabilities";
import { useFeatureFlags } from "./useFeatureFlags";
var CorpusFeature;
(function (CorpusFeature) {
    CorpusFeature["CONCEPT_TYPE_HIERARCHY"] = "concept_type_hierarchy_enabled";
    CorpusFeature["STRUCTURED_SEARCH"] = "structured_search_enabled";
})(CorpusFeature || (CorpusFeature = {}));
export var Feature;
(function (Feature) {
    Feature["ANSWER_GENERATION"] = "AnswerGeneration";
    Feature["APERTURE"] = "Aperture";
    Feature["CONCEPT_SEARCH"] = "ConceptSearch";
    Feature["CONCEPT_FILTER_PANEL"] = "ConceptFilterPanel";
    Feature["CONCEPT_TYPE_SEARCH"] = "ConceptTypeSearch";
    Feature["CONJUNCTIVE_QUERIES"] = "ConjunctiveQueries";
    Feature["CRC_SEARCH_INPUT"] = "CrcSearchInput";
    Feature["CUSTOM_CONCEPT_LISTS"] = "CustomConceptLists";
    Feature["EXPORT_EVIDENCE"] = "ExportEvidence";
    Feature["GLOSSARY"] = "Glossary";
    Feature["PRECISE_STRUCTURED_SEARCH"] = "PreciseStructuredSearch";
    Feature["QUALIFIERS"] = "Qualifiers";
    Feature["QUALIFIER_CLAUSES"] = "QualifierClauses";
    Feature["QUALIFIER_FILTER_PANEL"] = "QualifierFilterPanel";
    Feature["QUERY_ANALYSIS_CONJUNCTIVE"] = "QueryAnalysisConjunctive";
    Feature["QUERY_ANALYSIS_MULTIHOP"] = "QueryAnalysisMultihop";
    Feature["QUERY_GRAPH"] = "QueryGraph";
    Feature["QUERY_GRAPH_SOLVING"] = "QueryGraphSolving";
    Feature["QUERY_SUGGESTION"] = "QuerySuggestion";
    Feature["QUERY_SUGGESTION_CONCEPTS"] = "QuerySuggestionConcepts";
    Feature["QUERY_SUGGESTION_EVIDENCE"] = "QuerySuggestionEvidence";
    Feature["QUERY_SUGGESTION_RELATED"] = "QuerySuggestionRelations";
    Feature["REFUTING_SEARCH"] = "RefutingSearch";
    Feature["TOPIC_SUMMARIZATION"] = "TopicSummarization";
    Feature["TYPE_HIERARCHY_NAVIGATION"] = "TypeHierarchyNavigation";
    Feature["TRENDS"] = "Trends";
    Feature["TRENDS_EXPORT"] = "TrendsExport";
    Feature["VECTOR_SEARCH"] = "VectorSearch";
    Feature["WATCHLIST_PANEL"] = "WatchlistPanel";
})(Feature || (Feature = {}));
export const featureRequirementsMap = {
    [Feature.ANSWER_GENERATION]: {
        capabilities: [[CorpusCapability.SUGGEST_ANSWER]],
        appFeatureFlags: {
            enableAnswerGeneration: true,
        },
    },
    [Feature.APERTURE]: {
        corpusFeatureFlags: [[CorpusFeature.STRUCTURED_SEARCH]],
        appFeatureFlags: {
            enableAperture: true,
        },
    },
    [Feature.CONCEPT_SEARCH]: {
        capabilities: [
            [
                CorpusCapability.ANALYZE_QUERY,
                CorpusCapability.SEARCH_CONCEPTS,
                CorpusCapability.LOOKUP_CONCEPTS,
                CorpusCapability.LOOKUP_KB_IDS,
            ],
        ],
        appFeatureFlags: {
            enableConceptSearch: true,
        },
    },
    [Feature.CONCEPT_FILTER_PANEL]: {
        parentFeatures: [[Feature.CRC_SEARCH_INPUT]],
    },
    [Feature.CONCEPT_TYPE_SEARCH]: {
        capabilities: [
            [CorpusCapability.SEARCH_CONCEPTS, CorpusCapability.ANALYZE_QUERY],
        ],
        corpusFeatureFlags: [[CorpusFeature.CONCEPT_TYPE_HIERARCHY]],
        appFeatureFlags: {
            enableConceptSearch: true,
        },
    },
    [Feature.CONJUNCTIVE_QUERIES]: {
        capabilities: [[CorpusCapability.CONJUNCTION_BINDINGS]],
    },
    [Feature.CRC_SEARCH_INPUT]: {
        parentFeatures: [
            [
                Feature.CONCEPT_SEARCH,
                Feature.CONCEPT_TYPE_SEARCH,
                Feature.PRECISE_STRUCTURED_SEARCH,
            ],
        ],
    },
    [Feature.CUSTOM_CONCEPT_LISTS]: {
        capabilities: [
            [
                CorpusCapability.CREATE_CONCEPT,
                CorpusCapability.SEARCH_CONCEPTS_MEMBERS,
            ],
        ],
        parentFeatures: [[Feature.CONCEPT_SEARCH]],
        appFeatureFlags: {
            enableCustomLists: true,
        },
    },
    [Feature.EXPORT_EVIDENCE]: {
        capabilities: [[CorpusCapability.EXPORT_EVIDENCE]],
        appFeatureFlags: {
            exportEvidence: true,
        },
    },
    [Feature.GLOSSARY]: {
        capabilities: [[CorpusCapability.SEARCH_GLOSSARY]],
        appFeatureFlags: {
            enableGlossarySearch: true,
        },
    },
    [Feature.QUERY_ANALYSIS_CONJUNCTIVE]: {
        capabilities: [[CorpusCapability.ANALYZE_QUERY]],
        parentFeatures: [[Feature.CONCEPT_SEARCH]],
        appFeatureFlags: {
            enableConjunctiveQueryAnalysis: true,
        },
    },
    [Feature.QUERY_ANALYSIS_MULTIHOP]: {
        capabilities: [
            [
                CorpusCapability.MULTIHOP_DISCOVERY_CONFIG_PATH,
                CorpusCapability.DISCOVER_MULTIHOP_RELATIONSHIP,
            ],
        ],
        appFeatureFlags: {
            enableMultihopDiscovery: true,
        },
    },
    [Feature.PRECISE_STRUCTURED_SEARCH]: {
        corpusFeatureFlags: [[CorpusFeature.STRUCTURED_SEARCH]],
    },
    [Feature.QUALIFIERS]: {
        capabilities: [[CorpusCapability.SEARCH_ARGUMENTS]],
    },
    [Feature.QUALIFIER_CLAUSES]: {
        capabilities: [[CorpusCapability.SEARCH_ARGUMENTS_CLAUSES]],
        parentFeatures: [[Feature.QUALIFIERS]],
    },
    [Feature.QUALIFIER_FILTER_PANEL]: {
        parentFeatures: [
            [Feature.QUALIFIERS, Feature.QUALIFIER_CLAUSES, Feature.CONCEPT_SEARCH],
        ],
        appFeatureFlags: {
            enableQualifierFilters: true,
        },
    },
    [Feature.QUERY_GRAPH]: {
        parentFeatures: [
            [Feature.CONJUNCTIVE_QUERIES],
            [Feature.QUERY_ANALYSIS_CONJUNCTIVE],
            [Feature.QUERY_ANALYSIS_MULTIHOP],
        ],
        appFeatureFlags: {
            enableQueryGraphs: true,
        },
    },
    [Feature.QUERY_GRAPH_SOLVING]: {
        parentFeatures: [[Feature.QUERY_GRAPH], [Feature.CONJUNCTIVE_QUERIES]],
        appFeatureFlags: {
            enableGraphSolutions: true,
        },
    },
    [Feature.QUERY_SUGGESTION]: {
        capabilities: [
            [CorpusCapability.SUGGEST_QUERY_NEXT_CONCEPTS],
            [CorpusCapability.SUGGEST_QUERY_NEXT_EVIDENCE],
            [CorpusCapability.SUGGEST_QUERIES_RELATED],
        ],
        parentFeatures: [[Feature.CONCEPT_SEARCH]],
        appFeatureFlags: {
            enableSuggestedQuestions: true,
        },
    },
    [Feature.QUERY_SUGGESTION_CONCEPTS]: {
        capabilities: [[CorpusCapability.SUGGEST_QUERY_NEXT_CONCEPTS]],
        parentFeatures: [[Feature.QUERY_SUGGESTION]],
    },
    [Feature.QUERY_SUGGESTION_EVIDENCE]: {
        capabilities: [[CorpusCapability.SUGGEST_QUERY_NEXT_EVIDENCE]],
        parentFeatures: [[Feature.QUERY_SUGGESTION]],
    },
    [Feature.QUERY_SUGGESTION_RELATED]: {
        capabilities: [[CorpusCapability.SUGGEST_QUERIES_RELATED]],
        parentFeatures: [[Feature.QUERY_SUGGESTION]],
    },
    [Feature.REFUTING_SEARCH]: {
        capabilities: [
            [CorpusCapability.SEARCH_CONCEPTS_REFUTING],
            [CorpusCapability.SEARCH_RELATIONS_REFUTING],
        ],
        parentFeatures: [[Feature.CONCEPT_SEARCH]],
        appFeatureFlags: {
            enableRefutingSearch: true,
        },
    },
    [Feature.TOPIC_SUMMARIZATION]: {
        capabilities: [[CorpusCapability.SUGGEST_SUMMARY]],
        appFeatureFlags: {
            enableTopicSummaries: true,
        },
    },
    [Feature.TYPE_HIERARCHY_NAVIGATION]: {
        corpusFeatureFlags: [[CorpusFeature.CONCEPT_TYPE_HIERARCHY]],
        appFeatureFlags: {
            enableTypeHierarchyNavigation: true,
        },
    },
    [Feature.TRENDS]: {
        capabilities: [
            [
                CorpusCapability.TRENDS,
                CorpusCapability.TRENDS_GRANULARITIES,
                CorpusCapability.TRENDS_GROUP_BY_FIELDS,
            ],
        ],
        parentFeatures: [[Feature.REFUTING_SEARCH]],
        appFeatureFlags: {
            enableTrends: true,
        },
    },
    [Feature.TRENDS_EXPORT]: {
        capabilities: [[CorpusCapability.EXPORT_TRENDS]],
        parentFeatures: [[Feature.TRENDS]],
    },
    [Feature.VECTOR_SEARCH]: {
        appFeatureFlags: {
            disableVectorSearch: false,
        },
    },
    [Feature.WATCHLIST_PANEL]: {
        parentFeatures: [[Feature.CONCEPT_SEARCH]],
        appFeatureFlags: {
            enableWatchlists: true,
        },
    },
};
function getSupportedFeatures(capabilities, corpusFeatureFlags, featureFlags) {
    const featureEntries = Object.entries(featureRequirementsMap);
    // put features in order where parents come before children
    // also check for circular dependencies
    const orderedFeatures = [];
    let lastFeatureCount = 0;
    while (orderedFeatures.length < featureEntries.length) {
        const featureEntry = featureEntries
            .filter(([feature]) => !orderedFeatures.some(([orderedFeature]) => orderedFeature === feature))
            .find(([, requirements]) => !requirements.parentFeatures ||
            // every parent feature is in the ordered list
            requirements.parentFeatures.every(parentFeatures => parentFeatures.every(parentFeature => orderedFeatures.some(([orderedFeature]) => orderedFeature === parentFeature))));
        if (featureEntry) {
            orderedFeatures.push(featureEntry);
            if (lastFeatureCount === orderedFeatures.length) {
                throw new Error(`Circular dependency detected in feature requirements: ${featureEntry[0]}`);
            }
            else {
                lastFeatureCount = orderedFeatures.length;
            }
        }
        if (!featureEntry) {
            throw new Error(`Unable to find a feature that meets the requirements, check the feature definitions`);
        }
    }
    const supportedFeatures = new Set();
    for (const [feature, requirements] of orderedFeatures) {
        if (featureSupported(requirements)) {
            supportedFeatures.add(feature);
        }
    }
    return supportedFeatures;
    function featureSupported(requirements) {
        const hasRequiredCapabilities = !requirements.capabilities ||
            requirements.capabilities.length === 0 ||
            requirements.capabilities.some(andCapabilities => andCapabilities.every(capability => capabilities.includes(capability)));
        if (!hasRequiredCapabilities) {
            return false;
        }
        const hasRequiredCorpusFeatureFlags = !requirements.corpusFeatureFlags ||
            requirements.corpusFeatureFlags.length === 0 ||
            requirements.corpusFeatureFlags.some(andCorpusFeatureFlags => andCorpusFeatureFlags.every(flagKey => corpusFeatureFlags[flagKey]));
        if (!hasRequiredCorpusFeatureFlags) {
            return false;
        }
        const hasRequiredAppFeatureFlags = !requirements.appFeatureFlags ||
            Object.entries(requirements.appFeatureFlags).length === 0 ||
            Object.entries(requirements.appFeatureFlags).every(([appFeature, value]) => featureFlags[appFeature] === value);
        if (!hasRequiredAppFeatureFlags) {
            return false;
        }
        const hasSupportedParents = !requirements.parentFeatures ||
            requirements.parentFeatures.some(parentFeatures => parentFeatures.every(parentFeature => supportedFeatures.has(parentFeature)));
        if (!hasSupportedParents) {
            return false;
        }
        return (hasRequiredCapabilities &&
            hasRequiredCorpusFeatureFlags &&
            hasRequiredAppFeatureFlags &&
            hasSupportedParents);
    }
}
export function useSupportedFeatures() {
    const capabilities = useCapabilitiesList();
    const corpusFeatureFlags = useCorpusFeatureFlags();
    const featureFlags = useFeatureFlags();
    return React.useMemo(() => getSupportedFeatures(capabilities, corpusFeatureFlags, featureFlags), [capabilities, corpusFeatureFlags, featureFlags]);
}
export function useIsFeatureSupported(feature) {
    const supportedFeatures = useSupportedFeatures();
    return supportedFeatures.has(feature);
}
