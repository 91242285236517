import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useSetAtom } from "jotai";
import { useCurrentSource } from "../../../api/useCurrentSource";
import { Feature, useIsFeatureSupported, } from "../../../appState/atomic/featureFlags/features";
import { a_metadataUpserter } from "../../../appState/atomic/queryState/constraintModel/metadataUpserter";
import { sortMetadata } from "../../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useCurrentContextTitle } from "../../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../../appState/atomic/suggestedEvidence";
import { useUrlOpener } from "../../evidence/useUrlOpener";
import { EvidenceCard } from "../cards/EvidenceCard";
export function EvidenceList({ evidence }) {
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const [{ findings, structuredQuery: sq, showStructuredQuery }, sessionUpdater] = useCurrentSession();
    const { suggestEvidence } = useSuggestedEvidence();
    const source = useCurrentSource();
    const structuredQuery = sq && showStructuredQuery;
    const currentContextTitle = useCurrentContextTitle();
    const metadataUpserter = useSetAtom(a_metadataUpserter);
    const urlOpenerFn = useUrlOpener({
        suggestEvidence,
        currentContextTitle,
        findings,
        structuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        source,
    });
    return (_jsx(_Fragment, { children: evidence.map((evidence, index) => {
            return (_jsx(EvidenceCard, { className: "mr-1", evidence_id: evidence.id, actionsTooltip: "Give Feedback", 
                //showSurrounding={expandedEvidence}
                onMetadataClick: (e) => {
                    metadataUpserter(e);
                }, urlOpenerFn: urlOpenerFn, showHighlights: true, metadataSorter: sortMetadata(source) }, index));
        }) }));
}
