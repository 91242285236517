var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Redirect } from "react-router-dom";
import { Loading } from "../../../components/common/transitions/Loading";
import { getConfig } from "../../config/serverConfig";
import { useAuthState } from "./useAuthState";
export const ORG_LANDING_PAGES = JSON.parse(getConfig("ORG_LANDING_PAGES", "[]"));
export const OrgLandingPage = ({ olpConfig }) => {
    const { isAuthenticated, isLoading, loginWithRedirect, userInfo } = useAuthState();
    const isLandingPageIsAuthenticated = isAuthenticated && userInfo && (userInfo === null || userInfo === void 0 ? void 0 : userInfo.orgId) == olpConfig.org_id;
    React.useEffect(() => {
        console.log("olpConfig", olpConfig);
        if (isLoading || isLandingPageIsAuthenticated || !olpConfig) {
            return;
        }
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            loginWithRedirect && (yield loginWithRedirect(olpConfig));
        }))();
    }, [isLoading, isLandingPageIsAuthenticated, loginWithRedirect, olpConfig]);
    return isLandingPageIsAuthenticated ? (_jsx(Redirect, { to: "/" })) : (_jsx(Loading, { loading: true }));
};
