import { isEqual } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { filterDic, merge } from "../generic/collections";
import { useCurrentUrl } from "./useCurrentUrl";
import { createPath } from "./utils/createPath";
import { navigateTo } from "./utils/navigateTo";
export function useQueryParams() {
    const history = useHistory();
    const { params, pathname: currentPathName } = useCurrentUrl();
    const update = React.useCallback((paramsUpdate, pathName, setInsteadOfUpdate = false) => {
        pathName = pathName === undefined ? currentPathName : pathName;
        const updated = setInsteadOfUpdate
            ? filterDic(paramsUpdate, (k, v) => v !== undefined)
            : merge(params, paramsUpdate);
        if (!isEqual(updated, params) || pathName !== currentPathName) {
            navigateTo(history, pathName, updated);
        }
    }, [history, currentPathName, params]);
    const getPath = React.useCallback((paramsUpdate) => {
        const updated = merge(params, paramsUpdate);
        return createPath(currentPathName, updated);
    }, [currentPathName, params]);
    return [params, update, getPath, currentPathName];
}
