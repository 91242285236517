import { getConfig } from "../../config/serverConfig";
import { useLaunchDarklySync } from "./useLaunchDarklySync";
const LD_CLIENT_ID = getConfig("LD_CLIENT_SIDE_ID", "");
//TODO: there must be a good way to deal with this in the atom
function WithLD() {
    console.log("Using LaunchDarkly for feature flags");
    useLaunchDarklySync();
    return null;
}
function WithoutLD() {
    console.log("Using local config for feature flags");
    return null;
}
export default LD_CLIENT_ID ? WithLD : WithoutLD;
