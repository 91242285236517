import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tabs } from "antd";
import { useAtom, useAtomValue } from "jotai";
import React from "react";
import { EvidenceType } from "../../api/types/evidenceTypes";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useUpdateCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { usePreciseEvidenceOnly } from "../../appState/atomic/queryState/usePreciseEvidenceOnly";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useSummariesState } from "../../appState/atomic/session/parts/summariesState";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { useEvidenceSearchContext } from "../../appState/context/evidence/EvidenceSearchContext";
import { classes } from "../../appState/context/theme/classes";
import { AND } from "../../utils/text/filters";
import { landmarkIdTag } from "../common/assist/useStartTour";
import { SelectionButton } from "../common/buttons/SelectionButton";
import { ConfigHorizontal } from "../common/icons/Icons";
import { Bar } from "../common/layout/Bar";
import { Fill } from "../common/layout/Fill";
import { TitledContainer } from "../common/layout/TitledContainer";
import { useDMParamsDiagramAddOn } from "../constraintFlow/useDMParamsDiagramAddOn";
import { useEvidenceAddOns } from "../results/useEvidenceAddOns";
import { useSummaries } from "../summaries/useSummaries";
import { useSummariesAction } from "../summaries/useSummariesAction";
import { useSummariesFilter } from "../summaries/useSummariesFilter";
import { useTrendsAddOn } from "../trends/useTrendsAddOn";
import { EvidenceFilter } from "./EvidenceFilter";
import "./EvidencePanel.less";
import { EvidenceResults } from "./EvidenceResults";
import { useShowRefutingSelectionPopover } from "./RefutingSelectionPopover";
import { WidgetPanel } from "./WidgetPanel";
import { roundOffThousands } from "./roundOffThousands";
import { useDocumentItems } from "./useDocumentItems";
import { useEvidenceItems } from "./useEvidenceItems";
import { useEvidenceSort } from "./useEvidenceSort";
import { useExportEvidenceAddOn } from "./useExportEvidenceAddOn";
function EvidenceHeader({ headerSelectors, label, }) {
    return (_jsx(Bar, Object.assign({ className: "EvidenceHeader pb-2" }, { children: _jsxs("div", Object.assign({ className: classes("w-full flex justify-between items-start items-center") }, { children: [_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx("div", Object.assign({ className: "mr-2 select-none" }, { children: label })) })), _jsx("div", Object.assign({ className: "flex items-center space-x-2" }, { children: headerSelectors }))] })) })));
}
export function EvidencePanelContents({ argsInfo, className }) {
    var _a, _b;
    useUpdateCurrentContextTitle();
    const [searchParams, setSearchParams] = useAtom(queryStateAtoms.searchParams);
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const [model, setModel] = useAtom(queryStateAtoms.constraintModel);
    const use_precise_evidence_only = usePreciseEvidenceOnly();
    const setRefutingConfiguration = React.useCallback((config) => {
        if (!model.activeConstraintId)
            return;
        setModel(Object.assign(Object.assign({}, model), { config: Object.assign(Object.assign({}, model.config), { refutingConfiguration: Object.assign(Object.assign({}, model.config.refutingConfiguration), { [model.activeConstraintId]: config }) }) }));
    }, [model, setModel]);
    const disabled = invalidCorpusIds(corpus_ids);
    const { evidenceSearch } = useEvidenceSearchContext();
    const { evidenceAndTotals, corpus_filter, evidenceRequestParams, noSelection } = evidenceSearch;
    const totalEvidenceCount = ((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.total_count) || 0;
    const [{ selectedSummaries }] = useSummariesState();
    const [{ evidenceGroupedByDocument, showAnswer }] = useUserConfig();
    const { enableAnswerGeneration, enableEvidenceFilterInput } = useFeatureFlags();
    const { sortField, sortOrder } = useEvidenceSort();
    const supportsRefuting = useIsFeatureSupported(Feature.REFUTING_SEARCH);
    const summariesSelector = useSummariesAction({
        evidenceRequestParams,
    });
    const emptyQuery = useAtomValue(a_activeConstraintIsEmpty);
    const showRefutingSelectionPopover = useShowRefutingSelectionPopover();
    const { widget, widgetSize, filter: actionsFilters, selectors: evidenceHeaderSelectors, } = useEvidenceAddOns({
        evidenceAndTotals,
        corpus_filter,
        evidenceRequestParams,
        argsInfo,
    }, useExportEvidenceAddOn, useDMParamsDiagramAddOn, useTrendsAddOn);
    const { summaries, answer, loadingSummaries, loadingAnswer, filter: summariesFilter, } = useSummariesFilter({
        evidenceRequestParams,
    });
    const [additionalFilters, setAdditionalFilters] = React.useState([]);
    const addFilter = React.useCallback((filter) => {
        setAdditionalFilters(additionalFilters => [...additionalFilters, filter]);
    }, [setAdditionalFilters]);
    const clearAdditionalFilters = React.useCallback(() => {
        setAdditionalFilters([]);
    }, [setAdditionalFilters]);
    const filter = AND(summariesFilter, actionsFilters, ...additionalFilters);
    const { items: documents } = useDocumentItems(evidenceSearch, filter, sortField, sortOrder);
    const { items: evidence, highConfidence } = useEvidenceItems(evidenceSearch, filter, sortField, sortOrder);
    const getEvidenceWithPosition = React.useCallback((evidenceId) => {
        var _a;
        const relevantEvidence = evidenceGroupedByDocument
            ? documents
                .map(d => [d.primary_evidence, ...d.additional_evidence])
                .flat()
            : evidence;
        const filteredIdx = relevantEvidence.findIndex(e => e.id === evidenceId);
        const unfilteredEvidenceRef = (((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.evidence) || []).find(e => e.id === evidenceId);
        return {
            position: filteredIdx !== -1 && filteredIdx !== undefined
                ? filteredIdx + 1
                : undefined,
            evidence: unfilteredEvidenceRef,
        };
    }, [evidence, documents]);
    const { summariesList, answerWidget } = useSummaries({
        summaries,
        answer,
        loadingSummaries,
        loadingAnswer,
        corpus_filter,
        evidence: evidenceGroupedByDocument
            ? documents
                .map(d => [d.primary_evidence, ...d.additional_evidence])
                .flat()
            : evidence,
        getEvidenceWithPosition: getEvidenceWithPosition,
        loadingEvidence: evidenceAndTotals.loading,
        addFilter,
        clearFilters: clearAdditionalFilters,
    });
    const summariesColumn = !emptyQuery && summariesList;
    const evidenceCount = `(${roundOffThousands(totalEvidenceCount)}${totalEvidenceCount && totalEvidenceCount === 10000 ? "+" : ""})`;
    const messages = [];
    if (use_precise_evidence_only) {
        messages.push("only precise results");
    }
    if (!emptyQuery &&
        !highConfidence &&
        !evidenceAndTotals.loading &&
        ((_b = evidenceAndTotals.value) === null || _b === void 0 ? void 0 : _b.evidence.length)) {
        messages.push("no high confidence results");
    }
    const evidenceResults = (_jsxs("div", Object.assign({ className: "flex flex-col flex-1" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between" }, { children: [supportsRefuting && !emptyQuery && (_jsx(Tabs, { activeKey: searchParams.refuting
                            ? EvidenceType.REFUTING
                            : EvidenceType.SUPPORTING, className: "SupportingRefuting flex-1", defaultActiveKey: EvidenceType.SUPPORTING, onTabClick: key => {
                            setSearchParams({ refuting: key === EvidenceType.REFUTING });
                        }, items: [
                            {
                                key: EvidenceType.SUPPORTING,
                                label: `Supporting${!searchParams.refuting && !evidenceAndTotals.loading
                                    ? " " + evidenceCount
                                    : ""}`,
                                children: undefined,
                            },
                            {
                                key: EvidenceType.REFUTING,
                                label: (_jsxs("span", Object.assign({ className: "flex items-center" }, { children: [`Refuting${searchParams.refuting && !evidenceAndTotals.loading
                                            ? " " + evidenceCount
                                            : ""}`, _jsx(ConfigHorizontal, { className: "ml-3 mr-2 hover:bg-neutral-200 rounded py-0.5 flex items-center", onClick: e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                showRefutingSelectionPopover(setRefutingConfiguration);
                                            } })] }))),
                                children: undefined,
                            },
                        ] })), messages.length > 0 && (_jsx("span", Object.assign({ className: "font-bold text-tiny" }, { children: messages.join(", ") })))] })), _jsxs("div", Object.assign({ className: "flex flex-1" }, { children: [_jsx(EvidenceResults, { corpus_filter: corpus_filter, argsInfo: argsInfo, documents: documents, evidence: evidence, highConfidence: highConfidence, results: evidenceGroupedByDocument
                            ? documents.map(d => ({ type: "document", value: d }))
                            : evidence.map(e => ({ type: "evidence", value: e })), disabled: disabled, evidenceSearch: evidenceSearch, totalEvidenceCount: totalEvidenceCount, filteredBySummary: summariesList && selectedSummaries.length > 0, getEvidenceWithPosition: getEvidenceWithPosition }), summariesColumn] }))] })));
    const evidenceHeader = (_jsx("div", Object.assign({ className: "w-full flex justify-center" }, { children: _jsx("div", Object.assign({ className: "w-full flex flex-col flex-grow" }, { children: _jsx(EvidenceHeader, { label: _jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx("span", Object.assign({ className: "font-bold" }, { children: "Evidence" })) })), headerSelectors: [
                    emptyQuery || !enableEvidenceFilterInput ? undefined : (_jsx(EvidenceFilter, { disabled: disabled }, "filter")),
                    ...evidenceHeaderSelectors.map((selector, idx) => (_jsx(SelectionButton, { tooltip: selector.tooltip, className: "px-2 text-tiny", onClick: selector.onSelect, disabled: selector.disabled, selected: selector.selected, icon: selector.icon }, selector.key))),
                    summariesSelector ? (_jsx(SelectionButton, { tooltip: summariesSelector.tooltip, className: "px-2 text-tiny", onClick: summariesSelector.onSelect, disabled: disabled, selected: summariesSelector.selected, icon: summariesSelector.icon }, summariesSelector.key)) : null,
                ] }) })) })));
    const evidenceBlock = (_jsx("div", Object.assign({ className: "w-full flex flex-col flex-1" }, landmarkIdTag("tour-evidence"), { children: _jsx(WidgetPanel, { widget: widget, widgetSize: widgetSize, content: _jsxs(_Fragment, { children: [evidenceHeader, evidenceResults] }) }) })));
    const answerBlock = React.useMemo(() => {
        return (enableAnswerGeneration &&
            showAnswer &&
            !searchParams.evidenceFilter &&
            answerWidget);
    }, [enableAnswerGeneration, showAnswer, answerWidget]);
    return (_jsx(Fill, Object.assign({ flexRow: true, className: "justify-center" }, { children: _jsx(Fill, Object.assign({ className: classes("flex flex-col flex-1 pt-1 ml-4"), style: {
                maxWidth: !summariesColumn ? "50rem" : "76rem",
            } }, { children: _jsx(Fill, Object.assign({ flexRow: true }, { children: _jsx(TitledContainer, Object.assign({ className: classes("flex flex-col flex-1 h-full relative", className) }, landmarkIdTag("tour-evidence"), { noScroll: true, bgClassName: "bg-transparent", containerStyleClass: "pt-0" }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex flex-col relative" }, { children: [answerBlock, evidenceBlock] })) })) })) })) })));
}
