import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { take } from "lodash";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { identity } from "../../../utils/generic/identity";
import { showPopupMenu } from "../../contextMenu/showPopupMenu";
import { Plus } from "../icons/Icons";
import { Tooltip } from "../popups/Tooltip";
import { HorizontalOverflow } from "./HorizontalOverfolw";
import "./TagsList.less";
import { XTag } from "./XTag";
export function TagsList({ items, tagContent, tagStyle, onMouseDown, closable, className, wrap, onClose, onAdd, showMoreAction = true, isRemoved, keepRemoved = false, interactive = true, tagTooltip, tagWrapper = identity, maxTags = Number.MAX_SAFE_INTEGER, capTypeProvider, moreButtonCStyle, tagClassName, widthClass, setTagWidth = true, showMoreContent, }) {
    const toRender = React.useMemo(() => (items.length <= maxTags ? items : take(items, maxTags)), [items, maxTags]);
    const truncated = toRender.length < items.length;
    function isItemRemoved(item) {
        return keepRemoved && isRemoved && isRemoved(item);
    }
    function itemAction(item, idx) {
        isItemRemoved(item)
            ? onAdd
                ? onAdd(item, idx)
                : onMouseDown === null || onMouseDown === void 0 ? void 0 : onMouseDown(item, idx)
            : onClose
                ? onClose(item, idx)
                : onMouseDown === null || onMouseDown === void 0 ? void 0 : onMouseDown(item, idx);
    }
    function showPopup(e) {
        showPopupMenu({
            top: e.clientY,
            left: e.clientX,
            entries: items.map((item, idx) => ({
                caption: getTag(item, idx),
                action(e) {
                    itemAction(item, idx);
                    e.preventDefault();
                    e.stopPropagation();
                },
            })),
        });
    }
    function getTag(item, idx) {
        const removed = isItemRemoved(item);
        const keyVal = removed && isRemoved ? `${idx}-${isRemoved(item)}` : idx;
        const tagClass = classes(interactive ? "cursor-pointer" : undefined, removed
            ? "background-neutral-100 border-neutral-300 text-neutral-500"
            : tagClassName);
        const tag = (_jsx(XTag, Object.assign({ closable: closable, className: tagClass, style: removed ? {} : tagStyle === null || tagStyle === void 0 ? void 0 : tagStyle(item, idx), onClick: interactive
                ? e => {
                    e.preventDefault();
                    removed ? onAdd === null || onAdd === void 0 ? void 0 : onAdd(item, idx) : onMouseDown === null || onMouseDown === void 0 ? void 0 : onMouseDown(item, idx);
                }
                : undefined, onClose: e => {
                e.preventDefault();
                removed ? onAdd === null || onAdd === void 0 ? void 0 : onAdd(item, idx) : onClose === null || onClose === void 0 ? void 0 : onClose(item, idx);
            }, closeIcon: removed ? _jsx(Plus, { className: "relative ml-0.5 " }) : null }, { children: tagContent(item, idx) }), keyVal));
        const tooltip = tagTooltip === null || tagTooltip === void 0 ? void 0 : tagTooltip(item, idx);
        return tagWrapper === null || tagWrapper === void 0 ? void 0 : tagWrapper(tooltip ? (_jsx(Tooltip, Object.assign({ content: tooltip }, { children: tag }), idx)) : (tag), item, idx);
    }
    function getItem(item, idx) {
        return (_jsx("div", Object.assign({ className: "p-1" }, { children: tagContent(item, idx) }), idx));
    }
    function showItems() {
        return _jsx(_Fragment, { children: items.map(getItem) });
    }
    return (_jsx(HorizontalOverflow, Object.assign({ className: classes("TagsList flex flex-row items-center space-x-1", wrap && "flex-wrap", className), widthClass: widthClass, setTagWidth: setTagWidth, forceShowMore: truncated, showMoreCaption: (overflow, container) => {
            /*
            TODO: taking this out for now, may remove this feature altogether
            let count = 0
            if (container) {
              const containerEnd = container.getBoundingClientRect().right
              Array.from(container.children).forEach(child => {
                if (child.getBoundingClientRect().right > containerEnd) count++
              })
            }
            */
            return `…`;
        }, moreButtonCStyle: moreButtonCStyle, onShowMoreClick: showMoreAction ? showPopup : undefined, tooltipContent: showMoreContent || showItems }, { children: toRender.map(getTag) })));
}
