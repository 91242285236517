var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Popover } from "antd";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
import { ActionButton } from "../../components/common/buttons/ActionButton";
import { getFeedbackHoverStyles } from "./getFeedbackHoverStyles";
import { getFeedbackIcon } from "./getFeedbackIcon";
import { getFeedbackStyles } from "./getFeedbackStyles";
export function feedbackButtonEntry(item, type, feedback, sendFeedbackFn, contexts, sizeClass = "w-6 h-6", className, iconClass) {
    const hasMenu = contexts && contexts.length > 1;
    const action = hasMenu
        ? undefined
        : (e) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            e.preventDefault();
            e.stopPropagation();
            const current = feedback[item.id];
            if (current === type)
                return;
            yield sendFeedbackFn(item, type, (_a = contexts === null || contexts === void 0 ? void 0 : contexts[0]) === null || _a === void 0 ? void 0 : _a.context);
        });
    const button = (_jsx(ActionButton, { className: classes("cursor-pointer border-0 p-2", className, feedback[item.id] === type && getFeedbackStyles(feedback[item.id]), getFeedbackHoverStyles(type)), sizeClass: sizeClass, icon: getFeedbackIcon(type, iconClass), onClick: action }, `${item.id}-${feedback[item.id]}`));
    return !hasMenu ? (button) : (_jsx(Popover, Object.assign({ className: "p-4", content: (contexts || []).map(({ caption, context }, idx) => (_jsx("div", Object.assign({ className: "cursor-pointer hover:bg-neutral-300 p-1", onClick: e => {
                e.preventDefault();
                e.stopPropagation();
                sendFeedbackFn(item, type, context);
            } }, { children: _jsx("span", { children: caption }) }), idx))) }, { children: button })));
}
