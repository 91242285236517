import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Result } from "antd";
import { atom, useAtom } from "jotai";
import { isArray } from "lodash";
import React from "react";
import { filterMetadata } from "../../../appState/atomic/queryState/semanticSearch/filterMetadata";
import { classes } from "../../../appState/context/theme/classes";
import { columnWidthClass2 } from "../../../appState/context/theme/lf";
import { useSelectionChange } from "../../../utils/dom/useSelectionChange";
import { scrollToElement } from "../../../utils/dom/utils/scrollToElement";
import { useUniqId } from "../../../utils/identity/useUniqId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { CollapsableSection } from "../../CollapsableSection";
import { ShareFromSquare } from "../../common/icons/Icons";
import { TitledContainer } from "../../common/layout/TitledContainer";
import { popModal, pushModal } from "../../common/modals/Modal";
import { AnnotatedString, findTextSpans } from "../../common/text/AnnotatedString";
import "./DocViewer.less";
import { showCitations } from "./citations/Citations";
const a_showMeta = atom(false);
function DocViewer({ url, title, highlight, highlightWords, className, onSelectionChange, doc, metadata, disableScrollToHighlight, }) {
    var _a;
    const [highlights, setHighlights] = React.useState(isArray(highlight)
        ? highlight
        : highlight !== undefined
            ? [highlight]
            : []);
    const filteredMetadata = filterMetadata(metadata);
    const containerId = useUniqId(uuidv4);
    const text = url && doc.value;
    const highlightSpans = text && highlights.map(h => findTextSpans(text, h)).flat();
    const containerRef = React.useRef(null);
    useSelectionChange(!text || doc.loading, containerId, ((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.textContent) || undefined, onSelectionChange, valueMapper => setHighlights(valueMapper(highlights)));
    const topHighlightRef = React.useRef(null);
    const [showMeta, setShowMeta] = useAtom(a_showMeta);
    React.useEffect(() => {
        if (!text || !highlightSpans || highlightSpans.length === 0)
            return;
        const topHighlight = topHighlightRef.current;
        if (!topHighlight)
            return;
        scrollToElement(() => topHighlight, 490);
    }, [text, highlightSpans]);
    return (_jsxs("div", Object.assign({ className: "flex-1 relative flex  flex-col space-y-2 items-stretch" }, { children: [_jsx(TitledContainer, Object.assign({ headerLeft: title, loading: doc.loading, className: classes("flex-1", className), containerStyleClass: " ", empty: !url && "Select a document…" }, { children: !doc.loading && text ? (_jsx("div", Object.assign({ className: "relative" }, { children: _jsx("div", Object.assign({ id: containerId, className: classes("Document bg-white p-4 rounded-lg", "whitespace-pre-wrap") }, { children: _jsx(AnnotatedString, { text: text, annotations: (highlightSpans || []).map(s => ({
                                span: s,
                                annotation: "highlight",
                            })), renderAnnotation: ({ text, annotations, annotationIndex }) => {
                                if (annotations.length === 0) {
                                    return text.replace(/\n{3,}/g, "\n\n");
                                }
                                return (_jsx("span", Object.assign({ ref: disableScrollToHighlight || annotationIndex !== 0
                                        ? undefined
                                        : topHighlightRef, className: "bg-yellow-200" }, { children: text.replace(/\n{3,}/g, "\n\n") })));
                            } }) })) }))) : (_jsx(Result, { status: "error", title: "Loading Failed", subTitle: `Error loading ${url}`, extra: [
                        _jsx("a", Object.assign({ href: url, target: "_blank", rel: "noreferrer" }, { children: _jsx(Button, Object.assign({ type: "primary" }, { children: "Open in New Tab" })) }), "open"),
                    ] })) })), _jsx(CollapsableSection, Object.assign({ title: "Metadata", collapsed: !showMeta, onToggle: () => setShowMeta(!showMeta) }, { children: _jsx("div", Object.assign({ className: "flex flex-col bg-white w-full  text-tiny overflow-auto max-h-36 bg-gray-100" }, { children: _jsxs("table", Object.assign({ className: "border-separate border-spacing-0 w-full" }, { children: [_jsx("thead", { children: _jsx("tr", Object.assign({ className: "font-bold text-left" }, { children: filteredMetadata === null || filteredMetadata === void 0 ? void 0 : filteredMetadata.map((m, i) => (_jsx("th", Object.assign({ className: "p-2 pt-4 sticky top-0 bg-gray-200" }, { children: m.name }), i))) })) }), _jsx("tbody", { children: _jsx("tr", { children: filteredMetadata === null || filteredMetadata === void 0 ? void 0 : filteredMetadata.map((m, i) => (_jsx("td", Object.assign({ className: "p-2 scroll-y align-top break-all" }, { children: Array.isArray(m.value) ? m.value.join(", ") : m.value }), i))) }) })] })) })) }))] })));
}
export function showDocViewer({ highlight, url, doc, title, onSelectionChange, metadata, metadataSorter, externalUrl, disableScrollToHighlight, citations, }) {
    pushModal({
        title,
        easyClose() {
            return true;
        },
        titleColorClass: "bg-gray-700",
        className: "w-[80%] z-[1100]",
        content: (_jsxs("div", Object.assign({ className: "flex-1 flex flex-col space-y-2 items-stretch" }, { children: [_jsx(DocViewer, { className: "flex-1", doc: doc, metadata: metadata
                        ? metadataSorter
                            ? metadataSorter(metadata)
                            : metadata
                        : [], url: url, highlight: highlight, onSelectionChange: onSelectionChange, disableScrollToHighlight: disableScrollToHighlight }), _jsxs("div", Object.assign({ className: classes("flex items-center space-x-1") }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-1 ml-[-16px]" }, { children: [externalUrl && (_jsxs(Button, Object.assign({ "aria-disabled": true, type: "text", href: externalUrl, target: "_blank", rel: "noreferrer", className: "flex align-center items-center" }, { children: [_jsx(ShareFromSquare, { className: "text-base mr-1" }), _jsx("span", Object.assign({ className: "" }, { children: "Original Document" }))] }))), !!(citations === null || citations === void 0 ? void 0 : citations.length) && (_jsx(Button, Object.assign({ type: "text", 
                                    // className={columnWidthClass2}
                                    onClick: () => showCitations(citations) }, { children: "Citations" })))] })), _jsx("div", { className: "flex-1" }), _jsx(Button, Object.assign({ className: columnWidthClass2, type: "primary", onClick: () => popModal(true) }, { children: "Close" }))] }))] }))),
    });
}
