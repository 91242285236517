import chroma from "chroma-js";
export function getTagStyle(tagColor) {
    //TODO: use theme
    return {
        backgroundColor: chroma(tagColor).mix("white", 0.6).css(),
        borderColor: tagColor,
    };
}
export function getTagBaseClass(baseColor = "neutral") {
    return `bg-${baseColor}-100 hover:bg-${baseColor}-200 border border-${baseColor}-500`;
}
export function lineClampStyle(lineClamp) {
    return {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: lineClamp,
        lineClamp,
    };
}
