import { useAtomValue } from "jotai";
import { useRemoteQuery } from "../../api/useRemoteQuery";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useTrendDomain } from "./useTrendDomain";
import { useTrendRange } from "./useTrendRange";
import { useTrendsCorpusFilter } from "./useTrendsCorpusFilter";
import { getTrendsData } from "./utils/getTrendsData";
export function useTrendsData(disabled) {
    var _a, _b;
    const noSelection = useAtomValue(a_activeConstraintIsEmpty);
    const domain_field = ((_a = useTrendDomain()) === null || _a === void 0 ? void 0 : _a.id) || "";
    const corpus_filter = useTrendsCorpusFilter(domain_field);
    const range_field = ((_b = useTrendRange()) === null || _b === void 0 ? void 0 : _b.id) || "";
    const [{ selectedGranularity }] = useCurrentSession();
    const trendData = useRemoteQuery({
        loader: getTrendsData,
        params: noSelection || disabled
            ? undefined
            : {
                domain_field,
                range_field,
                corpus_filter,
                selectedGranularity,
            },
    });
    return {
        trendData,
        noSelection,
        selectedGranularity,
    };
}
