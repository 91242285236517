var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientProvider } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { queryClient } from "../../../App";
import { API } from "../../api/api";
import { useRefutingSuggestions } from "../../appState/atomic/evidence/useRefutingSuggestions";
import { areRefutingConceptsEquivalent } from "../../appState/atomic/evidence/utils/areRefutingConceptsEquivalent";
import { getConceptItemRenderer } from "../../appState/atomic/evidence/utils/getConceptItemRenderer";
import { getRelationItemRenderer } from "../../appState/atomic/evidence/utils/getRelationItemRenderer";
import { getRelevantSelections } from "../../appState/atomic/evidence/utils/getRelevantSelections";
import { refutingConceptResponseMapper } from "../../appState/atomic/evidence/utils/refutingConceptResponseMapper";
import { sameName } from "../../appState/atomic/evidence/utils/sameName";
import { a_constraintModelUtil, } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { ConceptSource, } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { useHasRefutingConcepts } from "../../appState/atomic/queryState/useHasRefutingConcepts";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { showDialog } from "../../utils/dialog/dialog";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { useParams } from "../../utils/lifecycle/useParams";
import { Tooltip } from "../common/popups/Tooltip";
import { SelectionModal } from "../modals/SelectionModal";
import { RefutingPicker } from "./RefutingPicker";
const EMPTY_SELECTION = {
    concepts1Refuting: null,
    concepts2Refuting: null,
    relationsRefuting: null,
};
const EMPTY_ORIGINAL = {
    refuting_relations: null,
    refuting_concepts: null,
    relationsRefuting: null,
};
function RefutingSelectionPopover({ corpus_ids, modelUtil, constraint, refuting_concepts, refuting_relations, supportsRefutingConcepts, onValue, }) {
    const overlayWidth = supportsRefutingConcepts ? "800px" : "675px";
    const disabled = invalidCorpusIds(corpus_ids);
    const [state, update] = useParams({
        selection: EMPTY_SELECTION,
        original: null,
    });
    const { concepts1Refuting, concepts2Refuting, relationsRefuting } = state.selection;
    const { original } = state;
    function useSelectForRefuting(items, states) {
        useEffectIfDifferent(() => {
            const changeList = {};
            const changeSet = {};
            states.forEach(s => {
                if (!items || !s.coraSelection)
                    return;
                const selected = items.filter(i => {
                    const idx = s.coraSelection.findIndex(cs => s.equivalence(i, cs));
                    return idx > -1;
                });
                changeList[s.key] = selected;
                changeSet[s.key] = new Set(selected);
            });
            update({
                selection: Object.assign(Object.assign({}, state.selection), changeList),
                original: Object.assign(Object.assign(Object.assign({}, EMPTY_ORIGINAL), original), changeSet),
            });
        }, [], [items]);
    }
    const count = ((concepts1Refuting === null || concepts1Refuting === void 0 ? void 0 : concepts1Refuting.length) || 0) +
        ((concepts2Refuting === null || concepts2Refuting === void 0 ? void 0 : concepts2Refuting.length) || 0) +
        ((relationsRefuting === null || relationsRefuting === void 0 ? void 0 : relationsRefuting.length) || 0);
    const combinedConceptSelections = [
        ...(concepts1Refuting || []),
        ...(concepts2Refuting || []),
    ];
    const { refutingSuggestions: refutingConceptsRemote, params: refutingConceptsParams, } = useRefutingSuggestions("concepts", !supportsRefutingConcepts || disabled, API.searchRefutingConcepts, sv => refutingConceptResponseMapper(sv));
    const { refutingSuggestions: refutingRelationsRemote, params: refutingRelationsParams, } = useRefutingSuggestions("relations", disabled, API.searchRefutingRelations, sv => sv[0] || []);
    useSelectForRefuting(refutingConceptsRemote === null || refutingConceptsRemote === void 0 ? void 0 : refutingConceptsRemote.value, [
        {
            key: "concepts1Refuting",
            coraSelection: getRelevantSelections(refuting_concepts, modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
                constraint.sourceNodeId,
            ])),
            equivalence: areRefutingConceptsEquivalent,
        },
        {
            key: "concepts2Refuting",
            coraSelection: getRelevantSelections(refuting_concepts, modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
                constraint.targetNodeId,
            ])),
            equivalence: areRefutingConceptsEquivalent,
        },
    ]);
    useSelectForRefuting(refutingRelationsRemote === null || refutingRelationsRemote === void 0 ? void 0 : refutingRelationsRemote.value, [
        {
            key: "relationsRefuting",
            coraSelection: refuting_relations,
            equivalence: sameName,
        },
    ]);
    return (_jsx(Tooltip, Object.assign({ content: "Refine Refuting Selections", placement: "top" }, { children: _jsx(SelectionModal, Object.assign({ title: _jsxs("div", { children: ["Refuting Evidence", " ", _jsxs("span", Object.assign({ className: "text-neutral-800" }, { children: ["(", count, ")"] }))] }), visible: true, onCancel: e => {
                e.stopPropagation();
                onValue({});
            }, onOk: e => {
                onValue({
                    refuting_concepts: combinedConceptSelections,
                    refuting_relations: relationsRefuting,
                });
                e.stopPropagation();
            }, closable: true, width: overlayWidth }, { children: _jsx("div", Object.assign({ className: "flex-1 flex flex-row space-x-4 items-stretch relative pt-2 pb-4 h-full" }, { children: _jsxs("div", Object.assign({ className: "py-4 flex bg-white h-full w-full border-b border-neutral-300" }, { children: [supportsRefutingConcepts && (_jsx(RefutingPicker, { getId: getTupleId, className: "pr-6", title: "Primary Concepts", emptyMessage: "No relevant refuting concepts", checkboxClassName: "PrimaryConcept", refutingSuggestions: refutingConceptsRemote, filterItems: suggestions => getRelevantSelections(suggestions, modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
                                constraint.sourceNodeId,
                            ])), selection: concepts1Refuting, equalityTest: areRefutingConceptsEquivalent, updateSelection: concepts1Refuting => update(Object.assign(Object.assign({}, state), { selection: Object.assign(Object.assign({}, state.selection), { concepts1Refuting }) })), getItemRenderer: getConceptItemRenderer(refutingConceptsParams) })), _jsx(RefutingPicker, { getId: i => i.name, className: "pr-6 flex-1", title: "Relations", emptyMessage: "No relevant refuting relations", checkboxClassName: "Relation", refutingSuggestions: refutingRelationsRemote, selection: relationsRefuting, equalityTest: sameName, updateSelection: relationsRefuting => update(Object.assign(Object.assign({}, state), { selection: Object.assign(Object.assign({}, state.selection), { relationsRefuting }) })), getItemRenderer: getRelationItemRenderer(refutingRelationsParams) }), supportsRefutingConcepts && (_jsx(RefutingPicker, { getId: getTupleId, className: "pr-6 flex-1", title: "Linked Concepts", emptyMessage: "No relevant refuting concepts", checkboxClassName: "LinkedConcept", refutingSuggestions: refutingConceptsRemote, filterItems: suggestions => getRelevantSelections(suggestions, modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
                                constraint.targetNodeId,
                            ])), selection: concepts2Refuting, equalityTest: areRefutingConceptsEquivalent, updateSelection: concepts2Refuting => update(Object.assign(Object.assign({}, state), { selection: Object.assign(Object.assign({}, state.selection), { concepts2Refuting }) })), getItemRenderer: getConceptItemRenderer(refutingConceptsParams) }))] })) })) })) })));
}
function getTupleId(i) {
    return i.concept_cluster.name + ":" + i.refuting_concept_cluster.name;
}
export function useShowRefutingSelectionPopover() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const constraint = useAtomValue(a_activeConstraint);
    const { config: { refutingConfiguration }, } = useAtomValue(queryStateAtoms.constraintModel);
    const { refuting_concepts = null, refuting_relations = null } = refutingConfiguration[constraint.id] || {};
    const supportsRefutingConcepts = useHasRefutingConcepts();
    //TODO: no context so we must drill >>>>>>>>
    return (refutingUpdater) => __awaiter(this, void 0, void 0, function* () {
        const update = yield showDialog(onValue => (_jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(RefutingSelectionPopover, { corpus_ids: corpus_ids, modelUtil: modelUtil, constraint: constraint, refuting_concepts: refuting_concepts, refuting_relations: refuting_relations, supportsRefutingConcepts: supportsRefutingConcepts, onValue: onValue }) }))));
        update && refutingUpdater(update);
    });
}
