import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "antd";
import React from "react";
import { FeedbackType } from "../../api/types/apiTypes";
import { classes } from "../../appState/context/theme/classes";
import { feedbackButtonEntry } from "./feedbackButtonEntry";
import { getFeedbackIcon } from "./getFeedbackIcon";
import { getFeedbackStyles } from "./getFeedbackStyles";
export function FeedbackWidget({ item, feedback, sendFeedbackFn, getNegativeContexts, iconClass, bgClass, expanded, }) {
    function content(item, sendFeedbackFn, getNegativeContexts, sizeClass, className, iconClass) {
        return (_jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [_jsx("div", { children: feedbackButtonEntry(item, FeedbackType.NEGATIVE, feedback, sendFeedbackFn, getNegativeContexts === null || getNegativeContexts === void 0 ? void 0 : getNegativeContexts(item), sizeClass, className, iconClass) }), _jsx("div", { children: feedbackButtonEntry(item, FeedbackType.POSITIVE, feedback, sendFeedbackFn, [], sizeClass, className, iconClass) })] })));
    }
    return expanded ? (_jsx("div", { children: content(item, sendFeedbackFn, getNegativeContexts, "w-6 h-6", undefined, "text-sm") })) : (_jsx(Popover, Object.assign({ overlayClassName: "feedback-popover bg-gray-100 rounded-sm shadow-md", placement: "left", content: content(item, sendFeedbackFn, getNegativeContexts, "w-6 h-6", undefined, "text-sm"), trigger: "hover", align: { offset: [15, 0] } }, { children: _jsx("div", Object.assign({ className: classes("flex justify-center items-center p-0", "cursor-pointer", "w-6 h-6", "rounded-lg", bgClass
                ? `${bgClass} hover:${bgClass}`
                : getFeedbackStyles(feedback[item.id])) }, { children: getFeedbackIcon(feedback[item.id], iconClass) })) })));
}
