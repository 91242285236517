import { focusAtom } from "jotai-optics";
import { focusAtomWithSetEffect } from "../../utils/focusAtom";
import { queryStateAtoms } from "../queryStateAtoms";
export const a_activeConstraintId = focusAtomWithSetEffect(queryStateAtoms.constraintModel, "activeConstraintId");
export const a_activeModelId = focusAtomWithSetEffect(queryStateAtoms.constraintModel, "activeModelId");
export const a_constraintModelState = focusAtomWithSetEffect(queryStateAtoms.constraintModel, "state");
export const a_constraintModelConfig = focusAtomWithSetEffect(queryStateAtoms.constraintModel, "config");
export const a_booleanMetadata = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.booleanMetadata"));
export const a_keywordMetadata = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.keywordMetadata"));
export const a_rangeMetadata = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.rangeMetadata"));
export const a_constraintModelSolution = focusAtomWithSetEffect(queryStateAtoms.constraintModel, "solution");
