import { Bounds } from "./Bounds";
import { Point } from "./geometry";
function lineLine(a1, a2, b1, b2) {
    const ua_t = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x);
    const ub_t = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x);
    const u_b = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);
    if (u_b !== 0) {
        const ua = ua_t / u_b;
        const ub = ub_t / u_b;
        if (0 <= ua && ua <= 1 && 0 <= ub && ub <= 1) {
            return true;
        }
    }
    return false;
}
//DEAD CODE
function lineRect(a1, a2, b) {
    const r0 = Point(b.x, b.y);
    const r1 = Point(b.x, b.yMax);
    const r2 = Point(b.xMax, b.yMax);
    const r3 = Point(b.xMax, b.y);
    return (lineLine(a1, a2, r0, r1) ||
        lineLine(a1, a2, r1, r2) ||
        lineLine(a1, a2, r2, r3) ||
        lineLine(a1, a2, r3, r0));
}
function linePolygon(a1, a2, b) {
    const length = b.length;
    for (let i = 0; i < length; ++i) {
        const b1 = b[i];
        const b2 = b[(i + 1) % length];
        if (lineLine(a1, a2, b1, b2))
            return true;
    }
    return false;
}
//DEAD CODE
function rectRect(a, b) {
    const a_min_x = a.x;
    const a_min_y = a.y;
    const a_max_x = a.x + a.width;
    const a_max_y = a.y + a.height;
    const b_min_x = b.x;
    const b_min_y = b.y;
    const b_max_x = b.x + b.width;
    const b_max_y = b.y + b.height;
    return (a_min_x <= b_max_x &&
        a_max_x >= b_min_x &&
        a_min_y <= b_max_y &&
        a_max_y >= b_min_y);
}
export function rectPolygon(a, b) {
    const c0 = Point(a.x, a.y);
    const c1 = Point(a.x, a.yMax);
    const c2 = Point(a.xMax, a.yMax);
    const c3 = Point(a.xMax, a.y);
    // intersection check
    if (linePolygon(c0, c1, b) ||
        linePolygon(c1, c2, b) ||
        linePolygon(c2, c3, b) ||
        linePolygon(c3, c0, b))
        return true;
    // check if a contains b
    for (let i = 0, l = b.length; i < l; ++i) {
        if (pointInRect(b[i], a))
            return true;
    }
    return (pointInPolygon(c0, b) ||
        pointInPolygon(c1, b) ||
        pointInPolygon(c2, b) ||
        pointInPolygon(c3, b));
}
function pointInRect(a, r) {
    return a.x >= r.x && a.y >= r.y && a.x <= r.xMax && a.y <= r.yMax;
}
//DEAD CODE
function polygonPolygon(a, b) {
    let i, l;
    // check if a intersects b
    for (i = 0, l = a.length; i < l; ++i) {
        const a1 = a[i];
        const a2 = a[(i + 1) % l];
        if (linePolygon(a1, a2, b))
            return true;
    }
    // check if a contains b
    for (i = 0, l = b.length; i < l; ++i) {
        if (pointInPolygon(b[i], a))
            return true;
    }
    // check if b contains a
    for (i = 0, l = a.length; i < l; ++i) {
        if (pointInPolygon(a[i], b))
            return true;
    }
    return false;
}
function pointInPolygon(point, polygon) {
    let inside = false;
    const x = point.x;
    const y = point.y;
    // use some raycasting to test hits
    // https://github.com/substack/point-in-polygon/blob/master/index.js
    const length = polygon.length;
    for (let i = 0, j = length - 1; i < length; j = i++) {
        const xi = polygon[i].x, yi = polygon[i].y, xj = polygon[j].x, yj = polygon[j].y, intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect)
            inside = !inside;
    }
    return inside;
}
export function pointLineIntersection(x, y, x1, y1, x2, y2, isSegment) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const d = dx * dx + dy * dy;
    const t = ((x - x1) * dx + (y - y1) * dy) / d;
    let p;
    if (!isSegment) {
        p = Point(x1 + t * dx, y1 + t * dy);
    }
    else {
        if (d) {
            if (t < 0)
                p = Point(x1, y1);
            else if (t > 1)
                p = Point(x2, y2);
            else
                p = Point(x1 + t * dx, y1 + t * dy);
        }
        else {
            p = Point(x1, y1);
        }
    }
    return p;
}
/**
 *Liang-Barsky function by Daniel White
 *
 * @link http://www.skytopia.com/project/articles/compsci/clipping.html
 *
 * @param  {number}        x0
 * @param  {number}        y0
 * @param  {number}        x1
 * @param  {number}        y1
 * @param  {array<number>} bbox
 * @return {array<array<number>>|null}
 */
export function liangBarsky(x0, y0, x1, y1, bbox) {
    const [xmin, ymin, xmax, ymax] = Bounds.toDiagonal(bbox);
    const dx = x1 - x0, dy = y1 - y0;
    let t0 = 0, t1 = 1;
    let p = 0, q = 0, r = 0;
    for (let edge = 0; edge < 4; edge++) {
        // Traverse through left, right, bottom, top edges.
        if (edge === 0) {
            p = -dx;
            q = -(xmin - x0);
        }
        if (edge === 1) {
            p = dx;
            q = xmax - x0;
        }
        if (edge === 2) {
            p = -dy;
            q = -(ymin - y0);
        }
        if (edge === 3) {
            p = dy;
            q = ymax - y0;
        }
        r = q / p;
        if (p === 0 && q < 0)
            return [null, null];
        if (p < 0) {
            if (r > t1)
                return [null, null];
            else if (r > t0)
                t0 = r; // Line is clipped!
        }
        else if (p > 0) {
            if (r < t0)
                return [null, null];
            else if (r < t1)
                t1 = r; // Line is clipped!
        }
    }
    return [
        { x: x0 + t0 * dx, y: y0 + t0 * dy },
        { x: x0 + t1 * dx, y: y0 + t1 * dy },
    ];
}
