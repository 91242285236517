import { jsx as _jsx } from "react/jsx-runtime";
import { RetweetOutlined } from "@ant-design/icons";
import { useAtomValue, useSetAtom } from "jotai";
import { isEqual } from "lodash";
import React from "react";
import { RelatedSearch } from "../../../../components/common/icons/Icons";
import { crcMenuExtension } from "../../../../extensions/dropdownMenuExtensions";
import { a_constraintModelUtil } from "../constraintModel/ConstraintModelUtil";
import { a_addConceptToNode, a_clearContextNodes, a_resetConceptNodes, a_swapConceptsInConstraintSlots, } from "../constraintModel/concepts";
import { a_activeConstraint } from "../constraintModel/constraints";
import { a_resetQuery } from "../constraintModel/resets";
import { SOURCE_CAPTION } from "../consts/SOURCE_CAPTION";
import { TARGET_CAPTION } from "../consts/TARGET_CAPTION";
import { queryStateAtoms } from "../queryStateAtoms";
import { ConceptSlot, ConceptSource } from "../types/ConstraintModelState";
export function usePopupMenuEntriesGetter() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const swapConceptsInConstraintSlots = useSetAtom(a_swapConceptsInConstraintSlots);
    const addConceptToNode = useSetAtom(a_addConceptToNode);
    const resetConceptNodes = useSetAtom(a_resetConceptNodes);
    const clearContextNodes = useSetAtom(a_clearContextNodes);
    const resetQuery = useSetAtom(a_resetQuery);
    return React.useCallback((conceptSlot) => {
        const nodeIds = conceptSlot === ConceptSlot.CONTEXT
            ? constraint[conceptSlot]
            : [constraint[conceptSlot]];
        return () => {
            const current = modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, nodeIds);
            return crcMenuExtension(corpus_ids, [
                conceptSlot !== ConceptSlot.CONTEXT && {
                    caption: "Swap Primary and Linked Concepts",
                    disabled: isEqual(modelUtil
                        .getConceptIdsForNodeIds(ConceptSource.MODEL, [
                        constraint.sourceNodeId,
                    ])
                        .sort(), modelUtil
                        .getConceptIdsForNodeIds(ConceptSource.MODEL, [
                        constraint.targetNodeId,
                    ])
                        .sort()),
                    action() {
                        swapConceptsInConstraintSlots(constraint.sourceNodeId, constraint.targetNodeId);
                    },
                    icon: _jsx(RetweetOutlined, {}),
                },
                {
                    caption: "Use as next structured query",
                    disabled: !current.length,
                    action() {
                        const concepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, nodeIds);
                        resetQuery();
                        Object.entries(concepts).forEach(([key, value]) => {
                            addConceptToNode(constraint.sourceNodeId, value);
                        });
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
                conceptSlot !== ConceptSlot.SOURCE && {
                    caption: `Use as ${SOURCE_CAPTION}`,
                    disabled: !current.length,
                    action() {
                        const concepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, nodeIds);
                        if (conceptSlot === ConceptSlot.CONTEXT) {
                            resetConceptNodes(constraint.contextNodeIds);
                            clearContextNodes();
                        }
                        else {
                            resetConceptNodes([constraint[conceptSlot]]);
                        }
                        resetConceptNodes([constraint.sourceNodeId]);
                        Object.entries(concepts).forEach(([key, value]) => {
                            addConceptToNode(constraint.sourceNodeId, value);
                        });
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
                conceptSlot !== ConceptSlot.TARGET && {
                    caption: `Use as ${TARGET_CAPTION}`,
                    disabled: !current.length,
                    action() {
                        const concepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, nodeIds);
                        if (conceptSlot === ConceptSlot.CONTEXT) {
                            resetConceptNodes(constraint.contextNodeIds);
                            clearContextNodes();
                        }
                        else {
                            resetConceptNodes([constraint[conceptSlot]]);
                        }
                        resetConceptNodes([constraint.targetNodeId]);
                        Object.entries(concepts).forEach(([key, value]) => {
                            addConceptToNode(constraint.targetNodeId, value);
                        });
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
            ], conceptSlot);
        };
    }, [
        corpus_ids,
        constraint,
        modelUtil,
        addConceptToNode,
        resetConceptNodes,
        clearContextNodes,
        swapConceptsInConstraintSlots,
    ]);
}
