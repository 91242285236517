import React from "react";
import { useOnElementResize } from "./useOnElementResize";
export function useIsOverflow(element, vertical = true) {
    const [isOverflow, setIsOverflow] = React.useState();
    useOnElementResize(element, () => {
        setIsOverflow(vertical
            ? element.scrollHeight > element.clientHeight
            : element.scrollWidth > element.clientWidth);
    });
    return isOverflow;
}
