import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { Feature, useIsFeatureSupported, } from "../../../appState/atomic/featureFlags/features";
import { a_addConceptToNode, a_removeConceptFromExtended, a_removeConceptIdFromNodes, } from "../../../appState/atomic/queryState/constraintModel/concepts";
import { queryStateAtoms } from "../../../appState/atomic/queryState/queryStateAtoms";
import { ConceptType } from "../../../appState/atomic/queryState/types/ConstraintModelState";
import { a_addConceptToExtra, a_removeConceptFromExtra, } from "../../../appState/atomic/session/parts/watchlists";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { identity } from "../../../utils/generic/identity";
import { RelatedSearch } from "../../common/icons/Icons";
import { useConceptMenuEntries } from "../../contextMenu/useConceptMenuEntries";
import { useAddCustomListMenuEntry } from "../useAddCustomListMenuEntry";
import { CoraStateNamedConceptMembersFacet } from "./CoraStateNamedConceptMembersFacet";
function conceptClusterToOption(item) {
    return {
        label: item.name,
        value: item.name,
        item,
        hasChildren: item.has_children_concepts,
    };
}
function nameAndKBsInMembersFilter(concepts) {
    const names = new Set(concepts.map(c => c.members.map(createNames).flat()).flat());
    return (s) => s.members.some(m => createNames(m).some(n => names.has(n)));
    function createNames(concept) {
        return concept.kb_ids.length
            ? concept.kb_ids.map(id => concept.name + ":" + id)
            : [concept.name];
    }
}
export function ConceptFacet(props) {
    const { nodeId, extraMenuEntries = [], selected } = props;
    const removeConceptIdFromNodes = useSetAtom(a_removeConceptIdFromNodes);
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const addConceptToNode = useSetAtom(a_addConceptToNode);
    const removeConceptFromExtended = useSetAtom(a_removeConceptFromExtended);
    const removeConceptFromExtra = useSetAtom(a_removeConceptFromExtra);
    const addConceptToExtra = useSetAtom(a_addConceptToExtra);
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const [{ structuredQuery: sq, showStructuredQuery }] = useCurrentSession();
    const structuredQuery = sq && showStructuredQuery;
    const addCustomList = useAddCustomListMenuEntry();
    const menuEntries = useConceptMenuEntries({
        showNLQueryOption: !structuredQuery,
        showStructuredQueryOptions: structuredQuery && !supportsHierarchyNavigation,
        getExtraConceptEntries: supportsHierarchyNavigation
            ? (item) => [
                {
                    caption: `Use narrower concept "${item.name}"`,
                    disabled: !props.clusterId,
                    action() {
                        const conceptsKey = props.facetType;
                        const conceptId = props.clusterId;
                        if (!conceptId)
                            return;
                        if (conceptsKey === ConceptType.EXTENDED) {
                            removeConceptFromExtra(corpus_ids, nodeId, conceptId);
                            removeConceptFromExtended(nodeId, conceptId);
                            addConceptToExtra(corpus_ids, item);
                            return;
                        }
                        removeConceptIdFromNodes([nodeId], conceptId);
                        addConceptToNode(nodeId, item);
                    },
                    icon: _jsx(RelatedSearch, {}),
                },
            ]
            : undefined,
        getExtraMenuEntries: () => [addCustomList(selected), ...extraMenuEntries],
    });
    return (_jsx(CoraStateNamedConceptMembersFacet, Object.assign({}, props, { itemInCollectionFilter: nameAndKBsInMembersFilter, valueToSelection: identity, toOption: conceptClusterToOption, getPopupMenuEntries: menuEntries })));
}
