var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API } from "../../../api/api";
import { getCurrentGranularity } from "../../../appState/atomic/queryState/semanticSearch/getCurrentGranularity";
export function getTrendsData({ domain_field, range_field, corpus_filter, selectedGranularity, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const granularityParams = domain_field && corpus_filter
            ? {
                corpus_filter,
                domain_field,
            }
            : undefined;
        const trendsGranularities = !granularityParams
            ? []
            : yield API.supported_granularities(granularityParams);
        const granularity = getCurrentGranularity(trendsGranularities, selectedGranularity);
        const groupByParams = domain_field && corpus_filter && range_field
            ? {
                corpus_filter,
                domain_field,
                granularity: selectedGranularity || trendsGranularities[0],
                range_field,
            }
            : undefined;
        const trendsGroupByFields = !groupByParams || groupByParams.granularity === undefined
            ? []
            : yield API.supported_group_by_fields(groupByParams);
        // TODO
        const range = {}; // TODO: don't set so that we get all results back
        const params = granularity && domain_field && range_field && corpus_filter
            ? {
                corpus_filter,
                domain_field,
                range_field,
                granularity,
                // ...(range.start && range.end && {range}),
            }
            : undefined;
        const trends = !params ? undefined : yield API.trends(params);
        return { granularity, trendsGranularities, trendsGroupByFields, trends, params };
    });
}
