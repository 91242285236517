import { debounce } from "lodash";
import React from "react";
export function useOnElementResize(element, onResize, dependencies = []) {
    React.useLayoutEffect(() => {
        if (!element)
            return;
        const resizeObserver = new ResizeObserver(debounce((entries) => {
            var _a;
            onResize((_a = entries[0]) === null || _a === void 0 ? void 0 : _a.contentRect);
        }, 100));
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    }, [element, ...dependencies]);
}
