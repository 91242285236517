import { highlightsOnlyOutsideOfParse } from "./highlightsOnlyOutsideOfParse";
export function uniqueHighlightsOnlyOutsideOfParse(evidence) {
    const outsideOnlyHighlights = highlightsOnlyOutsideOfParse(evidence);
    // get unique match_ids, because these can be duplicated
    const outsideOnlyStrings = outsideOnlyHighlights.map(x => x.match_id);
    // find the first match, because we want a match_name to display
    const uniqueStrings = [...new Set(outsideOnlyStrings)];
    return uniqueStrings
        .map(h => outsideOnlyHighlights === null || outsideOnlyHighlights === void 0 ? void 0 : outsideOnlyHighlights.find(x => x.match_id === h))
        .filter(x => x);
}
