import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { mapValues } from "lodash";
import { ClusteringMode } from "../../../../api/types/apiTypes";
import { EMPTY_METADATA_PARAMS } from "../../queryState/consts/EMPTY_QUERY_STATE";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const EMPTY_USER_CONFIG = {
    alternativeEvidenceLayout: false,
    alternativeFindingsLayout: false,
    autoExpandEvidence: false,
    conceptClustering: ClusteringMode.AGGRESSIVE,
    conceptExpansion: ClusteringMode.AGGRESSIVE,
    conjunctionBindingsSearchWidth: 50,
    defaultEvidencePageSize: 100,
    evidenceGroupedByDocument: true,
    expandedEvidence: false,
    expandedFacetCount: 20,
    hierarchicalFacets: false,
    metadataSearchParams: EMPTY_METADATA_PARAMS,
    modelBuilderAvailable: false,
    queryGraph: true,
    rank_with_vector_search: false,
    rankWithVectorSearch: true,
    relationExpansion: ClusteringMode.NONE,
    searchAperture: false,
    showAnswer: true,
    showTopResults: false,
    showVectorSearchToggle: false,
    structuredQuery: true,
    useFindingsNlg: false,
    horizontalGraphLayout: true,
    showMultihopStrategySelection: false,
};
const _atoms = mapValues(EMPTY_USER_CONFIG, (v, k) => atomWithReset(v));
export const USER_CONFIG_KEYS = Object.keys(_atoms);
export const a_userConfig = atom(get => {
    return {
        alternativeEvidenceLayout: get(_atoms.alternativeEvidenceLayout),
        alternativeFindingsLayout: get(_atoms.alternativeFindingsLayout),
        autoExpandEvidence: get(_atoms.autoExpandEvidence),
        conceptClustering: get(_atoms.conceptClustering),
        conceptExpansion: get(_atoms.conceptExpansion),
        conjunctionBindingsSearchWidth: get(_atoms.conjunctionBindingsSearchWidth),
        defaultEvidencePageSize: get(_atoms.defaultEvidencePageSize),
        evidenceGroupedByDocument: get(_atoms.evidenceGroupedByDocument),
        expandedEvidence: get(_atoms.expandedEvidence),
        expandedFacetCount: get(_atoms.expandedFacetCount),
        hierarchicalFacets: get(_atoms.hierarchicalFacets),
        metadataSearchParams: get(_atoms.metadataSearchParams),
        modelBuilderAvailable: get(_atoms.modelBuilderAvailable),
        queryGraph: get(_atoms.queryGraph),
        rank_with_vector_search: get(_atoms.rank_with_vector_search),
        rankWithVectorSearch: get(_atoms.rankWithVectorSearch),
        relationExpansion: get(_atoms.relationExpansion),
        searchAperture: get(_atoms.searchAperture),
        showAnswer: get(_atoms.showAnswer),
        showTopResults: get(_atoms.showTopResults),
        showVectorSearchToggle: get(_atoms.showVectorSearchToggle),
        structuredQuery: get(_atoms.structuredQuery),
        useFindingsNlg: get(_atoms.useFindingsNlg),
        horizontalGraphLayout: get(_atoms.horizontalGraphLayout),
        showMultihopStrategySelection: get(_atoms.showMultihopStrategySelection),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_userConfig);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useUserConfig() {
    return useResettableState(a_userConfig);
}
