import { ANY_MARKER } from "../consts/ANY";
import { EMPTY_CONSTRAINT } from "../consts/EMPTY_QUERY_STATE";
import { RefCount } from "./RefCount";
import { getQualifierAnyRefId } from "./getQualifierAnyRefId";
export function getConstraintReferredIds({ id, sources, relation, targets, context, qualifiers, } = EMPTY_CONSTRAINT, ids = RefCount()) {
    ids.addAll([
        ...sources,
        ...targets,
        relation,
        ...context,
        ...Object.values(qualifiers || {}).map(([k, v]) => v === ANY_MARKER ? getQualifierAnyRefId(id, k) : v),
    ].filter(Boolean));
    return ids;
}
