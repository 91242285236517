import { useAtomValue } from "jotai";
import React from "react";
import { useMemoIfEqual } from "../../../utils/lifecycle/useMemoIfEqual";
import { a_corpusFilterBuilder } from "./constraintModel/ConstraintModelUtil";
import { queryStateAtoms } from "./queryStateAtoms";
import { validateTextFilter } from "./semanticSearch/validateTextFilter";
import { invalidCorpusIds } from "./utils/invalidCorpusIds";
import { usePreciseEvidenceOnly } from "./usePreciseEvidenceOnly";
function useChangeCounter(value) {
    const ref = React.useRef(value);
    const count = React.useRef(0);
    React.useLayoutEffect(() => {
        ref.current = value;
        count.current++;
    }, [ref.current, value]);
    function increase() {
        count.current++;
    }
    return [count.current, increase];
}
export function useMetadataParamsGetter(field) {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const disabled = invalidCorpusIds(corpus_ids);
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const use_precise_evidence_only = usePreciseEvidenceOnly();
    const corpus_filter = useMemoIfEqual(disabled
        ? undefined
        : corpusFilterBuilder
            .fullQuery()
            .addQueryToFilter()
            .addContext()
            .addExtra()
            .addBooleanMetadata()
            .addRangeMetadata([field])
            .addKeywordMetadata([field])
            .toCorpusFilter());
    const paramsGetter = React.useCallback((count, prefix = "") => {
        prefix = validateTextFilter(prefix);
        return !corpus_filter
            ? undefined
            : Object.assign(Object.assign({ corpus_filter,
                count,
                field }, ((prefix === null || prefix === void 0 ? void 0 : prefix.length) && { prefix })), { use_precise_evidence_only });
    }, [useMemoIfEqual(corpus_filter)]);
    const [change] = useChangeCounter(corpus_filter);
    return { paramsGetter, change, disabled: !corpus_filter };
}
