import { uniqBy } from "lodash";
import { TwoKey } from "../../../appState/atomic/queryState/types/DoubleDict";
export function calculateBarycentersWithGhosts(layers, // nodes grouped by layer
edges) {
    const maxLayer = Math.max(...Object.keys(layers).map(Number));
    const paddedEdges = edges.reduce((acc, edge) => {
        const fromKey = TwoKey.findKey(TwoKey.from(layers), v => v.id === edge.source);
        const toKey = TwoKey.findKey(TwoKey.from(layers), v => v.id === edge.target);
        // determine if from is a late beginning node
        // if so add a ghost trail to the beginning layer
        const isBeginning = edges.every(e => e.target !== edge.source);
        const isLateBeginning = isBeginning && fromKey && Number(fromKey[0]) > 0;
        if (isLateBeginning) {
            const fromId = `ghost-${edge.source}-start`;
            acc.push({ source: fromId, target: edge.source });
            layers = injectNodeToLayer(layers, 0, { id: fromId, isGhost: true });
        }
        // determine if to is a late ending node
        // if so add a ghost trail to the ending layer
        const isEnding = edges.every(e => e.source !== edge.target);
        const isEarlyEnding = isEnding && toKey && Number(toKey[0]) < maxLayer;
        if (isEarlyEnding) {
            const toId = `ghost-${edge.target}-end`;
            acc.push({ source: edge.target, target: toId });
            layers = injectNodeToLayer(layers, maxLayer, { id: toId, isGhost: true });
        }
        return acc;
    }, [...edges]);
    const extendedEdges = paddedEdges.reduce((acc, edge) => {
        const fromKey = TwoKey.findKey(TwoKey.from(layers), v => v.id === edge.source);
        const toKey = TwoKey.findKey(TwoKey.from(layers), v => v.id === edge.target);
        if (fromKey && toKey) {
            const fromLayer = Number(fromKey[0]);
            const toLayer = Number(toKey[0]);
            const layerGap = toLayer - fromLayer;
            if (layerGap >= 1) {
                for (let i = 1; i <= layerGap; i++) {
                    const currentLayer = fromLayer + i;
                    const fromNodeId = i === 1
                        ? edge.source
                        : `ghost-${edge.source}-${edge.target}-${currentLayer - 1}`;
                    const targetNodeId = currentLayer === toLayer
                        ? edge.target
                        : `ghost-${edge.source}-${edge.target}-${currentLayer}`;
                    if (currentLayer !== toLayer) {
                        layers = injectNodeToLayer(layers, currentLayer, {
                            id: targetNodeId,
                            isGhost: true,
                        });
                    }
                    acc.push({ source: fromNodeId, target: targetNodeId });
                }
            }
        }
        return acc;
    }, []);
    const barycenterLayers = calculateBarycenters(layers, extendedEdges);
    //create position map
    const nodePositions = {};
    barycenterLayers.forEach((layer, i) => {
        layer.forEach((node, j) => {
            if (!node.isGhost) {
                nodePositions[node.id] = [i, j];
            }
        });
    });
    return nodePositions;
}
function calculateBarycenters(layers, edges) {
    const edgeMap = new Map();
    // Create a map of node to nodes it connects to in the next layer
    edges.forEach(edge => {
        var _a;
        if (!edgeMap.has(edge.source)) {
            edgeMap.set(edge.source, []);
        }
        (_a = edgeMap
            .get(edge.source)) === null || _a === void 0 ? void 0 : _a.push(layers.flat().find(node => node.id === edge.target));
    });
    // Apply the Barycenter method to order nodes within each layer
    for (let i = layers.length - 2; i >= 0; i--) {
        layers[i].sort((a, b) => {
            const aConnected = edgeMap.get(a.id) || [];
            const bConnected = edgeMap.get(b.id) || [];
            const aBarycenter = aConnected.reduce((acc, curr) => acc + layers[i + 1].indexOf(curr), 0) /
                aConnected.length;
            const bBarycenter = bConnected.reduce((acc, curr) => acc + layers[i + 1].indexOf(curr), 0) /
                bConnected.length;
            return aBarycenter - bBarycenter;
        });
    }
    return layers;
}
export function toLayers(nodes, depthMap // node id to depth
) {
    const layers = nodes.reduce((acc, n) => {
        const depth = depthMap[n.id];
        acc = injectNodeToLayer(acc, depth, n);
        return acc;
    }, []);
    return layers;
}
function injectNodeToLayer(layers, depth, node) {
    if (!layers[depth]) {
        layers[depth] = [];
    }
    layers[depth] = uniqBy([...layers[depth], node], "id");
    return layers;
}
