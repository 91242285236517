import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { EvidenceType } from "../../api/types/evidenceTypes";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { Chart } from "../common/icons/Icons";
import { TrendsDataChart } from "./TrendsDataChart";
import { useSelectedTrendRangeUpdater } from "./useSelectedTrendRangeUpdater";
export function useTrendsAddOn({ index, evidenceAndTotals, }) {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const setSearchParams = useSetAtom(queryStateAtoms.searchParams);
    const supportsTrends = useIsFeatureSupported(Feature.TRENDS);
    const disabled = invalidCorpusIds(corpus_ids);
    const [{ showTrends }, sessionUpdater] = useCurrentSession();
    const rangeUpdater = useSelectedTrendRangeUpdater();
    React.useEffect(() => {
        if (!showTrends) {
            rangeUpdater();
        }
    }, [showTrends, rangeUpdater]);
    return !supportsTrends
        ? undefined
        : {
            name: "trends",
            index,
            widget() {
                var _a;
                return showTrends ? (_jsx("div", Object.assign({ className: "relative flex flex-col items-center" }, { children: _jsx(TrendsDataChart, { disabled: disabled, setSelectedEvidenceType: type => {
                            setSearchParams({
                                refuting: type === EvidenceType.REFUTING,
                            });
                        }, emptyEvidence: evidenceAndTotals.loading
                            ? "loading"
                            : !((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.total_count) }) }), index)) : undefined;
            },
            selector(key) {
                return {
                    key,
                    icon: _jsx(Chart, {}),
                    tooltip: "View Trends",
                    selected: showTrends,
                    disabled,
                    onSelect: () => sessionUpdater({
                        showTrends: !showTrends,
                    }),
                };
            },
        };
}
