import { groupBy, keyBy } from "lodash";
import { ANY_MARKER } from "../../../appState/atomic/queryState/consts/ANY";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { GraphAnalysis } from "../GraphAnalysis";
import { getNodeValues, otherId } from "../diagramUtils";
import { getArgName, getQuestion, isConcept, isQualifier, isRelation, } from "../model";
export function getConstraintFromNodes(crcNodeIds, id, nodes, edges) {
    var _a;
    const classification = classifyConstraintNodes(crcNodeIds, nodes, edges);
    const { relation, source, target, context, qualifiers, required } = classification;
    return {
        id,
        relationNodeId: (relation === null || relation === void 0 ? void 0 : relation.id) || uuidv4(),
        sourceNodeId: (source === null || source === void 0 ? void 0 : source.id) || uuidv4(),
        targetNodeId: (target === null || target === void 0 ? void 0 : target.id) || uuidv4(),
        //TODO: breaks context but might need to rethink treating context as a group similarly to sources and targets
        contextNodeIds: context.map(c => c.id),
        qualifierNodeIds: Object.assign(Object.assign({}, Object.fromEntries(Object.entries(qualifiers).map(([argName, nodes]) => {
            return [argName, nodes[0].id];
        }))), Object.fromEntries(required.map(n => [getArgName(n), ANY_MARKER]))),
        is_directed: (_a = relation === null || relation === void 0 ? void 0 : relation.data.directed) !== null && _a !== void 0 ? _a : false,
        text: getQuestion(relation) || getQuestion(source),
    };
}
//TODO: this'll need to be updated, no doubt
function classifyConstraintNodes(constraintNodeIds, nodes, edges) {
    const nodesById = keyBy(nodes, n => n.id);
    const cNodes = constraintNodeIds.map(id => nodesById[id]);
    const ga = GraphAnalysis(cNodes, GraphAnalysis(cNodes, edges).edges);
    return Object.assign(Object.assign({ relation: ga.nodes.find(isRelation) }, classifyQualifiers(ga.nodes.filter(isQualifier))), classifyConcepts());
    function classifyConcepts() {
        const empty = {
            source: undefined,
            target: undefined,
            context: [],
        };
        const allConcepts = ga.nodes.filter(isConcept);
        if (!ga.nodes.find(isRelation))
            return allConcepts.length === 1
                ? Object.assign(Object.assign({}, empty), { source: allConcepts[0] }) : Object.assign(Object.assign({}, empty), { context: allConcepts });
        const rId = ga.nodes.find(isRelation).id;
        return ga
            .getEdges(rId)
            .filter(e => isConcept(ga.node(otherId(e, rId))))
            .reduce((acc, e) => {
            const concept = ga.node(otherId(e, rId));
            if (isRelation(e)) {
                acc = Object.assign(Object.assign({}, acc), { [e.target === rId ? "source" : "target"]: concept });
            }
            else {
                acc = Object.assign(Object.assign({}, acc), { context: [...acc.context, concept] });
            }
            return acc;
        }, empty);
    }
    function classifyQualifiers(nodes) {
        const byArgName = groupBy(nodes, getArgName);
        const { qualifiers, required } = Object.entries(byArgName).reduce((acc, [argName, qs]) => {
            const any = qs.find(q => !getNodeValues(q, false).length);
            if (any) {
                acc.required.push(any);
            }
            else {
                acc.qualifiers[argName] = qs;
            }
            return acc;
        }, {
            qualifiers: {},
            required: [],
        });
        return {
            qualifiers: Object.fromEntries(Object.entries(qualifiers).filter(([argName]) => !required.find(n => n.data.argName === argName))),
            required,
        };
    }
}
