var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getAuthToken } from "../../appState/atomic/auth/utils/authInfo";
import { filterByValue } from "../../utils/generic/objects";
import { OnlyOneAsyncCall } from "./OnlyOneAsyncCall";
import { createCache } from "./cache";
import { getCapabilityConfig, getCapabilityIntent, } from "./capabilities";
import { monitoredFetch } from "./monitoredActivity";
function getCapabilityEndpoint(capability, extension) {
    return capability + (extension ? `${extension}` : "");
}
let errorHandler = (error) => {
    console.error(error.message);
    throw error;
};
export function setErrorHandler(handler) {
    errorHandler = handler;
}
function getBody(params) {
    return JSON.stringify(filterByValue(params, v => v !== undefined));
}
function getCacheSize() {
    const cache = new URL(document.location.href).searchParams.get("cache");
    const value = cache !== null ? parseInt(cache) : Number.NaN;
    return isNaN(value) ? 30 : value;
}
const CACHE_SIZE = getCacheSize();
console.log("CACHE_SIZE:", CACHE_SIZE);
function isError(response) {
    return response.status < 200 || response.status >= 300;
}
export function HTTP() {
    function createHeaders(contentType = `application/json`) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = new Headers();
            headers.append("Authorization", `bearer ${yield getAuthToken()}`);
            headers.set("Content-Type", contentType);
            return headers;
        });
    }
    const http = {
        url(root_url, endpoint, params = {}) {
            const url = new URL(endpoint, root_url);
            Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
            return url.toString();
        },
        fetch(root_url, capability, _a) {
            var { headers = {}, params = {}, ignoreErrorHandler, contentType, capabilityExtension } = _a, other = __rest(_a, ["headers", "params", "ignoreErrorHandler", "contentType", "capabilityExtension"]);
            return __awaiter(this, void 0, void 0, function* () {
                const capabilityConfig = getCapabilityConfig(capability);
                const intent = getCapabilityIntent(other.method, capability);
                const endpoint = getCapabilityEndpoint(capability, capabilityExtension);
                const h = yield createHeaders(contentType);
                Object.entries(headers).forEach(([name, value]) => h.set(name, value));
                try {
                    const response = yield monitoredFetch(http.url(root_url, endpoint, params), Object.assign({ headers: h }, other), Object.assign(Object.assign({}, capabilityConfig), { description: intent }), (other === null || other === void 0 ? void 0 : other.signal) || undefined);
                    if (isError(response)) {
                        if (ignoreErrorHandler) {
                            throw response;
                        }
                        else {
                            errorHandler({
                                message: `Error while ${intent}. Response status ${response.status}`,
                                url: http.url(root_url, endpoint, params),
                                endpoint,
                                status: response.status,
                            });
                        }
                    }
                    return response;
                }
                catch (error) {
                    const { message } = error;
                    if (message && message.includes("signal is aborted without reason")) {
                        throw null;
                    }
                    !ignoreErrorHandler &&
                        errorHandler({
                            message: `Error while ${intent}.`,
                            endpoint,
                            error,
                        });
                    throw error;
                }
            });
        },
        delete(root_url, capability, capabilityExtension) {
            return __awaiter(this, void 0, void 0, function* () {
                const headers = yield createHeaders();
                const capabilityConfig = getCapabilityConfig(capability);
                const intent = getCapabilityIntent("DELETE", capability);
                const endpoint = getCapabilityEndpoint(capability, capabilityExtension);
                try {
                    const response = yield monitoredFetch(http.url(root_url, endpoint), {
                        method: "DELETE",
                        headers,
                    }, Object.assign(Object.assign({}, capabilityConfig), { description: intent }));
                    if (isError(response)) {
                        errorHandler({
                            message: `Error while ${intent}. Response status ${response.status}`,
                            url: http.url(root_url, endpoint),
                            endpoint,
                            status: response.status,
                        });
                    }
                    return response;
                }
                catch (error) {
                    errorHandler({
                        message: `Error while ${intent}.`,
                        endpoint,
                        error,
                    });
                    throw error;
                }
            });
        },
        get(root_url, capability, params = {}, config = {}) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield http.fetch(root_url, capability, Object.assign(Object.assign(Object.assign({}, params), config), { method: "GET" }));
            });
        },
        post(root_url, capability, params = {}, config = {}) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield http.fetch(root_url, capability, Object.assign(Object.assign(Object.assign({}, params), config), { method: "POST" }));
            });
        },
    };
    const cache = createCache(CACHE_SIZE, OnlyOneAsyncCall(({ root_url, capability, params }) => __awaiter(this, void 0, void 0, function* () {
        const response = yield http.fetch(root_url, capability, params);
        if (isError(response)) {
            throw `Errror calling ${capability}. Status: ${response.status}`;
        }
        return yield response.json();
    })));
    return Object.assign(Object.assign({}, http), { fetchData(root_url, capability, params = {}, config = {}) {
            return __awaiter(this, void 0, void 0, function* () {
                const { headers, noCache, signal, ignoreErrorHandler, capabilityExtension, } = Object.assign({ headers: {}, noCache: false, signal: null, ignoreErrorHandler: false }, config);
                return yield cache.get({
                    root_url,
                    capability,
                    params: {
                        method: "POST",
                        body: getBody(params),
                        headers,
                        signal,
                        ignoreErrorHandler,
                        capabilityExtension,
                    },
                }, noCache);
            });
        } });
}
