import { useMemoizedValue } from "../../utils/lifecycle/useMemoizedValue";
import { Bounds } from "./Bounds";
import { QTree } from "./QTree";
export function getBounds(n) {
    var _a, _b;
    return Bounds(((_a = n.positionAbsolute) === null || _a === void 0 ? void 0 : _a.x) || n.position.x, ((_b = n.positionAbsolute) === null || _b === void 0 ? void 0 : _b.y) || n.position.y, n.width || 0, n.height || 0);
}
export function getDiagonal(n) {
    return Bounds.toDiagonal(getBounds(n));
}
export function useCollisions(flow) {
    return useMemoizedValue(() => {
        const nodes = flow.getNodes();
        return QTree(nodes.map(n => n.id), id => getBounds(flow.getNode(id)));
    });
}
