import { isEqual } from "lodash";
import React from "react";
export function useMemoIfEqual(value) {
    const ref = React.useRef(value);
    const equal = isEqual(ref.current, value);
    React.useLayoutEffect(() => {
        if (!equal) {
            ref.current = value;
        }
    }, [equal, value]);
    return equal ? ref.current : value;
}
