import { classes } from "../../appState/context/theme/classes";
import { updateItems } from "../../utils/generic/collections";
import { GraphAnalysis } from "./GraphAnalysis";
import { typesAre } from "./diagramUtils";
import { ElementType } from "./model";
const UNAVAILABLE = "Unavailable";
export function markAsUnavailable(flow, sourceNodeId) {
    const source = flow.getNode(sourceNodeId);
    const neighbors = GraphAnalysis(flow.getNodes(), flow.getEdges()).neighborIds(source.id);
    const { type: s, id: sId } = source;
    const unavailable = new Set(flow
        .getNodes()
        .filter(({ type: t, id: tId }) => sId === tId ||
        neighbors.has(tId) ||
        typesAre(s, t, ElementType.RELATION, ElementType.RELATION) ||
        typesAre(s, t, ElementType.CONCEPT, ElementType.QUALIFIER) ||
        typesAre(s, t, ElementType.QUALIFIER, ElementType.QUALIFIER))
        .map(n => n.id));
    flow.setNodes(updateItems(flow.getNodes(), n => ({
        className: classes.set(n.className, n.id !== sId && unavailable.has(n.id), UNAVAILABLE),
    })));
    flow.setEdges(updateItems(flow.getEdges(), e => ({
        className: classes.set(e.className, e.source === sId ||
            e.target === sId ||
            unavailable.has(e.source) ||
            unavailable.has(e.target), UNAVAILABLE),
    })));
}
export function clearUnavailable({ nodes, edges }) {
    return {
        nodes: clear(nodes),
        edges: clear(edges),
    };
    function clear(items) {
        return updateItems(items, i => ({
            className: classes.remove(i.className, UNAVAILABLE),
        }));
    }
}
export function hasUnavailable(className) {
    return classes.has(className, UNAVAILABLE);
}
