var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { Tooltip } from "../popups/Tooltip";
export function ToggleButton(_a) {
    var { onClick = () => { }, disabled = false, selected = false, selectedClass = "bg-gray-200", className = "flex items-center justify-center", children, tooltip, style, width = 24, height = 24 } = _a, props = __rest(_a, ["onClick", "disabled", "selected", "selectedClass", "className", "children", "tooltip", "style", "width", "height"]);
    const buttonStyle = Object.assign(Object.assign({}, style), { width: `${width}px`, height: `${height}px` });
    const button = (_jsx("button", Object.assign({ disabled: disabled, onClick: onClick, className: classes("select-none", disabled && "cursor-not-allowed text-gray-400", selected && !disabled && selectedClass, className), style: Object.assign({}, buttonStyle) }, props, { children: children || null })));
    return tooltip ? _jsx(Tooltip, Object.assign({ content: tooltip }, { children: button })) : button;
}
