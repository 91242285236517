import { useAtomValue } from "jotai";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
export function useCurrentDomainModelDescriptor(descriptors, corpus_ids) {
    var _a, _b;
    const { activeModelId } = useAtomValue(queryStateAtoms.constraintModel);
    if (!activeModelId)
        return undefined;
    return (((_a = descriptors.value) === null || _a === void 0 ? void 0 : _a.find(d => d.id === activeModelId)) ||
        ((_b = descriptors.value) === null || _b === void 0 ? void 0 : _b[0]));
}
