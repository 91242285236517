var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CaretDownFilled, CaretRightFilled, ClockCircleOutlined, } from "@ant-design/icons";
import { Button } from "antd";
import { List, Map } from "immutable";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { keyBy, take } from "lodash";
import React from "react";
import { MetadataType } from "../../../api/types/metadataTypes";
import { useGlobalInfo } from "../../../appState/atomic/globalInfo";
import { toBoolean } from "../../../appState/atomic/queryState/semanticSearch/toBoolean";
import { toYesNo } from "../../../appState/atomic/queryState/semanticSearch/toYesNo";
import { CRCDirection } from "../../../appState/atomic/queryState/types/CRCDirection";
import { useRestoreQuery } from "../../../appState/atomic/queryState/useQueryState";
import { getArgsInfo } from "../../../appState/atomic/queryState/utils/getArgsInfo";
import { isInvalidCorpusId } from "../../../appState/atomic/queryState/utils/isInvalidCorpusId";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { isHistoryEntry, restoreHistoricalQuery, } from "../../../appState/atomic/session/utils/history";
import { QueryId } from "../../../appState/stateRestoration/queryId";
import { coraStateToV3QueryState } from "../../../appState/versionMigration/migrators/stateV1toV3";
import { textComparator } from "../../../utils/generic/comparators";
import { useMemoIfEqual } from "../../../utils/lifecycle/useMemoIfEqual";
import { limitLength } from "../../../utils/text/limitLength";
import { ActionButton } from "../../common/buttons/ActionButton";
import { popModal } from "../../common/modals/Modal";
import { Tooltip } from "../../common/popups/Tooltip";
import { Table, useColumns } from "../../common/table/table2/Table";
import { QueryTextRenderer } from "../../common/text/QueryTextRenderer";
import { AutoRefresh } from "../../common/transitions/AutoRefresh";
import { conceptColorGetter } from "../../conceptColorGetter";
import { rangeToString } from "../../facet/metadata/DatePicker";
import { queryModelToQueryParts } from "../../query/queryStateToQueryParts";
import "./History.less";
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");
function title(title) {
    return _jsx("div", Object.assign({ className: "whitespace-nowrap break-normal" }, { children: title }));
}
function getSelectedMetadata(metadataParams, source, maxCount = Number.MAX_SAFE_INTEGER) {
    const metadata = (source === null || source === void 0 ? void 0 : source.supported_metadata_fields) || [];
    const { rangeMetadata, keywordMetadata, booleanMetadata } = metadataParams;
    const rangeById = keyBy(rangeMetadata, r => r.id);
    const keywordById = keyBy(keywordMetadata, r => r.id);
    const booleanById = keyBy(booleanMetadata, r => r.id);
    return metadata
        .map(m => m.type === MetadataType.DATE
        ? rangeById[m.id]
        : m.type === MetadataType.BOOLEAN
            ? booleanById[m.id]
            : keywordById[m.id])
        .filter(Boolean)
        .map(i => ({
        title: i.name,
        value: i.type === MetadataType.DATE
            ? rangeToString(i.value)
            : i.type === MetadataType.BOOLEAN
                ? toYesNo(toBoolean(i.value))
                : keywordValueAsStr(i, maxCount),
    }));
    function keywordValueAsStr({ value }, maxCount) {
        const suffix = value.length > maxCount ? "…" : "";
        return (take(value, maxCount)
            .map(v => v.name)
            .join(", ") + suffix);
    }
}
function queryPartsToText({ dir, c1, r, c2, context }) {
    const arrow = dir === CRCDirection.BOTH ? "↔" : dir === CRCDirection.C1C2 ? "→" : "←";
    return ((c1 || []).join(",") +
        arrow +
        (r || []).join(",") +
        arrow +
        (c2 || []).join(",") +
        `[${(context || []).sort().join(",")}]`);
}
function getGroupKey(sourceName, queryParts, text) {
    return sourceName + (text || queryPartsToText(queryParts));
}
function overrideStrings(items = [], overrides = {}) {
    if (Array.isArray(overrides)) {
        return (overrides.length ? overrides : items).map(key => ({ key }));
    }
    return items
        .map(i => {
        {
            const override = overrides[i];
            return override
                ? override.map(o => ({ key: i, value: o }))
                : { key: i, value: undefined };
        }
    })
        .flat();
}
function groupKeyValues(keyValues) {
    return keyValues.reduce((acc, o) => {
        if (!acc[o.key]) {
            acc[o.key] = [];
        }
        if (o.value)
            acc[o.key].push(o.value);
        return acc;
    }, {});
}
function joinWithElement(nodes, separatorNode) {
    const result = [];
    nodes.forEach((node, index) => {
        result.push(React.cloneElement(node, { key: `node-${index}` }));
        if (index < nodes.length - 1) {
            result.push(React.cloneElement(separatorNode, { key: `sep-${index}` }));
        }
    });
    return result;
}
function OverrideList({ maxTags, overrideMaps, }) {
    const maps = Object.entries(overrideMaps).reduce((acc, [concept, overrides]) => {
        const maxTagOverflow = maxTags && overrides.length > maxTags;
        if (overrides.length > 0) {
            let tags = (_jsxs(_Fragment, { children: [_jsxs("span", Object.assign({ className: "mr-1" }, { children: [concept, ":"] }), "concept"), joinWithElement(overrides
                        .map((o, idx) => (_jsx("span", Object.assign({ className: "text-primary" }, { children: limitLength(o, 20) }), idx)))
                        .slice(0, maxTags), _jsx("span", Object.assign({ className: "mr-1" }, { children: "," }))), maxTagOverflow && (_jsxs("span", Object.assign({ className: "mr-1" }, { children: [", ", _jsx("span", Object.assign({ className: "text-primary" }, { children: "\u2026" }))] })))] }));
            tags = (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsxs("span", Object.assign({ className: "font-semibold mr-1" }, { children: [concept, ":"] })), _jsx("div", { children: overrides.map((o, idx) => (_jsx("span", Object.assign({ className: "block" }, { children: o }), idx))) })] })) }, { children: tags })));
            acc.push(_jsx("div", Object.assign({ className: "font-semibold flex flex-nowrap" }, { children: tags }), concept));
        }
        return acc;
    }, []);
    return _jsx("div", Object.assign({ className: "whitespace-nowrap" }, { children: maps }));
}
function useTableData() {
    const [{ queryHistory }, sessionUpdater] = useCurrentSession();
    const history = React.useMemo(() => queryHistory.toArray(), [queryHistory]);
    const restoreQueryState = useRestoreQuery();
    const globalInfo = useGlobalInfo();
    const infoById = React.useMemo(() => history.reduce((acc, entry) => {
        var _a;
        const corpus_id = entry.corpus_ids[0];
        if (!acc[corpus_id]) {
            acc[corpus_id] = {
                argsInfo: getArgsInfo(corpus_id, globalInfo),
                corpusName: ((_a = globalInfo.corporaById[corpus_id]) === null || _a === void 0 ? void 0 : _a.name) || corpus_id,
                corpus: globalInfo.corporaById[corpus_id],
            };
        }
        return acc;
    }, {}), [history, globalInfo]);
    return React.useMemo(() => history
        .map((entry, idx) => {
        var _a;
        const { time, corpus_ids } = entry;
        const corpus_id = entry.corpus_ids[0];
        const queryParts = isHistoryEntry(entry)
            ? entry.queryParts
            : queryModelToQueryParts(coraStateToV3QueryState(entry).constraintModel, []);
        const sourceName = infoById[corpus_id].corpusName || corpus_id;
        const qualifiers = Object.entries(queryParts.argClauses || {})
            .map(([key, v]) => !v.length ? [{ key }] : v.map(value => ({ key, value })))
            .flat();
        const docFilters = getSelectedMetadata(entry, (_a = infoById[corpus_id]) === null || _a === void 0 ? void 0 : _a.corpus, 1).map(f => ({
            key: f.title,
            value: f.value,
        }));
        const watchlist = overrideStrings(queryParts.extra, queryParts.extraOverrides);
        return {
            groupKey: getGroupKey(sourceName, queryParts, entry.text),
            key: (idx + ""),
            id: idx + "",
            time: new Date(time),
            text: entry.text,
            sourceName,
            queryParts,
            argColors: infoById[corpus_ids[0]].argsInfo.argColors,
            qualifiers,
            docFilters,
            watchlist,
            restore() {
                return __awaiter(this, void 0, void 0, function* () {
                    sessionUpdater({ modelBuilderMode: false });
                    popModal(true);
                    const queryState = yield restoreHistoricalQuery(entry);
                    restoreQueryState(queryState);
                });
            },
        };
    })
        .sort((h1, h2) => h2.time.getTime() - h1.time.getTime()), [history, globalInfo]);
}
function useGroupedTableData() {
    const data = useTableData();
    return React.useMemo(() => {
        const grouped = data.reduce((acc, row) => {
            const key = row.groupKey;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(row);
            return acc;
        }, {});
        return Object.entries(grouped)
            .map(([key, rows]) => ({
            key,
            rows: rows.sort((r1, r2) => r2.time.getTime() - r1.time.getTime()),
            time: rows[0].time,
        }))
            .sort((r1, r2) => r2.time.getTime() - r1.time.getTime());
    }, [data]);
}
const groupColumnDef = [
    {
        key: "assign",
        title: "",
        width: 125,
        render(value, row) {
            return (_jsx(Tooltip, Object.assign({ content: "Restore latest version of this query" }, { children: _jsx(ActionButton, Object.assign({ className: "cursor-pointer min-w-4 px-2 text-gray-500 border-gray-500 hover:bg-purple-700 hover:text-white", sizeClass: " ", onClick: row.rows[0].restore, disabled: isInvalidCorpusId(row.rows[0].sourceName) }, { children: "Restore Latest" })) })));
        },
    },
    {
        key: "time",
        title: title("Time"),
        width: 75,
        render(value, row) {
            return (_jsx(AutoRefresh, { interval: 5000, content: () => (_jsx(Tooltip, Object.assign({ content: _jsx("div", { children: `${row.time.toLocaleDateString()} ${row.time.toLocaleTimeString()}` }) }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-1" }, { children: [_jsx(ClockCircleOutlined, { className: "text-tiny" }), _jsx("div", { children: timeAgo.format(row.time, "mini-now") })] })) }))) }));
        },
        sorter: (a, b) => a.time.getTime() - b.time.getTime(),
    },
    {
        key: "source",
        title: title("Source"),
        width: 225,
        render(value, row) {
            return _jsx("span", { children: row.rows[0].sourceName });
        },
        sorter: (a, b) => textComparator(a.rows[0].sourceName, b.rows[0].sourceName),
    },
    {
        key: "query",
        title: title("Query"),
        render(value, row) {
            return (row.rows[0].text || (_jsx(QueryTextRenderer, { maxTags: 2, parts: row.rows[0].queryParts, partClassName: "text-primary font-semibold", conceptColorGetter: conceptColorGetter })));
        },
    },
];
const columnDef = [
    {
        key: "assign",
        title: "",
        width: 50,
        render(value, row) {
            return (_jsx(Tooltip, Object.assign({ content: "Restore this query version" }, { children: _jsx(ActionButton, Object.assign({ className: "cursor-pointer min-w-4 px-2 text-gray-500 border-gray-500 hover:bg-purple-700 hover:text-white", sizeClass: " ", onClick: row.restore, disabled: isInvalidCorpusId(row.sourceName) }, { children: "Restore" })) })));
        },
    },
    {
        key: "time",
        title: title("Time"),
        width: 50,
        render(value, row) {
            return (_jsx(AutoRefresh, { interval: 5000, content: () => (_jsx(Tooltip, Object.assign({ content: _jsx("div", { children: `${row.time.toLocaleDateString()} ${row.time.toLocaleTimeString()}` }) }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-1" }, { children: [_jsx(ClockCircleOutlined, { className: "text-tiny" }), _jsx("div", { children: timeAgo.format(row.time, "mini-now") })] })) }))) }));
        },
        sorter: (a, b) => a.time.getTime() - b.time.getTime(),
        // sortOrder: "descend",
    },
    {
        key: "conceptFilters",
        title: title("Concept Filters"),
        render(value, row) {
            const overrides = groupKeyValues([
                overrideStrings(row.queryParts.c1, row.queryParts.c1Overrides),
                overrideStrings(row.queryParts.c2, row.queryParts.c2Overrides),
                overrideStrings(row.queryParts.context, row.queryParts.contextOverrides),
            ].flat());
            return _jsx(OverrideList, { overrideMaps: overrides, maxTags: 2 });
        },
    },
    {
        key: "qualifiers",
        title: title("Qualifiers"),
        render(value, row) {
            return (_jsx(OverrideList, { overrideMaps: groupKeyValues(row.qualifiers), maxTags: 2 }));
        },
    },
    {
        key: "docFilters",
        title: title("Doc Filters"),
        render(value, row) {
            return (_jsx(OverrideList, { overrideMaps: groupKeyValues(row.docFilters), maxTags: 2 }));
        },
    },
    {
        key: "watchlist",
        title: title("Watchlist Filters"),
        render(value, row) {
            const overrides = groupKeyValues(row.watchlist);
            return _jsx(OverrideList, { overrideMaps: overrides, maxTags: 2 });
        },
    },
];
export function History() {
    const [{ queryHistory }, sessionUpdater] = useCurrentSession();
    const groupedData = useGroupedTableData();
    const [tableState] = React.useState({});
    const columns = useColumns(columnDef, tableState);
    const groupColumns = useColumns(groupColumnDef, tableState);
    const [size] = React.useState({
        width: 0,
        height: window.innerHeight,
    });
    const tableContainerRef = React.useRef(null);
    // TODO: this is a hack to fix a table layout where the table will expand but not shrink
    // the ant table seems to ignore the tableLayout="fixed" property in some cases
    React.useEffect(() => {
        const tableContainer = tableContainerRef.current;
        const table = tableContainer && tableContainer.querySelector("table");
        if (table && table.style.tableLayout === "fixed") {
            table.style.tableLayout = "auto";
        }
    }, [tableContainerRef]);
    return (_jsxs("div", Object.assign({ ref: tableContainerRef, className: "flex flex-col flex-1 relative justify-between" }, { children: [_jsx(Table, { size: "small", pagination: false, dataSource: groupedData, columns: groupColumns, tableLayout: "auto", scroll: useMemoIfEqual({ y: size.height - 225 }), expandable: {
                    expandIcon: ({ expanded, onExpand, record }) => {
                        return expanded ? (_jsx(CaretDownFilled, { onClick: e => onExpand(record, e) })) : (_jsx(CaretRightFilled, { onClick: e => onExpand(record, e) }));
                    },
                    expandedRowRender: record => (_jsx(Table, { className: "HistorySubtable", size: "small", pagination: false, dataSource: record.rows, columns: columns, scroll: { x: true }, tableLayout: "auto" })),
                    rowExpandable: record => record.rows.length > 0,
                } }), _jsx("div", Object.assign({ className: "py-2" }, { children: _jsx(Button, Object.assign({ disabled: !queryHistory.size, onClick: () => {
                        sessionUpdater(s => ({
                            queryHistory: List(),
                            recentConcepts: {},
                            recentRelations: {},
                            hiddenGraphNodes: Map(),
                        }));
                        QueryId.set("");
                    } }, { children: "Clear Query History" })) }))] })));
}
