import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTheme } from "../appState/context/theme/ThemeContext";
const BAR_WIDTH = 24;
const BAR_HEIGHT = 4;
function getConfidenceColor(confidence) {
    return confidence > 0.5 ? "green" : confidence > 0.3 ? "orange" : "red";
}
export function ConfidenceBar({ confidence, width = BAR_WIDTH, classname, }) {
    const theme = useTheme();
    return (_jsx("div", Object.assign({ className: classname, style: {
            minWidth: width,
            height: BAR_HEIGHT,
            borderRadius: BAR_HEIGHT / 2,
            backgroundColor: theme.colors.background,
            position: "relative",
        } }, { children: _jsx("div", { style: {
                position: "absolute",
                top: 0,
                left: 0,
                width: width * confidence,
                height: BAR_HEIGHT,
                borderRadius: BAR_HEIGHT / 2,
                backgroundColor: getConfidenceColor(confidence),
            } }) })));
}
