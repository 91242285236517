import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ReactFlowProvider } from "reactflow";
import { DiagramModeProvider } from "./DiagramMode";
import "./ModelBuilder.less";
import { ModelBuilderDiagram } from "./ModelBuilderDiagram";
import { ModelBuilderId } from "./diagramUtils";
export function ModelBuilder() {
    React.useEffect(() => {
        setTimeout(() => wrapper.current.focus(), 59);
    }, []);
    const wrapper = React.useRef(null);
    return (_jsx("div", Object.assign({ ref: wrapper, id: ModelBuilderId, tabIndex: 0, className: "ModelBuilder h-full flex-1 flex flex-col items-stretch justify-center bg-white" }, { children: _jsx(ReactFlowProvider, { children: _jsx(DiagramModeProvider, { children: _jsx(ModelBuilderDiagram, { parent: wrapper.current }) }) }) })));
}
