export function createTransmuter(type, toQuery, toValue, valid) {
    return {
        type,
        toQuery: value => (value === undefined ? undefined : toQuery(value)),
        toValue: query => {
            if (query === undefined)
                return undefined;
            const value = toValue(query);
            if (!valid(value))
                throw `[${value}] is not ${type}`;
            return value;
        },
        valid,
    };
}
