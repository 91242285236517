import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { popModal, pushModal } from "../../../common/modals/Modal";
import { Scroller } from "../../../common/layout/Scroller";
import { classes } from "../../../../appState/context/theme/classes";
import { Button } from "antd";
import { columnWidthClass2 } from "../../../../appState/context/theme/lf";
function Citations({ citations }) {
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch flex-1" }, { children: [_jsx(Scroller, Object.assign({ className: "bg-white" }, { children: _jsx("ol", Object.assign({ type: "1", className: "list-decimal space-y-2 py-4 pl-12 pr-8" }, { children: citations.map((citation, index) => (_jsxs("li", { children: [_jsx(Part, { content: citation.authors.join(", ") }), _jsx(Part, { content: citation.title }), _jsx(Part, { content: citation.publication_name }), _jsx(Part, { content: citation.publication_date }), citation.external_url && (_jsxs("span", { children: ["[", _jsx("a", Object.assign({ href: citation.external_url, target: "_blank", rel: "noreferrer", className: "underline" }, { children: "source" })), "]"] }))] }, index))) })) })), _jsx("div", Object.assign({ className: classes("flex items-center space-x-1 mt-4 justify-end") }, { children: _jsx(Button, Object.assign({ className: columnWidthClass2, type: "primary", onClick: () => popModal(true) }, { children: "Close" })) }))] })));
    function Part({ content }) {
        return content ? (_jsxs("span", { children: [content, ". "] })) : null;
    }
}
export function showCitations(citations) {
    pushModal({
        title: "Citations",
        simpleTitle: true,
        className: "w-[800px] z-[1100]",
        content: _jsx(Citations, { citations: citations }),
        easyClose() {
            return true;
        },
    });
}
