import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext } from "react";
import { getFindingIndexId } from "../../../components/findingsRedesign/getFindingIndexId";
import { useFindingState } from "../../atomic/session/parts/findingsState";
const DEFAULT_STATE = {
    finding: null,
    index: -1,
    indexId: null,
    mode: "view",
};
const FindingContext = createContext(undefined);
export function useFindingContext() {
    const context = useContext(FindingContext);
    if (!context)
        throw new Error("FindingCard must be used within a FindingProvider");
    return context;
}
//TODO: this may duplicate the finding, use a ref instead
export function FindingProvider({ children }) {
    const [{ findings }, findingsUpdater] = useFindingState();
    const reducer = React.useCallback((state, action) => {
        function openFinding(findingId, mode) {
            const index = findings.findIndex(f => f.id === findingId);
            if (index >= 0) {
                const finding = findings[index];
                return {
                    finding,
                    index,
                    indexId: getFindingIndexId(index),
                    mode: mode,
                };
            }
            else {
                console.error(`Finding ${findingId} not found`);
                return state;
            }
        }
        function updateFinding(finding, mode) {
            const index = findings.findIndex(f => f.id === finding.id);
            if (index >= 0) {
                const newFindings = [...findings];
                newFindings[index] = finding;
                findingsUpdater({ findings: newFindings });
                return Object.assign(Object.assign({}, state), { finding: finding, mode: mode || "view" });
            }
            else {
                console.error(`Finding ${finding.id} not found`);
                return state;
            }
        }
        function deleteFinding(findingId) {
            const index = findings.findIndex(f => f.id === findingId);
            if (index >= 0) {
                const newFindings = [...findings];
                newFindings.splice(index, 1);
                findingsUpdater({ findings: newFindings });
                return Object.assign(Object.assign({}, state), { finding: null });
            }
            else {
                console.error(`Finding ${findingId} not found`);
                return state;
            }
        }
        switch (action.type) {
            case "open":
                return openFinding(action.findingId, action.mode);
            case "close":
                return DEFAULT_STATE;
            case "setMode":
                return Object.assign(Object.assign({}, state), { mode: action.mode });
            case "update":
                return updateFinding(action.finding);
            case "delete":
                return deleteFinding(action.findingId);
            default:
                throw new Error(`Unhandled action type: ${action}`);
        }
    }, [findings]);
    const [state, dispatch] = React.useReducer(reducer, DEFAULT_STATE);
    return (_jsx(FindingContext.Provider, Object.assign({ value: { state, dispatch } }, { children: children })));
}
