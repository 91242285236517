var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { atom, useAtom, useSetAtom } from "jotai";
import { API } from "../../../api/api";
import { useInitializer } from "../../../utils/lifecycle/useInitializer";
import { IGNORED_SESSION_IDS } from "./utils/globalRegistry";
import { isUnnamedSession } from "./utils/isUnnamedSession";
export const a_directory = atom([]);
export function useSessionsDirectory(includeUnnamed = false) {
    const [directory] = useAtom(a_directory);
    return !includeUnnamed
        ? directory.filter(s => !isUnnamedSession(s.id))
        : directory;
}
export function useLoadSessionsDirectory() {
    const setDirectory = useSetAtom(a_directory);
    return useInitializer(() => __awaiter(this, void 0, void 0, function* () {
        const directory = yield API.getSessionsDirectory();
        setDirectory(directory.filter(d => !IGNORED_SESSION_IDS().includes(d.id)));
    }));
}
