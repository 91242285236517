import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterFilled } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_constraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_resetConceptNodes } from "../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_resetRelations } from "../../appState/atomic/queryState/constraintModel/relations";
import { a_resetAllFilters } from "../../appState/atomic/queryState/constraintModel/resets";
import { SOURCE_CAPTION } from "../../appState/atomic/queryState/consts/SOURCE_CAPTION";
import { TARGET_CAPTION } from "../../appState/atomic/queryState/consts/TARGET_CAPTION";
import { ConceptSource } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { useDisplayState } from "../../appState/atomic/session/parts/displayState";
import { useFacetState } from "../../appState/atomic/session/parts/facetState";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { a_currentExtraConceptNodeIds } from "../../appState/atomic/session/parts/watchlists";
import { useTheme } from "../../appState/context/theme/ThemeContext";
import { classes } from "../../appState/context/theme/classes";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { Close } from "../common/icons/Icons";
import "./CRCFacets.less";
import { FacetsForCRCConcepts } from "./FacetsForCRCConcepts";
import { FacetsForQualifiers } from "./FacetsForQualifiers";
import { FilterTabs, a_facetActiveTab } from "./FilterTabs";
import { WatchlistFacets } from "./WatchlistFacets";
import { MetadataFacets } from "./metadata/MetadataFacets";
function useClearFacetsSelectionWhenClusteringChanges() {
    const constraint = useAtomValue(a_activeConstraint);
    const [{ conceptClustering }] = useUserConfig();
    const resetConceptNodes = useSetAtom(a_resetConceptNodes);
    const resetRelations = useSetAtom(a_resetRelations);
    useEffectIfDifferent(() => {
        resetConceptNodes([constraint.sourceNodeId]);
        resetConceptNodes([constraint.targetNodeId]);
        resetRelations();
    }, [], [conceptClustering]);
}
function useExpandCollapseC1C2Facets() {
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const s = modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, [
        constraint.sourceNodeId,
    ]).length;
    const t = modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, [
        constraint.targetNodeId,
    ]).length;
    const r = modelUtil.getAllRelationIds(constraint).length;
    const [, facetUpdater] = useFacetState();
    React.useEffect(() => {
        facetUpdater(facetState => ({
            expandedFacetGroups: [
                ...facetState.expandedFacetGroups.filter(name => name !== SOURCE_CAPTION && name !== TARGET_CAPTION),
                ...(s || (r && t) ? [SOURCE_CAPTION] : []),
                ...(t || (r && s) ? [TARGET_CAPTION] : []),
            ],
        }));
    }, [s, t, r, facetUpdater]);
}
export function CRCFacets({ argsInfo }) {
    var _a;
    const [feedback, setFeedback] = React.useState({});
    useClearFacetsSelectionWhenClusteringChanges();
    useExpandCollapseC1C2Facets();
    const [, displayUpdater] = useDisplayState();
    const constraint = useAtomValue(a_activeConstraint);
    const resetFilters = useSetAtom(a_resetAllFilters);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const extraNodeIds = useAtomValue(a_currentExtraConceptNodeIds);
    const [activeTab, setActiveTab] = useAtom(a_facetActiveTab);
    const hasConceptFilters = useIsFeatureSupported(Feature.CONCEPT_FILTER_PANEL);
    const hasQualifierFilters = useIsFeatureSupported(Feature.QUALIFIER_FILTER_PANEL);
    const hasWatchlist = useIsFeatureSupported(Feature.WATCHLIST_PANEL);
    const items = [];
    if (hasConceptFilters) {
        items.push({
            key: FilterTabs.Concepts,
            label: button(FilterTabs.Concepts, modelUtil.hasConstraintOverrides(constraint)),
            children: _jsx(FacetsForCRCConcepts, {}),
        });
    }
    items.push({
        key: FilterTabs.Document,
        label: button(FilterTabs.Document, modelUtil.hasMetadataFilters()),
        children: _jsx(MetadataFacets, {}),
    });
    if (hasQualifierFilters) {
        items.push({
            key: FilterTabs.Qualifiers,
            label: button(FilterTabs.Qualifiers, modelUtil.hasQualifierFilters(constraint)),
            children: (_jsx(FacetsForQualifiers, { argsInfo: argsInfo, feedback: feedback, setFeedback: setFeedback })),
        });
    }
    if (hasWatchlist) {
        items.push({
            key: FilterTabs.Watchlist,
            label: button(FilterTabs.Watchlist, modelUtil.hasExtendedOverrides(extraNodeIds)),
            children: (_jsx(WatchlistFacets, { feedback: feedback, setFeedback: setFeedback })),
        });
    }
    const defaultTab = (_a = items[0]) === null || _a === void 0 ? void 0 : _a.key;
    const activeTabInItems = items.find(item => item.key === activeTab);
    React.useEffect(() => {
        if (!activeTabInItems) {
            setActiveTab(defaultTab);
        }
    }, [activeTab, defaultTab, activeTabInItems, setActiveTab]);
    return (_jsxs("div", Object.assign({ className: classes("CRCFacets flex-1 flex flex-col items-stretch space-y-2 mt-2") }, { children: [_jsxs("div", Object.assign({ className: "font-bold flex items-center justify-between mr-6" }, { children: [_jsx(Filtered, Object.assign({ filtered: modelUtil.isConstraintFiltered(constraint, extraNodeIds) }, { children: "Filters" })), _jsxs("div", Object.assign({ className: "flex space-x-1 items-center" }, { children: [_jsx(Button, Object.assign({ size: "small", disabled: !modelUtil.isConstraintFiltered(constraint, extraNodeIds), onClick: () => {
                                    resetFilters();
                                } }, { children: "Clear All" })), _jsx(Close, { className: " cursor-pointer", onClick: () => displayUpdater({ showFilters: false }) })] }))] })), _jsx(Tabs, { className: "FacetTabs", defaultActiveKey: defaultTab, activeKey: activeTab, onTabClick: key => setActiveTab(key), items: items })] })));
    function button(caption, filtered) {
        return (_jsx("span", Object.assign({ className: classes("flex-1 flex justify-center text-tiny select-none", filtered ? "filtered-tab" : "unfiltered-tab") }, { children: _jsx(Filtered, Object.assign({ filtered: filtered }, { children: caption })) })));
    }
}
function Filtered({ filtered, children, }) {
    const theme = useTheme();
    return (_jsxs("div", Object.assign({ className: "flex items-center space-x-1 select-none" }, { children: [_jsx("span", { children: children }), filtered && (_jsx("div", Object.assign({ style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    width: "16px",
                    height: "16px",
                    backgroundColor: theme.colors.primary,
                } }, { children: _jsx(FilterFilled, { className: "text-xxs text-white", style: { marginRight: "0px" } }) })))] })));
}
