import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const EMPTY_FINDINGS_STATE = {
    findings: [],
    findingCollapsedSections: {},
};
const _atoms = {
    findings: atomWithReset(EMPTY_FINDINGS_STATE.findings),
    findingCollapsedSections: atomWithReset(EMPTY_FINDINGS_STATE.findingCollapsedSections),
};
export const FINDINGS_STATE_KEYS = Object.keys(_atoms);
export const a_findingsState = atom(get => {
    return {
        findings: get(_atoms.findings),
        findingCollapsedSections: get(_atoms.findingCollapsedSections),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_findingsState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useFindingState() {
    return useResettableState(a_findingsState);
}
