import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Select } from "antd";
import React from "react";
import { useCurrentSource } from "../../api/useCurrentSource";
import { useAllTags } from "../../appState/atomic/allTags";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { sortMetadata } from "../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { useFindingContext } from "../../appState/context/findings/FindingContext";
import { addEvidenceToFinding, updateFindingPayload, } from "../../appState/context/findings/findings";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { ForwardButton } from "../common/icons/Icons";
import { FlexFill } from "../common/layout/FlexFill";
import { GroupedList } from "../common/layout/GroupedList";
import { EvidenceCard as OldEvidenceCard } from "../evidence/cards/EvidenceCard";
import { useUrlOpener } from "../evidence/useUrlOpener";
import { Panel } from "../layout/panels/Panel";
import { InPlaceInput } from "./InPlaceInput";
export function FindingsViewer() {
    var _a, _b, _c, _d;
    const { state, dispatch } = useFindingContext();
    const [title, setTitle] = React.useState(((_a = state.finding) === null || _a === void 0 ? void 0 : _a.title) || "");
    const [text, setText] = React.useState(((_b = state.finding) === null || _b === void 0 ? void 0 : _b.payload.selectedText) || "");
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const [{ structuredQuery: sq, showStructuredQuery, findings }, sessionUpdater] = useCurrentSession();
    const structuredQuery = sq && showStructuredQuery;
    const source = useCurrentSource();
    const tags = useAllTags((_c = state.finding) === null || _c === void 0 ? void 0 : _c.payload.tags);
    const { suggestEvidence } = useSuggestedEvidence();
    const currentContextTitle = useCurrentContextTitle();
    const addEvidenceToNewFinding = (unusedFinding, evidence) => {
        if (!state.finding)
            return;
        const newFinding = addEvidenceToFinding(state.finding, evidence);
        dispatch({ type: "update", finding: newFinding });
    };
    const evidences = ((_d = state.finding) === null || _d === void 0 ? void 0 : _d.payload.evidence) || [];
    const evidenceItems = evidences.map(e => ({
        type: "evidence",
        section: "evidence",
        value: e,
    }));
    function renderSectionHeader(section, index) {
        function render() {
            switch (section) {
                case "description":
                    return (_jsx(InPlaceInput, { className: "font-bold text-lg", value: title, mode: state.mode, onChange: title => {
                            setTitle(title);
                        } }));
                case "evidence":
                    return (_jsx("div", Object.assign({ className: "pt-2 pb-2" }, { children: _jsxs("h4", Object.assign({ className: "font-bold" }, { children: [evidences.length, " evidence items"] })) })));
                default:
            }
        }
        return _jsx("div", Object.assign({ className: "bg-background_primary pb-2" }, { children: render() }));
    }
    const EvidenceCard = OldEvidenceCard;
    const urlOpenerFn = useUrlOpener({
        suggestEvidence,
        currentContextTitle,
        findings,
        structuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        source,
    });
    function renderItem(item, index) {
        var _a, _b;
        switch (item.type) {
            case "description":
                return (_jsxs("div", { children: [_jsx("div", { children: _jsx(InPlaceInput, { className: "text-base", value: text, mode: state.mode, isArea: true, onChange: text => {
                                    setText(text);
                                } }) }), _jsx("div", Object.assign({ className: "flex items-center py-2" }, { children: state.mode === "view" ? (_jsx(BorderlessButton, Object.assign({ onClick: () => dispatch({ type: "setMode", mode: "edit" }) }, { children: "Edit" }))) : (_jsx(BorderlessButton, Object.assign({ onClick: () => {
                                    if (!state.finding)
                                        return;
                                    dispatch({
                                        type: "update",
                                        finding: Object.assign(Object.assign({}, state.finding), { title, payload: Object.assign(Object.assign({}, state.finding.payload), { selectedText: text }) }),
                                    });
                                } }, { children: "Save" }))) })), _jsxs("div", Object.assign({ className: "flex items-center py-2", style: { flexGrow: "0.5" } }, { children: [_jsx("span", Object.assign({ className: "pr-2" }, { children: "Tags" })), _jsx(Select, Object.assign({ mode: "tags", allowClear: true, style: { width: "100%" }, placeholder: "Finding Tags", defaultValue: (_a = state.finding) === null || _a === void 0 ? void 0 : _a.payload.tags, value: (_b = state.finding) === null || _b === void 0 ? void 0 : _b.payload.tags, onChange: tags => {
                                        if (!state.finding)
                                            return;
                                        const newFinding = updateFindingPayload(state.finding, {
                                            tags,
                                        });
                                        dispatch({ type: "update", finding: newFinding });
                                    } }, { children: tags === null || tags === void 0 ? void 0 : tags.map(tag => (_jsx(Select.Option, Object.assign({ value: tag }, { children: tag }), tag))) }))] }))] }));
            case "evidence":
                return (_jsx(EvidenceCard, { evidence: [item.value], metadataSorter: sortMetadata(source), urlOpenerFn: urlOpenerFn }));
            default:
        }
        return _jsx(_Fragment, {});
    }
    return (state.finding && (_jsx(Panel, Object.assign({ className: "FindingsViewer" }, { children: _jsxs(FlexFill, { children: [_jsx("div", { children: _jsx(BorderlessButton, Object.assign({ onClick: () => dispatch({ type: "close" }) }, { children: _jsx(ForwardButton, { style: { fontSize: "30px", transform: "rotate(180deg)" } }) })) }), _jsxs(FlexFill, Object.assign({ className: "flex-col space-y-4" }, { children: [_jsx("div", Object.assign({ className: "font-bold text-lg" }, { children: state.indexId })), _jsx(GroupedList, { items: [
                                {
                                    type: "description",
                                    section: "description",
                                },
                                ...evidenceItems,
                            ], groupContentRender: renderSectionHeader, itemContentRender: renderItem })] }))] }) }))));
}
