import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import FullPageError from "../../../../components/common/errorHandling/FullPageError";
import { getConfig } from "../../../config/serverConfig";
import { Auth0StateUpdater } from "./Auth0StateUpdater";
/**
 * Primarily a wrapper around Auth0Provider, but also intercepts error messages
 * that we may get in return from Auth0 (e.g., an expired org invite)
 */
export function Auth0CoraProvider({ children }) {
    React.useEffect(() => {
        console.log("Using Auth0 Provider");
    }, []);
    // look for auth0 params (and our custom redirect) for special handling
    const params = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        return {
            error: params.get("error"),
            error_description: params.get("error_description"),
            redirect: params.get("redirect"),
        };
    }, [window.location.search]);
    if (params.error && params.error_description) {
        return _jsx(FullPageError, { message: params.error_description });
    }
    if (params.redirect) {
        window.location.href = params.redirect;
        return null;
    }
    return (_jsxs(Auth0Provider, Object.assign({ domain: getConfig("AUTH0_DOMAIN"), clientId: getConfig("AUTH0_CLIENT_ID"), authorizationParams: {
            redirect_uri: window.location.origin,
            audience: getConfig("AUTH0_AUDIENCE"),
            scope: "openid profile email offline_access",
        }, useRefreshTokens: true, useRefreshTokensFallback: true, useCookiesForTransactions: true, useFormData: false, cacheLocation: "localstorage", onRedirectCallback: () => {
            // Auth0 sometimes leaves these in our URL after login
            const params = new URLSearchParams(window.location.search);
            if (params.get("code") || params.get("state")) {
                window.location.href = window.location.pathname;
            }
        } }, { children: [_jsx(Auth0StateUpdater, {}), children] })));
}
