import { isEmpty } from "lodash";
export class TwoKey {
    static from(twoDArray) {
        const dict = {};
        for (let i = 0; i < twoDArray.length; i++) {
            for (let j = 0; j < twoDArray[i].length; j++) {
                dict[i] = dict[i] || {};
                dict[i][j] = twoDArray[i][j];
            }
        }
        return dict;
    }
    static get(dict, key1, key2) {
        var _a;
        return (_a = dict[key1]) === null || _a === void 0 ? void 0 : _a[key2];
    }
    static getPrime(dict, key1) {
        return dict[key1] || {};
    }
    static set(dict, key1, key2, value) {
        const d = Object.assign({}, dict);
        if (!d[key1]) {
            d[key1] = {};
        }
        const dd = Object.assign({}, dict[key1]);
        dd[key2] = value;
        d[key1] = dd;
        return d;
    }
    static setPrime(dict, key1, value) {
        const d = Object.assign({}, dict);
        d[key1] = value;
        return d;
    }
    static has(dict, key1, key2) {
        var _a;
        return !!((_a = dict[key1]) === null || _a === void 0 ? void 0 : _a[key2]);
    }
    static deletePrime(dict, key1) {
        const d = Object.assign({}, dict);
        delete d[key1];
        return d;
    }
    static delete(dict, key1, key2) {
        const d = Object.assign({}, dict);
        if (d[key1]) {
            const dd = Object.assign({}, dict[key1]);
            delete dd[key2];
            if (isEmpty(dd)) {
                delete d[key1];
            }
            else {
                d[key1] = dd;
            }
        }
        return d;
    }
    static findKey(dict, finder) {
        for (const key1 in dict) {
            for (const key2 in dict[key1]) {
                if (finder(dict[key1][key2])) {
                    return [key1, key2];
                }
            }
        }
        return null;
    }
    static clean(dict) {
        const d = Object.assign({}, dict);
        for (const key1 in d) {
            if (isEmpty(d[key1])) {
                delete d[key1];
            }
        }
        return d;
    }
}
