import { jsx as _jsx } from "react/jsx-runtime";
import queryString from "query-string";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import { Loading } from "../../../../components/common/transitions/Loading";
export function OIDCDefaultLoginRoute() {
    const loc = useLocation();
    const { signinRedirect } = useAuth();
    React.useEffect(() => {
        const parsed = queryString.parse(loc.search);
        /* TODO: from Auth0 may be needed here
        {
          authorizationParams: {
            invitation:
              typeof parsed.invitation == "string" ? parsed.invitation : undefined,
            organization:
              typeof parsed.organization == "string"
                ? parsed.organization
                : undefined,
          },
        }
        */
        signinRedirect().catch(console.error);
    }, [loc.search]);
    return _jsx(Loading, { loading: true });
}
