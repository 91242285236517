import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { isMulthop } from "../../api/types/MultihopRelationship";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { AnswerCard } from "./AnswerCard";
import { Summaries } from "./Summaries";
export function useSummaries({ summaries, answer, loadingSummaries, loadingAnswer, corpus_filter, evidence, getEvidenceWithPosition, loadingEvidence, addFilter, clearFilters, }) {
    const { discoveryStrategy } = useAtomValue(queryStateAtoms.multihopConfig);
    const multihop = isMulthop(discoveryStrategy);
    const { resetEvidenceSelection } = useEvidenceSelection();
    const [{ selectedSummaries, showSummary }, sessionUpdater] = useCurrentSession();
    const summariesList = !multihop && showSummary && (_jsx(Summaries, { evidence: evidence, summaries: summaries, loadingSummaries: loadingSummaries, loadingEvidence: loadingEvidence, corpus_filter: corpus_filter, selected: selectedSummaries, setSelected: selectedSummaries => {
            resetEvidenceSelection();
            sessionUpdater({ selectedSummaries });
        }, getEvidenceWithPosition: getEvidenceWithPosition, addFilter: addFilter, clearFilters: clearFilters }));
    const question = corpus_filter.queries && corpus_filter.queries.length > 0
        ? corpus_filter.queries[0].text
        : null;
    const answerWidget = !multihop && question && (_jsx(AnswerCard, { question: question, answer: answer, loading: loadingEvidence || loadingAnswer, getEvidenceWithPosition: getEvidenceWithPosition }));
    return {
        answerWidget,
        summaries: multihop ? [] : summaries,
        summariesList,
        loadingSummaries: !multihop && loadingSummaries,
    };
}
