import { calculateBarycentersWithGhosts, toLayers, } from "./calculateBarycentersWithGhosts";
import { nodeIdsWithGraphDepths } from "./nodeIdsWithGraphDepths";
const PADDING = { x: 30, y: 20 };
export function getPositionedNodes(nodes, edges, axis) {
    const depthMap = nodeIdsWithGraphDepths(nodes, edges);
    const nodeBarycenters = calculateBarycentersWithGhosts(toLayers(nodes, depthMap), edges);
    const positionedNodes = repositionNodes(nodes, nodeBarycenters, axis);
    return positionedNodes;
}
export function repositionNodes(nodes, barycenters, axis) {
    const maxLenByLayer = nodes.reduce((acc, node) => {
        var _a;
        const [layer, _] = getBarycenter(node.id);
        return Object.assign(Object.assign({}, acc), { [layer]: Math.max(acc[layer] || 0, (_a = axis(node.width, node.height)) !== null && _a !== void 0 ? _a : 0) });
    }, {});
    const axisOffsetByLayer = Object.keys(maxLenByLayer)
        .map(Number)
        .sort(sortByNumber)
        .reduce((acc, layer, index) => {
        const keySlice = Object.keys(maxLenByLayer)
            .map(Number)
            .sort(sortByNumber)
            .slice(0, index);
        const offset = keySlice.reduce((acc, key) => acc + maxLenByLayer[key], 0) +
            index * axis(PADDING);
        return Object.assign(Object.assign({}, acc), { [layer]: offset });
    }, {});
    const maxOLenByRow = nodes.reduce((acc, node) => {
        var _a, _b;
        const [_, row] = getBarycenter(node.id);
        return Object.assign(Object.assign({}, acc), { [row]: Math.max((_a = acc[row]) !== null && _a !== void 0 ? _a : 0, (_b = axis.o(node.width, node.height)) !== null && _b !== void 0 ? _b : 0) });
    }, {});
    const oPosByRow = Object.keys(maxOLenByRow)
        .map(Number)
        .sort(sortByNumber)
        .reduce((acc, row, index) => {
        const offset = Object.keys(maxOLenByRow)
            .map(Number)
            .sort(sortByNumber)
            .slice(0, index)
            .reduce((acc, key) => acc + maxOLenByRow[key], 0) +
            index * axis.o(PADDING);
        return Object.assign(Object.assign({}, acc), { [row]: offset });
    }, {});
    const repositioned = nodes.map(node => {
        var _a, _b;
        const [layer, row] = getBarycenter(node.id);
        const maxLen = maxLenByLayer[layer];
        const maxOLen = maxOLenByRow[row];
        const axisPos = (_a = axisOffsetByLayer[layer]) !== null && _a !== void 0 ? _a : 0;
        const nodeLen = axis(node.width, node.height);
        const axisOffset = maxLen && nodeLen && nodeLen < maxLen ? (maxLen - nodeLen) / 2 : 0;
        const oPos = (_b = oPosByRow[row]) !== null && _b !== void 0 ? _b : 0;
        const oNodeLen = axis.o(node.width, node.height);
        const oAxisOffsetOffset = maxOLen && oNodeLen && oNodeLen < maxOLen ? (maxOLen - oNodeLen) / 2 : 0;
        const position = axis.obj(axisPos + axisOffset, oPos + oAxisOffsetOffset);
        return Object.assign(Object.assign({}, node), { data: Object.assign({}, node.data), position, positionAbsolute: position });
    });
    return repositioned;
    function sortByNumber(a, b) {
        return Number(a) - Number(b);
    }
    function getBarycenter(nodeId) {
        return barycenters[nodeId] || [0, 0];
    }
}
