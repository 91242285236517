import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const EMPTY_CONCEPT_LIST_STATE = {
    customConceptListsByCorpusId: {},
};
const _atoms = {
    customConceptListsByCorpusId: atomWithReset(EMPTY_CONCEPT_LIST_STATE.customConceptListsByCorpusId),
};
export const CONCEPT_LIST_STATE_KEYS = Object.keys(_atoms);
export const a_conceptListState = atom(get => {
    return {
        customConceptListsByCorpusId: get(_atoms.customConceptListsByCorpusId),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_conceptListState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useConceptListState() {
    return useResettableState(a_conceptListState);
}
