import { jsx as _jsx } from "react/jsx-runtime";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useDownloadTrends } from "../modals/useDownloadTrends";
import { Trends } from "./Trends";
import { useTrendsData } from "./useTrendsData";
export function TrendsDataChart({ disabled, setSelectedEvidenceType, emptyEvidence, }) {
    const { trendData, noSelection } = useTrendsData(disabled);
    const supportsTrendsExport = useIsFeatureSupported(Feature.TRENDS_EXPORT);
    const { trends = null, trendsGranularities = [], params, trendsGroupByFields = [], } = trendData.value || {};
    const DownloadTrendsDialog = useDownloadTrends(trendsGroupByFields);
    return (_jsx(Trends, { trends: trends, loading: trendData.loading, noSelection: noSelection, trendsGranularities: trendsGranularities, setSelectedEvidenceType: setSelectedEvidenceType, extraControls: supportsTrendsExport && DownloadTrendsDialog(params), emptyEvidence: emptyEvidence }));
}
