"use strict";
const colors = require("../colors");
module.exports = {
    primary: colors.cora_blue[500],
    primary_light: colors.cora_blue[400],
    primary_heavy: colors.cora_blue[600],
    secondary: colors.cora_blue[200],
    subtle: colors.cora_blue[100],
    text_primary: colors.black,
    text_secondary: colors.neutral[600],
    text_hint: colors.neutral[300],
    text_disabled: colors.neutral[600],
    text_highlight: colors.cora_blue[500],
    text_inverse: colors.white,
    text_header: colors.black,
    trends_color: colors.cora_blue[900],
    border_primary: colors.neutral[300],
    border_secondary: colors.neutral[100],
    border_heavy: colors.neutral[600],
    disabled_primary: colors.neutral[500],
    disabled_secondary: colors.neutral[200],
    background: colors.white,
    background_secondary: colors.white,
    background_inverse: colors.neutral[800],
    background_header: colors.white,
    input_header: colors.neutral[300],
};
