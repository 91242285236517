import { jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "antd";
import { useAtom } from "jotai";
import { a_debugMode } from "./a_debugMode";
export function DebugModeToggle() {
    const [debugMode, setDebugMode] = useAtom(a_debugMode);
    return (_jsxs(Button, Object.assign({ onClick: () => {
            setDebugMode(!debugMode);
        } }, { children: [!debugMode ? "Enable" : "Disable", " Debug Mode"] }), "debugMode"));
}
