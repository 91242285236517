import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { Loading } from "../../../../components/common/transitions/Loading";
export function Auth0DefaultLoginRoute() {
    const loc = useLocation();
    const { loginWithRedirect } = useAuth0();
    React.useEffect(() => {
        const parsed = queryString.parse(loc.search);
        loginWithRedirect({
            authorizationParams: {
                invitation: typeof parsed.invitation == "string" ? parsed.invitation : undefined,
                organization: typeof parsed.organization == "string"
                    ? parsed.organization
                    : undefined,
            },
        }).catch(console.error);
    }, [loc.search]);
    return _jsx(Loading, { loading: true });
}
