var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dropdown, Input } from "antd";
import React from "react";
import { useCurrentContextTitle } from "../../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { addEvidenceToFinding, countEvidenceInFindings, existsInFindings, removeEvidenceFromFinding, upsertFinding, useCreateNewFinding, useValidateFindingTitle, } from "../../../appState/context/findings/findings";
import { BorderlessButton } from "../../common/buttons/BorderlessButton";
import { ValidationIcon } from "../../common/forms/InputValidation";
import { Check } from "../../common/icons/Icons";
import { Tooltip } from "../../common/popups/Tooltip";
import { showNotification } from "../../common/status/notifications";
import "./FindingsDropdown.less";
export function FindingsDropdown({ renderDropdownAnchor, evidenceIdList, disabled, disabledDropdown, trigger = "click", autoFindingName, findingText, }) {
    const [newFindingTitle, setNewFindingTitle] = React.useState("");
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [inputRef, setInputRef] = React.useState(null);
    const [blockAutoFocus, setBlockAutoFocus] = React.useState(false);
    const [{ findings }, sessionUpdater] = useCurrentSession();
    const { getEvidenceList } = useEvidenceSearchUtils();
    const evidence = getEvidenceList(evidenceIdList);
    const currentContextTitle = useCurrentContextTitle();
    const hasNoEvidence = evidence.length === 0;
    const hasExistingFinding = evidence.some(e => existsInFindings(findings, e));
    const createNewFinding = useCreateNewFinding();
    const titleError = useValidateFindingTitle(findings, newFindingTitle);
    function createFinding(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const finding = yield createNewFinding({
                selectedText: findingText || evidence[0].text,
                context: evidence[0],
                contextTitle: currentContextTitle,
                evidence,
                title: name,
            });
            sessionUpdater(({ findings }) => ({
                findings: upsertFinding(findings, finding),
                showMyFindings: true,
            }));
            setNewFindingTitle("");
            showNotification(`Finding created: ${name}`);
            setMenuOpen(false);
        });
    }
    // autofocus wasn't consistently working, so we do it manually
    const inputFocus = React.useCallback((ref) => {
        if (menuOpen && ref) {
            if (document.activeElement !== ref.input && !blockAutoFocus) {
                setTimeout(() => {
                    ref.focus({ cursor: "start" });
                    setInputRef(ref);
                    setBlockAutoFocus(true);
                }, 0); // timeout hack for component shenanigans
            }
        }
    }, [menuOpen, blockAutoFocus]);
    React.useEffect(() => {
        if (!menuOpen) {
            setBlockAutoFocus(false);
        }
    }, [menuOpen]);
    const items = [
        {
            label: (_jsxs("div", Object.assign({ className: "flex flex-col", onClick: e => {
                    e.preventDefault();
                    e.stopPropagation();
                } }, { children: [_jsx("span", { children: `Add ${evidence.length} to new finding` }), _jsxs("div", Object.assign({ className: "flex space-x-1" }, { children: [_jsx(Input, { ref: inputFocus, allowClear: true, onKeyDown: e => {
                                    if (e.key === "Enter") {
                                        if (titleError)
                                            return;
                                        createFinding(newFindingTitle);
                                    }
                                    else if (e.key === "Escape") {
                                        setMenuOpen(false);
                                    }
                                }, value: newFindingTitle, onChange: e => {
                                    setNewFindingTitle(e.currentTarget.value);
                                }, className: "border-2 border-gray-400 rounded-md px-1 text-sm", placeholder: "Finding name", suffix: _jsx(ValidationIcon, { error: titleError }) }), _jsx(BorderlessButton, Object.assign({ className: "hover:bg-background_secondary", onClick: () => createFinding(newFindingTitle), disabled: newFindingTitle.length === 0 || !!titleError }, { children: _jsx(Check, { className: "px-1 text-base text-sm" }) }))] }))] }))),
            onClick: () => {
                if (inputRef) {
                    inputRef.focus({ cursor: "start" });
                }
            },
            key: "createNewFinding",
            disabled: hasNoEvidence,
        },
        {
            label: `Add ${evidence.length} to finding`,
            key: "addToFinding",
            children: !menuOpen
                ? []
                : findings.map(finding => {
                    var _a;
                    return ({
                        label: finding.title || ((_a = finding.context) === null || _a === void 0 ? void 0 : _a.contextTitle),
                        key: `addTo_${finding.id}`,
                        onClick: () => {
                            const updatedFinding = upsertFinding(findings, addEvidenceToFinding(finding, evidence));
                            sessionUpdater({
                                findings: updatedFinding,
                                showMyFindings: true,
                            });
                            const evidenceAlreadyInFinding = countEvidenceInFindings([finding], evidence);
                            const evidenceAdded = evidence.length - evidenceAlreadyInFinding;
                            if (evidenceAdded > 0) {
                                const countStatement = evidenceAlreadyInFinding > 0
                                    ? `${evidenceAdded} new evidence out of ${evidence.length} selected`
                                    : `${evidence.length} evidence`;
                                showNotification({
                                    message: `Added ${countStatement} to finding: ${finding.title || finding.context.contextTitle}`,
                                    duration: 5,
                                });
                            }
                            else {
                                showNotification({
                                    message: `Selected evidence already included in finding: ${finding.title || finding.context.contextTitle}`,
                                    duration: 5,
                                });
                            }
                            setMenuOpen(false);
                        },
                    });
                }),
            disabled: hasNoEvidence || findings.length === 0,
        },
        {
            label: `Remove ${evidence.length} from finding`,
            key: "removeFromFinding",
            children: !menuOpen
                ? []
                : findings
                    .filter(finding => finding.payload.evidence
                    .map(e => e.id)
                    .some(eId => evidence.map(e => e.id).includes(eId)))
                    .map(finding => ({
                    label: finding.title || finding.context.contextTitle,
                    key: `removeFrom_${finding.id}`,
                    onClick: () => {
                        const updatedFinding = upsertFinding(findings, removeEvidenceFromFinding(finding, evidence));
                        sessionUpdater({ findings: updatedFinding });
                        const evidenceInFinding = countEvidenceInFindings([finding], evidence);
                        const evidenceNotThere = evidence.length - evidenceInFinding;
                        const countStatement = evidenceNotThere > 0
                            ? `${evidenceInFinding} found out of ${evidence.length} selected`
                            : `${evidenceInFinding}`;
                        showNotification({
                            message: `Removed ${countStatement} evidence from finding: ${finding.title || finding.context.contextTitle}`,
                            duration: 5,
                        });
                        setMenuOpen(false);
                    },
                })),
            disabled: hasNoEvidence || !hasExistingFinding,
        },
        {
            label: `Remove ${evidence.length} from all findings`,
            key: "removeFromAllFindings",
            onClick: () => {
                const updatedFindings = findings.map(f => removeEvidenceFromFinding(f, evidence));
                sessionUpdater({ findings: updatedFindings });
                const evidenceInFindings = countEvidenceInFindings(findings, evidence);
                showNotification({
                    message: `Removed ${evidenceInFindings} evidence found across all findings`,
                    duration: 5,
                });
                setMenuOpen(false);
            },
            disabled: hasNoEvidence || !hasExistingFinding,
        },
        /* TODO: {
          label: hasMultiEvidence ? "Show all in findings" : "Show in findings",
          key: "5",
          disabled: hasNoEvidence || !hasExistingFinding,
        },*/
    ];
    if (autoFindingName) {
        items.unshift({
            label: `Use as finding`,
            key: "useAsFinding",
            onClick: () => createFinding(autoFindingName),
            disabled: hasNoEvidence,
        });
    }
    return (_jsx(Dropdown, Object.assign({ trigger: [trigger], menu: { items }, disabled: disabledDropdown, onOpenChange: open => {
            setMenuOpen(open);
            if (!open) {
                setNewFindingTitle("");
            }
        }, open: menuOpen }, { children: _jsx(Tooltip, Object.assign({ content: "Add/remove findings" }, { children: _jsx(_Fragment, { children: renderDropdownAnchor(() => setMenuOpen(true), hasExistingFinding) }) })) })));
}
