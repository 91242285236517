import React from "react";
import { useLocation } from "react-router-dom";
export function useCurrentUrl() {
    const { search, pathname } = useLocation();
    const params = React.useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const params = {};
        for (const p of searchParams.entries()) {
            params[p[0]] = p[1];
        }
        return params;
    }, [search]);
    return { params, pathname };
}
