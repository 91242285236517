var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { atom, useAtom, useSetAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { groupBy } from "lodash";
import { API } from "../api/api";
import { getContextCorpusFilter } from "../appState/context/findings/findings";
// reset with session
export const a_genius = atomWithReset({
    questionGroups: {},
    questionCount: 0,
});
const a_geniusUpdate = atom(null, (get, set, suggestedQuestions) => {
    set(a_genius, {
        questionGroups: groupBy(suggestedQuestions, "trigger_concept"),
        questionCount: suggestedQuestions.length,
    });
});
const a_suggestQuestions = atom(null, (get, set, { context, payload }, history, disabled = false) => __awaiter(void 0, void 0, void 0, function* () {
    const corpus_filter = yield getContextCorpusFilter(context);
    if (!corpus_filter || disabled) {
        set(a_geniusUpdate, []);
    }
    else {
        const suggestedQuestions = yield API.suggestedQuestionsEvidence({
            corpus_filter,
            evidence: payload.evidence,
            history,
        });
        set(a_geniusUpdate, suggestedQuestions);
    }
}));
const a_suggestQuestionsForConcepts = atom(null, (get, set, { corpus_filter, history, concept_clusters, disabled, }) => __awaiter(void 0, void 0, void 0, function* () {
    if (disabled || concept_clusters.length <= 0) {
        set(a_geniusUpdate, []);
        return;
    }
    else {
        const suggestedQuestions = yield API.suggestQuestionsForConcepts({
            corpus_ids: corpus_filter.corpus_ids,
            history,
            concept_clusters,
        });
        set(a_geniusUpdate, suggestedQuestions);
    }
}));
export function useSuggestedQuestionsState() {
    const suggestedQuestions = useAtom(a_genius)[0];
    const setSuggestedQuestions = useSetAtom(a_geniusUpdate);
    const suggestQuestions = useSetAtom(a_suggestQuestions);
    const suggestQuestionsForConcepts = useSetAtom(a_suggestQuestionsForConcepts);
    return {
        suggestedQuestions,
        setSuggestedQuestions,
        suggestQuestions,
        suggestQuestionsForConcepts,
    };
}
