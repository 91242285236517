import { jsx as _jsx } from "react/jsx-runtime";
import { Popover } from "antd";
import React from "react";
import { addWindowEventHandlers } from "../../../utils/events/addWindowEventHandlers";
import { useOnGlobalEscape } from "../../../utils/interactions/utils/interactionEvents";
import "./Popup.less";
export function usePopup() {
    const [config, showPopup] = React.useState(false);
    const ref = React.useRef(null);
    useOnGlobalEscape(Boolean(config && config.autoHide), () => showPopup(false));
    React.useEffect(() => {
        function hidePopup(e) {
            let p = e.target;
            while (p && p !== ref.current) {
                p = p.parentElement;
            }
            if (p !== ref.current) {
                showPopup(false);
            }
        }
        if (config && config.autoHide) {
            const mousedown = { mousedown: hidePopup };
            const wheel = { wheel: hidePopup };
            return addWindowEventHandlers(config.hideOnWheel ? Object.assign(Object.assign({}, wheel), mousedown) : mousedown);
        }
    }, [config]);
    React.useEffect(() => {
        if (config && config.onShow) {
            return config.onShow();
        }
    }, [config]);
    function Popup() {
        return !config ? null : (_jsx(Popover, Object.assign({ overlayClassName: "Popup", overlayStyle: {
                zIndex: config.zIndex || 1000,
            }, open: true, trigger: "click", content: _jsx("div", Object.assign({ ref: ref }, { children: config.content })), destroyTooltipOnHide: false, autoAdjustOverflow: true, overlayInnerStyle: {
                backgroundColor: "bg-alert_green-500",
                borderRadius: 8,
                padding: 0,
            } }, { children: _jsx("div", { style: {
                    position: "absolute",
                    left: config.left,
                    top: config.top,
                    zIndex: config.zIndex || 1000,
                } }) })));
    }
    return [Popup, showPopup];
}
