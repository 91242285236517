import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useAtomValue } from "jotai";
import { findLastIndex } from "lodash";
import React from "react";
import { EvidenceType } from "../../api/types/evidenceTypes";
import { a_constraintModelConfig } from "../../appState/atomic/queryState/constraintModel/modelState";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { Empty } from "../common/layout/Empty";
import { TrendsChart } from "./TrendsChart";
import { DIFFERENCE_LINE_NAME } from "./consts/DIFFERENCE_LINE_NAME";
import { useSelectedTrendRangeUpdater } from "./useSelectedTrendRangeUpdater";
import { useTrendDomain } from "./useTrendDomain";
function findMatchingPeriod(buckets, label) {
    var _a;
    return (_a = buckets.find((x) => x.name === label)) === null || _a === void 0 ? void 0 : _a.interval;
}
function dateFromPeriod(period) {
    return (period === null || period === void 0 ? void 0 : period.start) ? new Date(period === null || period === void 0 ? void 0 : period.start) : undefined;
}
function getSelectedDateRange(dateRange, selectedTrendRange) {
    const dateFilter = dateRange.filter(metadata => metadata.id === (selectedTrendRange === null || selectedTrendRange === void 0 ? void 0 : selectedTrendRange.id));
    return dateFilter.length > 0 ? dateFilter[0].value : undefined;
}
export function Trends({ trends, loading, noSelection, trendsGranularities, setSelectedEvidenceType, extraControls, emptyEvidence, }) {
    const { refuting } = useAtomValue(queryStateAtoms.searchParams);
    const modelConfig = useAtomValue(a_constraintModelConfig);
    const { rangeMetadata } = modelConfig;
    const rangeUpdater = useSelectedTrendRangeUpdater();
    const selectedTrendDomain = useTrendDomain();
    const [{ trendRangeType = "count" }] = useCurrentSession();
    // Get the currently selected date range for the trend domain.
    const selectedDateRange = React.useMemo(() => {
        const range = getSelectedDateRange(rangeMetadata, selectedTrendDomain);
        return range || {};
    }, [rangeMetadata, selectedTrendDomain]);
    function handleDateChange(labels) {
        if (!trends)
            return;
        let range = undefined;
        if (labels.length === 0) {
            range = undefined;
        }
        else if (labels.length === 1) {
            const period = findMatchingPeriod(trends.domain_buckets, labels[0]);
            if (period)
                range = period;
        }
        else if (labels.length > 1) {
            const firstPeriod = findMatchingPeriod(trends.domain_buckets, labels[0]);
            const firstStart = dateFromPeriod(firstPeriod);
            const secondPeriod = findMatchingPeriod(trends.domain_buckets, labels[1]);
            const secondStart = dateFromPeriod(secondPeriod);
            if (!firstStart || !secondStart)
                return;
            if (firstPeriod === secondPeriod) {
                range = firstPeriod;
            }
            else {
                const start = (firstStart === null || firstStart === void 0 ? void 0 : firstStart.getTime()) < (secondStart === null || secondStart === void 0 ? void 0 : secondStart.getTime())
                    ? firstPeriod === null || firstPeriod === void 0 ? void 0 : firstPeriod.start
                    : secondPeriod === null || secondPeriod === void 0 ? void 0 : secondPeriod.start;
                const end = (firstStart === null || firstStart === void 0 ? void 0 : firstStart.getTime()) < (secondStart === null || secondStart === void 0 ? void 0 : secondStart.getTime())
                    ? secondPeriod === null || secondPeriod === void 0 ? void 0 : secondPeriod.end
                    : firstPeriod === null || firstPeriod === void 0 ? void 0 : firstPeriod.end;
                if (!start || !end)
                    return;
                range = {
                    start,
                    end,
                };
            }
        }
        rangeUpdater(range);
    }
    const chartData = React.useMemo(() => {
        const trendsData = {};
        const periods = trends === null || trends === void 0 ? void 0 : trends.domain_buckets;
        if (!periods)
            return [];
        const unmentionedSeries = trends.series.find(x => x.type === EvidenceType.TOTAL);
        const isCount = trendRangeType === "count";
        trends === null || trends === void 0 ? void 0 : trends.series.filter(s => s.type !== EvidenceType.TOTAL).forEach((series) => {
            series.counts.forEach((value, idx) => {
                const bucket = periods[idx];
                const newVal = series.name.includes("Refuting") ? -value : value;
                const period = bucket.interval;
                const item = (isCount
                    ? {
                        period,
                        value: newVal,
                        lineName: series.name,
                    }
                    : {
                        period,
                        value: newVal / ((unmentionedSeries === null || unmentionedSeries === void 0 ? void 0 : unmentionedSeries.counts[idx]) || 1),
                        lineName: `${series.name.replace(" count", " ratio")}`,
                    });
                trendsData[bucket.name] = trendsData[bucket.name] || [];
                trendsData[bucket.name].push(item);
            });
        });
        const getHasData = (items) => {
            return Boolean(items[0].value || items[1].value);
        };
        const getDifference = (items) => {
            if (items.length >= 2) {
                return items[0].value + items[1].value;
            }
            return 0;
        };
        const values = Object.keys(trendsData).map((key, idx) => {
            const items = trendsData[key];
            const value = getDifference(items);
            const hasData = getHasData(items);
            return {
                key,
                value,
                hasData,
            };
        });
        const indexOfLastKeyWithData = findLastIndex(values, v => v.hasData);
        indexOfLastKeyWithData > 0 &&
            values.slice(0, indexOfLastKeyWithData + 1).forEach(({ key, value }) => {
                const items = trendsData[key];
                items.push({
                    period: items[0].period,
                    value,
                    lineName: DIFFERENCE_LINE_NAME,
                });
            });
        const formattedChartData = Object.keys(trendsData).map((key) => {
            const entry = { label: key };
            trendsData[key].forEach(x => {
                if (x.lineName)
                    entry[x.lineName] = x.value;
            });
            return entry;
        });
        return formattedChartData;
    }, [trends, trendRangeType]);
    function getEmpty(noSelection, trends, loading) {
        return noSelection ? ("No Query") : !trends ? (loading ? ("Loading trends") : !emptyEvidence ? (_jsxs("span", { children: ["No accurate trends for this query.", _jsx("span", Object.assign({ className: "align-super text-sm", style: { verticalAlign: "super" } }, { children: _jsx(InfoCircleOutlined, {}) }))] })) : ("No results for current query.")) : null;
    }
    return (_jsx("div", Object.assign({ className: "w-full h-full" }, { children: _jsx(Empty, Object.assign({ empty: getEmpty(noSelection, trends, loading), loading: loading, emptyTooltip: _jsxs("span", { children: ["When showing evidence, Cora shows highly confident and relevant results first, followed by less confident results. In the Trends, however, Cora only displays trend lines for the highly relevant results.", _jsx("br", {}), "In this case, there were no highly relevant results found, and so no Trends are computed."] }) }, { children: trends && !noSelection && (_jsx(TrendsChart, { data: chartData, title: trends.name, selectedDateRange: selectedDateRange, onSelectedDateChange: handleDateChange, trendsGranularities: trendsGranularities, setSelectedEvidenceType: setSelectedEvidenceType, extraControls: extraControls, getColorIdx: idx => (refuting ? 1 - idx : idx) })) })) })));
}
