import { isMacOS } from "get-os-name";
export const bindingTypes = new Set(["keypress", "keydown", "keyup"]);
const mac = isMacOS();
const macKeys = {
    MOD: "⌘",
    SHIFT: "⇧",
    ALT: "⌥",
    CTRL: "⌃",
    BACKSPACE: "⌫",
};
const replacements = {
    PLUS: "+",
};
function getShortcutParts(shortcut = "") {
    return shortcut
        .toUpperCase()
        .split("+")
        .filter(p => !bindingTypes.has(p.toLowerCase()))
        .map(p => replacements[p] || p)
        .map(p => (mac && macKeys[p]) || (p === "MOD" ? "CTRL" : p));
}
export function getShortcutDisplayText(shortcut) {
    if (!shortcut)
        return shortcut;
    return getShortcutParts(shortcut).join(" ");
}
