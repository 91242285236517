import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useAuthState } from "../useAuthState";
export function Auth0StateUpdater() {
    const { setUserInfo, setUserProfile, setIsLoading, setIsAuthenticated, setLoginWithRedirect, } = useAuthState();
    const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
    React.useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);
    React.useEffect(() => {
        setIsAuthenticated(isAuthenticated);
    }, [isAuthenticated]);
    React.useEffect(() => {
        setLoginWithRedirect(() => {
            //setting function to atom directly runs the function as a SetStateAction
            return (olpConfig) => {
                return loginWithRedirect({
                    authorizationParams: {
                        organization: olpConfig.org_id,
                        connection: olpConfig.connection,
                    },
                });
            };
        });
    }, [loginWithRedirect, setLoginWithRedirect]);
    React.useEffect(() => {
        if (user) {
            setUserInfo({
                userId: user.preferred_username,
                userName: user.name,
                orgId: user.org_id,
            });
            setUserProfile({
                name: user.name,
                email: user.email,
            });
        }
    }, [user]);
    return null;
}
