import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FilterFilled, QuestionOutlined } from "@ant-design/icons";
import { useAtomValue } from "jotai";
import React from "react";
import { useCurrentSource } from "../../api/useCurrentSource";
import { getCurrentUserInfo } from "../../appState/atomic/auth/utils/authInfo";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { a_debugMode } from "../../appState/atomic/utils/a_debugMode";
import { ModalType, useModalState } from "../../appState/beacon/useModalState";
import { classes } from "../../appState/context/theme/classes";
import { ListToggle } from "../common/buttons/ListToggle";
import { ToggleButton } from "../common/buttons/ToggleButton";
import { Findings, Graph, HistoryFull, ManageLists, SettingsGear, } from "../common/icons/Icons";
import { Rail } from "../common/layout/Rail";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { PrintFeatureFlags } from "../debug/PrintFeatureFlags";
import { PrintSession } from "../debug/PrintSession";
import { PrintState } from "../debug/PrintState";
import { useShowDocument360 } from "../help/Document360";
import { showCustomListsManager } from "../modals/CustomListsManager/CustomListsManager";
import { showCombinedHistory } from "../modals/history/CombinedHistory";
import { useConfigDialog } from "../modals/useConfigDialog";
import "./Toolbar.less";
function Label({ children }) {
    return _jsx("span", Object.assign({ className: "ml-1" }, { children: children }));
}
export function Toolbar() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const currentSource = useCurrentSource();
    const { enableFeedbackWidget, enableHelpCenterWidget } = useFeatureFlags();
    const supportsCustomLists = useIsFeatureSupported(Feature.CUSTOM_CONCEPT_LISTS);
    const [{ showFilters, showMyFindings, modelBuilderAvailable, modelBuilderMode, alternativeEvidenceLayout, }, sessionUpdater,] = useCurrentSession();
    const modelBuilderActive = modelBuilderAvailable && modelBuilderMode;
    const showDocument360 = useShowDocument360();
    const wideButtonClass = "flex items-center justify-start pl-2";
    const isNarrow = true;
    const { showModal, modalContainer } = useConfigDialog();
    const [modalState] = useModalState();
    const debugMode = useAtomValue(a_debugMode);
    const topControls = (_jsxs("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: [!modelBuilderAvailable ? (_jsxs(ToggleButton, Object.assign({ onClick: () => {
                    sessionUpdater({
                        showFilters: !showFilters,
                        modelBuilderMode: false,
                    });
                }, selected: !modelBuilderActive && showFilters, tooltip: "Show Filters", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selectedClass: "bg-background_secondary" }, { children: [_jsx(FilterFilled, {}), !isNarrow && _jsx(Label, { children: "Filters" })] }))) : (_jsx(ListToggle, { buttonWidth: !isNarrow ? 80 : undefined, buttonClassName: classes(!isNarrow ? wideButtonClass : "flex items-center justify-center", "rounded-md"), style: { padding: "1px" }, activeToggle: modelBuilderActive ? "graph" : showFilters ? "facets" : undefined, toggleEntries: [
                    {
                        key: "facets",
                        icon: (_jsxs(_Fragment, { children: [_jsx(FilterFilled, {}), !isNarrow && _jsx(Label, { children: "Filters" })] })),
                        tooltip: "Show Filters",
                        onClick: () => {
                            sessionUpdater({
                                showFilters: !showFilters,
                                modelBuilderMode: showFilters,
                            });
                        },
                    },
                    {
                        key: "graph",
                        icon: (_jsxs(_Fragment, { children: [_jsx(Graph, {}), !isNarrow && _jsx(Label, { children: "Graph" })] })),
                        tooltip: "Show Graph",
                        onClick: () => {
                            sessionUpdater({
                                showFilters: !showFilters,
                                modelBuilderMode: !modelBuilderMode,
                            });
                        },
                    },
                ] })), _jsxs("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: [!alternativeEvidenceLayout && (_jsxs(ToggleButton, Object.assign({ onClick: () => sessionUpdater({ showMyFindings: !showMyFindings }), tooltip: "Show My Findings", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selected: showMyFindings, selectedClass: "bg-background_secondary" }, { children: [_jsx(Findings, {}), !isNarrow && _jsx(Label, { children: "History" })] }))), _jsxs(ToggleButton, Object.assign({ onClick: () => showCombinedHistory(), tooltip: "Query History", width: !isNarrow ? 80 : undefined, selected: modalState[ModalType.HISTORY], selectedClass: "bg-background_secondary", className: !isNarrow ? wideButtonClass : undefined }, { children: [_jsx(HistoryFull, {}), !isNarrow && _jsx(Label, { children: "History" })] })), supportsCustomLists && (_jsxs(ToggleButton, Object.assign({ onClick: () => showCustomListsManager(corpus_ids), tooltip: "List Manager", disabled: !currentSource, selected: modalState[ModalType.LIST_MANAGER], selectedClass: "bg-background_secondary", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined }, { children: [_jsx(ManageLists, {}), !isNarrow && _jsx(Label, { children: "Lists" })] })))] })), _jsxs(ToggleButton, Object.assign({ onClick: e => showModal(e.altKey), tooltip: "System Configuration", disabled: !currentSource, selected: modalState[ModalType.CONFIG], selectedClass: "bg-background_secondary", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined }, { children: [_jsx(SettingsGear, { className: "SettingsGear" }), !isNarrow && _jsx(Label, { children: "Config" })] })), modalContainer] })));
    const bottomControls = (enableHelpCenterWidget || enableFeedbackWidget) && (_jsxs("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: [_jsx(DropDownMenu, Object.assign({ placement: "right", entries: [
                    enableHelpCenterWidget
                        ? {
                            caption: "Help Center",
                            action() {
                                setTimeout(showDocument360, 100);
                            },
                        }
                        : undefined,
                    enableFeedbackWidget
                        ? {
                            caption: "Submit Feedback",
                            action() {
                                window.open(`https://elementalcognition.zendesk.com/hc/en-us/requests/new?tf_17381088022935=cora_app&tf_anonymous_requester_email=${getCurrentUserInfo().userId}`, "_blank");
                            },
                        }
                        : undefined,
                ].filter(Boolean) }, { children: _jsxs(ToggleButton, Object.assign({ tooltip: "Help", width: !isNarrow ? 80 : undefined }, { children: [_jsx(QuestionOutlined, {}), !isNarrow && _jsx(Label, { children: "Help" })] })) })), debugMode && _jsx(PrintSession, {}), debugMode && _jsx(PrintState, {}), debugMode && _jsx(PrintFeatureFlags, {})] })));
    return (_jsx(Rail, { style: !isNarrow
            ? {
                width: "96px",
                minWidth: "96px",
                flex: "0 0 0",
            }
            : undefined, narrow: isNarrow, topControls: topControls, bottomControls: bottomControls }));
}
