import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { take } from "lodash";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { terminateEvent } from "../../../utils/interactions/utils/interactionEvents";
import { Clear } from "../../common/icons/Icons";
import { Tooltip } from "../../common/popups/Tooltip";
import { ElementType } from "../model";
import { DropDownEntityPicker } from "./DropDownEntityPicker";
import { getEntityPicker } from "./EntityPicker";
import { Expander } from "./Expander";
const OVERFLOW_WRAP = { overflowWrap: "anywhere" };
const MAX_POSSIBLE_DISPLAY = 10;
export function EntityView(props) {
    var _a;
    const { type, expanded, label, content, selection, possible, argName, updateContent, expandable = true, updateExpanded, } = props;
    const onClearContent = React.useCallback((e) => {
        terminateEvent(e);
        updateContent(null);
    }, []);
    const isRelation = type === ElementType.RELATION;
    const isConcept = type === ElementType.CONCEPT;
    const selected = isConcept
        ? selection
        : isRelation
            ? expanded
                ? content
                : []
            : content;
    const possibleBatchContent = isConcept && (_jsx("div", Object.assign({ className: classes("Batch font-bold ", !possible && "invisible") }, { children: (_a = possible === null || possible === void 0 ? void 0 : possible.length) !== null && _a !== void 0 ? _a : 99 })));
    const possibleBatch = possibleBatchContent && (_jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "text-tiny" }, { children: [_jsx("div", Object.assign({ className: "font-bold" }, { children: "Top solutions:" })), !(possible === null || possible === void 0 ? void 0 : possible.length) ? (_jsx("div", { children: "Nothing was found." })) : (take(possible.map((c, idx) => _jsx("div", { children: c.name }, idx)), MAX_POSSIBLE_DISPLAY)), possible && possible.length > MAX_POSSIBLE_DISPLAY && _jsx("div", { children: "..." })] })) }, { children: possibleBatchContent })));
    return (_jsxs("div", Object.assign({ className: "node-header flex-1 flex flex-col overflow-hidden" }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-1 items-center px-[2px]" }, { children: [!isRelation && (_jsx(Expander, { onChange: updateExpanded, expanded: expanded })), _jsx("div", Object.assign({ className: "node-icon font-bold" }, { children: isConcept ? "C" : isRelation ? "R" : argName || "" })), _jsx("div", Object.assign({ className: "node-label flex-1", style: OVERFLOW_WRAP }, { children: content.length < 2 ? (label) : (_jsx(Tooltip, Object.assign({ content: _jsx("div", { children: content.map((c, idx) => (_jsx("div", { children: c.name }, idx))) }) }, { children: _jsx("div", { children: label }) }))) })), !!content.length && (_jsx(Clear, { className: "node-clear cursor-pointer", onMouseDownCapture: onClearContent })), possibleBatch, _jsx(DropDownEntityPicker, { props: props, updateContent: updateContent }), " "] })), getEntityPicker(props, expanded, selected, updateContent)] })));
}
