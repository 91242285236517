import { FeatureIdentifier, } from "../../../../api/types/apiTypes";
import { CorpusCapability } from "../../../../api/utils/capabilities";
import { getSelectionOption } from "./getSelectionOption";
export function getRelationItemRenderer(params) {
    const renderWithoutFeedback = (selected, updateSelection, feedback, setFeedback) => (item) => getSelectionOption({
        item,
        nameGetter: i => i.name,
        identifier: FeatureIdentifier.SUGGEST_RELATIONS,
        capability: CorpusCapability.SEARCH_RELATIONS_REFUTING,
        params,
        selected,
        updateSelection,
        feedback,
        setFeedback,
    });
    return renderWithoutFeedback;
}
