var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { uuidv4 } from "../../../../utils/identity/uuidv4";
import { a_debugLog } from "../../utils/a_debugMode";
import { queryStateAtoms } from "../queryStateAtoms";
import { findFirstConstraint } from "./constraintOrdering";
import { a_activeConstraintId, a_constraintModelState } from "./modelState";
///////////////////////////////////////
// do not export or all hope is lost //
///////////////////////////////////////
function createConstraintState(id = uuidv4()) {
    return {
        id,
        sourceNodeId: uuidv4(),
        targetNodeId: uuidv4(),
        contextNodeIds: [],
        relationNodeId: uuidv4(),
        qualifierNodeIds: {},
        is_directed: false,
    };
}
const a_ghostConstraint = atom(createConstraintState());
const a_refreshGhostConstraint = atom(null, (get, set) => {
    const newGhostConstraint = createConstraintState();
    set(a_ghostConstraint, newGhostConstraint);
    set(a_debugLog, "refresh ghost constraint", newGhostConstraint.id);
});
function getExistingActiveConstraint(activeConstraintId, constraints) {
    let constraint = activeConstraintId
        ? constraints[activeConstraintId] || null
        : null;
    if (!constraint) {
        constraint = findFirstConstraint(Object.values(constraints));
    }
    return constraint;
}
export const a_activeConstraint = atom(get => {
    const { state: { constraints }, activeConstraintId, } = get(queryStateAtoms.constraintModel); //need to depend on the model
    let constraint = getExistingActiveConstraint(activeConstraintId, constraints);
    if (!constraint) {
        constraint = Object.assign(Object.assign({}, get(a_ghostConstraint)), (activeConstraintId ? { id: activeConstraintId } : {}));
    }
    return constraint;
}, (get, set, update) => {
    set(a_debugLog, "active constraint updating", update);
    const { state: { constraints }, activeConstraintId, } = get(queryStateAtoms.constraintModel); //need to depend on the model
    const baseConstraint = udpateFromActive() || updateFromGhost();
    const updateValue = update instanceof Function
        ? update(baseConstraint)
        : Object.assign(Object.assign({}, baseConstraint), update);
    if (activeConstraintId !== updateValue.id) {
        set(a_activeConstraintId, updateValue.id);
    }
    set(a_constraintModelState, state => {
        return Object.assign(Object.assign({}, state), { constraints: Object.assign(Object.assign({}, state.constraints), { [updateValue.id]: updateValue }) });
    });
    function udpateFromActive() {
        const preexistingConstraint = getExistingActiveConstraint(activeConstraintId, constraints);
        if (preexistingConstraint) {
            set(a_debugLog, "use existing constraint for update");
            return preexistingConstraint;
        }
    }
    function updateFromGhost() {
        set(a_debugLog, "use ghost constraint for update");
        const constraint = Object.assign(Object.assign({}, get(a_ghostConstraint)), (activeConstraintId ? { id: activeConstraintId } : {}));
        set(a_refreshGhostConstraint);
        return constraint;
    }
});
export const a_deleteConstraint = atom(null, (get, set, constraintId) => {
    set(a_refreshGhostConstraint);
    const a_constraints = focusAtom(queryStateAtoms.constraintModel, o => o.path("state.constraints"));
    const constraints = get(a_constraints);
    const _a = constraints, _b = constraintId, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    set(a_constraints, rest);
    if (get(a_activeConstraintId) === constraintId) {
        set(a_activeConstraintId, undefined);
    }
});
export const a_constraintText = focusAtom(a_activeConstraint, O => O.path("text").valueOr(""));
export const a_userText = focusAtom(a_activeConstraint, O => O.path("userText").valueOr(""));
export const a_isDirected = focusAtom(a_activeConstraint, O => O.path("is_directed").valueOr(false));
