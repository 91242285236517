import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { FLEX_COL, FLEX_ROW } from "../../../appState/context/theme/styleConst";
import { Tooltip } from "../popups/Tooltip";
export function ListToggle({ toggleEntries, activeToggle, className, style, direction = "col", buttonWidth = 30, buttonHeight = 30, buttonClassName = "justify-center items-center rounded-md", }) {
    const directionClass = direction === "col" ? FLEX_COL : FLEX_ROW;
    const labelClass = direction === "col" ? FLEX_ROW : FLEX_COL;
    const activeClass = "bg-primary hover:bg-primary_light text-white";
    const defaultClass = "bg-neutral-300 hover:bg-neutral-500 text-neutral-800";
    return (_jsx("div", Object.assign({ className: classes(className, directionClass, "items-center bg-neutral-300 rounded-md"), style: style }, { children: toggleEntries.map(entry => {
            const tooltip = entry.tooltip;
            const button = (_jsx("button", Object.assign({ className: classes("select-none", labelClass, buttonClassName, entry.key === activeToggle ? activeClass : defaultClass), style: {
                    width: `${buttonWidth}px`,
                    height: `${buttonHeight}px`,
                }, onClick: entry.onClick }, { children: entry.icon }), entry.key));
            return tooltip ? (_jsx(Tooltip, Object.assign({ content: tooltip }, { children: button }), entry.key)) : (button);
        }) })));
}
