import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { BaseEdge, getSimpleBezierPath } from "reactflow";
import { a_activeConstraintId } from "../../appState/atomic/queryState/constraintModel/modelState";
import { a_userConfig } from "../../appState/atomic/session/parts/userConfig";
import { NEUTRAL_COLOR_VALS } from "../../appState/context/theme/lf";
export default function CyclicEdge(props) {
    const { horizontalGraphLayout } = useAtomValue(a_userConfig);
    const activeConstraintId = useAtomValue(a_activeConstraintId);
    const { sourceX, sourceY, targetX, targetY, data } = props;
    const { constraintId, is_directed } = data;
    const isActive = constraintId === activeConstraintId;
    const markerEnd = is_directed
        ? `url(#${isActive ? "active-marker" : "inactive-marker"})`
        : undefined;
    const style = {
        stroke: isActive ? "black" : NEUTRAL_COLOR_VALS[500],
    };
    const cyclic = horizontalGraphLayout ? targetX < sourceX : targetY < sourceY;
    const [path] = cyclic
        ? getCyclicPath(horizontalGraphLayout, props)
        : getSimpleBezierPath(props);
    return _jsx(BaseEdge, { path: path, markerEnd: markerEnd, style: style });
}
function getCyclicPath(horizontalGraphLayout, { sourceX, sourceY, targetX, targetY, }) {
    const [rX, rY] = horizontalGraphLayout
        ? [(sourceX - targetX) * 0.5, 50]
        : [50, (sourceY - targetY) * 0.5];
    const [sX, sY] = horizontalGraphLayout
        ? [sourceX - 5, sourceY]
        : [sourceX, sourceY - 5];
    const [tX, tY] = horizontalGraphLayout
        ? [targetX + 5, targetY]
        : [targetX, targetY + 5];
    const edgePath = `M ${sX} ${sY} A ${rX} ${rY} 0 1 0 ${tX} ${tY}`;
    return [edgePath];
}
