import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { Bar } from "../../common/layout/Bar";
import { Fill } from "../../common/layout/Fill";
export function Panel({ children, className, header, style, }) {
    return (_jsxs("div", Object.assign({ className: classes(className, "flex flex-1 flex-col justify-center p-2"), style: style }, { children: [_jsx("div", Object.assign({ className: "w-full flex justify-center" }, { children: _jsx("div", Object.assign({ className: "w-full flex flex-col flex-grow" }, { children: header })) })), _jsx(Fill, Object.assign({ flexRow: true }, { children: children }))] })));
}
export function PanelHeader({ title, headerActions, }) {
    return (_jsx(Bar, Object.assign({ className: "pb-2" }, { children: _jsxs("div", Object.assign({ className: classes("w-full flex justify-between items-center") }, { children: [_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx("div", Object.assign({ className: "font-bold mr-2 select-none" }, { children: title })) })), _jsx("div", Object.assign({ className: "flex items-center space-x-4" }, { children: headerActions }))] })) })));
}
