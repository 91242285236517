import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { limitLength } from "../../../utils/text/limitLength";
import { BorderlessButton } from "../../common/buttons/BorderlessButton";
import { ShareFromSquare } from "../../common/icons/Icons";
import { Tooltip } from "../../common/popups/Tooltip";
import { MAX_TITLE_LEN } from "../../evidence/MAX_TITLE_LEN";
import { ActionableBlock } from "./ActionableBlock";
export function EvidenceCardTitle({ prefix, onOpen, title }) {
    return (_jsx(ActionableBlock, Object.assign({ centerActions: true, actions: [
            _jsx(Tooltip, Object.assign({ content: "Open Document" }, { children: _jsx(BorderlessButton, Object.assign({ className: "flex justify-center items-center hover:bg-gray-300 cursor-pointer rounded-full", onClick: () => onOpen === null || onOpen === void 0 ? void 0 : onOpen() }, { children: _jsx(ShareFromSquare, { className: classes("text-base my-auto px-1 py-1") }) })) }), "openDocument"),
        ] }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [prefix, _jsx("div", Object.assign({ className: "flex-1 font-semibold text-ellipsis overflow-hidden text-base item-center" }, { children: _jsx("span", { children: limitLength(title, MAX_TITLE_LEN) }) }))] })) })));
}
