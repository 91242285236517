import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEqual } from "lodash";
import React from "react";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { upsertFinding, useValidateFindingTitle, } from "../../appState/context/findings/findings";
import { scrollToElement } from "../../utils/dom/utils/scrollToElement";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { popModal, pushModal } from "../common/modals/Modal";
import { showNotification } from "../common/status/notifications";
import { FindingEditor } from "../findings/FindingEditor";
import { useSuggestedQuestionsState } from "../suggestedQuestionsAtom";
import { CancelOkButtons } from "./CancelOkButtons";
function FindingEditorModal({ finding: initial, newFinding, onFindingUpsert, suggest, disabled, }) {
    var _a;
    const titleToExclude = newFinding ? "" : ((_a = initial.title) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || "";
    const { enableSuggestedQuestions } = useFeatureFlags();
    const [finding, setFinding] = React.useState(initial);
    const { evictSuggestedEvidenceFor } = useSuggestedEvidence();
    const supportsSuggestQuestions = useIsFeatureSupported(Feature.QUERY_SUGGESTION_EVIDENCE);
    useEffectIfDifferent(() => evictSuggestedEvidenceFor(finding.id), [evictSuggestedEvidenceFor], [finding.payload.selectedText]);
    const [{ suggestedQuestionsHistory, findings }, sessionUpdater] = useCurrentSession();
    const { suggestQuestions } = useSuggestedQuestionsState();
    const titleError = useValidateFindingTitle(findings.filter(f => f.id !== finding.id), finding.title);
    return (_jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch space-y-2" }, { children: [_jsx(FindingEditor, { finding: finding, setFinding: setFinding, disabled: disabled, titleError: titleError }), _jsx(CancelOkButtons, { buttonCaption: "Save Changes", buttonColorClass: "bg-gray-700", disabled: !!titleError ||
                    isEqual(initial, finding) ||
                    !finding.payload.selectedText.trim().length, action: () => {
                    popModal(true);
                    sessionUpdater(({ findings }) => ({
                        findings: upsertFinding(findings, finding),
                    }));
                    const notificationText = newFinding
                        ? "Finding Created"
                        : "Finding Changed";
                    showNotification(notificationText);
                    onFindingUpsert === null || onFindingUpsert === void 0 ? void 0 : onFindingUpsert(finding);
                    supportsSuggestQuestions &&
                        suggest &&
                        newFinding &&
                        suggestQuestions(finding, suggestedQuestionsHistory, !enableSuggestedQuestions);
                    newFinding &&
                        scrollToElement(() => document.getElementById(finding.id), 1000);
                } })] })));
}
export function showFindingEditor(finding, newFinding, suggest, disabled, onFindingUpsert) {
    pushModal({
        title: newFinding ? "Add New Finding" : "Edit Finding",
        titleColorClass: "bg-gray-700",
        className: "w-full",
        content: (_jsx(FindingEditorModal, { finding: finding, newFinding: newFinding, onFindingUpsert: onFindingUpsert, suggest: suggest, disabled: disabled })),
    });
}
