var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { debounce } from "lodash";
import React from "react";
import { useMemoIfEqual } from "./useMemoIfEqual";
export function useDebouncedValue(value, debounceSettings = { delayMs: 300 }) {
    const { delayMs } = debounceSettings, settings = __rest(debounceSettings, ["delayMs"]);
    const isDelayed = delayMs > 0;
    const [delayed, setDelayed] = React.useState(value);
    const update = React.useCallback(isDelayed ? debounce(setDelayed, delayMs, settings) : (value) => value, []);
    React.useEffect(() => {
        isDelayed && update(value);
    }, [useMemoIfEqual(isDelayed && value), isDelayed && update]);
    return isDelayed ? delayed : value;
}
