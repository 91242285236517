import { jsx as _jsx } from "react/jsx-runtime";
import { groupBy } from "lodash";
import React from "react";
import { GroupedVirtuoso } from "react-virtuoso";
export function GroupedList({ ref, items, groupContentRender, itemContentRender, }) {
    const groupedResults = groupBy(items, "section");
    const groupCounts = Object.values(groupedResults).map(g => g.length);
    const groups = Object.keys(groupedResults);
    return (_jsx(GroupedVirtuoso, { ref: ref, groupCounts: groupCounts, groupContent: index => groupContentRender(groups[index], index), itemContent: index => itemContentRender(items[index], index) }));
}
