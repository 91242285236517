var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useReactFlow } from "reactflow";
import { API } from "../../api/api";
import { isMulthop } from "../../api/types/MultihopRelationship";
import { useInterruptableFetch } from "../../api/useInterruptableFetch";
import { DMTab, a_modelActiveTab, a_modelAnswerState, a_modelResetAnswer, a_modelSolutions, } from "../../appState/atomic/domainModel/modelState";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_resetModelConfig } from "../../appState/atomic/queryState/constraintModel/concepts";
import { useActiveModelId } from "../../appState/atomic/queryState/constraintModel/fromDMParams";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useConjunctionBindingsRequest } from "../../appState/atomic/queryState/utils/conjunctionBindingsRequest";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { classes } from "../../appState/context/theme/classes";
import { DivButton } from "../common/buttons/DivButton";
import { Tooltip } from "../common/popups/Tooltip";
import { showNotification } from "../common/status/notifications";
function delay(provider, millis) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(provider());
            }, millis);
        });
    });
}
export function SolutionsButtons() {
    const [{ conjunctionBindingsSearchWidth, expandedFacetCount }] = useUserConfig();
    const { discoveryStrategy } = useAtomValue(queryStateAtoms.multihopConfig);
    const multihop = isMulthop(discoveryStrategy);
    const setActiveTab = useSetAtom(a_modelActiveTab);
    const setModelAnswer = useSetAtom(a_modelAnswerState);
    const resetModelAnswer = useSetAtom(a_modelResetAnswer);
    const { solutions } = useAtomValue(a_modelSolutions);
    const { fitView } = useReactFlow();
    const supportsGraphSolving = useIsFeatureSupported(Feature.QUERY_GRAPH_SOLVING);
    const resetModelConfig = useSetAtom(a_resetModelConfig);
    const [fetch, loading] = useInterruptableFetch();
    const conjunctionBindingsParams = useConjunctionBindingsRequest(conjunctionBindingsSearchWidth, expandedFacetCount);
    const bindingsFor = conjunctionBindingsParams === null || conjunctionBindingsParams === void 0 ? void 0 : conjunctionBindingsParams.query.bindings_for;
    const disabled = Boolean(!(bindingsFor === null || bindingsFor === void 0 ? void 0 : bindingsFor.length) || loading);
    function execAndPreserveSelection(action) {
        return __awaiter(this, void 0, void 0, function* () {
            yield action();
        });
    }
    const activeModelId = useActiveModelId();
    return !supportsGraphSolving || (multihop && !activeModelId) ? null : (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ content: "Find concepts that satisfy the model." }, { children: _jsx(DivButton, Object.assign({ className: classes("px-1 border rounded"), disabled: disabled, onClick: () => fetch((signal) => __awaiter(this, void 0, void 0, function* () {
                        yield delay(() => execAndPreserveSelection(() => __awaiter(this, void 0, void 0, function* () {
                            const answerResponse = yield API.conjunctionBindings(conjunctionBindingsParams, {
                                signal,
                                ignoreErrorHandler: true,
                            });
                            resetModelAnswer();
                            setModelAnswer(answerResponse);
                            if (!(answerResponse.answer &&
                                answerResponse.references &&
                                answerResponse.evidence)) {
                                showNotification("No summary generated. Try adjusting the model constraints.", "warning");
                            }
                            // set no active constraint
                            //updateQueryStateFromActive(null)
                        })), 100);
                    }), _jsxs("div", { children: ["Finding Solutions", _jsx("div", { children: "This could take some time..." }), _jsx("div", { children: "We are working so you don't have to." })] })) }, { children: "Explore Solutions" })) })), _jsx(Tooltip, Object.assign({ content: "Clear current solutions and answers." }, { children: _jsx(DivButton, Object.assign({ disabled: isEmpty(solutions), onClick: () => {
                        // set no active constraint
                        // updateQueryStateFromActive(null)
                        setActiveTab(DMTab.GRAPH);
                        resetModelAnswer();
                    }, className: classes("px-1 border rounded") }, { children: "Clear Solutions" })) })), _jsx(Tooltip, Object.assign({ content: "Clear current solutions and answers." }, { children: _jsx(DivButton, Object.assign({ disabled: false, onClick: () => {
                        // set no active constraint
                        // updateQueryStateFromActive(null)
                        setActiveTab(DMTab.GRAPH);
                        resetModelConfig();
                        fitView({ padding: 0.1 });
                    }, className: classes("px-1 border rounded") }, { children: "Reset Graph" })) }))] }));
}
