export const EMPTY_CONSTRAINT_MODEL_CONFIG = {
    overrides: {},
    requiredConcepts: {},
    extendedConceptNodes: {},
    extendedNodeIds: [],
    qualifiers: {},
    requiredQualifiers: {},
    rangeMetadata: [],
    booleanMetadata: [],
    keywordMetadata: [],
    refutingConfiguration: {},
};
