var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { BaseEdge, useReactFlow } from "reactflow";
import { DEFAULT_PORT_R, getDirectPath } from "./diagramUtils";
import { ElementType } from "./model";
function DiagramEdge(_a) {
    var { source, target, pathOptions, label } = _a, props = __rest(_a, ["source", "target", "pathOptions", "label"]);
    const flow = useReactFlow();
    const sourceNode = flow.getNode(source);
    const targetNode = flow.getNode(target);
    if (!sourceNode || !targetNode)
        return null;
    const { path, labelX, labelY } = getDirectPath(sourceNode, targetNode, DEFAULT_PORT_R);
    return !path ? null : _jsx(BaseEdge, Object.assign({}, Object.assign(Object.assign({}, props), { path, labelX, labelY })));
}
export const EDGE_TYPES = {
    [ElementType.RELATION]: DiagramEdge,
    [ElementType.QUALIFIER]: DiagramEdge,
};
