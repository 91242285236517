import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { API } from "../../api/api";
import { useRemoteQuery } from "../../api/useRemoteQuery";
import { Empty } from "../common/layout/Empty";
import { pushModal } from "../common/modals/Modal";
function SourceInfo({ corpus_id }) {
    var _a, _b;
    const remote = useRemoteQuery({ loader: API.corpusInfo, params: { corpus_id } });
    const domain_models = (_b = (_a = remote.value) === null || _a === void 0 ? void 0 : _a.domain_models) !== null && _b !== void 0 ? _b : [];
    return (_jsx(Empty, Object.assign({ loading: remote.loading }, { children: remote.value && (_jsx("div", Object.assign({ className: "flex-1 flex flex-col space-y-1" }, { children: _jsx("table", { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: "Source Name:" }), _jsx("td", Object.assign({ className: "font-semibold pl-8" }, { children: remote.value.name }))] }), _jsxs("tr", { children: [_jsx("td", { children: "Source Id:" }), _jsx("td", Object.assign({ className: "font-semibold pl-8" }, { children: corpus_id }))] }), _jsxs("tr", { children: [_jsx("td", { children: "Document Count:" }), _jsx("td", Object.assign({ className: "font-semibold pl-8" }, { children: new Intl.NumberFormat("en", {
                                        maximumSignificantDigits: 3,
                                    }).format(remote.value.documents_count) }))] }), _jsxs("tr", { children: [_jsx("td", { children: "Last Updated:" }), _jsx("td", Object.assign({ className: "font-semibold pl-8" }, { children: remote.value.last_updated }))] }), _jsxs("tr", { children: [_jsx("td", { children: "Domain Models:" }), _jsx("td", Object.assign({ className: "font-semibold pl-8" }, { children: domain_models.length }))] })] }) }) }))) })));
}
export function showSourceInfo(corpus_id) {
    pushModal({
        title: "Source Info",
        easyClose() {
            return true;
        },
        className: "w-[30%]",
        content: _jsx(SourceInfo, { corpus_id: corpus_id }),
    });
}
