import { isEqual } from "lodash";
import React from "react";
import { useReactFlow } from "reactflow";
import { getAncestor } from "../../utils/dom/utils/dom";
import { addWindowEventHandlers } from "../../utils/events/addWindowEventHandlers";
import { updateItems } from "../../utils/generic/collections";
import { getEventModifiers, isContextMenuTrigger, } from "../../utils/interactions/utils/interactionEvents";
import { hideMenuBarPopup } from "../actions/useActionBarEntries";
import { showPopupMenu } from "../contextMenu/showPopupMenu";
import { Mode, useDiagramMode } from "./DiagramMode";
import { useDiagramStateUpdater } from "./DiagramState";
import { GraphAnalysis } from "./GraphAnalysis";
import { NodeSelector } from "./NodeSelector";
import { NO_ACTIVATE_ON_CLICK, activate, focusModelBuilder, getNodeAt, targetIsPane, } from "./diagramUtils";
import { selectionActivation } from "./layers/SelectionLayer";
export function useMouseDownHandlers(disabled, ...handlers) {
    return React.useCallback((e) => {
        if (disabled)
            return;
        handlers.find(handler => handler(e));
    }, [disabled, handlers]);
}
export function useFocusModelBuilder() {
    return React.useCallback(() => {
        focusModelBuilder();
        return false;
    }, []);
}
export function ignoreMouseHandling(e) {
    if (getAncestor(e.target, "react-flow__panel"))
        return true;
    return false;
}
export function useTrackViewport() {
    const flow = useReactFlow();
    const dUpdater = useDiagramStateUpdater();
    const stateOnKeyDown = React.useRef();
    React.useEffect(() => {
        return addWindowEventHandlers({
            keydown: capture,
            keyup: commit,
            mouseup: commit,
        });
    }, [flow]);
    return React.useCallback(mdEvent => {
        capture(mdEvent);
        return false;
    }, [flow]);
    function capture(e) {
        stateOnKeyDown.current = {
            viewport: flow.getViewport(),
            mod: getEventModifiers(e),
        };
    }
    function commit() {
        if (!stateOnKeyDown.current)
            return;
        const { viewport, mod } = stateOnKeyDown.current;
        stateOnKeyDown.current = undefined;
        // delay commit viewport to make sure animations are finished
        setTimeout(() => {
            if (!isEqual(viewport, flow.getViewport())) {
                dUpdater.commit({ viewport: flow.getViewport() });
            }
        }, 250);
    }
}
export function useHidePopups() {
    return React.useCallback(e => {
        showPopupMenu(false);
        // Menu bar popup was shown, event was handled
        return hideMenuBarPopup(e);
    }, []);
}
export function useSelectNodeBeforeShowingContextMenu() {
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    return React.useCallback(e => {
        const node = getNodeAt(flow, e);
        if (isContextMenuTrigger(e) && node && !node.selected) {
            dUpdater.commit({
                nodes: updateItems(flow.getNodes(), n => ({
                    selected: n.id === node.id,
                })),
            });
            return true;
        }
        return false;
    }, []);
}
export function useActivateQuery() {
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    const mode = useDiagramMode();
    return React.useCallback(e => {
        const node = getNodeAt(flow, e);
        if (e.button === 0 &&
            node &&
            !getAncestor(e.target, NO_ACTIVATE_ON_CLICK)) {
            dUpdater.commit(activate(GraphAnalysis.from(flow), node ? [node] : []));
        }
        return false;
    }, [mode()]);
}
export function useActivateNodeSelector(element, setSelectionLayer) {
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    const mode = useDiagramMode();
    return React.useCallback(e => {
        if (e.button === 0 && targetIsPane(e) && mode.is(Mode.normal)) {
            const dispose = addWindowEventHandlers({
                mousemove() {
                    selectionActivation(e, flow, NodeSelector(dUpdater, flow), element, layer => {
                        if (layer) {
                            mode.push(Mode.selecting);
                        }
                        else {
                            mode.pop();
                        }
                        setSelectionLayer(layer);
                    });
                    dispose();
                },
                mouseup() {
                    dispose();
                },
            });
        }
        return false;
    }, [element, mode()]);
}
