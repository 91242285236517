import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from "antd";
import { useEvidenceSelection } from "../../../appState/atomic/evidence/useEvidenceSelection";
import { Feature, useIsFeatureSupported, } from "../../../appState/atomic/featureFlags/features";
import { useSummariesDisplayState } from "../../../appState/atomic/summaries/useSummariesDisplayState";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { classes } from "../../../appState/context/theme/classes";
import { useUniqId } from "../../../utils/identity/useUniqId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { BorderlessButton } from "../../common/buttons/BorderlessButton";
import { FindingFilled } from "../../common/icons/Icons";
import { ActionableBlock } from "../evidenceDisplay/ActionableBlock";
import { CollapsibleArea } from "../evidenceDisplay/CollapsibleArea";
import { EvidenceList } from "../evidenceDisplay/EvidenceList";
import { TextWithEvidenceReferences } from "../evidenceDisplay/TextWithEvidenceReferences";
import { FindingsDropdown } from "../findingsControls/FindingsDropdown";
import { CopyButton } from "./CopyButton";
export function AnswerCard({ question, answer }) {
    const { evidenceSelected, selectEvidence, deselectEvidence } = useEvidenceSelection();
    const containerId = useUniqId(uuidv4);
    const { getEvidenceList } = useEvidenceSearchUtils();
    const { answerRefIsFolded, setFoldAnswerRef } = useSummariesDisplayState();
    const answerEvidenceIdList = answer.references.map(r => r.evidence_id);
    const answerEvidence = getEvidenceList(answerEvidenceIdList);
    const someSelected = answerEvidenceIdList.some(evidenceSelected);
    const allSelected = answerEvidenceIdList.every(evidenceSelected);
    const selectedChildren = answerEvidenceIdList.filter(evidenceSelected);
    const answerSupported = useIsFeatureSupported(Feature.ANSWER_GENERATION);
    if (!answerSupported || answer.answer === "") {
        return null;
    }
    const topFindingsControl = (_jsx(FindingsDropdown, { autoFindingName: question, findingText: answer.answer, evidenceIdList: answerEvidenceIdList, disabledDropdown: false, renderDropdownAnchor: (openMenu, hasExistingFinding) => {
            return (_jsx(BorderlessButton, Object.assign({ onClick: openMenu, className: "flex flex-1 justify-center items-center hover:bg-gray-300 cursor-pointer rounded-full" }, { children: _jsx(FindingFilled, { className: classes("text-base my-auto px-1 py-1") }) })));
        } }, "answerFindings"));
    const selectionFindingsControl = (_jsx(FindingsDropdown, { evidenceIdList: selectedChildren, disabledDropdown: !someSelected, renderDropdownAnchor: (setMenuOpen, hasExistingFinding) => {
            return (_jsx(BorderlessButton, Object.assign({ style: { height: "36px" }, className: "ml-2 rounded-xl font-medium text-tiny mr-2", onClick: setMenuOpen, disabled: !someSelected }, { children: _jsx("div", Object.assign({ className: "flex flex-1 justify-center items-center" }, { children: _jsx(FindingFilled, { className: classes("text-base my-auto mr-1") }) })) }), !someSelected ? "disabled" : "enabled"));
        } }));
    return (_jsxs("div", Object.assign({ id: containerId, className: "flex flex-col items-start space-y-2 whitespace-pre-wrap mb-4 pb-2 border-b border-b-gray-400" }, { children: [_jsx(ActionableBlock, Object.assign({ actions: [
                    topFindingsControl,
                    _jsx(CopyButton, { text: answer.answer }, "answerCopy"),
                ] }, { children: _jsx(TextWithEvidenceReferences, { text: answer.answer, references: answer.references, showReferences: true, showHiddenReferences: true }) })), _jsx(CollapsibleArea, Object.assign({ className: "w-full", collapsed: answerRefIsFolded, setCollapsed: setFoldAnswerRef, label: _jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx("span", Object.assign({ className: "ml-2 font-semibold text-gray-600" }, { children: "View Evidence" })) })), prefix: _jsx(Checkbox, { className: "ml-2", checked: allSelected, indeterminate: someSelected && !allSelected, onChange: e => {
                        e.target.checked
                            ? selectEvidence(answerEvidenceIdList)
                            : deselectEvidence(answerEvidenceIdList);
                    } }), suffix: selectionFindingsControl }, { children: _jsx("div", Object.assign({ className: "pl-[24px]" }, { children: _jsx(EvidenceList, { evidence: answerEvidence }) })) }))] })));
}
