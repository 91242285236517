import { Set } from "immutable";
import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
const a_foldAnswerRef = atomWithReset(false);
const a_foldSummariesBlock = atomWithReset(true);
const a_foldedSummaryRefs = atomWithReset(Set([]));
export function useSummariesDisplayState() {
    const [answerRefIsFolded, setFoldAnswerRef] = useAtom(a_foldAnswerRef);
    const [summaryBlockIsFolded, setFoldSummariesBlock] = useAtom(a_foldSummariesBlock);
    const [foldedSummaryRefs, setFoldedSummaryRefs] = useAtom(a_foldedSummaryRefs);
    const resetFoldedSummaryRefs = useResetAtom(a_foldedSummaryRefs);
    return {
        answerRefIsFolded,
        setFoldAnswerRef,
        summaryBlockIsFolded,
        setFoldSummariesBlock,
        summaryRefIsFolded: (summaryId) => foldedSummaryRefs.has(summaryId),
        setFoldSummaryRef: (summaryId, fold) => setFoldedSummaryRefs(fold
            ? foldedSummaryRefs.union([summaryId])
            : foldedSummaryRefs.subtract([summaryId])),
        resetFoldedSummaryRefs,
        updateFoldedSummaryRefs: (summaryIds) => {
            setFoldedSummaryRefs(Set(summaryIds));
        },
    };
}
