import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const EMPTY_FACET_STATE = {
    expandedFacetGroups: [],
    facetsCollapsedState: {},
    hiddenFacetsByCorpusIdAndGroup: {},
    stickyFacets: [],
    currentFacetsTab: "",
};
const _atoms = {
    facetsCollapsedState: atomWithReset(EMPTY_FACET_STATE.facetsCollapsedState),
    hiddenFacetsByCorpusIdAndGroup: atomWithReset(EMPTY_FACET_STATE.hiddenFacetsByCorpusIdAndGroup),
    expandedFacetGroups: atomWithReset(EMPTY_FACET_STATE.expandedFacetGroups),
    currentFacetsTab: atomWithReset(EMPTY_FACET_STATE.currentFacetsTab),
    stickyFacets: atomWithReset(EMPTY_FACET_STATE.stickyFacets),
};
export const FACET_STATE_KEYS = Object.keys(_atoms);
export const a_facetState = atom(get => {
    return {
        expandedFacetGroups: get(_atoms.expandedFacetGroups),
        facetsCollapsedState: get(_atoms.facetsCollapsedState),
        hiddenFacetsByCorpusIdAndGroup: get(_atoms.hiddenFacetsByCorpusIdAndGroup),
        stickyFacets: get(_atoms.stickyFacets),
        currentFacetsTab: get(_atoms.currentFacetsTab),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_facetState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useFacetState() {
    return useResettableState(a_facetState);
}
