import React from "react";
import { merge } from "../generic/collections";
import { delta } from "../generic/objects";
function useUpdater(setValue, debugName) {
    return React.useCallback((updateOrFn) => {
        setValue(value => {
            const updated = merge(value, updateOrFn, true);
            if (debugName && updated !== value)
                console.trace(debugName, delta(updated, value));
            return updated;
        });
    }, []);
}
export function useParams(initial, debugName) {
    const [state, setState] = React.useState(initial);
    return [state, useUpdater(setState, debugName)];
}
