import { jsx as _jsx } from "react/jsx-runtime";
import { partition } from "lodash";
import React from "react";
import { EvidenceType } from "../../../api/types/evidenceTypes";
import { classes } from "../../../appState/context/theme/classes";
import { Banner } from "../../common/status/Banner";
export function EvidenceSummaryBanner({ evidence }) {
    const [supporting, refuting] = partition(evidence.filter(e => e.evidence_type === EvidenceType.SUPPORTING ||
        e.evidence_type === EvidenceType.REFUTING), i => i.evidence_type === EvidenceType.SUPPORTING);
    return (_jsx(Banner, { children: classes(supporting.length && `Supporting (${supporting.length})`, refuting.length && `Refuting (${refuting.length})`, !evidence.length
            ? "No Evidence"
            : supporting.length + refuting.length === 0
                ? `Evidence Count: ${evidence.length}`
                : "") }));
}
