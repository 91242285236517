import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import "./animations.less";
import "./index.less";
// import {Zeno} from "./draft/zeno/Zeno"
// import {Connectors} from "./draft/Connectors"
import AuthApp from "./cora/appState/atomic/auth/AuthApp";
import { WithTheme } from "./cora/appState/context/theme/WithTheme";
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(WithTheme, { children: _jsx(AuthApp, {}) }) }), document.getElementById("root"));
