var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { API } from "../../api/api";
import { a_resetAllConstraintConceptFilters, a_resetAllSolutions, } from "../../appState/atomic/queryState/constraintModel/concepts";
import { a_userText } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_modelFromDMParams } from "../../appState/atomic/queryState/constraintModel/fromDMParams";
import { a_activeModelId } from "../../appState/atomic/queryState/constraintModel/modelState";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useQueryState } from "../../appState/atomic/queryState/useQueryState";
import { domainModelToDMParams } from "../../appState/atomic/queryState/utils/domainModelToDMParams";
import { getEmptyQueryStateForCorpus } from "../../appState/atomic/queryState/utils/getEmptyQueryStateForCorpus";
import { useUsageState } from "../../appState/atomic/session/parts/usageState";
import { useQueryInputTextResetter } from "../../appState/atomic/useQueryInputText";
import { classes } from "../../appState/context/theme/classes";
import { loadQueryState } from "../../appState/stateRestoration/loadQueryState";
import { QueryId } from "../../appState/stateRestoration/queryId";
import { DivButton } from "../common/buttons/DivButton";
import { Down } from "../common/icons/Icons";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { truncateToNearestSpace } from "../evidence/truncateToNearestSpace";
import { useCurrentDomainModelDescriptor } from "./useCurrentDomainModelDescriptor";
import { useDomainModelDescriptors } from "./useDomainModelDescriptors";
export function ModelPicker({ onLoading, onSelect, excludeCurrentQuery, placeholderText = "Select", }) {
    const [, updateQueryState] = useQueryState();
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const setUserText = useSetAtom(a_userText);
    const setActiveModelId = useSetAtom(a_activeModelId);
    const fromDMParams = useSetAtom(a_modelFromDMParams);
    const resetAllConstraintConceptFilters = useSetAtom(a_resetAllConstraintConceptFilters);
    const resetAllSolutions = useSetAtom(a_resetAllSolutions);
    const [{ currentQueryId }, usageUpater] = useUsageState();
    const resetQueryInputText = useQueryInputTextResetter();
    const descriptors = useDomainModelDescriptors(corpus_ids);
    const selected = useCurrentDomainModelDescriptor(descriptors, corpus_ids);
    const disabled = descriptors.loading;
    truncateToNearestSpace;
    const button = (_jsx(DivButton, Object.assign({ className: classes("px-1 border rounded"), disabled: disabled }, { children: _jsxs("div", Object.assign({ className: "flex flex-row space-x-1 items-center px-1" }, { children: [_jsx("div", { children: !descriptors.loading && !selected
                        ? excludeCurrentQuery
                            ? placeholderText
                            : "Current Query"
                        : descriptors.loading
                            ? "Loading..."
                            : truncateToNearestSpace((selected === null || selected === void 0 ? void 0 : selected.name) || "", {
                                maxLength: 30,
                                postfix: "...",
                            }) }), _jsx(Down, {})] })) })));
    return disabled ? (button) : (_jsx(DropDownMenu, Object.assign({ title: "Domain Model", hasClose: true, entries: () => {
            const currentQueryEntry = excludeCurrentQuery
                ? undefined
                : {
                    caption: "Current Query",
                    selected: !selected,
                    action() {
                        return __awaiter(this, void 0, void 0, function* () {
                            try {
                                onSelect && onSelect();
                                onLoading && onLoading(true);
                                resetAllSolutions();
                                if (selected && currentQueryId) {
                                    loadQueryState(currentQueryId).then(currentState => {
                                        setActiveModelId("");
                                        updateQueryState(Object.assign({}, currentState));
                                    });
                                }
                                else if (selected) {
                                    const emptyState = getEmptyQueryStateForCorpus(corpus_ids);
                                    updateQueryState(Object.assign({}, emptyState));
                                }
                            }
                            finally {
                                onLoading && setTimeout(() => onLoading(false), 100);
                            }
                        });
                    },
                };
            const modelEntries = descriptors.value.map(desc => ({
                caption: desc.name || desc.id,
                selected: desc.id === (selected === null || selected === void 0 ? void 0 : selected.id),
                action() {
                    return __awaiter(this, void 0, void 0, function* () {
                        try {
                            onSelect && onSelect();
                            onLoading && onLoading(true);
                            const dmParams = domainModelToDMParams(yield API.getDomainModel({ id: desc.id, corpus_ids }));
                            resetQueryInputText();
                            resetAllSolutions();
                            resetAllConstraintConceptFilters();
                            fromDMParams(dmParams, desc.id);
                            setUserText("");
                            usageUpater({
                                currentQueryId: !selected && QueryId.get() ? QueryId.get() : null,
                            });
                        }
                        finally {
                            onLoading && setTimeout(() => onLoading(false), 100);
                        }
                    });
                },
            }));
            return [currentQueryEntry, ...modelEntries].filter(Boolean);
        } }, { children: button })));
}
