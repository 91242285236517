import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { useDebouncedValue } from "../../../utils/lifecycle/useDebouncedValue";
import { wordMatches } from "../../../utils/text/filters";
import { ActionButton } from "../buttons/ActionButton";
import { Items } from "../forms/Items";
import { TitledContainer } from "./TitledContainer";
import { useItemFilters } from "./useItemFilters";
export function CollectionManager({ title, items, empty, itemClassName, itemRender, onClick, onRemove, controls, sorter, itemText, className, }) {
    const [textFilter, setTextFilter] = React.useState("");
    const debounced = useDebouncedValue(textFilter);
    const filtered = React.useMemo(() => {
        const filter = wordMatches(debounced);
        return !itemText ? items : items === null || items === void 0 ? void 0 : items.filter(i => filter(itemText(i)));
    }, [debounced, items]);
    const { items: itemsToShow, headerWrapper } = useItemFilters({
        items: filtered || [],
        textFilter,
        setTextFilter,
        itemsSorter: sorter && (items => items.sort(sorter)),
    });
    return (_jsx(TitledContainer, Object.assign({ headerLeft: title, headerWrapper: headerWrapper, headerInfo: (items === null || items === void 0 ? void 0 : items.length) ? items.length : "", headerRight: controls, className: classes("flex-1", className), empty: !(items === null || items === void 0 ? void 0 : items.length) && (empty || "No Items"), hideHeader: !filtered }, { children: _jsx(Items, { items: itemsToShow, gutter: "NONE", itemRender: (item, idx) => (_jsx("div", Object.assign({ className: classes("flex justify-between items-center cursor-pointer auto_hide_container px-2 py-1 rounded", typeof itemClassName === "string"
                    ? itemClassName
                    : itemClassName === null || itemClassName === void 0 ? void 0 : itemClassName(item, idx, items || [])), onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick(item) }, { children: _jsxs(_Fragment, { children: [(itemRender === null || itemRender === void 0 ? void 0 : itemRender(item, idx, items || [])) || item, onRemove && (_jsx(ActionButton, Object.assign({ className: classes("cursor-pointer auto_hide"), onMouseDownCapture: e => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClick === null || onClick === void 0 ? void 0 : onClick(item);
                                onRemove === null || onRemove === void 0 ? void 0 : onRemove(item);
                            } }, { children: _jsx(DeleteOutlined, {}) })))] }) }))) }) })));
}
