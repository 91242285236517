import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { a_resetAllConstraintConceptFilters, a_resetAllSolutions, } from "../../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint } from "../../../appState/atomic/queryState/constraintModel/constraints";
import { stageNewNode } from "../diagramUtils";
export function useGraphActions({ nodes, selected, active, stage, flow, dUpdater, }) {
    const constraint = useAtomValue(a_activeConstraint);
    const resetAllSolutions = useSetAtom(a_resetAllSolutions);
    const resetAllConstraintConceptFilters = useSetAtom(a_resetAllConstraintConceptFilters);
    return React.useCallback(() => ({
        global: [
            {
                caption: "Graph",
                subEntries: [
                    {
                        caption: "Add Concept",
                        shortcut: "c",
                        action() {
                            stageNewNode(stage);
                        },
                    },
                    {
                        caption: selected.length ? "Fit Selection" : "Fit",
                        shortcut: "alt+t",
                        action() {
                            if (!nodes.length) {
                                flow.zoomTo(1, { duration: 200 });
                            }
                            flow.fitView(Object.assign({ duration: 200 }, (selected.length && { nodes: selected })));
                        },
                    },
                    {
                        caption: "Fit Query",
                        shortcut: "alt+shift+t",
                        disabled: !active.length,
                        action() {
                            flow.fitView({
                                duration: 200,
                                nodes: active,
                            });
                        },
                    },
                    {
                        caption: "Clear",
                        disabled: !nodes.length,
                        action() {
                            dUpdater.commit({ nodes: [], edges: [] });
                            resetAllSolutions();
                        },
                    },
                    {
                        caption: "Reset",
                        desc: "Reset the graph to its initial state.",
                        disabled: !nodes.length,
                        action() {
                            resetAllConstraintConceptFilters();
                            resetAllSolutions();
                        },
                    },
                ],
            },
        ],
    }), [nodes, selected, active, stage, constraint, resetAllSolutions]);
}
