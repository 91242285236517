import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "../../../../App";
import { getConfig } from "../../config/serverConfig";
import { ORG_LANDING_PAGES, OrgLandingPage } from "./OrgLandingPage";
import { useAuthComponents } from "./useAuthComponents";
import { hasAuthToken, usingHeliosAuth } from "./utils/authInfo";
const LD_CLIENT_ID = getConfig("LD_CLIENT_SIDE_ID", "");
function AuthApp() {
    const skipAuthProvider = hasAuthToken() || usingHeliosAuth();
    if (skipAuthProvider)
        console.log("skipping auth provider");
    const { AuthWrapper, CoraAuthProvider, DefaultLoginRoute } = useAuthComponents();
    const landingPageRoutes = React.useMemo(() => {
        return ORG_LANDING_PAGES.map(olpConfig => (_jsx(Route, Object.assign({ exact: true, strict: false, path: olpConfig.path }, { children: _jsx(OrgLandingPage, { olpConfig: olpConfig }) }), olpConfig.path)));
    }, ORG_LANDING_PAGES);
    const routes = (_jsxs(Switch, { children: [_jsx(Route, Object.assign({ exact: true, strict: false, path: "/login" }, { children: _jsx(DefaultLoginRoute, {}) })), landingPageRoutes, _jsx(Route, Object.assign({ path: "*" }, { children: skipAuthProvider ? _jsx(App, {}) : _jsx(AuthWrapper, {}) }))] }));
    return (_jsx(BrowserRouter, { children: skipAuthProvider ? (routes) : (_jsx(CoraAuthProvider, { children: routes })) }));
}
export default LD_CLIENT_ID
    ? withLDProvider({ clientSideID: LD_CLIENT_ID })(AuthApp)
    : AuthApp;
