import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { a_addConceptToNode, a_addContextNodeId, a_clearContextNodes, a_resetConceptNodes, } from "../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_resetQuery } from "../../appState/atomic/queryState/constraintModel/resets";
import { CONTEXT_CAPTION } from "../../appState/atomic/queryState/consts/CONTEXT_CAPTION";
import { SOURCE_CAPTION } from "../../appState/atomic/queryState/consts/SOURCE_CAPTION";
import { TARGET_CAPTION } from "../../appState/atomic/queryState/consts/TARGET_CAPTION";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { conceptMenuExtension } from "../../extensions/dropdownMenuExtensions";
import { uuidv4 } from "../../utils/identity/uuidv4";
import { Menu } from "../actions/Menu";
import { RelatedSearch } from "../common/icons/Icons";
import { postQuestion } from "../queryAnalysis/postQuestion";
export function useConceptMenuEntries(options = {}) {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const corpora = useGlobalInfo().corporaById;
    const constraint = useAtomValue(a_activeConstraint);
    const resetQuery = useSetAtom(a_resetQuery);
    const addConceptToNode = useSetAtom(a_addConceptToNode);
    const addContextNodeId = useSetAtom(a_addContextNodeId);
    const resetConceptNodes = useSetAtom(a_resetConceptNodes);
    const clearContextNodes = useSetAtom(a_clearContextNodes);
    return (item, items) => {
        const { getExtraConceptEntries, getExtraMenuEntries } = options;
        const extraConceptEntries = (getExtraConceptEntries && getExtraConceptEntries(item, items)) || [];
        const extraMenuEntries = (getExtraMenuEntries && getExtraMenuEntries(item, items)) || [];
        const structuredQueryEntries = [];
        if (options.showNLQueryOption) {
            structuredQueryEntries.push({
                caption: "Use as next natural language query",
                action() {
                    postQuestion(item.name);
                },
                icon: _jsx(RelatedSearch, {}),
            });
        }
        if (options.showStructuredQueryOptions) {
            structuredQueryEntries.push({
                caption: "Use as next structured query",
                action() {
                    resetQuery();
                    addConceptToNode(constraint.sourceNodeId, item);
                },
                icon: _jsx(RelatedSearch, {}),
            }, {
                caption: `Use as ${SOURCE_CAPTION}`,
                action() {
                    resetConceptNodes([constraint.sourceNodeId]);
                    addConceptToNode(constraint.sourceNodeId, item);
                },
                icon: _jsx(RelatedSearch, {}),
            }, {
                caption: `Use as ${TARGET_CAPTION}`,
                action() {
                    resetConceptNodes([constraint.targetNodeId]);
                    addConceptToNode(constraint.targetNodeId, item);
                },
                icon: _jsx(RelatedSearch, {}),
            }, {
                caption: `Use as ${CONTEXT_CAPTION}`,
                action() {
                    resetConceptNodes(constraint.contextNodeIds);
                    clearContextNodes();
                    const nodeId = uuidv4(); // context gets a new node id
                    addContextNodeId(nodeId);
                    addConceptToNode(nodeId, item);
                },
                icon: _jsx(RelatedSearch, {}),
            });
        }
        return conceptMenuExtension(corpora, corpus_ids, [
            ...structuredQueryEntries,
            ...extraConceptEntries,
            Menu.SEPARATOR,
            ...(extraMenuEntries.length
                ? [...extraMenuEntries, Menu.SEPARATOR]
                : []),
        ], [item]);
    };
}
