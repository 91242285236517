import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getAvailableEvidence } from "../../../appState/context/findings/findings";
import { classes } from "../../../appState/context/theme/classes";
import { ButtonWrapper } from "../../common/buttons/ButtonWrapper";
import { Robot } from "../../common/icons/Icons";
import { Banner } from "../../common/status/Banner";
export function SuggestedEvidenceBanner({ evidence, suggested, onClick, }) {
    const available = React.useMemo(() => getAvailableEvidence(suggested === null || suggested === void 0 ? void 0 : suggested.value, evidence), [suggested === null || suggested === void 0 ? void 0 : suggested.value, evidence]);
    return !(suggested === null || suggested === void 0 ? void 0 : suggested.loading) && !available.length ? null : (_jsx(ButtonWrapper, Object.assign({ className: "flex-1", onClick: onClick }, { children: _jsx(Banner, Object.assign({ prefix: _jsx(Robot, { className: "text-gray-400", style: { fontSize: 20 } }) }, { children: classes((suggested === null || suggested === void 0 ? void 0 : suggested.loading) && "Looking for more evidence…", !(suggested === null || suggested === void 0 ? void 0 : suggested.loading) && `Suggested evidence (${available.length})…`) })) })));
}
