import Lazy from "lazy.js";
import { ArgumentType } from "../../../../api/types/semanticTypes";
import { CATEGORYCAL, CONCEPT_1_COLOR, CONCEPT_2_COLOR, CONTEXT_CONCEPT_COLOR, NEUTRAL_COLOR, RELATION_COLOR, } from "../../../context/theme/lf";
import { FixedArgs } from "../types/FixedArgs";
export function getArgsInfo(source_id, globalInfo) {
    const info = source_id ? globalInfo.corporaById[source_id] : undefined;
    const qualifierClauses = new Set((info === null || info === void 0 ? void 0 : info.qualifier_clauses) || []);
    const argNames = (source_id && globalInfo.allArgumentNames[source_id]) || [];
    return {
        allArgumentNames: Lazy(argNames)
            .filter(a => a.type === ArgumentType.MODIFIER)
            .map(a => a.name)
            .toArray(),
        argColors: Object.assign({ [FixedArgs.TEXT_MATCH]: NEUTRAL_COLOR, [FixedArgs.CONCEPT_1]: CONCEPT_1_COLOR, [FixedArgs.CONCEPT_2]: CONCEPT_2_COLOR, [FixedArgs.CONTEXT_CONCEPT]: CONTEXT_CONCEPT_COLOR, [FixedArgs.RELATION]: RELATION_COLOR }, Object.fromEntries(Lazy(argNames)
            .filter(a => a.type === ArgumentType.MODIFIER)
            .map(a => a.name)
            .toArray()
            .map((arg, idx) => [arg, CATEGORYCAL[idx % CATEGORYCAL.length]]))),
        qualifierClauses,
    };
}
