var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from "antd";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
import { ActionButton } from "../common/buttons/ActionButton";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { BasicMenuRow } from "./BasicMenuRow";
import { Menu } from "./Menu";
import { resolveValue } from "./utils/resolveValue";
function getActionDisabled(d) {
    // an action is disabled if it is itself disabled, or if all its sub-entries are disabled
    return !d || d === Menu.SEPARATOR
        ? false
        : resolveValue(d.disabled)
            ? true
            : !d.subEntries
                ? false
                : (resolveValue(d.subEntries) || []).every(sub => getActionDisabled(sub));
}
export function MenuEntry({ entry, rowProps, }) {
    const { captionClassName, hasIcons, onSelect, refreshMenu, entryClassName } = rowProps;
    const [showingSubmenu] = React.useState(false);
    if (!entry)
        return null;
    if (entry === Menu.SEPARATOR) {
        return (_jsx("div", { className: "w-full h-px my-1 bg-gray-300 opacity-20 self-center" }));
    }
    const { action, disabled, extra } = entry, props = __rest(entry, ["action", "disabled", "extra"]);
    const isSubMenu = !!entry.subEntries;
    const isDisabled = getActionDisabled(entry);
    const entryComponent = (_jsx(BasicMenuRow, Object.assign({}, props, { disabled: isDisabled, hasIcons: hasIcons, className: classes(showingSubmenu && "bg-background_secondary", entryClassName), captionClassName: captionClassName, onMouseDown: isDisabled || entry.subEntries
            ? undefined
            : event => {
                if (!entry.subEntries) {
                    onSelect === null || onSelect === void 0 ? void 0 : onSelect();
                    action === null || action === void 0 ? void 0 : action(event, refreshMenu);
                }
            }, isSubMenu: isSubMenu })));
    const extraArr = Array.isArray(extra) ? extra : extra ? [extra] : [];
    const menuEntry = (_jsxs("div", Object.assign({ className: classes("flex space-x-2 items-center justify-between auto_hide_container ", isDisabled
            ? "cursor-not-allowed opacity-50"
            : "cursor-pointer hover:bg-gray-100") }, { children: [entryComponent, _jsx(Space, { children: extraArr.map((extra, idx) => (_jsx(ActionButton, Object.assign({ className: "auto_hide", onClick: () => {
                        onSelect === null || onSelect === void 0 ? void 0 : onSelect();
                        extra.action();
                    } }, { children: resolveValue(extra.icon) }), idx))) })] })));
    if (entry.subEntries && !isDisabled) {
        const subEntries = resolveValue(entry.subEntries);
        return (_jsx(DropDownMenu, Object.assign({ autoAdjustOverflow: true, trigger: "hover", placement: "right", maxHeight: "20vh", entries: subEntries.map(e => !e
                ? null
                : e === Menu.SEPARATOR
                    ? e
                    : Object.assign(Object.assign({}, e), (e.action && {
                        action(event) {
                            var _a;
                            (_a = e.action) === null || _a === void 0 ? void 0 : _a.call(e, event, refreshMenu);
                            onSelect === null || onSelect === void 0 ? void 0 : onSelect();
                        },
                    }))), align: {
                offset: [-16, 0],
                overflow: { adjustX: true, adjustY: true },
            } }, { children: _jsx("div", { children: menuEntry }) })));
    }
    return menuEntry;
}
