import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { isEmpty, uniq } from "lodash";
import { a_corpusFilterBuilder } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { EXTRA_CONCEPTS_CAPTION } from "../../appState/atomic/queryState/consts/EXTRA_CONCEPTS_CAPTION";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { ConceptType } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useFacetState } from "../../appState/atomic/session/parts/facetState";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { a_addConceptToExtra, a_currentExtraConcepts, a_fullExtendedConceptTree, a_resetExtraConcepts, } from "../../appState/atomic/session/parts/watchlists";
import { CONTEXT_CONCEPT_COLOR_VALS } from "../../appState/context/theme/lf";
import { BrandButton } from "../common/buttons/BrandButton";
import { ConceptsPicker } from "../picker/PickerFields";
import { ConceptFacetWrapper } from "./FacetsForConcepts";
import { FacetsTabContent } from "./FacetsTabContent";
const CAPTION = EXTRA_CONCEPTS_CAPTION();
export function WatchlistFacets({ feedback, setFeedback }) {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const currentExtraConcepts = useAtomValue(a_currentExtraConcepts);
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const extenedConceptTree = useAtomValue(a_fullExtendedConceptTree);
    const resetExtraConcepts = useSetAtom(a_resetExtraConcepts);
    const addConceptToExtra = useSetAtom(a_addConceptToExtra);
    //INFO: a wide open filter
    const corpus_filter = corpusFilterBuilder.toCorpusFilter();
    const [{ conceptClustering }] = useUserConfig();
    const [{ expandedFacetGroups }, facetUpdater] = useFacetState();
    const disabled = invalidCorpusIds(corpus_ids);
    const watchlistEntries = Object.entries(extenedConceptTree).flatMap(([nodeId, conceptMap], idx) => Object.entries(conceptMap).map(([conceptId, concept]) => [nodeId, conceptId]));
    const watchlistFacets = watchlistEntries.map(([nodeId, conceptId], idx) => {
        var _a;
        return (_jsx(ConceptFacetWrapper, { nodeId: nodeId, conceptId: conceptId, clusterKey: ConceptType.EXTENDED, concept: (_a = extenedConceptTree[nodeId]) === null || _a === void 0 ? void 0 : _a[conceptId], argument_name: undefined, index: idx + 1, feedback: feedback, setFeedback: setFeedback }, idx + 1));
    });
    return (_jsx(FacetsTabContent, { header: conceptsPicker(), description: isEmpty(watchlistEntries) &&
            `Run a Research Query to add tangential concepts that might be mentioned in the surrounding document for each result. If any are found, you can select them to filter your results.`, facets: () => _jsx(_Fragment, { children: watchlistFacets }) }));
    function conceptsPicker() {
        const dropDownTrigger = (_jsx("div", Object.assign({ className: "flex justify-center items-center" }, { children: _jsx(BrandButton, Object.assign({ size: "small" }, { children: "Manage Watchlist..." })) })));
        return disabled ? (dropDownTrigger) : (_jsx(ConceptsPicker, { corpus_ids: corpus_ids, disabled: !corpus_ids.length, clustering_mode: conceptClustering, corpus_filter: corpus_filter, title: CAPTION, selected: Object.values(currentExtraConcepts), setSelected: selected => {
                resetExtraConcepts(corpus_ids);
                selected.forEach(concept => addConceptToExtra(corpus_ids, concept));
                selected.length > 0 &&
                    facetUpdater({
                        expandedFacetGroups: uniq(expandedFacetGroups.concat(EXTRA_CONCEPTS_CAPTION())),
                    });
            }, placeholder: "select concept(s)\u2026", tagColorVals: CONTEXT_CONCEPT_COLOR_VALS, noSimilar: true, customDropDownTrigger: dropDownTrigger, placement: "right", inputTrigger: false, dropdownContainerClassName: " " }));
    }
}
