import { Set } from "immutable";
import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import React from "react";
const a_view = atomWithReset(Set([]));
export function useEvidenceView() {
    const [view, setView] = useAtom(a_view);
    const markEvidenceToView = React.useCallback((evidenceIds) => {
        setView(view.union(evidenceIds));
    }, [view, setView]);
    const unmarkEvidenceToView = React.useCallback((evidenceIds) => {
        setView(view.subtract(evidenceIds));
    }, [view, setView]);
    const resetEvidenceMarkedToView = useResetAtom(a_view);
    return {
        view,
        markEvidenceToView,
        unmarkEvidenceToView,
        resetEvidenceMarkedToView,
    };
}
