import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TinyButton } from "../common/buttons/TinyButton";
import { Down, Right } from "../common/icons/Icons";
export function CollapseButton({ className, collapsed, onToggle }) {
    return (_jsx(FacetButton, Object.assign({ className: className, onMouseDown: onToggle &&
            (e => {
                e.preventDefault();
                onToggle();
            }) }, { children: collapsed ? _jsx(Right, {}) : _jsx(Down, {}) })));
}
export function FacetButton(props) {
    return _jsx(TinyButton, Object.assign({}, props, { size: 12, bgClassName: "" }));
}
