import React from "react";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { useShowSelectionMenu } from "../contextMenu/showSelectionMenu";
export function useEvidenceTextSelection() {
    const { suggestEvidence } = useSuggestedEvidence();
    const [{ structuredQuery, findings }] = useCurrentSession();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const currentContextTitle = useCurrentContextTitle();
    const showSelectionMenu = useShowSelectionMenu();
    return React.useCallback(({ evidence, rect, text }) => showSelectionMenu(rect, {
        text,
        findings,
        evidence,
        instance: evidence.instances[0],
        context: evidence.text,
        currentContextTitle,
        structuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        suggestEvidence,
    }), [suggestEvidence, showSelectionMenu, findings, structuredQuery]);
}
