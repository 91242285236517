import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import React from "react";
import { a_activeConstraintIsEmpty, a_constraintModelUtil, } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_concepts } from "../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { TOP_RESULTS_KEY } from "../../appState/atomic/queryState/consts/TOP_RESULTS_KEY";
import { ConceptSource, ConceptType, } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { ConceptFacetWrapper } from "./FacetsForConcepts";
import { FacetsTabContent } from "./FacetsTabContent";
export function FacetsForCRCConcepts() {
    const hasQuery = !useAtomValue(a_activeConstraintIsEmpty);
    return (_jsx(FacetsTabContent, { description: !hasQuery && (_jsx("div", { children: "Run a Research Query to display the concepts that are central to your query, which you can select to filter your results." })), facets: () => _jsx(CRCFacets, {}) }));
}
function CRCFacets() {
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const constraint = useAtomValue(a_activeConstraint);
    const [{ showTopResults }] = useUserConfig();
    const [feedback, setFeedback] = React.useState({});
    const concepts = useAtomValue(a_concepts);
    if (modelUtil.isConstraintEmpty(constraint))
        return null;
    function getNodeIdConceptIdPairs(nodeIds) {
        return nodeIds.flatMap(nodeId => modelUtil
            .getConceptIdsForNodeIds(ConceptSource.MODEL, [nodeId])
            .map(conceptId => [nodeId, conceptId]));
    }
    const sourceConcepts = getNodeIdConceptIdPairs([
        constraint.sourceNodeId,
    ]).map(([nodeId, conceptId]) => ({
        nodeId,
        conceptId,
        conceptKey: ConceptType.SOURCE,
    }));
    if (showTopResults &&
        constraint.is_directed &&
        modelUtil.onlySourceEmpty(constraint)) {
        sourceConcepts.push({
            nodeId: constraint.sourceNodeId,
            conceptId: TOP_RESULTS_KEY,
            conceptKey: ConceptType.SOURCE,
        });
    }
    const targetConcepts = getNodeIdConceptIdPairs([
        constraint.targetNodeId,
    ]).map(([nodeId, conceptId]) => ({
        nodeId,
        conceptId,
        conceptKey: ConceptType.TARGET,
    }));
    if (showTopResults &&
        constraint.is_directed &&
        modelUtil.onlyTargetEmpty(constraint)) {
        targetConcepts.push({
            nodeId: constraint.targetNodeId,
            conceptId: TOP_RESULTS_KEY,
            conceptKey: ConceptType.TARGET,
        });
    }
    const contextConcepts = getNodeIdConceptIdPairs(constraint.contextNodeIds).map(([nodeId, conceptId]) => ({
        nodeId,
        conceptId,
        conceptKey: ConceptType.CONTEXT,
    }));
    const argumentNames = {
        [ConceptType.SOURCE]: constraint.is_directed ? "subject" : undefined,
        [ConceptType.TARGET]: constraint.is_directed ? "object" : undefined,
        [ConceptType.CONTEXT]: undefined,
        [ConceptType.EXTENDED]: undefined, // not used here but needed for type
    };
    const conceptList = [...sourceConcepts, ...targetConcepts, ...contextConcepts];
    const facets = conceptList.map(({ nodeId, conceptId, conceptKey }, idx) => (_jsx(ConceptFacetWrapper, { nodeId: nodeId, conceptId: conceptId, clusterKey: conceptKey, concept: concepts[conceptId], argument_name: argumentNames[conceptKey], index: idx + 1, feedback: feedback, setFeedback: setFeedback }, nodeId + conceptId + idx)));
    return _jsx(_Fragment, { children: facets });
}
