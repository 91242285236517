import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { GenericEvidenceResults } from "../GenericEvidenceResults";
import { commaBigNumbers, sForPlural } from "../utils/utils";
export function EvidenceResults({ results, disabled, evidenceSearch, totalEvidenceCount, filteredBySummary, }) {
    const [{ evidenceGroupedByDocument }] = useCurrentSession();
    const { getFilteredEvidenceIdList, getFilteredDocumentIdList } = useEvidenceSearchUtils();
    const evidenceIdList = getFilteredEvidenceIdList();
    const documentIdList = getFilteredDocumentIdList();
    const evidenceDescription = React.useMemo(() => {
        const description = [
            `${evidenceGroupedByDocument ? "gathered from" : "showing"} the top ${commaBigNumbers(evidenceIdList.length)} out of ${commaBigNumbers(totalEvidenceCount)} total result${sForPlural(totalEvidenceCount)}`,
        ];
        if (evidenceGroupedByDocument) {
            description.unshift(`showing ${commaBigNumbers(documentIdList.length)} document group${sForPlural(documentIdList.length)}`);
        }
        if (filteredBySummary) {
            description.push(`filtered by summary`);
        }
        if (evidenceIdList.length > 1) {
            description.push(`sorted by relevance`);
        }
        return description;
    }, [evidenceIdList, documentIdList, filteredBySummary]);
    return (_jsx("div", Object.assign({ style: { flexGrow: "1", minWidth: "28rem" }, className: "flex flex-col flex-1 text-base" }, { children: _jsx(GenericEvidenceResults, { results: results, disabled: disabled, qualifiers: {
                evidence: evidenceDescription.join(", "),
            }, evidenceSearch: evidenceSearch }) })));
}
