import { isEqual } from "lodash";
import React from "react";
// TODO: Something is wrong with this. I'm getting memo errors I don't get in a pure useEffect.
export function useEffectIfDifferent(effect, dependencies, triggerIfAnyDifferent, triggerOnMount = false) {
    const prior = React.useRef(triggerIfAnyDifferent);
    const forceTrigger = React.useRef(triggerOnMount);
    React.useEffect(() => {
        const firstTime = forceTrigger.current;
        if (firstTime || !isEqual(prior.current, triggerIfAnyDifferent)) {
            forceTrigger.current = false;
            effect(firstTime);
        }
        prior.current = triggerIfAnyDifferent;
    }, dependencies.concat(triggerIfAnyDifferent));
}
