import { Set } from "immutable";
import { useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { TRUE } from "../../utils/text/filters";
import { useAnswer, useSummariesData } from "../results/useSummariesData";
export function useSummariesFilter({ evidenceRequestParams, }) {
    const { evidenceFilter } = useAtomValue(queryStateAtoms.searchParams);
    const { enableAnswerGeneration } = useFlags();
    const [{ showSummary, selectedSummaries, showAnswer, showStructuredQuery }] = useCurrentSession();
    const activeConstraint = useAtomValue(a_activeConstraint);
    const [loadingSummaries, setLoadingSummaries] = React.useState(true);
    const { summaries: maybeSummaries, loading: maybeLoadingSummaries } = useSummariesData(evidenceRequestParams, !showSummary);
    React.useEffect(() => {
        if (showSummary && maybeSummaries.length && !maybeLoadingSummaries) {
            setLoadingSummaries(false);
        }
        else if (showSummary) {
            setLoadingSummaries(true);
        }
    }, [showSummary]);
    React.useEffect(() => {
        if (maybeSummaries.length || !maybeLoadingSummaries) {
            setLoadingSummaries(false);
        }
        else if (maybeLoadingSummaries) {
            setLoadingSummaries(true);
        }
    }, [maybeSummaries, maybeLoadingSummaries]);
    const hasAnswerText = Boolean(activeConstraint.userText || activeConstraint.text);
    const { answer, loading: loadingAnswer } = useAnswer(evidenceRequestParams, !showAnswer || !enableAnswerGeneration || !hasAnswerText);
    const summaries = !evidenceFilter || loadingSummaries ? maybeSummaries : [];
    const filter = React.useMemo(() => {
        const ids = Set(selectedSummaries.map(s => s.evidence_ids).flat());
        return !showSummary || !ids.size ? TRUE : evidence => ids.has(evidence.id);
    }, [showSummary, selectedSummaries]);
    return { summaries, answer, loadingSummaries, loadingAnswer, filter };
}
