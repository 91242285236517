import { isEmpty, partition } from "lodash";
import { EMPTY_CONSTRAINT_MODEL_CONFIG } from "../../../appState/atomic/queryState/types/ConstraintModelConfig";
import { EMPTY_CONSTRAINT_MODEL_SOLUTION } from "../../../appState/atomic/queryState/types/ConstraintModelSolution";
import { createClusterId } from "../../../utils/identity/createClusterId";
import { createRelationId } from "../../../utils/identity/createRelationId";
import { GraphAnalysis } from "../GraphAnalysis";
import { classifyNodes, getActiveRelationOrConcept, getNodeValues, } from "../diagramUtils";
import { ElementType, isRelation } from "../model";
import { getConstraintFromNodes } from "./constraintsFromNodes";
//TODO: duplication of data which requires tranformation
export function diagramToQueryState({ nodes, edges, viewport }, state) {
    const ga = GraphAnalysis(nodes, edges);
    const comps = ga.components().map(ids => ids.map(id => ga.node(id)));
    const [complex, lone] = partition(comps, c => c.length > 1);
    const nodesByType = classifyNodes(nodes);
    //TODO: the following is a quick attempt, may be wrong or inefficient
    const conceptNodes = Object.fromEntries(nodesByType[ElementType.CONCEPT].map(n => [
        n.id,
        getNodeValues(n, false)
            .map(c => createClusterId(c) // TODO: required state: n.data.selection[c.name]?.length === 0
        )
            .filter(c => !isEmpty(c)),
    ]));
    //TODO: missing concepts from overrides and solutions
    const concepts = Object.fromEntries(nodesByType[ElementType.CONCEPT].flatMap(n => getNodeValues(n, false)
        .filter(c => !isEmpty(c))
        .map(c => [createClusterId(c), c])));
    const relationNodes = Object.fromEntries(nodesByType[ElementType.RELATION].map(n => [
        n.id,
        getNodeValues(n, false)
            .map(r => createRelationId(r))
            .filter(r => !isEmpty(r)),
    ]));
    const relations = Object.fromEntries(nodesByType[ElementType.RELATION].flatMap(n => getNodeValues(n, false)
        .filter(r => !isEmpty(r))
        .map(r => [createRelationId(r), r])));
    const qualifiers = Object.fromEntries(nodesByType[ElementType.QUALIFIER].map(n => [
        n.id,
        getNodeValues(n, false)
            .map(c => createClusterId(c) //TODO: required state from diagram
        )
            .filter(c => !isEmpty(c)),
    ]));
    const clauses = Object.fromEntries(nodesByType[ElementType.QUALIFIER].flatMap(n => getNodeValues(n, false)
        .filter(c => !isEmpty(c))
        .map(c => [createClusterId(c), c])));
    const orderedComps = [...sortComponents(complex), ...sortComponents(lone)];
    const constraints = Object.fromEntries(orderedComps
        .reduce((acc, nodes) => {
        acc.push(...componentToConstraints({ nodes, edges }));
        return acc;
    }, [])
        .map(c => [c.id, c]));
    const active = getNodeOrderId(getActiveRelationOrConcept(nodes));
    const constraintModel = {
        activeConstraintId: active ||
            state.constraintModel.activeConstraintId ||
            Object.keys(constraints)[0] ||
            "",
        data: {
            concepts,
            relations,
            clauses,
        },
        state: {
            constraints,
            conceptNodes,
            relationNodes,
        },
        config: Object.assign(Object.assign({}, EMPTY_CONSTRAINT_MODEL_CONFIG), { qualifiers }),
        solution: EMPTY_CONSTRAINT_MODEL_SOLUTION, //TODO:
    };
    return Object.assign(Object.assign({}, state), { constraintModel, modelBuilderState: {
            viewport,
            nodesInfo: Object.fromEntries(nodes.map(({ id, positionAbsolute: { x, y } = { x: 0, y: 0 }, data: { expanded, resized, order }, }) => [
                id,
                Object.assign(Object.assign({ x,
                    y, order: order }, (expanded && { expanded })), (resized && { resized })),
            ])),
        } });
    function sortComponents(comps) {
        return comps
            .map(c => [
            c.sort((a, b) => b.data.order - a.data.order),
            Math.max(...c.map(n => { var _a; return (_a = n.data.order) !== null && _a !== void 0 ? _a : 0; })),
        ])
            .sort((a, b) => b[1] - a[1])
            .map(c => c[0]);
    }
    function componentToConstraints({ nodes, edges, }) {
        return (nodes
            // if component has only one node use node, otherwise use relations
            .filter(n => nodes.length === 1 || isRelation(n))
            .map(n => getConstraintFromNodes(ga.nodeAndNeighborIds(n.id), getNodeOrderId(n), nodes, edges)));
    }
    function getNodeOrderId(n) {
        const order = n === null || n === void 0 ? void 0 : n.data.order;
        return (order !== null && order !== void 0 ? order : "") + "";
    }
}
