import { union } from "../../../../utils/generic/collections";
import { getNamedName } from "./getNamedName";
export function mergeRelationClusters(relations, collapseName = true) {
    return !relations.length
        ? []
        : [
            {
                name: getNamedName(relations),
                ids: union(...relations.map(r => r.ids)),
            },
        ];
}
