import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useEvidenceSearchContext } from "../../appState/context/evidence/EvidenceSearchContext";
import { ToggleButton } from "../common/buttons/ToggleButton";
import { Findings } from "../common/icons/Icons";
import { Rail } from "../common/layout/Rail";
import { useDMParamsDiagramAddOn } from "../constraintFlow/useDMParamsDiagramAddOn";
import { useEvidenceAddOns } from "../results/useEvidenceAddOns";
import { useSummariesAction } from "../summaries/useSummariesAction";
import { useTrendsAddOn } from "../trends/useTrendsAddOn";
import { Label } from "./Label";
import "./Toolbar.less";
export function PanelBar({ argsInfo }) {
    const { evidenceSearch } = useEvidenceSearchContext();
    const { evidenceAndTotals, corpus_filter, evidenceRequestParams } = evidenceSearch;
    const emptyQuery = useAtomValue(a_activeConstraintIsEmpty);
    const { selectors } = useEvidenceAddOns({
        evidenceAndTotals,
        corpus_filter,
        evidenceRequestParams,
        argsInfo,
    }, useDMParamsDiagramAddOn, useTrendsAddOn);
    const summariesSelector = useSummariesAction({ evidenceRequestParams });
    const [{ showMyFindings, showAnswer, showSummary }, sessionUpdater] = useCurrentSession();
    const wideButtonClass = "flex items-center justify-start pl-2";
    const isNarrow = true;
    const topControls = (_jsx("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col space-y-4" }, { children: [_jsxs(ToggleButton, Object.assign({ onClick: () => sessionUpdater({ showMyFindings: !showMyFindings }), tooltip: "Show My Findings", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selected: showMyFindings, selectedClass: "bg-background_secondary" }, { children: [_jsx(Findings, {}), !isNarrow && _jsx(Label, { children: "History" })] })), selectors.map(selector => (_jsxs(ToggleButton, Object.assign({ onClick: selector.onSelect, tooltip: selector.tooltip, width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selected: selector.selected, disabled: selector.disabled || emptyQuery }, { children: [selector.icon, !isNarrow && _jsx(Label, { children: selector.tooltip })] }), selector.key))), _jsxs(ToggleButton, Object.assign({ onClick: () => sessionUpdater({ showAnswer: !showAnswer }), tooltip: "Show Answer", width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selected: showAnswer, selectedClass: "bg-background_secondary", disabled: emptyQuery }, { children: ["A", !isNarrow && _jsx(Label, { children: "Answer" })] })), summariesSelector && (_jsxs(ToggleButton, Object.assign({ onClick: summariesSelector.onSelect, tooltip: summariesSelector.tooltip, width: !isNarrow ? 80 : undefined, className: !isNarrow ? wideButtonClass : undefined, selected: summariesSelector.selected, selectedClass: "bg-background_secondary", disabled: emptyQuery }, { children: [summariesSelector.icon, !isNarrow && _jsx(Label, { children: "Summary" })] })))] })) })));
    return (_jsx(Rail, { style: !isNarrow
            ? {
                width: "96px",
                minWidth: "96px",
                flex: "0 0 0",
            }
            : undefined, className: "border-l", narrow: isNarrow, topControls: topControls }));
}
