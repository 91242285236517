import { getDocumentUrl, openUrl } from "../../api/utils/url";
import { highlightWordsOutsideOfParse } from "../../appState/atomic/evidence/utils/highlightWordsOutsideOfParse";
import { sortMetadata } from "../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useShowSelectionMenu } from "../contextMenu/showSelectionMenu";
export function useUrlOpener({ suggestEvidence, currentContextTitle, findings, structuredQuery, usingHierarchyNav, source, }) {
    const showSelectionMenu = useShowSelectionMenu();
    return (evidence, instance, highlights, disableScrollToHighlight) => {
        openUrl(getDocumentUrl(instance.document_id), instance.corpus_id, instance.document_id, highlights || [...highlightWordsOutsideOfParse(evidence), evidence.text], instance.title, ({ rect, text, docText }) => showSelectionMenu(rect, {
            text,
            suggestEvidence,
            findings,
            evidence,
            instance,
            context: docText,
            currentContextTitle,
            structuredQuery,
            usingHierarchyNav,
            zIndex: 2000,
        }), instance.metadata, sortMetadata(source), instance.external_url, disableScrollToHighlight);
    };
}
