import { keyBy, mapValues } from "lodash";
import React from "react";
import { useShortcuts } from "../../utils/interactions/useShortcuts";
import { Menu } from "./Menu";
import { beep } from "./utils/beep";
import { resolveValue } from "./utils/resolveValue";
function getActionsWithShortcut(mActions) {
    const actions = mActions.filter(Boolean);
    const params = actions.filter(a => a.action && a.shortcut);
    const subParams = actions
        .map(a => a.subEntries || [])
        .flat()
        .filter(a => a !== Menu.SEPARATOR);
    return !subParams.length
        ? params
        : [...params, ...getActionsWithShortcut(subParams)];
}
export function useBindActions(actions, disabled = false, element) {
    const [actionsWithShortcut, actionsByShortcut] = React.useMemo(() => {
        const withShortcut = getActionsWithShortcut(actions);
        return [
            withShortcut,
            mapValues(keyBy(withShortcut, "shortcut"), a => resolveValue(a.disabled) ? () => beep() : a.action),
        ];
    }, [actions]);
    const triggerAction = useShortcuts(actionsByShortcut, disabled, element);
    return [triggerAction, actionsWithShortcut];
}
