var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, } from "@ant-design/icons/lib";
import { Select } from "antd";
import { useAtomValue } from "jotai";
import React from "react";
import { useSuggestedEvidenceFor } from "../../appState/atomic/evidence/useSuggestedEvidenceFor";
import { a_corpusFilterBuilder } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { useRestoreQuery } from "../../appState/atomic/queryState/useQueryState";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { addEvidenceToFinding, restoreFindingContext, updateFindingPayload, upsertFinding, } from "../../appState/context/findings/findings";
import { useSelectionChange } from "../../utils/dom/useSelectionChange";
import { sendImplicitEvidenceFeedback } from "../../utils/feedback/sendImplicitEvidenceFeedback";
import { ActionButton } from "../common/buttons/ActionButton";
import { ButtonWrapper } from "../common/buttons/ButtonWrapper";
import { Link, Robot } from "../common/icons/Icons";
import { Card } from "../common/layout/Card";
import { FocusContainer } from "../common/layout/FocusContainer";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { showNotification } from "../common/status/notifications";
import { DefaultMarkDown } from "../common/text/MarkDown";
import { showAttachSuggestedEvidence } from "../modals/AttachSuggestedEvidence";
import { showFindingEditor } from "../modals/FindingEditorModal";
import { EvidenceSummaryBanner } from "../results/banners/EvidenceSummaryBanner";
import { SuggestedEvidenceBanner } from "../results/banners/SuggestedEvidenceBanner";
//TODO: get rid of contextTitles
export function FindingCard({ item, allTags, highlightText, suggestQuestions, disabled, className, onTextSelected, }) {
    const restoreQuery = useRestoreQuery();
    const [, sessionUpdater] = useCurrentSession();
    const evidence = item.payload.evidence || [];
    const [suggested] = useSuggestedEvidenceFor(item.id);
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const corpus_filter = corpusFilterBuilder.fullFilter().toCorpusFilter();
    // const containerId = useUniqId(uuidv4)
    useSelectionChange(!item.payload.selectedText.length, item.id, item.payload.selectedText, onTextSelected);
    const addEvidenceToFindingInSession = (finding, evidence) => sessionUpdater(({ findings }) => ({
        findings: findings.map(f => f.id !== finding.id ? f : addEvidenceToFinding(f, evidence)),
    }));
    function showAddEvidence() {
        showAttachSuggestedEvidence({
            finding: item,
            addEvidenceToFinding: addEvidenceToFindingInSession,
            onSelect(selected) {
                return __awaiter(this, void 0, void 0, function* () {
                    addEvidenceToFindingInSession(item, selected);
                    yield sendImplicitEvidenceFeedback(selected, corpus_filter);
                    showNotification("Evidence Attached");
                });
            },
        });
    }
    return (_jsx(Card, { id: item.id, className: className, title: _jsxs("div", Object.assign({ className: "flex flex-col font-bold" }, { children: [_jsx("span", { children: item.title || item.context.contextTitle }), _jsx(DefaultMarkDown, { text: item.payload.selectedText, separator: highlightText, className: "text-base font-normal" })] })), tags: allTags && (_jsx(FocusContainer, Object.assign({ className: "border-white focus-within:border-gray-500" }, { children: _jsx(Select, Object.assign({ mode: "tags", size: "small", bordered: false, allowClear: true, style: { width: "100%" }, placeholder: "No Tags\u2026", value: item.payload.tags, onChange: tags => sessionUpdater(({ findings }) => ({
                    findings: upsertFinding(findings, updateFindingPayload(item, { tags })),
                })) }, { children: allTags })) }))), banners: _jsx(ButtonWrapper, Object.assign({ className: "flex-1", onClick: () => showFindingEditor(item, false, suggestQuestions, disabled) }, { children: _jsx(EvidenceSummaryBanner, { evidence: evidence }) })), extra: _jsx(SuggestedEvidenceBanner, { evidence: evidence, suggested: suggested, onClick: showAddEvidence }), actions: _jsx(_Fragment, { children: _jsx(DropDownMenu, Object.assign({ entries: () => [
                    {
                        caption: "Edit",
                        icon: _jsx(EditOutlined, {}),
                        action() {
                            showFindingEditor(item, false, suggestQuestions, disabled);
                        },
                    },
                    {
                        caption: "Remove",
                        icon: _jsx(DeleteOutlined, {}),
                        action() {
                            sessionUpdater(({ findings }) => ({
                                findings: findings.filter(f => f.id !== item.id),
                            }));
                        },
                    },
                    {
                        caption: "Suggest Evidence",
                        disabled,
                        icon: _jsx(Robot, {}),
                        action: showAddEvidence,
                    },
                    {
                        caption: "Go to Query",
                        disabled,
                        icon: _jsx(Link, {}),
                        action() {
                            restoreFindingContext(item.context, restoreQuery);
                        },
                    },
                ] }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })) }) }));
}
