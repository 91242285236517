import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Virtuoso } from "react-virtuoso";
import { useEvidenceFeedback } from "../../appState/atomic/evidence/useEvidenceFeedback";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { classes } from "../../appState/context/theme/classes";
import { VisualStatusMessage } from "../common/status/VisualStatusMessage";
import { OverallFeedback } from "../results/feedbackControls/OverallFeedback";
import { scrollToIndex } from "../../utils/dom/utils/scroll";
export function useEvidenceListPagination(evidenceAndTotals, pageSize, evidenceRequestParams) {
    const { loading, currentPage } = evidenceAndTotals;
    const virtuosoRef = React.useRef(null);
    const { resetEvidenceSelection } = useEvidenceSelection();
    const { resetEvidenceFeedback } = useEvidenceFeedback();
    React.useEffect(() => {
        resetEvidenceSelection();
        resetEvidenceFeedback();
        scrollToIndex(virtuosoRef.current, 0);
    }, [evidenceRequestParams, loading]);
    function listRender(itemRender, items) {
        return (_jsx("div", Object.assign({ className: "h-full flex-1" }, { children: _jsx(Virtuoso, { data: items, className: classes("h-full", "flex items-stretch relative overflow-y-auto overflow-x-hidden", "flex-col"), totalCount: items.length, itemContent: idx => _jsx(_Fragment, { children: itemRender(items[idx], idx, items) }), followOutput: false, ref: virtuosoRef, endReached: evidenceAndTotals.nextPage }) })));
    }
    const paginationFooter = (_jsx("div", Object.assign({ className: "mt-1 flex flex-col items-stretch" }, { children: _jsxs("div", Object.assign({ className: "flex justify-between", style: { paddingBottom: 1 } }, { children: [_jsx(OverallFeedback, {}), _jsx("div", Object.assign({ className: "flex" }, { children: !evidenceAndTotals.lastPage && evidenceAndTotals.loading && (_jsx(VisualStatusMessage, { status: "loading", message: "Loading more...", className: "text-tiny" })) }))] })) })));
    return { listRender, paginationFooter, virtuosoRef };
}
