var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { uniq } from "lodash";
import React from "react";
import { useEvidenceHighlights } from "../../appState/atomic/evidence/useEvidenceHighlights";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { useEvidenceView } from "../../appState/atomic/evidence/useEvidenceView";
import { classes } from "../../appState/context/theme/classes";
import { useSelectionChange } from "../../utils/dom/useSelectionChange";
import { useUniqId } from "../../utils/identity/useUniqId";
import { uuidv4 } from "../../utils/identity/uuidv4";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { ReferenceQualifier } from "./ReferenceQualifier";
import { TextWithEvidenceReferences } from "./TextWithEvidenceReferences";
export function SummaryCard(_a) {
    var { summary, summaryIndex, summaryCount, onSelect, className, selected, otherSelected, disabled, hidden, 
    //urlOpenerFn,
    filterText, actions, extraItemActions, 
    //summaryArgColorGetter,
    renderAsHtml, onRemove, showSurrounding, 
    //onMetadataClick,
    onSummaryTextSelected, actionsTooltip, footer, getEvidenceWithPosition, addFilter, clearFilters } = _a, props = __rest(_a, ["summary", "summaryIndex", "summaryCount", "onSelect", "className", "selected", "otherSelected", "disabled", "hidden", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onSummaryTextSelected", "actionsTooltip", "footer", "getEvidenceWithPosition", "addFilter", "clearFilters"]);
    const containerId = useUniqId(uuidv4);
    useSelectionChange(!summary, containerId, summary.summary, onSummaryTextSelected &&
        (({ text, rect }) => onSummaryTextSelected({ summary, rect, text })));
    const { resetEvidenceSelection, selectEvidence } = useEvidenceSelection();
    const { markEvidenceToView } = useEvidenceView();
    const { resetEvidenceHighlight, highlightEvidence, unhighlightEvidence } = useEvidenceHighlights();
    const select = () => {
        clearFilters();
        onSelect && !selected && onSelect(summary, true);
        resetEvidenceHighlight();
    };
    const clear = () => {
        clearFilters();
        onSelect && onSelect(summary, false);
        resetEvidenceHighlight();
    };
    const uniqueReferenceIds = uniq(summary.references.map(r => r.evidence_id));
    const uniqueReferenceCount = uniq(summary.references.map(r => r.evidence_id)).length;
    if (hidden)
        return null;
    return (_jsx("div", Object.assign({ className: classes("flex flex-col items-stretch rounded-lg text-base bg-white border px-4 py-3 font-sans mb-1 mr-1", disabled
            ? "opacity-50"
            : selected
                ? "bg-accent-50 border-accent-300"
                : "bg-white border-neutral-300 hover:border-accent-50", className), style: {
            position: selected ? "sticky" : "relative",
            top: 0,
            bottom: 0,
            zIndex: selected ? 1 : undefined,
        } }, props, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-between" }, { children: [_jsx("div", Object.assign({ className: classes("font-bold grow", onSelect && "cursor-pointer"), onClick: select }, { children: _jsx("div", { children: summary.cluster_name }) })), selected ? (_jsx(BorderlessButton, Object.assign({ className: "underline", onClick: clear }, { children: "Clear" }))) : (_jsx(BorderlessButton, Object.assign({ className: "underline", onClick: select }, { children: "Filter Evidence" })))] })), _jsx(ReferenceQualifier, { disableHighlights: false, disableFiltering: false, disableGroupSelect: otherSelected, referenceCount: uniqueReferenceCount, highlightReferences: () => {
                        resetEvidenceHighlight();
                        highlightEvidence(summary.evidence_ids);
                    }, selectReferences: () => {
                        resetEvidenceSelection();
                        selectEvidence(uniqueReferenceIds);
                        markEvidenceToView(uniqueReferenceIds);
                    }, filterByReferences: () => {
                        select();
                        addFilter((e) => uniqueReferenceIds.includes(e.id));
                    }, considerationCount: summary.evidence_ids.length, highlightConsiderations: () => {
                        resetEvidenceHighlight();
                        highlightEvidence(summary.evidence_ids);
                    }, selectConsiderations: () => {
                        resetEvidenceSelection();
                        selectEvidence(summary.evidence_ids);
                        markEvidenceToView(summary.evidence_ids);
                    }, filterByConsiderations: select, clearHighlights: () => resetEvidenceHighlight() }), _jsx("div", Object.assign({ className: classes("flex flex-col items-stretch", "mt-2", "pb-1") }, { children: _jsx("div", Object.assign({ id: containerId }, { children: renderAsHtml ? (_jsx("span", { dangerouslySetInnerHTML: renderAsHtml ? { __html: summary.summary } : undefined })) : (_jsx(TextWithEvidenceReferences, { text: summary.summary, references: summary.references, showReferences: true, showHiddenReferences: true, getEvidenceWithPosition: getEvidenceWithPosition })) })) })), footer && (_jsx("div", Object.assign({ className: "flex w-full border-t border-neutral-300 mt-2 pt-1" }, { children: footer })))] })) })));
}
