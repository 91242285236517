var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { atom, useAtom, useSetAtom } from "jotai";
import { pick } from "lodash";
import React from "react";
import { API } from "../../api/api";
import { useInitializer } from "../../utils/lifecycle/useInitializer";
import { useMemoIfEqual } from "../../utils/lifecycle/useMemoIfEqual";
import { pushError } from "../beacon/errorStream";
import { useQueryState } from "./queryState/useQueryState";
import { IGNORED_SESSION_IDS } from "./session/utils/globalRegistry";
const MODELS_DIRECTORY_ID = "MODELS__DIRECTORY";
const a_models = atom([]);
export function useModels() {
    const [models, update] = useAtom(a_models);
    const [queryState, updateQueryState] = useQueryState();
    const corpus_id = queryState.scope.corpus_ids[0];
    //TODO: fragile, will not type check
    const data = useMemoIfEqual(pick(queryState, ["constraintModel", "nodesInfo", "viewport"]));
    return React.useMemo(() => {
        return {
            models: models.filter(m => corpus_id.includes(m.corpus_id)),
            save(name) {
                return __awaiter(this, void 0, void 0, function* () {
                    const cid = yield API.getUrlId({ data });
                    yield save(models
                        .filter(removeFromModels(name))
                        .concat({ name, cid, timestamp: Date.now(), corpus_id }));
                });
            },
            delete(name) {
                return __awaiter(this, void 0, void 0, function* () {
                    yield save(models.filter(removeFromModels(name)));
                });
            },
            load(e) {
                return __awaiter(this, void 0, void 0, function* () {
                    const data = yield API.getUrlData({ id: e.cid });
                    if (!data) {
                        const error = `Unable to load model "${e.name}"`;
                        pushError({ error, showMessage: true });
                        return;
                    }
                    updateQueryState(data);
                });
            },
        };
        function removeFromModels(name) {
            return (m) => m.name !== name && m.corpus_id !== corpus_id;
        }
        function save(models) {
            return __awaiter(this, void 0, void 0, function* () {
                yield API.upsertUserData({
                    id: MODELS_DIRECTORY_ID,
                    data: {
                        id: MODELS_DIRECTORY_ID,
                        name: MODELS_DIRECTORY_ID,
                        models,
                    },
                });
                update(models);
            });
        }
    }, [corpus_id, data]);
}
export function useInitModels() {
    const setModels = useSetAtom(a_models);
    React.useMemo(() => {
        return IGNORED_SESSION_IDS([MODELS_DIRECTORY_ID]);
    }, []);
    return useInitializer(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        setModels(((_a = (yield API.loadUserData(MODELS_DIRECTORY_ID))) === null || _a === void 0 ? void 0 : _a.models) || []);
    }));
}
