import { cleanupSession } from "./migrators/sessionSrubbers";
import { v1SessionToV2Session } from "./migrators/sessionV1toV2";
import { v2SessionToV3Session } from "./migrators/sessionV2toV3";
import { VersionError, VersionMigrationSystem } from "./VersionMigrationSystem";
const CURRENT_VERSION = 3;
function detectSessionVersion(session) {
    if (session.concepts1 || session.concepts1Override) {
        return 1;
    }
    if (session.extraConceptsByCorpusId) {
        return 2;
    }
    if (session.extraConceptNodeIdsByCorpusId) {
        return 3;
    }
    throw new VersionError("Unknown version");
}
const sessionMigrator = new VersionMigrationSystem();
sessionMigrator.registerMigrator(1, 2, session => {
    const v1Session = session;
    return v1SessionToV2Session(v1Session);
});
sessionMigrator.registerMigrator(2, 3, session => {
    const v2Session = session;
    return v2SessionToV3Session(v2Session);
});
sessionMigrator.registerScrubber(cleanupSession);
export function migrateSession(session) {
    const version = detectSessionVersion(session);
    const result = sessionMigrator.migrate(session, version, CURRENT_VERSION);
    return result;
}
