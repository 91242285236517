var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { notification } from "antd";
import React from "react";
export function showNotification(a, level = "info") {
    const _a = typeof a === "string" ? { message: a, duration: 3 } : a, { message, duration = 3, placement = "bottom" } = _a, props = __rest(_a, ["message", "duration", "placement"]);
    const notificationFunc = level === "error"
        ? notification.error
        : level === "warning"
            ? notification.warning
            : level === "success"
                ? notification.success
                : notification.info;
    notificationFunc(Object.assign(Object.assign({}, props), { message: format(message), duration,
        placement }));
    function format(message) {
        if (typeof message === "string") {
            const lines = message.split("\n");
            return lines.length <= 1 ? (message) : (_jsx("div", { children: lines.map((line, idx) => (_jsx("div", { children: line }, idx))) }));
        }
        return message;
    }
}
