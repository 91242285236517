var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import dagre from "dagre";
import React from "react";
import ReactFlow, { ReactFlowProvider, useNodesInitialized, useReactFlow, } from "reactflow";
import "reactflow/dist/style.css";
import { showDialog } from "../../utils/dialog/dialog";
import { GraphAnalysis } from "./GraphAnalysis";
import { ModelBuilderContext } from "./ModelBuilderContext";
import { noSize } from "./diagramUtils";
import { NODE_TYPES } from "./node/DiagramNode";
const ID_SUFFIX = ":LAYOUT";
function CalcNodeSizes({ nodes: initialNodes, onLayoutNodes }) {
    initialNodes = React.useMemo(() => initialNodes.map(n => (Object.assign(Object.assign({}, n), { id: n.id + ID_SUFFIX }))), []);
    const flow = useReactFlow();
    const nodesInitialized = useNodesInitialized();
    const firstTime = React.useRef(true);
    React.useEffect(() => {
        if (nodesInitialized && firstTime.current) {
            firstTime.current = false;
            onLayoutNodes(flow
                .getNodes()
                .map(n => (Object.assign(Object.assign({}, n), { id: n.id.slice(0, -ID_SUFFIX.length) }))));
        }
    }, [nodesInitialized]);
    return (_jsx("div", Object.assign({ style: { width: 100, height: 100 }, className: "flex items-stretch" }, { children: _jsx(ReactFlow, { onlyRenderVisibleElements: true, className: "flex-1", defaultNodes: initialNodes, nodeTypes: NODE_TYPES }) })));
}
export function computeNodeSizes(nodes) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!nodes.some(noSize))
            return nodes;
        const div = document.createElement("div");
        div.id = "layoutNodesContainer";
        div.style.visibility = "hidden";
        //TODO: do we need to duplicate jotai here?
        return (yield showDialog(onValue => (_jsx(ModelBuilderContext, { children: _jsx(ReactFlowProvider, { children: _jsx(CalcNodeSizes, { nodes: nodes, onLayoutNodes: onValue }) }) }))));
    });
}
export function layoutGraph({ nodes = [], edges = [] }, layout) {
    return __awaiter(this, void 0, void 0, function* () {
        nodes = yield computeNodeSizes(nodes);
        if (layout === "SIZE_ONLY")
            return nodes;
        const graph = GraphAnalysis(nodes, edges).graph();
        graph.setGraph({
            rankdir: layout === "H" ? "LR" : "TB",
            // ranker: "longest-path",
            ranker: "network-simplex",
            // ranker: "tight-tree",
        });
        dagre.layout(graph);
        return nodes.map(node => {
            const { x, y, width, height } = graph.node(node.id);
            const position = {
                x: x - width / 2,
                y: y - height / 2,
            };
            return Object.assign(Object.assign({}, node), { position: position, positionAbsolute: position });
        });
    });
}
export function layoutSelection(selected, horizontal, dUpdater, flow, stage) {
    return __awaiter(this, void 0, void 0, function* () {
        const nodes = flow.getNodes();
        const { edges } = GraphAnalysis(nodes, flow.getEdges());
        const global = selected.length < 2;
        const layoutNodes = yield layoutGraph({
            nodes: global ? nodes : selected,
            edges: edges,
        }, horizontal ? "H" : "V");
        if (global) {
            dUpdater.commit({ nodes: layoutNodes });
            setTimeout(() => {
                flow.fitView({ duration: 200 });
            }, 200);
        }
        else {
            stage({
                nodes: layoutNodes,
            });
        }
    });
}
