import { isHistoryEntry } from "../../atomic/session/utils/history";
import { isLegacyFindingContext } from "../../context/findings/findings";
import { cleanupCoraState } from "./stateScrubbers";
function cleanupFinding(finding) {
    const context = finding.context;
    return !isLegacyFindingContext(context)
        ? finding
        : Object.assign(Object.assign({}, finding), { context: Object.assign(Object.assign({}, context), { coraState: cleanupCoraState(context.coraState) }) });
}
function cleanupHistoryEntry(entry) {
    return isHistoryEntry(entry) ? entry : cleanupCoraState(entry);
}
export function cleanupSession(session) {
    session = Object.assign({}, session);
    return Object.assign(Object.assign({}, session), { queryHistory: session.queryHistory.map(cleanupHistoryEntry), findings: session.findings.map(cleanupFinding) });
}
