import { toggle } from "../../../utils/generic/collections";
export function classes(...classNames) {
    return classNames.filter(Boolean).join(" ");
}
classes.remove = function (classNames, ...toRemove) {
    const set = new Set(toRemove);
    return !classNames
        ? classNames
        : classNames
            .split(/ +/g)
            .filter(c => !set.has(c))
            .join(" ");
};
classes.add = function (classNames, ...toAdd) {
    return classes(classes.remove(classNames, ...toAdd), toAdd.join(" "));
};
classes.toggle = function (classNames, toToggle) {
    return toggle(classes.list(classNames), toToggle).join(" ");
};
classes.set = function (classNames, add, ...toSet) {
    return add
        ? classes.add(classNames, ...toSet)
        : classes.remove(classNames, ...toSet);
};
classes.list = function (classNames) {
    return !classNames ? [] : classNames.split(/ +/g);
};
classes.has = function (classNames, className) {
    return classes.list(classNames).includes(className);
};
