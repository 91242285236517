var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSessionSavedStatus } from "./savedStatus";
import { useCurrentSession, useSessionEffects } from "./session";
import { useSaveRemoteSession } from "./useSaveRemoteSession";
import { useDebouncedEffect } from "./utils/debouncer";
import { saveLocalSession } from "./utils/saveLocalSession";
import { localDebouncer, remoteDebouncer } from "./utils/sessionDebouncers";
const REMOTE_SAVE_INTERVAL_MS = 5000;
const LOCAL_SAVE_INTERVAL_MS = 3000;
export function useSessionSaver() {
    const [session] = useCurrentSession();
    useSessionEffects();
    const { setSavedStatus } = useSessionSavedStatus();
    const saveRemoteSession = useSaveRemoteSession();
    useDebouncedEffect(saveLocalSession, true, { value: session, delay: LOCAL_SAVE_INTERVAL_MS }, localDebouncer);
    useDebouncedEffect((session) => __awaiter(this, void 0, void 0, function* () {
        yield saveRemoteSession(session);
        setSavedStatus(true);
    }), true, { value: session, delay: REMOTE_SAVE_INTERVAL_MS }, remoteDebouncer);
}
