import { getConfig } from "../../config/serverConfig";
import { Auth0AuthWrapper } from "./auth0/Auth0AppWrapper";
import { Auth0CoraProvider } from "./auth0/Auth0CoraProvider";
import { Auth0DefaultLoginRoute } from "./auth0/Auth0DefaultLoginRoute";
import { OIDCAuthWrapper } from "./oidc/OIDCAppWrapper";
import { OIDCCoraProvider } from "./oidc/OIDCCoraProvider";
import { OIDCDefaultLoginRoute } from "./oidc/OIDCDefaultLoginRoute";
export function useAuthComponents() {
    const authMode = getConfig("AUTH_MODE", "auth0");
    if (authMode === "oidc") {
        return {
            AuthWrapper: OIDCAuthWrapper,
            CoraAuthProvider: OIDCCoraProvider,
            DefaultLoginRoute: OIDCDefaultLoginRoute,
        };
    }
    return {
        AuthWrapper: Auth0AuthWrapper,
        CoraAuthProvider: Auth0CoraProvider,
        DefaultLoginRoute: Auth0DefaultLoginRoute,
    };
}
