import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
export function PageAlert() {
    const { pageAlertLabel, pageAlertMessage, pageAlertColor, pageAlertTextColor } = useFeatureFlags();
    if (!pageAlertMessage)
        return null;
    const backgroundColor = pageAlertColor || "red";
    const color = pageAlertTextColor || "white";
    return (_jsxs("div", Object.assign({ className: "flex items-center px-2 py-1 text-base select-none", style: { color, backgroundColor } }, { children: [pageAlertLabel && (_jsx("span", Object.assign({ className: "font-bold mr-1" }, { children: pageAlertLabel }))), _jsx("span", { children: pageAlertMessage })] })));
}
