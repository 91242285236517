import { ConstraintModelUtil } from "../../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { EMPTY_VIEWPORT } from "../../../appState/atomic/queryState/consts/EMPTY_QUERY_STATE";
import { mergeNamedMembersToList } from "../../../appState/atomic/queryState/semanticSearch/mergeNamedMembers";
import { useQueryState } from "../../../appState/atomic/queryState/useQueryState";
import { getConstraintsContaining } from "../../../appState/atomic/queryState/utils/getConstraintsContaining";
import { EMPTY_CONSTRAINT_MODEL, EMPTY_QUERY_STATE, } from "../../../appState/verions/V3_QueryState";
import { diagramToQueryState } from "./diagramToQueryState";
function filterQueryState(state, filter) {
    return EMPTY_QUERY_STATE;
    /*
    const dmParams = filterConstraintModel(state.constraintModel, filter)
    const refIds = getReferredIds(dmParams).toArray()
    return {
      ...state,
      dmParams,
      activeConstraintId: !getConstraint(dmParams, state.activeConstraintId)
        ? ""
        : state.activeConstraintId,
      overrides: ALL_CONCEPT_KEYS.reduce(
        (acc, id) => ({...acc, [id]: pick(state.overrides[id], refIds)}),
        {}
      ) as typeof state.overrides,
      nodesInfo: pick(state.nodesInfo, refIds),
    }
    */
}
function filterConstraintModel(model, filter) {
    return EMPTY_CONSTRAINT_MODEL;
    /*
    const filteredIds = Object.values(model.constraints)
      .filter(filter)
      .map(c => c.id)
    const constraints = pick(model.constraints, filteredIds)
    const ref = getReferredIds(constraints).toArray()
  
    return {
      concepts: pick(params.concepts, ref),
      relations: pick(params.relations, ref),
      clauses: pick(params.clauses, ref),
      constraints,
    }
    */
}
export function useDiagramToConjunctive() {
    const [queryState] = useQueryState();
    return (node, nodes, edges, prefix = "") => diagramToConjunctive(queryState, node, nodes, edges, prefix);
}
export function diagramToConjunctive(state, node, nodes, edges, prefix = "") {
    const diagramQS = diagramToQueryState({ nodes, edges, viewport: EMPTY_VIEWPORT }, state);
    const modelUtil = new ConstraintModelUtil(diagramQS.constraintModel);
    const dmParams = modelUtil.toDMParams();
    const overrides = modelUtil.toPsuedoOverrides();
    const nodeConstraints = new Set(getConstraintsContaining(dmParams.constraints, node.id));
    const qs = filterQueryState(diagramQS, c => nodeConstraints.has(c.id));
    const concepts = Object.fromEntries(Object.entries(dmParams.concepts)
        .filter(([id]) => id !== node.id && overrides[id])
        .map(([id]) => [
        id,
        mergeNamedMembersToList(Object.values(overrides[id]).flat())[0],
    ]));
    const { relations, clauses } = dmParams;
    const types = Object.fromEntries(Object.entries(dmParams.concepts)
        .filter(([id]) => id === node.id || !overrides[id])
        .map(([id, cs]) => [id, cs.members.map(m => m.id)]));
    const bindings_for = Object.keys(types)
        .sort()
        .map(id => (id === node.id && prefix.length ? { id, prefix } : { id }));
    return {
        concepts,
        relations,
        clauses,
        types,
        constraints: dmParams.constraints.map(c => !c.relation || relations[c.relation] ? c : Object.assign(Object.assign({}, c), { relation: null })),
        bindings_for,
    };
}
