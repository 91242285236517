export var Conjunction;
(function (Conjunction) {
    Conjunction["AND"] = "AND";
    Conjunction["OR"] = "OR";
})(Conjunction || (Conjunction = {}));
export var ArgType;
(function (ArgType) {
    ArgType["CONCEPT"] = "CONCEPT";
    ArgType["TYPE"] = "TYPE";
})(ArgType || (ArgType = {}));
export var ArgumentType;
(function (ArgumentType) {
    ArgumentType["CORE"] = "CORE";
    ArgumentType["MODIFIER"] = "MODIFIER";
})(ArgumentType || (ArgumentType = {}));
