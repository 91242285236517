var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Tooltip } from "antd";
import React from "react";
import { FeatureIdentifier, FeedbackType } from "../../api/types/apiTypes";
import { useCurrentSource } from "../../api/useCurrentSource";
import { useEvidenceFeedback } from "../../appState/atomic/evidence/useEvidenceFeedback";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { useEvidenceSearchContext } from "../../appState/context/evidence/EvidenceSearchContext";
import { classes } from "../../appState/context/theme/classes";
import { sendFeedback } from "../../utils/feedback/sendFeedback";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { Finding, FindingFilled, ThumbsDown, ThumbsUp, } from "../common/icons/Icons";
import { showNotification } from "../common/status/notifications";
import { useUrlOpener } from "../evidence/useUrlOpener";
import { FindingsDropdown } from "./findingsControls/FindingsDropdown";
export function useRenderEvidenceActions() {
    const [{ showStructuredQuery, structuredQuery, findings }, sessionUpdater] = useCurrentSession();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const { evidenceSelected } = useEvidenceSelection();
    const { feedback, updateFeedback } = useEvidenceFeedback();
    const { evidenceSearch: { corpus_filter }, } = useEvidenceSearchContext();
    const source = useCurrentSource();
    const { suggestEvidence } = useSuggestedEvidence();
    const corpora = useGlobalInfo().corporaById;
    const currentContextTitle = useCurrentContextTitle();
    const showingStructuredQuery = structuredQuery && showStructuredQuery;
    function sendEvidenceFeedback(item, type, context) {
        return __awaiter(this, void 0, void 0, function* () {
            yield sendFeedback(context
                ? FeatureIdentifier.EVIDENCE_QUALIFIER
                : FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/search/evidence", type, item, corpus_filter, context);
            updateFeedback(item.id, type);
            showNotification("Feedback sent");
        });
    }
    const urlOpenerFn = useUrlOpener({
        suggestEvidence,
        currentContextTitle,
        findings,
        structuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        source,
    });
    return React.useCallback((evidence, documentId) => {
        return (_jsxs(_Fragment, { children: [_jsx(FindingsDropdown, { evidenceIdList: [evidence.id], disabledDropdown: false, renderDropdownAnchor: (openMenu, hasExistingFinding) => {
                        const FindingIcon = hasExistingFinding ? FindingFilled : Finding;
                        return (_jsx(BorderlessButton, Object.assign({ onClick: openMenu, className: "flex flex-1 justify-center items-center hover:bg-gray-300 cursor-pointer rounded-full" }, { children: _jsx(FindingIcon, { className: classes("text-base my-auto px-1 py-1") }) })));
                    } }), _jsx(Tooltip, Object.assign({ title: "More actions" }, { children: _jsx(Dropdown, Object.assign({ menu: {
                            items: [
                                {
                                    label: "View in document",
                                    key: "viewInDoc",
                                    onClick: () => {
                                        urlOpenerFn(evidence, evidence.instances.find(i => {
                                            return i.document_id === documentId;
                                        }));
                                    },
                                    disabled: false,
                                },
                                {
                                    label: (_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("span", { children: "Feedback" }), _jsx(BorderlessButton, Object.assign({ className: classes(feedback.get(evidence.id) ===
                                                    FeedbackType.POSITIVE && "text-alert_green-500", "flex items-center hover:text-alert_green-500"), onClick: () => {
                                                    sendEvidenceFeedback(evidence, FeedbackType.POSITIVE);
                                                } }, { children: _jsx(ThumbsUp, { className: "" }) })), _jsx(BorderlessButton, Object.assign({ className: classes(feedback.get(evidence.id) ===
                                                    FeedbackType.NEGATIVE && "text-alert_red-500", "flex items-center  hover:text-alert_red-500"), onClick: () => {
                                                    sendEvidenceFeedback(evidence, FeedbackType.NEGATIVE);
                                                } }, { children: _jsx(ThumbsDown, { className: "" }) }))] }))),
                                    key: "giveFeedback",
                                },
                            ],
                        } }, { children: _jsx(BorderlessButton, Object.assign({ className: "flex justify-center items-center hover:bg-gray-300 cursor-pointer rounded-full" }, { children: _jsx(EllipsisOutlined, { className: "px-1 py-1" }) })) })) }))] }));
    }, [
        feedback,
        evidenceSelected,
        sendEvidenceFeedback,
        suggestEvidence,
        corpora,
        urlOpenerFn,
    ]);
}
