var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API } from "../../api/api";
import { FeatureIdentifier, FeedbackType, } from "../../api/types/apiTypes";
import { createFeedback } from "./createFeedback";
export function sendImplicitEvidenceFeedback(evidences, corpus_filter) {
    return __awaiter(this, void 0, void 0, function* () {
        yield API.send_feedback({
            feedback: evidences.map(evidence => createFeedback(FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/suggest/findings", FeedbackType.IMPLICIT_POSITIVE, evidence, corpus_filter)),
        });
    });
}
