import { partition } from "lodash";
import { ConstraintModelUtil } from "../../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { EMPTY_VIEWPORT } from "../../../appState/atomic/queryState/consts/EMPTY_QUERY_STATE";
import { CRCDirection } from "../../../appState/atomic/queryState/types/CRCDirection";
import { useQueryState } from "../../../appState/atomic/queryState/useQueryState";
import { EMPTY_QUERY_PARAMS, } from "../../../appState/verions/V1_CoraState";
import { queryParamsToDMParams } from "../../../appState/versionMigration/queryParamsToDMParams";
import { diagramToQueryState } from "./diagramToQueryState";
import { dmParamsToDiagram } from "./dmParamsToDiagram";
export function useDiagramToCorpusFilter() {
    const [queryState] = useQueryState();
    return (node, nodes, edges, possible = {}, excludeIds = []) => diagramToCorpusFilter(queryState, node, nodes, edges, possible, excludeIds);
}
function diagramToCorpusFilter(state, node, nodes, edges, possible = {}, excludeIds = [] // TODO
) {
    var _a;
    const q = diagramToQueryState({ nodes, edges, viewport: EMPTY_VIEWPORT }, state);
    const activeConstraintId = (_a = Object.values(q.constraintModel.state.constraints).find(c => c.sourceNodeId === node.id || c.targetNodeId === node.id)) === null || _a === void 0 ? void 0 : _a.id;
    // TODO: possible?
    const modelUtil = new ConstraintModelUtil(Object.assign(Object.assign({}, q.constraintModel), { activeConstraintId }));
    const constraint = modelUtil.getActiveConstraint() || modelUtil.getFirstConstraint();
    //TODO: not sure about this but may handle TODO above
    return modelUtil
        .buildCorpusFilter(q.scope.corpus_ids, q.scope.aperture, constraint)
        .fullFilter(excludeIds)
        .toCorpusFilter();
}
export function corpusFilterToDiagram(corpus_filter) {
    //TODO: create a function to convert corpus_filter directly to dmParams
    const [params] = queryParamsToDMParams(Object.assign(Object.assign({}, EMPTY_QUERY_PARAMS), remove_corpusFilterToQueryParams(corpus_filter)));
    return dmParamsToDiagram(params);
}
function toRelationCluster({ name, ids }) {
    return {
        name,
        ids,
    };
}
function remove_corpusFilterToQueryParams(corpus_filter) {
    const { context, queries } = corpus_filter;
    const { relation_filter, concept_filter } = (queries === null || queries === void 0 ? void 0 : queries[0]) || {};
    const filter = concept_filter === null || concept_filter === void 0 ? void 0 : concept_filter.filter;
    const noArgNames = !!(filter === null || filter === void 0 ? void 0 : filter.some(c => !c.argument_name));
    const [concepts1, concepts2] = !filter
        ? [[], []]
        : noArgNames
            ? [[filter[0]], filter.length < 1 ? [] : [filter[1]]]
            : partition(concept_filter === null || concept_filter === void 0 ? void 0 : concept_filter.filter, (a) => a.argument_name === "subject");
    const selectedRelations = (relation_filter === null || relation_filter === void 0 ? void 0 : relation_filter.filter.map(toRelationCluster)) || [];
    return Object.assign(Object.assign({}, (!context
        ? { extraConcepts: [], extraConceptsOverride: {} }
        : {
            extraConcepts: context,
            extraConceptsOverride: Object.fromEntries(context.map(c => [c.name, [c]])),
        })), { relations: selectedRelations, concepts1,
        concepts2, crcDirection: noArgNames || !selectedRelations.length
            ? CRCDirection.BOTH
            : CRCDirection.C1C2 });
}
