var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTheme } from "../../appState/context/theme/ThemeContext";
import { classes } from "../../appState/context/theme/classes";
import { NEUTRAL_COLOR_VALS } from "../../appState/context/theme/lf";
import { getTagStyle } from "../../appState/context/theme/themeUtils";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { BrandBadge } from "../Genius";
import { landmarkIdTag } from "../common/assist/useStartTour";
import { FieldsetBorder } from "../common/forms/FieldsetBorder";
import { ValidatedInput } from "../common/forms/InputValidation";
import { Close, Down, Up } from "../common/icons/Icons";
import { DropDown } from "../common/popups/DropDown";
import { TagsList } from "../common/tags/TagsList";
import { showPopupMenu } from "../contextMenu/showPopupMenu";
import { ClusterPicker, } from "./ClustersPicker";
export function ClusterPickerField(_a) {
    var { customDropDownTrigger, placement = "bottomLeft", horizontal, noBorder, size, remote, entityName, bgColor, titleColor, borderColor, activeBorderColor, titleInside, className = "flex-1", suffix, landmarkId, dropdownContainerClassName = "flex-1 flex w-full h-full", dropdownContainerStyle, onDropdownVisibleChange, noWrapper, entityType, extraContent, inputTrigger = true, tooltip } = _a, props = __rest(_a, ["customDropDownTrigger", "placement", "horizontal", "noBorder", "size", "remote", "entityName", "bgColor", "titleColor", "borderColor", "activeBorderColor", "titleInside", "className", "suffix", "landmarkId", "dropdownContainerClassName", "dropdownContainerStyle", "onDropdownVisibleChange", "noWrapper", "entityType", "extraContent", "inputTrigger", "tooltip"]);
    const theme = useTheme();
    const inputRef = React.useRef(null);
    function selectedToSelectionValue(selected, removedVal, isOriginal) {
        return selected.map(s => (Object.assign(Object.assign(Object.assign({}, s), { removed: removedVal !== undefined ? removedVal : false }), { isOriginal: isOriginal !== undefined ? isOriginal : true })));
    }
    const disabled = props.disabled;
    const { tagColorVals, title, selected, setSelected } = props;
    const [initialStatus, setInitialStatus] = React.useState(selectedToSelectionValue(selected));
    const [innerSelection, setInnerSelection] = React.useState({
        selection: [],
        remoteVals: [],
    });
    const [activeTabIndex, setActiveTabIndex] = React.useState(0);
    useEffectIfDifferent(() => {
        setInnerSelection({
            selection: [],
            remoteVals: [],
        });
        setInitialStatus(selectedToSelectionValue(selected));
    }, [selected], [selected]);
    const combinedSelection = React.useMemo(() => {
        const cs = [
            ...initialStatus,
            ...selectedToSelectionValue(innerSelection.selection, false, false),
        ];
        return cs;
    }, [innerSelection.selection, initialStatus]);
    const hasUpdates = React.useMemo(() => {
        return (innerSelection.selection.length > 0 ||
            initialStatus.filter(is => is.removed).length > 0);
    }, [innerSelection.selection, initialStatus]);
    function computeSelectedVals() {
        return combinedSelection.filter(cs => (cs.isOriginal && !cs.removed) || !cs.isOriginal);
    }
    function computeSelectedRemoteVals() {
        const combinedNames = computeSelectedVals().map(c => c.name);
        return innerSelection.remoteVals.filter(rv => combinedNames.includes(rv.name));
    }
    function clearSelection() {
        setInnerSelection({
            selection: [],
            remoteVals: innerSelection.remoteVals,
        });
        setInitialStatus(selectedToSelectionValue(selected, true));
    }
    function reset() {
        setInnerSelection({
            selection: [],
            remoteVals: [],
        });
        setInitialStatus(selectedToSelectionValue(selected));
        setSearchValue("");
    }
    const [showingDropDown, setShowingDropDown] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const summary = React.useMemo(() => {
        const removed = initialStatus.filter(is => is.removed).length;
        const kept = initialStatus.length - removed;
        const added = innerSelection.selection.length;
        const selectedCount = kept + added;
        const addedStr = `${selectedCount} selected`;
        const removedStr = `${removed} removed`;
        const sol = [
            ...(selectedCount ? [addedStr] : []),
            ...(removed ? [removedStr] : []),
        ];
        return sol.join(", ");
    }, [selected, innerSelection.selection, initialStatus]);
    React.useEffect(() => {
        var _a;
        showingDropDown && ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus());
    }, [showingDropDown]);
    const validatedInput = (_jsx(ValidatedInput, { ref: inputRef, disabled: disabled, placeholder: "Type Here", className: classes(inputTrigger ? "crcInput px-0" : "pb-2 border-0 text-white"), value: searchValue, onChange: setSearchValue, bordered: inputTrigger ? false : true, clearIcon: _jsx("div", Object.assign({ className: "flex items-center text-white bg-neutral-900 hover:bg-neutral-800 p-1 rounded" }, { children: _jsx(Close, {}) })), onClick: e => {
            if (showingDropDown) {
                e.preventDefault();
                e.stopPropagation();
            }
        }, autoFocus: showingDropDown }));
    const trigger = customDropDownTrigger || (_jsxs("div", Object.assign({ className: "flex w-full" }, { children: [_jsx("div", Object.assign({ className: "flex items-center w-[calc(100%-35px)] pl-1" }, { children: showingDropDown || selected.length === 0 ? (validatedInput) : selected.length > 0 ? (_jsx("div", Object.assign({ className: "flex justify-between items-center mr-6 w-full" }, { children: _jsx(TagsList, { items: selected, closable: !disabled, className: classes("flex-1 overflow-hidden w-full font-semibold"), tagClassName: disabled ? "cursor-not-allowed" : undefined, tagContent: s => props.nameRenderer ? props.nameRenderer(s) : s.name, tagStyle: () => tagColorVals && getTagStyle(tagColorVals[500]), showMoreAction: false, onClose: item => {
                            setSelected(selected.filter(it => it !== item), innerSelection.remoteVals);
                        }, maxTags: 1 }) }))) : null })), _jsx("div", Object.assign({ className: classes("border-l border-l-neutral-1000 flex items-center cursor-pointer ml-2", showingDropDown && "cursor-not-allowed") }, { children: showingDropDown ? (_jsx(Up, { className: "px-2 py-2" })) : (_jsx(Down, { className: "px-2 py-2" })) })), suffix] })));
    const dropDown = (_jsx(DropDown, Object.assign({ modal: true, manualHide: true, onVisibleChange: isNowVisible => {
            setShowingDropDown(isNowVisible);
            if (!isNowVisible)
                reset();
        }, disabled: disabled, placement: placement, 
        //    overlayWidth={675}
        //  overlayHeight={500}
        containerClassName: classes(dropdownContainerClassName, disabled && "opacity-100"), containerStyle: dropdownContainerStyle, getContent: hide => {
            return (_jsx(ClusterPicker, Object.assign({}, props, { selected: innerSelection.selection, setSelected: (selection, remoteVals) => {
                    setInnerSelection({
                        selection: selection,
                        remoteVals,
                    });
                }, clearSelection: clearSelection, hideDropdown: commit => {
                    if (commit) {
                        setSelected(computeSelectedVals(), computeSelectedRemoteVals());
                    }
                    else {
                        reset();
                    }
                    setShowingDropDown(false);
                    hide();
                }, activeTabIndex: activeTabIndex, entityName: entityName, entityType: entityType, filterVal: searchValue, filterComponent: inputTrigger ? undefined : validatedInput, summary: summary, extraContent: extraContent, hasUpdates: hasUpdates, setInitialStatus: setInitialStatus, combinedSelection: combinedSelection })));
        } }, { children: noWrapper ? (trigger) : (_jsx("div", Object.assign({ className: "w-full", onClick: e => {
                if (showingDropDown) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            } }, { children: trigger }))) })));
    return customDropDownTrigger ? (dropDown) : (_jsx(FieldsetBorder, Object.assign({ fieldTitle: _jsxs("div", Object.assign({ className: "flex" }, { children: [title, " ", remote && _jsx(BrandBadge, { remote: remote })] })), className: classes("relative max-w-[400px]", className), zIndex: showingDropDown ? 1001 : 0, bgColor: bgColor, titleColor: titleColor, borderColor: showingDropDown && tagColorVals
            ? tagColorVals[500]
            : NEUTRAL_COLOR_VALS[800], titleInside: titleInside, onContextMenuCapture: props.popupMenuEntries &&
            (e => {
                e.preventDefault();
                showPopupMenu({
                    top: e.clientY,
                    left: e.clientX,
                    entries: props.popupMenuEntries ? props.popupMenuEntries() : [],
                });
            }), onMouseDown: e => {
            if (showingDropDown) {
                e.stopPropagation();
                e.preventDefault();
            }
        } }, landmarkIdTag(landmarkId), { tooltip: tooltip }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex items-center text-gray-500 cursor-pointer w-full justify-between relative top-[-2px]" }, { children: [_jsx("div", Object.assign({ className: "flex-1 w-full" }, { children: dropDown })), suffix] })) })));
}
