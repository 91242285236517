import { atom, useAtomValue } from "jotai";
import { uniq } from "lodash";
import { createClusterId } from "../../../../utils/identity/createClusterId";
import { createRelationId } from "../../../../utils/identity/createRelationId";
import { uuidv4 } from "../../../../utils/identity/uuidv4";
import { ANY_MARKER } from "../consts/ANY";
import { queryStateAtoms } from "../queryStateAtoms";
import { EMPTY_CONSTRAINT_MODEL_CONFIG } from "../types/ConstraintModelConfig";
import { EMPTY_CONSTRAINT_MODEL_SOLUTION } from "../types/ConstraintModelSolution";
import { TwoKey } from "../types/DoubleDict";
import { findFirstConstraint } from "./constraintOrdering";
export function dmParamsToConstraintModel(dmParams, overridesWithNameKeys = {}) {
    const nodeIdToSourceTargetList = {};
    const nodeIdToContextEntry = {};
    //data
    const concepts = {};
    const relations = {};
    const clauses = {};
    //state
    const conceptNodes = {};
    const relationNodes = {};
    //config
    let overrides = {};
    const requiredConcepts = {};
    const qualifiers = {};
    const requiredQualifiers = {};
    function processConcept(nodeId, origConceptId, overridesByName) {
        const concept = getConceptFromModel(origConceptId);
        if (!concept) {
            return;
        }
        const conceptId = createClusterId(concept);
        const conceptOverrides = overridesByName[concept.name] || [];
        const overrideConceptIds = conceptOverrides.map(override => {
            const oConceptId = createClusterId(override);
            concepts[oConceptId] = override;
            return oConceptId;
        });
        overrides = TwoKey.set(overrides, nodeId, conceptId, overrideConceptIds);
        conceptNodes[nodeId] = uniq([...(conceptNodes[nodeId] || []), conceptId]);
        concepts[conceptId] = concept;
    }
    const constraintList = dmParams.constraints.map(constraint => {
        // source concept
        const prevNodeIdForSource = getIdFromSourceTargetList(constraint.sources);
        const sourceNodeId = prevNodeIdForSource || uuidv4();
        if (!prevNodeIdForSource) {
            nodeIdToSourceTargetList[sourceNodeId] = constraint.sources;
        }
        const sourceOverrideKey = constraint.sources.join("/");
        const sourceOverridesByName = overridesWithNameKeys[sourceOverrideKey] || {};
        constraint.sources.forEach(originalConceptId => processConcept(sourceNodeId, originalConceptId, sourceOverridesByName));
        //relation
        const relationNodeId = uuidv4();
        const nodeRelations = constraint.relation ? [constraint.relation] : [];
        nodeRelations.forEach(origRelationId => {
            const relation = getRelationFromModel(origRelationId);
            if (!relation)
                return;
            const relationId = createRelationId(relation);
            relationNodes[relationNodeId] = uniq([
                ...(relationNodes[relationNodeId] || []),
                relationId,
            ]);
            relations[relationId] = relation;
        });
        //target concept
        const prevNodeIdForTarget = getIdFromSourceTargetList(constraint.targets);
        const targetNodeId = prevNodeIdForTarget || uuidv4();
        if (!prevNodeIdForTarget) {
            nodeIdToSourceTargetList[targetNodeId] = constraint.targets;
        }
        const targetOverrideKey = constraint.targets.join("/");
        const targetOverridesByName = overridesWithNameKeys[targetOverrideKey] || {};
        constraint.targets.forEach(originalConceptId => processConcept(targetNodeId, originalConceptId, targetOverridesByName));
        //context
        const overrideKey = constraint.context.join("/");
        const contextOverridesByName = overridesWithNameKeys[overrideKey] || {};
        const contextNodeIds = constraint.context
            .map(origContextId => {
            const contextNodeId = getIdFromContextEntry(origContextId) || uuidv4();
            processConcept(contextNodeId, origContextId, contextOverridesByName);
            return contextNodeId;
        })
            .filter(Boolean);
        //qualifiers
        const qualifierNodeEntries = Object.entries(constraint.qualifiers)
            .map(([qualifierKey, origQualifierId]) => {
            if (origQualifierId === ANY_MARKER) {
                return [qualifierKey, ANY_MARKER];
            }
            const qualifier = getClauseFromModel(origQualifierId);
            if (!qualifier)
                return;
            const qualifierNodeId = uuidv4();
            const qualifierId = createClusterId(qualifier);
            qualifiers[qualifierNodeId] = [qualifierId]; // only one clause per qualifier
            clauses[qualifierId] = qualifier;
            return [qualifierKey, qualifierNodeId];
        })
            .filter(Boolean);
        const qualifierNodeIds = Object.fromEntries(qualifierNodeEntries);
        return {
            id: uuidv4(),
            sourceNodeId,
            relationNodeId,
            targetNodeId,
            contextNodeIds,
            qualifierNodeIds,
            is_directed: constraint.is_directed,
            text: constraint.text,
        };
    });
    const constraints = constraintList.reduce((acc, constraint) => {
        acc[constraint.id] = constraint;
        return acc;
    }, {});
    const firstConstraint = findFirstConstraint(constraintList);
    const activeConstraintId = (firstConstraint === null || firstConstraint === void 0 ? void 0 : firstConstraint.id) || undefined;
    // TODO: potentially matches incorrect nodes, but we work with what we get
    function getIdFromSourceTargetList(slotList) {
        if (!slotList.length)
            return null;
        const result = Object.entries(nodeIdToSourceTargetList).reduce((acc, [nodeId, slots]) => {
            if (slotList.every(slot => slots.includes(slot))) {
                return nodeId;
            }
            return acc;
        }, null);
        return result;
    }
    return {
        activeConstraintId,
        data: {
            concepts,
            relations,
            clauses,
        },
        state: {
            conceptNodes,
            relationNodes,
            constraints,
        },
        config: Object.assign(Object.assign({}, EMPTY_CONSTRAINT_MODEL_CONFIG), { overrides,
            requiredConcepts,
            qualifiers,
            requiredQualifiers }),
        solution: EMPTY_CONSTRAINT_MODEL_SOLUTION,
    };
    function getIdFromContextEntry(context) {
        return Object.entries(nodeIdToContextEntry).reduce((acc, [nodeId, contextEntry]) => {
            if (context === contextEntry) {
                return nodeId;
            }
            return acc;
        }, null);
    }
    function getConceptFromModel(conceptId) {
        const conceptFromModel = dmParams.concepts[conceptId];
        return Array.isArray(conceptFromModel)
            ? conceptFromModel[0]
            : conceptFromModel;
    }
    function getRelationFromModel(relationId) {
        const relationFromModel = dmParams.relations[relationId];
        return Array.isArray(relationFromModel)
            ? relationFromModel[0]
            : relationFromModel;
    }
    function getClauseFromModel(clauseId) {
        const clauseFromModel = dmParams.clauses[clauseId];
        return Array.isArray(clauseFromModel)
            ? clauseFromModel[0]
            : clauseFromModel;
    }
}
export const a_modelFromDMParams = atom(null, (get, set, dmParams, modelId) => {
    const model = dmParamsToConstraintModel(dmParams);
    set(queryStateAtoms.constraintModel, prevModel => {
        // COR-7748: preserve metadata
        // TODO: seems dodgy since it overwrites the incoming metadata
        model.config.booleanMetadata = prevModel.config.booleanMetadata;
        model.config.rangeMetadata = prevModel.config.rangeMetadata;
        model.config.keywordMetadata = prevModel.config.keywordMetadata;
        return Object.assign(Object.assign({}, model), { activeModelId: modelId });
    });
});
export function useActiveModelId() {
    return useAtomValue(queryStateAtoms.constraintModel).activeModelId;
}
