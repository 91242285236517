import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { TitledContainer } from "./common/layout/TitledContainer";
import { ValidationIcon } from "./common/forms/InputValidation";
import { CollapseButton } from "./facet/facetButtons";
import { classes } from "../appState/context/theme/classes";
export function CollapsableSection({ onToggle, collapsed, title, error, children, }) {
    const headerLeft = (_jsxs("div", Object.assign({ className: classes("flex space-x-2 items-cente", onToggle && "cursor-pointer"), onClick: onToggle }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-2 items-center" }, { children: [_jsx("div", { children: title }), _jsx(ValidationIcon, { error: error })] })), onToggle && _jsx(CollapseButton, { collapsed: collapsed, className: "mt-1" })] })));
    return (_jsx(TitledContainer, Object.assign({ headerLeft: headerLeft, noScroll: true, containerStyleClass: " ", bgClassName: "bg-transparent" }, { children: !collapsed && children })));
}
