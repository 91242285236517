var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAtomValue } from "jotai";
import { mapKeys, mapValues } from "lodash";
import React from "react";
import { useReactFlow } from "reactflow";
import { API } from "../../../api/api";
import { a_modelSolutions } from "../../../appState/atomic/domainModel/modelState";
import { ANY_MARKER } from "../../../appState/atomic/queryState/consts/ANY";
import { queryStateAtoms } from "../../../appState/atomic/queryState/queryStateAtoms";
import { invalidCorpusIds } from "../../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { useDiagramToCorpusFilter } from "../adapters/corpusFilterAdapters";
import { useDiagramToConjunctive } from "../adapters/diagramToConjunctive";
import { isConcept, isQualifier } from "../model";
const DEF_COUNT = 30;
function IdReplacer() {
    const ids = new Map();
    const replacer = function (id) {
        if (!ids.has(id))
            ids.set(id, `ID-${ids.size}`);
        return ids.get(id);
    };
    replacer.reset = () => ids.clear();
    return replacer;
}
// Replaces and sort all ids in the query with new ids generated by the replacer function
// This is done so if the only difference between two queries is the ids, or the order,
// they will be considered equal.
function normalizeConjunctiveQuery(q, replacer) {
    return {
        types: sortByKey(mapKeys(q.types, (_, id) => replacer(id))),
        concepts: sortByKey(mapKeys(q.concepts, (_, id) => replacer(id))),
        relations: sortByKey(mapKeys(q.relations, (_, id) => replacer(id))),
        clauses: sortByKey(mapKeys(q.clauses, (_, id) => replacer(id))),
        bindings_for: q.bindings_for
            .map(b => typeof b === "string" ? replacer(b) : Object.assign(Object.assign({}, b), { id: replacer(b.id) }))
            .sort(tOrSComparator),
        constraints: q.constraints
            .map(c => {
            var _a, _b, _c;
            return (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, c), { relation: c.relation ? replacer(c.relation) : null }), (((_a = c.sources) === null || _a === void 0 ? void 0 : _a.length) && {
                sources: c.sources.map(id => replacer(id)).sort(),
            })), (((_b = c.targets) === null || _b === void 0 ? void 0 : _b.length) && {
                targets: c.targets.map(id => replacer(id)).sort(),
            })), (((_c = c.context) === null || _c === void 0 ? void 0 : _c.length) && {
                context: c.context.map(id => replacer(id)).sort(),
            })), (c.qualifiers && {
                qualifiers: sortByKey(mapValues(c.qualifiers, v => v === ANY_MARKER ? ANY_MARKER : replacer(v))),
            })));
        })
            .sort(cComparator)
            .map(c => (Object.assign(Object.assign({}, c), { id: replacer(c.id) }))),
    };
    function sortByKey(d) {
        return Object.fromEntries(Object.entries(d).sort(([a], [b]) => a.localeCompare(b)));
    }
    function tOrSComparator(a, b) {
        if (typeof a === "string") {
            if (typeof b === "string")
                return a.localeCompare(b);
            return -1;
        }
        if (typeof b === "string")
            return 1;
        return a.id.localeCompare(b.id);
    }
    function cComparator(a, b) {
        return toStr(a).localeCompare(toStr(b));
        function toStr(c) {
            var _a, _b, _c, _d;
            return `${(_a = c.sources) === null || _a === void 0 ? void 0 : _a.join(",")}-${c.relation}-${(_b = c.targets) === null || _b === void 0 ? void 0 : _b.join(",")}:${(_c = c.context) === null || _c === void 0 ? void 0 : _c.join(",")}:${(_d = Object.keys(c.qualifiers || {})) === null || _d === void 0 ? void 0 : _d.join(",")}:${c.is_directed}`;
        }
    }
}
export function useLoader({ node, }) {
    const flow = useReactFlow();
    const [{ conjunctionBindingsSearchWidth }] = useCurrentSession();
    const replacer = React.useRef(IdReplacer()).current;
    const { corpus_ids, aperture } = useAtomValue(queryStateAtoms.scope);
    const { isSolved, solutions } = useAtomValue(a_modelSolutions);
    const diagramToConjunctive = useDiagramToConjunctive();
    const diagramToCorpusFilter = useDiagramToCorpusFilter();
    if (isConcept(node)) {
        const loaderParams = (prefix) => {
            replacer.reset();
            return invalidCorpusIds(corpus_ids)
                ? undefined
                : {
                    corpus_ids,
                    count: DEF_COUNT,
                    search_width: conjunctionBindingsSearchWidth,
                    aperture,
                    query: normalizeConjunctiveQuery(diagramToConjunctive(node, flow.getNodes(), flow.getEdges(), prefix), replacer),
                };
        };
        return {
            loaderParams,
            loader(params) {
                return __awaiter(this, void 0, void 0, function* () {
                    const { bindings } = yield API.conjunctionBindings(params);
                    return bindings[replacer(node.id)] || [];
                });
            },
        };
    }
    const corpus_filter = invalidCorpusIds(corpus_ids)
        ? undefined
        : diagramToCorpusFilter(node, flow.getNodes(), flow.getEdges(), solutions, [
            node.id,
        ]);
    if (isQualifier(node)) {
        const loaderParams = (prefix) => {
            return (corpus_filter && Object.assign(Object.assign({}, (prefix.length && { prefix })), { corpus_filter, count: DEF_COUNT, argument_name: node.data.argName }));
        };
        return {
            loaderParams,
            loader(params) {
                return __awaiter(this, void 0, void 0, function* () {
                    return API.searchArgumentClauses(params);
                });
            },
        };
    }
    else {
        const loaderParams = (prefix) => {
            return (corpus_filter && Object.assign(Object.assign({}, (prefix.length && { prefix })), { corpus_filter, count: DEF_COUNT }));
        };
        return {
            loaderParams,
            loader(params) {
                return __awaiter(this, void 0, void 0, function* () {
                    return API.relations(params);
                });
            },
        };
    }
}
