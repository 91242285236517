import { message } from "antd";
export function filterValidCorpusIds(corpus_ids, corpora) {
    return corpus_ids.filter(id => corpora[id]);
}
export function validateCorpusIds(corpusIdsFromParams, corpus_ids, corpora) {
    const validated = filterValidCorpusIds(corpusIdsFromParams.length ? corpusIdsFromParams : corpus_ids, corpora);
    if (corpusIdsFromParams.length &&
        validated.length !== corpusIdsFromParams.length) {
        message.error(`Invalid corpus_id: ${corpusIdsFromParams.join(", ")}`);
        console.warn("Invalid corpus_id", corpusIdsFromParams);
    }
    return [validated, corpusIdsFromParams];
}
