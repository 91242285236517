import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getCaption } from "../../api/utils/url";
import { classes } from "../../appState/context/theme/classes";
import { limitLength } from "../../utils/text/limitLength";
import { ButtonWrapper } from "../common/buttons/ButtonWrapper";
import { ShareFromSquare } from "../common/icons/Icons";
import { Banner } from "../common/status/Banner";
import { MAX_TITLE_LEN } from "./MAX_TITLE_LEN";
export function DocumentHeader({ document, highlight = document.primary_evidence.text, suffix, className, selectCount, urlOpener, }) {
    const primaryEvidence = document.primary_evidence;
    const allEvidence = [primaryEvidence, ...document.additional_evidence];
    const allEvidenceText = allEvidence.map(x => x.text);
    const caption = getCaption(primaryEvidence.instances[0]);
    return (_jsx(Banner, Object.assign({ className: classes("flex-1 flex flex-row overflow-hidden px-2", className), suffix: suffix }, { children: _jsxs(ButtonWrapper, Object.assign({ className: "flex text-sm font-bold text-ellipsis overflow-hidden items-center", onClick: () => {
                urlOpener === null || urlOpener === void 0 ? void 0 : urlOpener(document.primary_evidence, document.primary_evidence.instances[0], allEvidenceText, false);
            } }, { children: [_jsx("div", Object.assign({ className: "flex-1 text-ellipsis overflow-hidden text-base" }, { children: _jsx("span", { children: limitLength(caption, MAX_TITLE_LEN) }) })), _jsx(ShareFromSquare, { className: "text-base", style: { padding: "0 12px" } })] })) })));
}
