import React from "react";
export function useInitializer(initializer) {
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        initializer()
            .then(() => setReady(true))
            .catch(e => e);
    }, []);
    return ready;
}
