import { useAtomValue } from "jotai";
import { a_constraintModelUtil, a_corpusFilterBuilder, } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useMemoIfEqual } from "../../utils/lifecycle/useMemoIfEqual";
export function useTrendsCorpusFilter(field) {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const disabled = invalidCorpusIds(corpus_ids);
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const noSelection = modelUtil.isConstraintEmpty(constraint);
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    //INFO: uses full state with a range override based on the trends field
    return useMemoIfEqual(!field || disabled || noSelection
        ? undefined
        : //INFO: no possible values, excludes field from range overrides
            corpusFilterBuilder
                .fullQuery([], [], true)
                .addQueryToFilter()
                .addRangeMetadata([field])
                .addBooleanMetadata()
                .addKeywordMetadata()
                .addContext([], [], true)
                .addExtra()
                .addClauses()
                .toCorpusFilter());
}
