var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import React from "react";
import { FeatureIdentifier, FeedbackType } from "../../api/types/apiTypes";
import { EvidenceType } from "../../api/types/evidenceTypes";
import { useCurrentSource } from "../../api/useCurrentSource";
import { useSuggestedEvidenceFor } from "../../appState/atomic/evidence/useSuggestedEvidenceFor";
import { a_corpusFilterBuilder } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { sortMetadata } from "../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { getAvailableEvidence } from "../../appState/context/findings/findings";
import { brandButtonClass } from "../../appState/context/theme/lf";
import { sendFeedback } from "../../utils/feedback/sendFeedback";
import { uuidv4 } from "../../utils/identity/uuidv4";
import { ActionButton } from "../common/buttons/ActionButton";
import { ThumbsDown } from "../common/icons/Icons";
import { pushModal } from "../common/modals/Modal";
import { showNotification } from "../common/status/notifications";
import { DefaultMarkDown } from "../common/text/MarkDown";
import { showPopupMenu } from "../contextMenu/showPopupMenu";
import { EvidenceFooter } from "../evidence/EvidenceFooter";
import { EvidenceHeader } from "../evidence/EvidenceHeader";
import { SelectItemsModal } from "./SelectItemsModal";
function AttachSuggestedEvidence({ finding, onSelect, addEvidenceToFinding, }) {
    const source = useCurrentSource();
    const { suggestEvidence } = useSuggestedEvidence();
    const [suggested, setSuggested] = useSuggestedEvidenceFor(finding.id);
    const items = getAvailableEvidence(suggested === null || suggested === void 0 ? void 0 : suggested.value, finding.payload.evidence);
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const corpus_filter = corpusFilterBuilder.fullFilter().toCorpusFilter();
    React.useEffect(() => {
        suggestEvidence(finding);
    }, [finding, suggested, suggestEvidence]);
    return (_jsx(SelectItemsModal, { items: items, noFilter: true, brandingColorClass: "bg-gray-700", buttonColorClass: brandButtonClass, title: "Suggested Evidence", buttonCaption: "Attach Evidence", noItemsMessage: "No Suggestions", loading: suggested === null || suggested === void 0 ? void 0 : suggested.loading, contextText: finding.payload.selectedText, onSelected: onSelect, infoExtractor: (item, idx, actions, items, filterText, isSelected) => ({
            title: (_jsxs("div", Object.assign({ className: "divide-y" }, { children: [_jsx(EvidenceHeader, { evidence: item, highlight: item.text, metadataSorter: sortMetadata(source), onSelectionChangeInDocView: ({ rect, text, docText, setHighlights, }) => {
                            showPopupMenu({
                                top: rect.top,
                                left: rect.left + rect.width / 2,
                                entries: [
                                    {
                                        caption: "Attach Evidence to Finding",
                                        disabled: false,
                                        action() {
                                            var _a;
                                            addEvidenceToFinding(finding, [
                                                {
                                                    text,
                                                    id: uuidv4(),
                                                    instances: item.instances,
                                                    evidence_type: EvidenceType.SUPPORTING,
                                                    argument_spans: [],
                                                    highlight_spans: [],
                                                    is_precise: true,
                                                },
                                            ]);
                                            (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
                                            showNotification("Evidence attached to finding.");
                                            setHighlights(highlights => highlights.concat(text));
                                        },
                                    },
                                ],
                            });
                        }, suffix: actions }), _jsx("div", Object.assign({ className: "text-base pt-2" }, { children: _jsx(DefaultMarkDown, { text: item.text }) }))] }))),
            banners: (_jsx(EvidenceFooter, { evidence: item, className: "w-full", selected: isSelected, metadataSorter: sortMetadata(source), suffix: _jsx(ActionButton, Object.assign({ onClick: () => __awaiter(this, void 0, void 0, function* () {
                        yield sendFeedback(FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/suggest/findings", FeedbackType.NEGATIVE, item, corpus_filter);
                        showNotification("Feedback sent");
                        setSuggested(items => (items === null || items === void 0 ? void 0 : items.filter(i => i !== item)) || []);
                    }) }, { children: _jsx(ThumbsDown, {}) })) })),
        }) }));
}
export function showAttachSuggestedEvidence(props) {
    pushModal({
        title: "Additional Evidence",
        titleColorClass: "bg-gray-500",
        content: _jsx(AttachSuggestedEvidence, Object.assign({}, props)),
    });
}
