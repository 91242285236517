import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const UNNAMED_SESSION_ID = "EMPTY_SESSION-000";
export const EMPTY_SESSION_METADATA = {
    id: UNNAMED_SESSION_ID,
    name: "Untitled Session",
    timestamp: 0,
    sessionKey: "",
};
const _atoms = {
    id: atomWithReset(EMPTY_SESSION_METADATA.id),
    name: atomWithReset(EMPTY_SESSION_METADATA.name),
    timestamp: atomWithReset(EMPTY_SESSION_METADATA.timestamp),
    sessionKey: atomWithReset(EMPTY_SESSION_METADATA.sessionKey),
};
export const SESSION_METADATA_KEYS = Object.keys(_atoms);
export const a_sessionMetadata = atom(get => {
    return {
        id: get(_atoms.id),
        name: get(_atoms.name),
        timestamp: get(_atoms.timestamp),
        sessionKey: get(_atoms.sessionKey),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_sessionMetadata);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useSessionMetadata() {
    return useResettableState(a_sessionMetadata);
}
