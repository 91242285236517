import { useAtomValue } from "jotai";
import { API } from "../../../api/api";
import { useRemoteQuery } from "../../../api/useRemoteQuery";
import { Feature, useIsFeatureSupported } from "../featureFlags/features";
import { a_corpusFilterBuilder } from "./constraintModel/ConstraintModelUtil";
import { useValidatedCorpusIds } from "./useValidatedCorpusIds";
export function useSuggestRelatedQueries() {
    const supportsSuggestedQueries = useIsFeatureSupported(Feature.QUERY_SUGGESTION_RELATED);
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const corpus_ids = useValidatedCorpusIds();
    const query = corpusFilterBuilder
        .newQuery()
        .querySources()
        .queryRelations()
        .queryTargets()
        .toQuery();
    const params = !(corpus_ids === null || corpus_ids === void 0 ? void 0 : corpus_ids.length) || !query || !supportsSuggestedQueries
        ? undefined
        : {
            count: 10,
            corpus_ids,
            queries: [query],
        };
    const remote = useRemoteQuery({
        loader: API.suggestRelatedQueries,
        params: params,
    });
    return { remote, params };
}
