import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { groupBy, mapValues } from "lodash";
import React from "react";
import { useAllTagComponents, useAllTags } from "../../appState/atomic/allTags";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useHasBackgroundKnowledge } from "../../appState/atomic/queryState/useHasBackgroundKnowledge";
import { useRestoreQuery } from "../../appState/atomic/queryState/useQueryState";
import { getArgsInfo } from "../../appState/atomic/queryState/utils/getArgsInfo";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { getContextCorpusIds, getContextQueryParts, getContextText, restoreFindingContext, } from "../../appState/context/findings/findings";
import { classes } from "../../appState/context/theme/classes";
import { findingBorderClass, headerInputClass, } from "../../appState/context/theme/lf";
import { toggle } from "../../utils/generic/collections";
import { TinyButton } from "../common/buttons/TinyButton";
import { Down, Findings, Right } from "../common/icons/Icons";
import { FilteredCardList } from "../common/layout/CardsList";
import { useShowSelectionMenu } from "../contextMenu/showSelectionMenu";
import { FindingsDropDownMenu } from "../findingsRedesign/FindingsDropDownMenu";
import { QueryRenderer } from "../query/QueryRenderer";
import { FindingCard } from "./FindingCard";
function useArgInfoById(findings, globalInfo) {
    return findings.reduce((acc, f) => {
        const corpus_id = getContextCorpusIds(f.context)[0];
        if (corpus_id && !acc[corpus_id]) {
            acc[corpus_id] = getArgsInfo(corpus_id, globalInfo);
        }
        return acc;
    }, {});
}
//TODO: get rid of contextTitles
export function MyFindings() {
    const restoreQuery = useRestoreQuery();
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const disabled = invalidCorpusIds(corpus_ids);
    const [allCollapsed, setAllCollapsed] = React.useState([]);
    const [{ findings, structuredQuery: sq, showStructuredQuery }, sessionUpdater] = useCurrentSession();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const structuredQuery = sq && showStructuredQuery;
    const globalInfo = useGlobalInfo();
    const allTags = useAllTagComponents(useAllTags());
    const [showTags, setShowTags] = React.useState(false);
    const backgroundKnowledge = useHasBackgroundKnowledge();
    const argInfoById = React.useMemo(() => useArgInfoById(findings, globalInfo), [findings, globalInfo]);
    const { suggestEvidence } = useSuggestedEvidence();
    const currentContextTitle = useCurrentContextTitle();
    const showSelectionMenu = useShowSelectionMenu();
    const itemRender = React.useCallback((item, idx, items, filterText) => {
        const queryParts = getContextQueryParts(item.context);
        const jsonParams = JSON.stringify(queryParts);
        const collapsed = allCollapsed.indexOf(jsonParams) >= 0;
        const newGroup = idx === 0 ||
            jsonParams !==
                JSON.stringify(getContextQueryParts(items[idx - 1].context));
        const colorProvider = argInfoById[getContextCorpusIds(item.context)[0]].argColors;
        return (_jsxs("div", { children: [!newGroup ? undefined : (_jsxs("div", Object.assign({ className: classes("rounded px-2 py-1 text-xs flex justify-start items-center cursor-pointer space-x-1"), onClick: () => {
                        restoreFindingContext(item.context, restoreQuery);
                    } }, { children: [_jsx("div", Object.assign({ className: "flex-1 font-semibold italic text-base overflow-hidden" }, { children: getContextText(item.context) || (_jsx(QueryRenderer, { parts: queryParts, maxTags: 1, colorProvider: colorProvider })) })), _jsx(TinyButton, Object.assign({ size: 12, onMouseDown: e => {
                                e.preventDefault();
                                setAllCollapsed(c => toggle(c, jsonParams));
                            } }, { children: collapsed ? _jsx(Right, {}) : _jsx(Down, {}) }))] }))), !collapsed && (_jsx(FindingCard, { className: "mt-1", disabled: disabled, item: item, allTags: showTags ? allTags : undefined, highlightText: filterText, suggestQuestions: backgroundKnowledge, onTextSelected: ({ rect, text }) => showSelectionMenu(rect, {
                        text,
                        suggestEvidence,
                        context: text,
                        currentContextTitle,
                        structuredQuery,
                        usingHierarchyNav: supportsHierarchyNavigation,
                    }) }))] }));
    }, [
        showSelectionMenu,
        allCollapsed,
        setAllCollapsed,
        suggestEvidence,
        disabled,
        backgroundKnowledge,
        allTags,
        argInfoById,
    ]);
    return (_jsx(FilteredCardList, { style: { maxWidth: "32rem", minWidth: "24rem", flexGrow: 1, flexShrink: 1 }, bgClassName: "bg-gray-100", items: findings, noItemsMessage: "No Findings", filterBorderClass: findingBorderClass, brandingColorClass: "bg-notification_blue-100", title: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(Findings, { className: "mr-1 pt-1 text-gray-500 text-base" }), _jsx("span", { children: "My Findings" })] })), itemRender: itemRender, itemTexts: item => [item.payload.selectedText], itemTags: item => item.payload.tags, showTags: showTags, setShowTags: setShowTags, filterClass: headerInputClass, headerActions: _jsx(FindingsDropDownMenu, { findings: findings }), itemsSorter: findingSorter, containerStyleClass: "border-l p-2" }));
}
function findingSorter(items) {
    const groups = mapValues(groupBy(items, i => i.context.contextTitle), items => items.sort(timeComparator));
    return Object.values(groups)
        .sort((a, b) => timeComparator(a[0], b[0]))
        .flat();
}
function timeComparator(a, b) {
    return ((b.updateTimestamp || b.payload.foundTime) -
        (a.updateTimestamp || a.payload.foundTime));
}
