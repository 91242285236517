var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { atom, useAtom, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { keyBy } from "lodash";
import { API } from "../../api/api";
import { useInitializer } from "../../utils/lifecycle/useInitializer";
import { queryStateAtoms } from "./queryState/queryStateAtoms";
const searchURL = "/api/v1/search/concepts";
const a_globalInfo = atom({
    allArgumentNames: {},
    corporaById: {},
    multihopDiscoveryStrategies: ["NONE"],
});
const a_globalInfoInitialized = atomEffect((get, set) => {
    const globalInfo = get(a_globalInfo);
    const { corpus_ids } = get(queryStateAtoms.scope);
    if (corpus_ids.length === 0 &&
        Object.keys(globalInfo.corporaById).length > 0) {
        const initialCorpusIds = [Object.keys(globalInfo.corporaById)[0]];
        set(queryStateAtoms.scope, { corpus_ids: initialCorpusIds });
    }
});
export function useGlobalInfo() {
    return useAtom(a_globalInfo)[0];
}
export function useInitGlobalInfo() {
    const setGlobalInfo = useSetAtom(a_globalInfo);
    useAtom(a_globalInfoInitialized);
    return useInitializer(() => __awaiter(this, void 0, void 0, function* () {
        const corpora = yield API.corpora();
        const argNames = yield Promise.all(corpora.map(c => API.searchArguments({ corpus_filter: { corpus_ids: [c.id] } })));
        const allArgumentNames = Object.fromEntries(corpora.map((c, idx) => [c.id, argNames[idx]]));
        const multihopDiscoveryStrategies = [
            "NONE",
            ...(yield API.multihopDiscoveryConfig()),
        ];
        setGlobalInfo({
            allArgumentNames,
            corporaById: keyBy(corpora, "id"),
            multihopDiscoveryStrategies,
        });
    }));
}
export const WEB_SOURCE_ID = "web";
