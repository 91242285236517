import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { groupBy, mapValues } from "lodash";
import React from "react";
import { useAllTagComponents, useAllTags } from "../../appState/atomic/allTags";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useHasBackgroundKnowledge } from "../../appState/atomic/queryState/useHasBackgroundKnowledge";
import { getArgsInfo } from "../../appState/atomic/queryState/utils/getArgsInfo";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { getContextCorpusIds } from "../../appState/context/findings/findings";
import { findingBorderClass, headerInputClass, } from "../../appState/context/theme/lf";
import { FilteredCardList } from "../common/layout/CardsList";
import { useShowSelectionMenu } from "../contextMenu/showSelectionMenu";
import { Panel, PanelHeader } from "../layout/panels/Panel";
import { FindingCard } from "./FindingCard";
import { FindingsDropDownMenu } from "./FindingsDropDownMenu";
import { getFindingIndexId } from "./getFindingIndexId";
function useArgInfoById(findings, globalInfo) {
    return findings.reduce((acc, f) => {
        const corpus_id = getContextCorpusIds(f.context)[0];
        if (corpus_id && !acc[corpus_id]) {
            acc[corpus_id] = getArgsInfo(corpus_id, globalInfo);
        }
        return acc;
    }, {});
}
export function FindingsList() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const disabled = invalidCorpusIds(corpus_ids);
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const [{ findings, structuredQuery: sq, showStructuredQuery }] = useCurrentSession();
    const { suggestEvidence } = useSuggestedEvidence();
    const structuredQuery = sq && showStructuredQuery;
    const allTags = useAllTagComponents(useAllTags());
    const [showTags, setShowTags] = React.useState(false);
    const backgroundKnowledge = useHasBackgroundKnowledge();
    const currentContextTitle = useCurrentContextTitle();
    const showSelectionMenu = useShowSelectionMenu();
    const itemRender = React.useCallback((item, idx, items, filterText) => {
        return (_jsx("div", { children: _jsx(FindingCard, { indexId: getFindingIndexId(findings.findIndex(i => i.id === item.id)), className: "mt-1 flex-1", disabled: disabled, item: item, allTags: showTags ? allTags : undefined, highlightText: filterText, suggestQuestions: backgroundKnowledge, onTextSelected: ({ rect, text }) => showSelectionMenu(rect, {
                    text,
                    suggestEvidence,
                    context: text,
                    currentContextTitle,
                    usingHierarchyNav: supportsHierarchyNavigation,
                    structuredQuery,
                }) }) }));
    }, [showSelectionMenu, disabled, backgroundKnowledge, allTags]);
    const headerActions = _jsx(FindingsDropDownMenu, { findings: findings });
    return (_jsx(Panel, Object.assign({ className: "FindingsPanel", header: _jsx(PanelHeader, { title: "Findings", headerActions: headerActions }) }, { children: _jsx(FilteredCardList, { bgClassName: "", items: findings, noItemsMessage: "No Findings", filterBorderClass: findingBorderClass, brandingColorClass: "bg-notification_blue-100", itemRender: itemRender, itemTexts: item => [item.payload.selectedText], itemTags: item => item.payload.tags, showTags: showTags, setShowTags: setShowTags, filterClass: headerInputClass, itemsSorter: findingSorter, containerStyleClass: "" }) })));
}
function findingSorter(items) {
    const groups = mapValues(groupBy(items, i => i.context.contextTitle), items => items.sort(timeComparator));
    return Object.values(groups)
        .sort((a, b) => timeComparator(a[0], b[0]))
        .flat();
}
function timeComparator(a, b) {
    return ((b.updateTimestamp || b.payload.foundTime) -
        (a.updateTimestamp || a.payload.foundTime));
}
