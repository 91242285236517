import { useAtomValue } from "jotai";
import { isEqual } from "lodash";
import React from "react";
import { useReactFlow, useStoreApi } from "reactflow";
import { a_modelSolutions } from "../../appState/atomic/domainModel/modelState";
import { useQueryState } from "../../appState/atomic/queryState/useQueryState";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { useDiagramStateUpdater } from "./DiagramState";
import { diagramToQueryState } from "./adapters/diagramToQueryState";
import { queryStateToDiagram } from "./adapters/queryStateToDiagram";
import { useValueChanged } from "./useValueChanged";
const DEBUG = false;
export function useSyncQueryStateAndDiagram(initialized) {
    const [queryState, updateQueryState] = useQueryState();
    const lastQueryState = React.useRef();
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    const { isSolved, solutions } = useAtomValue(a_modelSolutions);
    const possibleChanged = useValueChanged(solutions);
    const store = useStoreApi();
    React.useEffect(function syncDiagramToQueryState() {
        return dUpdater.onCommit(dState => {
            updateQueryState(qState => {
                const queryState = diagramToQueryState(dState, qState);
                lastQueryState.current = queryState;
                DEBUG && console.log(">> Diag -> Que", dState, queryState);
                return queryState;
            });
        });
    }, []);
    useEffectIfDifferent(function syncQueryStateToDiagram() {
        if (!initialized ||
            (isEqual(lastQueryState.current, queryState) && !possibleChanged))
            return;
        lastQueryState.current = queryState;
        queryStateToDiagram(queryState, solutions, {
            nodes: flow.getNodes(),
            edges: flow.getEdges(),
        }, store.getState().width, store.getState().height).then(state => {
            DEBUG && console.log(">> Que -> Diag", queryState, state);
            dUpdater.update(state);
        });
    }, [flow], [queryState, solutions, initialized], true);
}
