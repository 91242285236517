import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { uniq } from "lodash";
import React from "react";
import { useFeatureFlags } from "../appState/atomic/featureFlags/useFeatureFlags";
import { a_corpusFilterBuilder } from "../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint, a_constraintText, } from "../appState/atomic/queryState/constraintModel/constraints";
import { a_modelFromCorpusFilter } from "../appState/atomic/queryState/constraintModel/fromCorpusFilter";
import { a_resetQuery } from "../appState/atomic/queryState/constraintModel/resets";
import { EXTRA_CONCEPTS_CAPTION } from "../appState/atomic/queryState/consts/EXTRA_CONCEPTS_CAPTION";
import { queryStateAtoms } from "../appState/atomic/queryState/queryStateAtoms";
import { useHasBackgroundKnowledge } from "../appState/atomic/queryState/useHasBackgroundKnowledge";
import { invalidCorpusIds } from "../appState/atomic/queryState/utils/invalidCorpusIds";
import { useFacetState } from "../appState/atomic/session/parts/facetState";
import { useUsageState } from "../appState/atomic/session/parts/usageState";
import { useCurrentSession } from "../appState/atomic/session/session";
import { useEffectIfDifferent } from "../utils/lifecycle/useEffectIfDifferent";
import { useMemoIfEqual } from "../utils/lifecycle/useMemoIfEqual";
import { Genius } from "./Genius";
import { Tooltip } from "./common/popups/Tooltip";
import { useQuestionHistory } from "./queryAnalysis/useQuestionHistory";
import { useSuggestedQuestionsState } from "./suggestedQuestionsAtom";
export function useSuggestQuestionsFromConcepts() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const { enableSuggestedQuestions } = useFeatureFlags();
    const [{ suggestedQuestionsHistory: history }] = useUsageState();
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const corpus_filter = corpusFilterBuilder.fullFilter().toCorpusFilter();
    const disabled = invalidCorpusIds(corpus_ids) || !enableSuggestedQuestions;
    const backgroundKnowledge = useHasBackgroundKnowledge();
    const { suggestQuestionsForConcepts } = useSuggestedQuestionsState();
    return function suggest(concept_clusters) {
        const clusters = concept_clusters.map(c => ({
            members: c.members,
            name: c.name,
        }));
        if (!disabled && backgroundKnowledge) {
            suggestQuestionsForConcepts({
                history,
                corpus_filter,
                concept_clusters: clusters,
                disabled,
            });
        }
    };
}
export function getSuggestedQuestionCaption({ question, explanation, }) {
    return (_jsxs("div", Object.assign({ className: "" }, { children: [_jsx(Tooltip, Object.assign({ content: question }, { children: _jsx("div", Object.assign({ className: "font-bold overflow-ellipsis overflow-hidden" }, { children: question })) })), _jsx(Tooltip, Object.assign({ content: explanation }, { children: _jsxs("div", Object.assign({ className: "font-normal" }, { children: [_jsxs("span", Object.assign({ className: "italic font-normal overflow-ellipsis overflow-hidden" }, { children: ["Reason:", " "] })), explanation] })) }))] })));
}
export function getSuggestedQuestionConceptCaption(name) {
    return (_jsxs("div", Object.assign({ className: "flex m2-2 mr-1" }, { children: ["Suggested Questions for", _jsx("span", Object.assign({ className: "font-bold px-1 overflow-ellipsis overflow-hidden" }, { children: name })), ">"] })));
}
export function useSuggestedQuestions() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const { enableSuggestedQuestions } = useFeatureFlags();
    const backgroundKnowledge = useHasBackgroundKnowledge();
    const { suggestedQuestions, setSuggestedQuestions } = useSuggestedQuestionsState();
    useEffectIfDifferent(() => {
        setSuggestedQuestions([]);
    }, [], [corpus_ids]);
    const [{ expandedFacetGroups }] = useFacetState();
    return {
        backgroundKnowledge: enableSuggestedQuestions ? backgroundKnowledge : false,
        suggestedQuestions,
        expandedFacetGroups,
    };
}
function useGetQuestionEntries(questionGroups, expandedFacetGroups, addQuestionToHistory, setSuggestedQuestions) {
    const [, sessionUpdater] = useCurrentSession();
    const constraint = useAtomValue(a_activeConstraint);
    const resetQuery = useSetAtom(a_resetQuery);
    const fromCorpusFilter = useSetAtom(a_modelFromCorpusFilter);
    const setConstraintText = useSetAtom(a_constraintText);
    return (name) => questionGroups[name].map(sq => {
        return {
            caption: getSuggestedQuestionCaption(sq),
            action() {
                const { corpus_filter } = sq;
                resetQuery();
                fromCorpusFilter(constraint.id, corpus_filter);
                setConstraintText(sq.question);
                addQuestionToHistory(sq.question);
                setSuggestedQuestions(questionGroups[name].filter(s => s !== sq));
                sessionUpdater(s => {
                    var _a;
                    return (Object.assign({ suggestedQuestionsHistory: s.suggestedQuestionsHistory.concat(corpus_filter) }, (((_a = corpus_filter.context) === null || _a === void 0 ? void 0 : _a.length) && {
                        expandedFacetGroups: uniq(expandedFacetGroups.concat(EXTRA_CONCEPTS_CAPTION())),
                    })));
                });
            },
        };
    });
}
export function SuggestedQuestions() {
    const { backgroundKnowledge, suggestedQuestions, expandedFacetGroups } = useSuggestedQuestions();
    const { add: addQuestionToHistory } = useQuestionHistory();
    const { setSuggestedQuestions } = useSuggestedQuestionsState();
    const getEntries = useGetQuestionEntries(suggestedQuestions.questionGroups, expandedFacetGroups, addQuestionToHistory, setSuggestedQuestions);
    const menu = React.useCallback(() => Object.keys(suggestedQuestions.questionGroups).map(name => {
        return {
            caption: (_jsx(Tooltip, Object.assign({ content: name }, { children: getSuggestedQuestionConceptCaption(name) }))),
            subEntries: getEntries(name),
        };
    }), [useMemoIfEqual(suggestedQuestions), expandedFacetGroups]);
    return (_jsx("div", { children: backgroundKnowledge && (_jsx(Genius, { placement: "rightBottom", offset: [0, 0], entries: menu, name: "", entriesCount: suggestedQuestions.questionCount, iconClass: "text-md" })) }));
}
