import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { highlightWordsOutsideOfParse } from "../../appState/atomic/evidence/utils/highlightWordsOutsideOfParse";
import { InfoRow } from "./InfoRow";
export function EvidenceHeader({ evidence, highlight = evidence.text, urlOpener, onSelectionChangeInDocView, suffix, metadataSorter, }) {
    const highlightWords = highlightWordsOutsideOfParse(evidence);
    return (_jsx(InfoRow, { highlight: [
            ...highlightWords,
            ...(!highlight
                ? []
                : Array.isArray(highlight)
                    ? highlight
                    : [highlight]),
        ], metadataSorter: metadataSorter, sources: evidence.instances, urlOpener: urlOpener &&
            (docId => urlOpener(evidence, evidence.instances.find(i => {
                return i.document_id === docId;
            }))), onSelectionChangeInDocView: onSelectionChangeInDocView, suffix: suffix }));
}
