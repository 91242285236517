var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Spin } from "antd";
import { useAtomValue, useSetAtom } from "jotai";
import { pick, take, uniq } from "lodash";
import { API } from "../../api/api";
import { useRemoteQuery } from "../../api/useRemoteQuery";
import { a_modelSolutions } from "../../appState/atomic/domainModel/modelState";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_corpusFilterBuilder } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_addConceptToNode, a_removeConceptFromExtended, a_removeConceptIdFromNodes, } from "../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { DEFAULT_COUNT } from "../../appState/atomic/queryState/consts/DEFAULT_COUNT";
import { TOP_RESULTS_KEY } from "../../appState/atomic/queryState/consts/TOP_RESULTS_KEY";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { mergeNamedMembersToList } from "../../appState/atomic/queryState/semanticSearch/mergeNamedMembers";
import { ReferenceType } from "../../appState/atomic/queryState/semanticSearch/references";
import { validateTextFilter } from "../../appState/atomic/queryState/semanticSearch/validateTextFilter";
import { ConceptType } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { usePreciseEvidenceOnly } from "../../appState/atomic/queryState/usePreciseEvidenceOnly";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { a_addConceptToExtra, a_removeConceptFromExtra, } from "../../appState/atomic/session/parts/watchlists";
import { classes } from "../../appState/context/theme/classes";
import { getConceptIds } from "../../utils/identity/getConceptIds";
import { wordMatches } from "../../utils/text/filters";
import { RelatedSearch } from "../common/icons/Icons";
import { showPopupMenu } from "../contextMenu/showPopupMenu";
import { useConceptMenuEntries } from "../contextMenu/useConceptMenuEntries";
import { ConceptReference } from "./ConceptReference";
import { defaultFeedbackProvider } from "./defaultFeedbackProvider";
import { ConceptFacet } from "./specializedFacets/ConceptFacet";
import { useSelectionModelFactory } from "./useSelectionModelFactory";
function customConceptListToConceptCluster(customConceptList) {
    const merged = mergeNamedMembersToList([customConceptList])[0];
    return Object.assign({}, merged);
}
function getSearchConceptsMembers({ customConceptList, corpus_filter, prefix, count, argument_name, use_precise_evidence_only, }, config) {
    return __awaiter(this, void 0, void 0, function* () {
        const concept_cluster = customConceptListToConceptCluster(customConceptList);
        const concepts = yield API.searchConceptsMembers({
            corpus_filter,
            concept_cluster,
            argument_name,
            count: 100,
            use_precise_evidence_only,
        }, config);
        const filter = prefix && wordMatches(prefix, false);
        return take(!filter ? concepts : concepts.filter(c => filter(c.name)), count);
    });
}
export function ConceptFacetWrapper({ nodeId, conceptId, clusterKey, concept, argument_name, index, feedback = {}, setFeedback, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const disabled = invalidCorpusIds(corpus_ids);
    const [{ hierarchicalFacets, expandedFacetCount }] = useUserConfig();
    const use_precise_evidence_only = usePreciseEvidenceOnly();
    const { isSolved, solutions } = useAtomValue(a_modelSolutions);
    const itemsOverride = conceptId && isSolved ? (_a = solutions[nodeId]) === null || _a === void 0 ? void 0 : _a[conceptId] : null; //TODO: what about extra and context concepts that match?
    const selectionModelFactory = useSelectionModelFactory();
    const selectionModel = selectionModelFactory({
        nodeId,
        conceptId,
        clusterKey,
    });
    const selected = selectionModel.getSelected();
    const required = (_c = (_b = selectionModel.isReqSelected) === null || _b === void 0 ? void 0 : _b.call(selectionModel)) !== null && _c !== void 0 ? _c : false;
    //TODO: kind of hacky
    // we only need to force include a concept when the watchlist is searching it's own concepts and it's empty
    const corpusFilterProvider = useCorpusFilterProvider([conceptId], clusterKey === ConceptType.EXTENDED &&
        concept &&
        !selected.length &&
        !required && //if it's required it'll get added elsewhere
        selected.length === 0 //if there is a selection it'll get added elsewhere
        ? [concept]
        : undefined);
    const subconceptParamsGetter = useSubconceptParamsGetter(corpusFilterProvider, argument_name);
    const facetParamsGetterForTopResults = useFacetParamsGetterForTopResults(argument_name, disabled || Boolean(concept));
    const loaderParamsGetter = createSubconceptsParamsGetter();
    const clusterParamsGetter = !facetParamsGetterForTopResults
        ? loaderParamsGetter
        : ({ prefix, maxCount, extraArg }) => {
            prefix = validateTextFilter(prefix);
            return Object.assign(Object.assign(Object.assign(Object.assign({}, facetParamsGetterForTopResults(maxCount)), { argument_name, check_if_concepts_have_children: true }), (prefix.length && { prefix })), { use_precise_evidence_only });
        };
    //TODO: what did this do?
    /*
    useUpdateFacetCollapseCleanup(
      conceptsKey,
      (facetParamsGetterForTopResults ? [TOP_RESULTS_KEY] : []).concat(
        relevantConcepts.map(c => c.name)
      )
    )
    */
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const parentParamsGetter = supportsHierarchyNavigation && (concept === null || concept === void 0 ? void 0 : concept.has_parent_concepts)
        ? createSubconceptsParamsGetter(true)
        : undefined;
    return concept && concept.isCustom ? (_jsx(ConceptFacet, { nodeId: nodeId, facetFor: concept, hierarchical: hierarchicalFacets, facetLoader: getSearchConceptsMembers, subEntriesLoader: API.searchConcepts, onlyLoadWhenExpanded: true, initiallyCollapsed: false, loaderParamsGetter: ({ prefix, maxCount, extraArg }) => {
            prefix = validateTextFilter(prefix, 1);
            const corpus_filter = corpusFilterProvider();
            return Object.assign(Object.assign({ corpus_filter, customConceptList: concept, count: maxCount === expandedFacetCount
                    ? 1000 /* SHOW ALL */
                    : DEFAULT_COUNT, argument_name, check_if_concepts_have_children: hierarchicalFacets }, (prefix.length && { prefix })), { use_precise_evidence_only });
        }, loaderParamsGetterExtraArg: concept, subEntriesLoaderParamsGetter: loaderParamsGetter, facetType: clusterKey, disabled: disabled, clusterId: conceptId, setSelected: selectionModel.setSelected, selected: selected, suffixProvider: concept &&
            setFeedback &&
            defaultFeedbackProvider(concept.name, feedback, setFeedback), getFacetTitle: (count, disabled) => (_jsx(FacetTitle, { concept: concept, nodeId: nodeId, clusterKey: clusterKey, conceptId: conceptId, index: index, disabled: disabled, parentParamsGetter: parentParamsGetter })), noExpansion: false, loading: false, minFilterLength: 1, itemsOverride: itemsOverride, setReq: concept && selectionModel.setReq, reqSelected: (_e = (_d = selectionModel.isReqSelected) === null || _d === void 0 ? void 0 : _d.call(selectionModel)) !== null && _e !== void 0 ? _e : false, max_count: expandedFacetCount })) : (_jsx(ConceptFacet, { nodeId: nodeId, facetFor: concept, hierarchical: hierarchicalFacets, facetLoader: API.searchConcepts, subEntriesLoader: API.searchConcepts, onlyLoadWhenExpanded: true, initiallyCollapsed: false, loaderParamsGetter: clusterParamsGetter, loaderParamsGetterExtraArg: concept, subEntriesLoaderParamsGetter: loaderParamsGetter, facetType: clusterKey, disabled: disabled, clusterId: conceptId, setSelected: selectionModel.setSelected, selected: selected, suffixProvider: concept &&
            setFeedback &&
            defaultFeedbackProvider(concept.name, feedback, setFeedback), getFacetTitle: (count, disabled) => (_jsx(FacetTitle, { concept: concept, nodeId: nodeId, clusterKey: clusterKey, conceptId: conceptId, index: index, disabled: disabled, parentParamsGetter: parentParamsGetter })), noExpansion: false, loading: false, minFilterLength: 1, itemsOverride: itemsOverride, setReq: concept && selectionModel.setReq, reqSelected: (_g = (_f = selectionModel.isReqSelected) === null || _f === void 0 ? void 0 : _f.call(selectionModel)) !== null && _g !== void 0 ? _g : false, max_count: expandedFacetCount }));
    //INFO: this is for children and hierarchical facets
    function createSubconceptsParamsGetter(getParent) {
        return ({ prefix, maxCount, extraArg }) => {
            prefix = validateTextFilter(prefix);
            const subconceptParams = subconceptParamsGetter(maxCount);
            return getSubconceptsForClusterParams(Object.assign({ getParent, params: Object.assign(Object.assign({}, subconceptParams), { argument_name,
                    use_precise_evidence_only }), check_if_concepts_have_children: !getParent, cluster: (extraArg || concept) }, (prefix.length && { prefix })));
        };
    }
}
function FacetTitle({ concept, nodeId, conceptId, clusterKey, index, disabled, parentParamsGetter, }) {
    const [{ structuredQuery }] = useUserConfig();
    const constraint = useAtomValue(a_activeConstraint);
    const removeConceptIdFromNodes = useSetAtom(a_removeConceptIdFromNodes);
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const addConceptToNode = useSetAtom(a_addConceptToNode);
    const removeConceptFromExtended = useSetAtom(a_removeConceptFromExtended);
    const removeConceptFromExtra = useSetAtom(a_removeConceptFromExtra);
    const addConceptToExtra = useSetAtom(a_addConceptToExtra);
    const parents = useRemoteQuery({
        loader: API.searchConcepts,
        params: parentParamsGetter
            ? pick(parentParamsGetter({
                prefix: "",
                maxCount: DEFAULT_COUNT,
            }), [
                "corpus_filter",
                "clustering_mode",
                "count",
                "parents_of",
                "use_precise_evidence_only",
            ])
            : undefined,
    });
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const showStructuredQueryOptions = structuredQuery && !supportsHierarchyNavigation;
    const menuEntries = useConceptMenuEntries({
        showNLQueryOption: !structuredQuery,
        showStructuredQueryOptions,
        getExtraConceptEntries: supportsHierarchyNavigation
            ? () => {
                var _a;
                return [
                    {
                        caption: "Use broader concept",
                        disabled: parents.loading || ((_a = parents.value) === null || _a === void 0 ? void 0 : _a.length) === 0,
                        subEntries: parents.loading
                            ? []
                            : (parents.value || [])
                                .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                .map(p => {
                                return {
                                    caption: p.name,
                                    action() {
                                        if (!conceptId)
                                            return;
                                        if (clusterKey === ConceptType.EXTENDED) {
                                            removeConceptFromExtra(corpus_ids, nodeId, conceptId);
                                            removeConceptFromExtended(nodeId, conceptId);
                                            addConceptToExtra(corpus_ids, p);
                                            return;
                                        }
                                        removeConceptIdFromNodes([nodeId], conceptId);
                                        addConceptToNode(nodeId, p);
                                    },
                                };
                            }),
                        icon: parents.loading ? _jsx(Spin, {}) : _jsx(RelatedSearch, {}),
                    },
                ];
            }
            : undefined,
    });
    return (_jsx("div", Object.assign({ className: "relative w-full", onContextMenu: e => {
            e.preventDefault();
            showPopupMenu({
                top: e.clientY,
                left: e.clientX,
                entries: menuEntries(concept, []),
                hideOnWheel: false,
            });
        } }, { children: _jsx("div", Object.assign({ className: classes("font-semibold", disabled && "cursor-not-allowed") }, { children: _jsx(ConceptReference, { cluster: concept, index: index, prefix: clusterKey === ConceptType.EXTENDED
                    ? ReferenceType.WATCHLIST
                    : ReferenceType.CONCEPT }) })) })));
}
//TODO: may be removable, only used in corpus facets
function useCorpusFilterProvider(excludeOverridesForConceptIds, includeContextConcepts) {
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    return () => {
        const filter = corpusFilterBuilder
            .fullFilter([], excludeOverridesForConceptIds, includeContextConcepts, true)
            .toCorpusFilter();
        return filter;
    };
}
//INFO: for children and hierarchical facets
function useSubconceptParamsGetter(corpusFilterProvider, argument_name) {
    const [{ conceptClustering }] = useUserConfig();
    function subconceptParamsGetter(count) {
        //INFO: corpus filter provider in subconcepts just passes through with a new function
        const filter = corpusFilterProvider();
        return Object.assign({ corpus_filter: filter, count, clustering_mode: conceptClustering }, (argument_name && { argument_name }));
    }
    return subconceptParamsGetter;
}
function getSubconceptsForClusterParams({ cluster, prefix, params, check_if_concepts_have_children, getParent, }) {
    const relationKey = getParent ? "parents_of" : "instance_of";
    const targetInstances = uniq(getConceptIds(cluster).filter(x => x !== undefined));
    return Object.assign(Object.assign(Object.assign({}, params), ((prefix === null || prefix === void 0 ? void 0 : prefix.length) && { prefix })), { check_if_concepts_have_children, [relationKey]: targetInstances });
}
function useFacetParamsGetterForTopResults(argument_name, disabled) {
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const [{ conceptClustering }] = useUserConfig();
    const use_precise_evidence_only = usePreciseEvidenceOnly();
    //INFO: corpus filter provider in top results sends nothing in
    const corpus_filter = corpusFilterBuilder
        .fullFilter([TOP_RESULTS_KEY], [TOP_RESULTS_KEY])
        .toCorpusFilter();
    //TODO: const hasNoSelectionFacet = remove_getTopResultsField(currentQuery) === conceptsKey
    return disabled || !corpus_filter //TODO: || !hasNoSelectionFacet
        ? undefined
        : (count) => (Object.assign(Object.assign({ corpus_filter,
            count, clustering_mode: conceptClustering }, (argument_name && { argument_name })), { use_precise_evidence_only }));
}
