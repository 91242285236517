var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
export function BorderlessButton(_a) {
    var { children, className, disabled, disabledClassName = "text-neutral-500 cursor-not-allowed", active, activeClassName = "underline text-accent-500" } = _a, props = __rest(_a, ["children", "className", "disabled", "disabledClassName", "active", "activeClassName"]);
    return (_jsx("button", Object.assign({ className: classes("p-0 m-0 border-0 flex items-center whitespace-nowrap select-none", className, disabled ? disabledClassName : undefined, active ? activeClassName : undefined), disabled: disabled }, props, { children: children })));
}
