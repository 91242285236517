import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import local from "../../../last-version.json";
import { getConfig } from "../../appState/config/serverConfig";
import { capitalize } from "../../utils/text/capitalize";
import { DropDown } from "../common/popups/DropDown";
import { Flower } from "./Flower";
import { Random } from "./Random";
import "./Version.less";
import adjectives from "./adjectives.json";
import flowers from "./flowers.json";
function genCodeName(sha) {
    const rnd = Random(sha);
    return capitalize(rnd(adjectives)) + " " + rnd(flowers);
}
export function getVersionInfo() {
    const serverSha = getConfig("GIT_SHA");
    const GIT_SHA = serverSha === "LOCAL" ? local.GIT_SHA : serverSha;
    return {
        GIT_SHA,
        codeName: genCodeName(GIT_SHA),
    };
}
export function Version({ children, disabled, onClick, }) {
    const info = getVersionInfo();
    return !disabled ? (_jsx(DropDown, Object.assign({ placement: "topLeft", overlayClassName: "Version", getContent: () => (_jsxs("div", Object.assign({ className: "flex flex-col text-white items-center   p-4  cursor-pointer rounded-lg", onClick: () => {
                onClick(info);
            }, style: { maxHeight: 200, backgroundColor: "#32153d" } }, { children: [_jsx("div", Object.assign({ className: "font-bold" }, { children: "Cora Version" })), _jsx(Flower, { seed: info.GIT_SHA }), _jsx("div", Object.assign({ className: "text-tiny italic font-bold" }, { children: info.codeName })), _jsx("div", Object.assign({ className: "text-sm italic text-gray-400" }, { children: info.GIT_SHA }))] }))) }, { children: children }))) : (_jsx(_Fragment, { children: children }));
}
