import { atom, useAtom } from "jotai";
import { Emitter } from "../../../utils/events/Emitter";
export var QueryStateEventSource;
(function (QueryStateEventSource) {
    QueryStateEventSource["CONSTRAINTS_BAR"] = "CONSTRAINTS_BAR";
})(QueryStateEventSource || (QueryStateEventSource = {}));
export const a_emitter = atom(Emitter());
export function useQueryStateEventListener() {
    const [emitter] = useAtom(a_emitter);
    return emitter.on;
}
