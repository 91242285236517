var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSetAtom } from "jotai";
import React from "react";
import { a_modelResetAnswer } from "../../../appState/atomic/domainModel/modelState";
import { useModels } from "../../../appState/atomic/models";
import { useCollectionActions } from "../../actions/useCollectionActions";
import { showNotification } from "../../common/status/notifications";
export function useFileActions() {
    const models = useModels();
    const resetModelAnswer = useSetAtom(a_modelResetAnswer);
    const actions = useCollectionActions({
        items: models.models,
        name: e => e.name,
        saveCaption: "Save As",
        emptyMessage: "No models",
        onSelect(m) {
            return __awaiter(this, void 0, void 0, function* () {
                resetModelAnswer();
                yield models.load(m);
            });
        },
        onRemove(m) {
            return __awaiter(this, void 0, void 0, function* () {
                yield models.delete(m.name);
                showNotification(`Model '${m.name}' removed`);
            });
        },
        onCopy(name) {
            return __awaiter(this, void 0, void 0, function* () {
                yield models.save(name);
                showNotification(`Model '${name}' saved`);
            });
        },
        onReplace(name) {
            return __awaiter(this, void 0, void 0, function* () {
                yield models.save(name);
                showNotification(`Model '${name}' saved`);
            });
        },
    });
    return React.useCallback(() => ({
        global: [
            {
                caption: "File",
                subEntries: () => actions,
            },
        ],
    }), [actions]);
}
