var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API } from "../api/api";
export function getKbIdsAndNamesFromConcept({ concepts, corpus_ids, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const concept_ids_set = new Set();
        concepts.map(m => {
            concept_ids_set.add(m.id);
            m.kb_ids.forEach(k => concept_ids_set.add(k));
        });
        const concept_ids = Array.from(concept_ids_set);
        if (concept_ids) {
            const { kb_ids_and_names } = yield API.lookupKbIds({
                corpus_ids,
                concept_ids,
            });
            const idAndNameDict = concept_ids.reduce((acc, cur, idx) => {
                acc[cur] = kb_ids_and_names[idx];
                return acc;
            }, {});
            return concepts.map(m => (Object.assign(Object.assign({}, m), { id: idAndNameDict[m.id], kb_ids_and_names: m.kb_ids.map(id => idAndNameDict[id]) })));
        }
        return concepts.map(m => (Object.assign(Object.assign({}, m), { id: null, kb_ids_and_names: m.kb_ids.map(id => null) })));
    });
}
