import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import React from "react";
import { a_modelActiveTab, a_modelSelectedEvidenceId, a_modelSolutions, } from "../../appState/atomic/domainModel/modelState";
import { a_activeConstraintId, a_constraintModelSolution, } from "../../appState/atomic/queryState/constraintModel/modelState";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { CopyButton } from "../results/cards/CopyButton";
import { TextWithConstraintReferences } from "./TextWithConstraintReferences";
function useSummary() {
    const { discoveryStrategy } = useAtomValue(queryStateAtoms.multihopConfig);
    const { activeModelId } = useAtomValue(queryStateAtoms.constraintModel);
    const isMultiHop = discoveryStrategy !== "NONE" && !activeModelId;
    const { answer, references = [], evidence = [], } = useAtomValue(a_constraintModelSolution);
    return {
        answer,
        evidence,
        references,
        isMultiHop,
    };
}
export function WithReport({ children }) {
    const setActiveConstraintId = useSetAtom(a_activeConstraintId);
    const [activeTab, setActiveTab] = useAtom(a_modelActiveTab);
    const setSelectedEvidenceId = useSetAtom(a_modelSelectedEvidenceId);
    const [{ showMultihopStrategySelection }] = useUserConfig();
    const { isMultiHop, answer, references, evidence } = useSummary();
    const { isSolved, solutions } = useAtomValue(a_modelSolutions);
    const hasAnswer = isMultiHop || Boolean(answer && references.length);
    return (_jsx(Tabs, { id: "GraphTabs", size: "small", className: "flex flex-col flex-1 h-full  px-2", activeKey: activeTab, onTabClick: key => setActiveTab(key), items: [
            {
                key: "graph",
                label: _jsx("span", { children: "Model Graph" }),
                children,
            },
            {
                key: "summary",
                disabled: !answer && (isSolved || isEmpty(solutions)),
                label: _jsx("span", { children: "Solution Summary" }),
                children: (_jsxs("div", Object.assign({ className: "flex flex-1 whitespace-pre-wrap pb-8 h-full", style: { height: "100%", overflowY: "scroll" } }, { children: [_jsx(TextWithConstraintReferences, { text: answer || "", evidence: evidence, references: references, onReferenceClick: isMultiHop
                                ? undefined
                                : ({ constraint_id, evidence_id }) => {
                                    constraint_id && setActiveConstraintId(constraint_id);
                                    setTimeout(() => setSelectedEvidenceId(evidence_id), 1000);
                                } }), showMultihopStrategySelection && answer && hasAnswer && (_jsx(CopyButton, { text: answer || "" })), !answer && !hasAnswer && (_jsxs("div", Object.assign({ className: "flex flex-1 flex-col item-center pt-4 text-center h-full" }, { children: [_jsx("span", Object.assign({ className: "text-xl" }, { children: "No Summary Generated" })), _jsx("span", Object.assign({ className: "block text-gray-500" }, { children: "Try adjusting the model constraints." }))] })))] }))),
            },
        ] }));
}
