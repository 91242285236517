import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactFlow, { Controls, Panel, ReactFlowProvider, useReactFlow, } from "reactflow";
import { Spin } from "antd";
import { useAtomValue } from "jotai";
import { a_modelLoading } from "../../appState/atomic/domainModel/modelState";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { NEUTRAL_COLOR_VALS } from "../../appState/context/theme/lf";
import { ConceptNode } from "./ConceptNode";
import "./ConstraintFlow.less";
import { CurrentContextList } from "./CurrentContextList";
import CyclicEdge from "./CyclicEdge";
import { GraphControls } from "./GraphControls";
import { LayoutGraphButton } from "./LayoutGraphButton";
import { MarkerDefinition } from "./MarkerDefinition";
import { RelationNode } from "./RelationNode";
import { SolutionsButtons } from "./SolutionsButtons";
import { WithReport } from "./WithReport";
import { WithoutReport } from "./WithoutReport";
import { useLayoutAndSyncNodes } from "./useLayoutAndSyncNodes";
const nodeTypes = { concept: ConceptNode, relation: RelationNode };
const edgeTypes = { cyclic: CyclicEdge };
function ConstraintFlowGraph() {
    const activeConstraintIsEmpty = useAtomValue(a_activeConstraintIsEmpty);
    useLayoutAndSyncNodes();
    const { showGraphReport } = useFeatureFlags();
    const { fitView } = useReactFlow();
    const modelLoading = useAtomValue(a_modelLoading);
    const FramingComponent = showGraphReport ? WithReport : WithoutReport;
    return (_jsxs("div", Object.assign({ className: "ConstraintFlow flex flex-col flex-1 bg-white mb-2 h-full" }, { children: [_jsx(FramingComponent, { children: _jsxs("div", Object.assign({ className: "relative flex flex-col flex-1 h-full" }, { children: [modelLoading && (_jsx("div", Object.assign({ style: { opacity: modelLoading ? 1 : 0 }, className: "absolute h-full w-full flex justify-center items-center transition-opacity" }, { children: _jsx(Spin, { size: "large" }) }))), _jsxs("div", Object.assign({ style: { opacity: !modelLoading ? 1 : 0 }, className: "relative flex flex-col flex-1 h-full transition-opacity" }, { children: [activeConstraintIsEmpty && (_jsx("div", Object.assign({ className: "flex flex-1 justify-center items-center select-none" }, { children: "Enter a query to view a graph." }))), !activeConstraintIsEmpty && (_jsxs(ReactFlow, Object.assign({ defaultNodes: [], defaultEdges: [], nodeTypes: nodeTypes, edgeTypes: edgeTypes, nodesDraggable: false, nodesConnectable: false, onInit: () => {
                                        setTimeout(() => {
                                            fitView({ padding: 0.1 });
                                        }, 10);
                                    }, fitView: true }, { children: [_jsx(MarkerDefinition, { color: "black", id: "active-marker" }), _jsx(MarkerDefinition, { color: NEUTRAL_COLOR_VALS[500], id: "inactive-marker" }), _jsxs(Controls, Object.assign({ showInteractive: false, fitViewOptions: {
                                                padding: 0.1,
                                            }, className: "flex flex-col", position: "top-left" }, { children: [_jsx(LayoutGraphButton, { horizontal: true }), _jsx(LayoutGraphButton, {})] })), _jsx(Panel, Object.assign({ position: "top-right" }, { children: _jsx(CurrentContextList, {}) }))] })))] }))] })) }), _jsx(GraphControls, { children: _jsx(SolutionsButtons, {}) })] })));
}
export default function ConstraintFlow() {
    return (_jsx(ReactFlowProvider, { children: _jsx(ConstraintFlowGraph, {}) }));
}
