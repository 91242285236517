import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from "antd";
import React from "react";
import { useShowDF } from "../../PopDF";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { useSessionSavedStatus } from "../../appState/atomic/session/savedStatus";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useInfoStream } from "../../appState/atomic/session/useInfoStream";
import { classes } from "../../appState/context/theme/classes";
import useWindowDimensions from "../../utils/dom/useWindowDimensions";
import { copyToClipboard } from "../../utils/interactions/utils/clipboard";
import { landmarkIdTag } from "../common/assist/useStartTour";
import { Logo } from "../common/icons/Icons";
import { TabButton } from "../common/layout/TabButton";
import { Tooltip } from "../common/popups/Tooltip";
import { showNotification } from "../common/status/notifications";
import { HelpMenu } from "../help/HelpMenu";
import { Version } from "../version/Version";
import { formatVersionInfo } from "../version/formatVersionInfo";
import { MultiHopModelPicker } from "./MultihopModelPicker";
import { SessionPicker } from "./SessionPicker";
import { SourcePicker } from "./SourcePicker";
import { UserMenu } from "./UserMenu";
export function Header({ children, controls, }) {
    const info = useInfoStream("NOT_MODAL");
    const { savedStatus } = useSessionSavedStatus();
    const [{ showMultihopStrategySelection }] = useUserConfig();
    const { enableViewableVersionNumber } = useFeatureFlags();
    const [{ showStructuredQuery, searchAperture, rankWithVectorSearch, showVectorSearchToggle, }, sessionUpdater,] = useCurrentSession();
    const { width } = useWindowDimensions();
    const roomForAperture = searchAperture ? 200 : 0;
    const foldPoint = (showStructuredQuery ? 2000 : 1200) + roomForAperture;
    const { disableVectorSearch } = useFeatureFlags();
    const showDF = useShowDF();
    return (_jsxs("div", Object.assign({ className: classes("flex-1 flex flex-col items-stretch bg-background_header text-text_header w-full p-4 border-b") }, { children: [_jsxs("div", Object.assign({ className: classes("min-h-[34px] flex text-sm font-bold weight-medium text-text_header w-full space-x-2", width < foldPoint ? "justify-between" : undefined) }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-2 items-center" }, { children: [_jsx("div", Object.assign({ className: "mr-2 relative cursor-pointer", style: { width: 56 } }, { children: _jsx(Version, Object.assign({ disabled: !enableViewableVersionNumber, onClick: info => {
                                        copyToClipboard(formatVersionInfo(info));
                                        showNotification("Version Information copied to Clipboard");
                                    } }, { children: _jsx(Logo, Object.assign({}, landmarkIdTag("tour-welcome"), { className: "text-5xl absolute mt-0.5 h-8 cursor-pointer", style: { top: -18, cursor: "default" }, onContextMenu: e => {
                                            if (e.shiftKey) {
                                                e.preventDefault();
                                                showDF();
                                            }
                                        } })) })) })), controls] })), width >= foldPoint ? children : undefined, _jsxs("div", Object.assign({ className: "flex items-center space-x-2 whitespace-nowrap" }, { children: [_jsxs(TabButton, Object.assign({ className: "italic" }, { children: ["(", (info === null || info === void 0 ? void 0 : info.message) || (savedStatus ? "Saved" : "Not Saved"), ")"] })), !disableVectorSearch && showVectorSearchToggle && (_jsx(Tooltip, Object.assign({ content: "Rank with vector search" }, { children: _jsx(Switch, { size: "small", checked: rankWithVectorSearch, onChange: b => sessionUpdater({ rankWithVectorSearch: b }) }) }))), _jsx(SourcePicker, {}), _jsx(MultiHopModelPicker, {}), _jsx(SessionPicker, {}), _jsx("div", Object.assign({}, landmarkIdTag("tour-tour"), { children: _jsx(HelpMenu, {}) })), _jsx(UserMenu, {})] }))] })), width < foldPoint ? (_jsx("div", Object.assign({ className: "flex-1 mt-1" }, { children: children }))) : undefined] })));
}
