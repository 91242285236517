import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getCaption, getDocumentUrl, openUrl } from "../../api/utils/url";
import { classes } from "../../appState/context/theme/classes";
import { limitLength } from "../../utils/text/limitLength";
import { ButtonWrapper } from "../common/buttons/ButtonWrapper";
import { ShareFromSquare } from "../common/icons/Icons";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { Banner } from "../common/status/Banner";
import { MAX_TITLE_LEN } from "./MAX_TITLE_LEN";
export function InfoRow({ sources, highlight, urlOpener = openUrl, suffix, onSelectionChangeInDocView, metadataSorter, }) {
    const count = sources.length || 0;
    const caption = getCaption(sources[0]);
    const button = (_jsx(Banner, Object.assign({ className: classes("flex-1 flex flex-row overflow-hidden px-0"), suffix: suffix }, { children: _jsxs(ButtonWrapper, Object.assign({ className: "flex text-sm font-bold text-ellipsis overflow-hidden items-center", onClick: count === 1
                ? () => urlOpener === null || urlOpener === void 0 ? void 0 : urlOpener(sources[0].document_id, sources[0].corpus_id, sources[0].document_id, highlight, getCaption(sources[0]), onSelectionChangeInDocView, sources[0].metadata, metadataSorter, sources[0].external_url)
                : undefined }, { children: [_jsx("div", Object.assign({ className: "flex-1 text-ellipsis overflow-hidden text-base" }, { children: count === 1
                        ? limitLength(caption, MAX_TITLE_LEN)
                        : `${count} different sources…` })), _jsx(ShareFromSquare, { className: "text-base", style: { padding: "0 4px" } })] })) })));
    return count === 1 ? (button) : (_jsx(DropDownMenu, Object.assign({ entries: () => sources.map(source => ({
            caption: getCaption(source),
            action() {
                urlOpener(source.document_id, source.corpus_id, getDocumentUrl(source.document_id), highlight, source.title, undefined, source.metadata, metadataSorter, source.external_url);
            },
        })) }, { children: button })));
}
