var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientProvider } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { queryClient } from "../../../../App";
import { Feature, useIsFeatureSupported, } from "../../../appState/atomic/featureFlags/features";
import { a_constraintModelUtil } from "../../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_addConcepts, a_resetConceptNodes, } from "../../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint } from "../../../appState/atomic/queryState/constraintModel/constraints";
import { a_addRelations, a_resetRelations, } from "../../../appState/atomic/queryState/constraintModel/relations";
import { queryStateAtoms } from "../../../appState/atomic/queryState/queryStateAtoms";
import { itemsOrOverrides } from "../../../appState/atomic/queryState/semanticSearch/itemsOrOverrides";
import { CRCDirection } from "../../../appState/atomic/queryState/types/CRCDirection";
import { ConceptSource } from "../../../appState/atomic/queryState/types/ConstraintModelState";
import { useConceptExpansions } from "../../../appState/atomic/queryState/useConceptExpansions";
import { useRelationsExpansions } from "../../../appState/atomic/queryState/useRelationsExpansions";
import { useSuggestRelatedQueries } from "../../../appState/atomic/queryState/useSuggestRelatedQueries";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { showDialog } from "../../../utils/dialog/dialog";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { useParams } from "../../../utils/lifecycle/useParams";
import { limitLength } from "../../../utils/text/limitLength";
import { ConfidenceBar } from "../../ConfidenceBar";
import { conceptColorGetter } from "../../conceptColorGetter";
import { SelectionModal } from "../../modals/SelectionModal";
import { ItemsPicker } from "../../picker/ItemsPicker";
import { QueryRenderer } from "../../query/QueryRenderer";
const EMPTY_STATE = {
    sources: null,
    targets: null,
    context: null,
    relations: null,
    queries: null,
};
function clusterRenderer(i) {
    var _a;
    return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex overflow-hidden" }, { children: i.name })), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
}
function SearchAperture({ conceptExpansion, onValue }) {
    const { aperture, corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const apertureSupported = useIsFeatureSupported(Feature.APERTURE);
    const hasSuggestedQueries = useIsFeatureSupported(Feature.QUERY_SUGGESTION_RELATED);
    const hasContextConcepts = modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds).length > 0;
    const [{ sources, targets, context, relations, queries }, update] = useParams(EMPTY_STATE);
    function useSelectForAperture(remoteItems, selectedInCrc, key) {
        React.useEffect(() => {
            if (!remoteItems)
                return;
            const fromState = selectedInCrc.map(i => (Object.assign(Object.assign({}, i), { score: 0 })));
            const selected = new Set([
                ...fromState,
                ...remoteItems.filter(i => i.score !== undefined && i.score <= aperture),
            ]);
            update({
                [key]: {
                    items: [...fromState, ...remoteItems],
                    selected,
                    initial: selected,
                },
            });
        }, [remoteItems]);
    }
    const { remote: suggestedConcepts1 } = useConceptExpansions(itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.sourceNodeId,
    ]), modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
        constraint.sourceNodeId,
    ])), corpus_ids, conceptExpansion, aperture, true);
    const { remote: suggestedConcepts2 } = useConceptExpansions(itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.targetNodeId,
    ]), modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
        constraint.targetNodeId,
    ])), corpus_ids, conceptExpansion, aperture, true);
    const { remote: suggestedContextConcepts } = useConceptExpansions(itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds), modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds)), corpus_ids, conceptExpansion, aperture, true);
    const { remote: suggestedRelations } = useRelationsExpansions(modelUtil.getAllRelations(constraint), corpus_ids, modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.sourceNodeId,
    ]), modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.targetNodeId,
    ]), aperture, true);
    const { remote: suggestedQueries } = useSuggestRelatedQueries();
    useSelectForAperture(suggestedConcepts1 === null || suggestedConcepts1 === void 0 ? void 0 : suggestedConcepts1.value, itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.sourceNodeId,
    ]), modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
        constraint.sourceNodeId,
    ])), "sources");
    useSelectForAperture(suggestedConcepts2 === null || suggestedConcepts2 === void 0 ? void 0 : suggestedConcepts2.value, itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.targetNodeId,
    ]), modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
        constraint.targetNodeId,
    ])), "targets");
    useSelectForAperture(suggestedContextConcepts === null || suggestedContextConcepts === void 0 ? void 0 : suggestedContextConcepts.value, itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds), modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds)), "context");
    useSelectForAperture(suggestedRelations === null || suggestedRelations === void 0 ? void 0 : suggestedRelations.value, modelUtil.getAllRelations(constraint), "relations");
    // REFACTOR: think about this, should probably determine queries from state
    // used to come from coraState but would always be empty
    useSelectForAperture(suggestedQueries === null || suggestedQueries === void 0 ? void 0 : suggestedQueries.value, [], "queries");
    const loading = Boolean((suggestedConcepts1 === null || suggestedConcepts1 === void 0 ? void 0 : suggestedConcepts1.loading) ||
        (suggestedConcepts2 === null || suggestedConcepts2 === void 0 ? void 0 : suggestedConcepts2.loading) ||
        (suggestedRelations === null || suggestedRelations === void 0 ? void 0 : suggestedRelations.loading) ||
        (suggestedQueries === null || suggestedQueries === void 0 ? void 0 : suggestedQueries.loading));
    const concepts1Changed = sources && !same(sources.selected, sources.initial);
    const concepts2Changed = targets && !same(targets.selected, targets.initial);
    const extraConceptsChanged = context && !same(context.selected, context.initial);
    const relationsChanged = relations && !same(relations.selected, relations.initial);
    const queriesChanged = queries && !same(queries.selected, queries.initial);
    const okDisabled = loading ||
        !(concepts1Changed ||
            relationsChanged ||
            concepts2Changed ||
            extraConceptsChanged ||
            queriesChanged);
    if (!apertureSupported) {
        return null;
    }
    return (_jsx(SelectionModal, Object.assign({ title: "Search Aperture", visible: true, closable: true, width: `${hasContextConcepts ? 1320 : 1175}px`, destroyOnClose: true, onCancel: () => onValue(null), onOk: () => {
            onValue({
                sources: !sources
                    ? []
                    : sources.items.filter(i => sources.selected.has(i)),
                targets: !targets
                    ? []
                    : targets.items.filter(i => targets.selected.has(i)),
                context: !context
                    ? []
                    : context.items.filter(i => context.selected.has(i)),
                relations: !relations
                    ? []
                    : relations.items.filter(i => relations.selected.has(i)),
                //TODO: queries no longer appear to be used, but maybe they are somewhere
                //queries: !queries
                // ? []
                //  : queries.items.filter(i => queries.selected.has(i)),
                aperture: 0,
            });
        }, okButtonProps: { disabled: loading || okDisabled } }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex flex-row space-x-4 items-stretch relative p-4" }, { children: [_jsx(ItemsPicker, { entries: (sources === null || sources === void 0 ? void 0 : sources.items) || null, getId: i => i.name, loading: suggestedConcepts1 === null || suggestedConcepts1 === void 0 ? void 0 : suggestedConcepts1.loading, title: "Primary Concept", selected: Array.from((sources === null || sources === void 0 ? void 0 : sources.selected) || []), updateSelection: selected => update({ sources: Object.assign(Object.assign({}, sources), { selected: new Set(selected) }) }), renderItem: i => clusterRenderer(i), emptyMessage: "No Suggestions", checkboxClassName: "PrimaryConcept" }), _jsx(ItemsPicker, { title: "Relations", entries: (relations === null || relations === void 0 ? void 0 : relations.items) || null, getId: i => i.name, loading: suggestedRelations === null || suggestedRelations === void 0 ? void 0 : suggestedRelations.loading, selected: Array.from((relations === null || relations === void 0 ? void 0 : relations.selected) || []), updateSelection: selected => update({ relations: Object.assign(Object.assign({}, relations), { selected: new Set(selected) }) }), renderItem: i => clusterRenderer(i), emptyMessage: "No Suggestions", checkboxClassName: "Relation" }), _jsx(ItemsPicker, { title: "Linked Concept", entries: (targets === null || targets === void 0 ? void 0 : targets.items) || null, getId: i => i.name, loading: suggestedConcepts2 === null || suggestedConcepts2 === void 0 ? void 0 : suggestedConcepts2.loading, selected: Array.from((targets === null || targets === void 0 ? void 0 : targets.selected) || []), updateSelection: selected => update({ targets: Object.assign(Object.assign({}, targets), { selected: new Set(selected) }) }), renderItem: i => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex overflow-hidden" }, { children: i.name })), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
                    }, emptyMessage: "No Suggestions", checkboxClassName: "LinkedConcept" }), hasContextConcepts && (_jsx(ItemsPicker, { title: "Context Concepts", entries: (context === null || context === void 0 ? void 0 : context.items) || null, getId: i => i.name, loading: suggestedContextConcepts === null || suggestedContextConcepts === void 0 ? void 0 : suggestedContextConcepts.loading, selected: Array.from((context === null || context === void 0 ? void 0 : context.selected) || []), updateSelection: selected => update({
                        context: Object.assign(Object.assign({}, context), { selected: new Set(selected) }),
                    }), renderItem: i => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex overflow-hidden" }, { children: i.name })), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
                    }, emptyMessage: "No Suggestions", checkboxClassName: "" })), hasSuggestedQueries &&
                    false && ( // doesn't work right now, need to fix
                _jsx(ItemsPicker, { title: "Similar Queries", entries: (queries === null || queries === void 0 ? void 0 : queries.items) || null, getId: i => i.name, loading: suggestedQueries === null || suggestedQueries === void 0 ? void 0 : suggestedQueries.loading, selected: Array.from((queries === null || queries === void 0 ? void 0 : queries.selected) || []), updateSelection: selected => update({ queries: Object.assign(Object.assign({}, queries), { selected: new Set(selected) }) }), renderItem: i => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx(QueryRenderer, { className: "flex-1 overflow-hidden", parts: remove_queryToPars(i), conceptColorGetter: conceptColorGetter, arrowSize: 7 }), _jsx(ConfidenceBar, { confidence: 1 - Math.max(0, Math.min(1, (_a = i.score) !== null && _a !== void 0 ? _a : 0)) })] })));
                    }, emptyMessage: "No Suggestions", style: { flex: 1.75 }, checkboxClassName: "DefaultGray" }))] })) })));
    function same(items, original) {
        return (items.size === original.size &&
            Array.from(items).every(i => original.has(i)));
    }
}
export function useShowSearchApertureModal() {
    const constraint = useAtomValue(a_activeConstraint);
    const setScope = useSetAtom(queryStateAtoms.scope);
    const resetConceptNodes = useSetAtom(a_resetConceptNodes);
    const resetRelations = useSetAtom(a_resetRelations);
    const addConcepts = useSetAtom(a_addConcepts);
    const addRelations = useSetAtom(a_addRelations);
    const [{ conceptExpansion }] = useCurrentSession();
    const showApertureModal = () => __awaiter(this, void 0, void 0, function* () {
        const update = yield showDialog(onValue => (_jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(SearchAperture, { onValue: onValue, conceptExpansion: conceptExpansion }) }))));
        if (update) {
            if (typeof update.aperture === "number") {
                setScope({ aperture: update.aperture });
            }
            resetConceptNodes([
                constraint.sourceNodeId,
                constraint.targetNodeId,
                ...constraint.contextNodeIds,
            ]);
            resetRelations();
            addConcepts([
                ...(update.sources || []).map(c => ({
                    nodeId: constraint.sourceNodeId,
                    concept: c,
                })),
                ...(update.targets || []).map(c => ({
                    nodeId: constraint.targetNodeId,
                    concept: c,
                })),
                ...(update.context || []).map(c => ({
                    nodeId: uuidv4(),
                    concept: c,
                    createContextNode: true,
                })),
            ]);
            // update.extraConcepts?.forEach(c => addConceptToExtended(c))
            addRelations([
                ...(update.relations || []).map(r => ({
                    nodeId: constraint.relationNodeId,
                    relation: r,
                })),
            ]);
        }
    });
    return () => showApertureModal();
}
function remove_queryToPars(query, maxPartLen) {
    const { concepts1, relations, concepts2, crcDirection: dir, } = remove_queryToCrc(query);
    return Object.assign(Object.assign(Object.assign(Object.assign({}, (concepts1.length && {
        c1: concepts1.map(i => limitLength(i.name, maxPartLen)),
    })), (concepts2.length && {
        c2: concepts2.map(i => limitLength(i.name, maxPartLen)),
    })), (relations.length && {
        r: relations.map(i => limitLength(i.name, maxPartLen)),
    })), { dir });
}
function remove_queryToCrc(query) {
    var _a, _b, _c, _d;
    const firstArgNane = (_b = (_a = query.concept_filter) === null || _a === void 0 ? void 0 : _a.filter[0]) === null || _b === void 0 ? void 0 : _b.argument_name;
    const crcDirection = firstArgNane === "subject"
        ? CRCDirection.C1C2
        : firstArgNane === "object"
            ? CRCDirection.C2C1
            : CRCDirection.BOTH;
    const concepts = ((_c = query.concept_filter) === null || _c === void 0 ? void 0 : _c.filter.map(i => ({
        name: i.name,
        members: i.members,
    }))) || [];
    const concepts1 = concepts.length == 2 || crcDirection !== CRCDirection.BOTH
        ? [concepts[0]]
        : [];
    const concepts2 = concepts.length == 1 && crcDirection === CRCDirection.BOTH
        ? [concepts[0]]
        : concepts.length == 2
            ? [concepts[1]]
            : [];
    return {
        concepts1,
        concepts2,
        relations: ((_d = query.relation_filter) === null || _d === void 0 ? void 0 : _d.filter) || [],
        crcDirection,
    };
}
