import { atom, useAtom, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { RESET, useResetAtom } from "jotai/utils";
import { isEmpty, pick } from "lodash";
import { a_genius } from "../../../components/suggestedQuestionsAtom";
import { a_featureFlags } from "../featureFlags/useFeatureFlags";
import { getUpdateValue } from "../utils/getUpdateValue";
import { a_conceptListState, CONCEPT_LIST_STATE_KEYS, EMPTY_CONCEPT_LIST_STATE, } from "./parts/conceptListState";
import { a_displayState, DISPLAY_STATE_KEYS, EMPTY_DISPLAY_STATE, } from "./parts/displayState";
import { a_domainModelState, DOMAIN_MODEL_STATE_KEYS, EMPTY_DOMAIN_MODEL_STATE, } from "./parts/domainModelState";
import { a_evidenceState, EMPTY_EVIDENCE_STATE, EVIDENCE_STATE_KEYS, } from "./parts/evidenceState";
import { a_facetState, EMPTY_FACET_STATE, FACET_STATE_KEYS, } from "./parts/facetState";
import { a_findingsState, EMPTY_FINDINGS_STATE, FINDINGS_STATE_KEYS, } from "./parts/findingsState";
import { a_oldGraphState, EMPTY_OLD_GRAPH_STATE, OLD_GRAPH_STATE_KEYS, } from "./parts/oldGraphState";
import { a_queryHistoryState, EMPTY_QUERY_HISTORY_STATE, QUERY_HISTORY_STATE_KEYS, } from "./parts/queryHistoryState";
import { a_sessionMetadata, EMPTY_SESSION_METADATA, SESSION_METADATA_KEYS, } from "./parts/sessionMetadata";
import { a_summariesState, EMPTY_SUMMARIES_STATE, SUMMARIES_STATE_KEYS, } from "./parts/summariesState";
import { a_trendState, EMPTY_TREND_STATE, TREND_STATE_KEYS, } from "./parts/trendState";
import { a_usageState, EMPTY_USAGE_STATE, USAGE_STATE_KEYS, } from "./parts/usageState";
import { a_userConfig, EMPTY_USER_CONFIG, USER_CONFIG_KEYS, } from "./parts/userConfig";
import { a_watchlistsState, EMPTY_WATCHLISTS_STATE, WATCHLISTS_STATE_KEYS, } from "./parts/watchlists";
import { a_savedStatus } from "./savedStatus";
import { getCurrentSessionFromLocalStorage } from "./utils/getCurrentSessionFromLocalStorage";
const EMPTY_SESSION = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, EMPTY_SESSION_METADATA), EMPTY_CONCEPT_LIST_STATE), EMPTY_DISPLAY_STATE), EMPTY_DOMAIN_MODEL_STATE), EMPTY_EVIDENCE_STATE), EMPTY_FACET_STATE), EMPTY_FINDINGS_STATE), EMPTY_OLD_GRAPH_STATE), EMPTY_QUERY_HISTORY_STATE), EMPTY_SUMMARIES_STATE), EMPTY_TREND_STATE), EMPTY_USAGE_STATE), EMPTY_WATCHLISTS_STATE), EMPTY_USER_CONFIG);
const a_session = atom(get => {
    const session = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, get(a_sessionMetadata)), get(a_conceptListState)), get(a_displayState)), get(a_domainModelState)), get(a_evidenceState)), get(a_facetState)), get(a_findingsState)), get(a_oldGraphState)), get(a_queryHistoryState)), get(a_summariesState)), get(a_trendState)), get(a_usageState)), get(a_userConfig)), get(a_watchlistsState));
    return session;
}, (get, set, updateOrFn) => {
    const currentValue = get(a_session);
    const update = getUpdateValue(updateOrFn, currentValue);
    //TODO: type for targetAtom
    function updateByType(targetAtom, keys) {
        const relevantUpdates = pick(update, keys);
        if (!isEmpty(relevantUpdates))
            set(targetAtom, relevantUpdates);
    }
    updateByType(a_sessionMetadata, SESSION_METADATA_KEYS);
    updateByType(a_conceptListState, CONCEPT_LIST_STATE_KEYS);
    updateByType(a_displayState, DISPLAY_STATE_KEYS);
    updateByType(a_domainModelState, DOMAIN_MODEL_STATE_KEYS);
    updateByType(a_evidenceState, EVIDENCE_STATE_KEYS);
    updateByType(a_facetState, FACET_STATE_KEYS);
    updateByType(a_findingsState, FINDINGS_STATE_KEYS);
    updateByType(a_oldGraphState, OLD_GRAPH_STATE_KEYS);
    updateByType(a_queryHistoryState, QUERY_HISTORY_STATE_KEYS);
    updateByType(a_summariesState, SUMMARIES_STATE_KEYS);
    updateByType(a_trendState, TREND_STATE_KEYS);
    updateByType(a_usageState, USAGE_STATE_KEYS);
    updateByType(a_userConfig, USER_CONFIG_KEYS);
    updateByType(a_watchlistsState, WATCHLISTS_STATE_KEYS);
});
const a_defaultSession = atom(get => {
    const session = EMPTY_SESSION;
    const { showTopResultsByDefault, defaultEvidencePageSize } = get(a_featureFlags);
    return Object.assign(Object.assign({}, session), { showTopResults: showTopResultsByDefault, defaultEvidencePageSize: defaultEvidencePageSize });
});
const a_sessionInit = atom(null, (get, set) => {
    const defaultSession = get(a_defaultSession);
    const session = getCurrentSessionFromLocalStorage(defaultSession) || defaultSession;
    set(a_session, session);
});
a_sessionInit.onMount = setAtom => {
    setAtom();
};
const a_sessionMustSaveEffect = atomEffect((get, set) => {
    get(a_session);
    set(a_savedStatus, false);
});
export function useSessionEffects() {
    useAtom(a_sessionMustSaveEffect);
}
function useSessionUpdater() {
    return useSetAtom(a_session);
}
function useSessionResetter() {
    const setSession = useSessionUpdater();
    const resetSuggestedQuestions = useResetAtom(a_genius);
    const resetSession = () => {
        resetSuggestedQuestions();
        setSession(RESET);
    };
    return resetSession;
}
//TODO: may lead to excessive rerenders
export function useCurrentSession() {
    const [session] = useAtom(a_session);
    const setSession = useSessionUpdater();
    const resetSession = useSessionResetter();
    return [session, setSession, resetSession];
}
export function useDefaultSession() {
    const [defaultSession] = useAtom(a_defaultSession);
    return defaultSession;
}
