import { useAtomValue } from "jotai";
import React from "react";
import { useNodesInitialized, useReactFlow, useUpdateNodeInternals, } from "reactflow";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { a_userConfig } from "../../appState/atomic/session/parts/userConfig";
import { Axis } from "../../utils/geometry/Axis";
import { useValueChanged } from "../modelBuilder/useValueChanged";
import { fitGraph } from "./fitGraph";
import { getPositionedNodes } from "./layoutUtils/getPositionedNodes";
import { useModelNodes } from "./useModelNodes";
export function useLayoutAndSyncNodes() {
    const { getNodes, getEdges, setNodes, setEdges, fitView } = useReactFlow();
    const nodesInitialized = useNodesInitialized();
    const { nodes, edges } = useModelNodes();
    const [isAdjusted, setIsAdjusted] = React.useState(false);
    const constraintModel = useAtomValue(queryStateAtoms.constraintModel);
    const { horizontalGraphLayout } = useAtomValue(a_userConfig);
    const dirChanged = useValueChanged(horizontalGraphLayout);
    const activeModelIdChanged = useValueChanged(constraintModel.activeModelId);
    const updateNodeInternals = useUpdateNodeInternals();
    function setNodesAndEdges(nodes, edges) {
        setNodes(nodes);
        edges && setEdges(edges);
        updateNodeInternals(nodes.map(n => n.id));
        activeModelIdChanged && fitGraph(fitView);
    }
    React.useEffect(() => {
        setIsAdjusted(false);
    }, [nodesInitialized]);
    React.useEffect(() => {
        const currentNodes = getNodes();
        // we don't want to lose the calculated position of the nodes if we still have it
        const mergedNodes = nodes.map(n => {
            const current = currentNodes.find(cn => cn.id === n.id);
            const nodePosition = {
                x: (current === null || current === void 0 ? void 0 : current.position.x) || n.position.x,
                y: (current === null || current === void 0 ? void 0 : current.position.y) || n.position.y,
            };
            return Object.assign(Object.assign({}, n), { position: nodePosition });
        });
        setNodesAndEdges(mergedNodes, edges);
        setIsAdjusted(false);
    }, [nodesInitialized, constraintModel, nodes, edges]);
    React.useEffect(() => {
        const nodesToAdjust = getNodes();
        const edges = getEdges();
        if (nodesInitialized &&
            nodesToAdjust.every(node => node.width) &&
            ((nodesToAdjust && nodesToAdjust.length && !isAdjusted) || dirChanged)) {
            setTimeout(() => setNodesAndEdges(getPositionedNodes(getNodes(), getEdges(), Axis.get(horizontalGraphLayout))), 0);
            setIsAdjusted(true);
        }
    }, [isAdjusted, nodesInitialized, horizontalGraphLayout, getNodes, getEdges]);
}
