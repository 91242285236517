var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { toggle } from "../../../../utils/generic/collections";
import { getItemKey } from "./getItemKey";
import { getItemRenderer } from "./getItemRenderer";
export function getSelectionOption(_a) {
    var { item, nameGetter, selected, updateSelection, checkBoxClassName } = _a, renderProps = __rest(_a, ["item", "nameGetter", "selected", "updateSelection", "checkBoxClassName"]);
    function action() {
        updateSelection(toggle(selected, item));
    }
    const caption = getItemRenderer
        ? getItemRenderer(Object.assign(Object.assign({}, renderProps), { item,
            nameGetter,
            action }))
        : getItemKey(item, nameGetter);
    return _jsx("div", Object.assign({ className: "flex flex-1" }, { children: caption }));
}
