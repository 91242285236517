import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { Handle, Position } from "reactflow";
import { a_constraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_activeConstraintId } from "../../appState/atomic/queryState/constraintModel/modelState";
import { ConceptSource } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { a_userConfig } from "../../appState/atomic/session/parts/userConfig";
import { CONCEPT_1_COLOR_VALS, CONCEPT_2_COLOR_VALS, NEUTRAL_COLOR_VALS, } from "../../appState/context/theme/lf";
import { Axis } from "../../utils/geometry/Axis";
import { ConceptLine } from "./ConceptLine";
export function ConceptNode(config) {
    const { id, data } = config;
    const axis = Axis.get(useAtomValue(a_userConfig).horizontalGraphLayout);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const constraint = useAtomValue(a_activeConstraint);
    const setActiveConstraintId = useSetAtom(a_activeConstraintId);
    const conceptIds = modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, [
        id,
    ]);
    const active = id === constraint.sourceNodeId || id === constraint.targetNodeId;
    const color = id === constraint.sourceNodeId
        ? CONCEPT_1_COLOR_VALS[600]
        : id === constraint.targetNodeId
            ? CONCEPT_2_COLOR_VALS[600]
            : NEUTRAL_COLOR_VALS[700];
    const hasConceptOrTopResults = !isEmpty(conceptIds);
    return (_jsxs(_Fragment, { children: [_jsx(Handle, { type: "target", position: axis(Position.Left, Position.Top) }), _jsx("div", Object.assign({ className: "flex items-center w-full h-full px-2 py-1 border border-black rounded", style: {
                    backgroundColor: "white",
                    borderColor: color,
                    borderWidth: "2px",
                    borderRadius: "8px",
                }, onClick: () => {
                    data.sourceConstraintId &&
                        setActiveConstraintId(data.sourceConstraintId);
                    !data.sourceConstraintId &&
                        data.targetConstraintId &&
                        setActiveConstraintId(data.targetConstraintId);
                } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [hasConceptOrTopResults &&
                            conceptIds.map((cId, i) => (_jsx(ConceptLine, { active: active, nodeId: id, conceptId: cId }, i))), !hasConceptOrTopResults && _jsx("span", { children: "?" })] })) })), _jsx(Handle, { type: "source", position: axis(Position.Right, Position.Bottom) })] }));
}
