import { useAtomValue, useSetAtom } from "jotai";
import { a_constraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_addConceptIdToNode, a_addConceptToExtended, a_addOverrideConceptForNodeId, a_addRequiredConceptByNodeId, a_concepts, a_removeConceptFromExtended, a_removeConceptIdFromNodes, a_removeRequiredConceptForNodeId, a_resetOverridesForConceptId, a_resetOverridesForNodeId, } from "../../appState/atomic/queryState/constraintModel/concepts";
import { TOP_RESULTS_KEY } from "../../appState/atomic/queryState/consts/TOP_RESULTS_KEY";
import { ConceptSource, ConceptType, } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { a_watchlistsState } from "../../appState/atomic/session/parts/watchlists";
//INFO: watchlists must add/remove the concept
export function useSelectionModelFactory(selectionKey) {
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const resetConceptOverridesForNodeId = useSetAtom(a_resetOverridesForNodeId);
    const resetOverridesForConceptId = useSetAtom(a_resetOverridesForConceptId);
    const addRequiredConceptByNodeId = useSetAtom(a_addRequiredConceptByNodeId);
    const removeRequiredConceptForNodeId = useSetAtom(a_removeRequiredConceptForNodeId);
    const addConceptToExtended = useSetAtom(a_addConceptToExtended);
    const removeConceptFromExtended = useSetAtom(a_removeConceptFromExtended);
    const addOverrideConceptForNodeId = useSetAtom(a_addOverrideConceptForNodeId);
    const addConceptIdToNode = useSetAtom(a_addConceptIdToNode);
    const removeConceptIdFromNodes = useSetAtom(a_removeConceptIdFromNodes);
    const concepts = useAtomValue(a_concepts);
    const extraConcepts = useAtomValue(a_watchlistsState).extraConcepts;
    return ({ nodeId, clusterKey, conceptId, }) => {
        const conceptOverrides = modelUtil.getOverridesForNodeConceptId(clusterKey === ConceptType.EXTENDED
            ? ConceptSource.EXTENDED
            : ConceptSource.MODEL, nodeId, conceptId);
        const concept = concepts[conceptId] || extraConcepts[conceptId];
        const selectionModel = {
            isReqSelected() {
                return modelUtil.isConceptRequired(nodeId, conceptId);
            },
            setReq: req => {
                if (req) {
                    resetConceptOverridesForNodeId(nodeId);
                    if (clusterKey === ConceptType.EXTENDED) {
                        addConceptToExtended(nodeId, concept);
                    }
                    addRequiredConceptByNodeId(nodeId, conceptId);
                    return;
                }
                resetConceptOverridesForNodeId(nodeId);
                removeRequiredConceptForNodeId(nodeId, conceptId);
                if (clusterKey === ConceptType.EXTENDED) {
                    removeConceptFromExtended(nodeId, conceptId);
                }
            },
            getSelected() {
                return conceptOverrides;
            },
            setSelected(selected) {
                resetOverridesForConceptId(nodeId, conceptId);
                if (conceptId === TOP_RESULTS_KEY) {
                    addConceptIdToNode(nodeId, TOP_RESULTS_KEY);
                }
                if (selected.length === 0) {
                    removeRequiredConceptForNodeId(nodeId, conceptId);
                    if (clusterKey === ConceptType.EXTENDED) {
                        removeConceptFromExtended(nodeId, conceptId);
                    }
                    if (conceptId === TOP_RESULTS_KEY) {
                        removeConceptIdFromNodes([nodeId], TOP_RESULTS_KEY);
                    }
                    return;
                }
                if (clusterKey === ConceptType.EXTENDED) {
                    addConceptToExtended(nodeId, concept);
                }
                addRequiredConceptByNodeId(nodeId, conceptId);
                selected.forEach(s => addOverrideConceptForNodeId(nodeId, conceptId, s));
            },
        };
        return selectionModel;
    };
}
