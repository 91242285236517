var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from "antd";
import { take } from "lodash";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Beacon, useBeacon } from "../../../appState/beacon/Beacon";
import { classes } from "../../../appState/context/theme/classes";
import { headerHeightClass } from "../../../appState/context/theme/lf";
import { intercalate } from "../../../utils/generic/collections";
import { useOnGlobalEscape } from "../../../utils/interactions/utils/interactionEvents";
import { ActionButton } from "../buttons/ActionButton";
import { Close, RightArrow } from "../icons/Icons";
const modalsBeacon = Beacon({ initial: [] });
function Modal({ title, simpleTitle, content, titleColorClass = "bg-gray-700", easyClose, modalConfigs, idx, className, onClose, }) {
    const handleClose = React.useCallback(() => {
        onClose && onClose();
        popModal(false);
    }, [onClose]);
    useOnGlobalEscape((easyClose === null || easyClose === void 0 ? void 0 : easyClose()) || false, handleClose);
    const titleElement = (_jsx("div", Object.assign({ className: "text-white  text-xl font-bold" }, { children: title }), "last"));
    return (_jsxs("div", Object.assign({ className: "absolute left-0 top-0 flex w-full h-full flex-row items-stretch z-[1100] flex-none" }, { children: [_jsx("div", { className: "flex-1", onClick: () => easyClose && handleClose() }), _jsxs("div", Object.assign({ className: classes("bg-background_secondary z-20 flex flex-col items-stretch", className) }, { children: [_jsxs("div", Object.assign({ className: classes("p-4 flex flex-row items-center", headerHeightClass, titleColorClass), style: { borderBottomWidth: 1, borderBottomColor: "#ccc" } }, { children: [_jsx("div", Object.assign({ className: "flex-1 font-medium px-12", style: { fontSize: 16, color: "rgba(255,255,355,.5" } }, { children: _jsx(Space, Object.assign({ size: "small" }, { children: simpleTitle
                                        ? titleElement
                                        : intercalate(take(modalConfigs, idx)
                                            .map(c => c.title)
                                            .map((t, idx) => _jsx("div", { children: t }, idx))
                                            .concat([titleElement]), idx => _jsx(RightArrow, {}, "A" + idx)) })) })), _jsx(ActionButton
                            // invert
                            , { 
                                // invert
                                onClick: handleClose, roundedClass: "rounded", style: { color: "white" }, icon: _jsx(Close, {}) })] })), _jsx("div", Object.assign({ className: "flex-1 py-4 px-16 flex flex-row items-stretch bg-background_secondary" }, { children: content }))] }))] })));
}
export function pushModal(config) {
    modalsBeacon.value(configs => [...configs, config]);
}
/**
 * If the top model can be closed, then do so.
 *
 * @param force Close regardless of if the modal can close.
 * @return A promise (because Modal.canClose is async) indicating if the top modal was closed.
 */
export function popModal(force) {
    const configs = modalsBeacon.value();
    const config = configs[configs.length - 1];
    if (!config)
        return Promise.resolve(false);
    const rest = [...configs.slice(0, -1)];
    if (!force && config.canClose) {
        return config.canClose().then(can => {
            can && modalsBeacon.value(rest);
            return can;
        });
    }
    else {
        modalsBeacon.value(rest);
        return Promise.resolve(true);
    }
}
/**
 * Attempt to close all of the open modals. (If force is false then a modal can prevents its close.)
 *
 * @param force Close all regardless of if a modal can close.
 */
export function clearModals(force) {
    return __awaiter(this, void 0, void 0, function* () {
        while (yield popModal(force))
            ;
    });
}
export function Modals() {
    const [modalConfigs] = useBeacon(modalsBeacon);
    return (_jsx(TransitionGroup, { children: modalConfigs.flatMap((config, idx) => [
            _jsx(CSSTransition, Object.assign({ timeout: 500, unmountOnExit: true, mountOnEnter: true, classNames: {
                    enter: "animate-fade_in!REMOVE_THIS_TO_ENABLE",
                    exit: "animate-fade_out!REMOVE_THIS_TO_ENABLE",
                    exitDone: "hidden",
                } }, { children: _jsx("div", { className: "absolute left-0 top-0 flex w-full h-full flex-row items-stretch z-20 bg-black opacity-60" }) }), idx + "background"),
            _jsx(CSSTransition, Object.assign({ timeout: 500, unmountOnExit: true, mountOnEnter: true, onEnter: () => {
                    if (idx === 0) {
                        const html = document.getElementsByTagName("html")[0];
                        html.style.overflow = "hidden";
                    }
                }, onExited: () => {
                    if (idx === 0) {
                        const html = document.getElementsByTagName("html")[0];
                        html.style.overflow = "unset";
                    }
                }, classNames: {
                    enter: "animate-slide_in",
                    exit: "animate-slide_out",
                    exitDone: "hidden",
                } }, { children: _jsx(Modal, Object.assign({ idx: idx, modalConfigs: modalConfigs }, config)) }), idx + "modal"),
        ]) }));
}
