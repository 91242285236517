import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const EMPTY_SUMMARIES_STATE = {
    selectedSummaries: [],
};
const _atoms = {
    selectedSummaries: atomWithReset(EMPTY_SUMMARIES_STATE.selectedSummaries),
};
export const SUMMARIES_STATE_KEYS = Object.keys(_atoms);
export const a_summariesState = atom(get => {
    return {
        selectedSummaries: get(_atoms.selectedSummaries),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_summariesState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useSummariesState() {
    return useResettableState(a_summariesState);
}
