import { useAtomValue } from "jotai";
import { useMemoIfEqual } from "../../../utils/lifecycle/useMemoIfEqual";
import { useGlobalInfo } from "../globalInfo";
import { queryStateAtoms } from "./queryStateAtoms";
import { getCorpusIds } from "./utils/getCorpusIds";
export function useValidatedCorpusIds() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const { corporaById } = useGlobalInfo();
    return useMemoIfEqual(getCorpusIds(corporaById, corpus_ids));
}
