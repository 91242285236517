var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { isEqual, partition } from "lodash";
import React from "react";
import { API } from "../../../api/api";
import { classes } from "../../../appState/context/theme/classes";
import { columnMinWidthClass1_25 } from "../../../appState/context/theme/lf";
import { confirmation } from "../../../utils/dialog/confirmation";
import { showDialog } from "../../../utils/dialog/dialog";
import { TEXT_MIME_TYPE, pickFiles } from "../../../utils/fileIO/filePicker";
import { useParams } from "../../../utils/lifecycle/useParams";
import { ValidationIcon } from "../../common/forms/InputValidation";
import { ManageLists } from "../../common/icons/Icons";
import { FocusContainer } from "../../common/layout/FocusContainer";
import { showNotification } from "../../common/status/notifications";
import { Loading } from "../../common/transitions/Loading";
import { CustomListEntries } from "./CustomListEntries";
const widthClassName = columnMinWidthClass1_25;
const LIST_NAME_PREFIX = _jsx(ManageLists, {});
function textToLines(text) {
    return text
        .split(/\n+/g)
        .map(l => l.trim())
        .filter(l => l.length > 0);
}
const NEW_CUSTOM_LIST = {
    name: "",
    members: [],
    isCustom: true,
    accepted_concepts: [],
};
function CustomListEditor({ list: initial = NEW_CUSTOM_LIST, customConceptListsByCorpusId, onValue, corpus_id, initialText = "", }) {
    const [{ list, text, loading, invalidEntries, listNameText }, update] = useParams({
        list: initial,
        text: initialText,
        listNameText: initial.name,
        loading: false,
        invalidEntries: "",
    });
    const newList = initial.name === NEW_CUSTOM_LIST.name;
    const changes = React.useMemo(() => !isEqual(initial, list), [initial, list]);
    const lists = customConceptListsByCorpusId[corpus_id] || [];
    const nameError = list.name === ""
        ? "List Name is Required"
        : lists
            .filter(l => newList || initial.name !== l.name)
            .find(l => l.name.trim() === list.name.trim())
            ? "There is another list with that name"
            : null;
    const valid = !nameError && !!list.members.length;
    return (_jsxs(Modal, Object.assign({ width: 664, wrapClassName: "absolute", bodyStyle: { height: "70vh", display: "flex", flexDirection: "column" }, title: newList ? "New Custom Concepts List" : `Edit: ${initial.name}`, closable: false, open: true, destroyOnClose: true, onCancel: () => __awaiter(this, void 0, void 0, function* () {
            if (changes &&
                !(yield confirmation({ title: `Lose Changes made to ${list.name}?` })))
                return;
            onValue(null);
        }), onOk: () => {
            onValue(list);
        }, okText: "Ok", okButtonProps: { disabled: !changes || !valid } }, { children: [_jsxs("div", Object.assign({ className: "flex-1 flex flex-col space-y-4 items-stretch relative" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row space-x-4 items-center" }, { children: [_jsx("div", { children: "List Name:" }), _jsxs(FocusContainer, Object.assign({ className: classes("flex-1 flex focus-within:bg-white") }, { children: [LIST_NAME_PREFIX, _jsx(Input, { allowClear: true, bordered: false, className: "flex-1", value: listNameText, placeholder: "Enter Name\u2026", onChange: e => {
                                            update({
                                                list: Object.assign(Object.assign({}, list), { name: e.target.value.trim() }),
                                                listNameText: e.target.value,
                                            });
                                        }, suffix: _jsx(ValidationIcon, { error: nameError }) })] }))] })), _jsxs("div", Object.assign({ className: "flex-1 flex flex-row space-x-4 items-stretch" }, { children: [_jsxs("div", Object.assign({ className: classes("flex flex-col space-y-4 items-stretch", widthClassName) }, { children: [_jsxs(FocusContainer, Object.assign({ className: classes("flex-1 flex flex-row items-stretch focus-within:bg-white") }, { children: [_jsx(TextArea, { allowClear: true, autoSize: false, placeholder: "Enter concepts to add to the list, one per line", value: text, onChange: e => update({ text: e.target.value }), className: "resize-none", style: { flex: 1, alignSelf: "stretch" }, bordered: false }), _jsx(ValidationIcon, { error: text.trim().length > 0 &&
                                                    text === invalidEntries &&
                                                    "Invalid Entries", className: "self-start mt-1" })] })), _jsxs("div", Object.assign({ className: "flex space-x-4" }, { children: [_jsx(Button, Object.assign({ className: "flex-1", onClick: () => __awaiter(this, void 0, void 0, function* () {
                                                    const [file] = (yield pickFiles(TEXT_MIME_TYPE, false)) || [];
                                                    if (!file)
                                                        return;
                                                    const text = yield file.text();
                                                    update({ text: textToLines(text).join("\n") });
                                                }) }, { children: "Import From File..." })), _jsx(Button, Object.assign({ disabled: !text.trim().length, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                                    var _a;
                                                    try {
                                                        update({ loading: true });
                                                        const lines = textToLines(text);
                                                        const concepts = Array.from(new Set((_a = list.accepted_concepts) === null || _a === void 0 ? void 0 : _a.concat(lines)));
                                                        const { concept_cluster, accepted_concepts = [], unmatched_concepts, } = yield API.createConcept({
                                                            concepts,
                                                            corpus_ids: [corpus_id],
                                                        });
                                                        const current = new Set(list.accepted_concepts);
                                                        const [replaced, added] = partition(accepted_concepts, c => current.has(c));
                                                        const invalidEntries = unmatched_concepts.join("\n");
                                                        update({
                                                            loading: false,
                                                            list: Object.assign(Object.assign({}, list), { members: (concept_cluster === null || concept_cluster === void 0 ? void 0 : concept_cluster.members) || [], accepted_concepts }),
                                                            text: invalidEntries,
                                                            invalidEntries: invalidEntries,
                                                        });
                                                        console.log(`Concepts\nReplaced: ${replaced.length}\nAdded: ${added.length}\nInvalid: ${unmatched_concepts.length}`);
                                                        showNotification({
                                                            message: `Concepts\nReplaced: ${replaced.length}\nAdded: ${added.length}\nInvalid: ${unmatched_concepts.length}`,
                                                            duration: 5,
                                                        });
                                                    }
                                                    finally {
                                                        update({ loading: false });
                                                    }
                                                }) }, { children: "Add To List" }))] }))] })), _jsx(CustomListEntries, { title: "Entries", list: list, update: list => update({ list }), empty: "No Entries" })] }))] })), _jsx(Loading, { loading: loading, style: {
                    backgroundColor: "rgba(0,0,0,.4)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                } })] })));
}
export function showCustomListEditor(props) {
    return __awaiter(this, void 0, void 0, function* () {
        return showDialog(onValue => {
            return _jsx(CustomListEditor, Object.assign({ onValue: onValue }, props));
        });
    });
}
