var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pick } from "lodash";
import { queryModelToQueryParts } from "../../../../components/query/queryStateToQueryParts";
import { keysFor } from "../../../../utils/generic/keysFor";
import { loadQueryState } from "../../../stateRestoration/loadQueryState";
import { QueryId } from "../../../stateRestoration/queryId";
import { ConstraintModelUtil } from "../../queryState/constraintModel/ConstraintModelUtil";
import { EMPTY_METADATA_PARAMS } from "../../queryState/consts/EMPTY_QUERY_STATE";
import { ALL_CONCEPT_TYPES } from "../../queryState/types/ConstraintModelState";
import { saveQueryState } from "../../queryState/useQueryIdSynchronizer";
export function isHistoryEntry(e) {
    return "queryId" in e;
}
export function getQueryStateHistoryEntry(queryState) {
    return __awaiter(this, void 0, void 0, function* () {
        const modelUtil = new ConstraintModelUtil(queryState.constraintModel);
        const constraint = modelUtil.getActiveConstraint();
        const queryParts = queryModelToQueryParts(queryState.constraintModel, ALL_CONCEPT_TYPES);
        const queryId = yield saveQueryState(queryState);
        return Object.assign(Object.assign(Object.assign({}, pick(queryState.scope, ["corpus_ids"])), pick(queryState.constraintModel.config, keysFor(EMPTY_METADATA_PARAMS))), { queryParts, text: (constraint === null || constraint === void 0 ? void 0 : constraint.text) || "", time: Date.now(), queryId, queryText: (constraint === null || constraint === void 0 ? void 0 : constraint.userText) || "" });
    });
}
export function restoreHistoricalQuery(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const queryId = isHistoryEntry(entry)
            ? entry.queryId
            : yield QueryId.getQueryIdForCoraState(entry);
        QueryId.set(queryId);
        return yield loadQueryState(queryId, true);
    });
}
