import { ConstraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { CRCDirection } from "../../appState/atomic/queryState/types/CRCDirection";
import { ConceptSource } from "../../appState/atomic/queryState/types/ConstraintModelState";
export function constraintToQueryParts(constraintModel, id) {
    const modelUtil = new ConstraintModelUtil(constraintModel);
    const constraintId = id || constraintModel.activeConstraintId;
    if (!constraintId)
        return {};
    const constraint = constraintModel.state.constraints[constraintId];
    if (!constraint)
        return {};
    const { concepts, relations } = constraintModel.data;
    function getNamesForIdKeys(ids, dict) {
        return ids.length ? ids.map(id => dict[id].name).filter(Boolean) : undefined;
    }
    const relationIds = modelUtil.getAllRelationIds(constraint);
    const contextIds = modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds);
    const text = constraint.text || "";
    //TODO: hacked in, might have unexpected behavior
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({
        c1: getNamesForIdKeys(modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, [
            constraint.sourceNodeId,
        ]), concepts),
    }, (relationIds.length
        ? {
            r: getNamesForIdKeys(relationIds, relations),
        }
        : {})), {
        c2: getNamesForIdKeys(modelUtil.getConceptIdsForNodeIds(ConceptSource.MODEL, [
            constraint.targetNodeId,
        ]), concepts),
    }), (contextIds.length
        ? { context: getNamesForIdKeys(contextIds, concepts) }
        : {})), { dir: constraint.is_directed ? CRCDirection.C1C2 : CRCDirection.BOTH }), (text && { text }));
}
