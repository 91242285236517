const conceptMenuExtensions = [];
const crcMenuExtensions = [];
export function registerConceptMenuExtension(extension) {
    conceptMenuExtensions.push(extension);
    return () => {
        conceptMenuExtensions.splice(conceptMenuExtensions.indexOf(extension), 1);
    };
}
export function registerCrcMenuExtension(extension) {
    crcMenuExtensions.push(extension);
    return () => {
        crcMenuExtensions.splice(crcMenuExtensions.indexOf(extension), 1);
    };
}
export function conceptMenuExtension(corpora, corpus_ids, entries, concepts) {
    return conceptMenuExtensions.reduce((acc, extension) => extension(corpora, corpus_ids, acc, concepts), entries);
}
export function crcMenuExtension(corpus_ids, entries, clusterKey) {
    return crcMenuExtensions.reduce((acc, extension) => extension(corpus_ids, acc, clusterKey), entries);
}
