import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { uniq } from "lodash";
import { createRelationId } from "../../../../utils/identity/createRelationId";
import { a_debugLog } from "../../utils/a_debugMode";
import { queryStateAtoms } from "../queryStateAtoms";
import { a_activeConstraint } from "./constraints";
const a_relationNodes = focusAtom(queryStateAtoms.constraintModel, O => O.path("state.relationNodes"));
export const a_addRelations = atom(null, (get, set, relationConfigs) => {
    set(a_debugLog, "adding relations", relationConfigs);
    set(queryStateAtoms.constraintModel, model => {
        const newRelations = relationConfigs.reduce((acc, { relation, relationId }) => {
            const newRelationId = relationId !== null && relationId !== void 0 ? relationId : createRelationId(relation);
            return Object.assign(Object.assign({}, acc), { [newRelationId]: relation });
        }, model.data.relations);
        const newRelationNodes = relationConfigs.reduce((acc, { nodeId, relation, relationId }) => {
            var _a;
            const newRelationId = relationId !== null && relationId !== void 0 ? relationId : createRelationId(relation);
            return Object.assign(Object.assign({}, acc), { [nodeId]: uniq([...((_a = acc[nodeId]) !== null && _a !== void 0 ? _a : []), newRelationId]) });
        }, model.state.relationNodes);
        return Object.assign(Object.assign({}, model), { data: Object.assign(Object.assign({}, model.data), { relations: newRelations }), state: Object.assign(Object.assign({}, model.state), { relationNodes: newRelationNodes }) });
    });
});
export const a_addRelation = atom(null, (get, set, relation, relationId) => {
    const relationNodeId = get(a_activeConstraint).relationNodeId;
    const newRelationId = relationId !== null && relationId !== void 0 ? relationId : createRelationId(relation);
    set(a_relationNodes, relationNodes => {
        var _a;
        return (Object.assign(Object.assign({}, relationNodes), { [relationNodeId]: uniq([
                ...((_a = relationNodes[relationNodeId]) !== null && _a !== void 0 ? _a : []),
                newRelationId,
            ]) }));
    });
    set(focusAtom(queryStateAtoms.constraintModel, O => O.path("data.relations")), relations => (Object.assign(Object.assign({}, relations), { [newRelationId]: relation })));
});
export const a_resetRelations = atom(null, (get, set) => {
    const relationNodeId = get(a_activeConstraint).relationNodeId;
    set(a_relationNodes, relationNodes => (Object.assign(Object.assign({}, relationNodes), { [relationNodeId]: [] })));
});
