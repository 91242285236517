var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { queryStateAtoms } from "../../../appState/atomic/queryState/queryStateAtoms";
import { useFacetsCollapseState } from "../useFacetsCollapseState";
import { NamedConceptMembersFacet } from "./NamedConceptMembersFacet";
export function CoraStateNamedConceptMembersFacet(_a) {
    var props = __rest(_a, []);
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const { facetType, clusterId, initiallyCollapsed } = props;
    //INFO: concept facet argKey is used to identify collapsed state
    const [collapsed, setCollapsed] = useFacetsCollapseState(corpus_ids, clusterId, facetType, initiallyCollapsed);
    return (_jsx(NamedConceptMembersFacet, Object.assign({}, props, { collapsed: collapsed, setCollapsed: setCollapsed })));
}
