import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Tooltip } from "antd";
import { useCurrentSource } from "../../api/useCurrentSource";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { classes } from "../../appState/context/theme/classes";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { ShareFromSquare } from "../common/icons/Icons";
import { AnnotatedString } from "../common/text/AnnotatedString";
import { truncateToNearestSpace } from "../evidence/truncateToNearestSpace";
import { useUrlOpener } from "../evidence/useUrlOpener";
import { RenderNiceMetadata } from "../results/evidenceDisplay/RenderNiceMetadata";
import { camelify } from "../results/utils/utils";
const letters = "abcdefghijklmnopqrstuvwxyz".split("");
const getReferenceLabel = (evidence) => {
    var _a, _b;
    const evidenceInstance = evidence === null || evidence === void 0 ? void 0 : evidence.instances[0];
    const metadata = evidenceInstance === null || evidenceInstance === void 0 ? void 0 : evidenceInstance.metadata;
    const authors = ((_a = metadata === null || metadata === void 0 ? void 0 : metadata.find(m => m.id === "authors")) === null || _a === void 0 ? void 0 : _a.value) || [];
    const firstAuthor = authors.length > 0 ? authors[0].split(" ").slice(-1)[0] : null;
    const publicationDate = ((_b = metadata === null || metadata === void 0 ? void 0 : metadata.find(m => m.id === "publication_date")) === null || _b === void 0 ? void 0 : _b.value) || "";
    const year = publicationDate ? publicationDate.split("-")[0] : "";
    const supText = firstAuthor && year ? `[${camelify(firstAuthor)} ${year}]` : "";
    return supText;
};
export function TextWithConstraintReferences({ text, references, evidence, onReferenceClick, }) {
    const referenceAnnotations = references.map(r => ({
        span: r.span,
        annotation: {
            constraint_id: r.constraint_id,
            evidence_id: r.evidence_id,
        },
    }));
    const source = useCurrentSource();
    const [{ findings, structuredQuery, showStructuredQuery }, sessionUpdater] = useCurrentSession();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const { suggestEvidence } = useSuggestedEvidence();
    const showingStructuredQuery = structuredQuery && showStructuredQuery;
    const corpora = useGlobalInfo().corporaById;
    const currentContextTitle = useCurrentContextTitle();
    // TODO: annotated string should be a function that returns an object to manipulate before displaying
    // TODO: hack for now
    const superscriptMap = references.reduce((acc, ref) => {
        const { evidence_id } = ref;
        const e = evidence.find(e => e.id === evidence_id);
        if (!e)
            return acc;
        const supText = getReferenceLabel(e);
        if (!supText)
            return acc;
        if (!acc[supText]) {
            acc[supText] = [evidence_id];
        }
        else if (!acc[supText].includes(evidence_id)) {
            acc[supText].push(evidence_id);
        }
        return acc;
    }, {});
    const urlOpenerFn = useUrlOpener({
        suggestEvidence,
        currentContextTitle,
        findings,
        structuredQuery: showingStructuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        source,
    });
    return (_jsx(AnnotatedString, { text: text, annotations: referenceAnnotations, renderAnnotation: ({ text, annotations }) => {
            if (annotations.length) {
                const targetEvidence = evidence.find(e => e.id === annotations[0].evidence_id);
                const evidenceInstance = targetEvidence === null || targetEvidence === void 0 ? void 0 : targetEvidence.instances[0];
                const evidenceDocTitle = evidenceInstance
                    ? evidenceInstance.title
                    : undefined;
                const evidenceSnippet = targetEvidence && targetEvidence.text
                    ? truncateToNearestSpace(targetEvidence.text, {
                        maxLength: 500,
                        postfix: "...",
                    })
                    : "";
                const tooltip = (_jsxs("div", Object.assign({ className: "flex flex-col items-start space-y-2" }, { children: [evidenceDocTitle && (_jsx("span", Object.assign({ className: "font-bold text-base" }, { children: evidenceDocTitle }))), targetEvidence && (_jsx(RenderNiceMetadata, { evidence: targetEvidence, className: "text-tiny" })), evidenceSnippet && (_jsx("span", Object.assign({ className: "text-base" }, { children: evidenceSnippet }))), evidenceDocTitle || evidenceSnippet ? _jsx(Divider, {}) : null, evidenceInstance && (_jsxs(BorderlessButton, Object.assign({ className: "hover:text-accent-500 text-gray-600 flex items-center space-x-1", onClick: () => urlOpenerFn(targetEvidence, evidenceInstance) }, { children: [_jsx("span", { children: "Open document" }), _jsx(ShareFromSquare, {})] }))), annotations[0].constraint_id && (_jsxs(BorderlessButton, Object.assign({ className: "hover:text-accent-500 text-gray-600 flex items-center space-x-1", onClick: () => onReferenceClick && onReferenceClick(annotations[0]) }, { children: [_jsx("span", { children: "View in evidence list" }), _jsx(ShareFromSquare, {})] })))] })));
                let supText = (targetEvidence && getReferenceLabel(targetEvidence)) || text;
                if (superscriptMap[supText] && superscriptMap[supText].length > 1) {
                    const evidenceIds = superscriptMap[supText];
                    const index = evidenceIds.indexOf(annotations[0].evidence_id);
                    supText =
                        supText.substring(0, supText.length - 1) + letters[index] + "]";
                }
                const superscript = (_jsx("sup", Object.assign({ className: classes("font-bold select-none whitespace-nowrap", onReferenceClick ? "cursor-pointer" : "cursor-default"), onClick: () => onReferenceClick && onReferenceClick(annotations[0]) }, { children: supText })));
                return (_jsx(Tooltip, Object.assign({ color: "white", placement: "bottom", open: !evidenceInstance &&
                        !evidenceDocTitle &&
                        !evidenceSnippet &&
                        !annotations[0].constraint_id
                        ? false
                        : undefined, overlayClassName: "EvidenceRefernceTip", overlayStyle: {
                        maxWidth: "35rem",
                    }, title: tooltip }, { children: superscript })));
            }
            return _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: text }));
        } }));
}
