import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Allotment } from "allotment";
import { Checkbox, Divider, Radio } from "antd";
import { useAtom, useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { EvidenceType } from "../../api/types/evidenceTypes";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useUpdateCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useEvidenceSearchContext, useEvidenceSearchUtils, } from "../../appState/context/evidence/EvidenceSearchContext";
import { classes } from "../../appState/context/theme/classes";
import { useLocalStorageState } from "../../appState/localStorage/useLocalStorageState";
import { landmarkIdTag } from "../common/assist/useStartTour";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { ConfigHorizontal, FindingFilled, NL } from "../common/icons/Icons";
import { Empty } from "../common/layout/Empty";
import { Fill } from "../common/layout/Fill";
import { TitledContainer } from "../common/layout/TitledContainer";
import { useDMParamsDiagramAddOn } from "../constraintFlow/useDMParamsDiagramAddOn";
import { useShowRefutingSelectionPopover } from "../evidence/RefutingSelectionPopover";
import { roundOffThousands } from "../evidence/roundOffThousands";
import { useExportEvidenceAddOn } from "../evidence/useExportEvidenceAddOn";
import { useTrendsAddOn } from "../trends/useTrendsAddOn";
import { FindingsDropdown } from "./findingsControls/FindingsDropdown";
import { EvidenceResults } from "./toReplaceAndDelete/EvidenceResults";
import { useEvidenceAddOns } from "./useEvidenceAddOns";
import { useAnswer, useSummariesData } from "./useSummariesData";
function ModeToggle() {
    var _a;
    const { evidenceSearch } = useEvidenceSearchContext();
    const { evidenceAndTotals } = evidenceSearch;
    const totalEvidenceCount = ((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.total_count) || 0;
    const [model, setModel] = useAtom(queryStateAtoms.constraintModel);
    const [searchParams, setSearchParams] = useAtom(queryStateAtoms.searchParams);
    const supportsRefuting = useIsFeatureSupported(Feature.REFUTING_SEARCH);
    const showRefutingSelectionPopover = useShowRefutingSelectionPopover();
    const setRefutingConfiguration = React.useCallback((config) => {
        if (!model.activeConstraintId)
            return;
        setModel(Object.assign(Object.assign({}, model), { config: Object.assign(Object.assign({}, model.config), { refutingConfiguration: Object.assign(Object.assign({}, model.config.refutingConfiguration), { [model.activeConstraintId]: config }) }) }));
    }, [model, setModel]);
    const evidenceCount = `(${roundOffThousands(totalEvidenceCount)}${totalEvidenceCount && totalEvidenceCount === 10000 ? "+" : ""})`;
    const modeOptions = [
        {
            label: (_jsx("span", Object.assign({ className: "nowrap" }, { children: `Supporting${!searchParams.refuting && !evidenceAndTotals.loading
                    ? " " + evidenceCount
                    : ""}
          ` }))),
            value: EvidenceType.SUPPORTING,
        },
        {
            label: (_jsxs("span", Object.assign({ className: "flex items-center nowrap" }, { children: [`Refuting${searchParams.refuting && !evidenceAndTotals.loading
                        ? " " + evidenceCount
                        : ""}`, _jsx(ConfigHorizontal, { className: "ml-3 mr-2 hover:bg-neutral-200 rounded py-0.5 flex items-center", onClick: e => {
                            e.preventDefault();
                            e.stopPropagation();
                            showRefutingSelectionPopover(setRefutingConfiguration);
                        } })] }))),
            value: EvidenceType.REFUTING,
        },
    ];
    if (!supportsRefuting)
        return null;
    return (_jsx(Radio.Group, { className: "flex items-center nowrap", options: modeOptions, onChange: ({ target: { value } }) => {
            setSearchParams({ refuting: value === EvidenceType.REFUTING });
        }, value: searchParams.refuting ? EvidenceType.REFUTING : EvidenceType.SUPPORTING, optionType: "button" }));
}
export function EvidencePanelContents({ argsInfo, className }) {
    var _a, _b;
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const [divider, setDivider] = useLocalStorageState("EVIDENCE_PICKER_DIVIDER_LOCATION", [1, 3]);
    const disabled = invalidCorpusIds(corpus_ids);
    const [{ selectedSummaries, evidenceGroupedByDocument, showAnswer, showStructuredQuery, showSummary, },] = useCurrentSession();
    const { enableAnswerGeneration } = useFlags();
    const activeConstraint = useAtomValue(a_activeConstraint);
    const { selection, evidenceSelected, resetEvidenceSelection, selectEvidence, deselectEvidence, } = useEvidenceSelection();
    const { evidenceSearch } = useEvidenceSearchContext();
    const { evidenceAndTotals, corpus_filter, evidenceRequestParams } = evidenceSearch;
    const totalEvidenceCount = ((_a = evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.total_count) || 0;
    const { getFilteredEvidenceIdList, getFilteredDocumentIdList } = useEvidenceSearchUtils();
    const emptyQuery = useAtomValue(a_activeConstraintIsEmpty);
    const { widget, widgetSize } = useEvidenceAddOns({
        evidenceAndTotals,
        corpus_filter,
        evidenceRequestParams,
        argsInfo,
    }, useExportEvidenceAddOn, useDMParamsDiagramAddOn, useTrendsAddOn);
    const { summaries, loading: loadingSummaries } = useSummariesData(evidenceRequestParams);
    const { answer, loading: loadingAnswer } = useAnswer(evidenceRequestParams, !showAnswer || !enableAnswerGeneration || !activeConstraint.userText);
    //TODO: setFilter(AND(summariesFilter, actionsFilters))
    useUpdateCurrentContextTitle(); //TODO: remove
    const evidenceIdList = getFilteredEvidenceIdList();
    const documentIdList = getFilteredDocumentIdList();
    const someSelected = evidenceIdList.some(evidenceSelected);
    const allSelected = evidenceIdList.every(evidenceSelected);
    const disabledSelection = disabled || selection.size === 0;
    const actionBar = (_jsxs("div", Object.assign({ className: "flex flex-1 items-center" }, { children: [_jsx(Checkbox, { className: "ml-[22px] pr-2", disabled: evidenceAndTotals.loading ||
                    ((_b = evidenceAndTotals.value) === null || _b === void 0 ? void 0 : _b.total_count) === 0 ||
                    !someSelected, checked: allSelected, indeterminate: someSelected && !allSelected, onChange: () => someSelected
                    ? resetEvidenceSelection()
                    : selectEvidence(evidenceIdList) }), _jsx(FindingsDropdown, { evidenceIdList: selection.toArray(), disabledDropdown: disabledSelection || !someSelected, renderDropdownAnchor: (setMenuOpen, hasExistingFinding) => {
                    return (_jsx(BorderlessButton, Object.assign({ style: { height: "36px" }, className: "rounded-xl font-medium text-tiny mr-2", onClick: setMenuOpen, disabled: disabledSelection || !someSelected }, { children: _jsxs("div", Object.assign({ className: "flex flex-1 justify-center items-center" }, { children: [_jsx(FindingFilled, { className: classes("text-base my-auto mr-1") }), `Edit Findings (${selection.size})`] })) }), disabledSelection ? "disabled" : "enabled"));
                } })] })));
    const results = React.useMemo(() => {
        const r = [];
        if (showAnswer && enableAnswerGeneration) {
            if (loadingAnswer) {
                r.push({
                    type: "status",
                    section: "answer",
                    value: { status: "loading", message: "Generating answers..." },
                });
            }
            else if (answer.answer !== "") {
                r.push({
                    type: "answer",
                    section: "answer",
                    value: answer,
                });
            }
        }
        if (showSummary) {
            if (loadingSummaries) {
                r.push({
                    type: "status",
                    section: "summaries",
                    value: { status: "loading", message: "Generating topic summaries..." },
                });
            }
            else if (summaries.length > 0) {
                r.push({
                    type: "summaries",
                    section: "summaries",
                    value: summaries,
                });
            }
        }
        if (evidenceAndTotals.loading) {
            r.push({
                type: "status",
                section: "evidence",
                value: { status: "loading", message: "Looking for evidence..." },
            });
        }
        else if (evidenceIdList.length === 0) {
            r.push({
                type: "status",
                section: "evidence",
                value: { status: "empty", message: "No evidence found." },
            });
        }
        else if (evidenceGroupedByDocument) {
            documentIdList.map((document_id) => r.push({
                type: "document",
                section: "evidence",
                value: document_id,
            }));
        }
        else {
            evidenceIdList.map((evidence_id) => r.push({
                type: "evidence",
                section: "evidence",
                value: evidence_id,
            }));
        }
        return r;
    }, [
        answer,
        showAnswer,
        summaries,
        showSummary,
        evidenceGroupedByDocument,
        documentIdList,
        evidenceIdList,
    ]);
    const evidenceResults = (_jsx(Empty, Object.assign({ skeleton: _jsx(NL, { className: "text-gray-600", style: { fontSize: "50px" } }), loading: emptyQuery ? "Enter a query to search for evidence." : undefined }, { children: _jsxs("div", Object.assign({ className: "w-full flex flex-col flex-1" }, landmarkIdTag("tour-evidence"), { children: [_jsx("div", Object.assign({ className: "w-full flex justify-center" }, { children: _jsx("div", Object.assign({ className: "w-full flex flex-col flex-grow" }, { children: _jsx(EvidencePanelHeader, { headerSelectors: [actionBar, _jsx(ModeToggle, {}, "mode")] }) })) })), _jsx("div", Object.assign({ className: "flex flex-row flex-1" }, { children: _jsx(EvidenceResults, { results: results, disabled: disabled, evidenceSearch: evidenceSearch, totalEvidenceCount: totalEvidenceCount, filteredBySummary: selectedSummaries.length > 0 }) }))] })) })));
    const panelContent = (_jsx(Fill, Object.assign({ className: classes("flex flex-col flex-1 ml-2") }, { children: _jsx(Fill, Object.assign({ flexRow: true }, { children: _jsx(TitledContainer, Object.assign({ className: classes("flex flex-col flex-1 h-full relative", className) }, landmarkIdTag("tour-evidence"), { noScroll: true, bgClassName: "bg-transparent", containerStyleClass: "pt-0" }, { children: _jsx("div", Object.assign({ className: "flex-1 flex flex-col relative" }, { children: widget && widgetSize ? (_jsxs(Allotment, Object.assign({ vertical: true, className: "h-full", defaultSizes: divider, onChange: setDivider }, { children: [_jsx(Allotment.Pane, Object.assign({ className: "", minSize: widgetSize.min }, { children: _jsx("div", Object.assign({ className: "h-full relative flex flex-col items-stretch pt-2" }, { children: widget })) })), _jsx(Allotment.Pane, Object.assign({ minSize: 260 }, { children: _jsx("div", Object.assign({ className: "h-full relative flex flex-col items-stretch pt-2" }, { children: evidenceResults })) }))] }))) : (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "" }, { children: [widget, widget && _jsx(Divider, { className: "my-1" })] })), evidenceResults] })) })) })) })) })));
    return (_jsx(Fill, Object.assign({ flexRow: true, className: "justify-center" }, { children: panelContent })));
}
function EvidencePanelHeader({ headerSelectors, }) {
    return (_jsx("div", Object.assign({ className: "flex flex-1" }, { children: _jsx("div", Object.assign({ className: classes("w-full flex justify-between items-start items-center") }, { children: _jsx("div", Object.assign({ className: "flex flex-1 items-center space-x-2 py-1 md:ml-[175px] lg:max-w-[80rem]" }, { children: headerSelectors })) })) })));
}
