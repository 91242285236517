import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { hasHighlightsOnlyOutsideOfParse } from "../../../appState/atomic/evidence/utils/hasHighlightsOnlyOutsideOfParse";
import { highlightsOnlyOutsideOfParse } from "../../../appState/atomic/evidence/utils/highlightsOnlyOutsideOfParse";
import { uniqueHighlightsOnlyOutsideOfParse } from "../../../appState/atomic/evidence/utils/uniqueHighlightsOnlyOutsideOfParse";
import { filterMetadata } from "../../../appState/atomic/queryState/semanticSearch/filterMetadata";
import { metadataValue } from "../../../appState/atomic/queryState/semanticSearch/metadataValue";
import { classes } from "../../../appState/context/theme/classes";
import { CONCEPT_COLOR_BG, CONCEPT_COLOR_BORDER, } from "../../../appState/context/theme/lf";
import { limitLength } from "../../../utils/text/limitLength";
import { ShareFromSquare } from "../../common/icons/Icons";
import { Tooltip } from "../../common/popups/Tooltip";
import { TagsList } from "../../common/tags/TagsList";
import { MetadataInfo } from "./MetadataInfo";
export function EvidenceFooter({ evidence, onMetadataClick, showHighlights, urlOpener, suffix, className, selected, metadataSorter, }) {
    var _a;
    const metadata = metadataSorter(((_a = evidence.instances[0]) === null || _a === void 0 ? void 0 : _a.metadata) || []);
    const hiddenHighlights = highlightsOnlyOutsideOfParse(evidence);
    const uniqueMatchIdHighlights = uniqueHighlightsOnlyOutsideOfParse(evidence);
    const items = filterMetadata(metadata, true);
    return (_jsxs("div", Object.assign({ className: classes("pt-1", className) }, { children: [showHighlights && hasHighlightsOnlyOutsideOfParse(evidence) && (_jsx("div", Object.assign({ className: classes("flex flex-1 space-x-2 border-b justify-start", "pb-3 mb-2", "border-b border-neutral-200") }, { children: _jsx(TagsList, { className: "w-fit py-0.5 px-1 font-medium", widthClass: "w-[calc(100%-8em)]", items: uniqueMatchIdHighlights, tagContent: item => (_jsxs("div", Object.assign({ className: "flex align-center" }, { children: [_jsx(ShareFromSquare, { className: "mt-1 mr-1" }), item.match_name] }))), tagStyle: () => ({
                        backgroundColor: CONCEPT_COLOR_BG,
                        borderColor: CONCEPT_COLOR_BORDER,
                    }), tagTooltip: item => "View in Document", onMouseDown: item => urlOpener === null || urlOpener === void 0 ? void 0 : urlOpener(evidence, evidence.instances[0], [
                        evidence.text,
                        ...hiddenHighlights.map(x => x.match_name),
                        item.match_name,
                    ]) }) }))), _jsxs("div", Object.assign({ className: "flex flex-row flex-1 justify-between w-full py-1" }, { children: [_jsx(TagsList, { className: "ml-0 py-0.5 px-1 font-medium", widthClass: "w-[calc(100%-8em)]", items: items, tagContent: item => {
                            const value = metadataValue(item, true);
                            if (value === undefined)
                                return "";
                            return typeof value === "boolean" ? (value ? (item.name) : ("Not " + item.name // this shoulnd't becuase they should get filtered
                            )) : (_jsx(Tooltip, Object.assign({ content: _jsx(MetadataInfo, { items: [item] }) }, { children: _jsx("span", { children: limitLength(value.toString(), 40) }) })));
                        }, showMoreContent: () => _jsx(MetadataInfo, { truncate: true, items: items }), tagClassName: "my-auto", tagStyle: () => ({
                            borderColor: "#DDDDDD",
                        }), 
                        // COR-7746: disable pill filters
                        // onMouseDown={item => onMetadataClick?.(item)}
                        interactive: false, moreButtonCStyle: selected
                            ? {
                                background: "transparent",
                            }
                            : {} }), _jsx("div", Object.assign({ className: "flex flex-1 space-x-1 pl-20 justify-end" }, { children: suffix }))] }))] })));
}
