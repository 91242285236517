var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCurrentSource } from "../../api/useCurrentSource";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { useQueryState } from "../../appState/atomic/queryState/useQueryState";
import { getEmptyQueryStateForCorpus } from "../../appState/atomic/queryState/utils/getEmptyQueryStateForCorpus";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { TABS_BG } from "../../appState/context/theme/lf";
import { loadQueryState } from "../../appState/stateRestoration/loadQueryState";
import { QueryId } from "../../appState/stateRestoration/queryId";
import { limitLength } from "../../utils/text/limitLength";
import { Down, Globe } from "../common/icons/Icons";
import { TabButton } from "../common/layout/TabButton";
import { Tooltip } from "../common/popups/Tooltip";
import { showPopupMenu } from "../contextMenu/showPopupMenu";
import { showSourceInfo } from "./SourceInfo";
import { SourcesDropDown } from "./SourcesDropDown";
function handleContextMenuClick(e, corporaId) {
    e.preventDefault();
    showPopupMenu({
        left: e.clientX,
        top: e.clientY,
        entries: [
            {
                caption: "Show Info…",
                action() {
                    showSourceInfo(corporaId);
                },
            },
        ],
    });
}
export function SourcePicker() {
    const [, updateQueryState] = useQueryState();
    const corpora = Object.values(useGlobalInfo().corporaById);
    const selected = useCurrentSource();
    const [{ lastQueryIdByCorpusId }] = useCurrentSession();
    return corpora.length === 1 ? (_jsxs("div", Object.assign({ className: "flex items-center text-tiny font-medium pr-2", onContextMenu: e => handleContextMenuClick(e, corpora[0].id) }, { children: [_jsx(Globe, {}), "\u00A0", _jsx("span", { children: corpora[0].name /* TODO: quite the bold assumption */ })] }))) : (_jsx(SourcesDropDown, Object.assign({ selectedSourceId: selected === null || selected === void 0 ? void 0 : selected.id, onSelect: (c) => __awaiter(this, void 0, void 0, function* () {
            const latestQueryId = lastQueryIdByCorpusId[c.id];
            if (latestQueryId) {
                QueryId.set(latestQueryId);
                const state = yield loadQueryState(latestQueryId, true);
                updateQueryState(state);
            }
            else {
                updateQueryState(getEmptyQueryStateForCorpus([c.id]));
            }
        }) }, { children: _jsx(Tooltip, Object.assign({ content: "Evidence Corpus Source", placement: "bottom-end" }, { children: _jsxs(TabButton, Object.assign({ onContextMenu: selected && (e => handleContextMenuClick(e, selected.id)), className: TABS_BG, "data-test-id": "corpusId" }, { children: [_jsx(Globe, { className: "text-base " }), _jsx("div", Object.assign({ className: "" }, { children: limitLength(selected === null || selected === void 0 ? void 0 : selected.name, 24) || "No Source Selected" })), _jsx(Down, { className: "text-xs" })] })) })) })));
}
