import React from "react";
import { useAuth } from "react-oidc-context";
import { useAuthState } from "../useAuthState";
export function OIDCStateUpdater() {
    const { setUserInfo, setUserProfile, setIsLoading, setIsAuthenticated, setLoginWithRedirect, } = useAuthState();
    const { user, isLoading, isAuthenticated, signinRedirect } = useAuth();
    React.useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);
    React.useEffect(() => {
        setIsAuthenticated(isAuthenticated);
    }, [isAuthenticated]);
    React.useEffect(() => {
        setLoginWithRedirect(() => {
            return (olpConfig) => {
                return signinRedirect();
            };
        });
    }, [signinRedirect, setLoginWithRedirect]);
    React.useEffect(() => {
        if (user) {
            setUserInfo({
                userId: user.profile.nickname,
                userName: user.profile.name,
                orgId: user.profile.sub,
            });
            setUserProfile({
                name: user.profile.name,
                email: user.profile.name,
            });
        }
    }, [user]);
    return null;
}
