import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Allotment } from "allotment";
import { useArgsInfo } from "../../appState/atomic/queryState/useArgsInfo";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { EvidenceSearchProvider } from "../../appState/context/evidence/EvidenceSearchContext";
import { useTheme } from "../../appState/context/theme/ThemeContext";
import { classes } from "../../appState/context/theme/classes";
import { landmarkIdTag } from "../common/assist/useStartTour";
import { Fill } from "../common/layout/Fill";
import { CRCFacets } from "../facet/CRCFacets";
import { MyFindings } from "../findings/MyFindings";
import { FindingsPanel } from "../findingsRedesign/FindingsPanel";
import { ModelBuilder } from "../modelBuilder/ModelBuilder";
import { EvidencePanel } from "../results/EvidencePanel";
import { ConstraintsBar } from "./ConstraintsBar";
import { PanelBar } from "./PanelBar";
import { Toolbar } from "./Toolbar";
export function CoraMainContent() {
    const theme = useTheme();
    const [session] = useCurrentSession();
    const { showFilters, modelBuilderMode, modelBuilderAvailable, showMyFindings, alternativeEvidenceLayout, alternativeFindingsLayout, } = session;
    const modelBuilderVisible = modelBuilderMode && modelBuilderAvailable;
    const argsInfo = useArgsInfo();
    const facets = showFilters && !modelBuilderVisible && (_jsx("div", Object.assign({ className: classes("flex flex-col space-y-4 justify-center px-2 h-full") }, landmarkIdTag("tour-filters"), { id: "Facets", style: {
            borderRightWidth: 1,
            borderColor: theme.colors.border_primary,
        } }, { children: _jsx(CRCFacets, { argsInfo: argsInfo }) })));
    return (_jsx(EvidenceSearchProvider, { children: _jsxs("div", Object.assign({ className: "flex flex-1" }, { children: [_jsx(Toolbar, {}), _jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch" }, { children: [_jsx(ConstraintsBar, {}), _jsx("div", Object.assign({ className: classes("flex-1 pl-0 flex flex-row items-stretch bg-background") }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex flex-row items-stretch" }, { children: [_jsx("div", Object.assign({ className: "flex-1 flex items-stretch space-x-2 mr-2" }, { children: _jsxs(Allotment, { children: [_jsx(Allotment.Pane, Object.assign({}, (modelBuilderVisible
                                                    ? {
                                                        minSize: 400,
                                                        preferredSize: 800,
                                                        visible: true,
                                                    }
                                                    : {
                                                        minSize: 225,
                                                        maxSize: 400,
                                                        preferredSize: 380,
                                                        visible: showFilters,
                                                    }), { children: _jsx(Fill, { children: modelBuilderVisible ? _jsx(ModelBuilder, {}) : facets }) })), _jsx(Allotment.Pane, Object.assign({}, {
                                                    minSize: 400,
                                                    preferredSize: 800,
                                                }, { children: _jsx(Fill, Object.assign({ className: "pl-2" }, { children: _jsx(EvidencePanel, {}) })) }))] }, modelBuilderVisible ? "withGraph" : "withFacets") })), showMyFindings &&
                                        (alternativeFindingsLayout ? (_jsx(FindingsPanel, {})) : (_jsx(MyFindings, {})))] })) }))] })), alternativeEvidenceLayout && _jsx(PanelBar, { argsInfo: argsInfo })] })) }));
}
