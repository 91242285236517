var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { uniq } from "lodash";
import { createClusterId } from "../../../../utils/identity/createClusterId";
import { uuidv4 } from "../../../../utils/identity/uuidv4";
import { queryStateAtoms } from "../queryStateAtoms";
import { a_activeConstraint } from "./constraints";
const a_qualifiers = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.qualifiers"));
const a_requiredQualifiers = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.requiredQualifiers"));
const a_qualifierNodeIds = (a_constraint) => focusAtom(a_constraint, optic => optic.prop("qualifierNodeIds"));
export const a_addQualifierKey = atom(null, (get, set, qualifierKey, qualifierId) => {
    set(a_qualifierNodeIds(a_activeConstraint), qualifierNodeIds => (Object.assign(Object.assign({}, qualifierNodeIds), { [qualifierKey]: qualifierId })));
});
export const a_removeQualifierKey = atom(null, (get, set, qualifierKey) => {
    set(a_qualifierNodeIds(a_activeConstraint), qualifierNodeIds => {
        const _a = qualifierNodeIds, _b = qualifierKey, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        return rest;
    });
});
const a_addClauseToQualifierNodeId = atom(null, (get, set, qualifierNodeId, clauseId) => {
    set(a_qualifiers, qualifiers => {
        var _a;
        return (Object.assign(Object.assign({}, qualifiers), { [qualifierNodeId]: uniq([
                ...((_a = qualifiers[qualifierNodeId]) !== null && _a !== void 0 ? _a : []),
                clauseId,
            ]) }));
    });
    set(a_requiredQualifiers, requiredQualifiers => (Object.assign(Object.assign({}, requiredQualifiers), { [qualifierNodeId]: true })));
});
const a_addClauseToRegistry = atom(null, (get, set, clauseId, clause) => {
    const a_clauses = focusAtom(queryStateAtoms.constraintModel, O => O.path("data.clauses"));
    set(a_clauses, clauses => (Object.assign(Object.assign({}, clauses), { [clauseId]: clause })));
});
export const a_addQualifier = atom(null, (get, set, qualifierKey, clause) => {
    const clauseId = createClusterId(clause);
    let nodeId = get(a_qualifierNodeIds(a_activeConstraint))[qualifierKey];
    if (!nodeId) {
        nodeId = uuidv4();
    }
    set(a_addClauseToRegistry, clauseId, clause);
    set(a_addClauseToQualifierNodeId, nodeId, clauseId);
    set(a_addQualifierKey, qualifierKey, nodeId);
});
export const a_resetClasuesForQualifierKey = atom(null, (get, set, qualifierKey) => {
    const qualifierNodeId = get(a_qualifierNodeIds(a_activeConstraint))[qualifierKey];
    if (!qualifierNodeId) {
        return;
    }
    set(a_qualifiers, qualifiers => {
        const _a = qualifiers, _b = qualifierNodeId, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        return rest;
    });
    set(a_removeQualifierKey, qualifierKey);
});
export const a_resetAllClauses = atom(null, (get, set) => {
    const constraintQualifierKeys = Object.keys(get(a_qualifierNodeIds(a_activeConstraint)));
    constraintQualifierKeys.forEach(qualifierKey => {
        set(a_resetClasuesForQualifierKey, qualifierKey);
    });
});
