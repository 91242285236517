import { isEqual } from "lodash";
import React from "react";
export function useValueChanged(value) {
    const ref = React.useRef(value);
    const changed = !isEqual(ref.current, value);
    React.useLayoutEffect(() => {
        ref.current = value;
    });
    return changed;
}
