import customColors from "../../../../assets/colors";
export const columnMinWidthClass1_25 = "min-w-[285px]";
export const columnWidthClass1 = "w-[228px] min-w-[228px] max-w-[228px]";
export const columnWidthClass2 = "w-[440px] min-w-[440px] max-w-[440px]";
export const columnWidthClass3 = "w-[660px] min-w-[660px] max-w-[660px]";
export const headerHeightClass = "h-[72px]";
export const findingBorderClass = "border-gray-400 placeholder-gray-500";
export const brandBorderClass = "border-gray-400 placeholder-gray-500";
export const brandButtonClass = "text-white focus:text-white focus:bg-gray-500 focus:border-gray-500 bg-gray-500 border-gray-500 hover:bg-gray-400 hover:border-gray-400";
export const blueButtonClass = "text-white focus:text-white focus:bg-brand_blue-500 focus:border-brand_blue-500 bg-brand_blue-500 border-brand_blue-500 hover:bg-brand_blue-400 hover:border-brand_blue-400";
export const coloredTextInfoClass = "border-brand_blue-300 bg-brand_blue-200";
export const hoverSelectedCardClass = "hover:z-10 hover:shadow-2xl ";
// "hover:z-10 hover:shadow-2xl hover:translate-x-[-1px] hover:translate-y-[-1px]"
export const selectedCardClass = "z-10 shadow-2xl ";
// "z-10 shadow-2xl translate-x-[-1px] translate-y-[-1px]"
export const headerInputClass = "HeaderInput border-gray-500 bg-gray-500 children:bg-gray-500 children:text-white children:text-right text-right";
export const BRAND_COLOR_VALS = customColors.accent;
export const CONCEPT_1_COLOR_VALS = customColors.notification_blue;
export const CONCEPT_1_COLOR = CONCEPT_1_COLOR_VALS[500];
export const CONCEPT_2_COLOR_VALS = customColors.alert_yellow;
export const CONCEPT_2_COLOR = CONCEPT_2_COLOR_VALS[500];
export const RELATION_COLOR_VALS = customColors.alert_green;
export const RELATION_COLOR = RELATION_COLOR_VALS[500];
export const CONTEXT_CONCEPT_COLOR_VALS = customColors.accent;
export const CONTEXT_CONCEPT_COLOR = CONTEXT_CONCEPT_COLOR_VALS[600];
export const NEUTRAL_COLOR = "gray";
export const NEUTRAL_COLOR_VALS = customColors.neutral;
export const CONCEPT_COLOR_BORDER = CONTEXT_CONCEPT_COLOR;
export const CONCEPT_COLOR_BG = customColors.accent[100];
export const TABS_BG = "bg-background_header text-text_header";
const selectedBg = customColors.accent[500];
export const selectedBgClass = "bg-primary text-white";
export const CATEGORYCAL = [
    "#ff8fb2",
    "#00B3A4",
    "#DB2C6F",
    "#7157D9",
    "#96622D",
    "#D13913",
    "#8F398F",
];
