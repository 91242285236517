import { Map } from "immutable";
import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
const a_feedback = atomWithReset(Map());
export function useEvidenceFeedback() {
    const [feedback, setFeedback] = useAtom(a_feedback);
    const resetEvidenceFeedback = useResetAtom(a_feedback);
    const updateFeedback = (evidenceId, type) => {
        //TODO: send feedback
        setFeedback(feedback.set(evidenceId, type));
    };
    return {
        feedback,
        updateFeedback,
        resetEvidenceFeedback,
    };
}
