import { Set } from "immutable";
import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
const a_selection = atomWithReset(Set([]));
export function useEvidenceSelection() {
    const [selection, setSelection] = useAtom(a_selection);
    const evidenceSelected = (evidenceId) => selection.includes(evidenceId);
    const selectEvidence = (evidenceIds) => {
        setSelection(selection.union(evidenceIds));
    };
    const deselectEvidence = (evidenceIds) => {
        setSelection(selection.subtract(evidenceIds));
    };
    const resetEvidenceSelection = useResetAtom(a_selection);
    return {
        selection,
        evidenceSelected,
        selectEvidence,
        deselectEvidence,
        resetEvidenceSelection,
    };
}
