import { useAtomValue } from "jotai";
import { take, uniq } from "lodash";
import React from "react";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useCurrentSession } from "../../appState/atomic/session/session";
export function useQuestionHistory() {
    const [{ questionsByCorpusId }, sessionUpdater] = useCurrentSession();
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const corpusId = corpus_ids[0];
    const history = React.useMemo(() => questionsByCorpusId[corpusId] || [], [questionsByCorpusId, corpusId]);
    const add = React.useCallback((question) => {
        if (!corpusId)
            return;
        const questions = take(uniq([question, ...history]), 100); // limit history to 100 unique items
        sessionUpdater({
            questionsByCorpusId: Object.assign(Object.assign({}, questionsByCorpusId), { [corpusId]: questions }),
        });
    }, [questionsByCorpusId, corpusId, history, sessionUpdater]);
    const remove = React.useCallback((question) => {
        if (!corpusId)
            return;
        const questions = uniq(history.filter(q => q !== question));
        sessionUpdater({
            questionsByCorpusId: Object.assign(Object.assign({}, questionsByCorpusId), { [corpusId]: questions }),
        });
    }, [questionsByCorpusId, corpusId, history, sessionUpdater]);
    const clear = React.useCallback(() => {
        if (!corpusId)
            return;
        sessionUpdater({
            questionsByCorpusId: Object.assign(Object.assign({}, questionsByCorpusId), { [corpusId]: [] }),
        });
    }, [questionsByCorpusId, corpusId, history, sessionUpdater]);
    return { history, add, remove, clear };
}
