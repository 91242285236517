import React from "react";
import { useFeatureFlags } from "../appState/atomic/featureFlags/useFeatureFlags";
import { getConfig } from "../appState/config/serverConfig";
export function useLogRocket() {
    const { enableLogrocketIntegration } = useFeatureFlags();
    //TODO: disable if flag turns false
    React.useEffect(() => {
        try {
            const lrAppId = getConfig("LOGROCKET_APP_ID", "");
            if (enableLogrocketIntegration && lrAppId.length > 0) {
                const LogRocket = require("logrocket");
                console.log("LogRocket enabled");
                LogRocket.init(lrAppId, {
                    console: {
                        shouldAggregateConsoleErrors: true,
                    },
                });
                return;
            }
        }
        catch (e) {
            // unable to initialize LogRocket
            // most likely not set in config
        }
        console.log("LogRocket disabled");
    }, [enableLogrocketIntegration]);
}
