import { List } from "immutable";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
export const EMPTY_QUERY_HISTORY_STATE = {
    queryHistory: List(),
};
const _atoms = {
    queryHistory: atomWithReset(EMPTY_QUERY_HISTORY_STATE.queryHistory),
};
export const QUERY_HISTORY_STATE_KEYS = Object.keys(_atoms);
export const a_queryHistoryState = atom(get => {
    return {
        queryHistory: get(_atoms.queryHistory),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_queryHistoryState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
