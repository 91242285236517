var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { textPrompt } from "../../components/common/modals/Propmpt";
export const CSV_MIME_TYPE = "text/csv";
export const RTF_MIME_TYPE = "text/rtf";
export const TEXT_MIME_TYPE = "text/*";
const DocTypes = {
    json: "json",
};
const SupportedMineTypes = {
    "application/json": "json",
};
export const FILE_TYPES = Object.keys(DocTypes)
    .map(ex => "*." + ex)
    .concat(Object.keys(SupportedMineTypes))
    .join(",");
function prepareElement(el) {
    el.id = `${Math.random()}${Math.random()}`;
    el.style.display = "none";
    document.body.appendChild(el);
}
export function pickFiles(fileTypes, multiple = true) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            const el = document.createElement("input");
            prepareElement(el);
            el.setAttribute("type", "file");
            fileTypes && el.setAttribute("accept", fileTypes);
            multiple && fileTypes && el.setAttribute("multiple", "multiple");
            let files = null;
            function removeInput() {
                const e = document.getElementById(el.id);
                e && document.body.removeChild(e);
                window.removeEventListener("focus", focusListener);
            }
            el.addEventListener("change", e => {
                var _a;
                const result = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files;
                files = result ? [...result] : null;
            }, { once: true });
            const focusListener = () => {
                setTimeout(() => {
                    resolve(files);
                    removeInput();
                }, 500);
            };
            el.click();
            window.addEventListener("focus", focusListener, { once: true });
        });
    });
}
export function saveTextFile(fileName, type, context) {
    const el = document.createElement("a");
    prepareElement(el);
    el.href = window.URL.createObjectURL(new Blob([context], { type }));
    el.download = fileName;
    el.click();
    setTimeout(() => {
        const e = document.getElementById(el.id);
        e && document.body.removeChild(e);
    }, 2000);
}
export function inputFileName(title) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield textPrompt({ title, placeholder: "File Name…" });
    });
}
