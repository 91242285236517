import { upsertArray } from "../../../../utils/generic/collections";
function cleanupMetadata(...values) {
    return values
        .filter(v => v)
        .flat()
        .map(m => ({
        id: m.id,
        value: m.value,
    }));
}
export function getMetadataFilter({ keywordMetadata, rangeMetadata, booleanMetadata, }) {
    const cleaned = cleanupMetadata(keywordMetadata, rangeMetadata, booleanMetadata);
    /*
    const cleanedOverride = cleanupMetadata(
      rangeMetadataOverride
    ) as MetadataFilter[]
    */
    const { result } = upsertArray(cleaned, [], m => m.id);
    return result.length ? { metadata: result } : undefined;
}
