import { EMPTY_QUERY_STATE } from "../verions/V3_QueryState";
import { coraStateToV3QueryState } from "./migrators/stateV1toV3";
import { v2QueryStateToV3QueryState } from "./migrators/stateV2toV3";
import { VersionError, VersionMigrationSystem } from "./VersionMigrationSystem";
const CURRENT_VERSION = 4;
function detectStateVersion(state) {
    console.log("state to migrate:", state);
    if (!state) {
        return 3;
    }
    if (state.concepts1 || state.concepts1Override) {
        return 1;
    }
    if (state.overrides || state.extra || state.extraOverrides) {
        return 2;
    }
    if (state.constraintModel &&
        state.scope &&
        state.searchParams &&
        state.modelBuilderState &&
        !state.multihopConfig) {
        return 3;
    }
    if (state.multihopConfig && state.multihopConfig.discoveryStrategy) {
        return 4;
    }
    throw new VersionError("Unknown version");
}
const stateMigrator = new VersionMigrationSystem();
stateMigrator.registerMigrator(1, 3, state => {
    const v1CoraState = state;
    return coraStateToV3QueryState(v1CoraState);
});
stateMigrator.registerMigrator(2, 3, state => {
    const v2QueryState = state;
    return v2QueryStateToV3QueryState(v2QueryState);
});
stateMigrator.registerMigrator(3, 4, state => {
    const v3QueryState = state;
    return Object.assign(Object.assign({}, v3QueryState), { multihopConfig: {
            discoveryStrategy: "NONE",
        } });
});
export function migrateState(state) {
    try {
        const version = detectStateVersion(state);
        const result = stateMigrator.migrate(state, version, CURRENT_VERSION);
        return result;
    }
    catch (error) {
        if (error instanceof VersionError) {
            console.warn("VersionError: ", error.message);
        }
        else {
            console.error("Error: ", error);
        }
        return EMPTY_QUERY_STATE;
    }
}
