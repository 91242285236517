import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined, RightOutlined } from "@ant-design/icons/lib";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { lineClampStyle } from "../../../appState/context/theme/themeUtils";
import { ActionButton } from "../buttons/ActionButton";
export function Card({ title, content, actions, className, onClick, onContextMenu, brandingColorClass, bgColorClass, banners, lineClamp, tags, extra, barOffset = -8, narrow, titleClass = "text-[14px] mb-2", cardLookClass = "rounded-lg  bg-white border", spacingClass, id, defaultOpen, }) {
    const ref = React.useRef(null);
    const canCollapse = Boolean(title && lineClamp);
    const startClosed = defaultOpen !== undefined ? !defaultOpen : canCollapse;
    const [collapsed, setCollapsed] = React.useState(startClosed);
    return (_jsxs("div", Object.assign({ id: id, className: classes("relative flex flex-col items-stretch  divide-y", cardLookClass, spacingClass || "px-4 py-2", className, bgColorClass), onClick: onClick, onContextMenu: onContextMenu }, { children: [title && (_jsx("div", Object.assign({ className: titleClass, style: { fontFamily: "Inter" } }, { children: title }))), content && (_jsx("div", Object.assign({ style: lineClamp === undefined || !collapsed
                    ? undefined
                    : lineClampStyle(lineClamp), ref: ref }, { children: content }))), tags, !banners && !actions && !canCollapse ? null : (_jsxs("div", Object.assign({ className: "flex space-x-2 pt-1" }, { children: [banners ? banners : _jsx("div", { className: "flex-1" }), _jsxs("div", Object.assign({ className: "flex space-x-1 items-center" }, { children: [actions, canCollapse && (_jsx(ActionButton, Object.assign({ onClick: () => {
                                    setCollapsed(c => !c);
                                } }, { children: collapsed ? _jsx(RightOutlined, {}) : _jsx(DownOutlined, {}) })))] }))] }))), extra] })));
}
