import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from "antd";
import React from "react";
import { BrandButton } from "../common/buttons/BrandButton";
import "./SelectionModal.less";
export function SelectionModal({ width = "80%", wrapClassName = "absolute", bodyStyle = {
    height: 256,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rec",
}, title, visible = true, closable = true, destroyOnClose = true, onOk, okText = "Apply", okButtonProps, onCancel, cancelText = "Cancel", cancelButtonProps, children, }) {
    const customTitle = _jsx("div", Object.assign({ className: "text-base font-medium" }, { children: title }));
    const customFooter = (_jsxs("div", Object.assign({ className: "flex space-x-2 justify-end" }, { children: [_jsx(BrandButton, Object.assign({ onClick: e => onCancel(e) }, cancelButtonProps, { children: cancelText })), _jsx(BrandButton, Object.assign({ onClick: e => onOk(e), type: "primary" }, okButtonProps, { children: okText }))] })));
    return (_jsx(Modal, Object.assign({ mask: true, maskClosable: false, className: "SelectionModal", width: width, wrapClassName: wrapClassName, bodyStyle: bodyStyle, title: customTitle, closable: closable, open: visible, destroyOnClose: destroyOnClose, footer: customFooter, onCancel: onCancel }, { children: children })));
}
