import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
import { useResettableState } from "../../utils/useResettableState";
export const EMPTY_DISPLAY_STATE = {
    showFilters: true,
    showModelGraph: false,
    showMyFindings: false,
    showStructuredQuery: false,
    showSummary: false,
    showTrends: false,
    showQueryGraph: false,
    modelBuilderMode: false,
    visiblePanels: [],
};
const _atoms = {
    showFilters: atomWithReset(EMPTY_DISPLAY_STATE.showFilters),
    showModelGraph: atomWithReset(EMPTY_DISPLAY_STATE.showModelGraph),
    showMyFindings: atomWithReset(EMPTY_DISPLAY_STATE.showMyFindings),
    showStructuredQuery: atomWithReset(EMPTY_DISPLAY_STATE.showStructuredQuery),
    showSummary: atomWithReset(EMPTY_DISPLAY_STATE.showSummary),
    showTrends: atomWithReset(EMPTY_DISPLAY_STATE.showTrends),
    modelBuilderMode: atomWithReset(EMPTY_DISPLAY_STATE.modelBuilderMode),
    showQueryGraph: atomWithReset(EMPTY_DISPLAY_STATE.showQueryGraph),
    visiblePanels: atomWithReset(EMPTY_DISPLAY_STATE.visiblePanels),
};
export const DISPLAY_STATE_KEYS = Object.keys(_atoms);
export const a_displayState = atom(get => {
    return {
        showFilters: get(_atoms.showFilters),
        showModelGraph: get(_atoms.showModelGraph),
        showMyFindings: get(_atoms.showMyFindings),
        showStructuredQuery: get(_atoms.showStructuredQuery),
        showSummary: get(_atoms.showSummary),
        showTrends: get(_atoms.showTrends),
        showQueryGraph: get(_atoms.showQueryGraph),
        modelBuilderMode: get(_atoms.modelBuilderMode),
        visiblePanels: get(_atoms.visiblePanels),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_displayState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
export function useDisplayState() {
    return useResettableState(a_displayState);
}
