import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
export const EMPTY_DOMAIN_MODEL_STATE = {
    selectedDMConceptsByCorpusAndModelId: {},
    selectedDomainModelByCorpusId: {},
};
const _atoms = {
    selectedDMConceptsByCorpusAndModelId: atomWithReset(EMPTY_DOMAIN_MODEL_STATE.selectedDMConceptsByCorpusAndModelId),
    selectedDomainModelByCorpusId: atomWithReset(EMPTY_DOMAIN_MODEL_STATE.selectedDomainModelByCorpusId),
};
export const DOMAIN_MODEL_STATE_KEYS = Object.keys(_atoms);
export const a_domainModelState = atom(get => {
    return {
        selectedDMConceptsByCorpusAndModelId: get(_atoms.selectedDMConceptsByCorpusAndModelId),
        selectedDomainModelByCorpusId: get(_atoms.selectedDomainModelByCorpusId),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_domainModelState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
