import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSetAtom } from "jotai";
import { groupBy } from "lodash";
import React from "react";
import { GroupedVirtuoso } from "react-virtuoso";
import { useCurrentSource } from "../../api/useCurrentSource";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { a_metadataUpserter } from "../../appState/atomic/queryState/constraintModel/metadataUpserter";
import { sortMetadata } from "../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { useEvidenceSearchUtils } from "../../appState/context/evidence/EvidenceSearchContext";
import { VisualStatusMessage } from "../common/status/VisualStatusMessage";
import { useUrlOpener } from "../evidence/useUrlOpener";
import { SummariesList } from "./SummariesList";
import { AnswerCard } from "./cards/AnswerCard";
import { DocumentCard } from "./cards/DocumentCard";
import { EvidenceCard } from "./cards/EvidenceCard";
import { EvidenceFilter } from "./evidenceControls/EvidenceFilter";
import { useEvidenceListPagination } from "./useEvidenceListPagination";
export function GenericEvidenceResults({ results, evidenceSearch, qualifiers, }) {
    const { getDocumentEvidence, hasHighConfidenceResults } = useEvidenceSearchUtils();
    const [{ expandedEvidence, findings, structuredQuery: sq, showStructuredQuery },] = useCurrentSession();
    const { suggestEvidence } = useSuggestedEvidence();
    const { enableEvidenceFilterInput } = useFeatureFlags();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const structuredQuery = sq && showStructuredQuery;
    const source = useCurrentSource();
    const { corpus_filter, pageSize } = evidenceSearch;
    const currentContextTitle = useCurrentContextTitle();
    const metadataUpserter = useSetAtom(a_metadataUpserter);
    const { paginationFooter, virtuosoRef } = useEvidenceListPagination(pageSize);
    const [collapsedDocuments, setCollapsedDocuments] = React.useState({});
    const setCollapsedDoc = React.useCallback((docId, collapsed) => {
        setCollapsedDocuments(Object.assign(Object.assign({}, collapsedDocuments), { [docId]: collapsed }));
    }, [collapsedDocuments, setCollapsedDocuments]);
    const urlOpenerFn = useUrlOpener({
        suggestEvidence,
        currentContextTitle,
        findings,
        structuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        source,
    });
    //TODO: review use callback
    const itemRender = React.useCallback((item, idx) => {
        if (item.type === "document") {
            const documentEvidence = getDocumentEvidence(item.value);
            return (documentEvidence && (_jsx(DocumentCard, { className: "mr-1", document_id: item.value, actionsTooltip: "Give Feedback", showSurrounding: expandedEvidence, onMetadataClick: (e) => {
                    metadataUpserter(e);
                }, urlOpenerFn: urlOpenerFn, showHighlights: true, metadataSorter: sortMetadata(source), collapsed: Boolean(collapsedDocuments[documentEvidence[0].instances[0].document_id]), setCollapsed: collapsed => setCollapsedDoc(documentEvidence[0].instances[0].document_id, collapsed) })));
        }
        else if (item.type === "evidence") {
            return (_jsx(EvidenceCard, { className: "mr-1", evidence_id: item.value, actionsTooltip: "Give Feedback", showSurrounding: expandedEvidence, onMetadataClick: (e) => {
                    metadataUpserter(e);
                }, urlOpenerFn: urlOpenerFn, showHighlights: true, metadataSorter: sortMetadata(source) }));
        }
        else if (item.type === "answer") {
            const question = corpus_filter.queries && corpus_filter.queries.length > 0
                ? corpus_filter.queries[0].text
                : null;
            return (question && _jsx(AnswerCard, { question: question, answer: item.value }));
        }
        else if (item.type === "summaries") {
            return _jsx(SummariesList, { summaries: item.value });
        }
        else if (item.type === "status") {
            return (_jsx(VisualStatusMessage, { className: "mb-[50px]", status: item.value.status, message: item.value.message }));
        }
    }, [
        urlOpenerFn,
        expandedEvidence,
        structuredQuery,
        getDocumentEvidence,
        suggestEvidence,
        collapsedDocuments,
        metadataUpserter,
    ]);
    const itemWrapper = React.useCallback((item, idx) => {
        const itemContent = itemRender(item, idx);
        return (_jsx("div", Object.assign({ className: "md:ml-[175px] lg:max-w-[80rem]" }, { children: itemContent }), idx));
    }, [itemRender]);
    const groupedResults = groupBy(results, "section");
    const groupCounts = Object.values(groupedResults).map(g => g.length);
    const groups = Object.keys(groupedResults);
    const highConfidence = hasHighConfidenceResults();
    return (_jsxs("div", Object.assign({ className: "flex flex-col flex-1" }, { children: [_jsx("div", Object.assign({ className: "flex flex-col flex-1" }, { children: _jsx(GroupedVirtuoso, { ref: virtuosoRef, groupCounts: groupCounts, groupContent: index => {
                        let label = "";
                        let qualifier = "";
                        let controls = null;
                        switch (groups[index]) {
                            case "evidence":
                                label = "Evidence";
                                qualifier = qualifiers["evidence"];
                                controls = enableEvidenceFilterInput ? (_jsx(EvidenceFilter, { disabled: false })) : undefined; //TODO: handle disabled
                                break;
                            case "summaries":
                                label = "Topic Summaries";
                                qualifier = "generated from top related evidence clusters";
                                break;
                            case "answer":
                                label = "Answer";
                                qualifier = "generated from top ranking evidence";
                                break;
                        }
                        return (_jsx("div", Object.assign({ className: "md:relative", 
                            // shut virtuoso up about 0 size elements
                            style: {
                                minWidth: "1px",
                                minHeight: "1px",
                                backgroundColor: "transparent",
                            } }, { children: _jsx("div", Object.assign({ className: "md:absolute md:max-w-[150px]", style: { top: 0, zIndex: 9999 } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col bg-background min-w-full pb-2 border-b border-b-gray-400 md:border-b-0 md:pb-0 md:min-h-[200px]" }, { children: [_jsx("h2", Object.assign({ className: "text-lg" }, { children: label })), controls, label === "Evidence" &&
                                            !evidenceSearch.evidenceAndTotals.loading &&
                                            !highConfidence && (_jsx("span", Object.assign({ className: "text-sm font-semibold mb-2" }, { children: "No high confidence results." }))), _jsx("span", Object.assign({ className: "text-sm" }, { children: qualifier }))] })) })) })));
                    }, itemContent: index => {
                        return itemWrapper(results[index], index);
                    } }) })), paginationFooter] })));
}
