import { API } from "../../../api/api";
import { useHasCapability } from "../../../api/useHasCapability";
import { useRemoteQuery } from "../../../api/useRemoteQuery";
import { CorpusCapability } from "../../../api/utils/capabilities";
import { EXPANSION_COUNT } from "./consts/EXPANSION_COUNT";
import { mergeNamedMembersToList } from "./semanticSearch/mergeNamedMembers";
import { mergeRelationClusters } from "./semanticSearch/mergeRelationClusters";
import { invalidCorpusIds } from "./utils/invalidCorpusIds";
function getRelationsExpansionParams({ relations, concepts1, concepts2, corpus_ids, aperture, }) {
    return {
        corpus_ids,
        cluster: mergeRelationClusters(relations)[0],
        context_concepts: mergeNamedMembersToList(concepts1).concat(mergeNamedMembersToList(concepts2)),
        count: EXPANSION_COUNT,
        aperture,
    };
}
export function useRelationsExpansions(relations, corpus_ids, concepts1, concepts2, aperture, enabled) {
    const hasCapability = useHasCapability(CorpusCapability.SUGGEST_RELATIONS);
    const hasParams = enabled &&
        hasCapability &&
        !invalidCorpusIds(corpus_ids) &&
        relations.length;
    const params = !hasParams
        ? undefined
        : getRelationsExpansionParams({
            concepts1,
            relations,
            concepts2,
            corpus_ids,
            aperture,
        });
    const remote = useRemoteQuery({
        loader: API.relation_expansion,
        params,
    });
    return { remote: hasCapability ? remote : null, params, hasCapability };
}
