import { atom, useAtom } from "jotai";
import { omit } from "lodash";
import { API } from "../../api/api";
import { getContextCorpusIds, getContextUrl } from "../context/findings/findings";
import { LOADING } from "./evidence/consts/LOADING";
const a_evidence = atom({});
export function useSuggestedEvidence() {
    const [suggestedEvidence, setSuggestedEvidence] = useAtom(a_evidence);
    function evictSuggestedEvidenceFor(id) {
        setSuggestedEvidence(evidence => omit(evidence, id));
    }
    function suggestEvidence(finding) {
        var _a, _b, _c;
        const { id, context, payload } = finding;
        const url = getContextUrl(payload.context) || "";
        if (suggestedEvidence[id])
            return;
        setSuggestedEvidence(evidence => (Object.assign(Object.assign({}, evidence), { [id]: LOADING })));
        API.suggest_evidence({
            evidence: ((_a = payload.evidence[0]) === null || _a === void 0 ? void 0 : _a.text) || payload.selectedText,
            finding: payload.selectedText,
            url: url,
            source_document_title: ((_c = (_b = payload.context) === null || _b === void 0 ? void 0 : _b.instances[0]) === null || _c === void 0 ? void 0 : _c.title) || "",
            count: 5,
            corpus_ids: getContextCorpusIds(context),
        })
            .then(value => {
            setSuggestedEvidence(evidence => (Object.assign(Object.assign({}, evidence), { [id]: { value, loading: false } })));
        })
            .catch(error => {
            setSuggestedEvidence(evidence => (Object.assign(Object.assign({}, evidence), { [id]: { value: [], loading: false } })));
            console.error(error);
        });
    }
    return {
        suggestedEvidence,
        setSuggestedEvidence,
        evictSuggestedEvidenceFor,
        suggestEvidence,
    };
}
