var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EllipsisOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown } from "antd";
import { useAtomValue } from "jotai";
import React from "react";
import { FeatureIdentifier, FeedbackType, } from "../../api/types/apiTypes";
import { useCurrentSource } from "../../api/useCurrentSource";
import { useEvidenceFeedback } from "../../appState/atomic/evidence/useEvidenceFeedback";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { useAnnotationProvider } from "../../appState/atomic/queryState/useAnnotationProvider";
import { useCurrentContextTitle } from "../../appState/atomic/queryState/useCurrentContextTitle";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useSuggestedEvidence } from "../../appState/atomic/suggestedEvidence";
import { classes } from "../../appState/context/theme/classes";
import { sendFeedback } from "../../utils/feedback/sendFeedback";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { Document, Finding, FindingFilled, ThumbsDown, ThumbsUp, } from "../common/icons/Icons";
import { Tooltip } from "../common/popups/Tooltip";
import { showNotification } from "../common/status/notifications";
import { FindingsDropdown } from "../results/findingsControls/FindingsDropdown";
import { DocumentSearchResults } from "./DocumentSearchResults";
import { EvidenceSearchResults } from "./EvidenceSearchResults";
import { LabeledSection } from "./LabeledSection";
import { useUrlOpener } from "./useUrlOpener";
function commaBigNumbers(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function sForPlural(n) {
    return n === 1 ? "" : "s";
}
export function EvidenceResults({ argsInfo, results, corpus_filter, evidence, documents, disabled, evidenceSearch, totalEvidenceCount, filteredBySummary, getEvidenceWithPosition, highConfidence, }) {
    const [{ evidenceGroupedByDocument, findings, showStructuredQuery, structuredQuery, autoFoldDocuments, }, sessionUpdater,] = useCurrentSession();
    const supportsHierarchyNavigation = useIsFeatureSupported(Feature.TYPE_HIERARCHY_NAVIGATION);
    const { selection, resetEvidenceSelection, evidenceSelected, selectEvidence } = useEvidenceSelection();
    const { feedback, updateFeedback } = useEvidenceFeedback();
    const annotationProvider = useAnnotationProvider();
    const source = useCurrentSource();
    const { suggestEvidence } = useSuggestedEvidence();
    const currentContextTitle = useCurrentContextTitle();
    const isEmptyQuery = useAtomValue(a_activeConstraintIsEmpty);
    function sendEvidenceFeedback(item, type, context) {
        return __awaiter(this, void 0, void 0, function* () {
            yield sendFeedback(context
                ? FeatureIdentifier.EVIDENCE_QUALIFIER
                : FeatureIdentifier.EVIDENCE_RELEVANCE, "/api/v1/search/evidence", type, item, corpus_filter, context);
            updateFeedback(item.id, type);
            showNotification("Feedback sent");
        });
    }
    const evidenceDescription = React.useMemo(() => {
        const description = [
            `${evidenceGroupedByDocument ? "gathered from" : "showing"} the top ${commaBigNumbers(evidence.length)} out of ${commaBigNumbers(totalEvidenceCount)} total result${sForPlural(totalEvidenceCount)}`,
        ];
        if (evidenceGroupedByDocument) {
            description.unshift(`showing ${commaBigNumbers(documents.length)} document group${sForPlural(documents.length)}`);
        }
        if (filteredBySummary) {
            description.push(`filtered by summary`);
        }
        if (evidence.length > 1) {
            description.push(`sorted by relevance`);
        }
        return description;
    }, [evidence, documents, filteredBySummary]);
    const urlOpenerFn = useUrlOpener({
        suggestEvidence,
        currentContextTitle,
        findings,
        structuredQuery,
        usingHierarchyNav: supportsHierarchyNavigation,
        source,
    });
    //TODO: really shouldn't do this
    const renderEvidenceActions = React.useCallback((evidence, documentId) => {
        return (_jsxs(_Fragment, { children: [_jsx(FindingsDropdown, { evidenceIdList: [evidence.id], disabledDropdown: false, renderDropdownAnchor: (openMenu, hasExistingFinding) => {
                        const FindingIcon = hasExistingFinding ? FindingFilled : Finding;
                        return (_jsx(BorderlessButton, Object.assign({ onClick: openMenu, className: "flex flex-1 justify-center items-center hover:bg-neutral-300 cursor-pointer rounded-full" }, { children: _jsx(FindingIcon, { className: classes("text-base my-auto px-1 py-1") }) })));
                    } }), _jsx(Tooltip, Object.assign({ content: "More actions" }, { children: _jsx(Dropdown, Object.assign({ menu: {
                            items: [
                                {
                                    label: "View in document",
                                    key: "viewInDoc",
                                    onClick: () => {
                                        urlOpenerFn(evidence, evidence.instances.find(i => {
                                            return i.document_id === documentId;
                                        }));
                                    },
                                    disabled: false,
                                },
                                {
                                    label: (_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("span", { children: "Feedback" }), _jsx(BorderlessButton, Object.assign({ className: classes(feedback.get(evidence.id) ===
                                                    FeedbackType.POSITIVE && "text-alert_green-500", "flex items-center hover:text-alert_green-500"), onClick: () => {
                                                    sendEvidenceFeedback(evidence, FeedbackType.POSITIVE);
                                                } }, { children: _jsx(ThumbsUp, { className: "" }) })), _jsx(BorderlessButton, Object.assign({ className: classes(feedback.get(evidence.id) ===
                                                    FeedbackType.NEGATIVE && "text-alert_red-500", "flex items-center  hover:text-alert_red-500"), onClick: () => {
                                                    sendEvidenceFeedback(evidence, FeedbackType.NEGATIVE);
                                                } }, { children: _jsx(ThumbsDown, { className: "" }) }))] }))),
                                    key: "giveFeedback",
                                },
                            ],
                        } }, { children: _jsx(BorderlessButton, Object.assign({ className: "flex flex-1 justify-center items-center hover:bg-neutral-300 cursor-pointer rounded-full" }, { children: _jsx(EllipsisOutlined, { className: "px-1 py-1" }) })) })) }))] }));
    }, [feedback, sendEvidenceFeedback, urlOpenerFn]);
    const someSelected = evidence.map(e => e.id).some(evidenceSelected);
    const allSelected = evidence.map(e => e.id).every(evidenceSelected);
    const disabledSelection = disabled || selection.size === 0;
    const evidenceControl = isEmptyQuery ? null : (_jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(Checkbox, { className: "ml-2 px-2", disabled: disabledSelection || selection.size === 0, checked: allSelected, indeterminate: someSelected && !allSelected, onChange: () => someSelected
                            ? resetEvidenceSelection()
                            : selectEvidence(evidence.map(e => e.id)) }), _jsx(FindingsDropdown, { evidenceIdList: evidence
                            .filter(e => evidenceSelected(e.id))
                            .map(e => e.id), disabledDropdown: disabledSelection, renderDropdownAnchor: (setMenuOpen, hasExistingFinding) => {
                            return (_jsx(BorderlessButton, Object.assign({ disabled: disabledSelection || selection.size === 0, style: { height: "36px" }, className: "rounded-xl font-medium text-tiny", onClick: setMenuOpen }, { children: _jsxs("div", Object.assign({ className: "flex flex-1 justify-center items-center" }, { children: [_jsx(FindingFilled, { className: classes("text-base my-auto mr-1") }), `Edit Findings (${selection.size})`] })) }), disabledSelection ? "disabled" : "enabled"));
                        } })] })), evidenceGroupedByDocument && (_jsx(Tooltip, Object.assign({ content: "Toggle auto-collapse of documents" }, { children: _jsx(BorderlessButton, Object.assign({ style: { height: "36px" }, className: "font-medium text-tiny", active: autoFoldDocuments, onClick: () => {
                        sessionUpdater({
                            autoFoldDocuments: !autoFoldDocuments,
                        });
                    } }, { children: _jsxs("div", Object.assign({ className: "flex flex-1 justify-center items-center" }, { children: [_jsx(Document, { className: classes("text-base my-auto mr-1") }), "Collapse Documents"] })) })) })))] })));
    const content = evidenceGroupedByDocument ? (_jsx(DocumentSearchResults, { className: "ml-1", argsInfo: argsInfo, results: results
            .filter(r => r.type === "document")
            .map(r => r.value), disabled: disabled, evidenceSearch: evidenceSearch, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions, getEvidenceWithPosition: getEvidenceWithPosition })) : (_jsx(EvidenceSearchResults, { className: "ml-1", argsInfo: argsInfo, results: results
            .filter(r => r.type === "evidence")
            .map(r => r.value), disabled: disabled, evidenceSearch: evidenceSearch, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions, getEvidenceWithPosition: getEvidenceWithPosition }));
    return (_jsx("div", Object.assign({ className: "flex flex-1 flex-col" }, { children: _jsx(LabeledSection, Object.assign({ style: { flexGrow: "1", minWidth: "28rem" }, label: evidenceControl }, { children: content })) })));
}
