import { isEmpty } from "lodash";
import { ConceptSource } from "../../../appState/atomic/queryState/types/ConstraintModelState";
export function toNodesAndEdges(modelUtil) {
    const soloNodesAndEdges = toSoloNodeIfPossible(modelUtil);
    if (soloNodesAndEdges) {
        return soloNodesAndEdges;
    }
    const constraintList = Object.values(modelUtil.model.state.constraints);
    const conceptNodeMap = {};
    const relationNodeMap = {};
    const edgeMap = {};
    constraintList.forEach(constraint => {
        const { id, sourceNodeId, targetNodeId, relationNodeId, is_directed } = constraint;
        const sourceFromMap = conceptNodeMap[sourceNodeId];
        const sourceNode = sourceFromMap !== null && sourceFromMap !== void 0 ? sourceFromMap : {
            id: sourceNodeId,
            type: "concept",
            data: {
                sourceConstraintId: id,
            },
            position: { x: 0, y: 0 },
        };
        const sourceExists = Boolean(sourceFromMap);
        if (sourceExists) {
            sourceNode.data.sourceConstraintId = id;
        }
        conceptNodeMap[sourceNodeId] = sourceNode;
        const relationNode = {
            id: relationNodeId,
            type: "relation",
            data: {
                constraintId: id,
            },
            position: { x: 0, y: 0 },
        };
        relationNodeMap[relationNodeId] = relationNode;
        const targetFromMap = conceptNodeMap[targetNodeId];
        const targetNode = targetFromMap !== null && targetFromMap !== void 0 ? targetFromMap : {
            id: targetNodeId,
            type: "concept",
            data: {
                targetConstraintId: id,
            },
            position: { x: 0, y: 0 },
        };
        if (targetFromMap) {
            targetNode.data.targetConstraintId = id;
        }
        conceptNodeMap[targetNodeId] = targetNode;
        createEdge(sourceNodeId, relationNodeId);
        createEdge(relationNodeId, targetNodeId);
        function createEdge(source, target) {
            const edge = {
                id: `${source}-${target}`,
                source,
                target,
                data: {
                    constraintId: id,
                    is_directed,
                },
                type: "cyclic",
            };
            edgeMap[edge.id] = edge;
        }
    });
    const nodes = [
        ...Object.values(conceptNodeMap),
        ...Object.values(relationNodeMap),
    ];
    const edges = Object.values(edgeMap);
    return { nodes, edges };
}
export function toSoloNodeIfPossible(modelUtil) {
    const { constraints } = modelUtil.model.state;
    const soloConstraint = Object.keys(constraints).length === 1;
    if (soloConstraint) {
        const constraint = Object.values(constraints)[0];
        const sourceConcepts = modelUtil.getConceptsForNodeIds(ConceptSource.MODEL, [constraint.sourceNodeId]);
        const hasSourceConcepts = !isEmpty(sourceConcepts);
        const targetConcepts = modelUtil.getConceptsForNodeIds(ConceptSource.MODEL, [constraint.targetNodeId]);
        const hasTargetConcepts = !isEmpty(targetConcepts);
        const relations = modelUtil.getRelationsForNodeIds([
            constraint.relationNodeId,
        ]);
        const hasRelations = !isEmpty(relations);
        if (!hasRelations && (!hasSourceConcepts || !hasTargetConcepts)) {
            return {
                nodes: [
                    {
                        id: hasTargetConcepts
                            ? constraint.targetNodeId
                            : constraint.sourceNodeId,
                        type: "concept",
                        data: {},
                        position: { x: 0, y: 0 },
                    },
                ],
                edges: [],
            };
        }
    }
    return null;
}
