import { arrDelta } from "../../../../utils/generic/collections";
import { createClusterId } from "../../../../utils/identity/createClusterId";
import { ANY_MARKER } from "../consts/ANY";
export function getIdReplacements(params, originalParams, replaceId) {
    const reps = {};
    const org = originalParams.constraints.find(c => c.id === replaceId);
    const c = params.constraints[0];
    if (c) {
        replaceConcepts("sources");
        replaceConcepts("targets");
        replaceConcepts("context");
        replaceRelation();
        replaceQualifiers();
    }
    return reps;
    function replaceRelation() {
        const id = c["relation"];
        const rep = org === null || org === void 0 ? void 0 : org["relation"];
        if (id && rep && rep !== id) {
            reps[id] = rep;
        }
    }
    function replaceConcepts(type) {
        // ignore ids that are on both sides
        const orgContext = (org === null || org === void 0 ? void 0 : org[type]) || [];
        const both = new Set(arrDelta(c[type], orgContext).both);
        const orgIdsByNormalId = Object.fromEntries(orgContext
            .filter(id => !both.has(id))
            .map(id => [createClusterId(originalParams.concepts[id]), id]));
        const unmatched = [];
        const usedOrgIds = new Set();
        c[type]
            .filter(id => !both.has(id))
            .forEach(id => {
            // For ids that are not on both sides, use values to guess replacements
            const orgId = orgIdsByNormalId[createClusterId(params.concepts[id])];
            if (orgId) {
                reps[id] = orgId;
                usedOrgIds.add(orgId);
            }
            else {
                unmatched.push(id);
            }
        });
        const unusedOrgIds = orgContext.filter(id => !usedOrgIds.has(id) && !both.has(id));
        unmatched.forEach((id, idx) => {
            // use positions to guess replacements for unmatched ids
            const orgId = unusedOrgIds[idx];
            if (orgId)
                reps[id] = unusedOrgIds[idx] || id;
        });
    }
    function replaceQualifiers() {
        Object.entries(c.qualifiers).forEach(([argName, id]) => {
            const rep = org === null || org === void 0 ? void 0 : org.qualifiers[argName];
            if (id !== ANY_MARKER && rep && rep !== id && rep !== ANY_MARKER) {
                reps[id] = rep;
            }
        });
    }
}
