import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from "antd";
import { atom, useAtom } from "jotai";
import Lazy from "lazy.js";
import { uniq } from "lodash";
import React from "react";
import { textComparator } from "../../utils/generic/comparators";
import { useFindingState } from "./session/parts/findingsState";
const a_allTags = atom([]);
export function useSynchronizeAllTags() {
    const [{ findings }] = useFindingState();
    const [, setTags] = useAtom(a_allTags);
    React.useEffect(() => {
        const allTags = getAllTags(findings, f => f.payload.tags);
        setTags(allTags);
    }, [findings]);
}
export function useAllTagComponents(allTags) {
    return React.useMemo(() => allTags.sort(textComparator).map(tag => (_jsx(Select.Option, Object.assign({ value: tag }, { children: tag }), tag))), [allTags]);
}
export function useAllTags(extraTags) {
    const allTags = useAtom(a_allTags)[0];
    return useMergeTags(allTags, extraTags);
}
function getAllTags(items, itemTags) {
    return !itemTags ? [] : Lazy(items.map(itemTags)).flatten().uniq().toArray();
}
function useMergeTags(allTags, extraTags) {
    return React.useMemo(() => (extraTags ? uniq([...allTags, ...extraTags]) : allTags), [allTags, extraTags]);
}
