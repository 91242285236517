var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEqual, keyBy, mapValues, omit, range, uniq } from "lodash";
import { MarkerType, Position, getRectOfNodes, getTransformForBounds, internalsSymbol, } from "reactflow";
import { ANY_MARKER } from "../../appState/atomic/queryState/consts/ANY";
import { classes } from "../../appState/context/theme/classes";
import { getAncestor } from "../../utils/dom/utils/dom";
import { focusElementById } from "../../utils/dom/utils/focusElementById";
import { updateItems } from "../../utils/generic/collections";
import { Bounds } from "./Bounds";
import { GraphAnalysis } from "./GraphAnalysis";
import { getBounds } from "./collisions";
import { ORIGIN, calcBounds, dist, distSq } from "./geometry";
import { interpolation } from "./interpolation";
import { liangBarsky, pointLineIntersection } from "./intersection";
import { ElementType, genId, getMarker, isEdge, upsertEdge, upsertNode, } from "./model";
export const NO_ACTIVATE_ON_CLICK = "NO_ACTIVATE_ON_CLICK";
export const DEFAULT_PORT_R = 0;
export const MIN_ZOOM = 0.2;
export const MAX_ZOOM = 4;
export const ZOOM_STEP = 0.2;
export const ACTIVE_BORDER_WIDTH = 0;
export const NORMAL_BORDER_WIDTH = 0;
export function getNodeCenter(node) {
    const { positionAbsolute = { x: 0, y: 0 }, width, height } = node;
    return {
        x: positionAbsolute.x + (width !== null && width !== void 0 ? width : 0) / 2,
        y: positionAbsolute.y + (height !== null && height !== void 0 ? height : 0) / 2,
    };
}
export function getBestHandlePositionForA(a, b) {
    const centerA = getNodeCenter(a);
    const centerB = getNodeCenter(b);
    const horizontalDiff = Math.abs(centerA.x - centerB.x);
    const verticalDiff = Math.abs(centerA.y - centerB.y);
    return horizontalDiff > verticalDiff
        ? centerA.x > centerB.x
            ? Position.Left
            : Position.Right
        : centerA.y > centerB.y
            ? Position.Top
            : Position.Bottom;
}
export function getHandleCoordsByPosition(node, position) {
    var _a, _b, _c;
    const { positionAbsolute = { x: 0, y: 0 }, width = 0, height = 0 } = node;
    const handle = (_c = (_b = (_a = node[internalsSymbol]) === null || _a === void 0 ? void 0 : _a.handleBounds) === null || _b === void 0 ? void 0 : _b.source) === null || _c === void 0 ? void 0 : _c.find(h => h.position === position);
    const { x, y } = handle || { x: 0, y: 0, width: 0, height: 0 };
    let offsetX = width / 2;
    let offsetY = height / 2;
    switch (position) {
        case Position.Left:
            offsetX = 0;
            break;
        case Position.Right:
            offsetX = width;
            break;
        case Position.Top:
            offsetY = 0;
            break;
        case Position.Bottom:
            offsetY = height;
            break;
    }
    return [positionAbsolute.x + offsetX, positionAbsolute.y + offsetY];
}
export function getClosestEdgeParams(source, target) {
    const sourcePosition = getBestHandlePositionForA(source, target);
    const targetPosition = getBestHandlePositionForA(target, source);
    const [sourceX, sourceY] = getHandleCoordsByPosition(source, sourcePosition);
    const [targetX, targetY] = getHandleCoordsByPosition(target, targetPosition);
    return {
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
    };
}
export function getLinePath(x1, y1, x2, y2) {
    return `M ${x1} ${y1} L ${x2} ${y2}`;
}
function getClosestEdgePoint(rect, { x, y }) {
    const [x1, y1, x2, y2] = Bounds.toDiagonal(rect);
    return [
        calc(x1, y1, x2, y1),
        calc(x2, y1, x2, y2),
        calc(x1, y2, x2, y2),
        calc(x1, y1, x1, y2),
    ].sort((a, b) => a.d - b.d)[0].p;
    function calc(x1, y1, x2, y2) {
        const p = pointLineIntersection(x, y, x1, y1, x2, y2, true);
        return { d: dist(x, y, p.x, p.y), p };
    }
}
export function getSourceIntersection(source, target) {
    const cS = getNodeCenter(source);
    const cT = getNodeCenter(target);
    const bbox = getBounds(source);
    const [p1, p2] = liangBarsky(cS.x, cS.y, cT.x, cT.y, bbox);
    const p = !p1 || !p2
        ? undefined
        : distSq(cT.x, cT.y, p1.x, p1.y) < distSq(cT.x, cT.y, p2.x, p2.y)
            ? p1
            : p2;
    return !p ||
        (p.x > Bounds.x(bbox) &&
            p.x < Bounds.xMax(bbox) &&
            p.y > Bounds.y(bbox) &&
            p.y < Bounds.yMax(bbox))
        ? getClosestEdgePoint(bbox, cT)
        : p;
}
export function getDirectPath(source, target, gap) {
    const s = getSourceIntersection(source, target);
    const t = getSourceIntersection(target, source);
    const d = dist(s.x, s.y, t.x, t.y);
    const interX = interpolation(0, s.x, d, t.x);
    const interY = interpolation(0, s.y, d, t.y);
    const x1 = interX(gap);
    const y1 = interY(gap);
    const x2 = interX(d - gap);
    const y2 = interY(d - gap);
    const path = getLinePath(x1, y1, x2, y2);
    const labelX = interX(d / 2);
    const labelY = interY(d / 2);
    return { path, labelX, labelY, source: s, target: t };
}
export function getNodeIntersection(intersectionNode, targetNode) {
    const { width, height, positionAbsolute } = intersectionNode;
    const targetPosition = targetNode.positionAbsolute;
    const w = width;
    const h = height;
    const w2 = w / 2;
    const h2 = h / 2;
    const x2 = positionAbsolute.x + w2;
    const y2 = positionAbsolute.y + h2;
    const x1 = targetPosition.x + w2;
    const y1 = targetPosition.y + h2;
    const xx1 = (x1 - x2) / w - (y1 - y2) / h;
    const yy1 = (x1 - x2) / w + (y1 - y2) / h;
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w2 * (xx3 + yy3) + x2;
    const y = h2 * (-xx3 + yy3) + y2;
    return { x, y };
}
// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
    const n = Object.assign(Object.assign({}, node.positionAbsolute), node);
    const nx = Math.round(n.x);
    const ny = Math.round(n.y);
    const px = Math.round(intersectionPoint.x);
    const py = Math.round(intersectionPoint.y);
    if (px <= nx + 1) {
        return Position.Left;
    }
    if (px >= nx + n.width - 1) {
        return Position.Right;
    }
    if (py <= ny + 1) {
        return Position.Top;
    }
    if (py >= n.y + n.height - 1) {
        return Position.Bottom;
    }
    return Position.Top;
}
export function getFloatingEdgeParams(source, target) {
    const sourceIntersectionPoint = getNodeIntersection(source, target);
    const targetIntersectionPoint = getNodeIntersection(target, source);
    const sourcePosition = getEdgePosition(source, sourceIntersectionPoint);
    const targetPosition = getEdgePosition(target, targetIntersectionPoint);
    return {
        sourceX: sourceIntersectionPoint.x,
        sourceY: sourceIntersectionPoint.y,
        targetX: targetIntersectionPoint.x,
        targetY: targetIntersectionPoint.y,
        sourcePosition,
        targetPosition,
    };
}
export function createNodesAndEdges() {
    const nodes = [];
    const edges = [];
    const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    nodes.push({ id: "target", data: { label: "Target" }, position: center });
    for (let i = 0; i < 8; i++) {
        const degrees = i * (360 / 8);
        const radians = degrees * (Math.PI / 180);
        const x = 250 * Math.cos(radians) + center.x;
        const y = 250 * Math.sin(radians) + center.y;
        nodes.push({ id: `${i}`, data: { label: "Source" }, position: { x, y } });
        edges.push({
            id: `edge-${i}`,
            target: "target",
            source: `${i}`,
            type: "floating",
            markerEnd: {
                type: MarkerType.Arrow,
            },
        });
    }
    return { nodes, edges };
}
export function getSmoothPath(fromX, fromY, toX, toY) {
    const [cx1, cy1, cx2, cy2] = Math.abs(fromX - toX) > Math.abs(fromY - toY)
        ? [(fromX + toX) / 2, fromY, (fromX + toX) / 2, toY]
        : [fromX, (fromY + toY) / 2, toX, (fromY + toY) / 2];
    return `M${fromX} ${fromY} C ${cx1} ${cy1} ${cx2} ${cy2} ${toX} ${toY}`;
}
export function createRelation(source, target) {
    const sC = getNodeCenter(source);
    const node = emptyNode(ElementType.RELATION, { directed: true }, { x: (sC.x + target.position.x) / 2, y: (sC.y + target.position.y) / 2 });
    return {
        node,
        edges: [
            upsertEdge({
                source: source.id,
                target: node.id,
                type: ElementType.RELATION,
            }),
            upsertEdge({
                source: node.id,
                target: target.id,
                type: ElementType.RELATION,
                directed: true,
            }),
        ],
    };
}
export function emptyNode(type, params = {}, position = { x: 0, y: 0 }) {
    return upsertNode(Object.assign({ type, position, positionAbsolute: position }, params));
}
export function stageNewNode(stage) {
    stage({
        nodes: [emptyNode(ElementType.CONCEPT)],
    });
}
export function typesAre(a, b, type1, type2) {
    return (a === type1 && b === type2) || (b === type1 && a === type2);
}
export function replaceIds({ nodes, edges }) {
    const newIds = mapValues(keyBy(nodes, "id"), () => genId());
    return {
        nodes: nodes.map(n => (Object.assign(Object.assign({}, n), { id: newIds[n.id] }))),
        edges: edges.map(e => (Object.assign(Object.assign({}, e), { id: genId(), source: newIds[e.source] || e.source, target: newIds[e.target] || e.target }))),
    };
}
export function isExpandable(node) {
    return node.type !== ElementType.RELATION;
}
export function setNodeExpansion(nodes, expanded) {
    return nodes.map(n => !isExpandable(n)
        ? n
        : Object.assign(Object.assign({}, n), { data: Object.assign(Object.assign({}, n.data), { expanded }) }));
}
export function cleanupNodes(nodes) {
    return nodes.map(n => {
        const _a = Object.assign(Object.assign({}, setFlag(n, "active", false)), { selected: false }), { data } = _a, rest = __rest(_a, ["data"]);
        return Object.assign(Object.assign({}, rest), { data: omit(data, "order") });
    });
}
export function getNodesBounds(nodes) {
    return calcBounds(nodes, n => getBounds(n));
}
export function getViewportBounds({ width, height, transform: [tx, ty, zoom], }) {
    const mul = 1 / zoom;
    return Bounds(-tx * mul, -ty * mul, width * mul, height * mul);
}
export function getViewportForNodes(nodes, viewportWidth, viewportHeight) {
    const [x, y, zoom] = getTransformForBounds(getRectOfNodes(nodes), viewportWidth, viewportHeight, MIN_ZOOM, 1);
    return { x, y, zoom };
}
export function offsetToOrigin(nodes, offset = ORIGIN) {
    const [minX, minY] = getNodesBounds(nodes);
    return updateItems(nodes, (_a) => {
        var { positionAbsolute, position } = _a, n = __rest(_a, ["positionAbsolute", "position"]);
        const p = positionAbsolute || position;
        const xy = { x: p.x + offset.x - minX, y: p.y + offset.y - minY };
        return Object.assign(Object.assign({}, n), { position: xy, positionAbsolute: xy });
    });
}
export function targetIsPane(event) {
    var _a;
    return ((_a = event.target.classList) === null || _a === void 0 ? void 0 : _a.contains("react-flow__pane")) || false;
}
export function getNodeIdAt(event) {
    const nodeElement = getAncestor(event.target, "react-flow__node");
    return (nodeElement === null || nodeElement === void 0 ? void 0 : nodeElement.getAttribute("data-id")) || null;
}
export function getNodeAt(flow, e) {
    const id = getNodeIdAt(e);
    return id ? flow.getNode(id) : null;
}
export function setNodeValue(node, key, value) {
    return Object.assign(Object.assign({}, node), { data: Object.assign(Object.assign({}, node.data), { [key]: value }) });
}
export function setFlag(item, flag, value) {
    return Object.assign(Object.assign({}, item), { data: Object.assign(Object.assign({}, item.data), { [flag]: !!value }), className: classes.set(item.className, !!value, flag) });
}
export function isDirected(item) {
    return item.data.directed;
}
export function setActive(item, value) {
    item = setFlag(item, "active", value);
    if (isEdge(item)) {
        item = Object.assign(Object.assign({}, item), getMarker(isDirected(item), !!value));
    }
    return item;
}
export function setDirection(item, value) {
    item = setFlag(item, "directed", value);
    if (isEdge(item)) {
        item = Object.assign(Object.assign({}, item), getMarker(isDirected(item), isActive(item)));
    }
    return item;
}
export function isExpanded(item) {
    return item.data.expanded;
}
export function setExpanded(item, expanded = false) {
    return Object.assign(Object.assign({}, item), { data: Object.assign(Object.assign({}, item.data), { expanded }) });
}
export function isActive(item) {
    return item.data.active;
}
export function isSelected(item) {
    return item.selected;
}
export function setSelected(item, value) {
    return Object.assign(Object.assign({}, item), { selected: value || false });
}
export function canMerge(a, b) {
    return (a.id !== b.id &&
        a.type === b.type &&
        (a.data.label === b.data.label ||
            a.data.label === ANY_MARKER ||
            b.data.label === ANY_MARKER));
}
export function toDiagram({ x, y }, { x: oX, y: oY, zoom }) {
    return {
        x: (x - oX) / zoom,
        y: (y - oY) / zoom,
    };
}
export function toPixel({ x, y }, { x: oX, y: oY, zoom }) {
    return {
        x: x * zoom + oX,
        y: y * zoom + oY,
    };
}
export function getSelectedNodeIds(nodes) {
    return getSelectedIds(nodes);
}
export function getSelectedIds(nodes, edges = []) {
    return new Set([...nodes, ...edges].filter(isSelected).map(n => n.id));
}
export function otherId(e, id) {
    return id === e.source ? e.target : e.source;
}
export function isRelation({ type }) {
    return type === ElementType.RELATION;
}
export function getCRCsAt(node, ga) {
    return getTargets().map(ga.nodeAndNeighborIds);
    function getTargets() {
        if (isRelation(node))
            return [node.id];
        const neighbors = ga
            .neighbors(node.id)
            .filter(isRelation)
            .map(n => n.id);
        return neighbors.length ? neighbors : [node.id];
    }
}
export function getActiveRelationOrConcept(nodes) {
    const activeNodes = nodes.filter(isActive);
    return (activeNodes.find(isRelation) || activeNodes[0]);
}
function updateNodesFlag(ids, { nodes, edges }, setter) {
    const set = new Set(ids);
    return {
        nodes: nodes.map(n => setter(n, set.has(n.id))),
        edges: edges.map(e => setter(e, set.has(e.source) && set.has(e.target))),
    };
}
export function getActiveNodes(ga, toActivate) {
    const [principalIds, otherIds] = getActivateComponents();
    const crcs = getCrcs(principalIds);
    return [crcs, otherIds];
    // returns all graph components containing active nodes
    // the first index is the principal component, the others have only one node
    function getActivateComponents() {
        // index of components containing nodes to activate or all components
        // if no toActivate
        const comps = !toActivate
            ? range(ga.components().length)
            : uniq(toActivate.map(n => ga.componentIdx(n.id)));
        // index of component with more than one node
        // if more than one component has more than one node, return negative
        const principalIdx = comps.reduce((acc, idx) => {
            if (acc === -1)
                return -1;
            if (acc === -2)
                return idx;
            if (ga.components()[idx].length > 1) {
                return ga.components()[acc].length > 1 ? -1 : idx;
            }
            return acc;
        }, -2);
        return principalIdx < 0
            ? [[], []]
            : [
                ga.components()[principalIdx],
                comps
                    .filter(idx => idx !== principalIdx)
                    .map(idx => ga.components()[idx])
                    .flat(),
            ];
    }
    function getActiveInPrincipal() {
        const set = new Set(principalIds);
        return toActivate.filter(n => set.has(n.id));
    }
    function getCrcs(comp) {
        if (!comp.length)
            return [];
        const gaComp = GraphAnalysis(comp.map(ga.node), ga.edges);
        const nodes = toActivate ? getActiveInPrincipal() : gaComp.nodes;
        let crcs = nodes.length ? getCRCsAt(nodes[0], gaComp) : [];
        const first = crcs;
        for (let idx = 1; idx < nodes.length && crcs.length; idx++) {
            crcs = commonAB(crcs, getCRCsAt(nodes[idx], gaComp));
        }
        return crcs.length ? crcs : toActivate ? [] : first;
        function commonAB(a, b) {
            const [s, l] = a.length > b.length ? [b, a] : [a, b];
            return s.filter(ia => l.find(ib => isEqual(ia, ib)));
        }
    }
}
export function activate(ga, nodes = []) {
    const [crcs, otherIds] = getActiveNodes(ga, nodes);
    const active = crcs.length ? [...crcs[0], ...otherIds] : [];
    return updateNodesFlag(active, ga, setActive);
}
export function validateActive({ nodes, edges }) {
    const active = nodes.filter(isActive);
    return activate(GraphAnalysis(nodes, edges), active.length ? active : nodes.length ? [nodes[0]] : []);
}
export function isEmptyNode(node) {
    return !getNodeValues(node, false).length;
}
export function getNodeValues(node, overrides) {
    return !node
        ? []
        : overrides
            ? Object.values(node.data.selection).flat()
            : node.data.content;
}
export function getNodeValueOrOverrides(node) {
    const overrides = getNodeValues(node, true);
    return overrides.length ? overrides : getNodeValues(node, false);
}
export const ModelBuilderId = "ModelBuilder";
export function focusModelBuilder() {
    focusElementById(ModelBuilderId);
}
export function validateNodeOrder(nodes) {
    const withOrder = nodes.filter(n => n.data.order !== undefined);
    if (withOrder.length === nodes.length)
        return nodes;
    let max = !withOrder.length
        ? 0
        : Math.max(...withOrder.map(n => n.data.order));
    return nodes.map(n => n.data.order === undefined ? Object.assign(Object.assign({}, n), { data: Object.assign(Object.assign({}, n.data), { order: ++max }) }) : n);
}
export function classifyNodes(nodes) {
    return nodes.reduce((acc, n) => {
        acc[n.type].push(n);
        return acc;
    }, {
        [ElementType.CONCEPT]: [],
        [ElementType.RELATION]: [],
        [ElementType.QUALIFIER]: [],
    });
}
export function noSize(n) {
    return isNaN(n.width) || isNaN(n.height);
}
export function clearItemsClass(items, className) {
    return items.map(n => (Object.assign(Object.assign({}, n), { className: classes.remove(n.className, className) })));
}
