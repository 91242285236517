import { isEqual } from "lodash";
import { getURLParams } from "./getURLParams";
export function setURLParams(params) {
    const current = getURLParams();
    if (isEqual(current, params))
        return;
    const url = new URL(window.location.href);
    url.searchParams.forEach((v, k) => url.searchParams.delete(k));
    Object.entries(params).forEach(([k, v]) => url.searchParams.set(k, v));
    history.pushState(null, "", url.href);
}
