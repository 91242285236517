import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { ShortcutForTooltip } from "../../actions/utils/ShortcutForTooltip";
import { Fit } from "../../common/icons/Icons";
import { Mode } from "../DiagramMode";
import { MAX_ZOOM, MIN_ZOOM } from "../diagramUtils";
export function useRootActions({ store, mode, flow, dUpdater, }) {
    return React.useCallback(() => {
        const zoom = flow.getZoom();
        return {
            globalRoot: [
                {
                    icon: _jsx(PlusOutlined, {}),
                    disabled: zoom >= MAX_ZOOM,
                    desc: "Zoom in",
                    action() {
                        flow.zoomIn();
                        dUpdater.commit();
                    },
                },
                {
                    icon: _jsx(MinusOutlined, {}),
                    disabled: zoom <= MIN_ZOOM,
                    desc: "Zoom out",
                    action() {
                        flow.zoomOut();
                        dUpdater.commit();
                    },
                },
                {
                    icon: _jsx(Fit, {}),
                    action() {
                        flow.fitView();
                        dUpdater.commit();
                    },
                },
                {
                    caption: "Select",
                    selectionGroup: "Mode",
                    selected: mode.is(Mode.normal, Mode.selecting),
                    shortcut: "s",
                    action() {
                        mode(Mode.normal);
                    },
                },
                {
                    caption: "Pan",
                    selectionGroup: "Mode",
                    selected: mode.is(Mode.panning),
                    shortcut: "p",
                    desc: (_jsxs("span", { children: ["Hold down ", _jsx(ShortcutForTooltip, { shortcut: "space" }), " to pan the diagram"] })),
                    action() {
                        mode(Mode.panning);
                    },
                },
            ],
        };
    }, [mode]);
}
