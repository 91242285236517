import { mergeNamedMembers } from "../../appState/atomic/queryState/semanticSearch/mergeNamedMembers";
import { getConceptIds } from "./getConceptIds";
import { uuidv5 } from "./uuidv5";
export function createClusterId(cluster) {
    if (Array.isArray(cluster)) {
        if (cluster.length === 1)
            cluster = cluster[0];
        else {
            cluster = mergeNamedMembers(cluster);
        }
    }
    const members = cluster.members || [];
    if (!members.length)
        console.error("Empty clusters may lead to id conflicts.");
    const idBasis = members.length ? getConceptIds(cluster).join() : cluster.name;
    return uuidv5(idBasis);
}
