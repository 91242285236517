// first constraint is the constraint with a source where no other constraint has the same target
export function findFirstConstraint(constraintList) {
    const startIds = getStartConstraintIds(constraintList);
    const startConstraints = constraintList.filter(c => startIds.includes(c.id));
    return startConstraints[0] || constraintList[0] || null;
}
// find the earliest constraint in the list
export function getStartConstraintIds(constraintList) {
    const idsSourcesTargets = constraintList.map(constraint => [
        constraint.id,
        constraint.sourceNodeId,
        constraint.targetNodeId,
    ]);
    const targets = idsSourcesTargets.map(([_, __, target]) => target);
    const untargettedConstraints = idsSourcesTargets.filter(([id, source, _]) => {
        return !targets.includes(source);
    });
    return untargettedConstraints.map(([id, _, __]) => id);
}
