import { useSelectionTracker } from "./useSelectionTracker";
function cleanSelection(selection) {
    const range = selection.getRangeAt(0);
    const fragment = range.cloneContents();
    const tempDiv = document.createElement("div");
    tempDiv.appendChild(fragment);
    tempDiv.querySelectorAll(".clearselect").forEach(sup => {
        sup.remove();
    });
    return tempDiv.textContent ? tempDiv.textContent.trim() : undefined;
}
export function useSelectionChange(disabled, containerId, contextText, onSelectionChange, setHighlights) {
    useSelectionTracker(!onSelectionChange || disabled, selection => {
        const range = selection.getRangeAt(0);
        const r = range.getBoundingClientRect();
        const container = document.getElementById(containerId);
        const rect = r &&
            (container === null || container === void 0 ? void 0 : container.contains(selection.anchorNode)) &&
            (container === null || container === void 0 ? void 0 : container.contains(selection.focusNode))
            ? {
                top: r.y,
                left: r.x,
                width: r.width,
                height: r.height,
            }
            : undefined;
        const text = cleanSelection(selection);
        if (rect && text) {
            onSelectionChange === null || onSelectionChange === void 0 ? void 0 : onSelectionChange({
                rect,
                text,
                docText: contextText,
                setHighlights,
            });
        }
    });
}
