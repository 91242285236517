import { jsx as _jsx } from "react/jsx-runtime";
import { ModalType, closeModal, openModal, } from "../../../appState/beacon/useModalState";
import { Fill } from "../../common/layout/Fill";
import { pushModal } from "../../common/modals/Modal";
import { History } from "./History";
import "./combinedHistory.less";
function CombinedHistory() {
    return (_jsx(Fill, Object.assign({ flexCol: true }, { children: _jsx(History, {}) })));
}
export function showCombinedHistory() {
    openModal(ModalType.HISTORY);
    pushModal({
        title: "History",
        titleColorClass: "bg-gray-700",
        content: _jsx(CombinedHistory, {}),
        easyClose: () => false,
        onClose: () => closeModal(ModalType.HISTORY),
        className: "min-w-[90%] max-w-[90%]",
    });
}
