var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox } from "antd";
import { uniq } from "lodash";
import React from "react";
import { useEvidenceHighlights } from "../../../appState/atomic/evidence/useEvidenceHighlights";
import { useEvidenceSelection } from "../../../appState/atomic/evidence/useEvidenceSelection";
import { useSummariesDisplayState } from "../../../appState/atomic/summaries/useSummariesDisplayState";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { classes } from "../../../appState/context/theme/classes";
import { useSelectionChange } from "../../../utils/dom/useSelectionChange";
import { useUniqId } from "../../../utils/identity/useUniqId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { copyToClipboard } from "../../../utils/interactions/utils/clipboard";
import { BorderlessButton } from "../../common/buttons/BorderlessButton";
import { Copy, FindingFilled } from "../../common/icons/Icons";
import { Tooltip } from "../../common/popups/Tooltip";
import { ReferenceQualifier } from "../evidenceControls/ReferenceQualifier";
import { ActionableBlock } from "../evidenceDisplay/ActionableBlock";
import { CollapsibleArea } from "../evidenceDisplay/CollapsibleArea";
import { EvidenceList } from "../evidenceDisplay/EvidenceList";
import { TextWithEvidenceReferences } from "../evidenceDisplay/TextWithEvidenceReferences";
import { FindingsDropdown } from "../findingsControls/FindingsDropdown";
export function SummaryCard(_a) {
    var { summary, summaryIndex, summaryCount, onSelect, className, selected, otherSelected, disabled, hidden, 
    //urlOpenerFn,
    filterText, actions, extraItemActions, 
    //summaryArgColorGetter,
    renderAsHtml, onRemove, showSurrounding, 
    //onMetadataClick,
    onSummaryTextSelected, actionsTooltip, footer, addFilter, clearFilters } = _a, props = __rest(_a, ["summary", "summaryIndex", "summaryCount", "onSelect", "className", "selected", "otherSelected", "disabled", "hidden", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onSummaryTextSelected", "actionsTooltip", "footer", "addFilter", "clearFilters"]);
    const { evidenceSelected, resetEvidenceSelection, selectEvidence, deselectEvidence, } = useEvidenceSelection();
    const { resetEvidenceHighlight, highlightEvidence } = useEvidenceHighlights();
    const { getEvidenceList } = useEvidenceSearchUtils();
    const { summaryRefIsFolded, setFoldSummaryRef } = useSummariesDisplayState();
    const containerId = useUniqId(uuidv4);
    useSelectionChange(!summary, containerId, summary.summary, onSummaryTextSelected &&
        (({ text, rect }) => onSummaryTextSelected({ summary, rect, text })));
    const select = () => {
        clearFilters && clearFilters();
        onSelect && !selected && onSelect(summary, true);
        resetEvidenceHighlight();
    };
    const clear = () => {
        clearFilters && clearFilters();
        onSelect && onSelect(summary, false);
        resetEvidenceHighlight();
    };
    const uniqueReferenceIds = uniq(summary.references.map(r => r.evidence_id));
    const unreferenced = summary.evidence_ids.filter(e => !uniqueReferenceIds.includes(e));
    const summaryEvidence = getEvidenceList(uniqueReferenceIds);
    const unreferencedEvidence = getEvidenceList(unreferenced);
    const summaryEvidenceIdList = [...uniqueReferenceIds, ...unreferenced];
    const someSelected = summaryEvidenceIdList.some(evidenceSelected);
    const allSelected = summaryEvidenceIdList.every(evidenceSelected);
    const selectedChildren = summaryEvidenceIdList.filter(evidenceSelected);
    const uniqueReferenceCount = uniq(summary.references.map(r => r.evidence_id)).length;
    if (hidden)
        return null;
    const topFindingsControl = (_jsx(FindingsDropdown, { autoFindingName: summary.cluster_name, findingText: summary.summary, evidenceIdList: summaryEvidenceIdList, disabledDropdown: false, renderDropdownAnchor: (openMenu, hasExistingFinding) => {
            return (_jsx(BorderlessButton, Object.assign({ onClick: openMenu, className: "flex flex-1 justify-center items-center hover:bg-gray-300 cursor-pointer rounded-full" }, { children: _jsx(FindingFilled, { className: classes("text-base my-auto px-1 py-1") }) })));
        } }, "summaryFindings"));
    const selectionFindingsControl = (_jsx(FindingsDropdown, { evidenceIdList: selectedChildren, disabledDropdown: !someSelected, renderDropdownAnchor: (setMenuOpen, hasExistingFinding) => {
            return (_jsx(BorderlessButton, Object.assign({ style: { height: "36px" }, className: "ml-2 rounded-xl font-medium text-tiny mr-2", onClick: setMenuOpen, disabled: !someSelected }, { children: _jsx("div", Object.assign({ className: "flex flex-1 justify-center items-center" }, { children: _jsx(FindingFilled, { className: classes("text-base my-auto mr-1") }) })) }), !someSelected ? "disabled" : "enabled"));
        } }));
    const copyButton = (_jsx(Tooltip, Object.assign({ trigger: "click", content: "Copied to clipboard", delay: 150 }, { children: _jsx(BorderlessButton, Object.assign({ className: "flex flex-1 justify-center items-center hover:bg-gray-300 cursor-pointer rounded-full", onClick: () => copyToClipboard(summary.summary) }, { children: _jsx(Copy, { className: classes("text-base my-auto px-1 py-1") }) })) }), "summaryCopy"));
    return (_jsxs("div", Object.assign({ className: classes("flex flex-col items-stretch text-base font-sans mb-4 pb-2 border-b border-b-gray-400", disabled ? "opacity-50" : "", className), style: {
            position: selected ? "sticky" : "relative",
            top: 0,
            bottom: 0,
            zIndex: selected ? 1 : undefined,
        } }, props, { children: [_jsxs(ActionableBlock, Object.assign({ actions: [topFindingsControl, copyButton] }, { children: [_jsx("div", Object.assign({ className: "flex w-full justify-between" }, { children: _jsx("div", Object.assign({ className: classes("font-bold grow", onSelect && "cursor-pointer"), onClick: select }, { children: _jsx("div", { children: summary.cluster_name }) })) })), _jsx(ReferenceQualifier, { disableHighlights: false, disableFiltering: false, disableGroupSelect: otherSelected, referenceCount: uniqueReferenceCount, highlightReferences: () => highlightEvidence(uniqueReferenceIds), selectReferences: () => {
                            resetEvidenceSelection();
                            selectEvidence(uniqueReferenceIds);
                        }, filterByReferences: () => {
                            select();
                            addFilter &&
                                addFilter((e) => uniqueReferenceIds.includes(e.id));
                        }, considerationCount: summary.evidence_ids.length, highlightConsiderations: () => highlightEvidence(summary.evidence_ids), selectConsiderations: () => {
                            resetEvidenceSelection();
                            selectEvidence(summary.evidence_ids);
                        }, filterByConsiderations: select, clearHighlights: resetEvidenceHighlight }), _jsx("div", Object.assign({ className: classes("flex flex-col items-stretch", "mt-2", "pb-1") }, { children: _jsx("div", Object.assign({ id: containerId }, { children: renderAsHtml ? (_jsx("span", { dangerouslySetInnerHTML: renderAsHtml ? { __html: summary.summary } : undefined })) : (_jsx(TextWithEvidenceReferences, { text: summary.summary, references: summary.references, showReferences: true, showHiddenReferences: true })) })) }))] })), _jsxs(CollapsibleArea, Object.assign({ className: "w-full", collapsed: summaryRefIsFolded(summary.id), setCollapsed: (collapsed) => setFoldSummaryRef(summary.id, collapsed), label: _jsx("span", Object.assign({ className: "ml-2 font-semibold text-gray-600" }, { children: "View Evidence" })), prefix: _jsx(Checkbox, { className: "ml-2", checked: allSelected, indeterminate: someSelected && !allSelected, onChange: e => {
                        e.target.checked
                            ? selectEvidence(summaryEvidenceIdList)
                            : deselectEvidence(summaryEvidenceIdList);
                    } }), suffix: selectionFindingsControl }, { children: [_jsx("div", Object.assign({ className: "pl-[24px]" }, { children: _jsx(EvidenceList, { evidence: summaryEvidence }) })), unreferencedEvidence.length > 0 && (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "ml-2 font-semibold text-gray-600 py-4" }, { children: "Additional Considered Evidence" })), _jsx("div", Object.assign({ className: "pl-[24px]" }, { children: _jsx(EvidenceList, { evidence: unreferencedEvidence }) }))] }))] }))] })));
}
