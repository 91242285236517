var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { loadable } from "jotai/utils";
import { isEqual, pick } from "lodash";
import React from "react";
import { EMPTY_QUERY_STATE } from "../../verions/V3_QueryState";
import { ConstraintModelUtil, a_constraintModelUtil, } from "../queryState/constraintModel/ConstraintModelUtil";
import { METADATA_SEARCH_PARAMS_KEYS } from "../queryState/queryStateAtoms";
import { a_queryState } from "../queryState/useQueryState";
import { a_queryHistoryState } from "../session/parts/queryHistoryState";
import { a_usageState } from "../session/parts/usageState";
import { getQueryStateHistoryEntry } from "../session/utils/history";
import atomWithDebounce from "../utils/atomWithDebounce";
const MAX_HISTORY_COUNT = 100;
const HISTORY_DEBOUNCE_TIME = 3000;
//TODO: this approach causes a drastic performance drop
function getComparablePart(entry) {
    const keys = [
        ...METADATA_SEARCH_PARAMS_KEYS,
        "corpus_ids",
        "queryParts",
        "queryId",
    ];
    return pick(entry || {}, keys);
}
const a_historyUpdateWriter = atom(null, (get, set, entry) => {
    const queryState = get(a_queryState);
    const history = get(a_queryHistoryState);
    const usage = get(a_usageState);
    const modelUtil = get(a_constraintModelUtil);
    const corpusIds = entry.corpus_ids;
    const queryId = entry.queryId;
    const lastQueryIdByCorpusId = Object.assign(Object.assign({}, usage.lastQueryIdByCorpusId), { [corpusIds[0]]: queryId });
    const queryHistory = modelUtil.isModelEmpty()
        ? history.queryHistory
        : history.queryHistory
            .sort((a, b) => b.time - a.time)
            .take(MAX_HISTORY_COUNT)
            .push(entry);
    if (!isEqual(history.queryHistory.toArray(), queryHistory.toArray()))
        set(a_queryHistoryState, {
            queryHistory,
        });
    if (!isEqual(usage.lastQueryIdByCorpusId, lastQueryIdByCorpusId))
        set(a_usageState, {
            lastQueryIdByCorpusId,
        });
});
export const { debouncedValueAtom: a_debouncedQueryState } = atomWithDebounce(EMPTY_QUERY_STATE, HISTORY_DEBOUNCE_TIME);
const a_updateDebouncedQueryState = atomEffect((get, set) => {
    const queryState = get(a_queryState);
    set(a_debouncedQueryState, queryState);
});
const a_historyUpdateGenerator = atom((get) => __awaiter(void 0, void 0, void 0, function* () {
    const queryState = get(a_debouncedQueryState);
    const modelUtil = new ConstraintModelUtil(queryState.constraintModel);
    if (modelUtil.isModelEmpty())
        return;
    const entry = yield getQueryStateHistoryEntry(queryState);
    const history = get(a_queryHistoryState);
    const comp = getComparablePart(entry);
    const last = getComparablePart(history.queryHistory.last());
    const corpusIds = entry.corpus_ids;
    if (isEqual(comp, last) || !corpusIds.length)
        return;
    return entry;
}));
const a_loadableHistory = loadable(a_historyUpdateGenerator);
// update query history every time there is a new QueryState
export function useHistoryUpdater() {
    useAtom(a_updateDebouncedQueryState);
    const historyEntry = useAtomValue(a_loadableHistory);
    const writeHistory = useSetAtom(a_historyUpdateWriter);
    React.useEffect(() => {
        if (historyEntry.state === "hasData" && historyEntry.data)
            writeHistory(historyEntry.data);
    }, [historyEntry, writeHistory]);
}
