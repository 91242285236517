import { jsx as _jsx } from "react/jsx-runtime";
import { findIndex, isEqual } from "lodash";
import React from "react";
export function findTextSpans(text, search, ignoreCase = true) {
    if (ignoreCase) {
        text = text.toLowerCase();
        search = search.toLowerCase();
    }
    const spans = [];
    let index = text.indexOf(search);
    while (index !== -1) {
        spans.push({ start: index, end: index + search.length });
        index = text.indexOf(search, index + 1);
    }
    return spans;
}
export function AnnotatedString({ text, annotations, renderAnnotation, }) {
    const getSegments = () => {
        const positions = [];
        annotations
            .filter(a => a.span.end > a.span.start)
            .forEach(a => {
            positions.push({
                index: a.span.start,
                type: "start",
                annotation: a.annotation,
            });
            positions.push({
                index: a.span.end,
                type: "end",
                annotation: a.annotation,
            });
        });
        // Sort positions by their index, and if they're the same, prioritize end over start.
        positions.sort((a, b) => {
            if (a.index === b.index) {
                return a.type === "end" ? -1 : 1;
            }
            return a.index - b.index;
        });
        const currentAnnotations = [];
        let lastIndex = 0;
        const segments = [];
        positions.forEach(position => {
            const segmentText = text.substring(lastIndex, position.index);
            if (segmentText.length) {
                segments.push({ text: segmentText, annotations: [...currentAnnotations] });
            }
            if (position.type === "start") {
                currentAnnotations.push(position.annotation);
            }
            else {
                const styleIdx = findIndex(currentAnnotations, a => isEqual(a, position.annotation));
                if (styleIdx !== -1) {
                    currentAnnotations.splice(styleIdx, 1);
                }
            }
            lastIndex = position.index;
        });
        // Handle any remaining text after the last position.
        const remainingText = text.substring(lastIndex);
        if (remainingText.length) {
            segments.push({ text: remainingText, annotations: currentAnnotations });
        }
        return segments;
    };
    const segments = getSegments();
    const getLastSegment = (idx) => {
        const last = segments[idx - 1];
        return last;
    };
    const annotationIndexTable = segments.reduce((acc, segment, idx) => {
        if (segment.annotations.length) {
            acc[idx] = Object.keys(acc).length;
        }
        return acc;
    }, {});
    return (_jsx("span", { children: segments.map((segment, idx) => (_jsx(React.Fragment, { children: renderAnnotation({
                text: segment.text,
                annotations: segment.annotations,
                index: idx,
                annotationIndex: annotationIndexTable[idx],
                neighboringAnnotations: {
                    before: idx > 0 ? getLastSegment(idx).annotations : [],
                    after: idx < segments.length - 1 ? segments[idx + 1].annotations : [],
                },
            }) }, idx))) }));
}
