export function commaBigNumbers(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function sForPlural(n) {
    return n === 1 ? "" : "s";
}
export function camelify(str) {
    if (!str)
        return str;
    if (str.includes(" ")) {
        return str.split(" ").map(camelify).join(" ");
    }
    if (str.includes("-")) {
        return str.split("-").map(camelify).join("-");
    }
    const firstLetter = str[0];
    return `${firstLetter.toUpperCase()}${str.slice(1).toLocaleLowerCase()}`;
}
/*
  TODO:
  const summariesDescription = React.useMemo(() => {
    if (
      !summariesList ||
      loadingSummaries ||
      evidenceAndTotals.loading ||
      summaries.length === 0
    ) {
      return []
    }
    const description = [
      `showing ${summaries.length} ${
        summaries.length === 1 ? "summary" : "summaries"
      }`,
    ]

    if (summaries.length > 1) {
      description.push(`sorted by number of results considered`)
    }

    return description
  }, [summaries, summariesList, evidenceAndTotals, loadingSummaries])
*/
