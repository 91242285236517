export function truncateToNearestSpace(text, { maxLength, postfix } = {}) {
    if (!maxLength)
        return text;
    if (text.length <= maxLength)
        return text;
    const lastSpace = text.lastIndexOf(" ", maxLength);
    if (lastSpace < 0)
        return text.slice(0, maxLength);
    const truncated = text.slice(0, lastSpace);
    if (truncated.length < text.length && postfix)
        return truncated + postfix;
    else
        return truncated;
}
