var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { DivButton } from "./DivButton";
export function HeaderButton(_a) {
    var { disabled, className } = _a, props = __rest(_a, ["disabled", "className"]);
    return (_jsx(DivButton, Object.assign({ className: classes("border rounded-lg border-neutral-800 flex items-center justify-center border border-neutral-800 text-neutral-500", disabled
            ? "cursor-not-allowed opacity-50"
            : "hover:bg-primary_heavy hover:text-white hover:border-primary_heavy", className), disabled: disabled, style: {
            width: 28,
            height: 28,
            // borderRadius: 8,
        } }, props)));
}
