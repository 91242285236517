import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useHydrateAtoms } from "jotai/utils";
import React from "react";
import { useMemoIfEqual } from "../../../utils/lifecycle/useMemoIfEqual";
import { EMPTY_QUERY_STATE } from "../../verions/V3_QueryState";
import { a_queryState } from "./useQueryState";
export function HydrateQueryState({ initial = EMPTY_QUERY_STATE, children, }) {
    //TODO: this is breaking reloading
    //const corpus_ids = useValidatedCorpusIds()
    const validatedState = useMemoIfEqual(Object.assign({}, initial));
    useHydrateAtoms([[a_queryState, validatedState]]);
    return _jsx(_Fragment, { children: children });
}
