var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { FeatureIdentifier, } from "../../api/types/apiTypes";
import { CorpusCapability } from "../../api/utils/capabilities";
import { FeedbackWidget } from "../../utils/feedback/FeedbackWidget";
import { sendFeedback } from "../../utils/feedback/sendFeedback";
import { selectSingle } from "../../utils/generic/collections";
import { useParams } from "../../utils/lifecycle/useParams";
import { ClipboardCopy } from "../actions/ClipboardCopy";
import { Empty } from "../common/layout/Empty";
import { Scroller } from "../common/layout/Scroller";
import { showNotification } from "../common/status/notifications";
import { SummaryCard } from "./SummaryCard";
function summariesMessage(summaries) {
    if (!summaries) {
        return "No Summaries";
    }
    if (summaries.length === 0) {
        return "Not enough relevant evidence to summarize. Please revise your selections.";
    }
}
export function Summaries({ evidence, summaries, loadingSummaries, loadingEvidence, corpus_filter, selected, setSelected, getEvidenceWithPosition, addFilter, clearFilters, }) {
    const [{ feedback }, updateParams] = useParams({
        feedback: {},
    });
    function sendSummaryFeedback(item, type) {
        return __awaiter(this, void 0, void 0, function* () {
            yield sendFeedback(FeatureIdentifier.SUGGEST_EVIDENCE_SUMMARY, CorpusCapability.SUGGEST_SUMMARY, type, item, corpus_filter);
            updateParams({ feedback: Object.assign(Object.assign({}, feedback), { [item.id]: type }) });
            showNotification("Feedback sent");
        });
    }
    function footerContent(summary, selected) {
        return (_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx(ClipboardCopy, { text: summary.summary }), _jsx("div", { children: _jsx(FeedbackWidget, { item: summary, feedback: feedback, sendFeedbackFn: sendSummaryFeedback, bgClass: isSelected(summary) ? undefined : "bg-accent-50", expanded: true }) })] })));
    }
    const isSelected = React.useCallback((summary) => !!selected.find(s => s.id == summary.id), [selected]);
    const selectedSummary = summaries.find(s => isSelected(s));
    return (_jsx("div", Object.assign({ className: "ml-2 flex flex-col flex-1" }, { children: _jsxs(Empty, Object.assign({ loading: !evidence
                ? "Loading…"
                : (loadingSummaries || loadingEvidence) && "Summarizing…", empty: summariesMessage(summaries), showIcon: false }, { children: [selectedSummary && (_jsx(_Fragment, { children: _jsx(SummaryCard, { summary: selectedSummary, summaryIndex: 0, summaryCount: 1, selected: true, otherSelected: false, onSelect: () => setSelected([]), footer: footerContent(selectedSummary, true), getEvidenceWithPosition: getEvidenceWithPosition, addFilter: addFilter, clearFilters: clearFilters }, 0) })), _jsx(Scroller, { children: summaries.map((summary, idx) => (_jsx(SummaryCard, { summary: summary, summaryIndex: idx, summaryCount: summaries.length, otherSelected: Boolean(selectedSummary), hidden: isSelected(summary), onSelect: () => setSelected(selectSingle(selected, summary)), footer: footerContent(summary, isSelected(summary)), getEvidenceWithPosition: getEvidenceWithPosition, addFilter: addFilter, clearFilters: clearFilters }, idx))) })] })) })));
}
