import { atom } from "jotai";
import { RESET } from "jotai/utils";
import { a_debugLog } from "./a_debugMode";
function showTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    // Add leading zeros if necessary.
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    // Display the time in a readable format.
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
function loggedAtomWithReset(initialValue, key) {
    const a_resettable = atom(initialValue, (get, set, update) => {
        const newValue = typeof update === "function"
            ? update(get(a_resettable))
            : typeof update === "object"
                ? Object.assign(Object.assign({}, get(a_resettable)), update) : update;
        if (newValue === RESET) {
            set(a_debugLog, `${showTime()} resetting ${key}`);
            set(a_resettable, initialValue);
        }
        else {
            set(a_debugLog, `${showTime()} updating ${key} ->`, newValue);
            set(a_resettable, newValue);
        }
    });
    return a_resettable;
}
export function createResettableStateAtoms(initialState) {
    return Object.entries(initialState).reduce((acc, [key, value]) => {
        acc[key] = loggedAtomWithReset(value, key);
        return acc;
    }, {});
}
