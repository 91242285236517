import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Radio } from "antd";
import { isEqual } from "lodash";
import React from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from "recharts";
import { useCurrentSource } from "../../api/useCurrentSource";
import { getCurrentGranularity } from "../../appState/atomic/queryState/semanticSearch/getCurrentGranularity";
import { useCurrentSession, } from "../../appState/atomic/session/session";
import { useTheme } from "../../appState/context/theme/ThemeContext";
import { classes } from "../../appState/context/theme/classes";
import { selectedBgClass } from "../../appState/context/theme/lf";
import { DivButton } from "../common/buttons/DivButton";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { DIFFERENCE_LINE_NAME } from "./consts/DIFFERENCE_LINE_NAME";
import { useTrendDomain } from "./useTrendDomain";
import { useTrendRange } from "./useTrendRange";
const colorList = [
    "#A968C2",
    "#D4B3E1",
    "#000000",
    "#898e99",
    "#565b66",
    "#76358f",
    "#2965cc",
    "#ecb11a",
    "#29a633",
    "#565b66",
    "#05944F",
    "#E11900",
];
const renderLegend = (props) => {
    if (!props)
        return null;
    const { payload } = props;
    return (_jsx("div", Object.assign({ className: "flex justify-center pb-2 text-md" }, { children: _jsx("div", Object.assign({ className: "flex space-x-4 items-center" }, { children: payload.map((entry, idx) => (_jsxs("div", Object.assign({ className: "flex space-x-1 items-center" }, { children: [_jsx("div", { style: {
                            width: 12,
                            height: idx > 1 ? 3 : 12,
                            backgroundColor: entry.color,
                        } }), _jsxs("div", { children: [" ", entry.value] })] }), idx))) })) })));
};
function updateZoomLevel(sessionUpdater, selectedGranularity) {
    sessionUpdater({
        selectedGranularity,
    });
}
function updateSelectedTrendDomain(sessionUpdater, newDomain) {
    sessionUpdater({
        selectedTrendDomain: newDomain,
    });
}
function updateSelectedTrendRange(sessionUpdater, newRange) {
    sessionUpdater({
        selectedTrendRange: newRange,
    });
}
const CustomTooltip = ({ active, payload, label, }) => {
    if (active && payload && payload.length) {
        return (_jsxs("div", Object.assign({ className: "custom-tooltip rounded-md bg-white p-2 text-md border border-neutral-700" }, { children: [_jsx("p", Object.assign({ className: "label" }, { children: label })), payload.map((line) => {
                    const value = Number(line.value);
                    return (_jsx("p", Object.assign({ className: "text-sm" }, { children: `${line.dataKey}: ${(line.dataKey === DIFFERENCE_LINE_NAME
                            ? value
                            : Math.abs(value)).toLocaleString()}` }), line.dataKey));
                })] })));
    }
    return null;
};
function dataItemToDateWindowItems(dataItem, idx) {
    const timePeriod = dataItem.label;
    return Object.keys(dataItem)
        .filter(x => x !== "label")
        .map(l => ({
        label: timePeriod,
        idx,
        value: dataItem[l],
    }));
}
export function TrendsChart({ data, title, selectedDateRange, onSelectedDateChange, trendsGranularities, setSelectedEvidenceType, extraControls, getColorIdx, }) {
    const theme = useTheme();
    const [{ selectedGranularity, selectedTrendRange, selectedTrendDomain, trendRangeType = "count", }, sessionUpdater,] = useCurrentSession();
    const granularity = getCurrentGranularity(trendsGranularities, selectedGranularity);
    const currentSource = useCurrentSource();
    const currentRangeSelection = useTrendRange();
    const currentDomainSelection = useTrendDomain();
    const domains = currentSource === null || currentSource === void 0 ? void 0 : currentSource.supported_trends_fields.domain;
    const ranges = currentSource === null || currentSource === void 0 ? void 0 : currentSource.supported_trends_fields.range;
    function isDataItemInSelectedDateRange(dataItem) {
        if (!selectedDateRange ||
            (!selectedDateRange.start && !selectedDateRange.end))
            return false;
        const isAfterStart = !selectedDateRange.start ||
            (!!selectedDateRange.start &&
                new Date(selectedDateRange.start) <= new Date(dataItem.label));
        const isBeforeEnd = !selectedDateRange.end ||
            (!!selectedDateRange.end &&
                new Date(selectedDateRange.end) > new Date(dataItem.label));
        return isAfterStart && isBeforeEnd;
    }
    const [selectedDate, setSelectedDate] = React.useState(data
        .filter(isDataItemInSelectedDateRange)
        .flatMap(dataItemToDateWindowItems));
    //TODO: dangerous
    React.useEffect(() => {
        const newWindow = data
            .filter(isDataItemInSelectedDateRange)
            .flatMap(dataItemToDateWindowItems);
        !isEqual(selectedDate, newWindow) && setSelectedDate(newWindow);
    }, [selectedDateRange]);
    // Return all data labels in the selected range.
    function getSelectedDates() {
        const min = selectedDate.reduce((acc, val) => Math.min(acc, Number(val.label)), Number.MAX_VALUE);
        const max = selectedDate.reduce((acc, val) => Math.max(acc, Number(val.label)), Number.MIN_VALUE);
        return data
            .map(val => Number(val.label))
            .filter(val => min <= val && val <= max);
    }
    const chartKeys = React.useMemo(() => {
        if (data.length === 0)
            return [];
        return Object.keys(data[0]).filter(k => !["label"].includes(k));
    }, [data]);
    const hasDifference = chartKeys.includes(DIFFERENCE_LINE_NAME);
    const chartEntryLabels = React.useMemo(() => {
        return data.map(x => x.label.toString());
    }, [data]);
    function handleBarClick(e) {
        const evidenceType = e.tooltipPayload[0].dataKey.split(" ")[0];
        setSelectedEvidenceType(evidenceType);
        // the chart event handlers will select the date so no need here
    }
    function getItemsFromEvent(e) {
        const idx = chartEntryLabels.findIndex(key => key === (e === null || e === void 0 ? void 0 : e.activeLabel));
        const dataItem = data[idx];
        if (!dataItem)
            return [];
        return dataItemToDateWindowItems(dataItem, idx);
    }
    function handleChartClick(e) {
        const items = getItemsFromEvent(e);
        const uniqueDates = Object.keys(selectedDate.concat(items).reduce((acc, val) => {
            acc[val.label] = true;
            return acc;
        }, {}));
        // If there are exactly 2 dates then draw as a range otherwise just select the clicked date.
        const newStuff = uniqueDates.length === 2 && selectedDate.length > 0
            ? [selectedDate[0]].concat(items)
            : items;
        setSelectedDate(newStuff);
    }
    function handleChartDrag(e) {
        if (selectedDate.length === 0)
            return;
        if (selectedDate && (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.length) < 3) {
            const items = getItemsFromEvent(e);
            const newStuff = selectedDate ? [selectedDate[0]].concat(items) : items;
            setSelectedDate(newStuff);
        }
    }
    function handleChartMouseUp(a) {
        onSelectedDateChange && onSelectedDateChange(selectedDate.map(x => x.label));
    }
    // TODO This is never called. This might be the issue:
    //  https://forum.nwoods.com/t/getting-mouse-events-over-gojs-surface/6855/4
    function handleChartEvent(a, b) {
        if (b.altKey || b.ctrlKey || b.shiftKey) {
            handleChartDrag(a);
        }
    }
    function clearSelectedDate() {
        setSelectedDate([]);
        onSelectedDateChange && onSelectedDateChange([]);
    }
    function getReferenceAreaProps() {
        const dates = getSelectedDates();
        if (dates.length === 0)
            return {};
        const min = Math.min(...dates);
        const max = Math.max(...dates);
        return {
            key: `area-${min}-${max}`,
            x1: min.toString(),
            x2: max.toString(),
        };
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex-col flex justify-center space-y-1 text-sm" }, { children: [_jsx(DropDownMenu, Object.assign({ entries: () => ["count", "ratio"].map(type => {
                            return {
                                caption: type,
                                icon: () => _jsx(Radio, { checked: trendRangeType === type }),
                                action() {
                                    sessionUpdater({
                                        trendRangeType: type,
                                    });
                                },
                            };
                        }), captionClassName: "text-sm ", title: () => _jsx("span", { children: "Y-Axis Type" }) }, { children: _jsxs(DivButton, Object.assign({ className: "border py-2  text-tiny", style: {
                                transform: "rotate(180deg)",
                                writingMode: "vertical-lr",
                                textOrientation: "sideways",
                            } }, { children: ["(", trendRangeType, ")"] })) })), _jsx(DropDownMenu, Object.assign({ entries: () => (ranges || []).map((range, idx) => {
                            return {
                                caption: range.name,
                                icon: () => (_jsx(Radio, { checked: (selectedTrendRange === null || selectedTrendRange === void 0 ? void 0 : selectedTrendRange.name.toLocaleLowerCase()) ===
                                        range.name.toLocaleLowerCase() })),
                                action() {
                                    updateSelectedTrendRange(sessionUpdater, range);
                                },
                            };
                        }), captionClassName: "text-sm ", title: () => _jsx("span", { children: "Y-Axis" }) }, { children: _jsx(DivButton, Object.assign({ className: "border py-2  text-tiny", style: {
                                transform: "rotate(180deg)",
                                writingMode: "vertical-lr",
                                textOrientation: "sideways",
                            } }, { children: currentRangeSelection === null || currentRangeSelection === void 0 ? void 0 : currentRangeSelection.name })) }))] })), _jsxs("div", Object.assign({ className: "flex-1 flex-col items-stretch" }, { children: [_jsx("div", Object.assign({ style: { position: "relative", width: "100%", paddingBottom: "200px" } }, { children: _jsx("div", Object.assign({ style: {
                                position: "absolute",
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: 0,
                            } }, { children: _jsx(ResponsiveContainer, Object.assign({ width: "98%", height: 200 }, { children: _jsxs(ComposedChart, Object.assign({ height: 220, data: data, margin: { top: 5, right: 10, left: 10, bottom: 5 }, onMouseDown: handleChartClick, onMouseMove: (a, b) => handleChartEvent(a, b), onMouseUp: handleChartMouseUp, stackOffset: "sign", className: "cursor-pointer" }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { height: 15, dataKey: "label", tick: { fontSize: 10 } }), _jsx(YAxis, { tick: { fontSize: 10 }, tickFormatter: t => Number(t).toLocaleString(), width: 40 }), _jsx(Legend, { iconSize: 10, 
                                            // wrapperStyle={{fontSize: "12px"}}
                                            verticalAlign: "top", content: renderLegend }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), _jsx(ReferenceArea, Object.assign({}, getReferenceAreaProps(), { xAxisId: 0, strokeOpacity: 0.3 })), chartKeys
                                            .filter(k => k !== DIFFERENCE_LINE_NAME)
                                            .map((k, i) => {
                                            return (_jsx(Bar, { dataKey: k, fillOpacity: 1, fill: colorList[getColorIdx(i)], stackId: "stack", 
                                                // Use mouseup as the click event is otherwise lost when clicking
                                                // an unselected bar probably because of interaction with the mouse
                                                // event handlers on the chart itself.
                                                onMouseUp: handleBarClick, isAnimationActive: false, strokeWidth: 0, className: "cursor-pointer" }, `area-${k}`));
                                        }), _jsx(ReferenceLine, { y: 0, stroke: "#000" }), hasDifference && (_jsx(Line, { type: "monotone", dataKey: DIFFERENCE_LINE_NAME, stroke: theme.colors.trends_color, strokeWidth: 3, dot: false, isAnimationActive: false }))] })) })) })) })), _jsxs("div", Object.assign({ className: "flex-row flex justify-center relative  space-x-1 text-sm" }, { children: [_jsx(DropDownMenu, Object.assign({ entries: () => (domains || []).map((domain, idx) => {
                                    return {
                                        caption: domain.name,
                                        icon: () => (_jsx(Radio, { checked: (selectedTrendDomain === null || selectedTrendDomain === void 0 ? void 0 : selectedTrendDomain.name.toLocaleLowerCase()) ===
                                                domain.name.toLocaleLowerCase() })),
                                        action() {
                                            updateSelectedTrendDomain(sessionUpdater, domain);
                                        },
                                    };
                                }), captionClassName: "text-sm ", title: () => _jsx("span", { children: "X-Axis" }) }, { children: _jsx(DivButton, Object.assign({ className: "border px-2  text-tiny" }, { children: currentDomainSelection === null || currentDomainSelection === void 0 ? void 0 : currentDomainSelection.name })) })), granularity && (_jsx(DropDownMenu, Object.assign({ entries: () => trendsGranularities.map((interval, idx) => {
                                    return {
                                        caption: interval,
                                        icon: () => (_jsx(Radio, { checked: (selectedGranularity === null || selectedGranularity === void 0 ? void 0 : selectedGranularity.toLocaleLowerCase()) ===
                                                interval.toLocaleLowerCase() })),
                                        action() {
                                            updateZoomLevel(sessionUpdater, interval);
                                        },
                                    };
                                }), captionClassName: "text-sm ", title: () => _jsx("span", { children: "Zoom Level" }) }, { children: _jsxs(DivButton, Object.assign({ className: "border px-2 " }, { children: ["(", granularity, ")"] })) }))), _jsxs("div", Object.assign({ className: "flex absolute space-x-2 items-center", style: { right: 0 } }, { children: [_jsx(DivButton, Object.assign({ className: classes("border px-2", selectedDate.length && selectedBgClass), disabled: selectedDate.length === 0, onClick: clearSelectedDate }, { children: "Clear Selection" })), extraControls] }))] }))] }))] })));
}
