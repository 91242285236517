var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { uniq } from "lodash";
import React from "react";
import { ArrowRight, Line } from "../../common/icons/Icons";
import { isDirected, isExpandable, setDirection, setNodeExpansion, } from "../diagramUtils";
import { mergeNodes } from "../mergeNodes";
import { genId, isRelation } from "../model";
export function useSelectionctions({ selected, selectedIds, selectedNode, ga, stage, flow, dUpdater, }) {
    return React.useCallback(() => ({
        selection: [
            selectedNode &&
                isRelation(selectedNode) && {
                caption: isDirected(selectedNode) ? _jsx(ArrowRight, {}) : _jsx(Line, {}),
                shortcut: "d",
                desc: isDirected(selectedNode) ? "Directed" : "Undirected",
                action() {
                    return __awaiter(this, void 0, void 0, function* () {
                        const newDir = !isDirected(selectedNode);
                        const dirEdgeIds = ga
                            .edgesForNode(selectedNode.id)
                            .filter(e => (isRelation(e) && e.source === selectedNode.id) ||
                            e.target === selectedNode.id)
                            .map(e => e.id);
                        dUpdater.commit({
                            nodes: flow
                                .getNodes()
                                .map(n => n.id === selectedNode.id
                                ? setDirection(selectedNode, newDir)
                                : n),
                            edges: flow
                                .getEdges()
                                .map(e => dirEdgeIds.includes(e.id) ? setDirection(e, newDir) : e),
                        });
                    });
                },
            },
            {
                more: true,
                subEntries: [
                    {
                        caption: "Expansion",
                        subEntries: [
                            {
                                caption: "Expand",
                                disabled: !selected.some(isExpandable),
                                shortcut: "x",
                                action: () => dUpdater.commit({
                                    nodes: flow
                                        .getNodes()
                                        .map(n => selectedIds.has(n.id)
                                        ? setNodeExpansion([n], true)[0]
                                        : n),
                                }),
                            },
                            {
                                caption: "Collapse",
                                disabled: !selected.some(isExpandable),
                                shortcut: "shift+x",
                                action: () => dUpdater.commit({
                                    nodes: flow
                                        .getNodes()
                                        .map(n => setNodeExpansion([n], false)[0]),
                                }),
                            },
                        ],
                    },
                    {
                        caption: "Merge",
                        subEntries: [
                            {
                                caption: "Merge",
                                shortcut: "alt+m",
                                disabled: selected.length < 2 ||
                                    isRelation(selected[0]) ||
                                    uniq(selected.map(n => n.data.caption + n.type)).length !==
                                        1,
                                action() {
                                    stage(mergeNodes(selected, flow.getNodes(), flow.getEdges()));
                                },
                            },
                            {
                                caption: "Split",
                                shortcut: "alt+shift+m",
                                disabled: !selectedNode ||
                                    isRelation(selectedNode) ||
                                    ga.neighbors(selectedNode.id).length < 2,
                                action() {
                                    const nId = selectedNode.id;
                                    const toRemove = ga.getEdges(nId);
                                    const [nodes, edges] = toRemove.reduce((acc, e) => {
                                        const id = genId();
                                        acc[0].push(Object.assign(Object.assign({}, selectedNode), { id }));
                                        const source = e.source === nId ? id : e.source;
                                        const target = source === id ? e.target : id;
                                        acc[1].push(Object.assign(Object.assign({}, e), { id: genId(), source, target }));
                                        return acc;
                                    }, [[], []]);
                                    stage({
                                        nodes,
                                        edges,
                                        nodesToRemove: [nId],
                                        edgesToRemove: toRemove.map(e => e.id),
                                    }, { layout: "H" });
                                },
                            },
                        ],
                    },
                ],
            },
        ].filter(Boolean),
    }), [selected, selectedIds, selectedNode, ga, stage]);
}
