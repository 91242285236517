var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "antd";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { useOnGlobalEscape } from "../../../utils/interactions/utils/interactionEvents";
import { Close } from "../icons/Icons";
import "./Popup.less";
export function DropDown(_a) {
    var { getContent, overlayWidth, overlayHeight, children, disabled, containerClassName, onVisibleChange, hasClose, title, manualHide, modal, initiallyVisible = false, containerStyle, showArrow = false } = _a, props = __rest(_a, ["getContent", "overlayWidth", "overlayHeight", "children", "disabled", "containerClassName", "onVisibleChange", "hasClose", "title", "manualHide", "modal", "initiallyVisible", "containerStyle", "showArrow"]);
    const bgDivRef = React.useRef();
    const keyRef = React.useRef(0);
    function changeVisibility(visible) {
        if (!visible && bgDivRef.current) {
            document.body.removeChild(document.getElementById(bgDivRef.current));
            bgDivRef.current = undefined;
            keyRef.current = keyRef.current + 1;
        }
        setVisible(visible);
        onVisibleChange === null || onVisibleChange === void 0 ? void 0 : onVisibleChange(visible, hide);
    }
    function hide() {
        changeVisibility(false);
    }
    const [visible, setVisible] = React.useState(initiallyVisible);
    useOnGlobalEscape(!manualHide && visible, hide);
    return (_jsx(Popover, Object.assign({ getPopupContainer: !modal
            ? undefined
            : () => {
                if (!visible)
                    return document.body;
                if (bgDivRef.current)
                    return document.getElementById(bgDivRef.current);
                const bg = document.createElement("div");
                bg.style.opacity = ".6";
                bg.style.zIndex = "1000";
                bg.className = "absolute w-full h-full bg-black";
                bg.addEventListener("mousedown", e => {
                    hide();
                    e.stopPropagation();
                    e.preventDefault();
                });
                const div = document.createElement("div");
                div.id = uuidv4();
                bgDivRef.current = div.id;
                div.className = "absolute w-full h-full";
                div.append(bg);
                document.body.append(div);
                return div;
            }, overlayClassName: "Popup", placement: "bottomRight", align: { offset: [0, 8] }, showArrow: showArrow, destroyTooltipOnHide: false, autoAdjustOverflow: true, open: visible, trigger: disabled ? [] : ["click"], content: getContent(hide), overlayInnerStyle: Object.assign(Object.assign({ backgroundColor: "bg-alert_green-500", borderRadius: 8, padding: 0 }, (overlayWidth && { width: overlayWidth })), (overlayHeight && { height: overlayHeight })), onOpenChange: changeVisibility, title: title || hasClose ? (_jsxs("div", Object.assign({ className: "flex items-center justify-between " }, { children: [_jsx("div", Object.assign({ className: "font-semibold w-full" }, { children: typeof title === "function" ? title() : title })), hasClose && _jsx(Close, { className: "cursor-pointer", onClick: hide })] }))) : null }, props, { children: _jsx("div", Object.assign({ style: containerStyle, className: classes(disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer", containerClassName) }, { children: children })) }), keyRef.current));
}
