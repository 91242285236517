import { useCurrentSource } from "./useCurrentSource";
function useHasCapabilities(...capabilities) {
    const currentSource = useCurrentSource();
    return capabilities.map(capability => (currentSource === null || currentSource === void 0 ? void 0 : currentSource.supported_apis.includes(capability)) || false);
}
export function useCapabilitiesList() {
    const currentSource = useCurrentSource();
    return ((currentSource === null || currentSource === void 0 ? void 0 : currentSource.supported_apis) || []);
}
export function useCorpusFeatureFlags() {
    const currentSource = useCurrentSource();
    return (currentSource === null || currentSource === void 0 ? void 0 : currentSource.feature_flags) || {};
}
export function useHasCapability(capability) {
    return useHasCapabilities(capability)[0];
}
