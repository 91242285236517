var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined } from "@ant-design/icons";
import React from "react";
import { useEvidenceHighlights } from "../../../appState/atomic/evidence/useEvidenceHighlights";
import { useEvidenceSelection } from "../../../appState/atomic/evidence/useEvidenceSelection";
import { classes } from "../../../appState/context/theme/classes";
import { useUniqId } from "../../../utils/identity/useUniqId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { ActionButton } from "../../common/buttons/ActionButton";
import { DropDownMenu } from "../../common/popups/DropDownMenu";
import { Tooltip } from "../../common/popups/Tooltip";
import { DocumentHeader } from "../DocumentHeader";
import { EvidenceFooter } from "../EvidenceFooter";
import { EvidenceRow } from "../EvidenceRow";
import { CollapseToggle } from "./GroupCollapse";
export function DocumentCard(_a) {
    var { document, className, style, urlOpenerFn, filterText, actions, extraItemActions, renderAsHtml, onRemove, showSurrounding, onMetadataClick, onEvidenceTextSelected, showHighlights, actionsTooltip, metadataSorter, collapsed = false, setCollapsed = () => { }, annotationProvider, renderEvidenceActions, getEvidenceWithPosition } = _a, props = __rest(_a, ["document", "className", "style", "urlOpenerFn", "filterText", "actions", "extraItemActions", "renderAsHtml", "onRemove", "showSurrounding", "onMetadataClick", "onEvidenceTextSelected", "showHighlights", "actionsTooltip", "metadataSorter", "collapsed", "setCollapsed", "annotationProvider", "renderEvidenceActions", "getEvidenceWithPosition"]);
    const primaryEvidence = document.primary_evidence;
    const containerId = useUniqId(uuidv4);
    const { evidenceSelected, selectEvidence, deselectEvidence } = useEvidenceSelection();
    const { evidenceHighlighted } = useEvidenceHighlights();
    const getAllEvidence = React.useCallback((document) => [
        document.primary_evidence,
        ...document.additional_evidence,
    ], [document]);
    const selectEvidenceHelper = (e) => evidenceSelected(e.id) ? deselectEvidence([e.id]) : selectEvidence([e.id]);
    const selectionCount = React.useMemo(() => getAllEvidence(document).filter(e => evidenceSelected(e.id)).length, [document, getAllEvidence, evidenceSelected]);
    return (_jsx("div", Object.assign({ className: classes("relative flex flex-col items-stretch py-3 font-sans border-neutral-300 rounded-xl border-b border-t border-r", selectionCount > 0 ? "bg-accent-50" : "bg-neutral-100", className), style: style }, props, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch" }, { children: [_jsx("div", Object.assign({ className: classes("sticky top-0", selectionCount > 0 ? "bg-accent-50" : "bg-neutral-100"), style: { zIndex: 10 } }, { children: _jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx(CollapseToggle, { className: "px-4 py-2", collapsed: collapsed, setCollapsed: setCollapsed }), _jsx("div", Object.assign({ style: { width: "0", flex: "1 1 auto" } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx(DocumentHeader, { metadataSorter: metadataSorter, document: document, selectCount: selectionCount, urlOpener: urlOpenerFn }), _jsx(EvidenceFooter, { className: "px-1", evidence: primaryEvidence, onMetadataClick: onMetadataClick, metadataSorter: metadataSorter, suffix: _jsxs(_Fragment, { children: [actions && (_jsx(Tooltip, Object.assign({ content: actionsTooltip }, { children: _jsx("span", { children: _jsx(DropDownMenu, Object.assign({ entries: () => actions(primaryEvidence) }, { children: _jsx(ActionButton, { children: _jsx(EllipsisOutlined, {}) }) })) }) }))), extraItemActions] }) })] })) }))] })) })), !collapsed && (_jsx("div", Object.assign({ className: classes("flex flex-col items-stretch", "mt-2") }, { children: _jsx("div", Object.assign({ id: containerId }, { children: _jsx("div", Object.assign({ className: "" }, { children: [
                                document.primary_evidence,
                                ...document.additional_evidence,
                            ].map((evidence, index) => {
                                return (_jsx(EvidenceRow, { className: "px-2", evidence: evidence, documentId: document.document_id, filterText: filterText, showSurrounding: showSurrounding, showHighlights: showHighlights, renderAsHtml: renderAsHtml, isHighlighted: evidenceHighlighted, isSelected: evidenceSelected, selectEvidence: selectEvidenceHelper, removeEvidence: onRemove, openEvidence: urlOpenerFn, onEvidenceTextSelected: onEvidenceTextSelected, annotationProvider: annotationProvider, renderEvidenceActions: renderEvidenceActions, getEvidenceWithPosition: getEvidenceWithPosition }, index));
                            }) })) })) }))), collapsed && selectionCount ? (_jsx("div", { className: "w-full border-t border-gray-100" })) : null] })) })));
}
