import { jsx as _jsx } from "react/jsx-runtime";
import { isEqual } from "lodash";
import React from "react";
import { useReactFlow, useStoreApi } from "reactflow";
import { addWindowEventHandlers } from "../../../utils/events/addWindowEventHandlers";
import { updateItems } from "../../../utils/generic/collections";
import { useWhileKeyPressed } from "../../../utils/interactions/useWhileKeyPressed";
import { terminateEvent, } from "../../../utils/interactions/utils/interactionEvents";
import { Mode, useDiagramMode } from "../DiagramMode";
import { useDiagramStateUpdater } from "../DiagramState";
import { getSelectedNodeIds } from "../diagramUtils";
export function usePanningTrigger() {
    const flow = useReactFlow();
    const store = useStoreApi();
    const mode = useDiagramMode();
    const viewport = React.useRef();
    const dUpdater = useDiagramStateUpdater();
    React.useEffect(() => {
        if (mode.is(Mode.panning)) {
            viewport.current = flow.getViewport();
        }
    }, [mode()]);
    useWhileKeyPressed({
        space(down) {
            if (down) {
                mode.push(Mode.panning);
            }
            else {
                mode.pop();
            }
        },
    }, !mode.is(Mode.normal));
    return React.useCallback(e => {
        let last = e;
        if (e.button === 0 && mode.is(Mode.panning)) {
            const selected = getSelectedNodeIds(flow.getNodes());
            terminateEvent(e);
            const cancel = (e) => {
                last = null;
                terminateEvent(e);
                dispose();
            };
            const dispose = addWindowEventHandlers({
                mousemove(e) {
                    if (!last)
                        return;
                    store
                        .getState()
                        .panBy({ x: e.clientX - last.clientX, y: e.clientY - last.clientY });
                    last = e;
                },
                mouseup(e) {
                    cancel(e);
                    setTimeout(() => {
                        flow.setNodes(updateItems(flow.getNodes(), n => ({
                            selected: selected.has(n.id),
                        })));
                        if (!isEqual(viewport.current, flow.getViewport())) {
                            dUpdater.commit({ viewport: flow.getViewport() });
                        }
                        viewport.current = undefined;
                    }, 100);
                },
                keyup: cancel,
                keydown: cancel,
            });
        }
        return false;
    }, [mode()]);
}
export function PanningLayer() {
    const mode = useDiagramMode();
    return !mode.is(Mode.panning) ? null : (_jsx("div", { className: "absolute w-full h-full z-20 cursor-move" }));
}
