import { atom } from "jotai";
import { MetadataType, } from "../../../../api/types/metadataTypes";
import { FilterTabs, a_facetActiveTab, } from "../../../../components/facet/FilterTabs";
import { upsertOrRemove } from "../../../../utils/generic/collections";
import { migrateKeywordMetadata } from "../coraState/versionManagement/migrateKeywordMetadata";
import { toBoolean } from "../semanticSearch/toBoolean";
import { a_booleanMetadata, a_keywordMetadata, a_rangeMetadata, } from "./modelState";
export const a_metadataUpserter = atom(null, (get, set, e) => {
    const field = e.type === MetadataType.KEYWORD
        ? "keywordMetadata"
        : e.type === MetadataType.BOOLEAN
            ? "booleanMetadata"
            : "rangeMetadata";
    const m = e.type === MetadataType.KEYWORD
        ? migrateKeywordMetadata(e)
        : e;
    const value = m.type === MetadataType.KEYWORD
        ? Array.isArray(m.value)
            ? m.value
            : [m.value]
        : m.type === MetadataType.BOOLEAN
            ? toBoolean(m.value)
            : { start: m.value, end: m.value };
    set(a_facetActiveTab, FilterTabs.Document);
    switch (e.type) {
        case MetadataType.BOOLEAN:
            set(a_booleanMetadata, [
                get(a_booleanMetadata).filter(b => m.id !== b.id),
                Object.assign(Object.assign({}, m), { value }),
            ]);
            break;
        case MetadataType.KEYWORD:
            set(a_keywordMetadata, [
                ...get(a_keywordMetadata).filter(k => m.id !== k.id),
                ...upsertOrRemove(value, i => i.id, m.id, Object.assign(Object.assign({}, m), { value })),
            ]);
            break;
        case MetadataType.DATE:
            set(a_rangeMetadata, [Object.assign(Object.assign({}, m), { value })]);
            break;
    }
});
