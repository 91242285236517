import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { useCurrentSource } from "../../api/useCurrentSource";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useConceptListState } from "../../appState/atomic/session/parts/conceptListState";
import { ManageLists } from "../common/icons/Icons";
import { showNotification } from "../common/status/notifications";
import { showCustomListEditor } from "../modals/CustomListsManager/CustomListEditor";
export function useAddCustomListMenuEntry() {
    const [{ customConceptListsByCorpusId: listsById }, listUpdater] = useConceptListState();
    const source = useCurrentSource();
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    return (facetSelection) => {
        return {
            caption: "Create Custom List from Selection...",
            disabled: !facetSelection.length || !source,
            icon: _jsx(ManageLists, {}),
            action() {
                showCustomListEditor({
                    corpus_id: corpus_ids[0],
                    list: undefined,
                    customConceptListsByCorpusId: listsById,
                    initialText: facetSelection.map(c => c.name).join("\n"),
                }).then(list => {
                    if (!list)
                        return;
                    const lists = listsById[source.id] || [];
                    const updatedListsById = Object.assign(Object.assign({}, listsById), { [source.id]: lists.concat([list]) });
                    listUpdater({ customConceptListsByCorpusId: updatedListsById });
                    showNotification(`Custom list ${list.name} created with ${list.accepted_concepts.length} concepts`);
                });
            },
        };
    };
}
