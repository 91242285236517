//EVIDENCE SEARCH PARAMS
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "ascending";
    SortOrder["DESC"] = "descending";
})(SortOrder || (SortOrder = {}));
export const COMMON_STATE_PARAMS_KEYS = [
    "aperture",
    "corpus_ids",
    "refuting",
    "evidenceFilter",
    "sortFieldId",
    "sortOrder",
];
