import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { message } from "antd";
import { useAtomValue } from "jotai";
import React from "react";
import "../../../App.less";
import { PopDF } from "../../PopDF";
import { useActivityMonitor } from "../../api/useActivityMonitor";
import { useSynchronizeAllTags } from "../../appState/atomic/allTags";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { useHistoryUpdater } from "../../appState/atomic/history/useHistoryUpdater";
import { HydrateQueryState } from "../../appState/atomic/queryState/HydrateQueryState";
import { INVALID_CORPUS_ID } from "../../appState/atomic/queryState/consts/INVALID_CORPUS_ID";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useQueryIdSynchronizer } from "../../appState/atomic/queryState/useQueryIdSynchronizer";
import { useSessionSavedStatus } from "../../appState/atomic/session/savedStatus";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useInfoStream } from "../../appState/atomic/session/useInfoStream";
import { useSessionSaver } from "../../appState/atomic/session/useSessionSaver";
import { useWarnReload } from "../../appState/atomic/session/useWarnReload";
import { useErrorType } from "../../appState/beacon/errorStream";
import { useRegisterKBMenuExtensions } from "../../extensions/KBLinks";
import { useLogRocket } from "../../externalServices/useLogRocket";
import { useMemoIfEqual } from "../../utils/lifecycle/useMemoIfEqual";
import { SuggestedQuestions } from "../SuggestedQuestions";
import { FAQ } from "../common/assist/FAQ";
import { VideoPopup } from "../common/assist/VideoPopup";
import tourLandmarks from "../common/assist/tourLandmarks.json";
import { TOUR_STORAGE_KEY, Tour, useStartTour, } from "../common/assist/useStartTour";
import { Modals } from "../common/modals/Modal";
import { useGlobalPopup } from "../common/popups/GlobalPopUp";
import { clearLoading, setLoading, useLoading, } from "../common/transitions/Loading";
import { Header } from "../header/Header";
import { QueryBar } from "../header/QueryBar";
import { ModelBuilderContext } from "../modelBuilder/ModelBuilderContext";
import "./Cora.less";
import { CoraMainContent } from "./CoraMainContent";
import { PageAlert } from "./PageAlert";
function useWarnInvalidSource() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    React.useEffect(() => {
        if (corpus_ids.indexOf(INVALID_CORPUS_ID) >= 0) {
            message.error({
                key: "useWarnInvalidSource",
                content: "Source not Available. Select a Source.",
                duration: 10,
                onClick: () => message.destroy("useWarnInvalidSource"),
            });
        }
    }, useMemoIfEqual([corpus_ids]));
}
export function CoraWrapper({ queryState }) {
    return (_jsx(HydrateQueryState, Object.assign({ initial: queryState }, { children: _jsx(ModelBuilderContext, { children: _jsx(Cora, {}) }) })));
}
function Cora() {
    useLogRocket();
    useQueryIdSynchronizer();
    useHistoryUpdater();
    useActivityMonitor();
    useErrorType(false);
    const { savedStatus } = useSessionSavedStatus();
    useWarnReload(!savedStatus);
    const loading = useLoading();
    const PopupMenu = useGlobalPopup();
    useSynchronizeAllTags();
    useWarnInvalidSource();
    useSessionSaver();
    const startTour = useStartTour();
    useRegisterKBMenuExtensions();
    const modalInfo = useInfoStream("MODAL");
    React.useEffect(() => {
        (modalInfo === null || modalInfo === void 0 ? void 0 : modalInfo.message) ? setLoading(modalInfo.message) : clearLoading();
    }, [modalInfo === null || modalInfo === void 0 ? void 0 : modalInfo.message]);
    const { onboardingTour, disableTourAutoLaunch } = useFeatureFlags();
    const hasStartedTour = window.localStorage.getItem(TOUR_STORAGE_KEY);
    const [{ structuredQuery, showStructuredQuery, modelBuilderAvailable }] = useCurrentSession();
    if (onboardingTour && hasStartedTour !== "true" && !disableTourAutoLaunch)
        startTour(tourLandmarks.landmarks);
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch absolute w-full h-full overflow-clip bg-background" }, { children: [_jsx(PageAlert, {}), _jsx("div", Object.assign({ className: "flex flex-col items-stretch w-full" }, { children: _jsx(Header, Object.assign({ controls: structuredQuery &&
                        showStructuredQuery &&
                        !modelBuilderAvailable && _jsx(SuggestedQuestions, {}) }, { children: _jsx("div", Object.assign({ className: "flex space-x-1 items-center flex-1" }, { children: _jsx(QueryBar, {}) })) })) })), _jsx(CoraMainContent, {}), _jsx(PopupMenu, {}), _jsx(Modals, {}), loading, _jsx(PopDF, {}), _jsx(FAQ, {}), _jsx(VideoPopup, {}), _jsx(Tour, {})] })));
}
