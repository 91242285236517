import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { hex } from "chroma-js";
import { useAtomValue } from "jotai";
import { a_constraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "../../appState/atomic/queryState/constraintModel/constraints";
import { ConceptSource } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { CONTEXT_CONCEPT_COLOR_VALS } from "../../appState/context/theme/lf";
import { ConceptLine } from "./ConceptLine";
export function CurrentContextList() {
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const contextConceptIdPairs = constraint.contextNodeIds.flatMap((nodeId) => modelUtil
        .getConceptIdsForNodeIds(ConceptSource.MODEL, [nodeId])
        .map(c => [nodeId, c]));
    if (!contextConceptIdPairs.length)
        return null;
    return (_jsx("div", Object.assign({ className: "flex flex-col space-y-1 p-2", style: {
            backgroundColor: hex(CONTEXT_CONCEPT_COLOR_VALS[100]).alpha(0.75).css(),
        } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col " }, { children: [_jsx("span", Object.assign({ className: "font-semibold underline" }, { children: "Context concepts" })), contextConceptIdPairs.map(([nId, cId], i) => (_jsx(ConceptLine, { active: true, nodeId: nId, conceptId: cId, overflow: 2 }, i)))] })) })));
}
