var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useDebouncedValue, } from "../utils/lifecycle/useDebouncedValue";
import { useHatch } from "../utils/lifecycle/useHatch";
export function useRemoteQuery({ loader, params: liveParams, mapper = (v) => v, defaultValue, rateLimitMode = "throttle", debounceSettings = { delayMs: 300 }, key, }) {
    // make mode immutable to avoid hook issues
    const mode = React.useMemo(() => rateLimitMode, []);
    const [params, setOpen] = mode === "throttle"
        ? useHatch(liveParams)
        : mode === "debounce"
            ? [useDebouncedValue(liveParams, debounceSettings), () => { }]
            : [liveParams, () => { }];
    const query = useQuery({
        queryKey: [key !== null && key !== void 0 ? key : "", params],
        queryFn: (context) => __awaiter(this, void 0, void 0, function* () {
            const { signal } = context;
            const [_, params] = context.queryKey;
            return loader(params, { signal });
        }),
        enabled: !!params,
    });
    if (mode === "throttle") {
        React.useEffect(() => {
            setOpen(!mode || query.isError || !query.isLoading);
        }, [query.isLoading, query.error, mode]);
    }
    return React.useMemo(() => {
        const value = query.data || null;
        return {
            value: value === null
                ? null
                : params === undefined
                    ? defaultValue !== null && defaultValue !== void 0 ? defaultValue : null
                    : mapper(value, params),
            loading: query.isLoading,
            error: query.error,
        };
    }, [query.data, query.error, query.isLoading]);
}
