import { atom, useAtom, useSetAtom } from "jotai";
import { a_queryInputText } from "../useQueryInputText";
import { maybeUpdateOrReset } from "../utils/maybeUpdateOrReset";
import { queryStateAtoms } from "./queryStateAtoms";
import { a_emitter } from "./useQueryStateEventListener";
const a_eventSource = atom(null);
const a_eventSourceUpdate = atom(null, (get, set, eventSource) => {
    const emitter = get(a_emitter);
    const queryState = get(a_queryState);
    // TODO: timeout may not be needed in jotai
    // TODO: this event system may not be needed at all
    //set timeout so the event is thrown after updates
    setTimeout(() => emitter.emit([queryState, eventSource]));
    return eventSource;
});
const QUERY_STATE_KEYS = Object.keys(queryStateAtoms);
export const a_queryState = atom(get => {
    const queryState = Object.fromEntries(QUERY_STATE_KEYS.map((key) => [
        key,
        get(queryStateAtoms[key]),
    ]));
    return queryState;
}, (get, set, updateOrFn, eventSource = null) => {
    const currentParams = get(a_queryState);
    const update = typeof updateOrFn === "function" ? updateOrFn(currentParams) : updateOrFn;
    if (eventSource !== get(a_eventSource))
        set(a_eventSourceUpdate, eventSource);
    maybeUpdateOrReset(queryStateAtoms, get, set, update, currentParams);
});
export function useQueryState() {
    const queryState = useAtom(a_queryState)[0];
    return [queryState, useSetAtom(a_queryState)];
}
const a_restoreQueryState = atom(null, (get, set, queryState) => {
    var _a, _b, _c;
    const value = typeof queryState === "function"
        ? queryState(get(a_queryState))
        : Object.assign(Object.assign({}, get(a_queryState)), queryState);
    set(a_queryState, value);
    const activeConstraintId = (_a = value === null || value === void 0 ? void 0 : value.constraintModel) === null || _a === void 0 ? void 0 : _a.activeConstraintId;
    const activeConstraint = (activeConstraintId &&
        ((_c = (_b = value === null || value === void 0 ? void 0 : value.constraintModel) === null || _b === void 0 ? void 0 : _b.state.constraints) === null || _c === void 0 ? void 0 : _c[activeConstraintId])) ||
        null;
    if (activeConstraint) {
        set(a_queryInputText, activeConstraint.userText || "");
    }
});
export function useRestoreQuery() {
    return useSetAtom(a_restoreQueryState);
}
