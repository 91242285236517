import { useAtomValue } from "jotai";
import { useRemoteQuery } from "../../../api/useRemoteQuery";
import { a_corpusFilterBuilder } from "../queryState/constraintModel/ConstraintModelUtil";
export function useRefutingSuggestions(key, disabled, loader, mapper) {
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    const corpus_filter = corpusFilterBuilder.fullFilter().toCorpusFilter();
    const params = disabled
        ? undefined
        : {
            corpus_filter,
            count: 10,
        };
    const refutingSuggestions = useRemoteQuery({
        key,
        loader,
        params,
        mapper,
    });
    return {
        refutingSuggestions,
        params,
    };
}
