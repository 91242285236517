var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { pushError } from "../appState/beacon/errorStream";
import { setLoading } from "../components/common/transitions/Loading";
export function useInterruptableFetch() {
    const [controller, setController] = React.useState();
    return [
        function (fetch, message) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const controller = new AbortController();
                    setController(controller);
                    setLoading({
                        message,
                        onCancel() {
                            setController(undefined);
                            controller.abort();
                            setLoading(false);
                        },
                    });
                    yield fetch(controller.signal);
                }
                catch (e) {
                    if (e.code !== DOMException.ABORT_ERR) {
                        pushError({ error: e });
                    }
                }
                finally {
                    setController(undefined);
                    setLoading(false);
                }
            });
        },
        Boolean(controller),
    ];
}
