import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WarningFilled } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { Document } from "../icons/Icons";
export function VisualStatusMessage({ status, message, className, style, }) {
    const content = (_jsxs("div", Object.assign({ className: classes("flex items-center", className), style: style }, { children: [status === "loading" ? (_jsx(Spin, { size: "default" })) : status === "empty" ? (_jsx(Document, { className: "text-black" })) : (_jsx(WarningFilled, { className: "text-black" })), _jsx("div", Object.assign({ className: "ml-2 text-gray-500" }, { children: message }))] })));
    return content;
}
