import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useSelectionChange } from "../../../utils/dom/useSelectionChange";
import { useUniqId } from "../../../utils/identity/useUniqId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { DefaultMarkDown, EvidenceMarkDown } from "../../common/text/MarkDown";
export function EvidenceText({ renderAsHtml, truncationThreshold, evidence, filterText, prefix, annotationProvider, onEvidenceTextSelected, }) {
    const containerId = useUniqId(uuidv4);
    useSelectionChange(false, //TODO: not sure about this
    containerId, evidence.text, onEvidenceTextSelected &&
        (({ text, rect }) => onEvidenceTextSelected({ evidence, rect, text })));
    const text = truncateToNearestSpace(evidence.text, {
        maxLength: truncationThreshold,
    });
    return (_jsx("span", Object.assign({ id: containerId }, { children: renderAsHtml ? (_jsx("span", { dangerouslySetInnerHTML: renderAsHtml
                ? {
                    __html: (prefix || "") + text,
                }
                : undefined })) : annotationProvider ? (_jsxs(_Fragment, { children: [prefix && _jsx("span", Object.assign({ className: "mr-1" }, { children: prefix })), _jsx(EvidenceMarkDown, { text: text, className: "flex-1 text-base ", evidence: evidence, annotationProvider: annotationProvider })] })) : (_jsx(DefaultMarkDown, { className: "flex-1 text-base", text: (prefix || "") + text, separator: filterText })) })));
}
export function truncateToNearestSpace(text, { maxLength, postfix } = {}) {
    if (!maxLength)
        return text;
    if (text.length <= maxLength)
        return text;
    const lastSpace = text.lastIndexOf(" ", maxLength);
    if (lastSpace < 0)
        return text.slice(0, maxLength);
    const truncated = text.slice(0, lastSpace);
    if (truncated.length < text.length && postfix)
        return truncated + postfix;
    else
        return truncated;
}
