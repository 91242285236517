import { createClusterId } from "../../../../utils/identity/createClusterId";
import { uuidv4 } from "../../../../utils/identity/uuidv4";
import { toConceptOrCustom } from "../semanticSearch/toConceptOrCustom";
import { cleanupConstraints } from "./cleanupConstraints";
import { createConstraint } from "./createConstraint";
export function conjunctiveToDMParams({ query_concepts, is_bag_of_concepts, analyzed_query }, question) {
    //analyze_query becomes dmParams
    if (analyzed_query && !is_bag_of_concepts) {
        const { concepts, relations, clauses, constraints } = analyzed_query;
        return {
            concepts,
            relations,
            clauses,
            constraints: cleanupConstraints(constraints),
        };
    }
    const withIds = (query_concepts === null || query_concepts === void 0 ? void 0 : query_concepts.map(c => [createClusterId(c), toConceptOrCustom(c)])) || [];
    const concepts = Object.fromEntries(withIds);
    const constraints = [
        createConstraint(uuidv4(), Object.keys(concepts), question),
    ];
    return {
        relations: {},
        clauses: {},
        concepts,
        constraints,
    };
}
