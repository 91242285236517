export const defaultFeatureFlags = {
    disableFindInProMap: true,
    onboardingTour: false,
    disableTourAutoLaunch: true,
    disableVectorSearch: false,
    enableAnswerGeneration: false,
    enableAperture: true,
    enableConceptSearch: true,
    enableConjunctiveQueryAnalysis: true,
    enableCustomLists: true,
    enableGlossarySearch: true,
    enableGraphSolutions: true,
    enableLogrocketIntegration: false,
    enableFeedbackWidget: false,
    enableHelpCenterWidget: false,
    enableMissingConcepts: true,
    enableMultihopDiscovery: true,
    enableNaturalLanguageInput: false,
    enableQualifierFilters: true,
    enableQueryGraphs: true,
    enableRefutingSearch: false,
    enableSuggestedQuestions: true,
    enableTopicSummaries: true,
    enableTrends: true,
    enableTypeHierarchyNavigation: true,
    enableViewableVersionNumber: false,
    enableWatchlists: true,
    exportEvidence: false,
    showGraphReport: false,
    showTopResultsByDefault: true,
    defaultEvidencePageSize: 50,
};
