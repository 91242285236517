var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API } from "../../api/api";
import { useRemoteQuery } from "../../api/useRemoteQuery";
import { isInvalidCorpusId } from "../../appState/atomic/queryState/utils/isInvalidCorpusId";
export function useDomainModelDescriptors(corpus_ids) {
    const corpus_id = !isInvalidCorpusId(corpus_ids[0]) ? corpus_ids[0] : "";
    return useRemoteQuery({
        loader(corpus_id) {
            return __awaiter(this, void 0, void 0, function* () {
                if (!corpus_id)
                    return [];
                const models = (yield API.corpusInfo({ corpus_id })).domain_models;
                return models
                    .filter(d => d.id !== "00000000-0000-0000-0000-000000000000")
                    .map(d => (Object.assign(Object.assign({}, d), { corpus_id })));
            });
        },
        params: corpus_id,
    });
}
