import React from "react";
import { useReactFlow } from "reactflow";
import { classes } from "../../appState/context/theme/classes";
import { updateItems } from "../../utils/generic/collections";
import { showNotification } from "../common/status/notifications";
import { Mode, useDiagramMode } from "./DiagramMode";
import { GraphAnalysis } from "./GraphAnalysis";
import { getBounds, useCollisions } from "./collisions";
import { canMerge, clearItemsClass } from "./diagramUtils";
import { applyUpdate } from "./graphUpdate";
import { ElementType, genId } from "./model";
import { useDiagramStateUpdater } from "./DiagramState";
export function mergeNodes(toMerge, nodes, edges) {
    const id = genId();
    const merged = Object.assign(Object.assign({}, toMerge[0]), { id });
    const ids = new Set(toMerge.map(n => n.id));
    const ga = GraphAnalysis(nodes, edges);
    const eToRemove = toMerge.map(n => ga.getEdges(n.id)).flat();
    const eToAdd = eToRemove.map(e => {
        const source = ids.has(e.source) ? id : e.source;
        const target = ids.has(e.target) ? id : e.target;
        return Object.assign(Object.assign({}, e), { id: genId(), source, target });
    });
    return {
        nodes: [merged],
        edges: eToAdd,
        nodesToRemove: Array.from(ids),
        edgesToRemove: eToRemove.map(e => e.id),
    };
}
export function useNodeMergeDragging() {
    const dUpdater = useDiagramStateUpdater();
    const flow = useReactFlow();
    const oneNodeSelected = React.useRef(false);
    const collisions = useCollisions(flow);
    const targetRef = React.useRef();
    const mode = useDiagramMode();
    const onNodeDragStart = React.useCallback(() => {
        oneNodeSelected.current =
            flow.getNodes().filter(n => n.selected).length === 1;
        collisions.reset();
        mode(Mode.dragging);
    }, [mode]);
    function setTarget(node) {
        targetRef.current = node;
        mode(node ? Mode.merging : Mode.dragging);
    }
    const onNodeDrag = React.useCallback((_, source) => {
        if (!oneNodeSelected.current)
            return;
        const { type } = source;
        const target = targetRef.current;
        if (type === ElementType.CONCEPT) {
            const mergeId = collisions()
                .getCollisions(getBounds(source))
                .find(id => canMerge(source, flow.getNode(id)));
            if (mergeId) {
                if ((target === null || target === void 0 ? void 0 : target.id) === mergeId)
                    return;
                const neighbors = GraphAnalysis(flow.getNodes(), flow.getEdges()).neighborIds(source.id);
                const merge = flow.getNode(mergeId);
                if (!neighbors.has(merge.id)) {
                    setTarget(merge);
                    flow.setNodes(updateItems(flow.getNodes(), n => ({
                        className: classes.set(n.className, mergeId === n.id || n.id === source.id, "merging"),
                    })));
                    return;
                }
            }
        }
        if (target) {
            setTarget(undefined);
            flow.setNodes(clearItemsClass(flow.getNodes(), "merging"));
        }
    }, [mode]);
    const onNodeDragStop = React.useCallback((_, node) => {
        mode(Mode.normal);
        if (!oneNodeSelected.current)
            return;
        const target = targetRef.current;
        if (target) {
            dUpdater.commit(applyUpdate(mergeNodes([target, node], flow.getNodes(), flow.getEdges()), flow.getNodes(), flow.getEdges()));
            setTarget(undefined);
            showNotification("Nodes merged");
        }
        else {
            dUpdater.commit();
        }
    }, [mode]);
    return { onNodeDragStart, onNodeDrag, onNodeDragStop };
}
