import { useCurrentSession } from "../../appState/atomic/session/session";
import { useQuestionAnalyzer } from "./useQuestionAnalyzer";
import { useQuestionHistory } from "./useQuestionHistory";
export function useQuestionAnalysisHelper() {
    const { add: addQuestionToHistory } = useQuestionHistory();
    const [, sessionUpdater] = useCurrentSession();
    const analyzeQuestion = useQuestionAnalyzer();
    return function analyze(text) {
        sessionUpdater({ selectedSummaries: [] });
        analyzeQuestion(text).then(success => {
            if (!success)
                return;
            addQuestionToHistory(text.trim());
        });
    };
}
