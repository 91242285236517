var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { API } from "../../api/api";
import { decodeQueryParams } from "../../utils/routing/utils/decodeQueryParams";
import { ENCODED_QUERY_PARAM_KEY } from "../atomic/queryState/consts/ENCODED_QUERY_PARAM_KEY";
import { migrateCoraState } from "./migrators/stateV1toV3";
function getStateAndDataId(urlParams, defValue) {
    const _a = urlParams, _b = ENCODED_QUERY_PARAM_KEY, dataId = _a[_b], other = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
    const stateParams = decodeQueryParams(defValue, other);
    return { dataId, stateParams };
}
export function getStateFromQueryParams(urlParams, defValue) {
    return __awaiter(this, void 0, void 0, function* () {
        const { dataId, stateParams } = getStateAndDataId(urlParams, defValue);
        if (dataId) {
            // id there is a dataId, decode it and set state
            const decoded = yield API.getUrlData({ id: dataId });
            return migrateCoraState(Object.assign(Object.assign({}, defValue), decoded));
        }
        return stateParams;
    });
}
