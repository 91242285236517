var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { omit } from "lodash";
import React from "react";
import { API } from "../../api/api";
import { FeatureIdentifier, } from "../../api/types/apiTypes";
import { useCurrentSource } from "../../api/useCurrentSource";
import { CorpusCapability } from "../../api/utils/capabilities";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { toConceptOrCustom } from "../../appState/atomic/queryState/semanticSearch/toConceptOrCustom";
import { validateTextFilter } from "../../appState/atomic/queryState/semanticSearch/validateTextFilter";
import { useConceptExpansions } from "../../appState/atomic/queryState/useConceptExpansions";
import { useRelationsExpansions } from "../../appState/atomic/queryState/useRelationsExpansions";
import { a_displayState } from "../../appState/atomic/session/parts/displayState";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { identity } from "../../utils/generic/identity";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { BrandButton } from "../common/buttons/BrandButton";
import { ManageLists } from "../common/icons/Icons";
import { showCustomListsManager } from "../modals/CustomListsManager/CustomListsManager";
import { ClusterPickerField } from "./ClustersPickerField";
const clusterCount = 50;
//INFO: corpus filter params being modified for picker fields
function createParams(prefix, clustering_mode, corpus_filter, argument_name, use_precise_evidence_only // not used because it seems strange to filter these based on a view setting
) {
    prefix = validateTextFilter(prefix);
    const hasPrefix = prefix.length > 0;
    return (corpus_filter && Object.assign(Object.assign({ corpus_filter: hasPrefix
            ? { corpus_ids: corpus_filter.corpus_ids }
            : omit(corpus_filter, "context"), count: clusterCount, clustering_mode }, (hasPrefix && { prefix })), { argument_name,
        use_precise_evidence_only }));
}
export function ConceptsPicker(_a) {
    var { corpus_filter, clustering_mode, argument_name, selected, corpus_ids, noCustomLists, noSimilar = true, className, horizontal, noBorder, entityType } = _a, props = __rest(_a, ["corpus_filter", "clustering_mode", "argument_name", "selected", "corpus_ids", "noCustomLists", "noSimilar", "className", "horizontal", "noBorder", "entityType"]);
    const [selection, setSelection] = React.useState([]);
    useEffectIfDifferent(() => {
        setSelection(selected);
    }, [], [selected]);
    const supportsCustomLists = useIsFeatureSupported(Feature.CUSTOM_CONCEPT_LISTS);
    React.useEffect(() => setSelection(selected), [selected]);
    const source = useCurrentSource();
    const [{ conceptExpansion }] = useCurrentSession();
    const { remote, params } = useConceptExpansions(selection, corpus_ids, conceptExpansion, null, !noSimilar);
    function customListEditorButton(selected, hide) {
        return !supportsCustomLists ? null : (_jsx(BrandButton, Object.assign({ disabled: !source, className: "flex items-center text-text_primary px-4", roundedClass: "rounded-lg", paddingClass: "px-4 py-4", size: "small", icon: _jsx(ManageLists, {}), onClick: () => {
                hide(false);
                showCustomListsManager(corpus_ids);
            } }, { children: "List Manager" })));
    }
    function useExtraConceptsPickerTabs(selected) {
        React.useEffect(() => {
            setSelection(selected);
        }, [selected]);
        const supportsCustomLists = useIsFeatureSupported(Feature.CUSTOM_CONCEPT_LISTS);
        const [{ customConceptListsByCorpusId }] = useCurrentSession();
        const customLists = customConceptListsByCorpusId[corpus_ids[0]] || [];
        const customTab = !supportsCustomLists
            ? {}
            : {
                "Custom Lists": {
                    remote: {
                        value: customLists.filter(c => c.members.length),
                        loading: false,
                    },
                    alwaysEnabled: true,
                    emptyMessage: (hide) => "No Custom Lists",
                },
            };
        return Object.assign({}, customTab);
    }
    return (_jsx(ClusterPickerField, Object.assign({}, props, { className: className, clusterType: "Concept", selected: selected, synonyms: () => [], updateSynonyms: (item, synonyms) => (Object.assign(Object.assign({}, item), { surface_forms: synonyms })), loader: API.searchConcepts, mapper: toConceptOrCustom, getParams: prefix => createParams(prefix, clustering_mode, corpus_filter, argument_name), extraClusterTabsHook: useExtraConceptsPickerTabs, extraControls: customListEditorButton, horizontal: horizontal, noBorder: noBorder, remote: remote, entityName: "Concepts", entityType: entityType, identifier: FeatureIdentifier.SUGGEST_CONCEPTS, capability: CorpusCapability.SEARCH_CONCEPTS, confidence: c => { var _a; return (_a = c.score) !== null && _a !== void 0 ? _a : 0; }, nameRenderer: c => c.isCustom ? (_jsxs("span", { children: [_jsx(ManageLists, { className: "relative mr-1", style: { top: 2 } }), _jsx("span", { children: c.name })] })) : (c.name) })));
}
export function RelationsPicker(_a) {
    var { corpus_filter, clustering_mode, argument_name, selected, corpus_ids, concepts1, concepts2, noSimilar = true } = _a, props = __rest(_a, ["corpus_filter", "clustering_mode", "argument_name", "selected", "corpus_ids", "concepts1", "concepts2", "noSimilar"]);
    const [selection, setSelection] = React.useState([]);
    const { showTrends } = useAtomValue(a_displayState);
    useEffectIfDifferent(() => {
        setSelection(selected);
    }, [], [selected]);
    React.useEffect(() => setSelection(selected), [selected]);
    const { remote, params } = useRelationsExpansions(selection, corpus_ids, concepts1, concepts2, null, !noSimilar);
    function useExtraClusterTabs(selected) {
        React.useEffect(() => {
            setSelection(selected);
        }, [selected]);
        return Object.assign({}, (remote &&
            !noSimilar && {
            "Similar Relations": {
                remote,
            },
        }));
    }
    return (_jsx(ClusterPickerField, Object.assign({}, props, { synonyms: i => i.ids, updateSynonyms: (item, synonyms) => (Object.assign(Object.assign({}, item), { ids: synonyms })), loader: API.relations, mapper: identity, getParams: prefix => createParams(prefix, clustering_mode, corpus_filter, undefined), title: "Relation", clusterType: "Relation", placeholder: "select relation(s)\u2026", selected: selected, extraClusterTabsHook: useExtraClusterTabs, remote: remote, entityName: "Relations", entityType: "Relation", identifier: FeatureIdentifier.SUGGEST_RELATIONS, capability: CorpusCapability.SEARCH_RELATIONS })));
}
