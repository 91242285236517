import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { mapValues, pick, uniq } from "lodash";
import { createClusterId } from "../../../../utils/identity/createClusterId";
import { a_debugLog } from "../../utils/a_debugMode";
import { queryStateAtoms } from "../queryStateAtoms";
import { EMPTY_CONSTRAINT_MODEL_CONFIG } from "../types/ConstraintModelConfig";
import { TwoKey } from "../types/DoubleDict";
import { a_activeConstraint } from "./constraints";
import { a_constraintModelConfig, a_constraintModelSolution } from "./modelState";
export const a_concepts = focusAtom(queryStateAtoms.constraintModel, O => O.path("data.concepts"));
export const a_conceptNodes = focusAtom(queryStateAtoms.constraintModel, O => O.path("state.conceptNodes"));
const a_overrides = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.overrides"));
const a_requiredConcepts = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.requiredConcepts"));
const a_extendedNodeIds = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.extendedNodeIds"));
const a_extendedConceptNodes = focusAtom(queryStateAtoms.constraintModel, O => O.path("config.extendedConceptNodes"));
const a_solutions = focusAtom(queryStateAtoms.constraintModel, O => O.path("solution.solutions"));
////////////////////////////
// Constraint concept methods
////////////////////////////
const a_currentConstraintNodeIds = atom(get => {
    const constraint = get(a_activeConstraint);
    if (!constraint)
        return [];
    return [
        constraint.sourceNodeId,
        constraint.targetNodeId,
        ...constraint.contextNodeIds,
    ];
});
export const a_addConceptIdToNode = atom(null, (get, set, nodeId, conceptId) => {
    set(a_debugLog, "adding concept id to node", nodeId, conceptId);
    set(a_conceptNodes, conceptNodes => (Object.assign(Object.assign({}, conceptNodes), { [nodeId]: uniq([...(conceptNodes[nodeId] || []), conceptId]) })));
});
export const a_addConcepts = atom(null, (get, set, conceptConfigs) => {
    set(a_debugLog, "adding concepts", conceptConfigs);
    set(queryStateAtoms.constraintModel, model => {
        const newConcepts = conceptConfigs.reduce((acc, { concept, conceptId }) => {
            const newConceptId = conceptId || createClusterId(concept);
            return Object.assign(Object.assign({}, acc), { [newConceptId]: concept });
        }, model.data.concepts);
        const newConceptNodes = conceptConfigs.reduce((acc, { nodeId, concept, conceptId }) => {
            const newConceptId = conceptId || createClusterId(concept);
            return Object.assign(Object.assign({}, acc), { [nodeId]: uniq([...(acc[nodeId] || []), newConceptId]) });
        }, model.state.conceptNodes);
        const updatedModel = Object.assign(Object.assign({}, model), { data: Object.assign(Object.assign({}, model.data), { concepts: newConcepts }), state: Object.assign(Object.assign({}, model.state), { conceptNodes: newConceptNodes }) });
        const { activeConstraintId } = model;
        const activeConstraint = activeConstraintId
            ? model.state.constraints[activeConstraintId]
            : undefined;
        if (activeConstraintId && activeConstraint) {
            updatedModel.state.constraints[activeConstraintId].contextNodeIds =
                conceptConfigs.reduce((acc, { nodeId, createContextNode }) => {
                    if (createContextNode)
                        return uniq([...acc, nodeId]);
                    else
                        return acc;
                }, activeConstraint.contextNodeIds);
        }
        return updatedModel;
    });
});
export const a_addConceptToNode = atom(null, (get, set, nodeId, concept, conceptId) => {
    set(a_debugLog, "adding concept to node", nodeId, concept.name);
    const newConceptId = conceptId || createClusterId(concept);
    set(a_concepts, concepts => (Object.assign(Object.assign({}, concepts), { [newConceptId]: concept })));
    set(a_addConceptIdToNode, nodeId, newConceptId);
});
const a_removeConceptIdFromNode = atom(null, (get, set, nodeId, conceptId) => {
    set(a_conceptNodes, conceptNodes => {
        if (!conceptNodes[nodeId])
            return conceptNodes;
        return Object.assign(Object.assign({}, conceptNodes), { [nodeId]: conceptNodes[nodeId].filter(id => id !== conceptId) });
    });
});
export const a_removeConceptIdFromNodes = atom(null, (get, set, nodeIds, conceptId) => {
    set(a_debugLog, "removing concept from nodes", nodeIds);
    nodeIds.forEach(nodeId => {
        set(a_removeConceptIdFromNode, nodeId, conceptId);
    });
});
const a_resetConceptNode = atom(null, (get, set, nodeId) => {
    set(a_debugLog, "resetting concept node", nodeId);
    set(a_conceptNodes, conceptNodes => {
        const newConceptNodes = Object.assign({}, conceptNodes);
        delete newConceptNodes[nodeId];
        return newConceptNodes;
    });
});
export const a_resetConceptNodes = atom(null, (get, set, nodeIds) => {
    set(a_debugLog, "resetting concept nodes", nodeIds);
    nodeIds.forEach(nodeId => {
        set(a_resetConceptNode, nodeId);
    });
});
export const a_addContextNodeId = atom(null, (get, set, nodeId) => {
    set(a_debugLog, "adding context node", nodeId);
    set(a_activeConstraint, activeConstraint => (Object.assign(Object.assign({}, activeConstraint), { contextNodeIds: uniq([...activeConstraint.contextNodeIds, nodeId]) })));
});
export const a_clearContextNodes = atom(null, (get, set) => {
    set(a_debugLog, "clearing context nodes");
    set(a_activeConstraint, activeConstraint => (Object.assign(Object.assign({}, activeConstraint), { contextNodeIds: [] })));
});
export const a_resetAllConstraintConcepts = atom(null, (get, set) => {
    set(a_debugLog, "resetting all constraint concepts");
    const nodeIds = get(a_currentConstraintNodeIds);
    set(a_resetConceptNodes, nodeIds);
    set(a_clearContextNodes);
});
export const a_swapConceptsInConstraintSlots = atom(null, (get, set, nodeId1, nodeId2) => {
    set(a_debugLog, "swapping concepts in constraint slots");
    const nodeState1 = get(a_conceptNodes)[nodeId1];
    const nodeState2 = get(a_conceptNodes)[nodeId2];
    // swap the concept ids for the node ids
    set(a_conceptNodes, conceptNodes => (Object.assign(Object.assign({}, conceptNodes), { [nodeId1]: [...nodeState2], [nodeId2]: [...nodeState1] })));
});
////////////////////////////
// Extended concept methods
////////////////////////////
export const a_addConceptToExtended = atom(null, (get, set, nodeId, concept, conceptId) => {
    set(a_debugLog, "adding concept to extended", nodeId);
    const newConceptId = conceptId || createClusterId(concept);
    set(a_extendedNodeIds, nodeIds => uniq([...nodeIds, nodeId]));
    set(a_extendedConceptNodes, conceptNodes => (Object.assign(Object.assign({}, conceptNodes), { [nodeId]: [newConceptId] })));
    set(a_concepts, concepts => (Object.assign(Object.assign({}, concepts), { [newConceptId]: concept })));
});
export const a_removeConceptFromExtended = atom(null, (get, set, nodeId, conceptId) => {
    set(a_debugLog, "removing concept from extended", nodeId);
    if (!nodeId)
        return;
    //for now, we are assuming that every extended node only has one concept
    //remove the node
    set(a_extendedNodeIds, nodeIds => nodeIds.filter(id => id !== nodeId));
    set(a_extendedConceptNodes, conceptNodes => {
        const newConceptNodes = Object.assign({}, conceptNodes);
        delete newConceptNodes[nodeId];
        return newConceptNodes;
    });
    //TODO: remove the concept from the config concepts if it's not used elsewhere
});
export const a_currentExtendedConceptTree = atom(get => {
    const nodeIds = get(a_extendedNodeIds);
    const conceptNodes = get(a_extendedConceptNodes);
    const concepts = get(a_concepts);
    // { [nodeId]: { [conceptId]: ConceptOrCustom } }
    const conceptTree = mapValues(pick(conceptNodes, nodeIds), conceptIds => Object.fromEntries(conceptIds
        .map(cId => [cId, concepts[cId]])
        .filter(([_, concept]) => concept !== undefined)));
    return conceptTree;
});
////////////////////////////
// Required concept methods
////////////////////////////
export const a_addRequiredConceptByNodeId = atom(null, (get, set, nodeId, conceptId) => {
    set(a_debugLog, "adding required concept for node", nodeId);
    set(a_requiredConcepts, requiredConcepts => TwoKey.set(requiredConcepts, nodeId, conceptId, true));
});
export const a_removeRequiredConceptForNodeId = atom(null, (get, set, nodeId, conceptId) => {
    set(a_debugLog, "removing required concept for node", nodeId);
    set(a_requiredConcepts, requiredConcepts => TwoKey.delete(requiredConcepts, nodeId, conceptId));
});
const a_resetRequiredConceptForNodeId = atom(null, (get, set, nodeId) => {
    set(a_debugLog, "resetting required concepts for node", nodeId);
    set(a_requiredConcepts, requiredConcepts => TwoKey.deletePrime(requiredConcepts, nodeId));
});
////////////////////////////
// Override concept methods
////////////////////////////
export const a_addOverrideConceptForNodeId = atom(null, (get, set, nodeId, conceptId, overrideConcept) => {
    set(a_debugLog, "adding override concept for node", nodeId, conceptId, overrideConcept.name);
    const overrideConceptId = createClusterId(overrideConcept);
    // add the override concept id to the nodeId => conceptId[] map
    set(a_overrides, overrides => TwoKey.set(overrides, nodeId, conceptId, [
        ...(TwoKey.get(overrides, nodeId, conceptId) || []),
        overrideConceptId,
    ]));
    // add the override concept to the conceptId => concept map
    set(a_concepts, concepts => (Object.assign(Object.assign({}, concepts), { [overrideConceptId]: overrideConcept })));
});
export const a_resetOverridesForConceptId = atom(null, (get, set, nodeId, conceptId) => {
    set(a_debugLog, "resetting override for node concept", nodeId, conceptId);
    set(a_overrides, overrides => TwoKey.delete(overrides, nodeId, conceptId));
    //TODO: remove the concept from the config concepts if it's not used elsewhere
});
export const a_resetOverridesForNodeId = atom(null, (get, set, nodeId) => {
    set(a_debugLog, "resetting override concepts for node", nodeId);
    set(a_overrides, overrides => TwoKey.deletePrime(overrides, nodeId));
    //TODO: remove the concept from the config concepts if it's not used elsewhere
});
export const a_resetModelConfig = atom(null, (get, set) => {
    set(a_debugLog, "resetting model config");
    set(a_constraintModelConfig, EMPTY_CONSTRAINT_MODEL_CONFIG);
});
const a_resetConceptFiltersForNodeIds = atom(null, (get, set, nodeIds) => {
    // clear all overrides associated with the node ids
    nodeIds.forEach(nodeId => {
        set(a_resetOverridesForNodeId, nodeId);
    });
    // clear all required concepts associated with the node ids
    nodeIds.forEach(nodeId => {
        set(a_resetRequiredConceptForNodeId, nodeId);
    });
});
export const a_resetAllConstraintConceptFilters = atom(null, (get, set) => {
    set(a_debugLog, "resetting all constraint concept filters");
    // get the node ids for the slots
    const nodeIds = get(a_currentConstraintNodeIds);
    set(a_resetConceptFiltersForNodeIds, nodeIds);
    set(a_clearContextNodes);
});
////////////////////////////
// Solution concept methods
////////////////////////////
export const a_addSolutionConceptForNodeId = atom(null, (get, set, nodeId, conceptId, solutionConcept) => {
    set(a_debugLog, "adding solution concept for node", nodeId);
    const solutionConceptId = createClusterId(solutionConcept);
    // add the override concept id to the nodeId => conceptId[] map
    set(a_solutions, solutions => TwoKey.set(solutions, nodeId, conceptId, [
        ...(TwoKey.get(solutions, nodeId, conceptId) || []),
        solutionConceptId,
    ]));
    // add the override concept to the conceptId => concept map
    set(a_concepts, concepts => (Object.assign(Object.assign({}, concepts), { [solutionConceptId]: solutionConcept })));
});
export const a_resetAllSolutions = atom(null, (get, set) => {
    set(a_debugLog, "resetting all solutions");
    set(a_constraintModelSolution, {
        solutions: {},
        answer: undefined,
        references: undefined,
        evidence: undefined,
    });
});
////////////////////////////
// Extended override methods
////////////////////////////
export const a_resetAllExtendedConceptFilters = atom(null, (get, set) => {
    set(a_debugLog, "resetting all extended concept filters");
    // get the node ids for the slot
    const nodeIds = get(a_extendedNodeIds);
    set(a_resetConceptFiltersForNodeIds, nodeIds);
    set(a_extendedNodeIds, []);
});
