import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { renderToString } from "react-dom/server";
export function findingsToHtml(findings) {
    const comp = (_jsx("ol", { children: findings.map((f, idx) => (_jsxs("li", { children: [_jsx("h1", { children: _jsx("b", { children: f.title }) }), _jsx("p", { children: f.payload.selectedText }), _jsx("br", {}), _jsx("h2", { children: _jsxs("b", { children: ["Evidence (", f.payload.evidence.length, ")"] }) }), _jsx("ul", { children: f.payload.evidence.map((e, idx) => {
                        const i = e.instances[0];
                        return (_jsxs("li", { children: [_jsx("h3", { children: _jsx("b", { children: i.title }) }), _jsx("p", { children: e.text }), i.metadata && (_jsx("table", { children: i.metadata.map(m => (_jsxs("tr", { children: [_jsx("td", { children: _jsxs("b", { children: [m.name, ":"] }) }), _jsx("td", { children: m.value })] }, m.name))) }))] }, idx));
                    }) })] }, idx))) }));
    return renderToString(comp);
}
