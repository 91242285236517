import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "allotment/dist/style.css";
import { Button, Result, message } from "antd";
import React from "react";
import "./App.less";
import { setErrorHandler } from "./cora/api/utils/http";
import LDSync from "./cora/appState/atomic/featureFlags/LDSync";
import { useInitGlobalInfo } from "./cora/appState/atomic/globalInfo";
import { useInitModels } from "./cora/appState/atomic/models";
import { SessionsInitializer } from "./cora/appState/atomic/session/SessionsInitializer";
import { useLoadSessionsDirectory } from "./cora/appState/atomic/session/useSessionsDirectory";
import { addErrorHandler, pushError, useErrorType, } from "./cora/appState/beacon/errorStream";
import { Loading } from "./cora/components/common/transitions/Loading";
import { CoraWrapper } from "./cora/components/layout/Cora";
import { reloadToHomePage } from "./cora/utils/routing/utils/reloadToHomePage";
export const queryClient = new QueryClient();
// set window name so other apps who pass url parameters always reach the same
// instance.
window.name = "CORA";
function App() {
    useErrorType(true);
    const fatal = useErrorHandler();
    const initializers = [
        useInitGlobalInfo(),
        useInitModels(),
        useLoadSessionsDirectory(),
    ];
    const ready = initializers.every(i => i === true);
    const error = initializers.find(i => typeof i === "string") || null;
    if (error !== null || fatal) {
        return (_jsx(Result, { status: "error", title: "Sorry, there was an error loading the Application.", extra: _jsx(Button, Object.assign({ type: "primary", onClick: reloadToHomePage }, { children: "Reload" }), "console") }));
    }
    else if (!ready) {
        return _jsx(Loading, { loading: true });
    }
    else {
        return (_jsxs(QueryClientProvider, Object.assign({ client: queryClient }, { children: [_jsx(LDSync, {}), _jsx(SessionsInitializer, { afterInitializing: queryState => (_jsx(CoraWrapper, { queryState: queryState })) })] })));
    }
}
setErrorHandler(e => {
    console.error("NETWORK ERROR", e.message, e);
    pushError({ error: e });
});
function useErrorHandler() {
    const [isFatal, setIsFatal] = React.useState(false);
    const lastError = React.useRef();
    React.useEffect(() => {
        return addErrorHandler(error => {
            var _a, _b, _c;
            if (!error)
                return;
            console.error(error);
            if (!error.isFatal) {
                if (error.error.status === 504) {
                    if (((_a = lastError.current) === null || _a === void 0 ? void 0 : _a.error.status) !== error.error.status) {
                        message.warning("Server timed out. Try again or add filters.\nYou might be asking for too many results.", 4);
                    }
                }
                else if (error.error.message !== undefined &&
                    error.error.status !== 400) {
                    const time = ((_b = lastError.current) === null || _b === void 0 ? void 0 : _b.timestamp) || 0;
                    if (error.timestamp - time > 5000) {
                        message.warning(error.error.message, 4);
                    }
                }
                else {
                    const time = ((_c = lastError.current) === null || _c === void 0 ? void 0 : _c.timestamp) || 0;
                    if (error.timestamp - time > 5000) {
                        message.error(error.showMessage
                            ? error.error
                            : "Error fetching data. Try reloading the page", 4);
                    }
                }
            }
            else {
                setIsFatal(true);
            }
            lastError.current = error;
        });
    }, []);
    return isFatal;
}
export default App;
