var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { groupBy } from "lodash";
import React from "react";
import { Handle } from "reactflow";
import { API } from "../../../api/api";
import { useHasCapability } from "../../../api/useHasCapability";
import { CorpusCapability } from "../../../api/utils/capabilities";
import { useFeatureFlags } from "../../../appState/atomic/featureFlags/useFeatureFlags";
import { queryStateAtoms } from "../../../appState/atomic/queryState/queryStateAtoms";
import { useArgsInfo } from "../../../appState/atomic/queryState/useArgsInfo";
import { getSuggestedQuestionCaption, getSuggestedQuestionConceptCaption, } from "../../SuggestedQuestions";
import { showPopupMenu } from "../../contextMenu/showPopupMenu";
import { corpusFilterToDiagram } from "../adapters/corpusFilterAdapters";
import { emptyNode, getNodeValueOrOverrides } from "../diagramUtils";
import { CONTEXT, ElementType, isConcept, isRelation, upsertEdge } from "../model";
export const NodeExtender = React.memo(function NodeExtender({ node, isSource, stage, targetPosition, }) {
    var _a;
    const handlerRef = React.useRef(null);
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const { enableSuggestedQuestions } = useFeatureFlags();
    const hasSuggestedQuestions = useHasCapability(CorpusCapability.SUGGEST_QUERY_NEXT_CONCEPTS);
    const argNames = ((_a = useArgsInfo()) === null || _a === void 0 ? void 0 : _a.allArgumentNames) || [];
    const onClick = React.useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const { top, left } = handlerRef.current.getBoundingClientRect();
        const { header, entries } = isRelation(node)
            ? yield getQualifierEntries(node.id, argNames, stage)
            : yield getSuggestedQuestionEntries(node, corpus_ids, stage, !enableSuggestedQuestions);
        entries.length &&
            showPopupMenu({
                top,
                left,
                header,
                entries,
            });
    }), [node, corpus_ids, stage, argNames]);
    return (_jsx(Handle, { ref: handlerRef, type: "source", style: {
            visibility: isSource ? "visible" : undefined,
        }, position: targetPosition, className: "sourceHandle flex justify-center items-center", onClick: (isConcept(node) && hasSuggestedQuestions) || isRelation(node)
            ? onClick
            : undefined }));
});
function getSuggestedQuestionEntries(node, corpus_ids, stage, disabled = false) {
    return __awaiter(this, void 0, void 0, function* () {
        const concept = getNodeValueOrOverrides(node)[0];
        const questionsByConcept = concept && !disabled
            ? Object.entries(groupBy(yield API.suggestQuestionsForConcepts({
                corpus_ids,
                history: [],
                concept_clusters: [concept],
            }), "trigger_concept"))
            : [];
        return {
            header: "Suggested Questions",
            entries: questionsByConcept.length === 1
                ? entriesForConcept(questionsByConcept[0][1])
                : questionsByConcept.map(([name, questions]) => ({
                    caption: getSuggestedQuestionConceptCaption(name),
                    subEntries: entriesForConcept(questions),
                })),
        };
        function entriesForConcept(questions) {
            return questions.map(q => ({
                caption: getSuggestedQuestionCaption(q),
                action() {
                    const { nodes, edges } = corpusFilterToDiagram(q.corpus_filter);
                    const source = nodes.find(n => getNodeValueOrOverrides(n)[0].name ===
                        getNodeValueOrOverrides(node)[0].name);
                    if (source) {
                        stage({
                            nodes: nodes.filter(n => n.id !== source.id),
                            edges: edges.map(e => e.source === source.id
                                ? Object.assign(Object.assign({}, e), { source: node.id }) : e.target === source.id
                                ? Object.assign(Object.assign({}, e), { target: node.id }) : e),
                        }, {
                            layout: "H",
                            newKeys: true,
                        });
                    }
                },
            }));
        }
    });
}
function getQualifierEntries(id, argNames, stage) {
    return __awaiter(this, void 0, void 0, function* () {
        return {
            header: "Qualifiers",
            entries: [CONTEXT, ...argNames].map(argName => ({
                caption: argName,
                action() {
                    return __awaiter(this, void 0, void 0, function* () {
                        const nodes = [
                            emptyNode(argName === "Context" ? ElementType.CONCEPT : ElementType.QUALIFIER, {
                                argName,
                                content: [],
                            }),
                        ];
                        stage({
                            nodes,
                            edges: [
                                upsertEdge({
                                    type: ElementType.QUALIFIER,
                                    argName,
                                    source: id,
                                    target: nodes[0].id,
                                }),
                            ],
                        });
                    });
                },
            })),
        };
    });
}
