import React from "react";
// The hatch is initialized with the value passed
// when the hook is first invoked.
// If the hatch is open, the hook returns the
// latest value passed to it.
// If the hatch is closed, the hook returns the
// last value passed to it when the hatch was open.
export function useHatch(value) {
    const [open, setOpen] = React.useState(true);
    const currentRef = React.useRef(value);
    React.useEffect(() => {
        if (open) {
            currentRef.current = value;
        }
    }, [value, open]);
    return [open ? value : currentRef.current, setOpen];
}
