import { useAtom } from "jotai";
import { isEqual } from "lodash";
import React from "react";
import { MetadataType } from "../../api/types/metadataTypes";
import { a_rangeMetadata } from "../../appState/atomic/queryState/constraintModel/modelState";
import { upsertOrRemove } from "../../utils/generic/collections";
import { useTrendDomain } from "./useTrendDomain";
export function useSelectedTrendRangeUpdater() {
    const [rangeMetadata, setRangeMetadata] = useAtom(a_rangeMetadata);
    const selectedTrendDomain = useTrendDomain();
    return React.useCallback((range) => {
        if (!selectedTrendDomain)
            return;
        const update = upsertOrRemove(rangeMetadata, i => i.id, selectedTrendDomain === null || selectedTrendDomain === void 0 ? void 0 : selectedTrendDomain.id, range && {
            id: selectedTrendDomain.id,
            name: selectedTrendDomain === null || selectedTrendDomain === void 0 ? void 0 : selectedTrendDomain.name,
            type: MetadataType.DATE,
            value: {
                start: range.start,
                end: range.end,
            },
        });
        const hasUpdate = !isEqual(update, rangeMetadata);
        hasUpdate && setRangeMetadata(update);
    }, [selectedTrendDomain, setRangeMetadata, setRangeMetadata]);
}
