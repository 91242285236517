import { jsx as _jsx } from "react/jsx-runtime";
import { useQueryState } from "../../appState/atomic/queryState/useQueryState";
import { ToggleButton } from "../common/buttons/ToggleButton";
import { Document } from "../common/icons/Icons";
import { Tooltip } from "../common/popups/Tooltip";
export function PrintState() {
    const [queryState] = useQueryState();
    return (_jsx(Tooltip, Object.assign({ content: "Print State" }, { children: _jsx(ToggleButton, Object.assign({ onClick: () => {
                console.log(queryState);
            } }, { children: _jsx(Document, {}) })) })));
}
