var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from "antd";
import Lazy from "lazy.js";
import moment from "moment";
import React from "react";
import DP from "react-datepicker";
import { DivButton } from "../../common/buttons/DivButton";
import { Left, Right } from "../../common/icons/Icons";
import "./DatePicker.less";
const CALENDAR_DATE_FORMAT = "MMM d yyyy";
const DATE_FORMAT = "MMM D YYYY";
const SERVER_DATE_FORMAT = "YYYY-MM-DD";
var Placeholder;
(function (Placeholder) {
    Placeholder["START"] = "From\u2026";
    Placeholder["END"] = "To\u2026";
})(Placeholder || (Placeholder = {}));
export function DatePicker(_a) {
    var _b, _c, _d, _e;
    var { selectedRange, field, onSelectionChange, range } = _a, props = __rest(_a, ["selectedRange", "field", "onSelectionChange", "range"]);
    const isStart = field === "start";
    const minMax = rangeToDates(range);
    const startYear = (_c = (_b = minMax.start) === null || _b === void 0 ? void 0 : _b.getFullYear()) !== null && _c !== void 0 ? _c : 2000;
    const endYear = (_e = (_d = minMax.end) === null || _d === void 0 ? void 0 : _d.getFullYear()) !== null && _e !== void 0 ? _e : new Date().getFullYear();
    const dateSelection = rangeToDates(selectedRange);
    const CustomInput = React.forwardRef((_a, ref) => {
        var { value, onChange } = _a, props = __rest(_a, ["value", "onChange"]);
        const [text, setText] = React.useState(value);
        React.useEffect(() => {
            setText(value);
        }, [value]);
        return (_jsx(Input, Object.assign({ value: text, onChange: e => {
                const text = e.target.value;
                setText(text);
                if (!text) {
                    onChange(e);
                    return;
                }
                let date = parseDate(text);
                if (date) {
                    const isStart = props.placeholder === Placeholder.START;
                    if (isStart && minMax.start && date < minMax.start) {
                        date = minMax.start;
                    }
                    else if (!isStart && minMax.end && date > minMax.end) {
                        date = minMax.end;
                    }
                    e.target.value = moment(date).format(DATE_FORMAT);
                    onChange(e);
                }
            } }, props, { ref: ref })));
    });
    return (_jsx(DP, Object.assign({}, props, { customInput: _jsx(CustomInput, {}), selectsStart: isStart, selectsEnd: !isStart, isClearable: !!dateSelection[field], calendarClassName: "shadow-xl rounded-xl", dateFormat: CALENDAR_DATE_FORMAT, showFullMonthYearPicker: true, placeholderText: isStart ? Placeholder.START : Placeholder.END, disabled: !range, selected: dateSelection[field], openToDate: (!dateSelection[field] && minMax[field]) || undefined, onChange: date => {
            onSelectionChange(toDateRange(isStart ? date : dateSelection.start, !isStart ? date : dateSelection.end, isStart));
        }, startDate: dateSelection.start, endDate: dateSelection.end, minDate: minMax.start, maxDate: minMax.end, renderCustomHeader: ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (_jsxs("div", Object.assign({ className: "px-4 flex space-x-2 justify-between items-center text-base" }, { children: [_jsx(DivButton, Object.assign({ disabled: prevMonthButtonDisabled, onMouseDown: e => {
                        e.preventDefault();
                        decreaseMonth();
                    } }, { children: _jsx(Left, {}) })), _jsx("select", Object.assign({ className: "font-bold text-base", style: { outline: "none" }, value: SHORT_MONTHS[date.getMonth()], onChange: ({ target: { value } }) => changeMonth(SHORT_MONTHS.indexOf(value)) }, { children: SHORT_MONTHS.map(option => (_jsx("option", Object.assign({ value: option }, { children: option }), option))) })), _jsx("select", Object.assign({ className: "font-bold text-base", style: { outline: "none" }, value: date.getFullYear(), onChange: ({ target: { value } }) => changeYear(Number(value)) }, { children: Lazy.range(startYear, endYear + 1)
                        .map(option => (_jsx("option", Object.assign({ value: option }, { children: option }), option)))
                        .toArray() })), _jsx(DivButton, Object.assign({ disabled: nextMonthButtonDisabled, onMouseDown: e => {
                        e.preventDefault();
                        increaseMonth();
                    } }, { children: _jsx(Right, {}) }))] }))) }, { children: range && (_jsxs("div", Object.assign({ className: "font-tiny ml-2 italic text-gray-500" }, { children: ["Range: ", rangeToString(range)] }))) })));
}
function rangeToDates(range) {
    return {
        start: (range === null || range === void 0 ? void 0 : range.start) ? moment(range.start).toDate() : null,
        end: (range === null || range === void 0 ? void 0 : range.end) ? moment(range.end).toDate() : null,
    };
}
function toDateRange(start, end, updatingStart) {
    if (!start && !end)
        return null;
    if (start && end && start > end) {
        if (updatingStart) {
            end = start;
        }
        else {
            start = end;
        }
    }
    return Object.assign(Object.assign({}, (end && {
        end: moment(end).format(SERVER_DATE_FORMAT),
    })), (start && {
        start: moment(start).format(SERVER_DATE_FORMAT),
    }));
}
export function rangeToString(range) {
    return `${range.start ? moment(range.start).format(DATE_FORMAT) : " "} → ${range.end ? moment(range.end).format(DATE_FORMAT) : " "}`;
}
const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const SHORT_MONTHS = MONTHS.map(m => m.slice(0, 3));
const ALL_MONTH_NAMES = new Set([...MONTHS, ...SHORT_MONTHS].map(m => m.toLowerCase()));
function parseDate(txt) {
    const parts = txt
        .toLowerCase()
        .split(/[-/ ,]/)
        .filter(p => p.trim());
    if (parts.length !== 3)
        return null;
    const month = parseMonth(parts[0]);
    const day = parseNumber(1, 31, parts[1]);
    const year = parseNumber(1900, moment().year(), parts[2]);
    if (month === null || day === null || year === null)
        return null;
    const mom = moment({ year, month: month - 1, day });
    return mom.isValid() ? mom.toDate() : null;
    function parseMonth(txt) {
        const idx = !ALL_MONTH_NAMES.has(txt)
            ? -1
            : MONTHS.findIndex(m => m.toLowerCase().startsWith(txt));
        return idx >= 0 ? idx + 1 : parseNumber(1, 12, txt);
    }
    function parseNumber(min, max, txt) {
        const num = Number(txt);
        return num >= min && num <= max ? num : null;
    }
}
