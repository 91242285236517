import { atom } from "jotai";
import { RESET } from "jotai/utils";
import { queryStateAtoms } from "../queryStateAtoms";
import { a_resetAllConstraintConceptFilters, a_resetAllConstraintConcepts, a_resetAllExtendedConceptFilters, } from "./concepts";
import { a_constraintText, a_userText } from "./constraints";
import { a_booleanMetadata, a_keywordMetadata, a_rangeMetadata, } from "./modelState";
import { a_resetAllClauses } from "./qualifiers";
import { a_resetRelations } from "./relations";
const a_resetMetadataFilters = atom(null, (get, set) => {
    set(a_keywordMetadata, []);
    set(a_booleanMetadata, []);
    set(a_rangeMetadata, []);
});
export const a_resetConceptFilters = atom(null, (get, set) => {
    set(a_resetAllConstraintConceptFilters);
    set(a_resetAllClauses);
    set(a_resetAllExtendedConceptFilters);
});
export const a_resetAllFilters = atom(null, (get, set) => {
    set(a_resetConceptFilters);
    set(a_resetMetadataFilters);
});
export const a_resetQuery = atom(null, (get, set) => {
    set(a_resetConceptFilters);
    set(a_resetAllConstraintConcepts);
    set(a_resetRelations);
    set(a_constraintText, "");
    set(a_userText, "");
    set(queryStateAtoms.searchParams, RESET);
});
