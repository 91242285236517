import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WebStorageStateStore } from "oidc-client-ts";
import React from "react";
import { AuthProvider } from "react-oidc-context";
import FullPageError from "../../../../components/common/errorHandling/FullPageError";
import { getConfig } from "../../../config/serverConfig";
import { getReturnUri } from "../utils/authInfo";
import { OIDCStateUpdater } from "./OIDCStateUpdater";
/**
 * Primarily a wrapper around OIDC AuthProvider, but also intercepts error messages
 * that we may get in return from OIDC (e.g., an expired org invite)
 */
export function OIDCCoraProvider({ children }) {
    React.useEffect(() => {
        console.log("Using OIDC Provider");
    }, []);
    // look for params (and our custom redirect) for special handling
    const params = React.useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        return {
            error: params.get("error"),
            error_description: params.get("error_description"),
            redirect: params.get("redirect"),
        };
    }, [window.location.search]);
    if (params.error && params.error_description) {
        return _jsx(FullPageError, { message: params.error_description });
    }
    if (params.redirect) {
        window.location.href = params.redirect;
        return null;
    }
    return (_jsxs(AuthProvider, Object.assign({ authority: `${getConfig("OIDC_URL")}realms/${getConfig("OIDC_REALM")}`, client_id: getConfig("OIDC_CLIENT_ID"), client_secret: getConfig("OIDC_CLIENT_SECRET", "") || undefined, redirect_uri: window.location.origin, post_logout_redirect_uri: getReturnUri(""), scope: "openid profile email offline_access", userStore: new WebStorageStateStore({ store: window.localStorage }), onSigninCallback: () => {
            const params = new URLSearchParams(window.location.search);
            if (params.get("session_state") ||
                params.get("state") ||
                params.get("iss")) {
                window.location.href = window.location.pathname;
            }
        } }, { children: [_jsx(OIDCStateUpdater, {}), children] })));
}
