export var SimilarityMode;
(function (SimilarityMode) {
    SimilarityMode["MINIMAL"] = "MINIMAL";
    SimilarityMode["MODERATE"] = "MODERATE";
    SimilarityMode["AGGRESSIVE"] = "AGGRESSIVE";
})(SimilarityMode || (SimilarityMode = {}));
export var FeedbackType;
(function (FeedbackType) {
    FeedbackType["POSITIVE"] = "POSITIVE";
    FeedbackType["IMPLICIT_POSITIVE"] = "IMPLICIT POSITIVE";
    FeedbackType["NEGATIVE"] = "NEGATIVE";
    FeedbackType["NEGATIVE_IN_CONTEXT"] = "NEGATIVE IN CONTEXT";
})(FeedbackType || (FeedbackType = {}));
export var FeatureIdentifier;
(function (FeatureIdentifier) {
    FeatureIdentifier["EVIDENCE_QUALIFIER"] = "EVIDENCE QUALIFIER";
    FeatureIdentifier["EVIDENCE_RELEVANCE"] = "EVIDENCE RELEVANCE";
    FeatureIdentifier["ASPECTS"] = "ASPECTS";
    FeatureIdentifier["SUGGEST_CONCEPTS"] = "SUGGEST CONCEPTS";
    FeatureIdentifier["SUGGEST_RELATIONS"] = "SUGGEST RELATIONS";
    FeatureIdentifier["SUGGEST_EVIDENCE_SUMMARY"] = "SUGGEST EVIDENCE SUMMARY";
    FeatureIdentifier["OVERALL"] = "OVERALL";
})(FeatureIdentifier || (FeatureIdentifier = {}));
export var ClusteringMode;
(function (ClusteringMode) {
    ClusteringMode["NONE"] = "NONE";
    ClusteringMode["MINIMAL"] = "MINIMAL";
    ClusteringMode["MODERATE"] = "MODERATE";
    ClusteringMode["AGGRESSIVE"] = "AGGRESSIVE";
})(ClusteringMode || (ClusteringMode = {}));
