import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ShortcutForTooltip } from "./ShortcutForTooltip";
export function getActionTooltip({ disabled, shortcut, desc }) {
    const disabledMsg = disabled && disabled !== true ? disabled : null;
    if (disabledMsg)
        return disabledMsg;
    const shortComp = shortcut ? _jsx(ShortcutForTooltip, { shortcut: shortcut }) : null;
    return !desc ? (shortComp) : (_jsxs("span", { children: [desc, " ", shortComp] }));
}
