import { jsx as _jsx } from "react/jsx-runtime";
import { Radio } from "antd";
import React from "react";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { textComparator } from "../../utils/generic/comparators";
import { DropDownMenu } from "../common/popups/DropDownMenu";
export function SourcesDropDown({ selectedSourceId, onSelect, children, caption, }) {
    const corpora = Object.values(useGlobalInfo().corporaById);
    return (_jsx(DropDownMenu, Object.assign({ menuTestId: "corpusList", title: "Source", hasClose: true, entries: () => {
            return corpora
                .sort((a, b) => textComparator(a.name, b.name))
                .map(c => {
                var _a;
                return ({
                    caption: (_a = caption === null || caption === void 0 ? void 0 : caption(c)) !== null && _a !== void 0 ? _a : c.name,
                    icon: () => _jsx(Radio, { checked: selectedSourceId === c.id }),
                    action() {
                        if (selectedSourceId !== c.id) {
                            onSelect(c);
                        }
                    },
                });
            });
        } }, { children: children })));
}
