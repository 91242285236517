import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { TABS_BG } from "../../appState/context/theme/lf";
import { useShowVideo } from "../common/assist/VideoPopup";
import tourLandmarks from "../common/assist/tourLandmarks.json";
import { useStartTour } from "../common/assist/useStartTour";
import { Down } from "../common/icons/Icons";
import { TabButton } from "../common/layout/TabButton";
import { DropDownMenu } from "../common/popups/DropDownMenu";
export function HelpMenu() {
    const { onboardingTour } = useFeatureFlags();
    const showVideo = useShowVideo();
    const startTour = useStartTour();
    return onboardingTour ? (_jsx(DropDownMenu, Object.assign({ title: "Tour", hasClose: true, entries: [
            {
                caption: "Get Started Tour",
                action() {
                    startTour(tourLandmarks.landmarks);
                },
            },
            {
                caption: "Learn by Watching",
                action() {
                    showVideo("https://vimeo.com/video/787685506?h=e2bdd73c3a");
                },
            },
            // {
            //   caption: "Give Feedback",
            //   disabled: true,
            // },
        ] }, { children: _jsxs(TabButton, Object.assign({ className: TABS_BG }, { children: [_jsx("div", Object.assign({ className: "" }, { children: "Tour" })), _jsx(Down, { className: "text-xs" })] })) }))) : null;
}
