import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { camelify } from "../utils/utils";
export function RenderNiceMetadata({ evidence, className, }) {
    const { getAssumedEvidenceMetadata } = useEvidenceSearchUtils();
    const metadata = getAssumedEvidenceMetadata(evidence.id);
    const hasAuthors = metadata.authors && metadata.authors.length > 0;
    const hasSource = metadata.publicationSource;
    return (_jsxs("span", Object.assign({ className: className }, { children: [hasAuthors ? (_jsx("span", { children: `${camelify(metadata.authors[0])}${metadata.authors.length > 1 ? `, et. al.` : ""}` })) : undefined, metadata.publicationSource && (_jsxs("span", { children: [" ", "in ", _jsx("i", { children: metadata.publicationSource })] })), _jsxs("span", { children: [hasAuthors || hasSource ? ", " : "", metadata.publicationDate.split("-")[0]] })] })));
}
