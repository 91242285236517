import { MetadataType } from "../../../../api/types/metadataTypes";
export function metadataValue({ value, type }, onlyFirst = true) {
    if (Array.isArray(value)) {
        return !value.length
            ? ""
            : onlyFirst
                ? value[0] + (value.length > 1 ? ` (+${value.length - 1})` : "")
                : value.join(", ");
    }
    if (type === MetadataType.BOOLEAN &&
        (value === "True" || value === "False"))
        return value === "True";
    return value;
}
