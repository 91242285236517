export function scrollToElement(elem, delay = 0) {
    setTimeout(() => {
        var _a;
        (_a = elem === null || elem === void 0 ? void 0 : elem()) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "center",
        });
    }, delay);
}
