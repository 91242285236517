var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSetAtom } from "jotai";
import { mapValues } from "lodash";
import React from "react";
import { error } from "../../../utils/dialog/error";
import { useEffectIfDifferent } from "../../../utils/lifecycle/useEffectIfDifferent";
import { useQueryParams } from "../../../utils/routing/useQueryParams";
import { pushError } from "../../beacon/errorStream";
import { loadQueryState } from "../../stateRestoration/loadQueryState";
import { QueryId } from "../../stateRestoration/queryId";
import { EMPTY_QUERY_STATE } from "../../verions/V3_QueryState";
import { migrateSession } from "../../versionMigration/sessionMigration";
import { useGlobalInfo } from "../globalInfo";
import { ConstraintModelUtil } from "../queryState/constraintModel/ConstraintModelUtil";
import { getEmptyQueryStateForCorpus } from "../queryState/utils/getEmptyQueryStateForCorpus";
import { a_queryInputText } from "../useQueryInputText";
import { UNNAMED_SESSION_ID } from "./parts/sessionMetadata";
import { useCurrentSession, useDefaultSession } from "./session";
import { useSessionControls } from "./useSessionControls";
import { useSessionsDirectory } from "./useSessionsDirectory";
import { getCurrentSessionFromLocalStorage } from "./utils/getCurrentSessionFromLocalStorage";
import { getLatestCorpusIds } from "./utils/getLatestCorpusIds";
import { getLatestQueryId } from "./utils/getLatestQueryId";
import { getRemoteSession } from "./utils/getRemoteSession";
import { isUnnamedSession } from "./utils/isUnnamedSession";
export function SessionsInitializer({ afterInitializing, }) {
    const initalized = React.useRef(false);
    const [queryState, setQueryState] = React.useState(null);
    const [params, setQueryParams] = useQueryParams();
    const { sessionId: sessionIdInUrl = UNNAMED_SESSION_ID } = params, urlParams = __rest(params, ["sessionId"]);
    const { setNewSession } = useSessionControls();
    const directory = useSessionsDirectory(true);
    const [, sessionUpdater] = useCurrentSession();
    const defaultSession = useDefaultSession();
    const corpora = useGlobalInfo().corporaById;
    const setQueryInputText = useSetAtom(a_queryInputText);
    useEffectIfDifferent(() => {
        function initialize() {
            return __awaiter(this, void 0, void 0, function* () {
                if (queryState !== null || initalized.current)
                    return;
                initalized.current = true;
                if (sessionIdInUrl === "!") {
                    yield setNewSession();
                    setQueryParams(mapValues(params, () => undefined));
                    QueryId.set("");
                    setQueryState(EMPTY_QUERY_STATE);
                    return;
                }
                const localSession = getCurrentSessionFromLocalStorage(defaultSession);
                if (!isUnnamedSession(sessionIdInUrl)) {
                    // the url has a session id
                    const session = yield getStoredSession(sessionIdInUrl);
                    if (session) {
                        setSession(session);
                    }
                    else {
                        const localSessionIsNamed = localSession && !isUnnamedSession(localSession.id);
                        yield error({
                            title: (_jsxs("div", { children: ["Unknown Session ID", _jsx("br", {}), "ID (", sessionIdInUrl, ")"] })),
                            content: !localSession ? ("Starting a new Session…") : !localSessionIsNamed ? ("Restoring Local Session…") : (_jsxs("div", { children: ["It seems you were previously working on a session named \"", localSession.name, "\".", _jsx("br", {}), "Restoring it..."] })),
                            type: "error",
                            width: 600,
                        });
                        yield setSession(localSession);
                        return;
                    }
                }
                const session = yield getStoredSession();
                yield setSession(session);
                function setSession(session) {
                    return __awaiter(this, void 0, void 0, function* () {
                        const corpusIds = session ? getLatestCorpusIds(session) : undefined;
                        const queryIdFromUrl = yield QueryId.getQueryIdOrLegacyFromUrl(urlParams, corpusIds, corpora);
                        const latestQueryId = yield getLatestQueryId(session);
                        const queryId = queryIdFromUrl || latestQueryId;
                        QueryId.set(queryId);
                        if (session) {
                            sessionUpdater(session);
                        }
                        else {
                            yield setNewSession();
                        }
                        try {
                            const queryState = !queryId
                                ? getEmptyQueryStateForCorpus(corpusIds)
                                : yield loadQueryState(queryId, true);
                            setQueryState(queryState);
                            const util = new ConstraintModelUtil(queryState.constraintModel);
                            const activeConstraint = util.getActiveConstraint();
                            const userText = activeConstraint === null || activeConstraint === void 0 ? void 0 : activeConstraint.userText;
                            if (userText) {
                                setQueryInputText(userText);
                            }
                        }
                        catch (e) {
                            pushError({
                                error: "Error restoring URL queryId",
                                fatal: false,
                                showMessage: true,
                            });
                            console.error("Error loading query state", e);
                            setQueryState(getEmptyQueryStateForCorpus(corpusIds));
                        }
                    });
                }
                function getStoredSession(id) {
                    return __awaiter(this, void 0, void 0, function* () {
                        if (!id)
                            id = localSession === null || localSession === void 0 ? void 0 : localSession.id;
                        if (!id)
                            return undefined;
                        const entry = directory.find(e => e.id === id);
                        const remoteSession = entry && (yield getRemoteSession(defaultSession, id));
                        return migrateSession((localSession === null || localSession === void 0 ? void 0 : localSession.id) !== id
                            ? remoteSession
                            : !remoteSession
                                ? localSession
                                : localSession.timestamp < remoteSession.timestamp
                                    ? remoteSession
                                    : localSession);
                    });
                }
            });
        }
        initialize();
    }, [
        sessionUpdater,
        setNewSession,
        directory,
        urlParams,
        defaultSession,
        corpora,
    ], [sessionIdInUrl], true);
    return queryState !== null ? afterInitializing(queryState) : null;
}
