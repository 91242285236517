import { mapValues } from "lodash";
import { ConstraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { ANY_MARKER } from "../../appState/atomic/queryState/consts/ANY";
import { itemsOrOverrides } from "../../appState/atomic/queryState/semanticSearch/itemsOrOverrides";
import { CRCDirection } from "../../appState/atomic/queryState/types/CRCDirection";
import { ConceptSource, ConceptType, } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { getSize } from "../../utils/generic/getSize";
import { filterByValue } from "../../utils/generic/objects";
export function queryModelToQueryParts(constraintModel, separateOverrides = []) {
    const modelUtil = new ConstraintModelUtil(constraintModel);
    const constraint = modelUtil.getActiveConstraint();
    if (!constraint) {
        return {};
    }
    const c1 = itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.sourceNodeId,
    ]), separateOverrides.includes(ConceptType.SOURCE)
        ? {}
        : modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
            constraint.sourceNodeId,
        ]))
        .flat()
        .map(c => c.name);
    const c1Overrides = separateOverrides.includes(ConceptType.SOURCE)
        ? filterByValue(mapValues(modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
            constraint.sourceNodeId,
        ]), c => c.map(c => c.name)), v => v.length > 0)
        : undefined;
    const c2 = itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [
        constraint.targetNodeId,
    ]), separateOverrides.includes(ConceptType.TARGET)
        ? {}
        : modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
            constraint.targetNodeId,
        ]))
        .flat()
        .map(c => c.name);
    const c2Overrides = separateOverrides.includes(ConceptType.TARGET)
        ? filterByValue(mapValues(modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
            constraint.targetNodeId,
        ]), c => c.map(c => c.name)), v => v.length > 0)
        : undefined;
    const r = modelUtil.getAllRelations(constraint).map(r => r.name);
    const context = itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds), separateOverrides.includes(ConceptType.CONTEXT)
        ? {}
        : modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds))
        .flat()
        .map(c => c.name);
    const contextOverrides = separateOverrides.includes(ConceptType.CONTEXT)
        ? filterByValue(mapValues(modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds), c => c.map(c => c.name)), v => v.length > 0)
        : undefined;
    const extra = itemsOrOverrides(modelUtil.getConceptListForNodeIds(ConceptSource.EXTENDED, constraintModel.config.extendedNodeIds), separateOverrides.includes(ConceptType.EXTENDED)
        ? {}
        : modelUtil.getOverridesForNodeIds(ConceptSource.EXTENDED, constraintModel.config.extendedNodeIds))
        .flat()
        .map(c => c.name);
    const extraOverrides = separateOverrides.includes(ConceptType.EXTENDED)
        ? filterByValue(mapValues(modelUtil.getOverridesForNodeIds(ConceptSource.EXTENDED, constraintModel.config.extendedNodeIds), c => c.map(c => c.name)), v => v.length > 0)
        : undefined;
    //TODO: avoid casting to arrays to deal with type issues
    const clauses = Object.assign(Object.assign({}, Object.fromEntries(modelUtil.getRequiredQualifierKeys(constraint).map(r => [r, [ANY_MARKER]]))), mapValues(modelUtil.getQualiferMap(constraint), qualifiers => qualifiers.map(q => q.name) || []));
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (getSize(c1) && { c1 })), (getSize(c1Overrides) && { c1Overrides })), (getSize(c2) && { c2 })), (getSize(c2Overrides) && { c2Overrides })), (getSize(r) && { r })), (getSize(context) && { context })), (getSize(contextOverrides) && { contextOverrides })), (getSize(extra) && { extra })), (getSize(extraOverrides) && { extraOverrides })), (constraint.is_directed && {
        dir: CRCDirection.C1C2,
    })), (getSize(clauses) && { argClauses: clauses }));
}
