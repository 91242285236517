import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from "antd";
import { useEvidenceHighlights } from "../../appState/atomic/evidence/useEvidenceHighlights";
import { useEvidenceSelection } from "../../appState/atomic/evidence/useEvidenceSelection";
import { useEvidenceView } from "../../appState/atomic/evidence/useEvidenceView";
import { AnnotatedString } from "../common/text/AnnotatedString";
import { truncateToNearestSpace } from "../evidence/truncateToNearestSpace";
import "./TextWithEvidenceReferences.less";
export function TextWithEvidenceReferences({ text, references, showReferences, showHiddenReferences, getEvidenceWithPosition, }) {
    const referenceAnnotations = references.map(r => ({
        span: r.span,
        annotation: r.evidence_id,
    }));
    const { resetEvidenceHighlight, highlightEvidence } = useEvidenceHighlights();
    const { evidenceSelected, selectEvidence, deselectEvidence } = useEvidenceSelection();
    const { markEvidenceToView } = useEvidenceView();
    return (_jsx(AnnotatedString, { text: text, annotations: referenceAnnotations, renderAnnotation: ({ text, annotations }) => {
            if (annotations.length) {
                const { position, evidence } = getEvidenceWithPosition
                    ? getEvidenceWithPosition(annotations[0])
                    : {
                        position: undefined,
                        evidence: undefined,
                    };
                const superscript = (_jsx("sup", Object.assign({ className: "font-bold cursor-pointer select-none", onMouseEnter: () => {
                        if (position) {
                            resetEvidenceHighlight();
                            highlightEvidence([annotations[0]]);
                        }
                    }, onMouseOut: () => {
                        if (position) {
                            resetEvidenceHighlight();
                        }
                    }, onClick: () => {
                        if (position) {
                            markEvidenceToView([annotations[0]]);
                            evidenceSelected(annotations[0])
                                ? deselectEvidence(annotations)
                                : selectEvidence(annotations);
                        }
                    } }, { children: `[${position ? position : "*"}]` })));
                if (!showReferences ||
                    (!showHiddenReferences && position === undefined))
                    return undefined;
                const evidenceDocTitle = evidence && evidence.instances.length > 0
                    ? evidence.instances[0].title
                    : undefined;
                const evidenceSnippet = evidence && evidence.text
                    ? truncateToNearestSpace(evidence.text, {
                        maxLength: 500,
                        postfix: "...",
                    })
                    : "";
                const tooltip = (_jsxs("div", Object.assign({ className: "flex flex-col items-start space-y-2" }, { children: [evidenceDocTitle && (_jsx("span", Object.assign({ className: "font-bold text-base" }, { children: evidenceDocTitle }))), evidenceSnippet && (_jsx("span", Object.assign({ className: "text-base" }, { children: evidenceSnippet }))), position === undefined && (_jsx("span", Object.assign({ className: "text-sm italic" }, { children: "Clear filters to view reference." })))] })));
                return (_jsx(Tooltip, Object.assign({ color: "white", placement: "bottom", open: evidenceDocTitle || evidenceSnippet || position === undefined
                        ? undefined
                        : false, overlayClassName: "EvidenceRefernceTip", overlayStyle: {
                        maxWidth: "35rem",
                    }, title: tooltip }, { children: superscript })));
            }
            return _jsx("span", { children: text });
        } }));
}
