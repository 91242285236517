import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { a_activeConstraintIsEmpty } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { useArgsInfo } from "../../appState/atomic/queryState/useArgsInfo";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { landmarkIdTag } from "../common/assist/useStartTour";
import { Fill } from "../common/layout/Fill";
import { EvidencePanelContents } from "../evidence/EvidencePanelContents";
import { EmptyEvidencePanel } from "./EmptyEvidencePanel";
import { EvidencePanelContents as AlternativeContents } from "./EvidencePanelContents";
export function EvidencePanel() {
    const [session] = useCurrentSession();
    const argsInfo = useArgsInfo();
    const emptyQuery = useAtomValue(a_activeConstraintIsEmpty);
    const { alternativeEvidenceLayout } = session;
    return (_jsx(Fill, Object.assign({ flexRow: true, className: "flex-1 justify-center" }, landmarkIdTag("tour-evidence"), { children: emptyQuery ? (_jsx(EmptyEvidencePanel, {})) : alternativeEvidenceLayout ? (_jsx(AlternativeContents, { argsInfo: argsInfo })) : (_jsx(EvidencePanelContents, { argsInfo: argsInfo })) })));
}
