import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown } from "antd";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
export function ReferenceQualifier({ disableHighlights, disableFiltering, disableGroupSelect, clearHighlights, referenceCount, highlightReferences, selectReferences, filterByReferences, considerationCount, highlightConsiderations, selectConsiderations, filterByConsiderations, }) {
    return (_jsxs("span", Object.assign({ className: "text-sm select-none" }, { children: ["references", " ", _jsx(Dropdown, Object.assign({ menu: {
                    items: [
                        {
                            label: "Filter By",
                            key: "filterByReferences",
                            onClick: filterByReferences,
                            disabled: disableFiltering,
                        },
                        {
                            label: "Select All",
                            key: "selectReferences",
                            onClick: selectReferences,
                            disabled: disableGroupSelect,
                        },
                    ],
                } }, { children: _jsx("span", Object.assign({ className: classes("underline", "cursor-pointer"), onMouseEnter: !disableHighlights ? highlightReferences : undefined, onMouseOut: !disableHighlights ? clearHighlights : undefined }, { children: `${referenceCount} evidence items` })) })), ` out of `, _jsx(Dropdown, Object.assign({ menu: {
                    items: [
                        {
                            label: "Filter By",
                            key: "filterByConsiderations",
                            onClick: filterByConsiderations,
                            disabled: disableFiltering,
                        },
                        {
                            label: "Select All",
                            key: "selectConsiderations",
                            onClick: selectConsiderations,
                            disabled: disableGroupSelect,
                        },
                    ],
                } }, { children: _jsx("span", Object.assign({ className: classes("underline", "cursor-pointer"), onMouseEnter: !disableHighlights ? highlightConsiderations : undefined, onMouseOut: !disableHighlights ? clearHighlights : undefined }, { children: `${considerationCount} considered` })) }))] })));
}
