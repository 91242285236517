import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { WithTheme } from "../../appState/context/theme/WithTheme";
export function showDialog(creator) {
    return new Promise(resolve => {
        const div = document.createElement("div");
        document.body.appendChild(div);
        const root = ReactDOM.createRoot(div);
        //TODO: will not have any context because it's rendered outside of the app!!!
        root.render(_jsx(WithTheme, { children: creator((value) => {
                setTimeout(() => {
                    var _a;
                    root.unmount();
                    (_a = div.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(div);
                    resolve(value);
                });
            }) }));
    });
}
