var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSetAtom } from "jotai";
import { API } from "../../../api/api";
import { useInfoTask } from "./useInfoStream";
import { a_directory } from "./useSessionsDirectory";
import { serializeSession } from "./utils/serializeSession";
export function useSaveRemoteSession() {
    const setDirectory = useSetAtom(a_directory);
    const infoTask = useInfoTask();
    return (session) => __awaiter(this, void 0, void 0, function* () {
        return yield infoTask({ message: `Saving Session…` }, () => __awaiter(this, void 0, void 0, function* () {
            yield API.upsertSession(serializeSession(session));
            setDirectory(dir => {
                const entry = { id: session.id, name: session.name };
                let update = false;
                const updated = dir.map(s => {
                    const isUpdate = s.id === session.id;
                    update = update || isUpdate;
                    return isUpdate ? entry : s;
                });
                return update ? updated : updated.concat([entry]);
            });
        }));
    });
}
