import { Set } from "immutable";
import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import React from "react";
const a_highlight = atomWithReset(Set([]));
export function useEvidenceHighlights() {
    const [highlight, setHighlight] = useAtom(a_highlight);
    const evidenceHighlighted = (evidenceId) => highlight.includes(evidenceId);
    const highlightEvidence = React.useCallback((evidenceIds) => {
        setHighlight(highlight.union(evidenceIds));
    }, [highlight, setHighlight]);
    const unhighlightEvidence = React.useCallback((evidenceIds) => {
        setHighlight(highlight.subtract(evidenceIds));
    }, [highlight, setHighlight]);
    const resetEvidenceHighlight = useResetAtom(a_highlight);
    return {
        evidenceHighlighted,
        highlightEvidence,
        unhighlightEvidence,
        resetEvidenceHighlight,
    };
}
