import { uuidv4 } from "../../../../utils/identity/uuidv4";
import { createConstraint } from "./createConstraint";
export function textToDMParams(question) {
    return {
        relations: {},
        clauses: {},
        concepts: {},
        constraints: [createConstraint(uuidv4(), [], question)],
    };
}
