var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Spin } from "antd";
import { useSetAtom } from "jotai";
import React from "react";
import { a_modelReset } from "../../appState/atomic/domainModel/modelState";
import { a_resetAllFilters } from "../../appState/atomic/queryState/constraintModel/resets";
import { useRestoreQuery } from "../../appState/atomic/queryState/useQueryState";
import { UNNAMED_SESSION_ID } from "../../appState/atomic/session/parts/sessionMetadata";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { useDeleteSession } from "../../appState/atomic/session/useDeleteSession";
import { useSessionControls } from "../../appState/atomic/session/useSessionControls";
import { useSessionsDirectory } from "../../appState/atomic/session/useSessionsDirectory";
import { isUnnamedSession } from "../../appState/atomic/session/utils/isUnnamedSession";
import { useQueryInputTextResetter } from "../../appState/atomic/useQueryInputText";
import { TABS_BG } from "../../appState/context/theme/lf";
import { confirmation } from "../../utils/dialog/confirmation";
import { limitLength } from "../../utils/text/limitLength";
import { useCollectionActions } from "../actions/useCollectionActions";
import { Down } from "../common/icons/Icons";
import { TabButton } from "../common/layout/TabButton";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { Tooltip } from "../common/popups/Tooltip";
import "./SelectionPicker.less";
const CONFIRM_CHANGE_TEXT = `You have unsaved work. Please confirm you want to change sessions, or cancel and 'Save Session As'`;
export function SessionPicker() {
    const [loading, setLoading] = React.useState(false);
    const directory = useSessionsDirectory();
    const deleteSession = useDeleteSession();
    const [currentSession] = useCurrentSession();
    const restoreQueryState = useRestoreQuery();
    const { id: sessionId } = currentSession;
    const isUnnamed = isUnnamedSession(sessionId);
    const unsavedInfo = isUnnamed && currentSession.findings.length > 0;
    const resetAllFilters = useSetAtom(a_resetAllFilters);
    const resetModel = useSetAtom(a_modelReset);
    const { switchSession, setNewSession, saveSessionAs } = useSessionControls();
    const resetQueryInputText = useQueryInputTextResetter();
    const selected = React.useCallback((item) => sessionId === item.id, [sessionId]);
    const entries = useCollectionActions({
        items: directory,
        name: item => item.name,
        emptyMessage: "No sessions",
        selected,
        newCaption: "New Session",
        saveCaption: `Save ${isUnnamed ? "" : "Copy "}As`,
        onSelect({ id, name }) {
            return __awaiter(this, void 0, void 0, function* () {
                if (yield confirmOp()) {
                    try {
                        setLoading(true);
                        resetQueryInputText(); //TODO: better state reset
                        yield switchSession(id, name, restoreQueryState);
                    }
                    finally {
                        setLoading(false);
                    }
                }
            });
        },
        onRemove({ id }) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    setLoading(true);
                    if (sessionId === id) {
                        yield setNewSession();
                    }
                    yield deleteSession(id);
                }
                finally {
                    setLoading(false);
                }
            });
        },
        onCopy(name) {
            return __awaiter(this, void 0, void 0, function* () {
                yield save(name);
            });
        },
        onReplace(name) {
            return __awaiter(this, void 0, void 0, function* () {
                const entry = directory.find(s => s.name === name);
                yield save(name);
                yield deleteSession(entry.id);
            });
        },
        onNew() {
            return __awaiter(this, void 0, void 0, function* () {
                if (yield confirmOp()) {
                    try {
                        setLoading(true);
                        resetAllFilters();
                        resetModel();
                        yield setNewSession(restoreQueryState);
                    }
                    finally {
                        setLoading(false);
                    }
                }
            });
        },
    });
    return (_jsx(DropDownMenu, Object.assign({ title: "Session", hasClose: true, disabled: loading, maxHeight: "70vh", entries: entries }, { children: _jsx(Tooltip, Object.assign({ content: "Session", placement: "bottom-start" }, { children: _jsxs(TabButton, Object.assign({ className: TABS_BG }, { children: [_jsx("div", { children: limitLength(currentSession.name, 24) }), loading ? _jsx(Spin, { size: "small" }) : _jsx(Down, { className: "text-xs" })] })) })) })));
    function confirmOp() {
        return __awaiter(this, void 0, void 0, function* () {
            return (!unsavedInfo ||
                (yield confirmation({
                    title: CONFIRM_CHANGE_TEXT,
                    okText: "Confirm",
                })));
        });
    }
    function save(name) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                setLoading(true);
                yield saveSessionAs(currentSession, name, restoreQueryState);
                if (isUnnamed) {
                    yield deleteSession(UNNAMED_SESSION_ID);
                }
            }
            finally {
                setLoading(false);
            }
        });
    }
}
