var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "antd";
import React from "react";
import { useCurrentSource } from "../../../api/useCurrentSource";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { ModalType, closeModal, openModal, } from "../../../appState/beacon/useModalState";
import { classes } from "../../../appState/context/theme/classes";
import { columnMinWidthClass1_25 } from "../../../appState/context/theme/lf";
import { confirmation } from "../../../utils/dialog/confirmation";
import { mapperComparator, textComparator, } from "../../../utils/generic/comparators";
import { useParams } from "../../../utils/lifecycle/useParams";
import { ManageLists } from "../../common/icons/Icons";
import { CollectionManager } from "../../common/layout/CollectionManager";
import { pushModal } from "../../common/modals/Modal";
import { SourcesDropDown } from "../../header/SourcesDropDown";
import { showCustomListEditor } from "./CustomListEditor";
import { CustomListEntries } from "./CustomListEntries";
const widthClassName = columnMinWidthClass1_25;
function CustomListsManager() {
    const currentSource = useCurrentSource();
    const [{ customConceptListsByCorpusId }, sessionUpdater] = useCurrentSession();
    const [{ listsById, currentListName, source }, update] = useParams({
        listsById: customConceptListsByCorpusId,
        currentListName: null,
        source: currentSource,
    });
    const lists = listsById[source.id] || [];
    const list = React.useMemo(() => {
        const list = lists.find(l => l.name === currentListName);
        return !list
            ? undefined
            : (list === null || list === void 0 ? void 0 : list.accepted_concepts)
                ? list
                : Object.assign(Object.assign({}, list), { accepted_concepts: list.members.map(m => m.name) });
    }, [currentListName, lists]);
    const upsertList = (updated, remove = false) => {
        if (!updated)
            return;
        const updatedListsById = Object.assign(Object.assign({}, listsById), { [source.id]: lists
                .filter(l => remove ? l.name !== (updated === null || updated === void 0 ? void 0 : updated.name) : l.name !== (list === null || list === void 0 ? void 0 : list.name))
                .concat(remove ? [] : [updated]) });
        sessionUpdater({ customConceptListsByCorpusId: updatedListsById });
        update({
            listsById: updatedListsById,
            currentListName: null,
        });
    };
    function newList() {
        update({ currentListName: null });
        showCustomListEditor({
            customConceptListsByCorpusId,
            corpus_id: source.id,
        }).then(upsertList);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch flex-1 space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "flex space-x-4 items-center" }, { children: [_jsx("div", { children: "Show Lists for Source:" }), _jsx(SourcesDropDown, Object.assign({ selectedSourceId: source.id, onSelect: source => update({ source, currentListName: null }), caption: c => { var _a; return `${c.name} (${((_a = customConceptListsByCorpusId[c.id]) === null || _a === void 0 ? void 0 : _a.length) || 0})`; } }, { children: _jsx(Button, { children: source.name }) }))] })), _jsxs("div", Object.assign({ className: "flex items-stretch flex-1 space-x-4" }, { children: [_jsx(CollectionManager, { title: "Defined Lists", className: widthClassName, empty: _jsx(Button, Object.assign({ onClick: newList }, { children: "New List" })), items: lists, itemClassName: item => classes(currentListName === item.name && "bg-gray-300", currentListName !== item.name && "hover:bg-background_secondary"), itemRender: item => {
                            var _a, _b;
                            return (_jsxs("div", Object.assign({ className: "flex justify-start items-center w-full" }, { children: [_jsx(ManageLists, { className: "mr-2 pt-1" }), " ", item.name, " (", (_b = (_a = item.accepted_concepts) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0, ")"] })));
                        }, itemText: item => item.name, onClick: current => update({ currentListName: current.name }), onRemove: (item) => __awaiter(this, void 0, void 0, function* () {
                            if (yield confirmation({ title: `Delete List ${item.name}?` })) {
                                upsertList(item, true);
                            }
                        }), controls: _jsx(Button, Object.assign({ onClick: newList }, { children: "New List" })), sorter: mapperComparator(item => item.name, textComparator) }), _jsx(CustomListEntries, { readonly: true, title: list ? `${currentListName}` : " ", list: list, update: upsertList, empty: "Select a List", controls: _jsx(Button, Object.assign({ onClick: () => {
                                return showCustomListEditor({
                                    customConceptListsByCorpusId,
                                    corpus_id: source.id,
                                    list,
                                }).then(upsertList);
                            } }, { children: "Edit List" })) })] })), _jsx("span", Object.assign({ className: "w-[586px]" }, { children: "Concept Lists allows you to create and manage list of concepts that can be used in queries and Filters." }))] })));
}
export function showCustomListsManager(corpus_ids) {
    openModal(ModalType.LIST_MANAGER);
    pushModal({
        title: (_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(ManageLists, {}), _jsx("div", { children: "Concept Lists" })] }))),
        titleColorClass: "bg-gray-700",
        content: _jsx(CustomListsManager, {}),
        easyClose: () => true,
        onClose: () => closeModal(ModalType.LIST_MANAGER),
    });
}
