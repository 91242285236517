import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { MiniMap } from "reactflow";
import { useTheme } from "../../appState/context/theme/ThemeContext";
import { isActive, isSelected } from "./diagramUtils";
export function ViewMap() {
    const theme = useTheme();
    const miniMapNodeColor = (n) => isActive(n) && isSelected(n)
        ? theme.colors.primary
        : isActive(n)
            ? "#dbe9ff"
            : isSelected(n)
                ? "#888888"
                : "rgba(0,0,0,0.1)";
    return _jsx(MiniMap, { nodeColor: miniMapNodeColor, pannable: true, zoomable: true });
}
