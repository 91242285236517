import { keyBy } from "lodash";
import { setActive, setExpanded, validateNodeOrder, } from "../diagramUtils";
import { CONTEXT, ElementType, genId, isConcept, upsertEdge, upsertNode, } from "../model";
function DiagramModel(initial) {
    const { nodes, edges } = {
        nodes: keyBy(initial.nodes, "id"),
        edges: keyBy(initial.edges, "id"),
    };
    let collector = emptyNodesAndEdges();
    const referredIds = new Set();
    return {
        getCollector() {
            return collector;
        },
        resetCollector() {
            collector = emptyNodesAndEdges();
        },
        result() {
            return {
                nodes: Object.values(nodes).filter(i => referredIds.has(i.id)),
                edges: Object.values(edges).filter(i => referredIds.has(i.id)),
            };
        },
        upsertNode(params) {
            const id = params.id || genId();
            nodes[id] = upsertNode(params, nodes[id]);
            referredIds.add(id);
            return (collector.nodes[id] = nodes[id]);
        },
        upsertEdge(params) {
            const id = params.id || genId();
            edges[id] = upsertEdge(params, edges[id]);
            referredIds.add(id);
            return (collector.edges[id] = edges[id]);
        },
    };
}
function emptyNodesAndEdges() {
    return { nodes: {}, edges: {} };
}
export function dmParamsToDiagram(params, overrides = {}, possible = {}, nodesInfo = {}, activeConstraintId = "", ne = { nodes: [], edges: [] }) {
    const model = DiagramModel(ne);
    const { concepts: conceptsByKey, relations: relationsByKey, clauses: clausesByKey, constraints, } = params;
    const neByConstraintId = Object.fromEntries(constraints.map(c => [c.id, createFromConstraint(c)]));
    const { nodes, edges } = model.result();
    nodes.forEach(enhanceNode);
    const activeIds = neByConstraintId[activeConstraintId];
    return {
        nodes: validateNodeOrder(nodes.map(n => setActive(n, activeIds === null || activeIds === void 0 ? void 0 : activeIds.nodes.has(n.id)))),
        edges: edges.map(e => setActive(e, activeIds === null || activeIds === void 0 ? void 0 : activeIds.edges.has(e.id))),
    }; //{nodes
    function createFromConstraint(constraint) {
        model.resetCollector();
        const { sources, targets, relation, is_directed = false, qualifiers, context, text, } = constraint;
        const createRelationNode = Boolean(relation ||
            (sources.length && targets.length) ||
            (context === null || context === void 0 ? void 0 : context.length) ||
            Object.entries(qualifiers || {}).length);
        const sNode = null;
        //TODO: not a priority, fix later
        /*
          source || createRelationNode
            ? model.upsertNode({
                id: source || genId(),
                content: source ? conceptsByKey[source] : [],
                type: ElementType.CONCEPT,
              })
            : null
        */
        const tNode = null;
        //TODO: not a priority, fix later
        /*
          target || createRelationNode
            ? model.upsertNode({
                id: target || genId(),
                content: target ? conceptsByKey[target] : [],
                type: ElementType.CONCEPT,
              })
            : null
        */
        const rNode = null;
        //TODO: not a priority, fix later
        /*
        createRelationNode
          ? model.upsertNode({
              id: relation || genId(),
              content: relation ? relationsByKey[relation] : [],
              type: ElementType.RELATION,
              directed: is_directed,
              ...(text && {question: text}),
            })
          : null
          */
        if (rNode) {
            model.upsertEdge({
                id: genId(),
                source: sNode.id,
                target: rNode.id,
                type: ElementType.RELATION,
                directed: is_directed, // remove if we don't want to show arrow
            });
            model.upsertEdge({
                id: genId(),
                source: rNode.id,
                target: tNode.id,
                type: ElementType.RELATION,
                directed: is_directed,
            });
            (context || []).forEach(id => createQualifier(id, CONTEXT, [conceptsByKey[id]], ElementType.CONCEPT));
            Object.entries(qualifiers || {}).forEach(([argName, id]) => createQualifier(id, argName, [clausesByKey[id]], ElementType.QUALIFIER));
        }
        const ne = model.getCollector();
        return {
            nodes: new Set(Object.keys(ne.nodes)),
            edges: new Set(Object.keys(ne.edges)),
        };
        function createQualifier(id, argName, content, type) {
            const node = model.upsertNode({
                id,
                content,
                type,
                argName,
            });
            model.upsertEdge({
                id: genId(),
                source: rNode.id,
                target: node.id,
                type: ElementType.QUALIFIER,
                argName,
                label: argName,
            });
        }
    }
    function enhanceNode(n) {
        var _a;
        const info = nodesInfo[n.id];
        if (info) {
            const position = { x: info.x, y: info.y };
            n.position = position;
            n.positionAbsolute = position;
            setExpanded(n, info.expanded);
            n.data = Object.assign(Object.assign(Object.assign({}, n.data), (info.expanded && { expanded: info.expanded })), (info.resized && { resized: info.resized }));
        }
        if (isConcept(n)) {
            n.data.possible = (_a = possible === null || possible === void 0 ? void 0 : possible[n.id]) === null || _a === void 0 ? void 0 : _a[n.id]; //TODO: hack to compile but incorrect
            if (overrides[n.id]) {
                n.data.selection = overrides[n.id];
            }
        }
    }
}
