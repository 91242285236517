import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
import { copyToClipboard } from "../../utils/interactions/utils/clipboard";
import { BorderlessButton } from "../common/buttons/BorderlessButton";
import { Copy } from "../common/icons/Icons";
import { Tooltip } from "../common/popups/Tooltip";
export function ClipboardCopy({ text, onClick, }) {
    return (_jsx(Tooltip, Object.assign({ trigger: "click", content: "Copied to clipboard", delay: 150 }, { children: _jsx(BorderlessButton, Object.assign({ className: classes("cursor-pointer border-0 p-2 text-neutral-800 hover:text-accent-500"), onClick: e => {
                copyToClipboard(text);
                onClick && onClick(e);
            } }, { children: _jsx(Copy, {}) })) })));
}
