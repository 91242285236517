import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Slider } from "antd";
import { useAtom } from "jotai";
import { queryStateAtoms } from "../../../appState/atomic/queryState/queryStateAtoms";
import { NEUTRAL_COLOR_VALS } from "../../../appState/context/theme/lf";
import { FieldsetBorder } from "../../common/forms/FieldsetBorder";
import "./ApertureSlider.less";
export function ApertureSlider({ children }) {
    const [{ aperture }, updateScope] = useAtom(queryStateAtoms.scope);
    return (_jsxs(FieldsetBorder, Object.assign({ fieldTitle: "Search Aperture", className: "pb-2 px-2 flex items-center", borderColor: NEUTRAL_COLOR_VALS[800] }, { children: [_jsx("div", Object.assign({ className: "text-tiny font-medium select-none" }, { children: "Narrow" })), _jsx(Slider, { className: "ApertureSlider", min: 0, onChange: aperture => {
                    updateScope({ aperture });
                }, value: aperture, step: 0.5, style: { width: 70, margin: 0, marginLeft: 8, marginRight: 8 }, tooltip: { open: false } }), _jsx("div", Object.assign({ className: "text-tiny font-medium select-none" }, { children: "Wide" })), children] })));
}
