import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
import { getAncestor } from "../../utils/dom/utils/dom";
import { addWindowEventHandlers } from "../../utils/events/addWindowEventHandlers";
import { DotDotDot, Down } from "../common/icons/Icons";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { Tooltip } from "../common/popups/Tooltip";
import { Menu } from "./Menu";
import { getActionTooltip } from "./utils/getActionTooltip";
import { resolveValue } from "./utils/resolveValue";
export function actionsForPopup(actions) {
    return actions
        .filter(Boolean)
        .map(a => a && a.more
        ? [
            Menu.SEPARATOR,
            ...actionsForPopup((resolveValue(a.subEntries) || [])),
        ]
        : [a])
        .flat();
}
const hidePopup = [];
export function hideMenuBarPopup(e) {
    const hide = hidePopup[0];
    if (hide && !getAncestor(e.target, "Menu")) {
        hide();
        return true;
    }
    return false;
}
export function useActionBarEntries(actions) {
    const hideRef = React.useRef();
    function updateHide(hide) {
        hideRef.current = hide;
        if (hide) {
            hidePopup.push(hide);
        }
        else {
            hidePopup.pop();
        }
    }
    React.useEffect(() => {
        return (hideRef.current &&
            addWindowEventHandlers({
                mousedown(e) {
                    var _a;
                    if (!getAncestor(e.target, "Menu")) {
                        e.stopPropagation();
                        e.preventDefault();
                        (_a = hideRef.current) === null || _a === void 0 ? void 0 : _a.call(hideRef);
                    }
                },
            }));
    }, []);
    return (_jsx(_Fragment, { children: actions.map((entry, idx) => {
            if (!entry)
                return null;
            const { action, icon, caption, title, subEntries, selected, more, disabled, } = entry;
            const hasSubEntries = !!(subEntries === null || subEntries === void 0 ? void 0 : subEntries.length);
            const buttonContent = (_jsxs("div", Object.assign({ onMouseDownCapture: () => {
                    var _a;
                    (_a = hideRef.current) === null || _a === void 0 ? void 0 : _a.call(hideRef);
                    updateHide(undefined);
                    action === null || action === void 0 ? void 0 : action();
                }, className: classes(" px-2 py-1 flex items-center space-x-1 cursor-pointer bg-white select-none", selected && "bg-gray-500 text-white", !selected && "hover:bg-gray-100", disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer") }, { children: [resolveValue(icon) ||
                        (more && _jsx(DotDotDot, { style: { fontSize: 16 } })), caption, hasSubEntries && caption && (_jsx(Down, { className: "self-center text-sm" }))] }), idx));
            const tooltip = getActionTooltip(entry);
            const button = tooltip ? (_jsx(Tooltip, Object.assign({ content: tooltip, placement: "top", wrapperClassname: "self-center" }, { children: buttonContent }), idx)) : (buttonContent);
            return hasSubEntries ? (_jsx(DropDownMenu, Object.assign({ title: title, hasClose: !!(title === null || title === void 0 ? void 0 : title.length), entries: subEntries, containerClassName: "flex items-stretch", placement: "bottomLeft", onVisibleChange: (visible, hide) => updateHide(visible ? hide : undefined) }, { children: button }), idx)) : (button);
        }) }));
}
