var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Lazy from "lazy.js";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { filterItems, includesFilter } from "../../../utils/text/filters";
import { VirtualizedItems } from "../forms/Items";
import { Card } from "./Card";
import { Empty } from "./Empty";
import { TitledContainer } from "./TitledContainer";
import { useItemFilters } from "./useItemFilters";
export function CardsList(_a) {
    var { items, filtering, textFilter, loading, noItemsMessage, title, className, itemsContainerClassName = "w-full", headerActions, itemRender, bgClassName = "", headerClassName, headerWrapper, unfilteredItemCount, footer, headerInfo, horizontal, narrow, containerStyleClass, itemsListRenderer, style } = _a, rest = __rest(_a, ["items", "filtering", "textFilter", "loading", "noItemsMessage", "title", "className", "itemsContainerClassName", "headerActions", "itemRender", "bgClassName", "headerClassName", "headerWrapper", "unfilteredItemCount", "footer", "headerInfo", "horizontal", "narrow", "containerStyleClass", "itemsListRenderer", "style"]);
    const render = React.useCallback((item, idx, items) => itemRender(item, idx, items, (textFilter === null || textFilter === void 0 ? void 0 : textFilter.trim().length) ? textFilter.trim() : undefined), [itemRender, textFilter]);
    return (_jsx(TitledContainer, Object.assign({ noScroll: true, className: classes("flex-1", className), headerLeft: title, containerStyleClass: containerStyleClass, headerInfo: headerInfo ||
            (!filtering || unfilteredItemCount === undefined
                ? `${items === null || items === void 0 ? void 0 : items.length}`
                : `${unfilteredItemCount} of ${items.length}`), headerRight: headerActions, headerWrapper: headerWrapper, headerClassName: headerClassName, bgClassName: bgClassName, loading: loading, style: style }, { children: _jsxs("div", Object.assign({ className: "flex flex-col flex-1" }, { children: [_jsx(Empty, Object.assign({ empty: filtering && !items.length
                        ? "Nothing matches current filter"
                        : !items.length && noItemsMessage }, { children: itemsListRenderer ? (itemsListRenderer(itemRender, items)) : (_jsx(VirtualizedItems, { horizontal: horizontal, gutter: narrow ? "NARROW" : undefined, className: classes("flex-1", itemsContainerClassName), items: items, itemRender: render })) })), footer] })) })));
}
export function infoRenderer(infoExtractor) {
    return (item, idx, items, filterText) => {
        return _jsx(Card, Object.assign({}, infoExtractor(item, idx, items, filterText)));
    };
}
function useTextFilter({ items, itemTexts, textFilterFunction = includesFilter, }) {
    const [textFilter, setTextFilter] = React.useState("");
    const hasTextFilter = textFilter.trim().length > 0 && itemTexts;
    const filteredItems = React.useMemo(() => {
        const filter = hasTextFilter
            ? (item) => Lazy(itemTexts ? itemTexts(item) : []).some(t => textFilterFunction(t, textFilter))
            : null;
        return filterItems(items, filter);
    }, [items, itemTexts, textFilter, hasTextFilter, textFilterFunction]);
    return {
        textFilter,
        setTextFilter,
        filteredItems,
    };
}
export function FilteredCardList(_a) {
    var { items, itemTexts, textFilterFunction = includesFilter, noFilter, className } = _a, props = __rest(_a, ["items", "itemTexts", "textFilterFunction", "noFilter", "className"]);
    const { setTextFilter, textFilter, filteredItems } = useTextFilter({
        items,
        itemTexts,
        textFilterFunction,
    });
    const { filtering, items: itemsToShow, headerWrapper, } = useItemFilters(Object.assign({ items: filteredItems, textFilter: noFilter ? undefined : textFilter, setTextFilter: noFilter ? undefined : setTextFilter }, props));
    return (_jsx(CardsList, Object.assign({}, props, { className: className, items: itemsToShow, textFilter: textFilter, headerWrapper: headerWrapper, filtering: noFilter ? false : filtering })));
}
