import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { useReactFlow } from "reactflow";
import { DMTab, a_modelActiveTab, a_modelLoading, a_modelResetAnswer, } from "../../appState/atomic/domainModel/modelState";
import { a_constraintModelState } from "../../appState/atomic/queryState/constraintModel/modelState";
import { ModelPicker } from "./ModelPicker";
export function GraphControls({ children, additionalControls, }) {
    const modelState = useAtomValue(a_constraintModelState);
    const setActiveTab = useSetAtom(a_modelActiveTab);
    const resetModelAnswer = useSetAtom(a_modelResetAnswer);
    const setLoading = useSetAtom(a_modelLoading);
    const { setEdges, setNodes } = useReactFlow();
    function clearGraph() {
        setEdges([]);
        setNodes([]);
    }
    return (_jsxs("div", Object.assign({ className: "p-2 flex w-full justify-between items-center bg-gray" }, { children: [_jsx(ModelPicker, { onLoading: setLoading, onSelect: () => {
                    clearGraph();
                    setActiveTab(DMTab.GRAPH);
                    resetModelAnswer();
                } }), _jsxs("div", Object.assign({ className: "flex items-center space-x-2 ml-2" }, { children: [Object.keys(modelState.constraints).length > 1 && children, additionalControls] }))] })));
}
