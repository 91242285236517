import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { cleanupHtmlTags } from "../../../utils/dom/utils/cleanupHtmlTags";
import { a_constraintModelUtil, } from "./constraintModel/ConstraintModelUtil";
import { getNamedName } from "./semanticSearch/getNamedName";
import { mergeNamedMembersToList } from "./semanticSearch/mergeNamedMembers";
import { mergeRelationClusters } from "./semanticSearch/mergeRelationClusters";
import { CRCDirection } from "./types/CRCDirection";
import { ConceptSource } from "./types/ConstraintModelState";
function getStateNlg(modelUtil) {
    const constraint = modelUtil.getActiveConstraint();
    if (!constraint)
        return "none";
    const sourceConcepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [constraint.sourceNodeId]);
    const sourceConceptsOverride = modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [constraint.sourceNodeId]);
    const targetConcepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [constraint.targetNodeId]);
    const targetConceptsOverride = modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [constraint.targetNodeId]);
    const relations = modelUtil.getAllRelations(constraint);
    const crcDirection = constraint.is_directed
        ? CRCDirection.C1C2
        : CRCDirection.BOTH;
    const dir = crcDirection === CRCDirection.BOTH ? "--" : "->";
    const r = mergeRelationClusters(relations);
    const c1 = mergeNamedMembersToList(sourceConcepts, sourceConceptsOverride);
    const c2 = mergeNamedMembersToList(targetConcepts, targetConceptsOverride);
    const has1 = c1.length > 0;
    const has2 = c2.length > 0;
    const hasR = r.length > 0;
    const n1 = has1 ? getNamedName(c1) : "?";
    const n2 = has2 ? getNamedName(c2) : "?";
    const nr = hasR ? getNamedName(r) : "?";
    return hasR
        ? `${n1} ${dir} ${nr} ${dir} ${n2}`
        : has1 && has2
            ? `${n1}, ${n2}`
            : has1
                ? n1
                : has2
                    ? n2
                    : "any";
}
//Used for grouping findings and as a backup title
//TODO: should no longer be needed
const a_currentContextTitle = atom("");
export function useCurrentContextTitle() {
    return useAtom(a_currentContextTitle)[0];
}
export function useUpdateCurrentContextTitle() {
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const setCurrentContextTitle = useSetAtom(a_currentContextTitle);
    // const currentSource = useCurrentSource()
    const semanticGroupName = getStateNlg(modelUtil);
    // const prefix = `${currentSource!.name} - ${getClustersName(
    //   coraState.context
    // )}`
    const title = semanticGroupName;
    React.useEffect(() => {
        setCurrentContextTitle(cleanupHtmlTags(title));
    }, [title]);
    return title;
}
