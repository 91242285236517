var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Lazy from "lazy.js";
import { uniqBy } from "lodash";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { columnWidthClass1, hoverSelectedCardClass, selectedCardClass, } from "../../../appState/context/theme/lf";
import { intercalate } from "../../../utils/generic/collections";
import { isExtendMouseEvent } from "../../../utils/interactions/utils/interactionEvents";
import { Card } from "../layout/Card";
import { CardsList } from "../layout/CardsList";
import { useItemFilters, } from "../layout/useItemFilters";
import { DefaultMarkDown } from "../text/MarkDown";
function markdownForSynonyms(title, synonyms, filterText, markDownClass) {
    return !synonyms
        ? undefined
        : intercalate(uniqBy(Lazy(synonyms)
            .filter(s => (typeof title !== "string" ||
            s.toLowerCase() !== title.toLowerCase()) &&
            (!filterText ||
                !filterText.length ||
                s.toLowerCase().startsWith(filterText)))
            .first(15)
            .toArray(), s => s.toLowerCase()).map(s => (_jsx(DefaultMarkDown, { text: s, separator: filterText, prefix: true, markDownClass: markDownClass }, s))), ", ");
}
export function PickerList(props) {
    const { extraItems, items, infoExtractor, isSelected, onSelect, synonyms, textFilter, setTextFilter, textFilterComponent, filterBorderClass, headerWrapper, className, itemRender, itemsListRenderer } = props, rest = __rest(props, ["extraItems", "items", "infoExtractor", "isSelected", "onSelect", "synonyms", "textFilter", "setTextFilter", "textFilterComponent", "filterBorderClass", "headerWrapper", "className", "itemRender", "itemsListRenderer"]);
    const { filtering, items: itemsToShow, headerWrapper: hWrapper, } = useItemFilters(Object.assign({ items,
        textFilter,
        setTextFilter,
        textFilterComponent,
        filterBorderClass }, rest));
    const extraAndItems = React.useMemo(() => ((extraItems === null || extraItems === void 0 ? void 0 : extraItems.length) ? extraItems.concat(itemsToShow) : itemsToShow), [extraItems, itemsToShow]);
    return (_jsx(CardsList, Object.assign({ narrow: true, className: className || columnWidthClass1, items: extraAndItems, filtering: filtering, headerWrapper: header => {
            header = (hWrapper === null || hWrapper === void 0 ? void 0 : hWrapper(header)) || header;
            header = (headerWrapper === null || headerWrapper === void 0 ? void 0 : headerWrapper(header)) || header;
            return header;
        }, itemsContainerClassName: " ", textFilter: textFilter, itemsListRenderer: itemsListRenderer, itemRender: itemRender
            ? itemRender
            : (item, idx, items, filterText) => {
                return (_jsx(ClusterCard, { item: item, filterText: filterText, isSelected: isSelected, onSelect: onSelect, synonyms: synonyms, infoExtractor: infoExtractor }));
            } }, rest)));
}
export function ClusterCard({ infoExtractor, item, synonyms, filterText, isSelected, onSelect, cardLookClass, spacingClass, noRiseOnHover, className, titleClassName, }) {
    const info = infoExtractor(item);
    const title = info.title;
    const synonymElements = markdownForSynonyms(title, synonyms === null || synonyms === void 0 ? void 0 : synonyms(item), filterText) || [];
    return (_jsx(Card, Object.assign({}, info, { cardLookClass: cardLookClass, spacingClass: spacingClass, narrow: true, titleClass: classes("text-[14px] font-semibold", titleClassName), banners: info.banners ||
            (synonymElements.length && (_jsx("div", Object.assign({ className: "flex flex-1 flex-nowrap whitespace-nowrap text-ellipsis space-x-1 overflow-clip self-stretch text-[12px]", style: { overflow: "hidden" } }, { children: synonymElements })))), title: typeof title === "string" ? (_jsx(DefaultMarkDown, { text: title, separator: filterText, markDownClass: "bg-core_yellow-500" })) : (title), className: classes("cursor-pointer", !noRiseOnHover && hoverSelectedCardClass, (isSelected === null || isSelected === void 0 ? void 0 : isSelected(item)) &&
            classes("bg-neutral-300", "border-gray-400", selectedCardClass), className), barOffset: -4, onClick: e => {
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(item, isExtendMouseEvent(e));
        } })));
}
