var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { pick } from "lodash";
import colors from "../../../assets/colors";
import { uuidv4 } from "../../utils/identity/uuidv4";
export const CONTEXT = "Context";
export var ElementType;
(function (ElementType) {
    ElementType["CONCEPT"] = "CONCEPT";
    ElementType["RELATION"] = "RELATION";
    ElementType["QUALIFIER"] = "QUALIFIER";
})(ElementType || (ElementType = {}));
export function isEdge(item) {
    return "source" in item;
}
export function isConcept(item) {
    return item.type === ElementType.CONCEPT;
}
export function isRelation(item) {
    return item.type === ElementType.RELATION;
}
export function isQualifier(item) {
    return item.type === ElementType.QUALIFIER;
}
function emptyNodeData(type) {
    const data = {
        active: false,
        expanded: false,
        expandable: false,
        label: "?",
        content: [],
        selection: {},
        argName: undefined,
        directed: false,
        question: undefined,
    };
    return data;
}
export function setContent(node, content) {
    node = setSelection(node, {});
    return Object.assign(Object.assign({}, node), { data: Object.assign(Object.assign({}, node.data), { content, label: getLabel(content), expandable: node.type !== ElementType.RELATION && content.length > 0 }) });
}
export function setSelection(node, selection) {
    return Object.assign(Object.assign({}, node), { data: Object.assign(Object.assign({}, node.data), { selection }) });
}
export function getArgName(node) {
    return node.data.argName;
}
export function getQuestion(node) {
    return (node === null || node === void 0 ? void 0 : node.data.question) || "";
}
function getLabel(items) {
    return !items.length
        ? "?"
        : items.length === 1
            ? items[0].name
            : items[0].name + "…";
}
const idGen = (function () {
    let id = 0;
    return function () {
        return "ID" + ++id;
    };
})();
export function genId() {
    // return idGen()
    return uuidv4();
}
export function upsertNode(_a, node) {
    var { id, type } = _a, params = __rest(_a, ["id", "type"]);
    const empty = emptyNodeData(type);
    const dataParams = pick(params, Object.keys(empty));
    const nodeParams = pick(params, Object.keys(params).filter(k => !(k in dataParams)));
    const _b = Object.assign(Object.assign({}, empty), dataParams), { label, expandable } = _b, data = __rest(_b, ["label", "expandable"]);
    if (!node) {
        node = {
            id: id || genId(),
            type,
            position: { x: 0, y: 0 },
            focusable: false,
            data: {
                label: getLabel(data.content),
                expandable: type !== ElementType.RELATION && !!data.content.length,
            },
        };
    }
    const updated = Object.assign(Object.assign(Object.assign({}, node), { data: Object.assign(Object.assign({}, node.data), data) }), nodeParams);
    return setSelection(setContent(updated, data.content), data.selection);
}
export function getMarker(directed, active, activeColor = colors.cora_purple[400], inactiveColor = colors.gray[400]) {
    return {
        markerEnd: directed
            ? {
                type: "arrowclosed",
                color: active ? activeColor : inactiveColor,
                width: 24,
                height: 24,
            }
            : undefined,
    };
}
function emptyEdgeData() {
    return {
        active: false,
        directed: false,
    };
}
export function upsertEdge(_a, edge) {
    var { id, type, argName, label } = _a, params = __rest(_a, ["id", "type", "argName", "label"]);
    const empty = emptyEdgeData();
    const dataParams = pick(params, Object.keys(empty));
    const edgeParams = pick(params, Object.keys(params).filter(k => !(k in dataParams)));
    const data = Object.assign(Object.assign(Object.assign({}, empty), dataParams), { argName });
    const { directed, active } = data;
    if (!edge) {
        edge = {
            id: id || genId(),
            type,
            focusable: false,
        };
    }
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, edge), { label: label || argName }), getMarker(directed, active)), { data: Object.assign(Object.assign({}, edge === null || edge === void 0 ? void 0 : edge.data), data) }), edgeParams);
}
