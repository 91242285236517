import { mergeNamedMembersToList } from "./mergeNamedMembers";
export function itemsOrOverrides(items, overrides = [], mergeOverrides = false) {
    const overridesArr = Array.isArray(overrides)
        ? overrides
        : Object.values(overrides).flat();
    return overridesArr.length
        ? mergeOverrides
            ? mergeNamedMembersToList(overridesArr)
            : overridesArr
        : items;
}
