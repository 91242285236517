import { hasXY } from "./hasXY";
const x = ((v, o) => hasXY(v) ? v.x : Array.isArray(v) ? v[0] : v);
x.xy = (v, o) => [v, o];
x.obj = (v, o) => ({ x: v, y: o });
const y = ((v, o) => hasXY(v) ? v.y : Array.isArray(v) ? v[1] : o);
y.xy = (v, o) => [o, v];
y.obj = (v, o) => ({ x: o, y: v });
x.o = y;
y.o = x;
export const Axis = {
    x,
    y,
    get(horizontal) {
        return horizontal ? x : y;
    },
};
