export class VersionError extends Error {
    constructor(message) {
        super(message);
        this.name = "VersionError";
    }
}
export class VersionMigrationSystem {
    constructor() {
        this.migrators = new Map();
        this.scrubbers = [];
    }
    registerScrubber(scrubber) {
        this.scrubbers.push(scrubber);
    }
    // Register a migrator function for a specific version transition (e.g., 1, 5)
    registerMigrator(fromVersion, toVersion, migrator) {
        const versionTransition = `${fromVersion}to${toVersion}`;
        this.migrators.set(versionTransition, migrator);
    }
    scrub(object) {
        let scrubbedObject = object;
        for (const scrubber of this.scrubbers) {
            scrubbedObject = scrubber(scrubbedObject);
        }
        return scrubbedObject;
    }
    // Migrate an object to the target version
    migrate(object, currentVersion, targetVersion) {
        if (currentVersion > targetVersion) {
            throw new Error("Downgrading version is not supported");
        }
        let migratedObject = object;
        while (currentVersion < targetVersion) {
            let migratorFound = false;
            for (let nextVersion = targetVersion; nextVersion > currentVersion; nextVersion--) {
                const migratorKey = `${currentVersion}to${nextVersion}`;
                const migrator = this.migrators.get(migratorKey);
                if (migrator) {
                    migratedObject = this.scrub(migrator(migratedObject));
                    currentVersion = nextVersion;
                    migratorFound = true;
                    break;
                }
            }
            if (!migratorFound) {
                throw new Error(`No migrator found for version ${currentVersion}`);
            }
        }
        return migratedObject;
    }
}
