var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { uniq } from "lodash";
import { createClusterId } from "../../../utils/identity/createClusterId";
import { uuidv4 } from "../../../utils/identity/uuidv4";
//From:
// extraConceptsByCorpusId: Dictionary<ConceptOrCustom[]>
//To:
// extraConceptNodeIdsByCorpusId: Dictionary<string[]>
// extraConceptIdsByNodeId: Dictionary<string[]>
// extraConcepts: Dictionary<ConceptOrCustom>
export function v2SessionToV3Session(v2Session) {
    const { extraConceptsByCorpusId } = v2Session, rest = __rest(v2Session, ["extraConceptsByCorpusId"]);
    const newFields = {
        extraConceptNodeIdsByCorpusId: {},
        extraConceptIdsByNodeId: {},
        extraConcepts: {},
    };
    // every concept is stored in it's own node
    if (extraConceptsByCorpusId) {
        for (const corpusId of Object.keys(extraConceptsByCorpusId)) {
            const concepts = extraConceptsByCorpusId[corpusId];
            if (!concepts) {
                continue;
            }
            for (const concept of concepts) {
                const nodeId = uuidv4();
                const id = createClusterId(concept);
                const { extraConceptNodeIdsByCorpusId, extraConceptIdsByNodeId, extraConcepts, } = newFields;
                extraConceptNodeIdsByCorpusId[corpusId] = uniq([
                    ...(extraConceptNodeIdsByCorpusId[corpusId] || []),
                    nodeId,
                ]);
                extraConceptIdsByNodeId[nodeId] = uniq([
                    ...(extraConceptIdsByNodeId[nodeId] || []),
                    id,
                ]);
                extraConcepts[id] = concept;
            }
        }
    }
    return Object.assign(Object.assign({}, rest), newFields);
}
