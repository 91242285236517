import { Map, Set } from "immutable";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { getUpdateValue } from "../../utils/getUpdateValue";
import { maybeUpdateOrReset } from "../../utils/maybeUpdateOrReset";
export const EMPTY_OLD_GRAPH_STATE = {
    graphConfigFlags: Set(),
    hiddenGraphNodes: Map(),
};
const _atoms = {
    graphConfigFlags: atomWithReset(EMPTY_OLD_GRAPH_STATE.graphConfigFlags),
    hiddenGraphNodes: atomWithReset(EMPTY_OLD_GRAPH_STATE.hiddenGraphNodes),
};
export const OLD_GRAPH_STATE_KEYS = Object.keys(_atoms);
export const a_oldGraphState = atom(get => {
    return {
        graphConfigFlags: get(_atoms.graphConfigFlags),
        hiddenGraphNodes: get(_atoms.hiddenGraphNodes),
    };
}, (get, set, updateOrFn) => {
    const currentValue = get(a_oldGraphState);
    const updateValue = getUpdateValue(updateOrFn, currentValue);
    maybeUpdateOrReset(_atoms, get, set, updateValue, currentValue);
});
