import Mousetrap from "mousetrap";
import React from "react";
import { bindingTypes } from "./utils/keyboard";
export function useShortcuts(actions, disabled = false, element) {
    const mousetrap = React.useRef();
    React.useEffect(() => {
        if (disabled)
            return;
        class M extends Mousetrap {
            constructor(el) {
                super(el);
                this.stopCallback = (e, element) => {
                    return (element.tagName == "INPUT" ||
                        element.tagName == "SELECT" ||
                        element.tagName == "TEXTAREA");
                };
            }
        }
        mousetrap.current = new M(element || undefined);
        Object.entries(actions).forEach(([shortcut, action]) => {
            const parts = shortcut.split("+");
            const bindingType = parts.find(p => bindingTypes.has(p));
            shortcut = parts.filter(p => p !== bindingType).join("+");
            mousetrap.current.bind(shortcut, e => {
                var _a, _b;
                (_a = e === null || e === void 0 ? void 0 : e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                (_b = e === null || e === void 0 ? void 0 : e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
                action();
            }, bindingType);
        });
        return () => {
            Object.keys(actions).forEach(s => mousetrap.current.unbind(s));
            mousetrap.current = undefined;
        };
    }, [actions, disabled, element]);
    return (shortcut) => { var _a; return (_a = mousetrap.current) === null || _a === void 0 ? void 0 : _a.trigger(shortcut); };
}
