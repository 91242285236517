var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import App from "../../../../../App";
import { Loading } from "../../../../components/common/transitions/Loading";
import { getReturnUri, setSessionInfo } from "../utils/authInfo";
function OIDCAppWrapper() {
    const [isLoading, setIsLoading] = React.useState(true);
    const { signinSilent, signoutSilent, user } = useAuth();
    React.useEffect(() => {
        ;
        (() => __awaiter(this, void 0, void 0, function* () {
            try {
                if (!user)
                    return;
                setSessionInfo(() => __awaiter(this, void 0, void 0, function* () {
                    const user = yield signinSilent();
                    if (!user)
                        return "";
                    return user.access_token;
                }), false, {
                    userId: user.profile.nickname,
                    userName: user.profile.name,
                    orgId: user.profile.sub,
                }, () => {
                    signoutSilent({
                        post_logout_redirect_uri: getReturnUri(user.profile.sub),
                    });
                });
            }
            finally {
                setIsLoading(false);
            }
        }))();
    }, [user]);
    if (isLoading) {
        return _jsx(Loading, { loading: true });
    }
    return (_jsx(_Fragment, { children: _jsx(App, {}) }));
}
export const OIDCAuthWrapper = withAuthenticationRequired(OIDCAppWrapper, {
    OnRedirecting: () => {
        console.log("redirecting for oidc login");
        return _jsx(Loading, { loading: true });
    },
});
