var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "antd";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
export function BrandButton(_a) {
    var { className, roundedClass = "rounded", paddingClass = "px-5 py-1", disabled, type } = _a, props = __rest(_a, ["className", "roundedClass", "paddingClass", "disabled", "type"]);
    return (_jsx(Button, Object.assign({ type: type, ghost: !type, className: classes("BrandButton", "flex justify-center items-center", className, paddingClass, roundedClass, disabled && "cursor-not-allowed"), disabled: disabled }, props)));
}
