import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { filterMetadata } from "../../appState/atomic/queryState/semanticSearch/filterMetadata";
import { metadataValue } from "../../appState/atomic/queryState/semanticSearch/metadataValue";
import { classes } from "../../appState/context/theme/classes";
import { limitLength } from "../../utils/text/limitLength";
import { Tooltip } from "../common/popups/Tooltip";
import { TagsList } from "../common/tags/TagsList";
import { MetadataInfo } from "./MetadataInfo";
export function EvidenceFooter({ evidence, onMetadataClick, suffix, className, selected, metadataSorter, }) {
    var _a;
    const metadata = metadataSorter(((_a = evidence.instances[0]) === null || _a === void 0 ? void 0 : _a.metadata) || []);
    const items = filterMetadata(metadata, true);
    return (_jsx("div", Object.assign({ className: classes("pt-1", className) }, { children: _jsxs("div", Object.assign({ className: "flex flex-row flex-1 justify-between w-full py-1" }, { children: [_jsx(TagsList, { className: "ml-0 py-0.5 px-1 font-medium", widthClass: "w-[calc(100%-8em)]", items: items, interactive: false, tagContent: item => {
                        const value = metadataValue(item, true);
                        if (value === undefined)
                            return "";
                        return typeof value === "boolean" ? (value ? (item.name) : ("Not " + item.name // this shoulnd't becuase they should get filtered
                        )) : (_jsx(Tooltip, Object.assign({ content: _jsx(MetadataInfo, { items: [item] }) }, { children: _jsx("span", { children: limitLength(value.toString(), 40) }) })));
                    }, showMoreContent: () => _jsx(MetadataInfo, { truncate: true, items: items }), tagClassName: "my-auto", tagStyle: () => ({
                        borderColor: "#DDDDDD",
                    }), 
                    // COR-7746: disable pill filters
                    // onMouseDown={item => onMetadataClick?.(item)}
                    moreButtonCStyle: selected
                        ? {
                            background: "transparent",
                        }
                        : {} }), _jsx("div", Object.assign({ className: "flex flex-1 space-x-1 pl-20 justify-end" }, { children: suffix }))] })) })));
}
