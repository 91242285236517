import { useAtomValue } from "jotai";
import { useGlobalInfo } from "../appState/atomic/globalInfo";
import { queryStateAtoms } from "../appState/atomic/queryState/queryStateAtoms";
import { getCorpusIds } from "../appState/atomic/queryState/utils/getCorpusIds";
export function useCurrentSource() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const { corporaById } = useGlobalInfo();
    const normalized = getCorpusIds(corporaById, corpus_ids);
    return corporaById[normalized[0]];
}
