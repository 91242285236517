import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { identity } from "../../../utils/generic/identity";
import { isNully } from "../../../utils/generic/isNully";
import { landmarkIdTag } from "../assist/useStartTour";
import { Empty } from "./Empty";
import { Scroller } from "./Scroller";
export function TitledContainer({ className, bgClassName = "bg-gray-100", headerLeft, headerInfo, headerMiddle, headerRight, children, empty, loading, noScroll, headerClassName, headerWrapper, footer, containerStyleClass = "p-4 rounded-lg", headerBottomMargin = true, hideHeader, landmarkId, onHeaderClick, id, style, }) {
    return (_jsxs("div", Object.assign({ id: id, className: classes("flex flex-col", containerStyleClass, bgClassName, className), style: style }, { children: [hideHeader
                ? null
                : (headerWrapper || identity)((headerLeft || headerRight || headerMiddle) && (_jsxs("div", Object.assign({ className: classes("flex flex-row justify-between items-center space-x-1", headerBottomMargin && "mb-2", onHeaderClick && "cursor-pointer", headerClassName), onClick: onHeaderClick }, landmarkIdTag(landmarkId), { children: [headerLeft && (_jsxs("div", Object.assign({ className: "flex font-semibold w-full" }, { children: [headerLeft, isNully(headerInfo) ? null : (typeof headerInfo ===
                                    "string" &&
                                    headerInfo.trim().length) ||
                                    typeof headerInfo === "number" ? (_jsxs("span", { children: ["\u00A0", `(${headerInfo})`] })) : (headerInfo)] }))), headerMiddle, headerRight && _jsx("div", { children: headerRight })] })))), _jsx(Empty, Object.assign({ empty: empty, loading: loading }, { children: noScroll ? children : _jsx(Scroller, { children: children }) })), footer] })));
}
