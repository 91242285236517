import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { ToggleButton } from "../common/buttons/ToggleButton";
import { Globe } from "../common/icons/Icons";
import { Tooltip } from "../common/popups/Tooltip";
export function PrintSession() {
    const [session] = useCurrentSession();
    return (_jsx(Tooltip, Object.assign({ content: "Print Session" }, { children: _jsx(ToggleButton, Object.assign({ onClick: () => {
                console.log(session);
            } }, { children: _jsx(Globe, {}) })) })));
}
