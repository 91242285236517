import { jsx as _jsx } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { groupBy, isNumber } from "lodash";
import React, { createContext, useContext } from "react";
import { queryStateAtoms } from "../../atomic/queryState/queryStateAtoms";
import { invalidCorpusIds } from "../../atomic/queryState/utils/invalidCorpusIds";
import { useEvidence } from "./useEvidence";
const EvidenceContext = createContext(undefined);
export function useEvidenceSearchContext() {
    const context = useContext(EvidenceContext);
    if (!context)
        throw new Error("EvidenceContext must be used within an EvidenceProvider");
    return context;
}
export function EvidenceSearchProvider({ children, }) {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const disabled = invalidCorpusIds(corpus_ids);
    const evidenceSearch = useEvidence(disabled);
    return (_jsx(EvidenceContext.Provider, Object.assign({ value: { evidenceSearch } }, { children: children })));
}
export function useEvidenceSearchUtils() {
    const context = useEvidenceSearchContext();
    const filterEvidence = (list) => list; //TODO: if needed anymore
    function getFullEvidenceList() {
        var _a;
        if (context.evidenceSearch.evidenceAndTotals.loading ||
            context.evidenceSearch.evidenceAndTotals.error)
            return null;
        const evidenceList = (_a = context.evidenceSearch.evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.evidence;
        return evidenceList || null;
    }
    function getEvidence(evidenceId) {
        const evidenceList = getFullEvidenceList();
        if (!evidenceList)
            return null;
        const evidence = evidenceList.find((e) => e.id === evidenceId);
        return evidence || null;
    }
    return {
        getEvidence,
        getAssumedEvidenceMetadata: (evidenceId) => {
            var _a, _b, _c;
            const metadataObject = {
                documentTitle: "",
                authors: [],
                publicationDate: "",
                publicationSource: "",
                additionalMetadata: [],
            };
            const evidence = getEvidence(evidenceId);
            if (!evidence)
                return metadataObject;
            const metadata = evidence.instances[0].metadata;
            if (!metadata)
                return metadataObject;
            metadataObject.documentTitle = evidence.instances[0].title;
            metadataObject.authors = (_a = metadata === null || metadata === void 0 ? void 0 : metadata.find(m => m.id === "authors")) === null || _a === void 0 ? void 0 : _a.value;
            metadataObject.publicationDate = (_b = metadata === null || metadata === void 0 ? void 0 : metadata.find(m => m.id === "publication_date")) === null || _b === void 0 ? void 0 : _b.value;
            metadataObject.publicationSource = (_c = metadata === null || metadata === void 0 ? void 0 : metadata.find(m => m.id === "journal_title")) === null || _c === void 0 ? void 0 : _c.value;
            metadataObject.additionalMetadata = (metadata || []).filter(m => !["authors", "publication_date"].includes(m.id));
            return metadataObject;
        },
        getEvidenceList: (evidenceIdList) => {
            const fullEvidenceList = getFullEvidenceList();
            return (fullEvidenceList === null || fullEvidenceList === void 0 ? void 0 : fullEvidenceList.filter(e => evidenceIdList.includes(e.id))) || [];
        },
        getFilteredEvidenceIdList() {
            const evidenceList = getFullEvidenceList() || [];
            return filterEvidence(evidenceList).map(e => e.id);
        },
        getFilteredDocumentIdList() {
            const evidenceList = getFullEvidenceList() || [];
            const filteredEvidence = filterEvidence(evidenceList);
            const documentGroups = groupBy(filteredEvidence, (e) => e.instances[0].document_id);
            return Object.keys(documentGroups);
        },
        hasHighConfidenceResults: () => {
            const evidenceList = getFullEvidenceList() || [];
            return filterEvidence(evidenceList).some(e => e.is_high_confidence);
        },
        getDocumentEvidence: (document_id) => {
            const evidenceList = getFullEvidenceList();
            if (!evidenceList)
                return null;
            const groupedByDocumentId = groupBy(evidenceList, (e) => e.instances[0].document_id);
            const documentEvidence = groupedByDocumentId[document_id];
            if (!documentEvidence || documentEvidence.length === 0)
                return null;
            return documentEvidence;
        },
        getEvidenceIndex: (evidenceId) => {
            var _a;
            if (context.evidenceSearch.evidenceAndTotals.loading ||
                context.evidenceSearch.evidenceAndTotals.error)
                return null;
            const evidenceList = (_a = context.evidenceSearch.evidenceAndTotals.value) === null || _a === void 0 ? void 0 : _a.evidence;
            if (!evidenceList)
                return null;
            const evidenceIndex = evidenceList.findIndex((e) => e.id === evidenceId);
            if (!isNumber(evidenceIndex) || evidenceIndex === -1)
                return null;
            return evidenceIndex;
        },
    };
}
