import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useRemoteQuery } from "../../../api/useRemoteQuery";
import { validateTextFilter } from "../../../appState/atomic/queryState/semanticSearch/validateTextFilter";
import { classes } from "../../../appState/context/theme/classes";
import { useDebouncedValue } from "../../../utils/lifecycle/useDebouncedValue";
import { ConfidenceBar } from "../../ConfidenceBar";
import { ValidatedInput } from "../../common/forms/InputValidation";
import { FocusContainer } from "../../common/layout/FocusContainer";
import { ItemsPicker, PickerMode } from "../../picker/ItemsPicker";
import { NO_ACTIVATE_ON_CLICK } from "../diagramUtils";
import { ElementType } from "../model";
const MIN_PREFIX_LEN = 2;
function EntityPicker(props) {
    const { selection, onChange, disabled, expanded, confidence, className, description, } = props;
    const [text, setText] = React.useState("");
    const prefix = validateTextFilter(useDebouncedValue(text.trim()), MIN_PREFIX_LEN);
    return !expanded && !selection.length ? null : (_jsxs("div", Object.assign({ className: classes("flex-1 flex flex-col items-stretch space-y-1", className), style: { minHeight: expanded ? 200 : undefined } }, { children: [expanded && description && (_jsx("div", Object.assign({ className: "text-left italic opacity-50" }, { children: `Top ${description}` }))), expanded && (_jsx(FocusContainer, Object.assign({ className: classes("flex focus-within:bg-white") }, { children: _jsx(ValidatedInput, { size: "small", className: classes(NO_ACTIVATE_ON_CLICK, "text-tiny"), bordered: false, placeholder: `Filter…`, allowClear: true, value: disabled ? "" : text, onChange: setText, disabled: disabled, minFilterLength: MIN_PREFIX_LEN }) }))), selection.length > 0 && (_jsx(ItemsPicker, { entries: selection, loading: false, selected: selection, updateSelection: onChange, emptyMessage: "Nothing Found", wrapperClassName: " ", getId: i => i.name, renderItem: itemRenderer(confidence), listClassName: " ", noScroller: true, disabled: disabled })), expanded && props.loader && _jsx(LoadedItems, Object.assign({}, props, { prefix: prefix }))] })));
}
function LoadedItems({ selection, onChange, loader: l, disabled, expanded, prefix, confidence, itemsOverride, }) {
    const { loader, loaderParams } = l;
    const params = useDebouncedValue(loaderParams(prefix.trim()));
    const { value: entries, loading } = useRemoteQuery({
        loader,
        params: disabled || !expanded || itemsOverride ? undefined : params,
    });
    return (_jsx(ItemsPicker, { entries: itemsOverride || entries, loading: expanded && loading, selected: selection, updateSelection: onChange, emptyMessage: "Nothing Found", getId: i => i.name, renderItem: itemRenderer(confidence), mode: PickerMode.hideSelection, listClassName: " ", disabled: disabled }));
}
function itemRenderer(confidence) {
    return (item) => {
        var _a;
        return (_jsxs("div", Object.assign({ className: "flex-1 flex justify-between items-center" }, { children: [_jsx("span", Object.assign({ className: "text-tiny" }, { children: item.name })), confidence && (_jsx(ConfidenceBar, { confidence: Math.max(0, Math.min(1, (_a = confidence(item)) !== null && _a !== void 0 ? _a : 0)) }))] })));
    };
}
export function getEntityPicker(props, expanded, sel, onChanged) {
    const { loader, type, argName, content, label, possible } = props;
    const isRelation = type === ElementType.RELATION;
    const isQualifier = type === ElementType.QUALIFIER;
    const description = isRelation
        ? "Relations"
        : isQualifier
            ? argName + " Qualifiers"
            : !content.length
                ? "Concepts"
                : possible
                    ? "Solutions"
                    : label;
    return (_jsx(EntityPicker, { className: "mt-1", loader: loader, selection: sel, description: description, onChange: onChanged, expanded: expanded }));
}
