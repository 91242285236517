import { createClusterId } from "../../utils/identity/createClusterId";
export function getUpdatedConceptGroup(fromConcepts, fromOverrides) {
    const sets = fromConcepts.map(c => {
        const id = createClusterId(c);
        const overrides = fromOverrides[c.name] || []; //TODO: may be right sometimes, but is a hack
        return [id, c, overrides];
    });
    const toIds = sets.map(([id]) => id);
    const toConcepts = Object.fromEntries(sets.map(([id, c]) => [id, c]));
    const toOverrides = Object.fromEntries(sets
        .map(([id, c, overrides]) => [id, overrides])
        .filter(([_, v]) => v.length > 0));
    return [toIds, toConcepts, toOverrides];
}
