import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { addEvidenceToFinding } from "../../appState/context/findings/findings";
import { blueButtonClass } from "../../appState/context/theme/lf";
import { pushModal } from "../common/modals/Modal";
import { EvidenceSummaryBanner } from "../results/banners/EvidenceSummaryBanner";
import { SelectItemsModal } from "./SelectItemsModal";
function AttachEvidenceToFindings({ evidence }) {
    const [{ findings: items }, sessionUpdater] = useCurrentSession();
    return (_jsx(SelectItemsModal, { items: items, brandingColorClass: "bg-notification_blue-500", buttonColorClass: blueButtonClass, title: "My Findings", buttonCaption: "Attach Evidence", noItemsMessage: "No Findings", notificationMessage: "Evidence Attached", contextText: evidence.length == 1
            ? evidence[0].text
            : `Adding ${evidence.length} new Evidence`, onSelected: selectedItems => {
            const selectedIds = new Set(selectedItems.map(item => item.id));
            sessionUpdater(({ findings }) => ({
                findings: findings.map(f => !selectedIds.has(f.id) ? f : addEvidenceToFinding(f, evidence)),
            }));
        }, infoExtractor: (item, idx, actions) => ({
            title: (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "flex justify-end" }, { children: actions })), _jsx("div", { children: item.payload.selectedText })] })),
            tags: _jsx(EvidenceSummaryBanner, { evidence: item.payload.evidence }),
        }) }));
}
export function showAttachEvidenceToFindings(props) {
    pushModal({
        title: "Attach Evidence",
        titleColorClass: "bg-notification_blue-500",
        content: _jsx(AttachEvidenceToFindings, Object.assign({}, props)),
    });
}
