var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { Menu } from "../../actions/Menu";
import { DropDown } from "./DropDown";
export function DropDownMenu(_a) {
    var { entries, captionClassName, disabled, dontHideOnSelect, containerClassName, maxHeight, getHeader, getFooter, entryClassName, menuBodyClassName, menuTestId, emptyMessage } = _a, props = __rest(_a, ["entries", "captionClassName", "disabled", "dontHideOnSelect", "containerClassName", "maxHeight", "getHeader", "getFooter", "entryClassName", "menuBodyClassName", "menuTestId", "emptyMessage"]);
    return (_jsx(DropDown, Object.assign({ disabled: disabled, getContent: hide => {
            return (_jsx(Menu, { captionClassName: captionClassName, entries: entries, onSelect: dontHideOnSelect ? undefined : hide, maxHeight: maxHeight, getFooter: getFooter && (refreshMenu => getFooter === null || getFooter === void 0 ? void 0 : getFooter(hide, refreshMenu)), getHeader: getHeader && (refreshMenu => getHeader === null || getHeader === void 0 ? void 0 : getHeader(hide, refreshMenu)), entryClassName: entryClassName, className: classes("rounded-lg shadow-none", menuBodyClassName), dataTestId: menuTestId, emptyMessage: emptyMessage }));
        }, containerClassName: containerClassName }, props)));
}
