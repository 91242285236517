import { defaultTheme, // Default theme
 } from "@ant-design/compatible";
// TODO: define a theme type
export function buildAntTheme(theme) {
    return Object.assign(Object.assign({}, defaultTheme), { token: Object.assign(Object.assign({}, defaultTheme.token), { colorPrimary: theme.primary, colorPrimaryHover: theme.primary, colorTextDisabled: theme.disabled_primary, colorInfo: theme.primary, fontFamily: "Inter, sans-serif", borderRadius: 4 }), components: {
            Button: {
                colorBorder: theme.disabled_primary,
            },
            Layout: {
                colorBgHeader: theme.primary,
            },
        } });
}
