var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { Tooltip } from "../popups/Tooltip";
export function DivButton(_a) {
    var { className, disabled, onMouseDown, onClick, tooltip } = _a, props = __rest(_a, ["className", "disabled", "onMouseDown", "onClick", "tooltip"]);
    const button = (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: classes("select-none", disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer", className), onMouseDown: disabled ? undefined : onMouseDown, onClick: disabled || !onClick
                ? undefined
                : e => {
                    e.preventDefault();
                    onClick(e);
                } }, props)) }));
    return tooltip ? _jsx(Tooltip, Object.assign({ content: tooltip }, { children: button })) : button;
}
