import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtom } from "jotai";
import { keyBy } from "lodash";
import React from "react";
import { MetadataType, } from "../../../api/types/metadataTypes";
import { useCurrentSource } from "../../../api/useCurrentSource";
import { a_booleanMetadata, a_keywordMetadata, a_rangeMetadata, } from "../../../appState/atomic/queryState/constraintModel/modelState";
import { migrateKeywordMetadata } from "../../../appState/atomic/queryState/coraState/versionManagement/migrateKeywordMetadata";
import { isNully } from "../../../utils/generic/isNully";
import { FacetsTabContent } from "../FacetsTabContent";
import { useUpdateFacetCollapseCleanup } from "../useFacetsCollapseState";
import { FacetsForBoolean } from "./FacetsForBolean";
import { FacetsForKeyword } from "./FacetsForKeyword";
import { FacetsForRange } from "./FacetsForRange";
export function MetadataFacets({ facetClassName }) {
    var _a;
    const [rangeMetadata, setRangeMetadata] = useAtom(a_rangeMetadata);
    const [booleanMetadata, setBooleanMetadata] = useAtom(a_booleanMetadata);
    const [keywordMetadata, setKeywordMetadata] = useAtom(a_keywordMetadata);
    const metadataFields = {
        keywordMetadata,
        rangeMetadata,
        booleanMetadata,
    };
    const metadata = ((_a = useCurrentSource()) === null || _a === void 0 ? void 0 : _a.supported_metadata_fields) || [];
    const metaDataById = keyBy(metadata, "name");
    useUpdateFacetCollapseCleanup("metadata", metadata.filter(m => m.type === MetadataType.KEYWORD).map(m => m.id));
    const byName = React.useMemo(() => {
        function update(key, type) {
            return (name, id, value) => {
                const param = metadataFields[key];
                const newValues = param
                    .filter(i => i.id !== id)
                    .concat(isNully(value) ? [] : [{ id, name, type, value }]);
                switch (key) {
                    case "keywordMetadata":
                        setKeywordMetadata(newValues);
                        break;
                    case "rangeMetadata":
                        setRangeMetadata(newValues);
                        break;
                    case "booleanMetadata":
                        setBooleanMetadata(newValues);
                        break;
                }
            };
        }
        return {
            [MetadataType.KEYWORD]: {
                Component: FacetsForKeyword,
                selection: keyBy(keywordMetadata.map(migrateKeywordMetadata).filter(Boolean), "name"),
                updateSelection: update("keywordMetadata", MetadataType.KEYWORD),
            },
            [MetadataType.DATE]: {
                Component: FacetsForRange,
                selection: keyBy(rangeMetadata, "name"),
                updateSelection: update("rangeMetadata", MetadataType.DATE),
            },
            [MetadataType.BOOLEAN]: {
                Component: FacetsForBoolean,
                selection: keyBy(booleanMetadata, "name"),
                updateSelection: update("booleanMetadata", MetadataType.BOOLEAN),
            },
        };
    }, [keywordMetadata, rangeMetadata, booleanMetadata]);
    return (_jsx(FacetsTabContent, { facets: () => (_jsx(_Fragment, { children: metadata
                .map(m => m.name)
                .map(name => metaDataById[name])
                .map((m, idx) => {
                var _a;
                const { Component, selection, updateSelection } = byName[m.type];
                return (_jsx(Component, { fieldId: m.id, name: m.name, selection: (_a = selection[m.name]) === null || _a === void 0 ? void 0 : _a.value, updateSelection: updateSelection, className: facetClassName }, m.name + idx));
            }) })) }));
}
