import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from "antd";
import React from "react";
import { useCurrentSession } from "../../../appState/atomic/session/session";
import { classes } from "../../../appState/context/theme/classes";
import { ActionButton } from "../../common/buttons/ActionButton";
import { Trash } from "../../common/icons/Icons";
import { Tooltip } from "../../common/popups/Tooltip";
import { ActionableBlock } from "./ActionableBlock";
import { ExtendedEvidenceText } from "./ExtendedEvidenceText";
export function EvidenceRow({ evidence, topDetails, bottomDetails, className, documentId, renderEvidenceActions, filterText, renderAsHtml, showSurrounding, isSelected, isHighlighted, selectEvidence, removeEvidence, openEvidence, onEvidenceTextSelected, annotationProvider, }) {
    const [{ showSummary }] = useCurrentSession();
    function addAndRemoveButtons(evidence) {
        return (_jsxs("div", Object.assign({ className: "" }, { children: [removeEvidence && (_jsx(ActionButton, { onClick: e => removeEvidence(evidence), icon: _jsx(Trash, {}) })), selectEvidence && (_jsx(Tooltip, Object.assign({ content: "Select" }, { children: _jsx("div", { children: _jsx(Checkbox, { className: "ml-2 mr-2", checked: isSelected && isSelected(evidence.id), onChange: e => {
                                selectEvidence(evidence);
                            } }) }) })))] })));
    }
    return (_jsxs("div", Object.assign({ className: classes("flex justify-between py-2 flex-col items-start border-t border-background_secondary", isHighlighted && isHighlighted(evidence.id)
            ? "bg-accent-50"
            : isSelected && isSelected(evidence.id)
                ? "bg-accent-100 hover:bg-accent-100"
                : "hover:bg-background_secondary", className) }, { children: [topDetails, _jsx(ActionableBlock, Object.assign({ actions: [
                    renderEvidenceActions && renderEvidenceActions(evidence, documentId),
                ] }, { children: _jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx("div", Object.assign({ className: "w-[24px] min-w-[24px] mr-2" }, { children: addAndRemoveButtons(evidence) })), _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx(ExtendedEvidenceText, { evidence: evidence, truncationThreshold: 500, filterText: filterText, renderAsHtml: renderAsHtml, annotationProvider: annotationProvider, onEvidenceTextSelected: onEvidenceTextSelected, showSurrounding: showSurrounding }), bottomDetails] }))] })) }))] })));
}
