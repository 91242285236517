import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FindingProvider, useFindingContext, } from "../../appState/context/findings/FindingContext";
import { FindingsViewer } from "./FindingViewer";
import { FindingsList } from "./FindingsList";
export function FindingsPanel() {
    return (_jsx(FindingProvider, { children: _jsx(Findings, {}) }));
}
function Findings() {
    const { state } = useFindingContext();
    const panelContents = state.finding ? _jsx(FindingsViewer, {}) : _jsx(FindingsList, {});
    return panelContents;
}
