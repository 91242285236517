import createIcon from "./createIcon";
import alignH from "../../../../assets/svg/alignH.svg";
import alignV from "../../../../assets/svg/alignV.svg";
import actionButton from "../../../../assets/svg/actionButton.svg";
import arrowBoth from "../../../../assets/svg/arrowBoth.svg";
import leftArrowWhite from "../../../../assets/svg/arrowLeft-white.svg";
import rightArrowWhite from "../../../../assets/svg/arrowRight-white.svg";
import arrowUpToLine from "../../../../assets/svg/arrowUpToLine.svg";
import books from "../../../../assets/svg/books.svg";
import chart from "../../../../assets/svg/chart.svg";
import check from "../../../../assets/svg/check.svg";
import checkLight from "../../../../assets/svg/checkLight.svg";
import claim from "../../../../assets/svg/claim.svg";
import clear from "../../../../assets/svg/clear.svg";
import clip from "../../../../assets/svg/clip.svg";
import close from "../../../../assets/svg/close.svg";
import closed from "../../../../assets/svg/closed.svg";
import config from "../../../../assets/svg/config.svg";
import configHorizontal from "../../../../assets/svg/configHorizontal.svg";
import copy from "../../../../assets/svg/copy.svg";
import curveFit from "../../../../assets/svg/curveFit.svg";
import dictionary from "../../../../assets/svg/dictionary.svg";
import document from "../../../../assets/svg/document.svg";
import dotDotDot from "../../../../assets/svg/dotDotDot.svg";
import down from "../../../../assets/svg/down.svg";
import exportIcon from "../../../../assets/svg/exportIcon.svg";
import facets from "../../../../assets/svg/facets.svg";
import filter from "../../../../assets/svg/filter.svg";
import filters from "../../../../assets/svg/filters.svg";
import finding from "../../../../assets/svg/finding.svg";
import findingFilled from "../../../../assets/svg/findingFilled.svg";
import findings from "../../../../assets/svg/findings.svg";
import fit from "../../../../assets/svg/fit.svg";
import flip from "../../../../assets/svg/flip.svg";
import four from "../../../../assets/svg/four.svg";
import globe from "../../../../assets/svg/globe.svg";
import glossary from "../../../../assets/svg/glossary.svg";
import graph from "../../../../assets/svg/graph.svg";
import historyFull from "../../../../assets/svg/historyFull.svg";
import home from "../../../../assets/svg/home.svg";
import importIcon from "../../../../assets/svg/importIcon.svg";
import left from "../../../../assets/svg/left.svg";
import line from "../../../../assets/svg/line.svg";
import link from "../../../../assets/svg/link.svg";
import list from "../../../../assets/svg/list.svg";
import location from "../../../../assets/svg/location.svg";
import logo from "../../../../assets/svg/logo.svg";
import manageLists from "../../../../assets/svg/manageLists.svg";
import newSearch from "../../../../assets/svg/new-search.svg";
import nl from "../../../../assets/svg/nl.svg";
import opened from "../../../../assets/svg/opened.svg";
import pin from "../../../../assets/svg/pin.svg";
import plus from "../../../../assets/svg/plus.svg";
import relatedSearch from "../../../../assets/svg/relatedSearch.svg";
import right from "../../../../assets/svg/right.svg";
import rightArrow from "../../../../assets/svg/rightArrow.svg";
import robot from "../../../../assets/svg/robot.svg";
import route from "../../../../assets/svg/route.svg";
import search from "../../../../assets/svg/search.svg";
import settingsGear from "../../../../assets/svg/settingsGear.svg";
import shareFromSquare from "../../../../assets/svg/shareFromSquare.svg";
import tags from "../../../../assets/svg/tags.svg";
import thumbsDown from "../../../../assets/svg/thumbsDown.svg";
import thumbsUp from "../../../../assets/svg/thumbsUp.svg";
import toggleSize from "../../../../assets/svg/toggleSize.svg";
import trash from "../../../../assets/svg/trash.svg";
import up from "../../../../assets/svg/up.svg";
export const AlignH = createIcon(alignH);
export const AlignV = createIcon(alignV);
export const ArrowBoth = createIcon(arrowBoth);
export const ArrowLeft = createIcon(leftArrowWhite);
export const ArrowRight = createIcon(rightArrowWhite);
export const ArrowUpToLine = createIcon(arrowUpToLine);
export const Books = createIcon(books);
export const Check = createIcon(check);
export const CheckLight = createIcon(checkLight);
export const Chart = createIcon(chart);
export const Claim = createIcon(claim);
export const Clear = createIcon(clear);
export const Clip = createIcon(clip);
export const Close = createIcon(close);
export const Closed = createIcon(closed);
export const Config = createIcon(config);
export const ConfigHorizontal = createIcon(configHorizontal);
export const Copy = createIcon(copy);
export const CurveFit = createIcon(curveFit);
export const Dictionary = createIcon(dictionary);
export const Document = createIcon(document);
export const Down = createIcon(down);
export const DotDotDot = createIcon(dotDotDot);
export const Export = createIcon(exportIcon);
export const Facets = createIcon(facets);
export const Filter = createIcon(filter);
export const Filters = createIcon(filters);
export const Finding = createIcon(finding);
export const FindingFilled = createIcon(findingFilled);
export const Findings = createIcon(findings);
export const Fit = createIcon(fit);
export const Flip = createIcon(flip);
export const ForwardButton = createIcon(actionButton);
export const Four = createIcon(four);
export const Glossary = createIcon(glossary);
export const Graph = createIcon(graph);
export const Globe = createIcon(globe);
export const Home = createIcon(home);
export const Import = createIcon(importIcon);
export const Left = createIcon(left);
export const Line = createIcon(line);
export const Link = createIcon(link);
export const List = createIcon(list);
export const Location = createIcon(location);
export const Logo = createIcon(logo);
export const ManageLists = createIcon(manageLists);
export const NL = createIcon(nl);
export const NewSearch = createIcon(newSearch);
export const Opened = createIcon(opened);
export const Pin = createIcon(pin);
export const Plus = createIcon(plus);
export const RightArrow = createIcon(rightArrow);
export const Robot = createIcon(robot);
export const Right = createIcon(right);
export const RelatedSearch = createIcon(relatedSearch);
export const Route = createIcon(route);
export const Search = createIcon(search);
export const HistoryFull = createIcon(historyFull);
export const SettingsGear = createIcon(settingsGear);
export const ShareFromSquare = createIcon(shareFromSquare);
export const Tags = createIcon(tags);
export const ThumbsDown = createIcon(thumbsDown);
export const ThumbsUp = createIcon(thumbsUp);
export const ToggleSize = createIcon(toggleSize);
export const Trash = createIcon(trash);
export const Up = createIcon(up);
