import { unionUsingKey } from "../../../../utils/generic/collections";
import { getSize } from "../../../../utils/generic/getSize";
import { getNamedName } from "./getNamedName";
import { itemsOrOverrides } from "./itemsOrOverrides";
export function mergeNamedMembers(items, override = {}, addRequired = false) {
    const actualItems = itemsOrOverrides(items, override);
    const required = addRequired ||
        getSize(override) > 0 ||
        actualItems.some(c => c.is_required);
    return !actualItems.length
        ? null
        : Object.assign({ name: getNamedName(actualItems), members: unionUsingKey(c => c.id, // TODO HACK
            ...actualItems.map(i => i.members || [])) }, (required && { is_required: true }));
}
export function mergeNamedMembersToList(items, override = {}, addRequired = false) {
    return [mergeNamedMembers(items, override, addRequired)].filter(Boolean);
}
