import { atom, useAtomValue, useSetAtom } from "jotai";
import { RESET, atomWithReset } from "jotai/utils";
import React from "react";
import { a_addSolutionConceptForNodeId, a_conceptNodes, a_concepts, a_resetAllSolutions, } from "../queryState/constraintModel/concepts";
import { a_constraintModelSolution } from "../queryState/constraintModel/modelState";
import { queryStateAtoms } from "../queryState/queryStateAtoms";
import { TwoKey } from "../queryState/types/DoubleDict";
import { useSessionMetadata } from "../session/parts/sessionMetadata";
export var DMTab;
(function (DMTab) {
    DMTab["GRAPH"] = "graph";
    DMTab["SUMMARY"] = "summary";
})(DMTab || (DMTab = {}));
export const a_modelActiveTab = atomWithReset(DMTab.GRAPH);
const a_modelIsSolved = atomWithReset(false);
export const a_modelSelectedEvidenceId = atomWithReset(null);
const a_modelRefreshCount = atomWithReset(0);
export const a_modelLoading = atom(false);
export const a_modelReset = atom(null, (get, set) => {
    set(a_modelActiveTab, RESET);
    set(a_modelSelectedEvidenceId, RESET);
    set(a_modelRefreshCount, RESET);
    set(a_modelIsSolved, RESET);
    set(a_resetAllSolutions);
});
export const a_modelResetAnswer = atom(null, (get, set) => {
    set(a_modelIsSolved, RESET);
    set(a_resetAllSolutions);
});
export const a_modelAnswerState = atom(null, (get, set, answer) => {
    set(a_constraintModelSolution, solution => (Object.assign(Object.assign({}, solution), { answer: answer.answer, evidence: answer.evidence, references: answer.references })));
    set(a_modelIsSolved, true);
    Object.entries(answer.bindings).forEach(([key, solutions]) => {
        const [nodeId, conceptId] = key.split("/");
        if (!nodeId || !conceptId) {
            console.error("Invalid binding key", key);
            return null;
        }
        solutions.forEach(s => set(a_addSolutionConceptForNodeId, nodeId, conceptId, s));
    });
});
export const a_modelSolutions = atom(get => {
    const isSolved = get(a_modelIsSolved);
    const concepts = get(a_concepts);
    const solution = get(a_constraintModelSolution);
    const nodeConceptKeys = Object.entries(get(a_conceptNodes)).flatMap(([nodeId, cIds]) => cIds.map(c => [nodeId, c]));
    const solutions = nodeConceptKeys.reduce((acc, [nodeId, conceptId]) => {
        const solutionIds = TwoKey.get(solution.solutions, nodeId, conceptId);
        if (!solutionIds) {
            return acc;
        }
        const solutionConcepts = solutionIds
            .map(id => concepts[id])
            .filter(Boolean);
        return TwoKey.set(acc, nodeId, conceptId, solutionConcepts);
    }, {});
    return {
        isSolved,
        solutions,
    };
});
function useModelAutoReset() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const [{ id: sessionId }] = useSessionMetadata();
    const resetModel = useSetAtom(a_modelReset);
    React.useEffect(() => {
        if (corpus_ids) {
            resetModel();
        }
    }, [corpus_ids, sessionId]);
}
