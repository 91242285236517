import { atom, useAtom, useSetAtom } from "jotai";
import React from "react";
import { useActionParams, } from "../../components/modelBuilder/actions/actionUtils";
import { useEditActions } from "../../components/modelBuilder/actions/editActions";
import { useFileActions } from "../../components/modelBuilder/actions/fileActions";
import { useGraphActions } from "../../components/modelBuilder/actions/graphActions";
import { useLayoutActions } from "../../components/modelBuilder/actions/layoutActions";
import { usePresetActions } from "../../components/modelBuilder/actions/presetsActions";
import { useRootActions } from "../../components/modelBuilder/actions/rootActions";
import { useSelectionctions } from "../../components/modelBuilder/actions/selectionActions";
import { useSolutionsActions } from "../../components/modelBuilder/actions/solutionsActions";
import { useNavigationActions } from "../../components/modelBuilder/navigation";
const a_selectionActions = atom({ global: [], selection: [], extra: [] });
export function useDiagramActions() {
    return useAtom(a_selectionActions)[0];
}
export function useDiagramActionsUpdater(mode, nodes, edges
// modelManager: ModelManagement
) {
    const setSelectionActions = useSetAtom(a_selectionActions);
    const params = useActionParams(nodes, edges, mode);
    const providers = [
        useRootActions,
        useSelectionctions,
        useFileActions,
        useEditActions,
        useSolutionsActions,
        usePresetActions,
        useGraphActions,
        useLayoutActions,
        useNavigationActions,
    ].map(provider => provider(params));
    React.useEffect(() => {
        const { global, globalRoot, selection, extra } = providers.reduce((acc, provider) => {
            const actions = provider();
            return {
                global: [...acc.global, ...(actions.global || [])],
                globalRoot: [...acc.globalRoot, ...(actions.globalRoot || [])],
                selection: [...acc.selection, ...(actions.selection || [])],
                extra: [...acc.extra, ...(actions.extra || [])],
            };
        }, { global: [], globalRoot: [], selection: [], extra: [] });
        setSelectionActions({
            selection: selection.filter(Boolean),
            global: [
                ...globalRoot.filter(Boolean),
                {
                    more: true,
                    subEntries: [...global.filter(Boolean)],
                },
            ],
            extra: extra.filter(Boolean),
        });
    }, [...providers, setSelectionActions]);
}
