import { cloneDeep } from "lodash";
import { SortOrder, } from "../../../verions/common";
const DEFAULT_APERTURE = 0.5;
const EMPTY_APERTURE = {
    aperture: DEFAULT_APERTURE,
};
function getEmptyAperture() {
    return cloneDeep(DEFAULT_APERTURE);
}
const EMPTY_CORPUS_IDS = {
    corpus_ids: [],
};
function getEmptyCorpusIds() {
    return cloneDeep(EMPTY_CORPUS_IDS);
}
const EMPTY_QUERY_TEXT = {
    queryText: "",
};
const BOOLEAN_METADATA = {
    booleanMetadata: [],
};
const KEYWORD_METADATA = {
    keywordMetadata: [],
};
export const EMPTY_RANGE_METADATA_OVERRIDE = {
    rangeMetadataOverride: [],
};
const RANGE_METADATA = {
    rangeMetadata: [],
};
export const EMPTY_METADATA_PARAMS = Object.assign(Object.assign(Object.assign({}, BOOLEAN_METADATA), KEYWORD_METADATA), RANGE_METADATA);
const EMPTY_REFUTING_RELATIONS = {
    refuting_relations: null,
};
const EMPTY_REFUTING_CONCEPTS = {
    refuting_concepts: null,
};
const EMPTY_REFUTING_TOGGLE = {
    refuting: false,
};
export const REFUTING_QUERY_PARAMS = Object.assign(Object.assign(Object.assign({}, EMPTY_REFUTING_RELATIONS), EMPTY_REFUTING_CONCEPTS), EMPTY_REFUTING_TOGGLE);
const DEFAULT_ORDER = SortOrder.DESC;
export const EMPTY_EVIDENCE_SEARCH_PARAMS = Object.assign(Object.assign({}, REFUTING_QUERY_PARAMS), { evidenceFilter: "", sortFieldId: "", sortOrder: DEFAULT_ORDER });
export const EMPTY_VIEWPORT = { x: 0, y: 0, zoom: 1 };
export const EMPTY_MODEL_BUILDER_STATE = {
    nodesInfo: {},
    viewport: EMPTY_VIEWPORT,
};
const EMPTY_CONSTRAINT_V1 = {
    id: "",
    source: null,
    relation: null,
    target: null,
    context: [],
    qualifiers: {},
    text: "",
};
export const EMPTY_CONSTRAINT = {
    id: "",
    sources: [],
    relation: null,
    targets: [],
    context: [],
    qualifiers: {},
    text: "",
};
const EMPTY_DM_PARAMS = {
    concepts: {},
    clauses: {},
    relations: {},
    constraints: [],
    // inference_rules: [],
};
export function getEmptyDmParams() {
    return cloneDeep(EMPTY_DM_PARAMS);
}
export const EMPTY_MODEL_ID = {
    activeModelId: "",
};
export const EMPTY_ACTIVE_CONSTRAINT_ID = {
    activeConstraintId: "",
};
export const EMPTY_MODEL_STATE = {
    dmParams: EMPTY_DM_PARAMS,
};
export const EMPTY_SCOPE = Object.assign(Object.assign({}, EMPTY_APERTURE), EMPTY_CORPUS_IDS);
export const EMPTY_COMMON_STATE_PARAMS = Object.assign(Object.assign(Object.assign({}, REFUTING_QUERY_PARAMS), EMPTY_EVIDENCE_SEARCH_PARAMS), EMPTY_SCOPE);
