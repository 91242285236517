import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue } from "jotai";
import { take } from "lodash";
import { a_constraintModelUtil } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { TOP_RESULTS_KEY } from "../../appState/atomic/queryState/consts/TOP_RESULTS_KEY";
import { ConceptSource } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { NEUTRAL_COLOR_VALS } from "../../appState/context/theme/lf";
import { Tooltip } from "../common/popups/Tooltip";
export function ConceptLine({ active, nodeId, conceptId, overflow = 3, }) {
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const color = active ? "black" : NEUTRAL_COLOR_VALS[900];
    const nodeOverrides = modelUtil.getOverridesForNodeIds(ConceptSource.MODEL, [
        nodeId,
    ]);
    const overrides = nodeOverrides[conceptId] || [];
    const nodeSolutions = modelUtil.getSolutionsForNodeIds([nodeId]);
    const solutions = nodeSolutions[conceptId] || [];
    const concept = modelUtil.model.data.concepts[conceptId];
    const cName = concept
        ? concept.name
        : conceptId === TOP_RESULTS_KEY
            ? TOP_RESULTS_KEY
            : "?";
    const tooltip = (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsxs("span", Object.assign({ className: "font-semibold" }, { children: ["Concept: ", _jsx("span", Object.assign({ className: "font-normal" }, { children: cName }))] })), solutions.length > 0 && overrides.length === 0 && (_jsx("span", Object.assign({ className: "font-semibold" }, { children: "Solutions:" }))), solutions.length > 0 &&
                        overrides.length === 0 &&
                        solutions.map(({ name }, i) => _jsx("span", { children: name }, i))] })), overrides.length > 0 &&
                overrides.map(({ name }, i) => (_jsx("span", Object.assign({ className: "text-xs" }, { children: name }), i)))] })));
    return (_jsx(Tooltip, Object.assign({ content: tooltip }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-center" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("span", Object.assign({ className: "p-1 font-semibold", style: { color } }, { children: cName })), solutions.length > 0 && overrides.length === 0 && (_jsx("span", Object.assign({ className: "ml-1 bg-accent-500 text-white p-1" }, { children: solutions.length })))] })), overrides.length > 0 &&
                    take(overrides, overflow).map(({ name }, i) => (_jsx("span", Object.assign({ className: "text-xs", style: { color } }, { children: name }), i))), overrides.length > overflow && (_jsxs("span", Object.assign({ className: "text-xs" }, { children: ["+", overrides.length - overflow, " more"] })))] })) })));
}
