import { jsx as _jsx } from "react/jsx-runtime";
import { atom, useAtom } from "jotai";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { getAncestor } from "../../../utils/dom/utils/dom";
import { uuidv4 } from "../../../utils/identity/uuidv4";
import { terminateEvent } from "../../../utils/interactions/utils/interactionEvents";
import { Down } from "../../common/icons/Icons";
import { DropDown } from "../../common/popups/DropDown";
import { ElementType } from "../model";
import { getEntityPicker } from "./EntityPicker";
const CLASS_NAME = "DropDownEntityPicker";
const a_hideDropDown = atom(null);
export function DropDownEntityPicker({ props, updateContent, }) {
    const id = React.useMemo(uuidv4, []);
    const [hideDropDown, setHidDropDown] = useAtom(a_hideDropDown);
    const { type, content, selection, expanded } = props;
    const isConcept = type === ElementType.CONCEPT;
    const selected = isConcept ? selection : content;
    const down = (_jsx(Down, { className: classes(CLASS_NAME, "node-chevron", !expanded && "cursor-pointer"), style: expanded ? { visibility: "hidden" } : undefined }));
    return expanded ? null : (_jsx(DropDown, Object.assign({ containerClassName: "flex items-center", showArrow: true, onVisibleChange: (visible, hide) => {
            setHidDropDown(null);
            if (hideDropDown && hideDropDown.id !== id) {
                //if there is another dropdown visible, hide it
                hideDropDown === null || hideDropDown === void 0 ? void 0 : hideDropDown.hide();
            }
            if (visible)
                setHidDropDown({ hide, id });
        }, getContent: () => {
            return (_jsx("div", Object.assign({ className: "p-4" }, { children: getEntityPicker(props, true, selected, sel => {
                    updateContent(sel);
                }) })));
        } }, { children: down })));
}
export function dropDownEntityPickerMouseHandling(e) {
    if (e.button === 0 && getAncestor(e.target, CLASS_NAME)) {
        terminateEvent(e);
        return true;
    }
    return false;
}
