var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDebouncedValue } from "../utils/lifecycle/useDebouncedValue";
export function usePaginatedRemoteQuery({ loader, params: liveParams, consolidatePages, delayMs = 300, }) {
    const params = useDebouncedValue(liveParams, {
        delayMs,
        leading: false,
        trailing: true,
    });
    const query = useInfiniteQuery({
        queryKey: ["evidence", params],
        initialPageParam: null,
        queryFn({ pageParam: cursor }) {
            return __awaiter(this, void 0, void 0, function* () {
                return loader(Object.assign(Object.assign({}, params), { cursor }));
            });
        },
        getNextPageParam(lastPage) {
            return lastPage.cursor;
        },
        enabled: !!params,
    });
    return React.useMemo(() => {
        var _a;
        const value = !query.data ? null : consolidatePages(query.data.pages);
        return {
            loading: query.isLoading,
            error: query.isError ? query.error : null,
            value,
            lastPage: !query.hasNextPage,
            nextPage: () => {
                if (query.isError ||
                    query.isLoading ||
                    !query.data ||
                    !query.hasNextPage)
                    return;
                query.fetchNextPage();
            },
            currentPage: (((_a = query.data) === null || _a === void 0 ? void 0 : _a.pages.length) || 0) - 1,
        };
    }, [query.isLoading, query.isError, query.data]);
}
