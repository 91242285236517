import React from "react";
import { useLocalStorageRetrieve } from "./useLocalStorageRetrieve";
import { useLocalStorageStore } from "./useLocalStorageStore";
export function useLocalStorageHelper(hook, key, initial) {
    const stored = useLocalStorageRetrieve(key, initial);
    const [value, update] = hook(stored);
    React.useEffect(() => {
        update(stored);
    }, [stored]);
    useLocalStorageStore(key, value);
    return [value, update];
}
