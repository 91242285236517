import { dmParamsToConstraintModel } from "../../atomic/queryState/constraintModel/fromDMParams";
import { conceptsWithOverridesToNodes, modelV1toV2 } from "./stateV1toV3";
export function v2QueryStateToV3QueryState(v2QueryState) {
    const { dmParams, overrides, aperture, corpus_ids, queryText, booleanMetadata, keywordMetadata, rangeMetadata, extra, extraOverrides, activeModelId, evidenceFilter, sortFieldId, sortOrder, refuting, nodesInfo, viewport, refuting_concepts, refuting_relations, activeConstraintId, } = v2QueryState;
    const updatedDMParams = modelV1toV2(dmParams);
    const constraintModel = dmParamsToConstraintModel(updatedDMParams, overrides);
    constraintModel.config.booleanMetadata = booleanMetadata;
    constraintModel.config.keywordMetadata = keywordMetadata;
    constraintModel.config.rangeMetadata = rangeMetadata;
    const { conceptNodes: extendedNodes, concepts: extendedConcepts, overrides: extendedOverrides, required: extendedRequired, } = conceptsWithOverridesToNodes(extra, extraOverrides);
    constraintModel.state.conceptNodes = Object.assign(Object.assign({}, constraintModel.state.conceptNodes), extendedNodes);
    constraintModel.data.concepts = Object.assign(Object.assign({}, constraintModel.data.concepts), extendedConcepts);
    constraintModel.config.overrides = Object.assign(Object.assign({}, constraintModel.config.overrides), extendedOverrides);
    constraintModel.config.requiredConcepts = Object.assign(Object.assign({}, constraintModel.config.requiredConcepts), extendedRequired);
    if (activeConstraintId) {
        constraintModel.activeConstraintId = activeConstraintId;
        constraintModel.config.refutingConfiguration = Object.assign(Object.assign({}, constraintModel.config.refutingConfiguration), { [activeConstraintId]: {
                refuting_concepts,
                refuting_relations,
            } });
    }
    if (activeModelId) {
        constraintModel.activeModelId = activeModelId;
    }
    if (queryText && activeConstraintId) {
        const activeConstraint = constraintModel.state.constraints[activeConstraintId];
        if (activeConstraint) {
            activeConstraint.userText = queryText;
        }
    }
    return {
        scope: {
            aperture,
            corpus_ids,
        },
        searchParams: {
            evidenceFilter,
            sortFieldId,
            sortOrder,
            refuting,
        },
        constraintModel,
        modelBuilderState: {
            nodesInfo,
            viewport,
        },
        multihopConfig: {
            discoveryStrategy: "NONE",
        },
    };
}
