import { DEFAULT_CRC_DIRECTION } from "../atomic/queryState/consts/DEFAULT_CRC_DIRECTION";
import { EMPTY_ACTIVE_CONSTRAINT_ID, EMPTY_COMMON_STATE_PARAMS, EMPTY_METADATA_PARAMS, EMPTY_MODEL_ID, EMPTY_MODEL_STATE, EMPTY_RANGE_METADATA_OVERRIDE, REFUTING_QUERY_PARAMS, } from "../atomic/queryState/consts/EMPTY_QUERY_STATE";
import { RELATION } from "../atomic/queryState/consts/RELATION";
export const QUERY_PARAMS_KEYS = [
    "concepts1",
    "concepts1Override",
    "concepts1Possible",
    "concepts2",
    "concepts2Override",
    "concepts2Possible",
    "extraConcepts",
    "extraConceptsOverride",
    "extraConceptsPossible",
    "contextConcepts",
    "contextConceptsOverride",
    "contextConceptsPossible",
    "text",
    "argClauses",
    "required_arguments",
    "refuting_relations",
    "refuting_concepts",
    "crcDirection",
];
const CONCEPTS1_OVERRIDE = {
    concepts1Override: {},
};
const CONCEPTS1_POSSIBLE = {
    concepts1Possible: null,
};
const CONCEPT1 = Object.assign(Object.assign({ concepts1: [] }, CONCEPTS1_OVERRIDE), CONCEPTS1_POSSIBLE);
const CONCEPTS2_OVERRIDE = {
    concepts2Override: {},
};
const CONCEPTS2_POSSIBLE = {
    concepts2Possible: null,
};
const CONCEPT2 = Object.assign(Object.assign({ concepts2: [] }, CONCEPTS2_OVERRIDE), CONCEPTS2_POSSIBLE);
const CONTEXT_CONCEPTS_OVERRIDE = {
    contextConceptsOverride: {},
};
const CONTEXT_CONCEPTS_POSSIBLE = {
    contextConceptsPossible: null,
};
const CONTEXT_CONCEPTS = Object.assign(Object.assign({ contextConcepts: [] }, CONTEXT_CONCEPTS_OVERRIDE), CONTEXT_CONCEPTS_POSSIBLE);
const EXTRA_CONCEPTS_OVERRIDE = {
    extraConceptsOverride: {},
};
const EXTRA_CONCEPTS_POSSIBLE = {
    extraConceptsPossible: null,
};
const EXTRA_CONCEPTS = Object.assign(Object.assign({ extraConcepts: [] }, EXTRA_CONCEPTS_OVERRIDE), EXTRA_CONCEPTS_POSSIBLE);
export const EMPTY_QUERY_PARAMS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, CONCEPT1), RELATION), CONCEPT2), EXTRA_CONCEPTS), CONTEXT_CONCEPTS), REFUTING_QUERY_PARAMS), { crcDirection: DEFAULT_CRC_DIRECTION, argClauses: {}, required_arguments: [], text: "" });
export const EMPTY_CORA_STATE = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, EMPTY_MODEL_STATE), EMPTY_MODEL_ID), EMPTY_ACTIVE_CONSTRAINT_ID), EMPTY_METADATA_PARAMS), EMPTY_RANGE_METADATA_OVERRIDE), EMPTY_COMMON_STATE_PARAMS), EMPTY_QUERY_PARAMS), { queryText: "", queries: [] });
