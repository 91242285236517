import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactFlow, { Background } from "reactflow";
import { useDiagramActions, useDiagramActionsUpdater, } from "../../appState/atomic/actions";
import { classes } from "../../appState/context/theme/classes";
import { getAncestor } from "../../utils/dom/utils/dom";
import { actionsForPopup } from "../actions/useActionBarEntries";
import { useBindActions } from "../actions/useBindActions";
import { showPopupMenu } from "../contextMenu/showPopupMenu";
import { useConnection } from "./connection";
import { ConnectionLine } from "./ConnectionPath";
import { EDGE_TYPES } from "./DiagramEdge";
import { Mode, useDiagramMode } from "./DiagramMode";
import { DiagramToolbar } from "./DiagramToolbar";
import { getNodeIdAt, MAX_ZOOM, MIN_ZOOM, stageNewNode } from "./diagramUtils";
import { PanningLayer, usePanningTrigger } from "./layers/PanningLayer";
import { StagingLayer } from "./layers/StagingLayer";
import { useNodeMergeDragging } from "./mergeNodes";
import { ignoreMouseHandling, useActivateNodeSelector, useActivateQuery, useFocusModelBuilder, useHidePopups, useMouseDownHandlers, useSelectNodeBeforeShowingContextMenu, useTrackViewport, } from "./MouseDownHandler";
import { NODE_TYPES } from "./node/DiagramNode";
import { dropDownEntityPickerMouseHandling } from "./node/DropDownEntityPicker";
import { useScrollToActive } from "./scrollToActive";
import SelectionToolbar from "./SelectionToolbar";
import { useStage } from "./Stage";
import { useSyncQueryStateAndDiagram } from "./syncQueryStateAndDiagram";
import { useSyncReactFlowAndState } from "./syncReactFlowAndState";
import { ViewMap } from "./ViewMap";
export function ModelBuilderDiagram({ parent }) {
    const [initialized, setInitialized] = React.useState(false);
    const state = useSyncReactFlowAndState();
    const mode = useDiagramMode();
    const reactFlowWrapper = React.useRef(null);
    const stage = useStage();
    useDiagramActionsUpdater(mode, state.nodes, state.edges);
    useSyncQueryStateAndDiagram(initialized);
    const { global: globalActions, selection: selectionActions, extra, } = useDiagramActions();
    useBindActions([...globalActions, ...selectionActions, ...extra], mode.is(Mode.staging), parent);
    useScrollToActive();
    const showContextMenu = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        showPopupMenu({
            entries: actionsForPopup([...globalActions, ...selectionActions]),
            top: e.clientY,
            left: e.clientX,
        });
    }, [globalActions, selectionActions]);
    const [selectionLayer, setSelectionLayer] = React.useState(null);
    const onMouseDownCapture = useMouseDownHandlers(mode.is(Mode.staging), ignoreMouseHandling, useTrackViewport(), dropDownEntityPickerMouseHandling, useFocusModelBuilder(), useHidePopups(), useSelectNodeBeforeShowingContextMenu(), useActivateQuery(), usePanningTrigger(), useActivateNodeSelector(reactFlowWrapper.current, setSelectionLayer));
    const onDoubleClick = React.useCallback((e) => {
        if (!getNodeIdAt(e)) {
            stageNewNode(stage);
        }
    }, [stage]);
    const connection = useConnection(reactFlowWrapper);
    const dragToMergeNode = useNodeMergeDragging();
    const showToolbar = mode.is(Mode.normal);
    return (_jsx("div", Object.assign({ ref: reactFlowWrapper, className: "relative flex-1 flex items-stretch" }, { children: _jsxs(ReactFlow, Object.assign({}, state, dragToMergeNode, connection, { className: classes("ModelBuilderDiagram flex-1", mode()), panOnDrag: false, onlyRenderVisibleElements: true, onMouseDownCapture: onMouseDownCapture, onWheel: onWheel, onContextMenu: showContextMenu, autoPanOnConnect: true, minZoom: MIN_ZOOM, maxZoom: MAX_ZOOM, zoomOnDoubleClick: false, zoomOnScroll: false, zoomActivationKeyCode: "Meta", attributionPosition: "bottom-left", nodeTypes: NODE_TYPES, edgeTypes: EDGE_TYPES, multiSelectionKeyCode: "Shift", selectionKeyCode: undefined, panOnScroll: true, connectionLineComponent: ConnectionLine, connectionLineStyle: ConnectionLine.style, deleteKeyCode: null, onDoubleClick: onDoubleClick, onInit: flow => {
                setTimeout(() => setInitialized(flow.viewportInitialized), 0);
            } }, { children: [_jsx(ViewMap, {}), _jsx(Background, { variant: "dots" }), showToolbar && _jsx(SelectionToolbar, {}), selectionLayer, _jsx(StagingLayer, { element: reactFlowWrapper.current }), _jsx(PanningLayer, {}), _jsx(DiagramToolbar, {})] })) })));
}
function onWheel(e) {
    const scroller = e.metaKey ? null : getAncestor(e.target, "Scroller");
    scroller === null || scroller === void 0 ? void 0 : scroller.scroll(0, scroller.scrollTop + e.deltaY);
}
