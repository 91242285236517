import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Form, InputNumber, Modal } from "antd";
import FormBuilder from "antd-form-builder";
import { omit, pick } from "lodash";
import React, { useCallback } from "react";
import { Feature, useSupportedFeatures, } from "../../appState/atomic/featureFlags/features";
import { useFeatureFlags } from "../../appState/atomic/featureFlags/useFeatureFlags";
import { useCurrentSession, useDefaultSession, } from "../../appState/atomic/session/session";
import { DebugModeToggle } from "../../appState/atomic/utils/DebugModeToggle";
import { ModalType, closeModal, openModal, } from "../../appState/beacon/useModalState";
import { DEFAULT_LOCAL_CONFIG, useLocalConfig, } from "../../appState/config/localConfig";
import { useTheme } from "../../appState/context/theme/ThemeContext";
import { useParams } from "../../utils/lifecycle/useParams";
import "./Config.less";
const SESSION_CONFIG_KEYS = [
    "alternativeEvidenceLayout",
    "alternativeFindingsLayout",
    "autoExpandEvidence",
    "conceptClustering",
    "conjunctionBindingsSearchWidth",
    "defaultEvidencePageSize",
    "evidenceGroupedByDocument",
    "expandedEvidence",
    "expandedFacetCount",
    "hierarchicalFacets",
    "modelBuilderAvailable",
    "queryGraph",
    "searchAperture",
    "showAnswer",
    "showTopResults",
    "showVectorSearchToggle",
    "structuredQuery",
    "showMultihopStrategySelection",
];
function useConfigFromSession() {
    const [currentSession] = useCurrentSession();
    return pick(currentSession, ...SESSION_CONFIG_KEYS);
}
const viewWidgetProps = { zIndex: 10000 };
const guruModeFields = new Set([
    "alternativeEvidenceLayout",
    "alternativeFindingsLayout",
    "autoExpandEvidence",
    "colorTheme",
    "defaultEvidencePageSize",
    "modelBuilderAvailable",
    "showTopResults",
    "showVectorSearchToggle",
    "showMultihopStrategySelection",
]);
const clusteringModes = ["NONE", "MINIMAL", "MODERATE", "AGGRESSIVE"];
const themes = ["light", "dark", "classic"];
export function useConfigDialog() {
    const theme = useTheme();
    const [form] = Form.useForm();
    const [{ visible, guruMode }, update] = useParams({
        visible: false,
        guruMode: false,
    });
    const { enableSuggestedQuestions, enableNaturalLanguageInput } = useFeatureFlags();
    const [, sessionUpdater] = useCurrentSession();
    const [localConfig, updateLocalConfig] = useLocalConfig();
    const [inProgressConfig, setInProgressConfig] = React.useState(useConfigFromSession());
    const sessionConfig = useConfigFromSession();
    const configValues = Object.assign(Object.assign({}, localConfig), sessionConfig);
    const defaultSession = useDefaultSession();
    function validExpandedFacetCount(value) {
        return value >= 15 && value <= 100;
    }
    function validEvidenceCount(value) {
        return value >= 5 && value <= 200;
    }
    const fieldMap = {
        "Answer Generation": [
            {
                key: "showAnswer",
                label: "Enable Answer Generation",
                widget: "checkbox",
                dependencies: [Feature.ANSWER_GENERATION],
            },
        ],
        Evidence: [
            {
                key: "showVectorSearchToggle",
                label: "Show Vector Search Toggle",
                widget: "checkbox",
                dependencies: [Feature.VECTOR_SEARCH],
            },
            {
                key: "evidenceGroupedByDocument",
                label: "Automatically group evidence by document",
                widget: "checkbox",
            },
            {
                key: "expandedEvidence",
                label: "Expand evidence with additional context",
                widget: "checkbox",
            },
            {
                key: "autoExpandEvidence",
                label: "Long evidence never collapses",
                widget: "checkbox",
            },
            {
                key: "alternativeEvidenceLayout",
                label: "Display alternative evidence layout (BETA)",
                widget: "checkbox",
            },
            {
                key: "defaultEvidencePageSize",
                label: "Evidence Count (Per Page)",
                widget: InputNumber,
                rules: [
                    {
                        validator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                                if (!validEvidenceCount(value)) {
                                    reject(new Error(`Value must be between 5 and 200`));
                                }
                                else {
                                    resolve(true);
                                }
                            });
                        },
                    },
                ],
            },
        ],
        Findings: [
            {
                key: "alternativeFindingsLayout",
                label: "Display alternative findings layout (BETA)",
                widget: "checkbox",
            },
        ],
        "Concept Search": [
            {
                key: "structuredQuery",
                label: "Structured Query",
                widget: "checkbox",
                dependencies: [Feature.CONCEPT_SEARCH, Feature.CONCEPT_TYPE_SEARCH],
            },
            {
                key: "searchAperture",
                label: "Search Aperture",
                widget: "checkbox",
                dependencies: [Feature.APERTURE],
            },
            {
                key: "showTopResults",
                label: "Show Top Results",
                widget: "checkbox",
                dependencies: [Feature.CONCEPT_SEARCH, Feature.CONCEPT_TYPE_SEARCH],
            },
            {
                key: "hierarchicalFacets",
                label: "Hierarchical Concept Aspects (BETA)",
                widget: "checkbox",
                dependencies: [Feature.TYPE_HIERARCHY_NAVIGATION],
            },
            {
                key: "conceptClustering",
                label: "Concept Clustering Mode",
                widget: "select",
                dependencies: [Feature.CONCEPT_SEARCH, Feature.CONCEPT_TYPE_SEARCH],
                options: clusteringModes,
                viewWidgetProps,
            },
            {
                key: "expandedFacetCount",
                label: "Expanded Filter Count",
                dependencies: [Feature.CONCEPT_SEARCH, Feature.CONCEPT_TYPE_SEARCH],
                widget: InputNumber,
                rules: [
                    {
                        validator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                                if (!validExpandedFacetCount(value)) {
                                    reject(new Error(`Value must be between 15 and 100`));
                                }
                                else {
                                    resolve(true);
                                }
                            });
                        },
                    },
                ],
            },
        ],
        "Query Graph": [
            {
                key: "queryGraph",
                label: "Query Graph (BETA)",
                dependencies: [Feature.QUERY_GRAPH],
                widget: "checkbox",
            },
            {
                key: "showMultihopStrategySelection",
                label: "Multihop Discovery Strategy(BETA)",
                dependencies: [Feature.QUERY_ANALYSIS_MULTIHOP],
                widget: "checkbox",
            },
            {
                key: "conjunctionBindingsSearchWidth",
                label: "Conjunction Bindings Count",
                dependencies: [Feature.QUERY_GRAPH_SOLVING],
                widget: InputNumber,
            },
        ],
        "Model Builder": [
            {
                key: "modelBuilderAvailable",
                label: "Model Builder (BETA)",
                dependencies: [Feature.CONCEPT_SEARCH, Feature.CONCEPT_TYPE_SEARCH],
                widget: "checkbox",
            },
        ],
        Display: [
            {
                key: "colorTheme",
                label: "Color Theme",
                widget: "select",
                options: themes,
                viewWidgetProps,
            },
        ],
    }; //
    const supportedFeatures = useSupportedFeatures();
    const showModal = (guruMode) => {
        openModal(ModalType.CONFIG);
        form.setFieldsValue(Object.assign(Object.assign({}, configValues), { colorTheme: theme.active }));
        update({ visible: true, guruMode });
    };
    const hideModal = useCallback(() => {
        closeModal(ModalType.CONFIG);
        update({ visible: false });
    }, []);
    const updateConfigProgress = useCallback(() => {
        const fieldsValues = form.getFieldsValue();
        const newConfig = pick(fieldsValues, ...SESSION_CONFIG_KEYS);
        setInProgressConfig(newConfig);
    }, [form]);
    const updateConfig = useCallback(() => {
        const fieldsValues = form.getFieldsValue();
        const localConfig = pick(fieldsValues, ...Object.keys(DEFAULT_LOCAL_CONFIG));
        updateLocalConfig(localConfig);
        sessionUpdater(inProgressConfig);
        fieldsValues.colorTheme && theme.setTheme(fieldsValues.colorTheme);
    }, [form, inProgressConfig, updateLocalConfig, sessionUpdater]);
    const [{ suggestedQuestionsHistory }] = useCurrentSession();
    const renderSection = (title, fields) => {
        const filteredFields = fields
            .filter(({ key }) => guruMode || !guruModeFields.has(key))
            .filter(({ dependencies }) => !dependencies ||
            dependencies.length === 0 ||
            dependencies.every(d => supportedFeatures.has(d)));
        if (!filteredFields.length)
            return null;
        return (_jsxs("fieldset", { children: [_jsx("legend", { children: title }), _jsx(FormBuilder, { form: form, meta: fields.map(f => omit(f, ["dependencies"])) })] }, title));
    };
    const modalContainer = (_jsx("div", { children: visible && (_jsx(Modal, Object.assign({ width: 700, title: `Configuration${guruMode ? " (Guru Mode)" : ""}`, closable: false, open: visible, destroyOnClose: true, footer: _jsxs(_Fragment, { children: [enableSuggestedQuestions && (_jsx(Button, Object.assign({ disabled: !suggestedQuestionsHistory.length, onClick: () => {
                            Modal.confirm({
                                title: "Clear Suggested Questions History?",
                                onOk() {
                                    sessionUpdater(s => ({
                                        suggestedQuestionsHistory: [],
                                    }));
                                },
                            });
                        } }, { children: "Clear Suggested Questions History" }), "clear")), guruMode && _jsx(DebugModeToggle, {}), _jsx(Button, Object.assign({ onClick: () => {
                            form.setFieldsValue(Object.assign(Object.assign({}, pick(defaultSession, ...SESSION_CONFIG_KEYS)), DEFAULT_LOCAL_CONFIG));
                            theme.setTheme();
                            updateLocalConfig({});
                        } }, { children: "Reset" })), _jsx(Button, Object.assign({ onClick: hideModal }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "primary", onClick: () => {
                            updateConfig();
                            hideModal();
                        }, disabled: !validExpandedFacetCount(inProgressConfig.expandedFacetCount) }, { children: "Ok" }))] }) }, { children: _jsx(Form, Object.assign({ className: "Config", form: form, initialValues: configValues, onValuesChange: updateConfigProgress }, { children: Object.entries(fieldMap).map(([key, fields]) => renderSection(key, fields)) })) }))) }));
    return {
        showModal,
        modalContainer,
    };
}
