import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from "antd";
import { atom, useAtom, useSetAtom } from "jotai";
import React from "react";
import { useGlobalEscape } from "../../../utils/interactions/useGlobalEscape";
import { Close } from "../icons/Icons";
import { VideoPlayer } from "../video/VideoPlayer";
import "./VideoPopup.less";
const a_videoUrl = atom("");
const padding = 16;
const width = 640;
const height = 320;
export function useShowVideo() {
    return useSetAtom(a_videoUrl);
}
export function VideoPopup() {
    const [videoUrl, setVideoUrl] = useAtom(a_videoUrl);
    useGlobalEscape(() => setVideoUrl(""), !!videoUrl);
    return videoUrl ? (_jsx(Modal, Object.assign({ closable: false, open: true, destroyOnClose: true, className: "VideoPopup", centered: true, footer: null, width: width + 2 * padding }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-stretch space-y-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between mb-2" }, { children: [_jsx("div", {}), _jsx(Close, { className: "cursor-pointer text-white", onClick: () => setVideoUrl("") })] })), _jsx(VideoPlayer, { className: "self-center mb-2", width: width, height: height, url: videoUrl })] })) }))) : null;
}
