import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../appState/context/theme/classes";
const AutoAdjustingTextarea = ({ value, onChange, style, className }) => {
    const [text, setText] = React.useState(value);
    const textareaRef = React.useRef(null);
    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to recalculate
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
        }
    }, [text]); // This effect runs whenever the text changes
    return (_jsx("textarea", { className: classes("AutoAdjustingTextarea w-full border-box border border-secondary", className), ref: textareaRef, value: text, onChange: e => {
            setText(e.target.value);
            onChange(e.target.value);
        }, style: Object.assign(Object.assign({}, style), { resize: "none", overflow: "hidden" }) }));
};
export default AutoAdjustingTextarea;
