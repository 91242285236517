import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { atom, useAtom, useSetAtom } from "jotai";
import React from "react";
import df from "../assets/images/df.png";
import { addWindowEventHandlers } from "./utils/events/addWindowEventHandlers";
import { useOnGlobalEscape } from "./utils/interactions/utils/interactionEvents";
const a_message = atom(null);
export function PopDF() {
    const [message, setMessage] = useAtom(a_message);
    useOnGlobalEscape(Boolean(message), () => setMessage(null));
    React.useEffect(() => {
        if (message) {
            return addWindowEventHandlers({
                mousedown(e) {
                    e.preventDefault();
                    setMessage(null);
                },
            });
        }
    }, [message]);
    return message ? (_jsx("div", Object.assign({ className: "absolute", style: { bottom: 0, right: 72, zIndex: 1000 } }, { children: _jsxs("div", Object.assign({ className: "relative" }, { children: [_jsx("img", { src: df }), _jsx("div", Object.assign({ style: {
                        width: 384,
                        height: 250,
                        position: "absolute",
                        top: 35,
                        left: 35,
                    }, className: "text-xl flex justify-center items-center" }, { children: message }))] })) }))) : null;
}
const messages = [
    "A happy soul is the best shield for a cruel world.",
    "A successful man is one who can lay a firm foundation with the bricks that other throw at him.",
    "All limitations are self-imposed.",
    "All progress takes place outside the comfort zone.",
    "And still, I rise.",
    "Aspire to inspire before we expire.",
    "Be so good they can't ignore you.",
    "Broken crayons still color. ",
    "Change the game, don't let the game change you.",
    "Change the world by being yourself.",
    "Character cannot be developed in ease and quiet. Only through experience of trial and suffering can the soul be strengthened, ambition inspired, and success achieved.",
    "Determine your priorities and focus on them.",
    "Die with memories, not dreams.",
    "Do it with passion or not at all.",
    "Do one thing every day that scares you.",
    "Don't be afraid to give up the good to go for the great.",
    "Don't let the fear of losing be greater than the excitement of winning.",
    "Don't you know your imperfections is a blessing?",
    "Dream as if you'll live forever, live as if you'll die today.",
    "Dream without fear. Love without limits.",
    "Embrace the glorious mess that you are.",
    "Every moment is a fresh beginning.",
    "Everything you can imagine is real.",
    "Fall seven times and stand up eight.",
    "Go forth on your path, as it exists only through your walking.",
    "Good things happen to those who hustle.",
    "Happiness depends upon ourselves.",
    "Hate comes from intimidation, love comes from appreciation.",
    "Have enough courage to start and enough heart to finish.",
    "I could agree with you but then we'd both be wrong.",
    "I don't need it to be easy, I need it to be worth it.",
    "I failed my way to success.",
    "I find that the harder I work, the more luck I seem to have.",
    "I have nothing to lose but something to gain.",
    "I never did anything worth doing by accident, nor did any of my inventions come indirectly through accident, except the phonograph. No, when I have fully decided that a result is worth getting, I go about it, and make trial after trial, until it comes.",
    "I never dreamed about success, I worked for it.",
    "I owe my success to having listened respectfully to the very best advice, and then going away and doing the exact opposite.",
    "I will remember and recover, not forgive and forget.",
    "If it matters to you, you'll find a way.",
    "If I'm gonna tell a real story, I'm gonna start with my name.",
    "If you are not willing to risk the usual, you will have to settle for the ordinary.",
    "If you can dream it, you can do it.",
    "If you really look closely, most overnight successes took a long time.",
    "If you really want to do something, you'll find a way. If you don't, you'll find an excuse.",
    "If you tell the truth you don't have to remember anything.",
    "If you want it, work for it.",
    "Impossible is for the unwilling.",
    "In order to succeed, we must first believe that we can.",
    "In order to succeed, your desire for success should be greater than your fear of failure.",
    "It hurt because it mattered.",
    "It is better to fail in originality than to succeed in imitation.",
    "Keep going. Be all in.",
    "Keep on going, and the chances are that you will stumble on something, perhaps when you are least expecting it. I never heard of anyone ever stumbling on something sitting down.",
    "Let the beauty of what you love be what you do.",
    "Life becomes easier when you learn to accept the apology you never got.",
    "Many of life's failures are people who did not realize how close they were to success when they gave up.",
    "May your choices reflect your hopes, not your fears.",
    "Never give in except to convictions of honor and good sense.",
    "Never let your emotions overpower your intelligence.",
    "Never regret anything that made you smile.",
    "Normality is a paved road: it's comfortable to walk but no flowers grow.",
    "Nothing lasts forever but at least we got these memories.",
    "Oh, the things you can find, if you don't stay behind.",
    "One day the people that don't even believe in you will tell everyone how they met you.",
    "Opportunities don't happen. You create them.",
    "People who succeed have momentum. The more they succeed, the more they want to succeed, and the more they find a way to succeed. Similarly, when someone is failing, the tendency is to get on a downward spiral that can even become a self-fulfilling prophecy.",
    "Problems are not stop signs, they are guidelines.",
    "Reality is wrong, dreams are for real.",
    "Screw it, let's do it.",
    "Simplicity is the ultimate sophistication.",
    "Some people dream of success while others wake up and work.",
    "Stop chasing the money and start chasing the passion.",
    "Strive for greatness.",
    "Success is not final; failure is not fatal: It is the courage to continue that counts.",
    "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.",
    "Success is the child of audacity.",
    "Success is walking from failure to failure with no loss of enthusiasm.",
    "Success isn't just about what you accomplish in your life; it's about what you inspire others to do.",
    "Success seems to be connected with action. Successful people keep moving. They make mistakes, but they don't quit.",
    "Success usually comes to those who are too busy to be looking for it.",
    "Successful people do what unsuccessful people are not willing to do. Don't wish it were easier; wish you were better.",
    "Take the risk or lose the chance.",
    "The difference between who you are and who you want to be is what you do.",
    "The meaning of life is to give life meaning.",
    "The ones who are crazy enough to think they can change the world, are the ones that do.",
    "The only limit to our realization of tomorrow will be our doubts of today.",
    "The only place where success comes before work is in the dictionary.",
    "The real test is not whether you avoid this failure, because you won't. It's whether you let it harden or shame you into inaction, or whether you learn from it; whether you choose to persevere.",
    "The road to success and the road to failure are almost exactly the same.",
    "The secret of success is to do the common thing uncommonly well.",
    "The secret to success is to know something nobody else knows.",
    "The successful warrior is the average man, with laser-like focus.",
    "The time is always right to do what is right.",
    "The true meaning of life is to plant trees, under whose shade you do not expect to sit.",
    "The way to get started is to quit talking and begin doing.",
    "There are no secrets to success. It is the result of preparation, hard work, and learning from failure.",
    "There are two types of people who will tell you that you cannot make a difference in this world: those who are afraid to try and those who are afraid you will succeed.",
    "There is a powerful driving force inside every human being that, once unleashed, can make any vision, dream, or desire a reality.",
    "There is no substitute for hard work.",
    "To live will be an awfully big adventure.",
    "Try not to become a man of success. Rather become a man of value.",
    "Try to be a rainbow in someone's cloud.",
    "Turn your wounds into wisdom.",
    "Wanting to be someone else is a waste of who you are.",
    "What consumes your mind controls your life.",
    "What we think, we become.",
    "Whatever you do, do it well.",
    "When nothing goes right, go left.",
    "When words fail, music speaks.",
    "White is not always light and black is not always dark.",
    "Would you like me to give you a formula for success? It's quite simple, really: Double your rate of failure. You are thinking of failure as the enemy of success. But it isn't at all. You can be discouraged by failure or you can learn from it, so go ahead and make mistakes. Make all you can. Because remember that's where you will find success.",
    "Yesterday you said tomorrow. Just do it.",
    "You can if you think you can.",
    "You know you are on the road to success if you would do your job, and not be paid for it.",
    "My wife told me to stop impersonating a flamingo. I had to put my foot down.",
    "I went to buy some camo pants but couldn't find any.",
    "I used to have a handle on life, but then it broke.",
    "I was wondering why the frisbee kept getting bigger and bigger, but then it hit me.",
    "I heard there were a bunch of break-ins over at the car park. That is wrong on so many levels.",
    "I want to die peacefully in my sleep, like my grandfather& Not screaming and yelling like the passengers in his car.",
    "When life gives you melons, you might be dyslexic.",
    "Don't you hate it when someone answers their own questions? I do.",
    "It takes a lot of balls to golf the way I do.",
    "I told him to be himself; that was pretty mean, I guess.",
    "The problem with kleptomaniacs is that they always take things literally.",
    "I can't believe I got fired from the calendar factory. All I did was take a day off.",
    "Most people are shocked when they find out how bad I am as an electrician.",
    "Never trust atoms; they make up everything.",
    "I used to think I was indecisive. But now I'm not so sure.",
    "Russian dolls are so full of themselves.",
    "The easiest time to add insult to injury is when you're signing someone's cast.",
    "Light travels faster than sound, which is the reason that some people appear bright before you hear them speak.",
    "A termite walks into the bar and asks, Is the bar tender here?'",
    "I told my aunt she drew her eyebrows too high. She seemed surprised.",
    "People who use selfie sticks really need to have a good, long look at themselves.",
    "Two fish are in a tank. One says, How do you drive this thing?'",
    "I always take life with a grain of salt. And a slice of lemon. And a shot of tequila.",
    "Just burned 2,000 calories. That's the last time I leave brownies in the oven while I nap.",
    "Always borrow money from a pessimist. They'll never expect it back.",
    "Build a man a fire and he'll be warm for a day. Set a man on fire and he'll be warm for the rest of his life.",
    "The last thing I want to do is hurt you; but it's still on the list.",
    "Today a man knocked on my door and asked for a small donation toward the local swimming pool. I gave him a glass of water.",
    "I'm reading a book about anti-gravity. It's impossible to put down.",
    "Doctor, there's a patient on line one that says he is invisible.'Well, tell him I can't see him right now.'",
    "Atheism is a non-prophet organization.",
    "The future, the present, and the past walk into a bar. Things got a little tense.",
    "Before you criticize someone, walk a mile in their shoes. That way, when you do criticize them, you're a mile away and you have their shoes.",
    "Maybe if we start telling people their brain is an app, they'll want to use it.",
    "If a parsley farmer gets sued, can they garnish his wages?",
    "I got a new pair of gloves today, but they're both lefts,' which on the one hand is great, but on the other, it's just not right.",
    "I didn't think orthopedic shoes would help, but I stand corrected.",
    "I was riding a donkey the other day when someone threw a rock at me and I fell off. I guess I was stoned off my ass.",
    "People who take care of chickens are literally chicken tenders.",
    "It was an emotional wedding. Even the cake was in tiers.",
];
export function useShowDF() {
    const setMessage = useSetAtom(a_message);
    return () => {
        const idx = Math.floor(Math.random() * messages.length);
        setMessage(messages[idx]);
    };
}
