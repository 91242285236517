import React from "react";
export function useLocationChange(callback, triggerOnMount = false) {
    React.useEffect(() => {
        triggerOnMount && callback(window.location.href);
    }, []);
    React.useEffect(() => {
        const listener = () => callback(window.location.href);
        window.addEventListener("popstate", listener);
        return () => window.removeEventListener("popstate", listener);
    }, [callback]);
}
