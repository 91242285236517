import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../../appState/context/theme/classes";
import { ValidatedInput } from "../../common/forms/InputValidation";
import { Search } from "../../common/icons/Icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { queryStateAtoms } from "../../../appState/atomic/queryState/queryStateAtoms";
import { useDebouncedEffect } from "../../../appState/atomic/session/utils/debouncer";
import "./EvidenceFilter.less";
export function EvidenceFilter({ disabled, onFocusChange, }) {
    const [searchParams, setSearchParams] = useAtom(queryStateAtoms.searchParams);
    const [filter, setFilter] = React.useState(searchParams.evidenceFilter || "");
    const [disableResync, setDisableResync] = React.useState(false);
    // resync the filter if the coraState changes externally while this component is mounted
    React.useEffect(() => {
        if (!disableResync && filter !== searchParams.evidenceFilter)
            setFilter(searchParams.evidenceFilter);
    }, [searchParams]);
    // give the user time to type before resyncing
    // without this, sometimes the resync will remove the last few characters if their is a pause in typing
    useDebouncedEffect(() => setDisableResync(false), false, {
        value: filter,
        delay: 1000,
    });
    useDebouncedEffect(evidenceFilter => {
        setSearchParams({ evidenceFilter });
    }, false, { value: filter, delay: 500 });
    const onChange = React.useCallback((s) => {
        setFilter(s);
        setDisableResync(true);
    }, [setFilter, setDisableResync]);
    return (_jsx("div", Object.assign({ className: "EvidenceFilter" }, { children: _jsx(ValidatedInput, { className: classes("p-0 text-md mb-1"), style: {
                borderWidth: "0px 0px 1px 0px",
                borderStyle: "solid",
                borderColor: "gray",
                borderRadius: 0,
                maxWidth: "20rem",
            }, prefix: _jsx(Search, { className: "ml-2 neutral-gray-800 text-base" }), value: filter, placeholder: "Search within...", onChange: onChange, disabled: disabled, onFocus: () => onFocusChange && onFocusChange(true), onBlur: () => onFocusChange && onFocusChange(false), clearIcon: _jsx("div", Object.assign({ className: "text-md mr-2 flex items-center text-gray-500" }, { children: _jsx(CloseCircleOutlined, {}) })) }) })));
}
