import { jsx as _jsx } from "react/jsx-runtime";
import { omit } from "lodash";
import { API } from "../../../api/api";
import { useRemoteQuery } from "../../../api/useRemoteQuery";
import { Feature, useIsFeatureSupported, } from "../../../appState/atomic/featureFlags/features";
import { WEB_SOURCE_ID } from "../../../appState/atomic/globalInfo";
import { brandBorderClass, columnWidthClass2, } from "../../../appState/context/theme/lf";
import { Card } from "../../common/layout/Card";
import { FilteredCardList } from "../../common/layout/CardsList";
import { pushModal } from "../../common/modals/Modal";
import { DefaultMarkDown } from "../../common/text/MarkDown";
import { InfoRow } from "../../evidence/InfoRow";
function Glossary({ corpus_id, span_in_evidence, concept_cluster, }) {
    const glossarySupported = useIsFeatureSupported(Feature.GLOSSARY);
    const glossary = useRemoteQuery({
        loader: API.search_glossary,
        params: glossarySupported && concept_cluster // prefer concept cluster, if available
            ? {
                corpus_id,
                concept_cluster: omit(concept_cluster, [
                    "isOriginal",
                    "removed",
                ]),
            }
            : glossarySupported && span_in_evidence
                ? {
                    corpus_id,
                    span_in_evidence,
                }
                : undefined,
    });
    const terms = (glossary.value || []).filter(t => t.title && t.gloss);
    return (_jsx("div", Object.assign({ className: "flex-1 flex items-stretch space-x-[144px]" }, { children: _jsx(FilteredCardList, { className: columnWidthClass2, filterBorderClass: brandBorderClass, title: "Glossary", loading: glossary.loading, items: terms, noItemsMessage: "No Definitions", brandingColorClass: "bg-gray-700", itemTexts: item => [item.title, item.gloss], itemRender: (item, idx, items, filterText) => (_jsx(Card, { title: _jsx(DefaultMarkDown, { text: item.title, separator: filterText, className: "font-bold" }), content: _jsx(DefaultMarkDown, { text: item.gloss, separator: filterText }), brandingColorClass: "bg-gray-700", lineClamp: terms.length !== 1 ? 3 : undefined, banners: item.url && (_jsx(InfoRow, { sources: [
                        {
                            external_url: item === null || item === void 0 ? void 0 : item.url,
                            corpus_id: WEB_SOURCE_ID,
                            document_id: item === null || item === void 0 ? void 0 : item.url,
                            title: "",
                            id: "",
                        },
                    ] })), actions: null })) }) })));
}
export function showGlossary({ corpus_id, span_in_evidence, concept_cluster, }) {
    pushModal({
        title: "Glossary",
        titleColorClass: "bg-gray-700",
        content: (_jsx(Glossary, { corpus_id: corpus_id, span_in_evidence: span_in_evidence, concept_cluster: concept_cluster })),
        easyClose: () => true,
    });
}
