import { mapValues } from "lodash";
import { TwoKey, } from "../../../appState/atomic/queryState/types/DoubleDict";
function findStartNodes(nodes, edges) {
    const edgeTargets = edges.map(e => e.target);
    return nodes.filter(n => !edgeTargets.includes(n.id));
}
// closes gaps in the depth map by moving nodes with gaps
function adjustDepthMap(edges, depthMap) {
    let gapsExist = true;
    while (gapsExist) {
        let closedGap = false;
        edges.forEach(edge => {
            const sourceDepth = depthMap[edge.source];
            const targetDepth = depthMap[edge.target];
            const gapSize = targetDepth - sourceDepth;
            // if there is a gap and moving the node will not affect any other edges
            const fromEdges = edges.filter(e => e.source === edge.source);
            if (gapSize > 1 && fromEdges.length === 1) {
                depthMap[edge.source] = targetDepth - 1;
                closedGap = true;
            }
        });
        if (!closedGap) {
            gapsExist = false;
        }
    }
    const minDepth = Math.min(...Object.values(depthMap));
    const normalizedDepths = mapValues(depthMap, depth => depth - minDepth);
    return normalizedDepths;
}
// nodes can be at multiple depths due to multiple paths to the same node
export function nodeIdsWithGraphDepths(nodes, edges) {
    let depthByPath = {};
    const startNodes = findStartNodes(nodes, edges);
    startNodes.forEach((node, idx) => {
        traverse(node, 0, idx.toString());
    });
    const depthMap = mapValues(depthByPath, pathDepths => Math.max(...Object.values(pathDepths)));
    return adjustDepthMap(edges, depthMap);
    function traverse(node, depth, path) {
        if (TwoKey.has(depthByPath, node.id, path)) {
            const currentDepth = TwoKey.get(depthByPath, node.id, path) || depth;
            depthByPath = TwoKey.set(depthByPath, node.id, path, Math.min(currentDepth, depth));
            if (currentDepth < depth) {
                return;
            }
        }
        else {
            depthByPath = TwoKey.set(depthByPath, node.id, path, depth);
        }
        const nextNodes = edges
            .filter(e => e.source === node.id)
            .map(({ target }) => nodes.find(n => n.id === target))
            .filter(Boolean);
        nextNodes.forEach((n, idx) => {
            traverse(n, depth + 1, idx > 0 ? path + idx : path);
        });
    }
}
