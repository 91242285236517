import { useAtomValue } from "jotai";
import { mapValues, mergeWith } from "lodash";
import React from "react";
import { useCurrentSession } from "../session/session";
import { a_constraintModelUtil } from "./constraintModel/ConstraintModelUtil";
import { a_activeConstraint } from "./constraintModel/constraints";
import { ConceptSource } from "./types/ConstraintModelState";
//TODO: functionality should just come from the constraint, hack for now
export function useAnnotationProvider() {
    const [{ showTopResults }] = useCurrentSession();
    const constraint = useAtomValue(a_activeConstraint);
    const is_directed = constraint === null || constraint === void 0 ? void 0 : constraint.is_directed;
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const conceptIndex = React.useMemo(() => {
        const constraintNodes = modelUtil.getConceptNodeIds(constraint);
        const constraintPathTable = modelUtil.getMemberIdToConceptPathTable(ConceptSource.MODEL, constraintNodes);
        const extendedNodes = modelUtil.model.config.extendedNodeIds;
        const watchlistPathTable = modelUtil.getMemberIdToConceptPathTable(ConceptSource.EXTENDED, extendedNodes);
        function getPrefix(nId) {
            if (constraintNodes.includes(nId))
                return "C";
            if (extendedNodes.includes(nId))
                return "W";
            return null;
        }
        function getType(nId) {
            if (constraintNodes.includes(nId))
                return "Concept";
            if (extendedNodes.includes(nId))
                return "Watchlist";
            return null;
        }
        function getPositionFunc(nId) {
            return (cId) => constraintNodes.includes(nId)
                ? modelUtil.getConstraintConceptPosition(constraint, cId, showTopResults && is_directed)
                : extendedNodes.includes(nId)
                    ? modelUtil.getWatchlistConceptPosition(cId)
                    : null;
        }
        const pathTable = mergeWith({}, constraintPathTable, watchlistPathTable, (a, b) => (a || []).concat(b || []));
        return mapValues(pathTable, paths => paths // leaf can be parent
            .map(([nId, parentConceptId, leafConceptId]) => {
            const leafConcept = modelUtil.model.data.concepts[leafConceptId];
            const leafName = leafConcept === null || leafConcept === void 0 ? void 0 : leafConcept.name;
            const position = getPositionFunc(nId)(parentConceptId);
            const prefix = getPrefix(nId);
            if (!leafName || position === null || !prefix)
                return;
            return {
                superscript: `${prefix}${position}`,
                tooltip: leafName,
                type: getType(nId),
                index: position,
            };
        })
            .filter(Boolean));
    }, [modelUtil, showTopResults, is_directed]);
    const clausesIndex = React.useMemo(() => {
        return mapValues(modelUtil.getClauseTextIndex(constraint), ref => {
            return {
                superscript: ref.clauseType.slice(0, 2),
                tooltip: ref.clauseType,
            };
        });
    }, [modelUtil]);
    return React.useCallback(evidenceSpans => {
        return evidenceSpans.reduce((acc, s) => {
            const m = s.match;
            if (m.match_type === "TEXT_MATCH") {
                acc.push({
                    type: "text_match",
                    value: {
                        key: m.match_id,
                        value: m.match_id,
                        context: "Text Match",
                    },
                });
            }
            else if (m.match_type === "KEYWORD_MATCH") {
                acc.push({
                    type: "keyword_match",
                    value: {
                        key: m.match_id,
                        value: m.match_id,
                        context: "Keyword Match",
                    },
                });
            }
            else if (m.match_type === "CONCEPT" ||
                m.match_type === "CONTEXT_CONCEPT") {
                const i = conceptIndex[m.match_id];
                if (i && i.length > 0) {
                    i.forEach(i => acc.push({
                        type: "concept",
                        value: {
                            key: i.superscript,
                            value: i.tooltip,
                            context: `${i.type}`,
                            ordering: i.index,
                        },
                    }));
                }
                else {
                    //console.log("UNKNOWN CONCEPT", m.match_name)
                }
            }
            else if (m.match_type === "ARGUMENT") {
                acc.push({
                    type: "argument",
                    value: {
                        key: m.match_id,
                        value: m.match_id,
                        context: "Qualifier",
                    },
                });
            }
            else if (m.match_type === "ARGUMENT_CLAUSE") {
                const i = clausesIndex[m.match_id];
                if (i) {
                    acc.push({
                        type: "argument",
                        value: {
                            key: i.superscript,
                            value: i.tooltip,
                            context: "Qualifier",
                        },
                    });
                }
                else {
                    const additionalArgs = s.additionalSpans.filter(s => s.type === "argument" &&
                        Object.values(clausesIndex).filter(c => c.tooltip === s.payload.name).length > 0);
                    if (additionalArgs.length) {
                        additionalArgs.forEach(a => acc.push({
                            type: "argument",
                            value: {
                                key: a.payload.name.slice(0, 2),
                                value: a.payload.name,
                                context: "Qualifier",
                            },
                        }));
                    }
                }
            }
            else if (m.match_type === "RELATION") {
                acc.push({
                    type: "relation",
                    value: {
                        key: "R",
                        value: m.match_name,
                        context: "Relation",
                    },
                });
            }
            return acc;
        }, []);
    }, [conceptIndex, clausesIndex]);
}
